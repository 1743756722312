import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SkuGroup} from '../models/sku-group.model';
import {catchError, map} from 'rxjs/operators';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {UtilService} from '@app/services/util.service';
import {SkuGroupService} from '@app/services/sku-group.service';

@Injectable({providedIn: 'root'})
export class SkuGroupResolver implements Resolve<SkuGroup[]> {

  constructor(private service: SkuGroupService,
              private uiBlockerService: UiBlockerService,
              private utilService: UtilService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<SkuGroup[]> {
    this.uiBlockerService.block();
    const projectId = +this.utilService.getRouteParameter(route, 'projectId');
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    return this.service.fetchAll(projectId, modelRunId).pipe(map((skuGroups) => {
      this.uiBlockerService.unblock();
      return skuGroups;
    }), catchError(() => {
      this.uiBlockerService.unblock();
      this.router.navigate(['/404']);
      return of(null);
    }));
  }
}
