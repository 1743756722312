import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class UiBlockerService {

  loadingRequests: number;

  constructor(private spinner: NgxSpinnerService) {
    this.loadingRequests = 0;
  }

  block(): void {
    this.loadingRequests = this.loadingRequests + 1;
    this.toggleSpinner();
  }

  unblock(): void {
    setTimeout(() => {
      this.loadingRequests = this.loadingRequests - 1;
      this.loadingRequests = this.loadingRequests < 0 ? 0 : this.loadingRequests;
      this.toggleSpinner();
    }, 100);
  }

  unblockAll(): void {
    this.loadingRequests = 0;
    this.unblock();
  }

  toggleSpinner(): void {
    if (this.loadingRequests > 0) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }
}
