<div class="mr-t-2 reports-container">
    <div class="tool-bar create-report">
        <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnToScenarios()"><span class="sif sif-arrow-w"></span></button>
        <h2>Create {{modelRun.displayName}} Report</h2>
    </div>
    <mat-card class="mat-card-border">
        <mat-tab-group class="mat-tabs-groups" [selectedIndex]="selectedReportMenuIndex">
            <mat-tab *ngFor="let reportMenuTab of reportMenuTabs">
                <ng-template mat-tab-label>
                    <span (click)="changeReport(reportMenuTab.id)" class="reporting-menu-tab">{{reportMenuTab.label}}</span>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <router-outlet></router-outlet>
    </mat-card>
</div>

