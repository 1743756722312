import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {AppConstantsService} from '@app/services/app-constants.service';

@Directive({
  selector: '[appReportStatus]',
})
export class ReportStatusDirective implements OnInit {

  @Input() appReportStatus = null;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private appConstantsService: AppConstantsService) {
  }

  ngOnInit(): void {
    let textClass = null;
    const status = this.appReportStatus.scenarioOutOfSync ? this.appConstantsService.OUT_OF_SYNC : this.appReportStatus.status;
    switch (this.appReportStatus.status.toLowerCase()) {
      case 'completed':
        textClass = 'text-success';
        break;
      case 'not started':
      case 'running':
        textClass = 'text-warn';
        break;
      case 'terminated, not completed':
      case 'timed out':
      case 'constraint error':
      case 'failed':
        textClass = 'text-danger';
        break;
    }
    textClass = this.appReportStatus.scenarioOutOfSync ? 'text-warn' : textClass;
    this.renderer.addClass(this.elementRef.nativeElement, textClass);
    this.elementRef.nativeElement.innerText = status;
  }
}
