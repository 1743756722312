import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {AppConstantsService} from '../services/app-constants.service';
import {ModelRunSku} from '../models/model-run-sku.model';
import {UtilService} from './util.service';
import {UserConfigurations} from '../models/user-configurations.model';
import {ItemCharacteristicsSku} from '../interfaces/item-characteristics.interface';
import {ModelCacheUtil} from '../utils/model-cache-util';

@Injectable({
  providedIn: 'root'
})
export class ModelRunSkuService {

  private cache: ModelCacheUtil<ModelRunSku>;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private appConstantsService: AppConstantsService,
    private utilService: UtilService) {
    this.cache = new ModelCacheUtil<ModelRunSku>();
  }

  getAll(projectId: number, modelRunId: string): Observable<Array<ModelRunSku>> {
    const modelRunSkus = this.cache.filter((it) => it.projectId === projectId && it.modelRunId === modelRunId);
    if (modelRunSkus.length) {
      return of(modelRunSkus);
    } else {
      return this.fetchAll(projectId, modelRunId);
    }
  }

  fetchAll(projectId: number, modelRunId: string): Observable<Array<ModelRunSku>> {
    const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'skus');
    return this.http.get<Array<ModelRunSku>>(url).pipe(map((skus: Array<ModelRunSku>) => {
      this.cache.clear();
      this.cache.appendAll(skus);
      return skus;
    }));
  }

  getSkuConfigsInDisplayOrder(modelRunSkus: Array<ModelRunSku>, skuConfigsItemDisplayOrder: UserConfigurations): Array<ModelRunSku> {
    if (skuConfigsItemDisplayOrder) {
      modelRunSkus.forEach(modelRunSku => {
        if (skuConfigsItemDisplayOrder.configurations && skuConfigsItemDisplayOrder.configurations.skuOrder) {
          modelRunSku['displayOrder'] = skuConfigsItemDisplayOrder.configurations.skuOrder.find(sku => sku.skuId === modelRunSku.skuId).displayOrder;
        } else {
          modelRunSku['displayOrder'] = modelRunSku.skuId;
        }
      });
      return modelRunSkus.sort((sku1, sku2) => {
        return sku1.displayOrder - sku2.displayOrder;
      });
    } else {
      return modelRunSkus;
    }
  }

  updateSkusGroup(projectId: number, modelRunId: string, skus: Array<ItemCharacteristicsSku>): Observable<Array<ModelRunSku>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/modelRunSkusGroups`;
    return this.http.put<Array<ModelRunSku>>(url, {skus}).pipe(map((modelRunSkus) => {
      this.cache.appendAll(modelRunSkus);
      return modelRunSkus;
    }));
  }
}
