import {DataType, DropdownData} from '../components/dropdown/dropdown.data.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownFilterService<T> {

  isAllCompleted = false;

  public getLabels(dropdown: DropdownData<T>): string {
    let label = '';
    if (dropdown.dataType === DataType.CHECKBOX) {
      label = this.setCheckboxLabels(dropdown);
    } else {
      label = this.setRadioLabels(dropdown);
    }
    return label;
  }

  private setRadioLabels(radioItem: DropdownData<T>): string {
    let label = '';
    radioItem.data.forEach((item) => {
      if (item.selected) {
        label = item.label;
      }
    });
    return label;
  }

  private setCheckboxLabels(CheckboxItem: DropdownData<T>): string {
    let label = '';
    this.isAllCompleted = CheckboxItem != null && CheckboxItem.data.every(t => t.selected);
    if (this.isAllCompleted === true) {
      if (CheckboxItem.groupSelect) {
        label = CheckboxItem.groupSelect;
      } else {
        label = 'All ' + CheckboxItem.dropdownLabel;
      }
    } else {
      label = this.getCheckboxLabels(CheckboxItem);
    }
    return label;
  }

  private getCheckboxLabels(item: DropdownData<T>): string {
    let label = '';
    const datasetLength = item.data.length;
    const labelsArray: Array<string> = [];
    item.data.forEach(dataItem => {
      if (dataItem.selected === true) {
        labelsArray.push(dataItem.label);
      }
    });
    if (labelsArray.length) {
      if (labelsArray.length === 1) {
        label = labelsArray[0];
      } else {
        label = labelsArray.length + ' ' + item.dropdownLabel;
      }
    }
    if (labelsArray.length === datasetLength) {
      labelsArray.length = 0;
    }
    return label;
  }

}
