import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SourcingMatrixReport} from '@app/models/sourcing-matrix-report.model';
import {Scenario} from '@app/models/scenario.model';
import {ScenarioGroup} from '@app/models/scenario-group.model';
import {MetaData} from '@app/models/meta-data.model';
import {ScenarioService} from '@app/services/scenario.service';
import {ScenarioGroupService} from '@app/services/scenario-group.service';
import {FormControl, Validators} from '@angular/forms';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {SkuGroup} from '@app/models/sku-group.model';
import {SourcingMatrixService} from '../../../../services/sourcing-matrix.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GenericConfirmationModalComponent} from '@app/components/generic-confirmation-modal/generic-confirmation-modal.component';
import {ReportService} from '@app/services/report.service';
import {forkJoin} from 'rxjs';
import {ReportOutputMetric} from '@app/interfaces/report-output-metric.interface';
import {
  GroupBasedScenarioFilterData,
  NonGroupBasedScenarioFilterData
} from "@app/interfaces/scenario-group-filter-data.interface";
import {AppConstantsService} from '@app/services/app-constants.service';

@Component({
  selector: 'app-create-sourcing-matrix-report',
  templateUrl: './create-sourcing-matrix-report.component.html',
  styleUrls: ['./create-sourcing-matrix-report.component.scss']
})
export class CreateSourcingMatrixReportComponent implements OnInit {

  report: SourcingMatrixReport;

  validationErrors: Record<string, string>;

  metrics: Array<ReportOutputMetric>;

  scenarios: Array<Scenario>;

  metaData: MetaData;

  scenarioGroups: Array<ScenarioGroup>;

  skuGroups: Array<SkuGroup>;

  reportNameMaxLength: number;

  reportDescriptionMaxLength: number

  disableStartRun: boolean;

  existingReports: Array<SourcingMatrixReport>;

  scenarioGroupsData: Array<GroupBasedScenarioFilterData>;

  referenceScenarioData: NonGroupBasedScenarioFilterData;

  searchValue = '';

  get projectId(): number {
    return this.metaData.projectId;
  }

  get modelRunId(): string {
    return this.metaData.modelRunId;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sourcingMatrixService: SourcingMatrixService,
              private scenarioService: ScenarioService,
              private scenarioGroupService: ScenarioGroupService,
              private uiBlockerService: UiBlockerService,
              private snackBarService: SnackBarService,
              private dialog: MatDialog,
              private reportService: ReportService,
              private appConstantsService: AppConstantsService) {
  }

  setupNewReport(projectId: number, modelRunId: string): SourcingMatrixReport {
    const report = new SourcingMatrixReport();
    report.name = this.sourcingMatrixService.getNewReportName(this.reportNameMaxLength);
    report.description = '';
    report.projectId = projectId;
    report.modelRunId = modelRunId;
    report.outputConfigMetric = null;
    report.skuGroupId = null;
    return report;
  }

  duplicateReport(source: SourcingMatrixReport): SourcingMatrixReport {
    const report = new SourcingMatrixReport();
    report.name = this.sourcingMatrixService.getDuplicateReportName(source.name, this.reportNameMaxLength);
    report.description = source.description;
    report.projectId = source.projectId;
    report.modelRunId = source.modelRunId;
    report.outputConfigMetric = source.outputConfigMetric;
    report.scenarioId = this.scenarios.find(it => it.id === source.scenarioId) ? source.scenarioId : null;
    report.skuGroupId = this.skuGroups.find(it => it.skuGroupId === source.skuGroupId) ? source.skuGroupId : (source.skuGroupId === -1 ? -1 : null);
    return report;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const source = params.source;
      this.validationErrors = {};
      this.reportNameMaxLength = 30;
      this.reportDescriptionMaxLength = 50;
      this.disableStartRun = true;
      this.metaData = this.route.parent.parent.snapshot.data.metaData;
      this.skuGroups = this.route.parent.parent.snapshot.data.skuGroups;
      this.metrics = Object.keys(this.metaData.outputConfigurations)
        .filter(it => it.toLowerCase().indexOf('share') !== -1)
        .map((key: string) => {
          return {id: key, data: this.metaData.outputConfigurations[key]};
        });
      this.report = this.setupNewReport(this.projectId, this.modelRunId);

      forkJoin([this.scenarioService.getAll(this.projectId, this.modelRunId),
        this.scenarioGroupService.getAll(this.projectId, this.modelRunId),
        this.sourcingMatrixService.getAll(this.projectId, this.modelRunId)
      ]).subscribe(([scenarios, scenarioGroups, reports]) => {
        this.scenarios = scenarios;
        this.scenarioGroups = scenarioGroups;
        this.existingReports = reports;
        this.prepareScenarioGroups('');

        if (source) {
          this.sourcingMatrixService.getById(source, {
            projectId: this.projectId,
            modelRunId: this.modelRunId
          }).subscribe((sourceReport) => {
            this.report = this.duplicateReport(sourceReport);
            if (this.report.scenarioId) {
              this.changeScenario(this.report.scenarioId);
            }
            if (this.report.skuGroupId) {
              this.changeGroup(this.report.skuGroupId);
            }
            this.onMetricChange(this.report.outputConfigMetric);
          });
        }
      });
    });

  }

  prepareScenarioGroups(filterString: string): void {
    const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups, filterString);
    this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
    this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
  }

  search(searchValue: string): void {
    this.searchValue = searchValue;
    this.prepareScenarioGroups(this.searchValue);
  }

  closeReport(): void {
    this.router.navigateByUrl(`/projects/${this.projectId}/runs/${this.modelRunId}/reports/sourcing-matrix`);
  }

  applyReportName(name: string, focusOut: boolean): void {
    if (focusOut && name.trim() === '') {
      name = this.sourcingMatrixService.getNewReportName(this.reportNameMaxLength);
    }
    this.report.name = name;
    this.validateReportName();
  }

  applyReportDescription(description: string): void {
    this.report.description = description
    this.validateReportDescription();
  }

  async changeGroup(skuGroupId: number): Promise<void> {
    this.report.skuGroupId = skuGroupId;
    await this.toggleStartRunButton();
  }

  onMetricChange(metric: string): void {
    this.report.outputConfigMetric = metric;
    this.toggleStartRunButton();
  }

  async validateModelReportName(): Promise<string> {
    return new Promise<string>((resolve) => {
      const name = this.report.name;
      const maxLength = this.reportNameMaxLength;
      const reports = this.existingReports || [];
      const preExistingReportWithSameName = reports.find((report: SourcingMatrixReport) => {
        return report.name.toLowerCase().trim() === name.toLowerCase().trim();
      });
      if (preExistingReportWithSameName) {
        resolve(this.appConstantsService.REPORT_ALREADY_EXISTS);
      } else {
        const validate = new FormControl(name, [
          Validators.maxLength(maxLength),
          Validators.required
        ]);
        resolve(validate.invalid ? validate.errors.required ? `Report name is required.` : `The maximum number of characters allowed is ${maxLength}.` : null);
      }
    });
  }

  validateModelReportDescription(): string {
    let reportDescriptionError = null;
    const reportDescriptionMaxLength = this.reportDescriptionMaxLength;
    const description = this.report.description;
    if (description && description.length > reportDescriptionMaxLength) {
      reportDescriptionError = `The maximum number of characters allowed is ${reportDescriptionMaxLength}.`;
    }
    return reportDescriptionError;
  }

  async validateModelScenario(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.scenarioId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Scenario is required.' : null);
    });
  }

  async validateModelItemCharacteristics(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.skuGroupId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Item Characteristics is required.' : null);
    });
  }

  async validateModelMetric(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.outputConfigMetric, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Metric is required.' : null);
    });
  }

  async validateReportName(): Promise<void> {
    const errorMsg = await this.validateModelReportName();
    this.validationErrors.name = errorMsg;
    await this.toggleStartRunButton();
  }

  async validateReportDescription(): Promise<void> {
    const errorMsg = await this.validateModelReportDescription();
    this.validationErrors.description = errorMsg;
    await this.toggleStartRunButton();
  }

  generateReport(): void {
    this.uiBlockerService.block();
    this.report.name = this.report.name.trim();
    this.report.description = this.report.description?.trim();
    this.sourcingMatrixService.add(this.report).subscribe((report: SourcingMatrixReport) => {
      this.snackBarService.openSnackBar('Sourcing matrix run submitted successfully.', 'success');
      this.report = report;
      this.uiBlockerService.unblock();
      this.closeReport();
    }, () => {
      this.uiBlockerService.unblock();
    });
  }

  changeScenario(scenarioId: string): void {
    this.report.scenarioId = scenarioId;
    this.toggleStartRunButton();
  }

  async toggleStartRunButton(): Promise<void> {
    const errMsg = (
      await this.validateModelReportName() ||
      await this.validateModelScenario() ||
      await this.validateModelItemCharacteristics() ||
      await this.validateModelMetric()
    );
    this.disableStartRun = !!errMsg;
  }

  async canDeactivate(): Promise<boolean> {
    /**
     * Once the report is successfully generated, we don't want to show confirmation when redirecting to the list route.
     */
    if (this.report.id) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Exit Sourcing Matrix Run Setup?',
      body: 'Changes will not be saved. Press \'Exit\' to continue, otherwise press \'Cancel\'.',
      confirmButtonLabel: 'EXIT',
      cancelButtonLabel: 'CANCEL'
    };
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(value => {
        const isExiting = value === 'EXIT';
        if (!isExiting) {
          // set the selected tab to the price elasticity tab if user decides to cancel out of transition
          this.reportService.activeReportSubject.next('sourcing-matrix');
        }
        resolve(isExiting);
      });
    });
  }
}
