import {Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, CanDeactivate, Router, RoutesRecognized} from '@angular/router';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {HotTableRegisterer} from '@handsontable/angular';
import {Calibration} from './../../../../../models/calibration/calibration.model';
import {Scenario} from './../../../../../models/scenario.model';
import {SkuConfig} from './../../../../../models/sku-config.model';
import {UserConfigurations} from './../../../../../models/user-configurations.model';
import {ScenarioService} from './../../../../../services/scenario.service';
import {UserConfigurationsService} from './../../../../../services/user-configurations.service';
import {UtilService} from './../../../../../services/util.service';
import {AppConstantsService} from './../../../../../services/app-constants.service';
import {CalibrationService} from './../../../../../services/calibration.service';
import {UiBlockerService} from './../../../../../services/ui-blocker.service';
import {SkuGroupService} from '@app/services/sku-group.service';
import {
    percentageCellValidator,
    priceCellValidator,
    promoPriceCellValidator,
    specialPromoPriceCellValidator,
    scenarioNameValidator,
    featurePriceCellValidator,
    displayPriceCellValidator,
    featureAndDisplayPriceCellValidator,
    sizeCellValidator, tauValidator, roundTo, rhoValidator
} from '@app/utils/sku-config.validator';
import {SkuConfigTableCellRenderers} from '@app/utils/sku-config-table-cell-renderers';
import {GeneralSetting} from '@app/models/general-setting.model';
import {GeneralSettingService} from '@app/services/general-setting.service';
import {MetaData} from '@app/models/meta-data.model';
import {SkuGroup} from '@app/models/sku-group.model';
import {WebMessageService} from './../../../../../services/web-message.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GenericConfirmationModalComponent} from '@app/components/generic-confirmation-modal/generic-confirmation-modal.component';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import * as moment from 'moment';
import {UserManagementService} from '@app/services/user-management.service';
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {CalibrationDeactivateGuard} from '@app/routes/project/runs/run/calibration/calibration.deactivate.guard';
import {AccessPolicyService} from '@app/services/access-policy.service';
import {ScenarioGroupService} from '@app/services/scenario-group.service';
import {AuditTrialService} from '@app/services/audit-trial.service';
import {AuditTrail} from '@app/models/audit-trail.model';
import {ModelRunService} from "@app/services/model-run.service";
import {SingleSelectDropdownOption} from '@app/components/single-select-dropdown/single-select-dropdown.component';
import {ProjectService} from "@app/services/project.service";
import {Segment} from "@app/models/segment.model";
import {DropdownData} from "@app/components/dropdown/dropdown.data.model";
import {ModelRun} from "@app/models/model-run.model";
import {filter, pairwise} from "rxjs/operators";
import {ReadFile} from 'ngx-file-helpers';
import {MetaDataService} from '@app/services/meta-data.service';
import {
    GroupBasedScenarioFilterData,
    NonGroupBasedScenarioFilterData
} from "@app/interfaces/scenario-group-filter-data.interface";
import {ScenarioGroup} from "@app/models/scenario-group.model";
import {ModelRunSku} from "@app/models/model-run-sku.model";
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { outputConfiguration } from 'src/app/models/meta-data.model';
import { MatTabGroup } from '@angular/material/tabs';
import { PriceElasticityReport } from 'src/app/models/price-elasticity-report.model';
import { PriceElasticityService } from 'src/app/services/price-elasticity.service';

const CALIBRATION_MSG_GROUP = 'calibration';
const SELECT_SCENARIO = 'Select Scenario';
const DEFAULT_STEP2_CALIBRATION_VIEW = 'current';
const LOW_POPULATION_THRESHOLD = 75;

@Component({
    selector: 'app-calibration',
    templateUrl: './calibration.component.html',
    styleUrls: ['./calibration.component.scss'],
    animations: [
        trigger('bodyExpansion', [
            transition(':enter', [
                style({ height: '0px', minHeight: '0' }),
                animate('300ms ease-in', style({ height: '*', visibility: 'visible' })),
            ]),
            transition(':leave', [
                animate('300ms ease-out', style({ height: '0px', minHeight: '0' })),
            ]),
        ]),
    ]
})
export class CalibrationComponent implements OnInit, OnDestroy, CanDeactivate<CalibrationDeactivateGuard> {

    projectId: number;
    modelRunId: string;
    modelRun: ModelRun;
    calibration: Calibration;
    scenarios: Array<Scenario>;
    scenariosForDropdown: Array<Scenario>;
    calibrationScenario: Scenario;
    userConfigurations: UserConfigurations;
    subscriptions: Subscription;
    scenarioSkuConfigs: Record<string, SkuConfig[]>
    dataSet: Array<any>;
    clonedDataSet: Array<any>;
    hotTableId: string;
    scenarioSkuConfigsDataReady$: Subject<Record<string, SkuConfig[]>>;
    reloadCurrentResultsTable: Subject<any> = new Subject<any>();
    reloadNewResultsTable: Subject<any> = new Subject<any>();
    reloadScenarioResultsTable: Subject<any> = new Subject<any>();
    reloadTable: Subject<any> = new Subject<any>();
    tableSettings = {
        columns: [],
        columnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [],
        groupColumnStartIndex: 0,
        groupColumnEndIndex: 0,
        inputsColumnStartIndex: 0,
        inputsColumnEndIndex: 0,
        outPutColumnStartIndex: 0,
        outPutColumnEndIndex: 0,
        unCalibratedHeadersEndIndex : 0 ,
        unCalibratedHeadersStartIndex : 0,
        targetHeadersStartIndex : 0,
        targetHeadersEndIndex : 0 ,
        calibratedStartIndex : 0,
        calibratedEndIndex : 0,
        subHeaders: [],
    };
    visibleColumns: any;
    skuGroups: SkuGroup[];
    cellRenderers: SkuConfigTableCellRenderers;
    generalSetting: GeneralSetting;
    metaData: MetaData;
    inputColumns: any;
    tradePromoInputColumns : any;
    nonFormErrorMessages = {};
    skuConfigs: any;
    calibrationConfigColumnChooser: UserConfigurations;
    disableApply = false;
    disableApplyReason: string;
    disableSave = false;
    disableCalibration = false;
    disableCalibrateReason: string;
    disableCalibrationForPromoShare = false;
    exportWarnMessage: string;
    showProgress = false;
    currentNewTypes = [
        {id: 'current', displayName: 'Current', selected: false},
        {id: 'new', displayName: 'New', selected: false}
    ];
    selectedCalibrationView = DEFAULT_STEP2_CALIBRATION_VIEW;
    showElement = false;
    newResultDataSet: Array<any>;
    showBenchMark = false;
    disableCalibrationBenchmark = false
    calibrationStatus = '';
    calibrationStatusState = 'start-text';
    calibrating = false;
    lockMessage = null;
    calibrationLocked = true;
    disableLockCalibration = true;
    userActivity: number;
    dataChanged = false;
    warningModalMessages = {
        exitCalibrationHeader: 'Are you sure you want to exit Calibration?',
        clickOnApply: 'Changes won\'t be saved until you click on "Apply"',
        exitCurrentViewHeader: 'Are you sure you want to exit the "Current Items" view?',
        clickOnCalibrateExit: 'Changes won\'t be saved until you click on "Calibrate"',
        clickOnRecalibrateExit: 'Changes won\'t be saved until you click on "SAVE" (tau/ rho overrides) or "RECALIBRATE" (changes to target shares/ elasticities/ target splits)',
        exitNewViewHeader: 'Are you sure you want to exit the "New Items" view?',
        clickOnSaveOrCalibrate: 'Changes won\'t be saved until you click on "SAVE" (tau/ rho overrides) or "CALIBRATE NEW" (changes to target shares/ elasticities/ target splits)',
        reCalibrateHeader:'Calibrate current items',
        clickOnRecalibrate:'Are you sure you want to submit a new calibration request? If you proceed, all previous calibration adjustments for all items will be modified and overwritten.',
        calibrateNewHeader:'Calibrate new items',
        clickOnCalibrateNew:'Are you sure you want to submit a new calibration request? If you proceed, all previous calibration adjustments to the relevant new items will be modified and overwritten.',
        saveCalibrationHeader:'Save Calibration',
        clickOnSaveCalibration:'Are you sure you want to save calibration adjustments? If you proceed, all unsubmitted changes to targets will be discarded.',
        stopCalibrationHeader: 'Stop Calibration',
        clickOnStopCalibration: 'Are you sure you want to stop calibration? This action cannot be undone',
        reCalibrateFiltersChanngedHeader: 'Calibrate with new sample',
        clickOnFiltersChanngedRecalibrate: 'Are you sure you want to submit a calibration request with a new filter selection? If you proceed, calibration will be cleared and restarted with the new sample.',
        calibrateFiltersChanngedHeader: 'Calibrate with new sample',
        clickOnFiltersChanngedCalibrate: 'Are you sure you want to submit a calibration request with a new filter selection? If you proceed, calibration will be cleared and restarted with the new sample.',
    };
    auditTrails: Array<AuditTrail>;
    calibratedDynamicColumnCount = 0;
    volumeTypeOptions: Array<SingleSelectDropdownOption>;
    selectedVolumeTypeOption: SingleSelectDropdownOption;
    disableVolumeTypeReason = 'To change this value you should clear and restart calibration';
    nSizeText: string = null;
    segments: Segment[];
    nSize: number;
    filters: Array<DropdownData<string>>;
    populationFilterToolTip: string = null;
    navigateToUrl = '';
    enableUploadWeeklyData = false;
    uploadWeeklyDataFileId: string;
    scenarioDropdownShow = false;
    showPreviewIcon = false;
    scenarioResultsTable : boolean;
    scenarioGroupsData: Array<GroupBasedScenarioFilterData>;
    referenceScenarioData: NonGroupBasedScenarioFilterData;
    searchValue = '';
    scenarioGroups: Array<ScenarioGroup>;
    modelRunSkus: Array<ModelRunSku>;
    selectedScenarioId: string;
    conditionalColumns: any;
    collapsed = false;
    timeOnPromoInvalidScenariosList:{id:string,name:string}[]=[];
    showScenarioInvalidWarningMessage = false;
    timeOnPromoWarningMessage: string;

    disableCalibrationSettings = false;
    slideOpenCalibrationSettings = false;
    generalCalibrationSetting = {avgItemPriceElasticity:-1, calibrationMetrics:[],selectedCalibrationMetric:"",calcVersion:""};
    enableCalibrationSettingsAdminView = false;
    triggerClose: Subject<boolean> = new Subject();
    resetPopulationFiltersStatus = false;
    hidePopulationFiltersReset = false;
    showPopulationFilter: boolean;
    totalNSize: number;
    lowPopulation = false;
    lowPopulationMsgType = 'warn';
    lowPopulationMsg: string;
    outputsFilteredOnNonSampleFilterToggleTooltip: string;
    isFilterChangedExpanded = true;
    isFilterChanged = false;
    originalFilters = [];
    hasPromo = false;
    isTargetSharesUpdated = false;
    step2CompletedPercentage = 0;
    step2CompletedPercentageIntervalId: any;
    step2InSubmittedState = false;

    constructor(private hotTableRegisterer: HotTableRegisterer,
                private router: Router,
                private route: ActivatedRoute,
                private utilService: UtilService,
                private uiBlockerService: UiBlockerService,
                private calibrationService: CalibrationService,
                private appConstantService: AppConstantsService,
                private scenarioService: ScenarioService,
                private userConfigurationsService: UserConfigurationsService,
                private webMessageService: WebMessageService,
                private appConstantsService: AppConstantsService,
                private generalSettingService: GeneralSettingService,
                private dialog: MatDialog,
                private snackBarService: SnackBarService,
                private skuGroupService: SkuGroupService,
                private userManagementService: UserManagementService,
                private authProxyService: AuthProxyService,
                private accessPolicyService: AccessPolicyService,
                private scenarioGroupService: ScenarioGroupService,
                private ngZone: NgZone,
                private auditTrialService: AuditTrialService,
                private modelRunService: ModelRunService,
                private projectService: ProjectService,
                private priceElasticityService: PriceElasticityService,
                private metaDataService: MetaDataService) {
    }        

    refreshCalibrationLockState() {
        const lockedDuration = this.calibration.lockedOn !== null ? (new Date().getTime() - new Date(this.calibration.lockedOn).getTime())/3600000 : 0;

        if(lockedDuration > 1){
            this.calibrationService.forceUnlock(this.projectId, this.modelRunId).subscribe((calibration) => {                
                this.calibration = calibration;
                this.calibrationLocked = false;
                this.disableLockCalibration = false;       
            });           
        }
        else{            
            const lockedByOther = (this.calibration.lockedBy && this.calibration.lockedBy !== this.authProxyService.user.userId);
            this.calibrationLocked = this.calibration.lockedBy && this.calibration.lockedBy === this.authProxyService.user.userId;
            this.disableLockCalibration = (this.calibration.lockedBy && this.calibration.lockedBy !== this.authProxyService.user.userId);
            if (lockedByOther) {
                this.userManagementService.getUser(this.calibration.lockedBy).subscribe((collaborator) => {
                    this.lockMessage = `Calibration is currently being edited by ${collaborator.fullName}. You will be unable to make edits until the user exits calibration.`;
                });
            } else {
                this.lockMessage = null;
            }
        }
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.hotTableId = 'calibration-hot-table';
        this.scenarioSkuConfigsDataReady$ = new Subject<Record<string, SkuConfig[]>>();
        this.metaData = this.route.parent.snapshot.data.metaData;
        this.hasPromo = this.metaData.promo !== 'none';
        this.generalSettingService.get(this.metaData.projectId, this.metaData.modelRunId).subscribe(g => {
            this.generalSetting = g;
        });
        this.cellRenderers = this.generalSettingService.createSkuConfigTableCellRenderersInstance(this.generalSetting);
        this.calibration = this.route.snapshot.data.calibration;
        this.showBenchMark = this.metaData.hasPrice;
        this.projectId = +this.utilService.getRouteParameter(this.route.snapshot, 'projectId');
        this.modelRunId = this.utilService.getRouteParameter(this.route.snapshot, 'modelRunId');           
        this.segments = this.route.snapshot.data.segments;
        this.modelRun = this.modelRunService.getModelRun(this.modelRunId);      
        this.setFilters(this.segments);
        const scenarioGroups = this.route.snapshot.data.scenarioGroups;
        this.scenariosForDropdown = this.scenarioService.scenarios;
        this.scenarioGroups = this.scenarioGroupService.scenarioGroups;
        const calibrationScenariosGroup = this.scenarioGroupService.getCalibrationScenariosGroup(scenarioGroups);
        this.scenarios = this.route.snapshot.data.scenarios.filter((scenario: Scenario) => {
            return scenario.name == this.appConstantsService.REFERENCE_SCENARIO_NAME || calibrationScenariosGroup.assignedScenarios.indexOf(scenario.id) !== -1;
        });
        this.calibrationScenario = this.scenarios.find(it => this.scenarioService.isCalibrationScenario(it));
        this.prepareScenarioGroups('');
        this.selectedScenarioId = this.calibrationScenario.id;
        this.currentNewTypes[0].selected = true;
        this.setUserAdditionalColumnsSelection();
        this.calibrationService.getCalcVersion(this.projectId, this.modelRunId).subscribe(data =>{
            if(data){
                this.generalCalibrationSetting.calcVersion = data['version'];
            }
            if (this.skuGroupService.skuGroups.length) {
                this.skuGroups = this.skuGroupService.skuGroups.filter(skuGroup => skuGroup.showInSimulator);
                this.loadScenarioSkuConfigs();
            } else {
                this.skuGroupService.getAll(this.projectId, this.modelRunId).subscribe((skuGroups) => {
                    this.skuGroups = skuGroups.filter(skuGroup => skuGroup.showInSimulator);                
                    this.loadScenarioSkuConfigs();
                });
            }
        });        
        this.refreshCalibrationLockState();
        this.toggleDisableCalibrationBenchmark();
        this.webMessageService.consumeMessage(`${CALIBRATION_MSG_GROUP}-${this.modelRunId}`).subscribe({
            next: (eventData) => {
                const responseJson = JSON.parse(eventData).data;
                if (responseJson.modelRunId === this.modelRunId) {
                    if (responseJson.action === 'CALIBRATION-SAVE-FAILED') {
                        this.snackBarService.openErrorSnackBar('Calibration Save Failed, Calibrated Results Will be cleared. Please Calibrate or Save Again');
                    }
                    this.calibrationService.fetch(this.projectId, this.modelRunId).subscribe((calibration: Calibration) => {
                        this.onCalibrationChange(calibration);
                    });

                }
            }
        });
        if (this.calibration.syncPromotions) {
            this.warnMessage('Last change(s) require a new calibration run.');
        }
        this.setupVolumeTypeOptions();
        this.enableUploadWeeklyData = this.metaDataService.isPlugAndPlay(this.metaData);
        this.uploadWeeklyDataFileId = null;
        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.ngZone.run(() => {
                    this.setCalibrationStatus();
                });
            }, 1000);
        });

        this.generalCalibrationSetting.avgItemPriceElasticity=this.calibration?.avgItemPriceElasticity;
        this.generalCalibrationSetting.calibrationMetrics = this.volumeTypeOptions;
        this.generalCalibrationSetting.selectedCalibrationMetric = this.selectedVolumeTypeOption?.value;       
        this.enableCalibrationSettingsAdminView = this.authProxyService.userIsAdminUser();
        if (this.segments.length) {
            this.subscriptions.add(this.modelRunService.getNSize(this.projectId, this.modelRunId, this.segments.map(it => it.segment)).subscribe((data: any) => {
                this.totalNSize = data.nSize;
            }));
        }
        this.setupSnapshotData();
        this.getStep2CompletionPercentage();
        this.step2CompletedPercentageIntervalId = setInterval(()=>{
            this.getStep2CompletionPercentage(); 
        },300000);
    }

    get isCalibrationInitiated(): boolean {
        return this.calibration.statusCode >= this.appConstantService.CALIBRATION_STEP1_STARTED;
    }

    get hasCorrelatedSkus(): boolean {
        return this.calibration && this.calibration.skus && this.calibration.skus.find(it => it.correlatedSkus && it.correlatedSkus.length) ? true : false;
    }

    get additionalColumnOptions(): any {
        return this.columnsOptions.filter((it) => {
            return !it.hide;
        });
    }

    toggleDisableCalibrationBenchmark() {
        this.disableCalibrationBenchmark = this.calibration && this.calibration.statusCode >= this.appConstantService.CALIBRATION_STEP1_STARTED;
    }

    get syncedOn(): any {
        return `${moment(this.calibration.syncedOn).fromNow()}`;
    }

    get step2Ended(): any {
        return `${moment(this.calibration.step2Ended).fromNow()}`;
    }

    setCalibrationStatus(): void {
        const dataSet = this.dataSet;
        const calibration = this.calibration;
        let calibrationStatus = '';
        let calibrationStatusState = 'start-text';
        const calibrationScenario = this.calibrationScenario;
        const allSkusAssignedToScenario = dataSet && dataSet.every(it => it.scenarioName !== SELECT_SCENARIO);
        const isEverySkuAssignedToCalibrationScenario = allSkusAssignedToScenario && dataSet.every(it => it.scenarioId === calibrationScenario.id);
        const metaData = this.metaData;

        if (!calibration.id || calibration.statusCode === 0) {
            if (metaData.hasPrice) {
                if (isEverySkuAssignedToCalibrationScenario) {
                    calibrationStatus = 'Add a PE Benchmark, if applicable. Then click on APPLY to get uncalibrated results.';
                } else {
                    calibrationStatus = 'Select a scenario for each of the items not assigned to the Calibration Scenario and add a PE Benchmark, if applicable. Then click on APPLY to get uncalibrated results.';
                }
            } else {
                if (isEverySkuAssignedToCalibrationScenario) {
                    calibrationStatus = 'Click on APPLY to get uncalibrated results.';
                } else {
                    calibrationStatus = 'Select a scenario for each of the items not assigned to the Calibration Scenario. Then click on APPLY to get uncalibrated results.';
                }
            }
        } else if (this.calibrationService.started(calibration)) {
            if (this.calibrationService.simulatingCalibration(calibration)) {
                calibrationStatus = `Simulating calibrated results...`;
                calibrationStatusState = 'warning-text';
                this.calibrating = true;
            } else if (calibration.statusCode == this.appConstantService.CALIBRATION_STEP1_STARTED) {
                calibrationStatus = 'Simulating uncalibrated results...';
                calibrationStatusState = 'warning-text-status';
                this.calibrating = true;
            } else if (calibration.statusCode === this.appConstantService.CALIBRATION_STEP1_FAILED) {
                calibrationStatus = 'Simulating uncalibrated results failed.Try again.';
                calibrationStatusState = 'error-text-status';
                this.calibrating = false;
            } else if (calibration.statusCode < this.appConstantService.CALIBRATION_STEP2_STARTED) {
                calibrationStatus = 'Add a target share to each of the items assigned to the Calibration Scenario.';
                this.calibrating = false;
            } else if (calibration.statusCode === this.appConstantService.CALIBRATION_STEP2_FAILED) {
                calibrationStatus = 'Calibration failed. Try again.';
                calibrationStatusState = 'error-text-status';
                this.calibrating = false;
            } else if (this.calibrationService.step2Started(calibration)) {
                this.userManagementService.getUser(calibration.step2StartedBy).subscribe((user) => {
                    calibrationStatus = `<b>In Progress</b>: ${moment(calibration.step2Started).fromNow()} <span class="user-full-name"> by ${user.fullName}</span>`;
                    if(this.isLongRuningCalibration(this.calibration,this.generalCalibrationSetting.calcVersion)){
                        if(this.step2InSubmittedState){
                            calibrationStatus = `<b>Submitted</b> by ${user.fullName}</span>`;
                        }
                        else{
                            calibrationStatus = `<b>In Progress</b>: ${100-this.step2CompletedPercentage}% remaining <span class="user-full-name"> by ${user.fullName}</span>`;
                        }
                    }
                    calibrationStatusState = 'warning-text-status';
                    this.calibrationStatus = calibrationStatus;
                });
                this.calibrating = true;
            } else if (calibration.statusCode >= this.appConstantService.CALIBRATION_STEP2_COMPLETED) {
                this.userManagementService.getUser(calibration.step2StartedBy).subscribe((user) => {
                    calibrationStatus = `<b>Last Run</b>: ${moment(this.calibration.step2Ended).fromNow()} <span class="user-full-name">by ${user.fullName}</span>`;
                    if (calibration.syncedOn) {
                        this.userManagementService.getUser(calibration.syncedBy).subscribe((user) => {
                            calibrationStatus += ` | <b>Last Saved</b>: ${moment(this.calibration.syncedOn).fromNow()} <span class="user-full-name">by ${user.fullName}</span>`;
                            this.calibrationStatus = calibrationStatus;
                        });
                    }
                    calibrationStatusState = 'text-success-status';
                    this.calibrationStatus = calibrationStatus;
                });
                this.calibrating = false;
            }
        }
        this.calibrationStatus = calibrationStatus;
        this.calibrationStatusState = calibrationStatusState;
    }

    loadScenarioSkuConfigs() {
        const joinRequests = this.scenariosForDropdown.reduce((requests, scenario) => {
            if (!requests[scenario.id]) {
                requests[scenario.id] = this.scenarioService.getSkuConfigs(this.projectId, this.modelRunId, scenario.id);
            }
            return requests;
        }, {});
        this.uiBlockerService.block();
        forkJoin(joinRequests)
            .subscribe((data) => {
                this.scenarioSkuConfigs = data;
                this.auditTrialService.getAuditTrails(this.projectId, this.modelRunId).subscribe((auditTrails) => {
                    this.skuConfigs = this.scenarioSkuConfigs[this.calibrationScenario.id];
                    this.auditTrails = auditTrails.filter((it) => it.property === 'adjustedTauValue' || it.property === 'adjustedRho1Value');
                    this.reloadData();
                });
            });
    }

    setCurrentType(viewType: any): void {
        this.selectedCalibrationView = viewType.id;
        this.currentNewTypes.forEach((profit) => {
            profit.selected = profit.id === viewType.id;
        });
        if(viewType.id === 'new') {
            this.showPreviewIcon = true;
        }else {
            this.showPreviewIcon = false;
            this.scenarioDropdownShow = false;
        }
        this.dataSet = null;
        this.reloadData();
    }

    onPromotionClick(): void {
        this.navigateToUrl = 'calibration/promotion';
        this.navigateAwayFromCalibration('calibration/promotion', this.route.parent);
    }

    setupCalibrateAfterStep2Columns(): Record<string, Record<any, any>> {
        const shareTargetLabel = this.calibrationService.getShareTargetLabel(this.calibration);
        const isTargetEqVolume = this.calibrationService.isTargetEqVolume(this.calibration);

        const uncalibratedUnitShare = {
            name: 'uncalibratedUnitShare',
            type: 'numeric',
            displayName: shareTargetLabel,
            data: isTargetEqVolume ? 'uncalibratedEqVolumeShare' : 'uncalibratedUnitShare',
            readOnly: true,
            className: 'htRight',
            width: 140,
            renderer: this.cellRenderers.unitShareRendererForCalibration.bind(this.cellRenderers)
        };
        const uncalibratedPriceElasticityColumn = {
            name: 'uncalibratedPriceElasticity',
            type: 'numeric',
            displayName: 'Price <br> Elasticity',
            data: 'uncalibratedPriceElasticity',
            readOnly: true,
            className: 'htRight',
            width: 70,
            renderer: this.cellRenderers.priceElasticityRenderer.bind(this.cellRenderers)
        };
        const targetSharesColumn = {
            name: 'targetUnitShare',
            type: 'numeric',
            displayName: shareTargetLabel,
            data: 'targetUnitShare',
            readOnly: true,
            className: 'htRight',
            width: 140,
            renderer: this.cellRenderers.targetShareRenderer.bind(this.cellRenderers),
            validator: percentageCellValidator
        };
        const targetPriceElasticityColumn = {
            name: 'targetPriceElasticity',
            type: 'numeric',
            displayName: 'Price <br> Elasticity',
            data: 'targetPriceElasticity',
            readOnly: true,
            className: 'htRight',
            width: 70,
            renderer: this.cellRenderers.targetPriceElasticityRenderer.bind(this.cellRenderers)
        };
        const tauColumn = {
            name: 'tau',
            type: 'numeric',
            displayName: 'Tau',
            data: 'tau',
            readOnly: true,
            className: 'htRight',
            width: 75,
            renderer: this.cellRenderers.tauRenderer.bind(this.cellRenderers),
            validator: tauValidator
        };
        const rhoColumn = {
            name: 'rho',
            type: 'numeric',
            displayName: 'Rho',
            data: 'rho1',
            readOnly: true,
            className: 'htRight',
            width: 75,
            renderer: this.cellRenderers.rhoRenderer.bind(this.cellRenderers),
            validator: rhoValidator
        };
        const velocityColumn = {
            name: 'velocity',
            type: 'numeric',
            displayName: 'Units Velocity',
            data: 'velocity',
            readOnly: true,
            className: 'htRight',
            width: 90,
            renderer: this.cellRenderers.velocityRenderer.bind(this.cellRenderers)

        };

        const precisionIndexColumn = {
            name: 'index',
            type: 'numeric',
            displayName: 'Units Share <br> Fit Index',
            data: 'precisionIndex',
            readOnly: true,
            className: 'htRight',
            width: 120,
            renderer: this.cellRenderers.precisionIndexRenderer.bind(this.cellRenderers),
        };

        const precisionDifferenceColumn = {
            name: 'absolute',
            type: 'numeric',
            displayName: 'Units Share <br> Fit Difference',
            data: 'precisionDifference',
            readOnly: true,
            className: 'htRight',
            width: 130,
            renderer: this.cellRenderers.precisionDifferenceRenderer.bind(this.cellRenderers),
        };

        const calibratedUnitsOnPromoShare = {
            name: 'calibratedUnitsOnPromoShare',
            displayName: 'Units on Promo Share',
            type: 'text',
            data: 'unitsOnPromoShare',
            readOnly: true,
            className: 'htRight',
            width: 120,
            renderer: this.cellRenderers.unitRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedUnitShare = {
            name: 'calibratedUnitShare',
            type: 'numeric',
            displayName: 'Unit <br> Shares',
            data: 'calibratedUnitShare',
            readOnly: true,
            className: 'htRight',
            width: 140,
            renderer: this.cellRenderers.unitShareRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedUnit = {
            name: 'unit',
            displayName: 'Units',
            type: 'text',
            data: 'calibratedUnit',
            readOnly: true,
            className: 'htRight',
            width: 120,
            renderer: this.cellRenderers.unitRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedPriceElasticityColumn = {
            name: 'calibratedPriceElasticity',
            type: 'numeric',
            displayName: 'Price <br> Elasticity',
            data: 'calibratedPriceElasticity',
            readOnly: true,
            className: 'htRight',
            width: 70,
            renderer: this.cellRenderers.priceElasticityRenderer.bind(this.cellRenderers)
        };
        const correlationColumn = {
            name: 'correlation',
            type: 'numeric',
            displayName: 'Correlation',
            data: 'correlation',
            readOnly: true,
            className: 'htRight',
            width: 80,
            renderer: this.cellRenderers.coRelationWeightRenderer.bind(this.cellRenderers)
        };
        const weightColumn = {
            name: 'weight',
            type: 'numeric',
            displayName: 'Weight',
            data: 'weight',
            readOnly: true,
            className: 'htRight',
            width: 60,
            renderer: this.cellRenderers.weightRenderer.bind(this.cellRenderers)
        };
        const targetPercentageUnitsOnPromoShareColumn = {
            name: 'targetPercentageUnitsOnPromoShare',
            type: 'numeric',
            displayName: '% Units on<br> Promo Share',
            data: 'targetPercentageUnitsOnPromoShare',
            readOnly: true,
            className: 'htRight',
            width: 140,
            renderer: this.cellRenderers.targetPercentageUnitsOnPromoShareRenderer.bind(this.cellRenderers)            
        };
        const calibratedPercentageUnitsOnPromoShareColumn = {
            name: 'calibratedPercentageUnitsOnPromoShare',
            type: 'numeric',
            displayName: '% Units on<br> Promo Share',
            data: 'calibratedPercentageUnitsOnPromoShare',
            readOnly: true,
            className: 'htRight',
            width: 140,
            renderer: this.cellRenderers.percentageUnitsOnPromoShareForCalibration.bind(this.cellRenderers)
        };
        const promoSplitFitIndexColumn = {
            name: 'promoSplitFitIndex',
            type: 'numeric',
            displayName: 'Promo Split <br> Fit Index',
            data: 'promoSplitFitIndex',
            readOnly: true,
            className: 'htRight',
            width: 120,
            renderer: this.cellRenderers.promoSplitFitIndexRenderer.bind(this.cellRenderers),
        };
        
        const currentViewColumns = {
            uncalibratedUnitShare,
            uncalibratedPriceElasticityColumn,
            targetSharesColumn,
            targetPercentageUnitsOnPromoShareColumn,
            targetPriceElasticityColumn,
            tauColumn,
            rhoColumn,
            calibratedUnitShare,
            calibratedUnitsOnPromoShare,
            calibratedUnit,
            calibratedPriceElasticityColumn,
            calibratedPercentageUnitsOnPromoShareColumn,
            velocityColumn,
            precisionIndexColumn,
            precisionDifferenceColumn,
            promoSplitFitIndexColumn
        };

        const newViewColumns = {
            uncalibratedUnitShare,
            uncalibratedPriceElasticityColumn,
            correlationColumn,
            weightColumn,
            tauColumn,
            rhoColumn,
            targetSharesColumn,
            targetPercentageUnitsOnPromoShareColumn,
            targetPriceElasticityColumn,
            calibratedUnitShare,
            calibratedUnitsOnPromoShare,
            calibratedUnit,
            calibratedPriceElasticityColumn,
            calibratedPercentageUnitsOnPromoShareColumn,
            velocityColumn,
            precisionIndexColumn,
            precisionDifferenceColumn,
            promoSplitFitIndexColumn
        };

        if (this.selectedCalibrationView === DEFAULT_STEP2_CALIBRATION_VIEW) {
            return currentViewColumns;
        } else {
            return newViewColumns;
        }
    }

    getApplicableColumnsAfterStep2(): Array<Record<any, any>> {
        this.calibratedDynamicColumnCount = 0;
        const calibratedRevenueShare = {
            name: 'revenueShare',
            displayName: 'Revenue <br> Shares',
            type: 'text',
            data: 'calibratedRevenueShare',
            readOnly: true,
            className: 'htRight',
            width: 70,
            renderer: this.cellRenderers.revenueShareRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedRevenue = {
            name: 'revenue',
            displayName: 'Revenue',
            type: 'text',
            data: 'calibratedRevenue',
            readOnly: true,
            className: 'htRight',
            width: 120,
            renderer: this.cellRenderers.revenueRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedEqVolumeShare = {
            name: 'equivalizedVolumeShare',
            displayName: 'EQV <br> Shares',
            type: 'text',
            data: 'calibratedEqVolumeShare',
            readOnly: true,
            className: 'htRight',
            width: 60,
            renderer: this.cellRenderers.equivalizedVolumeShareRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedEqVolume = {
            name: 'equivalizedVolume',
            displayName: 'EQV',
            type: 'text',
            data: 'calibratedEqVolume',
            readOnly: true,
            className: 'htRight',
            width: 120,
            renderer: this.cellRenderers.equivalizedVolumeRendererForCalibration.bind(this.cellRenderers)
        };

        const calibratedPsShare = {
            name: 'psShare',
            type: 'text',
            data: 'calibratedPsShare',
            readOnly: true,
            className: 'htRight',
            displayName: 'Preference <br> Share',
            width: 80,
            renderer: this.cellRenderers.preferenceShareRendererForCalibration.bind(this.cellRenderers)
        };

        const calibrateInputColumns = this.setupCalibrateAfterStep2Columns();
        const conditionsColumns = [];

        if (this.metaData.hasEqVolume) {
            this.calibratedDynamicColumnCount = this.calibratedDynamicColumnCount + 2;
            conditionsColumns.push(calibratedEqVolumeShare);
            conditionsColumns.push(calibratedEqVolume);
        }

        if (this.metaData.hasRevenue) {
            this.calibratedDynamicColumnCount = this.calibratedDynamicColumnCount + 2;
            conditionsColumns.push(calibratedRevenueShare);
            conditionsColumns.push(calibratedRevenue);
        }

        if (this.metaData.hasPrefShare) {
            this.calibratedDynamicColumnCount = this.calibratedDynamicColumnCount + 1;
            conditionsColumns.push(calibratedPsShare);
        }

        let currentViewColumns;
        let newViewColumns;
        if(this.checkPercentageUnitsOnPromoShareVisibility()) {
            currentViewColumns = this.metaData.hasPrice ? [calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.uncalibratedPriceElasticityColumn,
                calibrateInputColumns.targetSharesColumn, calibrateInputColumns.targetPriceElasticityColumn, calibrateInputColumns.targetPercentageUnitsOnPromoShareColumn, calibrateInputColumns.tauColumn,
                calibrateInputColumns.rhoColumn,calibrateInputColumns.calibratedUnitShare,calibrateInputColumns. calibratedUnitsOnPromoShare,calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([calibrateInputColumns.calibratedPriceElasticityColumn,
                calibrateInputColumns.calibratedPercentageUnitsOnPromoShareColumn, calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn, calibrateInputColumns.promoSplitFitIndexColumn]) :
                [calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.targetSharesColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.calibratedUnitShare, calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([
                calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn]);

            newViewColumns = this.metaData.hasPrice ? [
                calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.uncalibratedPriceElasticityColumn,
                calibrateInputColumns.correlationColumn, calibrateInputColumns.weightColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.rhoColumn, calibrateInputColumns.targetSharesColumn,
                calibrateInputColumns.targetPriceElasticityColumn, calibrateInputColumns.targetPercentageUnitsOnPromoShareColumn, calibrateInputColumns.calibratedUnitShare,calibrateInputColumns.calibratedUnitsOnPromoShare, calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([calibrateInputColumns.calibratedPriceElasticityColumn,
                calibrateInputColumns.calibratedPercentageUnitsOnPromoShareColumn,calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn, calibrateInputColumns.promoSplitFitIndexColumn]) : [
                calibrateInputColumns.calibratedUnitsOnPromoShare ,calibrateInputColumns.uncalibratedUnitShare,
                calibrateInputColumns.correlationColumn, calibrateInputColumns.weightColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.targetSharesColumn,
                calibrateInputColumns.calibratedUnitShare, calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn]);
        }
        else{
            currentViewColumns = this.metaData.hasPrice ? [calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.uncalibratedPriceElasticityColumn,
                calibrateInputColumns.targetSharesColumn, calibrateInputColumns.targetPriceElasticityColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.rhoColumn,
                calibrateInputColumns.calibratedUnitShare,calibrateInputColumns. calibratedUnitsOnPromoShare,calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([calibrateInputColumns.calibratedPriceElasticityColumn,
                calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn]) :
                [calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.targetSharesColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.calibratedUnitShare, calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([
                calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn]);

            newViewColumns = this.metaData.hasPrice ? [
                calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.uncalibratedPriceElasticityColumn,
                calibrateInputColumns.correlationColumn, calibrateInputColumns.weightColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.rhoColumn, calibrateInputColumns.targetSharesColumn,
                calibrateInputColumns.targetPriceElasticityColumn, calibrateInputColumns.calibratedUnitShare,calibrateInputColumns.calibratedUnitsOnPromoShare, calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([calibrateInputColumns.calibratedPriceElasticityColumn,
                calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn]) : [
                calibrateInputColumns.calibratedUnitsOnPromoShare ,calibrateInputColumns.uncalibratedUnitShare,
                calibrateInputColumns.correlationColumn, calibrateInputColumns.weightColumn, calibrateInputColumns.tauColumn, calibrateInputColumns.targetSharesColumn,
                calibrateInputColumns.calibratedUnitShare, calibrateInputColumns.calibratedUnit].concat(conditionsColumns).concat([calibrateInputColumns.velocityColumn, calibrateInputColumns.precisionIndexColumn, calibrateInputColumns.precisionDifferenceColumn]);
        }
        if(this.selectedCalibrationView !== DEFAULT_STEP2_CALIBRATION_VIEW && this.scenarioResultsTable){
            newViewColumns = this.metaData.hasPrice ? [
                calibrateInputColumns.correlationColumn, calibrateInputColumns.weightColumn, calibrateInputColumns.tauColumn,calibrateInputColumns.uncalibratedUnitShare, calibrateInputColumns.uncalibratedPriceElasticityColumn] : [
                calibrateInputColumns.correlationColumn, calibrateInputColumns.weightColumn, calibrateInputColumns.tauColumn,calibrateInputColumns.uncalibratedUnitShare];
        }
        const columns = this.selectedCalibrationView === DEFAULT_STEP2_CALIBRATION_VIEW ? currentViewColumns : newViewColumns;
        columns.forEach((column) => {
            let configurationColumn: outputConfiguration;
            if(this.metaData.outputConfigurations){
                configurationColumn = this.metaData.outputConfigurations[this.getMappedConfigurationColumnName(column.name)];
            }
            
            if(column.name==='calibratedUnitsOnPromoShare'){
                if(this.metaData.hasCoeffsFnd){
                    const ColHeaders = column.displayName;
                    this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${ColHeaders}</span>`);
                    this.tableSettings.columns.push(column);
                }
            }else if(configurationColumn){
                if(configurationColumn.showInSimulator){
                    const colViewHeaders = column.displayName;
                    this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${colViewHeaders}</span>`);
                    this.tableSettings.columns.push(column);
                }
            }            
            else{
                const colViewHeaders = column.displayName;
                this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${colViewHeaders}</span>`);
                this.tableSettings.columns.push(column);
            }
        });
        return columns;
    }

    getMappedConfigurationColumnName(columnName){
        switch(columnName){
            case 'calibratedUnitShare' : return 'unitsShare';
            case 'unit': return 'units';
            default : return columnName;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.webMessageService.close(`${CALIBRATION_MSG_GROUP}-${this.modelRunId}`);
        clearInterval(this.step2CompletedPercentageIntervalId);
    }

    columnsOptions: any[] = [
        {name: 'Item Characteristics', checked: false, hide: false},
        {name: 'Inputs', checked: false, hide: true},
        {name: 'Scenario', checked: true, hide: false},
    ]

    volumeTypeColumnOptions = [
        {name: 'Pack Rate Adjuster', checked: false},
        {name: 'Price Elasticity Benchmark', checked: false},
    ]

    returnToScenarios(): void {
        this.navigateAwayFromCalibration('scenarios', this.route.parent);
    }

    constructHotTableData(): void {
        if (!this.dataSet) {
            this.dataSet = [];
            this.newResultDataSet = [];
            this.scenarioSkuConfigs[this.calibrationScenario.id].forEach(s => {
                this.dataSet.push(Object.assign({}, s));
            });
        }
        this.dataSet.forEach((sku) => {
            const calibrationSku = this.isCalibrationInitiated ? this.calibration.skus.find((it) => it.skuId === sku.skuId) : null;
            let scenario = null;
            if (calibrationSku && calibrationSku.scenarioId) {
                scenario = this.scenarios.find((it) => it.id === calibrationSku.scenarioId);
            } else {
                scenario = this.scenarios.find((it) => it.name === sku.scenarioName);
            }
            const selectedDiscretePromo = sku.promotions && sku.promotions.length ? sku.promotions[(sku.promoDropdownIdx - 1)]
                : null;
            sku.promoPriceText = selectedDiscretePromo ? selectedDiscretePromo.text :
                sku.promotions && sku.promotions.length ? 'Special Price' : null;
            sku.specialPromoPrice = sku.specialPromoPrice ? sku.specialPromoPrice :
                sku.reportingBasePromo ? parseFloat(sku.reportingBasePromo) : sku.promoPriceMin;
            sku['scenarioName'] = sku['scenarioName'] ? sku['scenarioName'] : sku.isSelected ? this.appConstantsService.CALIBRATION_SCENARIO_NAME : scenario ? scenario.name : 'Select Scenario';
            sku.scenarioId = sku.isSelected ? this.calibrationScenario.id : scenario ? scenario.id : null;
            sku['isNewItem'] = sku['isNewItem'] ? sku['isNewItem'] : sku['scenarioName'] !== this.appConstantsService.CALIBRATION_SCENARIO_NAME;
            if (sku['isNewItem']) {
                sku.isSelected = false;
            }
            sku['dropdownScenarios'] = [];
            sku['dropdownScenarios'].push({name: 'Select Scenario'});
            sku['step2Ended'] = this.calibration.step2Ended;
            sku['step2StartedBy'] = this.calibration.step2StartedBy;
            if (!sku.isSelected) {
                const allSkuConfigs = this.scenarioSkuConfigs;
                Object.keys(allSkuConfigs).forEach((key) => {
                    if (key !== this.calibrationScenario.id && allSkuConfigs[key].find((s) => {
                        return s.skuId === sku.skuId && s.isSelected;
                    })) {
                        sku['dropdownScenarios'].push(this.scenarioService.getScenario(key));
                    }
                });
            }
            sku['step'] = this.isCalibrationInitiated && this.calibration.status ? this.calibration.status : 'Not Started';
            if (calibrationSku) {
                calibrationSku.scenarioId = sku.isSelected ? this.calibrationScenario.id : scenario ? scenario.id : null;
            }
            if (this.columnsOptions.find((c) => {
                return c.name === 'Item Characteristics' && c.checked;
            })) {
                this.addGroupData(sku);
            }
            this.setSelectedScenarioSpecificInputs(sku);
            if (calibrationSku) {
                sku['uncalibratedUnitShare'] = calibrationSku.uncalibrated ? calibrationSku.uncalibrated.unitShare : null;
                sku['uncalibratedEqVolumeShare'] = calibrationSku.uncalibrated ? calibrationSku.uncalibrated.equivalizedVolumeShare : null;
                sku['targetUnitShare'] = sku.viewType || sku['targetUnitShare'] ? sku['targetUnitShare'] : (calibrationSku.target && calibrationSku.target.unitShare > 0) ? calibrationSku.target.unitShare : 0;
                sku['targetPercentageUnitsOnPromoShare'] = sku.viewType || sku['targetPercentageUnitsOnPromoShare'] ? sku['targetPercentageUnitsOnPromoShare'] : (calibrationSku.target && calibrationSku.target.percentageUnitsOnPromoShare >= 0) ? calibrationSku.target.percentageUnitsOnPromoShare : null;
            }
            if (this.isCalibrationInitiated && this.calibration.statusCode >= this.appConstantsService.CALIBRATION_STEP2_COMPLETED) {
                if(this.scenarioResultsTable){
                    this.setSelectedScenarioSpecificResults(sku);
                }
                //tau bounds
                //uncalibrated
                sku['minTau'] = this.generalSetting.minTau ? this.generalSetting.minTau : -4;
                sku['maxTau'] = this.generalSetting.maxTau ? this.generalSetting.maxTau : 4;
                sku['uncalibratedUnitShare'] = calibrationSku.uncalibrated ? calibrationSku.uncalibrated.unitShare : null;
                sku['uncalibratedEqVolumeShare'] = calibrationSku.uncalibrated ? calibrationSku.uncalibrated.equivalizedVolumeShare : null;
                sku['uncalibratedPriceElasticity'] = calibrationSku.uncalibrated ? calibrationSku.uncalibrated.priceElasticity : null;
                sku['hasCoRelatedSkus'] = calibrationSku.correlatedSkus && calibrationSku.correlatedSkus.length > 0;
                //target
                if (calibrationSku.target) {
                    sku['targetUnitShare'] = sku.viewType ? sku['targetUnitShare'] : (calibrationSku.target && calibrationSku.target.unitShare > 0) ? calibrationSku.target.unitShare : 0;
                    sku['targetPercentageUnitsOnPromoShare'] = sku.viewType ? sku['targetPercentageUnitsOnPromoShare'] : (calibrationSku.target && calibrationSku.target.percentageUnitsOnPromoShare >= 0) ? calibrationSku.target.percentageUnitsOnPromoShare : null;
                    sku['targetPriceElasticity'] = sku.viewType ? sku['targetPriceElasticity'] : calibrationSku.target.priceElasticity;
                    sku['tau'] = sku['viewType'] ? sku['tau'] : calibrationSku.target.tau;
                    sku['baseTargetUnitShare'] = sku['viewType'] ? sku['baseTargetUnitShare'] : calibrationSku.target.unitShare;
                    sku['baseTargetPriceElasticity'] = sku['viewType'] ? sku['baseTargetPriceElasticity'] : calibrationSku.target.priceElasticity;
                    sku['baseTau'] = sku['viewType'] ? sku['baseTau'] : calibrationSku.target.baseTau;
                    sku['baseTargetPercentageUnitsOnPromoShare'] = sku.viewType ? sku['baseTargetPercentageUnitsOnPromoShare'] : calibrationSku.target.percentageUnitsOnPromoShare;
                    sku['tauValueFromStep2'] = sku['viewType'] ? sku['tauValueFromStep2'] : calibrationSku.target.baseTauValueFromStep2;
                    sku['savedTauValue'] = sku['viewType'] ? sku['savedTauValue'] : calibrationSku.target.tau;
                }

                //calibrated
                sku['calibratedUnitShare'] = calibrationSku.calibrated ? calibrationSku.calibrated.unitShare : null;
                sku['calibratedUnit'] = calibrationSku.calibrated ? calibrationSku.calibrated.unit : null;
                sku['calibratedRevenue'] = calibrationSku.calibrated ? calibrationSku.calibrated.revenue : null;
                sku['calibratedRevenueShare'] = calibrationSku.calibrated ? calibrationSku.calibrated.revenueShare : null;
                sku['calibratedEqVolume'] = calibrationSku.calibrated ? calibrationSku.calibrated.equivalizedVolume : null;
                sku['calibratedEqVolumeShare'] = calibrationSku.calibrated ? calibrationSku.calibrated.equivalizedVolumeShare : null;
                sku['calibratedPsShare'] = calibrationSku.calibrated ? calibrationSku.calibrated.psShare : null;

                sku['calibratedPriceElasticity'] = calibrationSku.calibrated ? calibrationSku.calibrated.priceElasticity : null;
                sku['calibratedPercentageUnitsOnPromoShare'] = calibrationSku.calibrated ? calibrationSku.calibrated.percentageUnitsOnPromoShare : null;
                sku['velocity'] = sku.distribution && sku.calibratedUnitShare && sku.calibratedUnitShare > 0 ? (sku.calibratedUnitShare / sku.distribution) : 0;
                sku['precisionIndex'] = sku.targetUnitShare && sku.calibratedUnitShare && sku.targetUnitShare > 0 ? (sku.calibratedUnitShare / sku.targetUnitShare) : 0;
                sku['precisionDifference'] = sku.targetUnitShare && sku.calibratedUnitShare && sku.targetUnitShare > 0 ? (sku.calibratedUnitShare - sku.targetUnitShare) : 0;
                sku['promoSplitFitIndex'] = sku.targetPercentageUnitsOnPromoShare && sku.calibratedPercentageUnitsOnPromoShare && sku.targetPercentageUnitsOnPromoShare > 0 ? (sku.calibratedPercentageUnitsOnPromoShare / sku.targetPercentageUnitsOnPromoShare) : 0;                
                sku['correlatedSkus'] = calibrationSku.correlatedSkus;
                sku['viewType'] = this.selectedCalibrationView;
                sku['userDefinedTau'] = false;
                if(this.auditTrails){
                    const tauAuditTrails = this.auditTrails.filter((it) => { return it.skuId === sku.skuId && it.property === 'adjustedTauValue'; });
                    sku['hasTauAdjustment'] = (tauAuditTrails.length > 0) ? true : false;
                    sku['hasTauAdjustmentDescription'] = ((tauAuditTrails.length > 0) && (tauAuditTrails[tauAuditTrails.length - 1].reasonForAdjustment)) ? true : false;
                    if(this.metaData.hasPrice){
                        const rhoAuditTrails = this.auditTrails.filter((it) => { return it.skuId === sku.skuId && it.property === 'adjustedRho1Value'; });
                        sku['hasRhoAdjustment'] = (rhoAuditTrails.length > 0) ? true : false;
                        sku['hasRhoAdjustmentDescription'] = (rhoAuditTrails.length > 0) && rhoAuditTrails[rhoAuditTrails.length - 1].reasonForAdjustment ? true : false;
                    }
                }
                sku['auditTrailLogs'] = this.auditTrails.filter((it) => it.skuId === sku['skuId']);

                if(this.metaData.hasPrice){
                    sku['rho1'] = calibrationSku.target.rho1 ? calibrationSku.target.rho1 : calibrationSku.calibrated.rho1;
                    sku['baseRho1ValueFromStep2'] = calibrationSku?.calibrated?.rho1;
                    sku['rho1Saved'] = calibrationSku.target.rho1 ? calibrationSku.target.rho1 : calibrationSku.calibrated.rho1;
                    sku['minRho'] = 0.05;
                    sku['maxRho'] = 4;
                }
            }
            this.loadCorrelatedSkus();
            this.setCalibrationStatus();
            this.checkTimeOnPromoValidForActiveScenarios();
        });

        this.cloneDataset();
    }

    loadCorrelatedSkus(): void {
        const correlatedSkusHotData = [];
        this.dataSet.forEach((hotObj) => {
            correlatedSkusHotData.push(hotObj);
            if (hotObj.correlatedSkus) {
                if (hotObj.correlatedSkus.find((it) => !it.isSelected)) {
                    this.setWeightForPartiallySelectedSkus(hotObj.correlatedSkus);
                }
                this.sortCorrelatedSkusByWeightInDescendingOrder(hotObj);
                hotObj.correlatedSkus.forEach((cSku) => {
                    let skuObject = this.dataSet.find(it => it.parentSkuId === hotObj.skuId && it.skuId === cSku.skuId);
                    if (skuObject) {
                        skuObject['userDefinedTau'] = hotObj.targetUnitShare && hotObj.targetUnitShare > 0 ? true : hotObj.tau !== hotObj.tauValueFromStep2;
                        skuObject['parentSkuId'] = hotObj.skuId;
                        skuObject['isSelected'] = cSku['isSelected'] ? hotObj.targetUnitShare ? 0 : 1 : 0;
                        skuObject['weight'] = cSku['weight'];
                        skuObject['correlation'] = cSku['correlation'];
                        skuObject['version'] = cSku['correlation'];
                        skuObject['correlatedWeight'] = cSku['correlatedWeight'];
                    } else {
                        skuObject = Object.assign({}, this.dataSet.find(it => it.skuId === cSku.skuId));
                        skuObject['userDefinedTau'] = hotObj.targetUnitShare && hotObj.targetUnitShare > 0 ? true : hotObj.tau !== hotObj.tauValueFromStep2;
                        skuObject['parentSkuId'] = hotObj.skuId;
                        skuObject['isSelected'] = cSku['isSelected'] ? hotObj.targetUnitShare ? 0 : 1 : 0;
                        skuObject['weight'] = cSku['weight'];
                        skuObject['correlation'] = cSku['correlation'];
                        skuObject['version'] = cSku['correlation'];
                        skuObject['correlatedWeight'] = cSku['correlatedWeight'];
                        correlatedSkusHotData.push(skuObject);
                    }

                });
            }
        });
        this.newResultDataSet = correlatedSkusHotData;
    }

    setWeightForPartiallySelectedSkus(correlatedSkus: any) {
        const correlatedSkuOfActiveSkus = correlatedSkus.filter(it => it.isSelected);
        const rThreshold = 0;
        const sThreshold = 0.15;
        correlatedSkuOfActiveSkus.forEach((it) => {
            it['correlationFactor'] = Math.pow(it.correlation - rThreshold, 2);
        });
        const sumOfAdjustmentCorrelation = correlatedSkuOfActiveSkus.reduce((sum, corSku) => sum + (corSku.correlationFactor), 0);
        const sumOfAdjustmentCorrelationOfSelected = correlatedSkuOfActiveSkus.reduce((sumProduct, corSku) => sumProduct + (corSku.isSelected ? (corSku.correlationFactor) : 0), 0);
        const maxValue = sThreshold > sumOfAdjustmentCorrelation ? sThreshold : sumOfAdjustmentCorrelation;
        const sumOfAdjustmentWeight = sumOfAdjustmentCorrelationOfSelected / maxValue;
        const sum = maxValue * sumOfAdjustmentWeight;
        correlatedSkus.forEach((it) => {
            it['correlatedWeight'] = it.isSelected ? (it.correlationFactor / sum) : 0;
        });
    }


    /**
     * sort correlates skus by weight in descendion order
     * @param hotObj
     */
    sortCorrelatedSkusByWeightInDescendingOrder(hotObj: any): void {
        hotObj.correlatedSkus.sort((sku1, sku2) => {
            return sku2.weight - sku1.weight;
        });
    }

    /**
     * set the inputs of sku depends on scenario selected from dropdown
     * we need inputs of the selected scenario for velocity calculation even inputs is not selected in calibration page
     * @param sku
     */
    setSelectedScenarioSpecificInputs(sku: SkuConfig): void {
        if (this.isCalibrationInitiated) {
            const selectedSkuConfigs = this.scenarioSkuConfigs[sku.scenarioId];
            const selectedScenarioSku = selectedSkuConfigs.find(skuObj => skuObj.skuId === sku.skuId);
            const columns = this.tableSettings.columns;
            const inputsColumnStartIndex = this.tableSettings.inputsColumnStartIndex;
            columns.map((column, index) => {
                if (inputsColumnStartIndex !== 0 && index >= inputsColumnStartIndex && index <= this.tableSettings.inputsColumnEndIndex) {
                    sku[(column.name)] = selectedScenarioSku[(column.name)];
                }
            });
        }
    }

    /**
     * set the inputs of sku depends on scenario selected from dropdown
     * we need inputs of the selected scenario for velocity calculation even inputs is not selected in calibration page
     * @param sku
     */
    setSelectedScenarioSpecificResults(sku: SkuConfig): void {
            const selectedSkuConfigs = this.scenarioSkuConfigs[this.selectedScenarioId];
            const selectedScenarioSku = selectedSkuConfigs.find(skuObj => skuObj.skuId === sku.skuId);
            const columns = ['unit', 'unitShare', 'unitsPromoShare', 'revenue', 'revenueShare', 'profit','equivalizedVolume', 'equivalizedVolumeShare', 'psShare'];
            columns.map((column, index) => {
                    sku[(column)] = selectedScenarioSku[(column)];
                    return sku;
            });
    }

    reloadData(): void {
        this.uiBlockerService.block();
        this.setUserAdditionalColumnsSelection();
        this.tableSettings.columnHeaders = [];
        this.tableSettings.groupHeaders = [];
        this.tableSettings.columns = [];
        this.tableSettings.allowFiltersForHeaders = this.selectedCalibrationView === 'new' ? this.scenarioColumnVisible ? [3] : [] : this.scenarioColumnVisible ? [1, 2] : [1];
        this.tableSettings.groupColumnStartIndex = this.selectedCalibrationView === DEFAULT_STEP2_CALIBRATION_VIEW ? this.scenarioColumnVisible ? 3  :2 :this.scenarioColumnVisible ? 4 : 3;
        this.tableSettings.inputsColumnStartIndex = 0;
        if (this.isCalibrationInitiated && this.calibration.statusCode >= this.appConstantsService.CALIBRATION_STEP2_COMPLETED) {
            this.initializeColumns();
            this.constructColumnHeaders();
            this.getApplicableColumnsAfterStep2();
            const unCalibratedColumnChooserButton = `<span class="menu-btn padding-left-1 sif sif-column-chooser unCalibrated"></span>`;
            const targetColumnChooserButton = `<span class="menu-btn padding-left-1 sif sif-column-chooser target"></span>`;
            const calibratedColumnChooserButton = `<span class="menu-btn padding-left-1 sif sif-column-chooser calibrated"></span>`;
            const resultsColumnChooserButton = `<span class="menu-btn padding-left-1 sif sif-column-chooser results"></span>`;
            if(!this.scenarioResultsTable) {
                this.tableSettings.unCalibratedHeadersEndIndex = this.tableSettings.unCalibratedHeadersStartIndex + (this.metaData.hasPrice ? 2 : 1);
                this.tableSettings.targetHeadersStartIndex = this.tableSettings.unCalibratedHeadersEndIndex;
                if(this.checkPercentageUnitsOnPromoShareVisibility()){
                    this.tableSettings.targetHeadersEndIndex = this.tableSettings.targetHeadersStartIndex + (this.selectedCalibrationView === 'current' ? (this.metaData.hasPrice ? 5 : 2) : (this.metaData.hasPrice ? 7 : 4));
                    this.tableSettings.calibratedStartIndex = this.tableSettings.targetHeadersEndIndex;
                    this.tableSettings.calibratedEndIndex = this.tableSettings.calibratedStartIndex + (this.metaData.hasPrice ? 8 + this.calibratedDynamicColumnCount : 5 + this.calibratedDynamicColumnCount);
                }
                else{
                    this.tableSettings.targetHeadersEndIndex = this.tableSettings.targetHeadersStartIndex + (this.selectedCalibrationView === 'current' ? (this.metaData.hasPrice ? 4 : 2) : (this.metaData.hasPrice ? 6 : 4));
                    this.tableSettings.calibratedStartIndex = this.tableSettings.targetHeadersEndIndex;
                    this.tableSettings.calibratedEndIndex = this.tableSettings.calibratedStartIndex + (this.metaData.hasPrice ? 6 + this.calibratedDynamicColumnCount : 5 + this.calibratedDynamicColumnCount);
                }  

                const columnHeaders = [
                    {
                        label: `UNCALIBRATED ${unCalibratedColumnChooserButton}`,
                        colspan: this.metaData.hasPrice ? 2 : 1,
                        type: 'UNCALIBRATED',
                    },
                    {
                        label: `TARGETS & ADJUSTMENTS ${targetColumnChooserButton}`,
                        colspan: this.tableSettings.targetHeadersEndIndex - this.tableSettings.targetHeadersStartIndex,
                        type: 'TARGETS',
                    },
                    {
                        label: `CALIBRATED ${calibratedColumnChooserButton}`,
                        colspan: this.tableSettings.calibratedEndIndex - this.tableSettings.calibratedStartIndex,
                        type: 'CALIBRATED'
                    }];

                columnHeaders.forEach((h) => {
                    this.tableSettings.groupHeaders.push(h);
                });
            }else{
                this.tableSettings.targetHeadersStartIndex = this.tableSettings.unCalibratedHeadersStartIndex;
                if(this.checkPercentageUnitsOnPromoShareVisibility()){
                    this.tableSettings.targetHeadersEndIndex = this.tableSettings.targetHeadersStartIndex + (this.selectedCalibrationView === 'current' ? (this.metaData.hasPrice ? 4 : 2) : (this.metaData.hasPrice ? 6 : 4));
                }
                else{
                    this.tableSettings.targetHeadersEndIndex = this.tableSettings.targetHeadersStartIndex + (this.selectedCalibrationView === 'current' ? (this.metaData.hasPrice ? 3 : 2) : (this.metaData.hasPrice ? 5 : 4));
                }
                this.tableSettings.outPutColumnStartIndex = this.tableSettings.targetHeadersEndIndex;
                const outputColumnCount = this.constructOutputColumnHeaders(0);
                this.tableSettings.outPutColumnEndIndex = this.tableSettings.outPutColumnStartIndex+outputColumnCount;
                this.tableSettings.unCalibratedHeadersStartIndex = 0;
                const columnHeaders = [
                    {
                        label: `TARGETS & ADJUSTMENTS ${targetColumnChooserButton}`,
                        colspan: this.tableSettings.targetHeadersEndIndex - this.tableSettings.targetHeadersStartIndex,
                        type: 'TARGETS',
                    },
                    {
                        label: `RESULTS ${resultsColumnChooserButton}`,
                        colspan: outputColumnCount,
                        type: 'RESULTS',
                    }];

                columnHeaders.forEach((h) => {
                    this.tableSettings.groupHeaders.push(h);
                });
            }
            this.constructHotTableData();
        } else {
            this.initializeColumns();
            this.constructColumnHeaders();
            this.constructCalibrationStepOneColumnHeaders();
            this.constructHotTableData();
            this.checkApplyButtonVisibility();
        }
        this.checkCalibrationButtonVisibility();
        this.uiBlockerService.unblock();
    }

    constructOutputColumnHeaders(outputColumnCount): number {
        for (const key in this.conditionalColumns) {
            if (this.metaData.outputConfigurations && this.metaData.outputConfigurations[key] && this.metaData.outputConfigurations[key]['showInSimulator']) {
                const scenarioResultsHeaders = `${this.metaData.outputConfigurations[key]['displayName']}`;
                this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${scenarioResultsHeaders}</span>`);
                this.conditionalColumns[key].displayName = this.metaData.outputConfigurations[key]['displayName'];
                this.tableSettings.columns.push(this.conditionalColumns[key]);
                outputColumnCount = outputColumnCount + 1;
            }
        }
        return outputColumnCount;
    }

    checkApplyButtonVisibility(): void {
        const skuWithSelectScenario = this.dataSet.find((it) => it.scenarioName === 'Select Scenario');
        const isAvgItemPriceElasticityValid = this.calibrationService.validateAvgItemPriceElasticity(this.calibration.avgItemPriceElasticity);
        this.disableApply = skuWithSelectScenario || !isAvgItemPriceElasticityValid || this.showScenarioInvalidWarningMessage;
        this.disableApplyReason = skuWithSelectScenario ? 'Select a calibration scenario for each item' : (!isAvgItemPriceElasticityValid) ? 'Price Elasticity Benchmark must be either blank or between 0 and -7' : this.showScenarioInvalidWarningMessage?this.timeOnPromoWarningMessage:null;
    }

    /**
     * @see https://floating-point-gui.de/basic/
     * */
    checkCalibrationButtonVisibility(): void {
        const calibratedSkuWithoutTargetShare = this.dataSet.find((it) => {
            return this.scenarioService.isCalibrationScenarioName(it.scenarioName) && (!it.targetUnitShare || it.targetUnitShare === '--');
        });
        const calibratedSkuWithoutPercentageUnitsOnPromoShare = this.dataSet.find((it) => {
            return this.scenarioService.isCalibrationScenarioName(it.scenarioName) && (roundTo(it.targetPercentageUnitsOnPromoShare * 100 ,6) > 100);
        });
        if (this.calibration.statusCode >= 6) {
            let totalTargetShareOfCalibrateSkusInPercentage;
            let totalTargetShareOfCalibrateSkus = this.dataSet.reduce((total, it) => {
                const targetUnitShare = (it.isNewItem || !it.targetUnitShare || it.parentSkuId || it.targetUnitShare === '--') ? 0 : (it.targetUnitShare * 10000000000000000);
                return total + targetUnitShare;
            }, 0);
            totalTargetShareOfCalibrateSkus = totalTargetShareOfCalibrateSkus / 10000000000000000;
            totalTargetShareOfCalibrateSkusInPercentage = roundTo(totalTargetShareOfCalibrateSkus * 100,6);                     
            this.disableCalibration = ((totalTargetShareOfCalibrateSkus < 0.99999998) || (totalTargetShareOfCalibrateSkus > 1.00000002) ||
                (calibratedSkuWithoutTargetShare !== undefined && calibratedSkuWithoutTargetShare !== null));
            if(this.checkPercentageUnitsOnPromoShareVisibility()){                
                this.disableCalibrationForPromoShare = (calibratedSkuWithoutPercentageUnitsOnPromoShare !== undefined && calibratedSkuWithoutPercentageUnitsOnPromoShare !== null);
            }  
            if(this.disableCalibration){
                this.disableCalibrateReason = `Current Target Shares must be non-zero and must sum to 100%. current value: ${totalTargetShareOfCalibrateSkusInPercentage}%`;
            }
            else if(this.disableCalibrationForPromoShare){
                this.disableCalibrateReason = `Current target splits must be between 0% and 100%`;
                this.disableCalibration = this.disableCalibrationForPromoShare;
            }
            else{
                this.disableCalibrateReason = '';
            }
        } else {
            let totalTargetShareOfCalibrateSkusInPercentage;
            let totalTargetShareOfCalibrateSkus = this.dataSet.reduce((total, it) => {
                const targetUnitShare = (it.isNewItem || !it.targetUnitShare || it.targetUnitShare === '--') ? 0 : (it.targetUnitShare * 10000000000000000);
                return total + targetUnitShare;
            }, 0);
            totalTargetShareOfCalibrateSkus = totalTargetShareOfCalibrateSkus / 10000000000000000;
            totalTargetShareOfCalibrateSkusInPercentage = roundTo(totalTargetShareOfCalibrateSkus * 100,6);
            this.disableCalibration = ((totalTargetShareOfCalibrateSkus < 0.99999998) || (totalTargetShareOfCalibrateSkus > 1.00000002) ||
                (calibratedSkuWithoutTargetShare !== undefined && calibratedSkuWithoutTargetShare !== null) || this.calibrationService.step2Started(this.calibration));
            this.disableCalibration = this.calibration && this.calibration.statusCode >= this.appConstantsService.CALIBRATION_STEP2_COMPLETED ? false : this.disableCalibration;
            if(this.checkPercentageUnitsOnPromoShareVisibility()){                
                this.disableCalibrationForPromoShare = (calibratedSkuWithoutPercentageUnitsOnPromoShare !== undefined && calibratedSkuWithoutPercentageUnitsOnPromoShare !== null) || this.calibrationService.step2Started(this.calibration);
            }
            if(this.disableCalibration){
                this.disableCalibrateReason = `Current Target Shares must be non-zero and must sum to 100%. current value: ${totalTargetShareOfCalibrateSkusInPercentage}%`;
            }
            else if(this.disableCalibrationForPromoShare){
                this.disableCalibrateReason = `Current target splits must be between 0% and 100%`;
                this.disableCalibration = this.disableCalibrationForPromoShare;
            }
            else{
                this.disableCalibrateReason = '';
            }

            if (!this.disableCalibration && this.enableUploadWeeklyData && !this.calibration.weeklyDataFileClaimCheckId) {
                this.disableCalibration = true;
                this.disableCalibrateReason = 'Weekly Data file not uploaded';
            }
        }

    }

    setUserAdditionalColumnsSelection() {
        const configType = this.appConstantService.CALIBRATION_ADDITIONAL_COLUMNS_OPTIONS;
        this.calibrationConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
        this.calibrationConfigColumnChooser = this.calibrationConfigColumnChooser ? this.calibrationConfigColumnChooser : new UserConfigurations();
        this.calibrationConfigColumnChooser.configurations = this.calibrationConfigColumnChooser.configurations ? this.calibrationConfigColumnChooser.configurations : {
            hideAdditionalColumns: []

        };
        if (!this.calibrationConfigColumnChooser.configurations.hideAdditionalColumns.length) {
            this.columnsOptions.forEach(column => {
                this.calibrationConfigColumnChooser.configurations.hideAdditionalColumns.push({
                    name: column.name,
                    selected: column.checked
                });
            });
        }
        if (this.calibrationConfigColumnChooser.configurations.hideAdditionalColumns) {
            this.columnsOptions.forEach(column => {
                const matchedColumn = this.calibrationConfigColumnChooser.configurations.hideAdditionalColumns.find(c => c.name === column.name);
                if (matchedColumn) {
                    column.checked = matchedColumn.selected;
                    column.hide = column.name === 'Inputs' || column.name === 'Scenario' ? !this.isCalibrationInitiated || this.scenarioResultsTable:false;
                }
            });
        }
    }

    initializeColumns(): void {
        this.setUserAdditionalColumnsSelection();
        const continuousPromoPriceRenderer = this.cellRenderers.continuousPromoPriceRenderer.bind(this.cellRenderers);
        const promoPriceDropdownRenderer = this.cellRenderers.promoPriceDropdownRenderer.bind(this.cellRenderers);
        const isContinuousPromo = this.metaData.promo === 'continuous';
        this.visibleColumns = {
            id: {
                name: 'id',
                type: 'numeric',
                data: 'skuId',
                readOnly: true,
                className: 'htRight',
                width: 56,
                index: 1,
                displayName: 'Id',
            },
            isSelected: {
                name: 'isSelected',
                type: 'checkbox',
                data: 'isSelected',
                readOnly: false,
                className: 'htCenter',
                width: 50,
                index: 0,
                displayName: 'isSelected',
                checkedTemplate: 1,
                uncheckedTemplate: 0,
                renderer: this.cellRenderers.calibrationCheckBoxRenderer.bind(this.cellRenderers)
            },
            reportingName: {
                name: 'reportingName',
                type: 'text',
                displayName: 'reportingName',
                data: 'reportingName',
                readOnly: true,
                className: 'ellipsis htLeft',
                width: 416,
                index: 2,
                renderer: this.selectedCalibrationView === 'new' ? this.cellRenderers.calibrationReportNameRenderer.bind(this.cellRenderers) : this.cellRenderers.reportNameRenderer.bind(this.cellRenderers)
            },
            Scenario: {
                name: 'scenarioName',
                type: 'text',
                displayName: 'Scenario',
                data: 'scenarioName',
                readOnly: true,
                className: 'ellipsis htLeft',
                width: this.calibration.statusCode < 3 ? '280' : '140',
                index: 3,
                renderer: this.cellRenderers.scenarioDropdownRenderer.bind(this.cellRenderers),
                validator: scenarioNameValidator,
                handsontable: {
                    afterRenderer: this.cellRenderers.addTitleAfterRenderer.bind(this.cellRenderers),
                }
            },
        };
        this.inputColumns = {
            priceInput: {
                name: 'priceInput',
                type: 'numeric',
                data: 'priceInput',
                displayName: 'Regular Price',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.priceInputRenderer.bind(this.cellRenderers)
            },
            priceInputRange: {
                name: 'priceInputRange',
                type: 'text',
                data: 'priceInputRange',
                displayName: 'Regular Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.priceRangeRenderer.bind(this.cellRenderers)
            },
            distribution: {
                name: 'distribution',
                type: 'numeric',
                data: 'distribution',
                readOnly: true,
                className: 'htRight',
                width: 60,
                displayName: 'Distribution',
                dropdownMenu: false,
                renderer: this.cellRenderers.distributionRenderer.bind(this.cellRenderers)
            },
            promoPrice: {
                name: 'promoPrice',
                type: isContinuousPromo ? 'numeric' : 'dropdown',
                data: isContinuousPromo ? 'promoPrice' : 'promoPriceText',
                readOnly: true,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Promo Price',
                source: '',
                width: 135,
                isPromoHeader: true,
                renderer: isContinuousPromo ? continuousPromoPriceRenderer : promoPriceDropdownRenderer
            },
            promoPriceRange: {
                name: 'promoPriceRange',
                type: 'text',
                data: 'promoPriceRange',
                displayName: 'Promo Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            specialPromoPrice: {
                name: 'specialPromoPrice',
                displayName: 'Special Promo Price',
                type: 'numeric',
                data: 'specialPromoPrice',
                readOnly: true,
                className: 'htRight',
                width: 130,
                isPromoHeader: true,
                renderer: this.cellRenderers.specialPromoPriceCellRenderer.bind(this.cellRenderers),
                validator: specialPromoPriceCellValidator
            },
            promoDistribution: {
                name: 'promoDistribution',
                type: 'numeric',
                data: 'promoDistribution',
                displayName: 'Time on Promo',
                readOnly: true,
                className: 'htRight',
                width: 60,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
        };
        this.tradePromoInputColumns = {
            featurePrice: {
                name: 'featurePrice',
                type: 'numeric',
                data: 'featurePrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Feature Only Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.featurePriceRenderer.bind(this.cellRenderers),
                validator: featurePriceCellValidator
            },
            featureDistribution: {
                name: 'featureDistribution',
                type: 'numeric',
                data: 'featureDistribution',
                displayName: 'Time on Feature Only',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            displayPrice: {
                name: 'displayPrice',
                type: 'numeric',
                data: 'displayPrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Display Only Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.displayPriceRenderer.bind(this.cellRenderers),
                validator: displayPriceCellValidator
            },
            displayDistribution: {
                name: 'displayDistribution',
                type: 'numeric',
                data: 'displayDistribution',
                displayName: 'Time on Display Only',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            featureAndDisplayPrice: {
                name: 'featureAndDisplayPrice',
                type: 'numeric',
                data: 'featureAndDisplayPrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Feature & Display Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.featureAndDisplayPriceRenderer.bind(this.cellRenderers),
                validator: featureAndDisplayPriceCellValidator
            },
            featureAndDisplayDistribution: {
                name: 'featureAndDisplayDistribution',
                type: 'numeric',
                data: 'featureAndDisplayDistribution',
                displayName: 'Time on Feature and Display',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            }
        };
        this.conditionalColumns = {
            units: {
                name: 'units',
                displayName: 'Units',
                type: 'text',
                data: 'unit',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitRenderer.bind(this.cellRenderers)
            },
            unitsShare: {
                name: 'unitsShare',
                displayName: 'unitsShare',
                type: 'text',
                data: 'unitShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitShareRenderer.bind(this.cellRenderers)
            },
            unitsPromoShare: {
                name: 'unitsPromoShare',
                displayName: 'Units on Promo Share',
                type: 'text',
                data: 'unitsPromoShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitsPromoShareRenderer.bind(this.cellRenderers)
            },
            equivalizedVolume: {
                name: 'equivalizedVolume',
                displayName: 'equivalizedVolume',
                type: 'text',
                data: 'equivalizedVolume',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.equivalizedVolumeRenderer.bind(this.cellRenderers)
            },
            equivalizedVolumeShare: {
                name: 'equivalizedVolumeShare',
                displayName: 'equivalizedVolumeShare',
                type: 'text',
                data: 'equivalizedVolumeShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.equivalizedVolumeShareRenderer.bind(this.cellRenderers)
            },
            revenue: {
                name: 'revenue',
                displayName: 'revenue',
                type: 'text',
                data: 'revenue',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.revenueRenderer.bind(this.cellRenderers)
            },
            revenueShare: {
                name: 'revenueShare',
                displayName: 'revenueShare',
                type: 'text',
                data: 'revenueShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.revenueShareRenderer.bind(this.cellRenderers)
            },
            psShare: {
                name: 'psShare',
                type: 'text',
                data: 'psShare',
                readOnly: true,
                className: 'htRight',
                displayName: 'psShare',
                width: 150,
                renderer: this.cellRenderers.preferenceShareRenderer.bind(this.cellRenderers)
            },
            profit: {
                name: 'profit',
                type: 'text',
                data: 'profit',
                readOnly: true,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Profit',
                source: '',
                width: 150,
                renderer: this.cellRenderers.profitRenderer.bind(this.cellRenderers)
            },
            volume: {
                name: 'volume',
                displayName: 'volume',
                type: 'text',
                data: 'volume',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.volumeRenderer.bind(this.cellRenderers)
            },
            volumeShare: {
                name: 'volumeShare',
                displayName: 'volumeShare',
                type: 'text',
                data: 'volumeShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.volumeShareRenderer.bind(this.cellRenderers)
            },

        };
    }

    get scenarioColumnVisible() : boolean{
        return this.columnsOptions.find((c) => {
            return c.name === 'Scenario' && c.checked;
        })? true : false;
    }

    /**
     * set table column headers related to groups and inputs . It sets the input columns start index and endindex aswell
     */
    constructColumnHeaders(): void {
        let columnCount = 0;
        this.tableSettings.groupHeaders.push({
            label: `ITEMS & SCENARIOS`,
            colspan: !this.isCalibrationInitiated?3:(this.selectedCalibrationView === 'current' ? (this.scenarioColumnVisible ? 3 : 2 ): (this. scenarioColumnVisible ? 4 : 3)),
            type: 'SCENARIO'
        });
        Object.keys(this.visibleColumns).forEach((key) => {
            if ((key !== 'isSelected' || this.selectedCalibrationView === 'new')) {
                if(key==='Scenario' && this.calibration.statusCode!==0 ){
                    if (this.scenarioColumnVisible) {
                        if (this.metaData.inputConfigurations[key]) {
                            this.tableSettings.columnHeaders.push(this.metaData.inputConfigurations[key]['displayName']);
                        } else {
                            this.tableSettings.columnHeaders.push(this.visibleColumns[key]['displayName']);
                        }
                        columnCount = columnCount + 1;
                        this.tableSettings.columns.push(this.visibleColumns[key]);
                    }
                }else {
                    if (this.metaData.inputConfigurations[key]) {
                        this.tableSettings.columnHeaders.push(this.metaData.inputConfigurations[key]['displayName']);
                    } else {
                        this.tableSettings.columnHeaders.push(this.visibleColumns[key]['displayName']);
                    }
                    columnCount = columnCount + 1;
                    this.tableSettings.columns.push(this.visibleColumns[key]);
                }

            }
        });
        let groupColumnCount = 0;
        this.tableSettings.groupColumnStartIndex =  this.selectedCalibrationView === DEFAULT_STEP2_CALIBRATION_VIEW ? this.scenarioColumnVisible ? 3 :2 : this.scenarioColumnVisible ? 4:3;
        if (this.columnsOptions.find((c) => {
            return c.name === 'Item Characteristics' && c.checked;
        })) {
            this.addGroupColumns();
            groupColumnCount = this.skuGroups.length;
        }
        if (this.columnsOptions.find((c) => {
            return c.name === 'Inputs' && c.checked && !c.hide;
        })) {
            let inputsColumnCount = 0;
            inputsColumnCount = this.constructPriceInputColumnHeaders(inputsColumnCount);
            inputsColumnCount = this.constructPromoInputColumnHeaders(inputsColumnCount);
            this.tableSettings.inputsColumnStartIndex = this.tableSettings.groupColumnStartIndex
                + groupColumnCount;
            if (inputsColumnCount > 0) {
                const inputColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser inputs"></span>`;
                this.tableSettings.groupHeaders.push({
                    label: `INPUTS ${inputColumnChooserButton}`,
                    type: 'INPUTS',
                    colspan: inputsColumnCount
                });
            }
            this.tableSettings.inputsColumnEndIndex = inputsColumnCount + this.tableSettings.inputsColumnStartIndex;
        } else {
            this.tableSettings.inputsColumnEndIndex = 0;
            this.tableSettings.inputsColumnStartIndex = 0;
        }
        this.tableSettings.groupColumnEndIndex = groupColumnCount ? this.tableSettings.groupColumnStartIndex + groupColumnCount : 0 ;
        this.tableSettings.unCalibratedHeadersStartIndex =  this.tableSettings.inputsColumnEndIndex ? this.tableSettings.inputsColumnEndIndex : this.tableSettings.groupColumnEndIndex ? this.tableSettings.groupColumnEndIndex : this.tableSettings.groupColumnStartIndex ;
    }

    constructPriceInputColumnHeaders(inputsColumnCount): number {
        for (const key in this.inputColumns) {
            if (!this.inputColumns[key].isPromoHeader && (this.metaData.inputConfigurations[key]
                && this.metaData.inputConfigurations[key]['showInSimulator'])) {
                if (key === 'priceInput' && this.metaData.hasPrice) {
                    this.inputColumns[key].validator = priceCellValidator;
                } else if (key === 'priceInput') {
                    this.inputColumns.priceInput.readOnly = true;
                }
                const inputColumnHeader = `${this.metaData.inputConfigurations[key]['displayName']}`;
                this.tableSettings.columnHeaders.push(`<span class='headers-align-right right-align-headers'>${inputColumnHeader}</span>`);
                this.inputColumns[key].displayName = this.metaData.inputConfigurations[key]['displayName'];
                this.tableSettings.columns.push(this.inputColumns[key]);
                inputsColumnCount = inputsColumnCount + 1;
            }
        }
        return inputsColumnCount;
    }

    constructPromoInputColumnHeaders(inputsColumnCount): number {
        if (this.metaData && this.metaData.promo !== 'none') {
            let requiredHeaders = ['promoPrice', 'specialPromoPrice', 'promoPriceRange', 'promoDistribution'];
            if(this.metaData.hasCoeffsFnd) {
                const tradePromoColumns = ['featurePrice', 'featureDistribution', 'displayPrice',  'displayDistribution',
                    'featureAndDisplayPrice', 'featureAndDisplayDistribution'];
                requiredHeaders = requiredHeaders.concat(tradePromoColumns);
            }
            requiredHeaders.forEach(header => {
                if (this.metaData.inputConfigurations[header] && this.metaData.inputConfigurations[header]['showInSimulator']) {
                    const promoInputHeader = `${this.metaData.inputConfigurations[header]['displayName']}`;
                    this.tableSettings.columnHeaders.push(`<span class='headers-align-right right-align-headers'>${promoInputHeader}</span>`);
                    if(this.inputColumns[header]){
                        this.inputColumns[header].displayName = this.metaData.inputConfigurations[header]['displayName'];
                        this.tableSettings.columns.push(this.inputColumns[header]);
                    }
                    if(this.tradePromoInputColumns[header]){
                        this.tradePromoInputColumns[header].displayName = this.metaData.inputConfigurations[header]['displayName'];
                        this.tableSettings.columns.push(this.tradePromoInputColumns[header]);
                    }

                    inputsColumnCount = inputsColumnCount + 1;
                }
            });
        }
        return inputsColumnCount;
    }

    constructCalibrationStepOneColumnHeaders(): any {
        const isTargetEqVolume = this.calibrationService.isTargetEqVolume(this.calibration);
        const shareTargetLabel = this.calibrationService.getShareTargetLabel(this.calibration);
        if (!this.scenarioResultsTable && this.isCalibrationInitiated && ([this.appConstantService.CALIBRATION_STEP1_COMPLETED, this.appConstantService.CALIBRATION_STEP2_STARTED, this.appConstantService.CALIBRATION_STEP2_FAILED].indexOf(this.calibration.statusCode) !== -1)) {
            const unCalibratedColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser unCalibrated"></span>`;
            this.tableSettings.groupHeaders.push({
                label: `UNCALIBRATED ${unCalibratedColumnChooserButton}`,
                type: 'UNCALIBRATED',
                colspan: 1
            });
            const colObject = {
                name: 'uncalibratedUnitShare',
                type: 'numeric',
                displayName: shareTargetLabel,
                data: isTargetEqVolume ? 'uncalibratedEqVolumeShare' : 'uncalibratedUnitShare',
                readOnly: true,
                className: 'htRight',
                width: 140,
                renderer: this.cellRenderers.unitShareRenderer.bind(this.cellRenderers)
            };
            this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${shareTargetLabel}</span>`);
            this.tableSettings.columns.push(colObject);
            this.tableSettings.unCalibratedHeadersEndIndex = this.tableSettings.unCalibratedHeadersStartIndex+1;
            this.tableSettings.targetHeadersStartIndex = this.tableSettings.unCalibratedHeadersEndIndex;
            const targetColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser target"></span>`;
            this.tableSettings.groupHeaders.push({
                label: `TARGETS & ADJUSTMENTS ${targetColumnChooserButton}`,
                type: 'TARGETS',
                colspan: this.checkPercentageUnitsOnPromoShareVisibility() ? 2 : 1
            });
            const colObject1 = {
                name: 'targetUnitShare',
                displayName: 'Target <br> Share',
                type: 'text',
                data: 'targetUnitShare',
                readOnly: false,
                className: 'htRight',
                width: 210,
                renderer: this.cellRenderers.targetShareRenderer.bind(this.cellRenderers)
            };
            const colObject2 = {
                name: 'targetPercentageUnitsOnPromoShare',
                displayName: '% Units on<br> Promo Share',
                type: 'text',
                data: 'targetPercentageUnitsOnPromoShare',
                readOnly: false,
                className: 'htRight',
                width: 210,
                renderer: this.cellRenderers.targetPercentageUnitsOnPromoShareRenderer.bind(this.cellRenderers)
            };
            const targetColHeader = 'Targets';
            const targetColHeader1 = '% Units on<br> Promo Share';
            this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${targetColHeader}</span>`);
            this.tableSettings.columns.push(colObject1);
            if(this.checkPercentageUnitsOnPromoShareVisibility()){
                this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${targetColHeader1}</span>`);
                this.tableSettings.columns.push(colObject2);
            }
            this.tableSettings.targetHeadersEndIndex = this.checkPercentageUnitsOnPromoShareVisibility() ?
                this.tableSettings.targetHeadersStartIndex + 2 : this.tableSettings.targetHeadersStartIndex + 1;
        }
    }

    addGroupColumns(): void {
        let colIndex = this.selectedCalibrationView === 'new' ? this.scenarioColumnVisible ? 3 : 2 : this.scenarioColumnVisible ? 2 : 1;
        if (this.skuGroups && this.skuGroups.length) {
            this.skuGroups.forEach(group => {
                colIndex = colIndex + 1;
                const groupName = group.displayName;
                const colObject = {
                    name: group.name,
                    type: 'text',
                    data: groupName,
                    readOnly: true,
                    className: 'ellipsis htLeft',
                    width: 220,
                    renderer: this.cellRenderers.toolTipRenderer.bind(this.cellRenderers)
                };
                this.tableSettings.columnHeaders.push(groupName);
                this.tableSettings.columns.push(colObject);
                this.tableSettings.allowFiltersForHeaders.push(colIndex);
            });
            if (this.skuGroups.length) {
                const groupColumnChooserButton = `<span class='menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser groups'></span>`;
                this.tableSettings.groupHeaders.push({
                    label: `ITEM CHARACTERISTICS ${groupColumnChooserButton}`,
                    type: 'GROUPS',
                    colspan: this.skuGroups.length
                });
            } else {
                this.tableSettings.allowFiltersForHeaders.push(2);
            }
        }
    }

    addGroupData(hotObject): void {
        const groups = hotObject.groups;
        this.skuGroups.forEach(group => {
            const matchedSkuGroup = groups.find((skuGroup) => {
                return skuGroup.skuGroupId === group.skuGroupId;
            });
            const itemGroupings = group.itemGroupings;
            const itemGrouping = itemGroupings.find(i => {
                return matchedSkuGroup && i.itemGroupingId === matchedSkuGroup.itemGroupingId;
            });
            /**
             * NOTE: this should not happen in real case scenario, we had to add this for our test cases
             * where the suGroup data do not match with the groups in sku config
             */
            hotObject[group.displayName] = itemGrouping ? itemGrouping.displayName : 'All Other';
        });
    }

    shareIndividualCheckedList(item: any): void {
        this.columnsOptions.find((c) => {
            return c.name === item.name;
        }).checked = item.checked;
       const c = this.calibrationConfigColumnChooser.configurations.hideAdditionalColumns.find(c => {
            return c.name === item.name;
        });
       if(c){
           c.selected = item.checked;
       }else{
           this.calibrationConfigColumnChooser.configurations.hideAdditionalColumns.push(item);
       }
        if (this.calibrationConfigColumnChooser.id) {
            this.userConfigurationsService.updateUserConfiguration(this.calibrationConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.userConfigurations = this.userConfigurationsService.getUserConfigurationsByType(this.appConstantsService.CALIBRATION_ADDITIONAL_COLUMNS_OPTIONS);
                this.reloadData();
                this.reloadCalibrationConfigTable();
            });
        } else {
            this.calibrationConfigColumnChooser.configType = this.appConstantService.CALIBRATION_ADDITIONAL_COLUMNS_OPTIONS;
            this.calibrationConfigColumnChooser.projectId = this.metaData.projectId;
            this.calibrationConfigColumnChooser.modelRunId = this.metaData.modelRunId;
            this.userConfigurationsService.createNewUserConfiguration(this.calibrationConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.userConfigurations = this.userConfigurationsService.getUserConfigurationsByType(this.appConstantsService.CALIBRATION_ADDITIONAL_COLUMNS_OPTIONS);
                this.reloadData();
                this.reloadCalibrationConfigTable();
            });
        }
    }

    calibrateStep1(): void {
        const simulatedScenarios = this.scenarios;
        const sourceData = this.dataSet;
        const skuToScenarioMapping = sourceData.map((it) => {
            let selectedScenario = null;
            if (it.scenarioName === 'Calibration') {
                selectedScenario = this.calibrationScenario;
            } else {
                selectedScenario = simulatedScenarios.find((scenario) => scenario.name === it.scenarioName);
            }
            return {
                skuId: it.skuId,
                scenarioId: selectedScenario ? selectedScenario.id : null
            };
        });
        this.uiBlockerService.block();
        this.calibrationService.calibrateStep1(this.calibration, this.calibration.avgItemPriceElasticity, skuToScenarioMapping, this.getSelectedSegments()).subscribe((calibration) => {
            this.snackBarService.openSuccessSnackBar('Changes applied successfully.');
            this.onCalibrationChange(calibration);
            this.uiBlockerService.unblock();
            this.dataChanged = false;
        }, () => {
            this.snackBarService.openErrorSnackBar('Applying calibration changes failed.');
            this.uiBlockerService.unblock();
        });
    }

    onCalibrationChange(calibration: Calibration) {
        this.dataSet = null;
        this.calibration = calibration;
        this.auditTrialService.getAuditTrails(this.projectId, this.modelRunId).subscribe((auditTrails) => {
            this.auditTrails = auditTrails.filter((it) => it.property === 'adjustedTauValue' || it.property === 'adjustedRho1Value');
            this.reloadData();
            this.reloadCalibrationConfigTable();
            this.checkApplyButtonVisibility();
            this.checkCalibrationButtonVisibility();
            this.toggleDisableCalibrationBenchmark();
            this.setCalibrationStatus();
            this.refreshCalibrationLockState();
            this.setupVolumeTypeOptions();
        });
    }

    setupVolumeTypeOptions(): void {
        this.volumeTypeOptions = this.calibrationService.setupVolumeTypeOptions(this.calibration);
        this.selectedVolumeTypeOption = this.volumeTypeOptions.find(it => it.selected);
    }

    onCreateCalibrate(): void {
        this.warnMessage('Please wait for the calibration process to complete. You will be emailed when it is finished and can return here to view your results.');
    }

    warnMessage(message: string): void {
        this.showProgress = true;
        this.exportWarnMessage = message;
    }

    calibrateStep2WithConfirmation(type: string): void {
        let promise;
        if (type == 'CALIBRATE') {
            if (this.getIsFilterChanged()) {
                promise = this.warningDialog(this.warningModalMessages.calibrateFiltersChanngedHeader, this.warningModalMessages.clickOnFiltersChanngedCalibrate, 'CALIBRATE');
            }
            else {
                this.calibrateStep2();
            }

        } else if (type == 'RECALIBRATE') {
            if (this.getIsFilterChanged()) {
                promise = this.warningDialog(this.warningModalMessages.reCalibrateFiltersChanngedHeader, this.warningModalMessages.clickOnFiltersChanngedRecalibrate, 'RECALIBRATE');
            }
            else {
                promise = this.warningDialog(this.warningModalMessages.reCalibrateHeader, this.warningModalMessages.clickOnRecalibrate, 'RECALIBRATE');
            }
        } else {
            promise = this.warningDialog(this.warningModalMessages.calibrateNewHeader, this.warningModalMessages.clickOnCalibrateNew, 'CALIBRATE NEW');
        }
        if (promise) {
            promise.then((response: boolean) => {
                if (response) { this.calibrateStep2(); }
            });
        }
    }

    calibrateStep2(): void {
        const sourceData = this.dataSet;
        const skuToScenarioMapping = sourceData.filter(it => !it.parentSkuId).map((it) => {
            return {
                skuId: it.skuId,
                targetUnitShare: it.targetUnitShare ? it.targetUnitShare : it.baseTargetUnitShare,
                targetPriceElasticity: it.targetPriceElasticity ? it.targetPriceElasticity : it.baseTargetPriceElasticity,
                targetPercentageUnitsOnPromoShare: this.checkPercentageUnitsOnPromoShareVisibility() ? it.targetPercentageUnitsOnPromoShare ? it.targetPercentageUnitsOnPromoShare : it.baseTargetPercentageUnitsOnPromoShare : null
            };
        });
        this.uiBlockerService.block();
        this.saveFilters();
        this.calibrationService.calibrateStep2(this.calibration, skuToScenarioMapping, this.selectedCalibrationView,this.getSelectedSegments()).subscribe((calibration) => {
            this.snackBarService.openSuccessSnackBar('Calibration started successfully.');
            this.auditTrails = [];
            this.onCalibrationChange(calibration);
            this.uiBlockerService.unblock();
            this.dataChanged = false;
            this.isTargetSharesUpdated = false;
        }, () => {
            this.snackBarService.openErrorSnackBar('Starting calibration failed.');
            this.uiBlockerService.unblock();
        });
    }

    tableErrorEventHandler(errorMessage): void {
        if (errorMessage.nonFormErrorMessages && errorMessage.nonFormErrorMessages.length) {
            this.disableApply = true;
            this.disableCalibration = true;
            this.disableSave = true;
        }else{
            this.disableApply = false;
            this.disableCalibration = false;
            this.disableSave = false;
            this.checkApplyButtonVisibility();
        }
    }

    tableCurrentResultsErrorEventHandler(errorMessage): void{
        if (errorMessage.nonFormErrorMessages && errorMessage.nonFormErrorMessages.length) {
            this.disableSave = true;
            this.disableCalibration = true;
        }else{
            this.disableSave = false;
            this.checkCalibrationButtonVisibility();
        }
    }

    tableNewResultsErrorEventHandler(errorMessage): void {
        if (errorMessage.nonFormErrorMessages && errorMessage.nonFormErrorMessages.length) {
            this.disableCalibration = true;
            this.disableSave = true;
        }else{
            this.disableCalibration = false;
            this.disableSave = false;
        }
    }

    tableDataChangeEventHandler(updatedTableData): void {
        if(!this.clonedDataSet)
        {
            this.cloneDataset();
        }
        this.isTargetSharesUpdated= this.checkIfTagetSharesUpdated(updatedTableData,this.clonedDataSet);
        this.dataSet = updatedTableData;
        this.checkTimeOnPromoValidForActiveScenarios();
        this.checkApplyButtonVisibility();
        if(this.selectedCalibrationView == 'new' && this.checkPercentageUnitsOnPromoShareVisibility()){
            this.checkCalibrationButtonVisibilityForNewItemsView();
        }
        else{
             this.checkCalibrationButtonVisibility();
        }      
        this.setCalibrationStatus();
        this.dataChanged = true;
    }

    checkIfTagetSharesUpdated(updatedTableData,originaltableData){
        const returnValue = updatedTableData.some(sku=>{
            const originalSku = originaltableData.find(s=>s.skuId==sku.skuId);
            let isDifferent= originalSku.targetUnitShare != sku.targetUnitShare || originalSku.targetPriceElasticity != sku.targetPriceElasticity || originalSku.targetPercentageUnitsOnPromoShare != sku.targetPercentageUnitsOnPromoShare;
            const keys=Object.keys(sku).filter(k=>k.toLowerCase().includes('split'));
            if(!isDifferent && keys && keys.length>0){
                keys.forEach(key=>{
                    isDifferent = isDifferent || originalSku[key] != sku[key];
                });
            }
            return isDifferent;
        });
        return returnValue;
    }

    /**
     * Sends a signal to calibration config table component to re-render.
     * NOTE: Make sure to set all the data that is being passed down to the component first before calling this method.
     * */
    reloadCalibrationConfigTable(): void {
        setTimeout(() => {
            if (this.calibration.statusCode < 3) {
                this.reloadTable.next();
            } else {
                if (this.selectedCalibrationView === DEFAULT_STEP2_CALIBRATION_VIEW) {
                    this.reloadCurrentResultsTable.next();
                } else {
                    if (this.scenarioResultsTable) {
                        this.reloadScenarioResultsTable.next();
                    } else {
                        this.reloadNewResultsTable.next();
                    }
                }
            }
        }, 0);
        this.uiBlockerService.unblock();
    }

    delete() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: 'Calibration Delete',
            body: 'Are you sure you want to clear calibration and restart? Calibration adjustments and promo adjustments will be cleared from database. If you have delivered results, the answers might change if you proceed! Please consider Exporting',
            confirmButtonLabel: 'CLEAR CALIBRATION',
            cancelButtonLabel: 'CANCEL'
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        return new Promise((resolve) => {
            dialogRef.afterClosed().subscribe(value => {
                const clearCalibration = value === 'CLEAR CALIBRATION';
                if (clearCalibration) {
                    this.uiBlockerService.block();
                    this.calibrationService.delete(this.calibration).subscribe(() => {
                        this.calibrationService.create(this.projectId, this.modelRunId).subscribe((calibration) => {
                            this.selectedCalibrationView = DEFAULT_STEP2_CALIBRATION_VIEW;
                            const currentObj = {id: 'current', displayName: 'Current', selected: false};
                            this.setCurrentType(currentObj);
                            this.onCalibrationChange(calibration);
                            this.uiBlockerService.unblock();
                        });
                    }, () => {
                        this.snackBarService.openErrorSnackBar('Clearing calibration failed.');
                        this.uiBlockerService.unblock();
                    });
                }
                resolve(clearCalibration);
            });
        });
    }

    saveCalibrationWithConfirmation(calculateElasticity=false){
        if(!this.isTargetSharesUpdated){
            if(!calculateElasticity)
                {this.saveCalibration();}
            else
                {this.saveCalibrationWithElasticity();}
        }
        else{
            const promise = this.warningDialog(this.warningModalMessages.saveCalibrationHeader, this.warningModalMessages.clickOnSaveCalibration,'SAVE',false);
            promise.then((response: boolean) => {
                if (response) {
                    if(!calculateElasticity)
                        {this.saveCalibration();}
                    else
                        {this.saveCalibrationWithElasticity();}
                }
            });
        }
    }

    saveCalibration() {
        const sourceData = this.dataSet;
        const filterCondition = this.selectedCalibrationView === 'current' ? (it: any) => !it.isNewItem : (it: any) => it.hasCoRelatedSkus;
        const payload = sourceData.filter(filterCondition).map((it) => {
            const skuData:any = {
                skuId: it.skuId,
                newTau: it.newTau ? it.newTau : it.tau,
                correlatedSkus: it.correlatedSkus ? it.correlatedSkus : null,
                baseTau: it.baseTau,
                tau: it.savedTauValue
            };
            
            if(this.metaData.hasPrice){
                skuData.rho1 = it.rho1Saved? it.rho1Saved : null,
                skuData.newRho1 = it.rho1? it.rho1 : null;
            }
            
            return skuData;
        });
        this.uiBlockerService.block();
        this.saveFilters();
        this.calibrationService.calibrateSave(this.projectId, this.modelRunId, payload, this.dataChanged).subscribe((calibration) => {
            this.onCalibrationChange(calibration);
            this.uiBlockerService.unblock();
            this.showProgress = false;
            this.dataChanged = false;
            this.isTargetSharesUpdated = false;
            this.snackBarService.openSuccessSnackBar('Adjustment(s) saved successfully');
        }, () => {
            this.snackBarService.openErrorSnackBar('Saving calibration failed.');
            this.uiBlockerService.unblock();
        });
    }

    saveCalibrationWithElasticity(){
        const sourceData = this.dataSet;
        const filterCondition = this.selectedCalibrationView === 'current' ? (it: any) => !it.isNewItem : (it: any) => it.hasCoRelatedSkus;
        const payload = sourceData.filter(filterCondition).map((it) => {
            const skuData:any = {
                skuId: it.skuId,
                newTau: it.newTau ? it.newTau : it.tau,
                correlatedSkus: it.correlatedSkus ? it.correlatedSkus : null,
                baseTau: it.baseTau,
                tau: it.savedTauValue
            };
            
            if(this.metaData.hasPrice){
                skuData.rho1 = it.rho1Saved? it.rho1Saved : null,
                skuData.newRho1 = it.rho1? it.rho1 : null;
            }
            
            return skuData;
        });
        this.uiBlockerService.block();
        this.saveFilters();
        this.calibrationService.calibrateSave(this.projectId, this.modelRunId, payload, this.dataChanged, true).subscribe((calibration) => {
            this.onCalibrationChange(calibration);
            this.uiBlockerService.unblock();
            this.showProgress = false;
            this.dataChanged = false;
            this.calculateElasticity();
            this.snackBarService.openSuccessSnackBar('Adjustment(s) saved successfully');
        }, () => {
            this.snackBarService.openErrorSnackBar('Saving calibration failed.');
            this.uiBlockerService.unblock();
        });
    }

    calculateElasticity(){
        const report: PriceElasticityReport = new PriceElasticityReport();
        report.name = "CalibrationCreatedReport";
        report.projectId = this.calibration.projectId;
        report.modelRunId = this.calibration.modelRunId;
        report.scenarioId = this.calibrationScenario.id;
        report.metric = "share";
        report.skuGroupId = -1;
        report.description = '';
        this.priceElasticityService.addReportForCalibration(report).subscribe((report: PriceElasticityReport) => {
          });
    }

    messageBarCloseEventHandler(): void {
        this.showProgress = false;
    }

    currentNewWarningModal(item: any): boolean {
        let promise;
        let moveForward = false;
        this.scenarioResultsTable = false;
        if (this.dataChanged) {
            if (this.selectedCalibrationView == 'current') {
                promise = this.warningDialog(this.warningModalMessages.exitCurrentViewHeader, this.calibration.statusCode<=3? this.warningModalMessages.clickOnCalibrateExit:this.warningModalMessages.clickOnRecalibrateExit);

            } else {
                promise = this.warningDialog(this.warningModalMessages.exitNewViewHeader, this.warningModalMessages.clickOnSaveOrCalibrate);
            }

            promise.then((response: boolean) => {
                if (response) {
                    this.setCurrentType(item);
                }
            });
        } else {
            moveForward = true;
        }

        return moveForward;
    }

    currentNewNavigateWarningModal(path: string, route: ActivatedRoute): void {
        if (this.selectedCalibrationView == 'current') {
            this.warningDialog(this.warningModalMessages.exitCurrentViewHeader, this.calibration.statusCode<=3? this.warningModalMessages.clickOnCalibrateExit:this.warningModalMessages.clickOnRecalibrateExit).then((response: boolean) => {
                this.navigate(response, path, route);
            });
        } else {
            this.warningDialog(this.warningModalMessages.exitNewViewHeader, this.warningModalMessages.clickOnSaveOrCalibrate).then((response: boolean) => {
                this.navigate(response, path, route);
            });
        }
    }

    navigate(naviage: boolean, path: string, route: ActivatedRoute): void {
        if (naviage) {
            this.router.navigate([path], {relativeTo: route});
        }
    }

    navigateAwayFromCalibration(path: string, route: ActivatedRoute): void {
        if (this.dataChanged) {
            if (!this.isCalibrationInitiated || this.calibration.statusCode < 3) {
                this.warningDialog(this.warningModalMessages.exitCalibrationHeader, this.warningModalMessages.clickOnApply).then((response: boolean) => {
                    this.navigate(response, path, route);
                });
            } else if (this.calibration.statusCode < 6) {
                this.warningDialog(this.warningModalMessages.exitCalibrationHeader, this.calibration.statusCode<=3? this.warningModalMessages.clickOnCalibrateExit:this.warningModalMessages.clickOnRecalibrateExit).then((response: boolean) => {
                    this.navigate(response, path, route);
                });
            } else {
                this.currentNewNavigateWarningModal(path, route);
            }
        } else {
            this.navigate(true, path, route);
        }
    }

    warningDialog(header: string, body: string, ConfirmButton = 'EXIT',rollbackChanges = true): Promise<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: header,
            body: body,
            confirmButtonLabel: ConfirmButton,
            cancelButtonLabel: 'CANCEL'
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        return new Promise((resolve) => {
            dialogRef.afterClosed().subscribe(value => {
                if (value === ConfirmButton) {
                    if(rollbackChanges)
                        {this.dataChanged = false;}
                    this.isTargetSharesUpdated = false;
                    if (this.navigateToUrl !== 'calibration/promotion') {
                        this.calibrationService.releaseCalibrationLock(this.calibration).subscribe(() => {
                            resolve(true);
                        });
                    }
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    applyOptions(): void {
        this.checkApplyButtonVisibility();
    }

    exportCalibrationData(): void {
        this.uiBlockerService.block();
        this.calibrationService.exportCalibrationData(this.metaData.projectId, this.metaData.modelRunId).subscribe((response) => {
            const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            const modelRun = this.modelRunService.activeModelRun;
            anchor.download = `${modelRun.displayName} Calibration Export.xlsx`;
            anchor.href = url;
            anchor.click();
            this.uiBlockerService.unblock();
        }, () => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar('Calibration export failed.');
        });
    }

    toggleCalibrationLock(): void {
        if (this.calibration.lockedBy) {
            if (this.calibration.lockedBy === this.authProxyService.user.userId) {
                this.uiBlockerService.block();
                this.calibrationService.unlock(this.projectId, this.modelRunId).subscribe((calibration) => {
                    this.onCalibrationChange(calibration);
                    this.uiBlockerService.unblock();
                });
            }
        } else {
            this.uiBlockerService.block();
            this.calibrationService.lock(this.projectId, this.modelRunId).subscribe((calibration) => {
                this.onCalibrationChange(calibration);
                this.uiBlockerService.unblock();
            });
        }
    }

    /**
     * Runs a timer so that if user is idle for more than stated time duration, it will unlock the calibration automatically.
     * */
    setCalibrationLockTimeout() {
        this.ngZone.runOutsideAngular(() => {
            if (this.userActivity) {
                clearTimeout(this.userActivity);
            }
            this.userActivity = setTimeout(() => {
                this.calibrationService.releaseCalibrationLock(this.calibration).subscribe(it => this.onCalibrationChange(it));
                if (this.navigateToUrl === 'calibration/promotion') {
                    this.router.navigate(['calibration'], {relativeTo: this.route.parent});
                }
            }, this.appConstantsService.AUTO_UNLOCK_SCENARIO_PERIOD);
        });
    }

    @HostListener('document:keydown', ['$event'])
    @HostListener('window:mousemove')
    refreshUserState() {
        clearTimeout(this.userActivity);
        this.setCalibrationLockTimeout();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler() {
        clearTimeout(this.userActivity);
        this.calibrationService.releaseCalibrationLock(this.calibration).subscribe();
    }

    /**
     * Deactivate guard, a hook that gets called when exiting route.
     * Using this hook to unlock calibration if its locked.
     * */
    canDeactivate(): Promise<boolean> {
        if (this.dataChanged) {
            return this.warningDialog(this.warningModalMessages.exitCalibrationHeader, 'Changes will not be saved. Press \'Exit\' to continue, otherwise press \'Cancel\'.');
        } else {
            if (this.navigateToUrl !== 'calibration/promotion') {
                return new Promise((resolve) => {
                    this.calibrationService.releaseCalibrationLock(this.calibration).subscribe(() => {
                        resolve(true);
                    });
                });
            } else {
                return new Promise((resolve) => {
                    resolve(true);
                });
            }
        }
    }

    volumeTypeOptionChange(option: SingleSelectDropdownOption): void {
        this.volumeTypeOptions.forEach((it) => {
            it.selected = (it === option);
        });
        this.selectedVolumeTypeOption = option;
        this.calibration.targetType = option.value;
    }

    exportProjectData(): void {
        this.uiBlockerService.block();
        this.projectService.exportProjectData(this.metaData.projectId, this.metaData.modelRunId).subscribe((response) => {
            const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            const modelRun = this.modelRunService.activeModelRun;
            anchor.download = `${modelRun.displayName} Project Data Export.xlsx`;
            anchor.href = url;
            anchor.click();
            this.uiBlockerService.unblock();
        }, () => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar('Project Data export failed.');
        });
    }
    setFilters(segments: Segment[]): void {
        const selectedSegments = this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun);
        this.filters = this.modelRunService.getPopulationFilters(selectedSegments, this.metaData, segments);
        this.modelRunService.getNSize(this.projectId, this.modelRunId, this.modelRunService.getSelectedSegments(this.modelRun, this.segments, this.filters)).subscribe({
            next: (data: any) => {
                this.nSize = data.nSize;
                this.nSizeText = this.modelRunService.getNSizeText(this.filters, this.nSize);
                this.populationFilterToolTip = this.populationFilterToolTipMessage();
            }
        });
    }

    populationFilterToolTipMessage(): string {
        let populationFilterTooltip = this.nSizeText;
        if (this.filters && this.filters.length) {
            this.filters.forEach((filter) => {
                const allSelected = filter.data.every((data) => {
                    return data.selected;
                });
                if (!allSelected) {
                    populationFilterTooltip = `${populationFilterTooltip}\n ${filter.dropdownLabel}:`;
                    for (let i = 0; i < filter.data.length; i++) {
                        const item = filter.data[i];
                        if (item.selected) {
                            populationFilterTooltip = `${populationFilterTooltip} ${item.label}`;
                            if (i != filter.data.length - 1) {
                                populationFilterTooltip = `${populationFilterTooltip},`;
                            }
                        }
                    }
                }
            });
        }
        return populationFilterTooltip;
    }

    uploadWeeklyDataFile(file: ReadFile): void {
        this.uiBlockerService.block();
        this.calibrationService.uploadWeeklyDataFile(this.calibration, file).subscribe((calibration) => {
                this.snackBarService.openSuccessSnackBar('Successfully uploaded weekly data file.');
                this.calibration.weeklyDataFileClaimCheckId = calibration.weeklyDataFileClaimCheckId;
                this.checkCalibrationButtonVisibility();
                this.uiBlockerService.unblock();
            },
            error => {
                this.snackBarService.openErrorSnackBar(error.error.message);
                this.uiBlockerService.unblock();
            });
    }

    downloadWeeklyData(): void {
        this.uiBlockerService.block();
        this.calibrationService.downloadWeeklyDataFile(this.calibration).subscribe((response) => {
            const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = `weeklyData.csv`;
            anchor.href = url;
            anchor.click();
            this.uiBlockerService.unblock();
        }, error => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar('Failed downloading report.');
        });
    }

    onClickPreviewResults(): void {
        this.scenarioDropdownShow = !this.scenarioDropdownShow;
        this.collapsed = !this.collapsed;
        if (this.selectedCalibrationView === 'new') {
            this.scenarioResultsTable = !this.scenarioResultsTable;
        }
        this.reloadData();
    }

    prepareScenarioGroups(filterString: string): void {
        const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenariosForDropdown, this.scenarioGroups, filterString);
        this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
        this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
    }

    search(searchValue: string): void {
        this.searchValue = searchValue;
        this.prepareScenarioGroups(this.searchValue);
    }

    changeScenario(scenarioId: string): Promise<string> {
        return new Promise((resolve, reject) => {
                this.uiBlockerService.block();
                this.selectedScenarioId = scenarioId;
                this.reloadData();
                this.reloadCalibrationConfigTable();
                this.uiBlockerService.unblock();
        });
    }

    /**
 * this methods create timeOnPromoInvalidScenarios set which can be used to quickly lookup for invalid scenarios due to Time on promo validation
 * Also, it will create a map with key as scenario name and value as scenario id
 */
    checkTimeOnPromoValidForActiveScenarios(){
        //if the study don't include to Time on Promo feature or step1 is already completed
        if(this.metaData.promo === 'none' || this.calibration.statusCode >= 3){
            return;
        }

        if(!this.timeOnPromoInvalidScenariosList.length){
            this.scenarios.map((scenario) => {
                const skuList = this.scenarioSkuConfigs.hasOwnProperty(scenario.id)?this.scenarioSkuConfigs[scenario.id]:undefined;
                
                if(skuList != undefined){
                    for(let idx=0;idx<skuList.length; idx++){
                        const convertedValue = parseFloat(skuList[idx].promoDistribution);
                        // if any sku inside skuList is is invalid then we break the loop
                        if(skuList[idx].isSelected && (convertedValue === 1)){
                            this.timeOnPromoInvalidScenariosList.push({id:scenario.id, name:scenario.name});
                            break;
                        }
                    }
                }
            });
        }

        if(this.dataSet === null || this.dataSet === undefined){
            return;
        }

        const currentTableData = this.dataSet;
        const invalidScenarioNames  = this.timeOnPromoInvalidScenariosList.filter(value => currentTableData.find(row => row.scenarioName === value.name)).map(value => value.name);

        if(invalidScenarioNames.length){
            this.timeOnPromoWarningMessage = this.getTimeOnPromoInvalidMessageForScenario(invalidScenarioNames);
            this.showScenarioInvalidWarningMessage=true;
            this.cellRenderers.setInvalidScenarioNames(invalidScenarioNames);
        }else{
            this.showScenarioInvalidWarningMessage=false;
        }

    }

    /**
     * @param scenario containg scenario names that are invalid
     * @returns string after appending the invalid scenario name
     */
    getTimeOnPromoInvalidMessageForScenario(scenarios: Array<string>): string{
        
        if(scenarios.length){
            return `Scenario(s) include item(s) at 100% Time on Promo. Update '${scenarios.join(', ')}' to proceed with calibration.`;
        }else{
            return '';
        }
    }

    onCalibrationSettingsFlyoutToggle(value){
        this.slideOpenCalibrationSettings=value;
    }

    onCalibrationSettingsSave($event: { generalSetting: any; close: boolean }): void {
        this.uiBlockerService.block();
        const data={
            avgItemPriceElasticity: $event.generalSetting.avgItemPriceElasticity,
            targetType: $event.generalSetting.selectedCalibrationMetric,
            version: $event.generalSetting.calcVersion
        };
        if($event.close==true){
            this.onCalibrationSettingsFlyoutToggle(false);
        }
        this.calibrationService.saveCalibrationSettings(this.projectId,this.modelRunId,data).subscribe((calibration: Calibration) => {
            this.calibration = calibration;
            this.generalCalibrationSetting = {avgItemPriceElasticity:data.avgItemPriceElasticity, calibrationMetrics:this.generalCalibrationSetting.calibrationMetrics,selectedCalibrationMetric:data.targetType,calcVersion:data.version};
            this.onCalibrationChange(calibration);
            this.uiBlockerService.unblock();
        }, () => {
            this.snackBarService.openErrorSnackBar('Saving calibration settings failed.');
            this.uiBlockerService.unblock();
        });
    }

    clickedOutsideDrawer(){
        this.triggerClose.next(true);
    }

    isCalibrationStepStarted() {
        return this.calibration && this.calibration.statusCode !== 0 && this.calibration.statusCode != this.appConstantsService.CALIBRATION_STEP1_COMPLETED;
    }

    updateCurrentType(viewType: any): void {
        let promise;
        this.scenarioResultsTable = false;

        if (this.calibration.statusCode >= 6 && this.hasCorrelatedSkus) {
            if (this.dataChanged) {
                if (this.selectedCalibrationView == 'current') {
                    promise = this.warningDialog(this.warningModalMessages.exitCurrentViewHeader, this.calibration.statusCode<=3? this.warningModalMessages.clickOnCalibrateExit:this.warningModalMessages.clickOnRecalibrateExit);

                } else {
                    promise = this.warningDialog(this.warningModalMessages.exitNewViewHeader, this.warningModalMessages.clickOnSaveOrCalibrate);
                }

                promise.then((response: boolean) => {
                    if(response)
                        {this.setCurrentType(viewType);}
                });
            } else {
                this.setCurrentType(viewType);
            }
        }
    }

    resetPopulationFiltersStatusCheck(): void {
        if (this.modelRun.runId) {
            this.hidePopulationFiltersReset = this.metaData.projectType.toLowerCase().trim() === "als";
            this.resetPopulationFiltersStatus = this.modelRun.selectedSegments !== this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun)
                || this.modelRun.selectedSegments !== this.getSelectedSegments().join(',');
        }
    }

    getResetPopulationFiltersStatus() {
        this.resetPopulationFiltersStatusCheck();
        return this.resetPopulationFiltersStatus;
    }

    getSelectedSegments(): Array<number> {
        if (this.modelRun.runId) {
            return this.modelRunService.getSelectedSegments(this.modelRun, this.segments, this.filters);
        } else {
            return [-1];
        }
    }

    setupSnapshotData(): void {
        this.scenarios = this.scenarioService.scenarios;
        this.modelRun = this.modelRunService.getModelRun(this.modelRunId);
        this.showPopulationFilter = !this.segments.every((segment) => {
            return !segment.showInSimulator;
        });
        this.setFilters(this.segments);
        this.onFilterSelectionChange(null);
        this.scenarioGroups = this.scenarioGroupService.scenarioGroups;
        if (!this.scenarioGroups) {
            this.scenarioGroups = [];
        }
    }

    onFilterSelectionChange(filter: DropdownData<string>): void {
        this.updateNSize();
        this.scenarioService.skuConfigInputsResetSubject.next();
        this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
        this.scenarioService.MODEL_RUN_POPULATION_CHANGED_SUBJECT.next(this.filters);
        this.resetPopulationFiltersStatusCheck();
    }

    updateNSize(): void {
        if (this.modelRun.runId) {
            this.modelRunService.getNSize(this.projectId, this.modelRunId, this.getSelectedSegments()).subscribe({
                next: (data: any) => {
                    const oneThirdOfTotalNSize = Math.floor((1 / 3) * this.totalNSize);
                    this.nSize = data.nSize;
                    if (this.nSize <= LOW_POPULATION_THRESHOLD) {
                        this.lowPopulation = true;
                        this.lowPopulationMsgType = 'danger';
                        this.lowPopulationMsg = '75 or less respondents selected. Results are likely unreliable.';
                    } else if (this.nSize > Math.min(LOW_POPULATION_THRESHOLD, oneThirdOfTotalNSize) && this.nSize < Math.max(LOW_POPULATION_THRESHOLD, oneThirdOfTotalNSize)) {
                        this.lowPopulation = true;
                        this.lowPopulationMsgType = 'warn';
                        this.lowPopulationMsg = 'Less than 1/3 of total sample selected. Use extra caution when interpreting results.';
                    } else {
                        this.lowPopulation = false;
                        this.lowPopulationMsgType = null;
                    }
                }
            });
        }
    }

    setOutputsFilteredOnNonSampleFilterToggleTooltip(): void {
        const hasUnSelectedNonSampleFilter = this.modelRunService.unSelectedNonSameFilterExists(this.filters);
        this.outputsFilteredOnNonSampleFilterToggleTooltip = hasUnSelectedNonSampleFilter ? this.metaDataService.tooltipOnNonSampleFilterEngage(this.metaData) : null;
    }

    resetPopulationFilters(): void {
        const userSelectedSegmentsConfig = this.userConfigurationsService.getUserSelectedSegmentsConfig();
        if (this.resetPopulationFiltersStatus) {
            userSelectedSegmentsConfig.configurations.userRunLevelSelectedSegments = this.modelRun.selectedSegments;
            this.uiBlockerService.block();
            this.userConfigurationsService.updateUserConfiguration(userSelectedSegmentsConfig).subscribe(userConfigurations => {
                this.uiBlockerService.unblock();
                this.setFilters(this.segments);
                this.updateNSize();
                this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
                this.scenarioService.MODEL_RUN_POPULATION_CHANGED_SUBJECT.next(this.filters);

                this.scenarioService.RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$.next();
                this.resetPopulationFiltersStatusCheck();
            },
                err => {
                    this.uiBlockerService.unblock();
                    this.snackBarService.openErrorSnackBar(err.error.message);
                });
        }

    }

    getIsFilterChanged() {
        const currentSelectedSegments = this.getSelectedSegments();
        const filterChanged = this.calibration &&
            this.calibration.statusCode > 0 &&
            this.calibration.selectedSegments &&
            (currentSelectedSegments.length != this.calibration.selectedSegments.length || !this.getSelectedSegments().every(d => this.calibration.selectedSegments.includes(d)));

        if (filterChanged != this.isFilterChanged) {
            this.isFilterChanged = filterChanged;
            this.reloadCalibrationConfigTable();
        }

        this.setOriginalFilters();
        return this.isFilterChanged;
    }

    setOriginalFilters() {
        this.originalFilters = [];
        this.filters.forEach(filter => {
            const differenceInFilter = this.convertFilterToWarningMessage(filter);
            if(differenceInFilter){
                this.originalFilters.push(differenceInFilter);   
            }
        });
    }

    convertFilterToWarningMessage(filter: DropdownData<string>) {
        const currentSelectedSegments = this.getSelectedSegments();
        const difference = filter.data.filter(d=>
                                (currentSelectedSegments?.includes(+d.value) && !this.calibration?.selectedSegments?.includes(+d.value)) ||
                                (!currentSelectedSegments?.includes(+d.value) && this.calibration?.selectedSegments?.includes(+d.value))
                            ).map(d => d.label);
        if(difference.length>0){
            return `${filter.dropdownLabel}: ${difference.join(', ')}`;
        }
        return '';
    }

    saveFilters(){
        const userConfigurations = this.userConfigurationsService.userConfigurations.find(c=>c.configType==this.appConstantService.USER_RUN_SEGMENTS);
        if(userConfigurations){
            userConfigurations.configurations['userRunLevelSelectedSegments']=this.getSelectedSegments().join(',');
            this.userConfigurationsService.updateUserConfiguration(userConfigurations).subscribe(uc => {
                this.userConfigurationsService.userConfigurations = uc;
            });
        }
    }

    cloneDataset(){
        this.clonedDataSet=JSON.parse(JSON.stringify(this.dataSet));
    }

    checkPercentageUnitsOnPromoShareVisibility(){
        return this.hasPromo && this.generalCalibrationSetting.calcVersion == '6_1';
    }    

    getStep2CompletionPercentage(){
        let calcVersion = "6_1";
        if(this.generalCalibrationSetting?.calcVersion)
            {calcVersion = this.generalCalibrationSetting.calcVersion;}
        if(this.isLongRuningCalibration(this.calibration,calcVersion)){
            this.calibrationService.getStep2CompletionPercentage(this.projectId, this.modelRunId).subscribe(data=>{
                if(data['status']=='SUBMITTED'){
                    this.step2InSubmittedState = true;
                }
                else{
                    this.step2InSubmittedState = false;
                    if(data['percentageCompleted'] && !isNaN(+data['percentageCompleted'])){
                        this.step2CompletedPercentage = Math.round(data['percentageCompleted']);
                    }
                }
            });
        }
    }
    
    isLongRuningCalibration(calibration,calcVersion) {
        let returnValue = false;
        if(this.calibrationService.step2Started(calibration) && calcVersion == "6_1" && calibration.targetedInnovation != 'new'){
            returnValue = true;
        }
        return returnValue;
    }

    stopCalibration(){
        let promise;
        promise = this.warningDialog(this.warningModalMessages.stopCalibrationHeader, this.warningModalMessages.clickOnStopCalibration, 'STOP',false);

        promise.then((response: boolean) => {
            if(response)
                {this.calibrationService.stopCalibration(this.projectId, this.modelRunId).subscribe((calibration) => {                 
                    this.onCalibrationChange(calibration);    
                });}           
        });
    }

    checkCalibrationButtonVisibilityForNewItemsView(){      
         const calibratedSkuWithoutPercentageUnitsOnPromoShare = this.dataSet.find((it) => {
            return !this.scenarioService.isCalibrationScenarioName(it.scenarioName) && (roundTo(it.targetPercentageUnitsOnPromoShare * 100 ,6) > 100);
        });
           
        this.disableCalibrationForPromoShare = (calibratedSkuWithoutPercentageUnitsOnPromoShare !== undefined && calibratedSkuWithoutPercentageUnitsOnPromoShare !== null);
      
        if(this.disableCalibrationForPromoShare){
            this.disableCalibrateReason = `Current target splits must be between 0% and 100%`;
            this.disableCalibration = this.disableCalibrationForPromoShare;
        }
        else{
            this.disableCalibrateReason = '';
        }
    }

}
