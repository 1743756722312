import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

/************************************    IMPORT GUARD SECTION    ***********************************************/
import {InternalUserActivateGuard} from '@app/guards/internal-user-activate-guard.service';
import {SyncedModelRunGuard} from '@app/guards/synced-model-run-guard.service';
import {CreatePriceElasticityReportDeactivateGuard} from '@app/routes/reports/price-elasticity/create-price-elasticity-report/create-price-elasticity-report.deactivate.guard';
import {CreateOptimizationReportDeactivateGuard} from '@app/routes/reports/optimization/create-optimization-report/create-optimization-report.deactivate.guard';
import {CreatePriceChangeImpactReportDeactivateGuard} from '@app/routes/reports/price-change-impact/create-price-change-impact/create-price-change-impact-report.deactivate.guard';
import {CreateSourcingMatrixDeactivateGuard} from '@app/routes/reports/sourcing-matrix/create-sourcing-matrix-report/create-sourcing-matrix-report.deactivate.guard';
import {ErrorsActivateGuard} from '@app/routes/errors/errors.activate.guard';
import {CalibrationDeactivateGuard} from '@app/routes/project/runs/run/calibration/calibration.deactivate.guard';
/************************************  END OF IMPORT GUARD SECTION ***********************************************/

/************************************    IMPORT RESOLVER SECTION    ***********************************************/
import {PermissionResolver} from '@app/routes/project/runs/run/scenarios/permission.resolver';
import {AccessPolicyResolver} from '@app/routes/project/runs/run/scenarios/access-policy.resolver';
import {ProjectCollaboratorResolver} from '@app/resolvers/project-collaborator.resolver';
import {UserConfigurationsResolver} from '@app/resolvers/userConfigurations.resolver';
import {ModelRunResolver} from '@app/resolvers/model-run.resolver';
import {PriceElasticityResolver} from '@app/routes/reports/price-elasticity/price-elasticity.resolver';
import {PriceChangeImpactResolver} from '@app/routes/reports/price-change-impact/price-change-impact.resolver';
import {SourcingMatrixResolver} from '@app/routes/reports/sourcing-matrix/sorcing-matrix.resolver';
import {GeneralSettingResolver} from '@app/resolvers/general-setting.resolver';
import {CalibrationResolver} from '@app/resolvers/calibration.resolver';
import {SimulationRunInputResolver} from '@app/resolvers/simulation-run-input.resolver';
import {SimulationRunResultResolver} from '@app/resolvers/simulation-run-result.resolver';
import {CalibrationInitResolver} from '@app/resolvers/calibration-init.resolver';

/************************************  END OF IMPORT RESOLVER SECTION ***********************************************/

import {ProjectComponent} from './routes/project/project.component';
import {RunsComponent} from './routes/project/runs/runs.component';
import {RunComponent} from './routes/project/runs/run/run.component';
import {NotFoundComponent} from './routes/errors/not-found/not-found.component';
import {ProjectResolver} from './resolvers/project.resolver';
import {RunsResolver} from './resolvers/runs.resolver';
import {ScenarioGroupResolver} from './resolvers/scenario-group.resolver';
import {ScenarioResolver} from './resolvers/scenario.resolver';
import {OptimizationReportResolver} from './routes/reports/optimization/optimization-report.resolver';
import {ScenariosComponent} from '@app/routes/project/runs/run/scenarios/scenarios.component';
import {ManageScenariosComponent} from './routes/project/runs/run/manage-scenarios/manage-scenarios.component';
import {ScenarioComponent} from '@app/routes/project/runs/run/scenarios/scenario/scenario.component';
import {MetaDataResolver} from '@app/resolvers/meta-data.resolver';
import {ModelRunSkuResolver} from '@app/resolvers/model-run-sku.resolver';
import {SkuGroupResolver} from '@app/resolvers/sku-group.resolver';
import {SegmentResolver} from '@app/resolvers/segment.resolver';

import {ReportsComponent} from '@app/routes/reports/reports.component';

import {OptimizationComponent} from '@app/routes/reports/optimization/optimization.component';
// eslint-disable-next-line max-len
import {ListOptimizationReportComponent} from '@app/routes/reports/optimization/list-optimization-report/list-optimization-report.component';
import {CreateOptimizationReportComponent} from '@app/routes/reports/optimization/create-optimization-report/create-optimization-report.component';

import {PriceElasticityComponent} from '@app/routes/reports/price-elasticity/price-elasticity.component';
import {ListPriceElasticityReportComponent} from '@app/routes/reports/price-elasticity/list-price-elasticity-report/list-price-elasticity-report.component';
import {CreatePriceElasticityReportComponent} from '@app/routes/reports/price-elasticity/create-price-elasticity-report/create-price-elasticity-report.component';

import {PriceChangeImpactComponent} from './routes/reports/price-change-impact/price-change-impact.component';
import {ListPriceChangeImpactComponent} from './routes/reports/price-change-impact/list-price-change-impact/list-price-change-impact.component';
import {CreatePriceChangeImpactComponent} from './routes/reports/price-change-impact/create-price-change-impact/create-price-change-impact.component';

import {SourcingMatrixComponent} from './routes/reports/sourcing-matrix/sourcing-matrix.component';
import {ListSourcingMatrixReportComponent} from './routes/reports/sourcing-matrix/list-sourcing-matrix-report/list-sourcing-matrix-report.component';
import {CreateSourcingMatrixReportComponent} from './routes/reports/sourcing-matrix/create-sourcing-matrix-report/create-sourcing-matrix-report.component';
import {ItemReorderTableComponent} from '@app/components/item-reorder-table/item-reorder-table.component';
import {CalibrationComponent} from './routes/project/runs/run/calibration/calibration.component';
import {PromotionsModalComponent} from './components/promotions-modal/promotions-modal.component';
import {SizeElasticityComponent} from "@app/routes/reports/size-elasticity/size-elasticity.component";
import {SizeElasticityResolver} from "@app/routes/reports/size-elasticity/size-elasticity.resolver";
import {ListSizeElasticityReportComponent} from "@app/routes/reports/size-elasticity/list-size-elasticity-report/list-size-elasticity-report.component";
import {CreateSizeElasticityReportComponent} from "@app/routes/reports/size-elasticity/create-size-elasticity-report/create-size-elasticity-report.component";
import {CreateSizeElasticityReportDeactivateGuard} from "@app/routes/reports/size-elasticity/create-size-elasticity-report/create-size-elasticity-report.deactivate.guard";

const routes: Routes = [
    {
        path: 'projects/:projectId',
        component: ProjectComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
            project: ProjectResolver,
            collaborators: ProjectCollaboratorResolver,
            runs: RunsResolver
        },
        children: [
            {path: '', redirectTo: 'runs', pathMatch: 'full'},
            {
                path: 'runs',
                component: RunsComponent,
                children: [
                    {
                        path: ':modelRunId',
                        component: RunComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {
                            modelRunSkus: ModelRunSkuResolver,
                            skuGroups: SkuGroupResolver,
                            metaData: MetaDataResolver,
                            permissions: PermissionResolver,
                            scenarios: ScenarioResolver,
                            scenarioGroups: ScenarioGroupResolver,
                            generalSetting: GeneralSettingResolver,
                            userConfigurationsResolver: UserConfigurationsResolver,
                            calibration: CalibrationResolver,
                        },
                        children: [
                            {
                                path: 'scenarios',
                                component: ScenariosComponent,
                                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                                resolve: {
                                    metaData: MetaDataResolver,
                                    segments: SegmentResolver,
                                    calibration: CalibrationResolver,
                                    scenarioGroups: ScenarioGroupResolver,
                                },
                                children: [
                                    {
                                        path: ':scenarioId',
                                        component: ScenarioComponent,
                                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                                        resolve: {
                                            simulationRunInputs: SimulationRunInputResolver,
                                            simulationRunResults: SimulationRunResultResolver
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'manage',
                                component: ManageScenariosComponent,
                                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                                resolve: {
                                    accessPolicies: AccessPolicyResolver,
                                    calibration: CalibrationResolver,
                                    scenarioGroups: ScenarioGroupResolver,
                                }
                            },
                            {
                                path: 'reorderItems',
                                component: ItemReorderTableComponent,
                                resolve: {
                                    userConfigurationsResolver: UserConfigurationsResolver
                                },
                                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
                            },
                            {
                                path: 'calibration',
                                component: CalibrationComponent,
                                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                                canActivate: [InternalUserActivateGuard, SyncedModelRunGuard],
                                canDeactivate: [CalibrationDeactivateGuard],
                                resolve: {
                                    calibration: CalibrationInitResolver,
                                    scenarios: ScenarioResolver,
                                    scenarioGroups: ScenarioGroupResolver,
                                    userConfigurations: UserConfigurationsResolver,
                                    segments: SegmentResolver,
                                }
                            },
                            {
                                path: 'calibration/promotion',
                                component: PromotionsModalComponent,
                                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                                canActivate: [InternalUserActivateGuard, SyncedModelRunGuard],
                                resolve: {
                                    calibration: CalibrationResolver
                                },
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: 'projects/:projectId/runs/:modelRunId/reports',
        component: ReportsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        canActivate: [InternalUserActivateGuard, SyncedModelRunGuard],
        resolve: {
            project: ProjectResolver,
            modelRun: ModelRunResolver,
            modelRunSkus: ModelRunSkuResolver,
            scenarios: ScenarioResolver,
            scenarioGroups: ScenarioGroupResolver,
            metaData: MetaDataResolver,
            skuGroups: SkuGroupResolver,
            segments: SegmentResolver
        },
        children: [
            {path: '', redirectTo: 'optimization', pathMatch: 'full'},
            {
                path: 'optimization',
                component: OptimizationComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    reports: OptimizationReportResolver
                },
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: ListOptimizationReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {}
                    },
                    {
                        path: 'create',
                        component: CreateOptimizationReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {},
                        canDeactivate: [CreateOptimizationReportDeactivateGuard]
                    }
                ]
            },
            {
                path: 'price-elasticity',
                component: PriceElasticityComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    reports: PriceElasticityResolver
                },
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: ListPriceElasticityReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {}
                    },
                    {
                        path: 'create',
                        component: CreatePriceElasticityReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {},
                        canDeactivate: [CreatePriceElasticityReportDeactivateGuard]
                    }
                ]
            },
            {
                path: 'size-elasticity',
                component: SizeElasticityComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    reports: SizeElasticityResolver
                },
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: ListSizeElasticityReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {}
                    },
                    {
                        path: 'create',
                        component: CreateSizeElasticityReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {},
                        canDeactivate: [CreateSizeElasticityReportDeactivateGuard]
                    }
                ]
            },
            {
                path: 'price-change-impact',
                component: PriceChangeImpactComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    reports: PriceChangeImpactResolver
                },
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: ListPriceChangeImpactComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {}
                    },
                    {
                        path: 'create',
                        component: CreatePriceChangeImpactComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {},
                        canDeactivate: [CreatePriceChangeImpactReportDeactivateGuard]
                    }
                ]
            },
            {
                path: 'sourcing-matrix',
                component: SourcingMatrixComponent,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                resolve: {
                    reports: SourcingMatrixResolver
                },
                children: [
                    {path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: ListSourcingMatrixReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {}
                    },
                    {
                        path: 'create',
                        component: CreateSourcingMatrixReportComponent,
                        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                        resolve: {},
                        canDeactivate: [CreateSourcingMatrixDeactivateGuard]
                    }
                ]
            }
        ]
    },
    {
        path: 'errors',
        canActivate: [ErrorsActivateGuard],
        children: [
            {path: '', redirectTo: '404', pathMatch: 'full'},
            {path: '404', component: NotFoundComponent}
        ]
    },
    {path: '**', redirectTo: 'errors/404'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
