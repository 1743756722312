import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {Calibration} from "@app/models/calibration/calibration.model";
import {CalibrationService} from "@app/services/calibration.service";

@Component({
    selector: 'app-calibration-benchmark',
    templateUrl: './calibration-benchmark.component.html',
    styleUrls: ['./calibration-benchmark.component.scss']
})

export class CalibrationBenchmarkComponent implements OnChanges {
    @Input() calibration: Calibration;
    @Input() disable: boolean;
    @Output() valueChanged = new EventEmitter();
    @ViewChild('inputElement') input;
    editMode = false;
    invalidAvgItemPriceElasticity = false;
    tooltip: string;

    constructor(private calibrationService: CalibrationService) {
    }

    ngOnChanges(): void {
        this.tooltip = this.disable ? 'To change this value you should clear and restart calibration' : '';
    }

    enableEditMode(): void {
        if (!this.disable) {
            this.editMode = true;
            setTimeout(() => {
                const input: HTMLInputElement = this.input.nativeElement as HTMLInputElement;
                input.focus();
            }, 0);
        }
    }

    validateAvgItemPriceElasticity(): void {
        this.invalidAvgItemPriceElasticity = !this.calibrationService.validateAvgItemPriceElasticity(this.calibration.avgItemPriceElasticity);
        this.valueChanged.emit();
    }

    disableEditMode(): void {
        this.invalidAvgItemPriceElasticity = !this.calibrationService.validateAvgItemPriceElasticity(this.calibration.avgItemPriceElasticity);
        if (!this.invalidAvgItemPriceElasticity) {
            this.editMode = false;
        }
    }

}
