import {Component, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ScenarioService} from '../../services/scenario.service';
import {ModelRunService} from '../../services/model-run.service';
import {UiBlockerService} from '../../services/ui-blocker.service';
import {EnvironmentService} from '../../services/environment.service';
import {AppConstantsService} from '../../services/app-constants.service';
import {UserConfigurations} from '../../models/user-configurations.model';
import {UserConfigurationsService} from '../../services/user-configurations.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackBarService} from '../../components/snack-bar/snack-bar.service';
import {ModelRun} from '@app/models/model-run.model';
import {ModelRunSkuService} from '@app/services/model-run-sku.service';
import {forkJoin} from 'rxjs';
import {ModelRunSku} from '@app/models/model-run-sku.model';

@Component({
    selector: 'app-item-reorder-table',
    templateUrl: './item-reorder-table.component.html',
    styleUrls: ['./item-reorder-table.component.scss']
})
export class ItemReorderTableComponent implements OnInit {

    skuConfigs: Array<ModelRunSku>;
    headers = ['ID', 'Reporting Name'];
    skuConfigsItemDisplayOrder: UserConfigurations;
    projectId: number;
    modelRunId: string;
    modelRun: ModelRun;

    constructor(
        private scenarioService: ScenarioService,
        private modelRunService: ModelRunService,
        private uiBlockerService: UiBlockerService,
        private environmentService: EnvironmentService,
        private appConstantsService: AppConstantsService,
        private userConfigurationsService: UserConfigurationsService,
        private modelRunSkuService: ModelRunSkuService,
        private snackBarService: SnackBarService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const scenarios = this.scenarioService.scenarios;
        if (scenarios && scenarios.length) {
            this.projectId = scenarios[0].projectId;
            this.modelRunId = scenarios[0].modelRunId;
            this.modelRun = this.modelRunService.getModelRun(this.modelRunId);
            forkJoin([
                this.modelRunSkuService.fetchAll(this.projectId, this.modelRunId),
                this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId)
            ]).subscribe(([modelRunSkus, userConfigurations]) => {
                this.skuConfigs = this.modelRunSkuService.getSkuConfigsInDisplayOrder(modelRunSkus, this.userConfigurationsService.getSkuOrderConfig());
            });
        }
    }


    drop(event: CdkDragDrop<string[]>): void {
        if ((event.currentIndex === 0 || event.currentIndex) && event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.skuConfigs, event.previousIndex, event.currentIndex);
            const skuOrder = [];
            let index = 1;
            this.skuConfigs.map(skuConfig => {
                const skuObj = {skuId: skuConfig.skuId, displayOrder: index++};
                skuOrder.push(skuObj);
            });
            const configType = this.appConstantsService.ITEM_ORDER_CONFIG_TYPE;
            this.skuConfigsItemDisplayOrder = this.userConfigurationsService.getUserConfigurationsByType(configType);
            this.skuConfigsItemDisplayOrder = this.skuConfigsItemDisplayOrder ? this.skuConfigsItemDisplayOrder : new UserConfigurations();
            this.skuConfigsItemDisplayOrder.configurations = {
                skuOrder
            };
            this.applyColumnSelectionChange();
        }
    }

    applyColumnSelectionChange(): void {
        this.uiBlockerService.block();
        if (this.skuConfigsItemDisplayOrder.id) {
            this.userConfigurationsService.updateUserConfiguration(this.skuConfigsItemDisplayOrder).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.snackBarService.openSuccessSnackBar('SKU order updated successfully');
                this.uiBlockerService.unblock();
            });
        } else {
            this.skuConfigsItemDisplayOrder.projectId = this.projectId;
            this.skuConfigsItemDisplayOrder.modelRunId = this.modelRunId;
            this.skuConfigsItemDisplayOrder.configType = this.appConstantsService.ITEM_ORDER_CONFIG_TYPE;
            this.userConfigurationsService.createNewUserConfiguration(this.skuConfigsItemDisplayOrder).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.snackBarService.openSuccessSnackBar('SKU order updated successfully');
                this.uiBlockerService.unblock();
            });
        }
    }

    returnToScenarios(): void {
        this.router.navigate(['scenarios'], {relativeTo: this.route.parent});
    }
}
