<div class="mat-card">
    <h3 class="settings-header">
        Calibration Settings
        <button mat-icon-button aria-label="Close" (click)="onClose()"><span class="sif sif-close"></span>
        </button>
    </h3>
    <div class="settings-body">
        <div class="view-adjustments">
            <div class="PEBenchmark">
                <span *ngIf="!showPEBenchmark" class="sif sif-add" (click)="togglePEBenchmark(true)"></span>
                <span *ngIf="!showPEBenchmark" class="pt-1" (click)="togglePEBenchmark(true)">PRICE ELASTICITY BENCHMARK</span>
                <span *ngIf="showPEBenchmark" class="PEBenchmark-expanded" >Benchmark:</span>
                <div *ngIf="showPEBenchmark">
                    <input
                            type="text"
                            class="input-box"
                            [(ngModel)]="peBenchmark"
                            (change)="validateAvgItemPriceElasticity()"
                            (paste)="validateAvgItemPriceElasticity()"
                            (keyup)="validateAvgItemPriceElasticity()"
                             />
                </div>
            </div>
            <div *ngIf="!isPEBenchmarkValid" class="validation-error"><mat-error> Value must be negative and up to -7</mat-error></div>
            <div>
                <span class="title-settings">Calibration Metric</span>
                <div class="category-details">
                    <mat-radio-group class="example-radio-group" [(ngModel)]="selectedMetric">
                        <mat-radio-button class="example-radio-button" *ngFor="let metric of metrics" [value]="metric.value">
                          {{metric.label}}
                        </mat-radio-button>
                      </mat-radio-group>
                </div>
            </div>
            <div *ngIf="showVersion">
                <span class="title-settings">Calculation Version</span>
                <div class="category-details">
                    <mat-radio-group class="example-radio-group" [(ngModel)]="selectedVersion">
                        <mat-radio-button class="example-radio-button" *ngFor="let version of versions" [value]="version.value">
                          {{version.label}}
                        </mat-radio-button>
                      </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div class="settings-footer">
        <button mat-button [disabled]="saveButtonDisabled()? true : null" (click)="onSave(false)">Apply Changes</button>
        <button mat-flat-button color="primary" [disabled]="disableSaveButton? true : null" (click)="onSave(true)">Save & Close</button>
    </div>
</div>
