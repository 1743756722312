import {Injectable} from '@angular/core';
import {Project} from '../models/project.model';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {Collaborator} from '../models/collaborator.model';
import {ModelCacheUtil} from '@app/utils/model-cache-util';
import {UtilService} from "@app/services/util.service";

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    private cache: ModelCacheUtil<Project>;
    private collaboratorsCache: ModelCacheUtil<Collaborator>;

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private utilService: UtilService) {
        this.cache = new ModelCacheUtil<Project>();
        this.collaboratorsCache = new ModelCacheUtil<Collaborator>();
    }

    get collaborators(): Array<Collaborator> {
        return this.collaboratorsCache.cachedModels;
    }

    getProject(projectId: number): Observable<Project> {
        const project = this.cache.find(it => it.id === projectId);
        if (project) {
            return of(project);
        } else {
            const env = this.environmentService.environment.authProxy;
            const url = `${env.url}/${env.studioContextPath}/projects/${projectId}`;
            return this.http.get<{ project: Project }>(url).pipe(map((data) => {
                const project: Project = data.project;
                this.cache.clear();
                this.cache.append(project);
                return project;
            }));
        }
    }

    /**
     * Api path to get toolbar configuration from backend - /projectNavigation
     */
    getProjectNavigationAPI(projectId: number): string {
        return `/projects/${projectId}/projectNavigation`;
    }

    getProjectCollaborators(projectId: number): Observable<Array<Collaborator>> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/projectCollaborators`;
        return this.http.get<Array<Collaborator>>(url).pipe(map((collaborators: Array<Collaborator>) => {
            this.collaboratorsCache.clear();
            this.collaboratorsCache.appendAll(collaborators);
            return collaborators;
        }));
    }

    getCollaboratorById(userManagementId: string): Collaborator {
        return this.collaborators.find(it => it.userManagementId === userManagementId);
    }

    exportProjectData(projectId: number, modelRunId: string): Observable<any> {
        const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, 'project/export');
        return this.http.get(`${url}`, {
                responseType: 'arraybuffer'
            }
        );
    }

}
