export function isEmpty(value: any): boolean {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
}

// serialize circular references by finding and replacing (or removing) the cyclic references by serializable values.
const circularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export function isNotEmpty(value: any): boolean {
    return !isEmpty(value);
}

/**
 * fast way to deep compare 2 obj
 * limitation: this can not compare object with functions
 * @param obj1(json object)
 * @param obj2 (json object)
 */
export function compareJson(obj1, obj2): any {
    return JSON.stringify(obj1, circularReplacer()) === JSON.stringify(obj2, circularReplacer());
}

export function cloneJson(obj1, circularReplacerRequired = true): any {
    // for some scenario circular replacer is failing
    if (circularReplacerRequired) {
        return JSON.parse(JSON.stringify(obj1, circularReplacer()));
    }
    return JSON.parse(JSON.stringify(obj1));
}

/**
 * A method to identify is the JSON and it's children are empty
 * eg Obj = {
 *            name: '',
 *            addresses: [],
 *            type:{},
 *          }
 *  For the above object all the child values are empty, so the
 *  function will return true
 */
export function isEmptyJson(obj): boolean {
    for (const key in obj) {
        // if the value is 'object'
        if (obj[key] instanceof Object === true) {
            if (isEmptyJson(obj[key]) === false) {
                return false;
            }
        } else {
            // if value is string/number
            // if array or string have length is not 0.
            if (isNotEmpty(obj[key])) {
                return false;
            }
        }
    }
    return true;
}

