import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthProxyService} from '../../services/auth-proxy.service';

@Injectable()
export class ErrorsActivateGuard implements CanActivate {

    constructor(private authProxyService: AuthProxyService) {
    }

    canActivate(): boolean | Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.authProxyService.getUserInfo().subscribe(() => {
                resolve(true);
            }, () => {
                reject(false);
            });
        });
    }
}
