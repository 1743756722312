import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../models/user.model';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthProxyService {

  private sessionUser: User = null;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
  }

  /**
   * Setter for session user
   */
  set user(user: User) {
    this.sessionUser = user;
  }

  /**
   * Getter for session user
   */
  get user(): User {
    return this.sessionUser;
  }

  fetchUserInfo(): Observable<User> {
    const url = `${this.environmentService.environment.authProxy.url}/api/userInfo`;
    return this.http.get<User>(url).pipe(map((user) => {
      this.user = user;
      return user;
    }));
  }

  getUserInfo(): Observable<User> {
    if (this.user) {
      return of(this.user);
    } else {
      return this.fetchUserInfo();
    }
  }

  logout(): void {
    const logoutUrl = `${this.environmentService.environment.authProxy.url}/logout`;
    window.localStorage.clear();
    window.location.href = logoutUrl;
  }

  isInternalUser(): boolean {
    return this.user && this.user.isInternalUser;
  }

  userHasFeature(featureFlag: string): boolean {
    return this.sessionUser.featureFlags.indexOf(featureFlag) !== -1;
  }

  userHasRole(role: string): boolean {
    return this.sessionUser.roles.indexOf(role) !== -1;
  }

  userIsAdminUser(): boolean {
    return this.userHasRole('ROLE_SYSTEM_ADMINISTRATOR');
  }

  userHasAllProjectAccessRole(): boolean {
    return this.userHasRole('ROLE_All Project Access');
  }
}
