import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {ModelCacheUtil} from '../utils/model-cache-util';
import {Guardrail} from '../models/guardrail.model';

@Injectable({
  providedIn: 'root'
})
export class GuardrailService {

  /**
   * Cached list of guardrails
   */
  private cache: ModelCacheUtil<Guardrail>;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    this.cache = new ModelCacheUtil<Guardrail>();
  }

  get guardrails(): Array<Guardrail> {
    return this.cache.cachedModels;
  }

  fetchAll(): Observable<Array<Guardrail>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/guardrails`;
    return this.http.get<Array<Guardrail>>(url).pipe(map((guardrails: Guardrail[]) => {
      this.cache.clear();
      this.cache.appendAll(guardrails);
      return guardrails;
    }));
  }

  getByName(name: string): Observable<Guardrail> {
    const guardrail = this.cache.find((it: Guardrail) => {
      return name.toLowerCase() === it.name.toLowerCase();
    });
    if (guardrail) {
      return of(guardrail);
    } else {
      return of(undefined);
    }
  }
}
