import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportService} from '../../services/report.service';
import {ModelRun} from '../../models/model-run.model';
import {Project} from '../../models/project.model';
import {Scenario} from '../../models/scenario.model';
import {MetaData} from '../../models/meta-data.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit, OnDestroy {

  reportMenuTabs: Array<any> = [];
  selectedReportMenuIndex: number;
  activeReportTabId: string;
  subscriptions: Subscription = null;
  project: Project;
  modelRun: ModelRun;
  scenarios: Array<Scenario>;
  metaData: MetaData;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.selectedReportMenuIndex = 0;

    this.subscriptions.add(this.route.params.subscribe(() => {
      this.project = this.route.snapshot.data.project;
      this.modelRun = this.route.snapshot.data.modelRun;
      this.scenarios = this.route.snapshot.data.scenarios;
      this.metaData = this.route.snapshot.data.metaData;
      this.reportMenuTabs = this.setupMenuTabs(this.metaData);
      this.redirectOnModelRunChange();
    }));

    this.subscriptions.add(this.reportService.activeReportSubject.subscribe((reportId) => {
      this.activateMenuTab(reportId);
    }));
  }

  /**
   * Redirects user to the optimization report in case model run is changed and the report the user in currently in
   * is no longer valid. For eg when user is in price elasticity report and the run is changes to another which has no
   * price (metaData.hasPrice), then price elasticity report shouldn't be available and hence we need to redirect the user
   * to optimization report instead.
   */
  redirectOnModelRunChange(): void {
    if (this.activeReportTabId) {
      const activeReportTabExists = this.reportMenuTabs.find((menuTab) => {
        return menuTab.id === this.activeReportTabId;
      });
      if (!activeReportTabExists) {
        this.changeReport(this.reportMenuTabs[0].id);
      }
    }
  }

  setupMenuTabs(metaData: MetaData): Array<any> {
    const menuTabs = [
      {
        id: 'optimization',
        label: 'optimization',
        selected: false
      },
      {
        id: 'price-elasticity',
        label: 'Price Elasticity',
        selected: false
      },
      {
        id: 'size-elasticity',
        label: 'Size Elasticity',
        selected: false
      },
      {
        id: 'price-change-impact',
        label: 'Price Change Impact',
        selected: false
      }, {
        id: 'sourcing-matrix',
        label: 'Sourcing Matrix',
        selected: false
      }
    ];

    return menuTabs.filter((menuTab) => {
      return !((!metaData.hasPrice && ['price-elasticity', 'price-change-impact'].indexOf(menuTab.id) !== -1) ||
               (!metaData.hasSize && ['size-elasticity'].indexOf(menuTab.id) !== -1));
    });
  }

  /**
   * Highlights selected reporting tab.
   * NOTE: have to set selectedReportMenuIndex, activeReportTabId to null first because if we don't we have noticed that
   * it doesn't set the correct selected tab in certain cases such as when guard blocks a transition.
   */
  activateMenuTab(reportId: string): void {
    setTimeout(() => {
      this.selectedReportMenuIndex = null;
      this.activeReportTabId = null;
    });
    this.reportMenuTabs.forEach((reportMenuTab, index) => {
      reportMenuTab.selected = (reportId === reportMenuTab.id);
      if (reportMenuTab.selected) {
        /**
         * Had to add setTimeout here to avoid getting error in consolse.
         * @see https://blog.angular-university.io/angular-debugging/
         */
        setTimeout(() => {
          this.selectedReportMenuIndex = index;
          this.activeReportTabId = reportId;
        });
      }
    });
  }

  /**
   * @see https://stackoverflow.com/questions/48507161/create-click-event-on-mattab-material/48507421
   */
  changeReport(reportId: string): void {
    this.router.navigate([reportId], {relativeTo: this.route});
  }

  returnToScenarios(): void {
    this.router.navigateByUrl(`/projects/${this.modelRun.projectId}/runs/${this.modelRun.id}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
