<div class="create-list">
    <div class="table-data">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Run Name</th>
                    <th>Run Description</th>
                    <th>Scenario</th>
                    <th>Sample Information</th>
                    <th>Item Characteristic</th>
                    <th>Metric</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reports; let i = index;">
                    <td>{{i+1}}</td>
                    <td>{{report.name}}</td>
                    <td>{{report.description}}</td>
                    <td>{{report.scenarioId | getScenarioName:projectId:modelRunId}}</td>
                    <td [appReportSampleInfo]="report"></td>
                    <td>{{report.skuGroupId | getGroupName:projectId:modelRunId}}</td>
                    <td>{{report.metric | titlecase}}</td>
                    <td [appReportStatus]="report"></td>
                    <td>                        
                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{report:report}" [ngClass]="t.menuOpen? 'active':'inActive'" #t="matMenuTrigger">
                                <span class="sif sif-ellipsis-vertical"></span>
                            </button>
                        </div>                        
                    </td>
                </tr>
                <tr *ngIf="reports.length===0">
                    <td colspan="6" class="no-optimize">No Price Elasticity Runs currently exist. Press “+ Price Elasticity Run” to begin adding runs to the queue.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <button mat-button (click)="createPriceElasticityRun()" class="add-report"><span class="sif sif-add"></span><span>Price Elasticity RUN</span></button>
    <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
        <ng-template matMenuContent let-report="report">
            <button mat-menu-item [disabled]="report.status!=='Completed'? true : null" (click)="downloadReport(report, false)">Export to Excel</button>
            <button mat-menu-item [disabled]="report.status!=='Completed'? true : null" (click)="downloadReport(report, true)">Export to SharePoint</button>
            <mat-divider class="divider"></mat-divider>
            <button mat-menu-item (click)="duplicate(report)">Duplicate</button>
            <button mat-menu-item (click)="deleteReport(report)">Delete</button>
        </ng-template>
    </mat-menu>
</div>
