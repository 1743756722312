/**
 * Handles common tasks related to caching model data
 */
export class ModelCacheUtil<T extends { id?: string | number }> {
    private cache: Array<T> = [];

    clear(): void {
        this.cache = [];
    }

    get cachedModels(): Array<T> {
        return this.cache;
    }

    findIndex(predicate: (it: T) => boolean): number {
        return this.cache.findIndex(predicate);
    }

    find(predicate: (it: T) => boolean): T {
        return this.cache.find(predicate);
    }

    filter(predicate: (it: T) => boolean): Array<T> {
        return this.cache.filter(predicate);
    }

    append(model: T): void {
        const cachedModelIndex = this.findIndex((it) => {
            return it.id === model.id;
        });
        if (cachedModelIndex !== -1) {
            this.cache.splice(cachedModelIndex, 1, model);
        } else {
            this.cache.push(model);
        }
    }

    appendAll(models: Array<T>): void {
        if (models) {
            models.forEach((model) => {
                this.append(model);
            });
        }
    }

    remove(model: T): void {
        const cachedModelIndex = this.findIndex((it) => {
            return it.id === model.id;
        });
        if (cachedModelIndex !== -1) {
            this.cache.splice(cachedModelIndex, 1);
        }
    }

    refresh(updatedModel: T): T {
        if (this.cachedModels.find(it => it.id === updatedModel.id)) {
            this.append(updatedModel);
            return updatedModel;
        }
        return null;
    }
}
