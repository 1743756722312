import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Scenario} from '@app/models/scenario.model';
import {ScenarioGroup} from '@app/models/scenario-group.model';
import {SkuGroup} from '@app/models/sku-group.model';
import {ModelRunService} from '@app/services/model-run.service';
import {ScenarioService} from '@app/services/scenario.service';
import {ScenarioGroupService} from '@app/services/scenario-group.service';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GenericConfirmationModalComponent} from '@app/components/generic-confirmation-modal/generic-confirmation-modal.component';
import {FormControl, Validators} from '@angular/forms';
import {PriceChangeImpactReport} from '@app/models/price-change-impact-report.model';
import {PriceChangeImpactService} from '@app/services/price-change-impact.service';
import {MetaData} from '@app/models/meta-data.model';
import {SkuConfig} from '@app/models/sku-config.model';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {ReportService} from '@app/services/report.service';
import {ItemGrouping} from '@app/models/item-grouping.model';
import {forkJoin} from 'rxjs';
import {
  GroupBasedScenarioFilterData,
  NonGroupBasedScenarioFilterData
} from '@app/interfaces/scenario-group-filter-data.interface';
import {ModelRunSku} from '@app/models/model-run-sku.model';
import {SimulationRunInputService} from '@app/services/simulation-run-input.service';
import {SimulationRunInput} from '@app/models/simulation-run-input.model';
import {
  PriceChangeImpactReportItemGrouping,
  PriceChangeImpactReportSkuConfig
} from '@app/interfaces/price-change-impact-report.interface';
import {AppConstantsService} from '@app/services/app-constants.service';


const PRICE_POINTS = ['lowest', 'lower', 'low', 'middle', 'high', 'higher', 'highest'];

@Component({
  selector: 'app-create-price-change-impact',
  templateUrl: './create-price-change-impact.component.html',
  styleUrls: ['./create-price-change-impact.component.scss'],
})
export class CreatePriceChangeImpactComponent implements OnInit {

  report: PriceChangeImpactReport;

  validationErrors: Record<string, string>;

  metrics: Array<{ id; data }>;

  metaData: MetaData;

  modelRunSkus: Array<ModelRunSku>;

  scenarios: Array<Scenario>;

  scenarioGroups: Array<ScenarioGroup>;

  skuGroups: Array<SkuGroup>;

  reportSkuConfigs: Array<PriceChangeImpactReportSkuConfig>;

  reportItemGroupings: Array<PriceChangeImpactReportItemGrouping>;

  reportNameMaxLength: number;

  reportDescriptionMaxLength: number;

  disableStartRun: boolean;

  disablePricePoints: boolean;

  existingReports: Array<PriceChangeImpactReport>;

  minPricePoint: number;

  maxPricePoint: number;

  scenarioGroupsData: Array<GroupBasedScenarioFilterData>;

  referenceScenarioData: NonGroupBasedScenarioFilterData;

  searchValue = '';

  get projectId(): number {
    return this.metaData.projectId;
  }

  get modelRunId(): string {
    return this.metaData.modelRunId;
  }

  get selectedReportSkuConfig(): PriceChangeImpactReportSkuConfig {
    if (this.reportSkuConfigs && this.reportSkuConfigs.length) {
      return this.reportSkuConfigs.find((reportSkuConfig) => {
        return reportSkuConfig.groupToChange;
      });
    }
    return null;
  }

  get selectedReportItemGrouping(): PriceChangeImpactReportItemGrouping {
    if (this.reportItemGroupings && this.reportItemGroupings.length) {
      return this.reportItemGroupings.find((reportItemGrouping) => {
        return reportItemGrouping.groupToChange;
      });
    }
    return null;
  }

  constructor(private priceChangeImpactService: PriceChangeImpactService,
              private modelRunService: ModelRunService,
              private scenarioService: ScenarioService,
              private scenarioGroupService: ScenarioGroupService,
              private router: Router,
              private route: ActivatedRoute,
              private uiBlockerService: UiBlockerService,
              private dialog: MatDialog,
              private reportService: ReportService,
              private snackBarService: SnackBarService,
              private simulationRunInputService: SimulationRunInputService,
              private appConstantsService: AppConstantsService) {
  }

  setupNewReport(projectId: number, modelRunId: string): PriceChangeImpactReport {
    const priceChangeImpactReport = new PriceChangeImpactReport();
    priceChangeImpactReport.name = this.priceChangeImpactService.getNewReportName(this.reportNameMaxLength);
    priceChangeImpactReport.description = '';
    priceChangeImpactReport.projectId = projectId;
    priceChangeImpactReport.modelRunId = modelRunId;
    priceChangeImpactReport.metrics = [];
    priceChangeImpactReport.skuGroupId = null;
    priceChangeImpactReport.pricePoints = {
      lowest: null,
      lower: null,
      low: null,
      middle: null,
      high: null,
      higher: null,
      highest: null
    };
    return priceChangeImpactReport;
  }

  duplicateReport(sourceReport: PriceChangeImpactReport): PriceChangeImpactReport {
    const report = new PriceChangeImpactReport();
    report.name = this.priceChangeImpactService.getDuplicateReportName(sourceReport.name, this.reportNameMaxLength);
    report.description = sourceReport.description;
    report.projectId = sourceReport.projectId;
    report.modelRunId = sourceReport.modelRunId;
    report.scenarioId = this.scenarios.find(it => it.id === sourceReport.scenarioId) ? sourceReport.scenarioId : null;
    report.metrics = sourceReport.metrics;
    report.skuGroupId = this.skuGroups.find(it => it.skuGroupId === sourceReport.skuGroupId) ? sourceReport.skuGroupId : (sourceReport.skuGroupId === -1 ? -1 : null);
    report.itemGroupingId = sourceReport.itemGroupingId;
    report.skuId = sourceReport.skuId;
    report.pricePoints = {
      lowest: sourceReport.pricePoints.lowest,
      lower: sourceReport.pricePoints.lower,
      low: sourceReport.pricePoints.low,
      middle: sourceReport.pricePoints.middle,
      high: sourceReport.pricePoints.high,
      higher: sourceReport.pricePoints.higher,
      highest: sourceReport.pricePoints.highest
    };
    return report;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const source = params.source;
      this.scenarios = [];
      this.scenarioGroups = [];
      this.validationErrors = {};
      this.reportItemGroupings = [];
      this.reportSkuConfigs = [];
      this.reportNameMaxLength = 35;
      this.reportDescriptionMaxLength = 50;
      this.disableStartRun = true;
      this.disablePricePoints = true;
      this.metaData = this.route.parent.parent.snapshot.data.metaData;
      this.modelRunSkus = this.route.parent.parent.snapshot.data.modelRunSkus;
      this.skuGroups = this.route.parent.parent.snapshot.data.skuGroups;
      this.metrics = Object.keys(this.metaData.outputConfigurations).map((key: string) => {
        return {id: key, data: this.metaData.outputConfigurations[key]};
      });
      this.report = this.setupNewReport(this.projectId, this.modelRunId);
      forkJoin([this.scenarioService.getAll(this.projectId, this.modelRunId),
        this.scenarioGroupService.getAll(this.projectId, this.modelRunId),
        this.priceChangeImpactService.getAll(this.projectId, this.modelRunId)
      ]).subscribe(([scenarios, scenarioGroups, reports]) => {
        this.scenarios = scenarios;
        this.scenarioGroups = scenarioGroups;
        this.existingReports = reports;

        if (source) {
          this.priceChangeImpactService.getById(source, {
            projectId: this.projectId,
            modelRunId: this.modelRunId
          }).subscribe((sourceReport: PriceChangeImpactReport) => {
            this.report = this.duplicateReport(sourceReport);
            const skuId = this.report.skuId;
            const itemGroupingId = this.report.itemGroupingId;
            const pricePoints = Object.assign({}, this.report.pricePoints);

            this.changeScenario(this.report.scenarioId).then(() => {
              this.onMetricChange(this.report.metrics);
              if (this.report.skuGroupId === -1) {
                this.onSkuChange(skuId);
              } else {
                this.onItemGroupChange(itemGroupingId);
              }
              this.report.pricePoints = pricePoints;

            }).catch(() => {
              //do nothing
            });
          });
        }
        this.prepareScenarioGroups('');
      });
    });
  }

  validatePricePoints(): void {
    this.validatePricePoint('lowest');
    this.validatePricePoint('lower');
    this.validatePricePoint('low');
    this.validatePricePoint('middle');
    this.validatePricePoint('high');
    this.validatePricePoint('higher');
    this.validatePricePoint('highest');
  }

  search(searchValue: string): void {
    this.searchValue = searchValue;
    this.prepareScenarioGroups(this.searchValue);
  }

  prepareScenarioGroups(filterString: string): void {
    const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups, filterString);
    this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
    this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
  }

  applyReportName(name: string, focusOut: boolean): void {
    if (focusOut && name.trim() === '') {
      name = this.priceChangeImpactService.getNewReportName(this.reportNameMaxLength);
    }
    this.report.name = name;
    this.validateReportName();
  }

  applyReportDescription(description: string): void {
    this.report.description = description;
    this.validateReportDescription();
  }

  resetPricePoints(): void {
    this.report.pricePoints = {
      lowest: null,
      lower: null,
      low: null,
      middle: null,
      high: null,
      higher: null,
      highest: null
    };
  }

  resetSkuAndItemSelection(): void {
    this.report.skuId = undefined;
    this.report.itemGroupingId = undefined;
  }

  changeScenario(scenarioId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (scenarioId && this.scenarios.find(it => it.id === scenarioId)) {
        this.report.scenarioId = scenarioId;
        this.uiBlockerService.block();
        this.simulationRunInputService.fetchAll(this.projectId, this.modelRunId, scenarioId).subscribe((simulationRunInputs: SimulationRunInput[]) => {
          const filteredSkuConfigs = this.scenarioService.generateSkuConfigs(this.modelRunSkus, simulationRunInputs, []).filter((it) => it.isSelected)
            .reduce((acc: Array<PriceChangeImpactReportSkuConfig>, skuConfig: SkuConfig) => {
              acc.push({
                sku: skuConfig,
                groupToChange: this.report.skuGroupId === -1 && this.report.skuId && this.report.skuId === skuConfig.skuId
              });
              return acc;
            }, []);
          this.reportSkuConfigs = [...filteredSkuConfigs];

          if (this.report.skuGroupId) {
            // since reportSkuConfigs have been updated, we will need to reset reportItemGroupings
            // so that we can rerun the checks for reportItemGroupings if they need to be disabled/enabled
            this.changeGroup(this.report.skuGroupId);
          }
          if ((this.selectedReportSkuConfig && this.report.skuGroupId === -1) ||
            (this.selectedReportItemGrouping && this.report.skuGroupId > 0)) {
            this.populatePricePointsToTest();
          } else {
            this.resetPricePoints();
            this.resetSkuAndItemSelection();
          }
          this.toggleStartRunButton();
          this.uiBlockerService.unblock();
          resolve();
        });
      } else {
        reject();
      }
    });
  }

  async changeGroup(skuGroupId: number): Promise<void> {
    const reportSkuConfigs = this.reportSkuConfigs || [];
    this.report.skuGroupId = skuGroupId;

    if (this.report.skuGroupId > -1) {
      this.reportItemGroupings = this.skuGroups
        .find(skuGroup => skuGroup.skuGroupId === skuGroupId)
        .itemGroupings
        .reduce((acc: Array<PriceChangeImpactReportItemGrouping>, itemGrouping: ItemGrouping) => {
          acc.push({
            ...itemGrouping,
            groupToChange: false,
            disabled: !reportSkuConfigs.find((skuConfig) => {
              return skuConfig.sku.groups.find((group) => {
                return group.skuGroupId === skuGroupId && group.itemGroupingId === itemGrouping.itemGroupingId;
              });
            })
          });
          return acc;
        }, []);
      this.resetPricePoints();
    }
    this.resetSkuAndItemSelection();
    await this.toggleStartRunButton();
  }

  onSkuChange(skuId: number): void {
    /**
     *  NOTE: make sure skuId exists and that it is selected.
     *  When duplicating report, the selected sku might not be selected any more.
     */
    this.report.skuId = this.reportSkuConfigs.find(it => it.sku.skuId === skuId) ? skuId : null;
    this.report.itemGroupingId = null;
    this.reportSkuConfigs.forEach((reportSkuConfig) => {
      reportSkuConfig.groupToChange = (skuId === reportSkuConfig.sku.skuId);
    });
    this.populatePricePointsToTest();
    this.toggleStartRunButton();
    this.validatePricePoints();
  }

  onItemGroupChange(itemGroupingId: number): void {
    /**
     *  NOTE: make sure item grouping exists.
     *  When duplicating report, the selected grouping might have been removed.
     */
    this.report.skuId = null;
    this.report.itemGroupingId = this.reportItemGroupings.find(it => it.itemGroupingId === itemGroupingId) ? itemGroupingId : null;
    this.reportItemGroupings.forEach((reportItemGroup) => {
      reportItemGroup.groupToChange = (itemGroupingId === reportItemGroup.itemGroupingId);
    });
    this.populatePricePointsToTest();
    this.toggleStartRunButton();
    this.validatePricePoints();
  }

  /**
   * @see https://git1.affinnova.com/OnlineDCSimulator/VBAProject/blob/master/Reporting.bas#L244
   */
  populatePricePointsToTest(): void {
    const skus = this.reportSkuConfigs.map(skuConfig => skuConfig.sku);
    let skusToSimulate = [];
    this.disablePricePoints = true;

    if (this.report.scenarioId) {
      if (this.report.skuGroupId === -1 && this.report.skuId) {
        // if item level is selected as item characteristics
        if (this.selectedReportSkuConfig) {
          skusToSimulate.push(this.selectedReportSkuConfig.sku);
        }
      } else if (this.report.skuGroupId > -1 && this.report.itemGroupingId) {
        // if item grouping is selected as item characteristics
        skusToSimulate = skus.filter((sku) => {
          return sku.groups.find((group) => {
            return group.skuGroupId === this.report.skuGroupId && group.itemGroupingId === this.report.itemGroupingId;
          });
        });
      }

      if (skusToSimulate.length) {
        this.disablePricePoints = false;
        const pricePoints = this.priceChangeImpactService.getPricePointsToTestForSkus(skusToSimulate);
        this.report.pricePoints = this.priceChangeImpactService.getRoundedPricePointRange(pricePoints);
        this.minPricePoint = pricePoints.lowest;
        this.maxPricePoint = pricePoints.highest;
      }
    }
  }

  closeReport(): void {
    this.router.navigateByUrl(`/projects/${this.projectId}/runs/${this.modelRunId}/reports/price-change-impact`);
  }

  generateReport(): void {
    this.uiBlockerService.block();
    this.report.name = this.report.name.trim();
    this.report.description = this.report.description?.trim();
    this.priceChangeImpactService.add(this.report).subscribe((report: PriceChangeImpactReport) => {
      this.snackBarService.openSnackBar('Price change impact run submitted successfully.', 'success');
      this.report = report;
      this.uiBlockerService.unblock();
      this.closeReport();
    }, () => {
      this.uiBlockerService.unblock();
    });
  }

  onMetricChange(metrics: Array<string>): void {
    this.report.metrics = metrics;
    this.toggleStartRunButton();
  }

  async canDeactivate(): Promise<boolean> {
    /**
     * Once the report is successfully generated, we don't want to show confirmation when redirecting to the list route.
     */
    if (this.report.id) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Exit Price Change Impact Run Setup?',
      body: 'Changes will not be saved. Press \'Exit\' to continue, otherwise press \'Cancel\'.',
      confirmButtonLabel: 'EXIT',
      cancelButtonLabel: 'CANCEL'
    };
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(value => {
        const isExiting = value === 'EXIT';
        if (!isExiting) {
          // set the selected tab to the price change tab if user decides to cancel out of transition
          this.reportService.activeReportSubject.next('price-change-impact');
        }
        resolve(isExiting);
      });
    });
  }

  async validateModelReportName(): Promise<string> {
    return new Promise<string>((resolve) => {
      const name = this.report.name;
      const maxLength = this.reportNameMaxLength;
      const reports = this.existingReports || [];
      const preExistingReportWithSameName = reports.find((report: PriceChangeImpactReport) => {
        return report.name.toLowerCase().trim() === name.toLowerCase().trim();
      });
      if (preExistingReportWithSameName) {
        resolve(this.appConstantsService.REPORT_ALREADY_EXISTS);
      } else {
        const validate = new FormControl(name, [
          Validators.maxLength(maxLength),
          Validators.required
        ]);
        resolve(validate.invalid ? validate.errors.required ? `Report name is required.` : `The maximum number of characters allowed is ${maxLength}.` : null);
      }
    });
  }

  validateModelReportDescription(): string {
    let reportDescriptionError = null;
    const reportDescriptionMaxLength = this.reportDescriptionMaxLength;
    const description = this.report.description;
    if (description && description.length > reportDescriptionMaxLength) {
      reportDescriptionError = `The maximum number of characters allowed is ${reportDescriptionMaxLength}.`;
    }
    return reportDescriptionError;
  }

  async validateModelScenario(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.scenarioId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Scenario is required.' : null);
    });
  }

  async validateModelItemCharacteristics(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.skuGroupId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Item Characteristics is required.' : null);
    });
  }

  async validateModelMetrics(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.metrics.length, [
        Validators.min(1)
      ]);
      return resolve(validate.invalid ? 'Metrics is required.' : null);
    });
  }

  async validateModelGroupToChange(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.skuId || this.report.itemGroupingId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Group to change is required.' : null);
    });
  }

  async validateModelPricePoint(range: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const pricePoints = this.report.pricePoints;
      const currentPointIndex = PRICE_POINTS.indexOf(range);
      const min = currentPointIndex === 0 ? this.minPricePoint : pricePoints[PRICE_POINTS[currentPointIndex - 1]];
      const max = currentPointIndex === PRICE_POINTS.length - 1 ? this.maxPricePoint : pricePoints[PRICE_POINTS[currentPointIndex + 1]];
      const validate = new FormControl(pricePoints[range] === null ? null : +pricePoints[range], [
        Validators.required,
        Validators.pattern(/^((|-?\d+)|((|-?\d+)\.(\d+)))$/),
        Validators.min(+min),
        Validators.max(+max)
      ]);
      resolve(validate.invalid ? `The % range entered falls out of the tested range of ${min}% - ${max}%.` : null);
    });
  }

  async validateModelPricePoints(): Promise<string> {
    return new Promise<string>((resolve) => {
      let error = null;
      const max = this.maxPricePoint;
      const min = this.minPricePoint;
      const ranges = this.priceChangeImpactService.pricePointRanges;
      const pricePoints = this.report.pricePoints;

      if (ranges.reduce((acc, range) => acc && !isNaN(pricePoints[range]), true)) {
        for (let i = 0; i < ranges.length - 1; i++) {
          if (!(+pricePoints[ranges[i + 1]] >= +pricePoints[ranges[i]])) {
            error = `Price point at "${ranges[i + 1]}" must be higher than price point at "${ranges[i]}".`;
          }
        }
        if (!error && (+pricePoints.lowest < +min || +pricePoints.highest > +max)) {
          error = `The % range entered falls out of the tested range of ${min}% - ${max}%.`;
        }
      } else {
        error = `The % range entered falls out of the tested range of ${min}% - ${max}%.`;
      }
      return resolve(error);
    });
  }

  async validateReportName(): Promise<void> {
    const errorMsg = await this.validateModelReportName();
    this.validationErrors.name = errorMsg;
    await this.toggleStartRunButton();
  }

  async validateReportDescription(): Promise<void> {
    const errorMsg = await this.validateModelReportDescription();
    this.validationErrors.description = errorMsg;
    await this.toggleStartRunButton();
  }

  async validatePricePoint(point: string): Promise<void> {
    let errorMsg = await this.validateModelPricePoint(point);
    this.validationErrors[point] = errorMsg;
    errorMsg = await this.validateModelPricePoints();
    this.validationErrors.pricePoints = errorMsg;
    await this.toggleStartRunButton();
  }

  async toggleStartRunButton(): Promise<void> {
    const errMsg = (
      await this.validateModelReportName() ||
      await this.validateModelScenario() ||
      await this.validateModelItemCharacteristics() ||
      await this.validateModelMetrics() ||
      await this.validateModelGroupToChange() ||
      await this.validateModelPricePoint('lowest') ||
      await this.validateModelPricePoint('lower') ||
      await this.validateModelPricePoint('low') ||
      await this.validateModelPricePoint('middle') ||
      await this.validateModelPricePoint('high') ||
      await this.validateModelPricePoint('higher') ||
      await this.validateModelPricePoint('highest') ||
      await this.validateModelPricePoints()
    );
    this.disableStartRun = !!errMsg;
  }
}
