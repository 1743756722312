import {Pipe, PipeTransform} from '@angular/core';
import {ProjectService} from '../services/project.service';
import {ScenarioService} from '@app/services/scenario.service';
import {AppConstantsService} from '@app/services/app-constants.service';

@Pipe({
  name: 'getScenarioOwnerName',
  pure: true
})
export class GetScenarioOwnerNamePipe implements PipeTransform {

  private name = '';

  constructor(private projectService: ProjectService,
              private scenarioService: ScenarioService,
              private appConstantsService: AppConstantsService) {
  }

  transform(scenarioId: string, showInternalUserView: boolean): string {
    const internalUserClientViewName = 'BASES';
    const scenario = this.scenarioService.scenarios.find(it => it.id === scenarioId);
    if (scenario) {
      if ([
        this.appConstantsService.REFERENCE_SCENARIO_NAME,
        this.appConstantsService.CALIBRATION_SCENARIO_NAME
      ].indexOf(scenario.name) !== -1) {
        this.name = internalUserClientViewName;
      } else {
        if (scenario.createdBy) {
          const collaborator = this.projectService.collaborators.find(it => it.userManagementId === scenario.createdBy);
          if (collaborator) {
            if (collaborator.isInternalUser) {
              this.name = showInternalUserView ? collaborator.fullName : internalUserClientViewName;
            } else {
              this.name = collaborator.fullName;
            }
          } else {
            // fetch it from user management service because the collaborator might have been removed
          }
        }
      }
    }
    return this.name;
  }
}
