import {ItemGrouping} from './item-grouping.model';

export class SkuGroup {
  id: string;
  projectId: number;
  modelRunId: string;
  skuGroupId: number;
  name: string;
  displayName: string;
  showInSimulator: boolean;
  itemGroupings: Array<ItemGrouping>;
  dateCreated: string;
  lastUpdated: string;
}
