export class PriceChangeImpactReport {
  id: string;
  projectId: number;
  modelRunId: string;
  scenarioId: string;
  name: string;
  description: string;
  skuGroupId: number;
  metrics: Array<string>;
  status: string;
  skuId: number;
  itemGroupingId: number;
  pricePoints: Record<string, number>;
  scenarioOutOfSync: boolean;
  selectedSegments: string;
}
