<div class="population-filters">
  <p class="n-size text" [class]="nSizeClass">{{nSizeText}}</p>
  <div>
    <app-ns-dropdown
      class="filter-item"
      *ngFor="let filter of filters"
      [dropDownData]="filter"
      [disable]="disable"
      (selectionChange)="onFilterSelectionChange($event)"></app-ns-dropdown>
  </div>
</div>
