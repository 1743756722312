<div class="mr-t-2 calibration-container">
    <div class="tool-bar create-calibration">
        <div class="promo-header">
            <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnToCalibration()"><span
                    class="sif sif-arrow-w"></span></button>
            <h2>Adjust Promotions</h2>
        </div>
        <div mat-dialog-actions align="end">
            <button mat-button (click)="returnToCalibration()">Cancel</button>
            <button [disabled]="disableApply || !dataChanged" mat-raised-button cdkFocusInitial color="primary"
                    (click)="savePromotionsToSimulator()">Apply
            </button>
        </div>
    </div>

    <div class="promo-hot-table-container">
        <ng-template [ngIf]="hotTableData && hotTableData.length > 0">
            <app-promotions-config-table
                    [hotTableData]="hotTableData"
                    [tableSettings]="tableSettings"
                    (errorEvent)="tableErrorEventHandler($event)"
                    (tableChangesEvent)="tableDataChangeEventHandler($event)"
                    [reloadTableSubject]="reloadTable">
            </app-promotions-config-table>
        </ng-template>
    </div>
</div>