import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {EnvironmentService} from './environment.service';
import {OptimizationReport} from './../models/optimization-report.model';
import {PriceChangeImpactReport} from './../models/price-change-impact-report.model';
import {PriceElasticityReport} from './../models/price-elasticity-report.model';
import {SourcingMatrixReport} from './../models/sourcing-matrix-report.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private environmentService: EnvironmentService) {
  }

  getRouteParameter(route: ActivatedRouteSnapshot, name: string): string {
    let currentRoute = route;
    let param = null;
    while (currentRoute) {
      param = currentRoute.paramMap.get(name);
      if (param) {
        return param;
      } else {
        currentRoute = currentRoute.parent;
      }
    }
  }

  /**
   * Generates report name.
   * for eg: for report names:
   * Untitled Report=> Untitled Report 0, Untitled Report 0 pre exists then Untitled Report 1
   * */
  generateReportName(prependName: string, maxLength: number, cachedReports: Array<OptimizationReport | PriceChangeImpactReport | PriceElasticityReport | SourcingMatrixReport>): string {
    let count = 0;
    let newName = null;
    let loop = true;
    while (loop) {
      newName = `${prependName} ${count}`;
      if (newName.length > maxLength) {
        prependName = prependName.substr(0, prependName.length - (newName.length - maxLength));
        newName = `${prependName} ${count}`;
      }
      if (cachedReports.find((report) => {
        return report.name.toLowerCase().trim() === newName.toLowerCase().trim();
      })) {
        count++;
      } else {
        loop = false;
      }
    }
    return newName;
  }

  /**
   * Generates a duplicate report name based on the given report name.
   * for eg: for report names:
   * Untitled Report 0 => Untitled Report 0(1), if Untitled Report 0(1) pre exists then Untitled Report 0(2)
   * Untitled Report 0(1) => Untitled Report 0(1)(1), if Untitled Report 0(1)(1) pre exists then Untitled Report 0(1)(2)
   * */
  generateDuplicateReportName(prependName: string, maxLength: number, cachedReports: Array<OptimizationReport | PriceChangeImpactReport | PriceElasticityReport | SourcingMatrixReport>): string {
    const regex = /(\(\d+\))+$/;
    const found = prependName.match(regex);
    const append = found ? found[0] : '';
    let prepend = prependName.replace(regex, '');
    let count = 1;
    let newName = null;
    let loop = true;
    while (loop) {
      newName = `${prepend}${append}(${count})`;
      if (newName.length > maxLength) {
        prepend = prepend.substr(0, prepend.length - (newName.length - maxLength));
        newName = `${prepend}${append}(${count})`;
      }
      if (cachedReports.find((report) => {
        return report.name.toLowerCase().trim() === newName.toLowerCase().trim();
      })) {
        count++;
      } else {
        loop = false;
      }
    }
    return newName;
  }

  getModelRunUriBasedPath(projectId: number, modelRunId: string, path: string): string {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}`;
    if (path) {
      return `${url}/${path}`;
    }
    return url;
  }
}
