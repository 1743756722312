import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnvironmentService} from './../services/environment.service';
import {HttpClient} from '@angular/common/http';
import {MetaData} from '../models/meta-data.model';
import {AppConstantsService} from '@app/services/app-constants.service';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  private cache: MetaData;

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private appConstantsService: AppConstantsService) {
    this.cache = null;
  }

  fetch(projectId: number, modelRunId: string): Observable<MetaData> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/metaData`;
    return this.http.get<MetaData>(url).pipe(map((metaData: MetaData) => {
      this.cache = metaData;
      return metaData;
    }));
  }

  /**
   * Lists all price elasticity reports associated with given project and run id.
   */
  get(projectId: number, modelRunId: string): Observable<MetaData> {
    if (this.cache && this.cache.projectId === projectId && this.cache.modelRunId === modelRunId) {
      return of(this.cache);
    } else {
      return this.fetch(projectId, modelRunId);
    }
  }

  /**
   * Indicates which output column types needs to be shown when non-sample population filter is toggled.
   * Values could be : Shares only|Preference Share only|Shares & Absolutes
   * */
  outputsOnNonSampleFilterToggle(metaData: MetaData): string {
    const outputConfigurations = Object.keys(metaData.outputConfigurations).map(it => metaData.outputConfigurations[it]);
    const allSharesAndAbsolutes = outputConfigurations.every(config => config.showOnNonSampleFilterToggle);
    const sharesOnly = outputConfigurations.filter(config => config.label.toLowerCase().indexOf('share') !== -1)
      .every(config => config.showOnNonSampleFilterToggle);
    const preferenceShareOnly = outputConfigurations.find(config => config.label === this.appConstantsService.PREFERENCE_SHARE);
    if (allSharesAndAbsolutes) {
      return this.appConstantsService.SHARES_AND_ABSOLUTES;
    } else if (sharesOnly) {
      return this.appConstantsService.SHARES_ONLY;
    } else if (preferenceShareOnly && preferenceShareOnly.showOnNonSampleFilterToggle) {
      return this.appConstantsService.PREFERENCE_SHARE_ONLY;
    }
  }

  tooltipOnNonSampleFilterEngage(param: string | MetaData): string {
    const outputsOnNonSampleFilterToggle = (typeof param) === "string" ? param : this.outputsOnNonSampleFilterToggle(param as MetaData);
    let tooltip = "";
    if (outputsOnNonSampleFilterToggle === this.appConstantsService.SHARES_ONLY) {
      tooltip = "Only share-based results are available when engaging filters";
    } else if (outputsOnNonSampleFilterToggle === this.appConstantsService.PREFERENCE_SHARE_ONLY) {
      tooltip = "Only Preference Share results are available when engaging filters";
    }else if(outputsOnNonSampleFilterToggle === this.appConstantsService.FOR_ABSOLUTES_INDICES_ONLY){
      tooltip = "Compare scenarios to view indices for this metric";
    }
    return tooltip;
  }

  isPlugAndPlay(metaData: MetaData): boolean {
    return metaData.hasCoeffsFnd;
  }
}
