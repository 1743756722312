import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UtilService} from '../../../services/util.service';
import {PriceElasticityReport} from '../../../models/price-elasticity-report.model';
import {PriceElasticityService} from '../../../services/price-elasticity.service';
import {UiBlockerService} from '../../../services/ui-blocker.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PriceElasticityResolver implements Resolve<Observable<Array<PriceElasticityReport>>> {

  constructor(private service: PriceElasticityService, private utilService: UtilService, private uiBlockerService: UiBlockerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<PriceElasticityReport>> {
    this.uiBlockerService.block();
    const projectId = this.utilService.getRouteParameter(route, 'projectId');
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    return this.service.fetchAll(+projectId, modelRunId).pipe(map((reports: PriceElasticityReport[]) => {
      this.uiBlockerService.unblock();
      return reports;
    }));
  }
}
