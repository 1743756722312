import {Pipe, PipeTransform} from '@angular/core';
import {MetaData} from '../models/meta-data.model';

@Pipe({
  name: 'getMetricDisplayName',
  pure: true
})
export class GetMetricDisplayNamePipe implements PipeTransform {

  transform(metrics: string | Array<string>, metaData?: MetaData): string {
    const displayNames = [];
    const _metrics = (typeof metrics === 'string') ? metrics.split(',') : metrics;
    _metrics.forEach((metric) => {
      displayNames.push(metaData.outputConfigurations[metric].displayName);
    });
    return displayNames.join(', ');
  }
}
