<h2 mat-dialog-title>New Scenario</h2>
<mat-dialog-content class="mat-typography">
      <div class="create-scenario-form">
        <mat-form-field id="basedOnScenarioId" appearance="standard">
          <label>Based On</label>
          <mat-select class="mat-select" id="selectedScenario" [(ngModel)]="selectedScenarioId"
                      disableOptionCentering panelClass="select-bottom">
            <mat-option *ngIf='referenceScenarioData && referenceScenarioData.scenario' [value]="referenceScenarioData.scenario.id">
              {{referenceScenarioData.scenario.name}}
            </mat-option>
            <mat-optgroup class="scenario-group-optgroup" *ngFor="let scenarioGroup of scenarioGroupsData" [ngClass]="scenarioGroup.isVisible? 'show' : 'hide'">
              <span class="opt-group-btn" mat-button>{{scenarioGroup.name}}</span>
              <mat-option
                *ngFor="let scenarioData of scenarioGroup.scenarios"
                [value]="scenarioData.scenario.id" panelClass="scenario-group">
                {{scenarioData.scenario.name}}
              </mat-option>
            </mat-optgroup>
            </mat-select>
         </mat-form-field>
         <mat-form-field  id="scenarioNameField" appearance="standard">
            <label>Name</label>
            <input matInput
                   #newScenarioName
                   class="mat-input-element"
                   [ngClass]="{'warning':validations.name.warning,'error':validations.name.error}"
                   placeholder="Enter Scenario name"
                   id="scenarioName"
                   [(ngModel)]="scenarioName"
                   required
                   (keyup) ="validateName(scenarioName)">
           <div class="flex">
             <mat-error *ngIf="validations.name.error">
               {{validations.name.error}}
           </mat-error>
           <mat-hint>{{newScenarioName.value.length}} / 50</mat-hint>
           </div>

         </mat-form-field>
         <mat-form-field id="scenarioDescField" class="padding-border-0" appearance="standard">
            <label>Description</label>
            <input matInput
                   #newScenarioDescription
                   class="mat-input-element"
                   [ngClass]="{'warning':validations.description.warning,'error':validations.description.error}"
                   placeholder="Enter Description"
                   id="scenarioDescription"
                   [(ngModel)]="scenarioDescription"
                   (keyup) ="validateDescription(scenarioDescription)">
           <div class="flex">
             <mat-error *ngIf="validations.description.error">
               {{validations.description.error}}
             </mat-error>
             <mat-hint align="end">{{newScenarioDescription.value.length}} / 50</mat-hint>
           </div>
         </mat-form-field>
         <mat-form-field id="assignToGroupsField" class="padding-border-0" appearance="standard">
            <label class="label-badge">Assign to Folder(s)</label>
            <mat-select class="mat-select" disableOptionCentering panelClass="assign-groups-dropdown"
             (selectionChange)="onScenarioGroupChange()"  multiple [(ngModel)]="selectedScenarioGroups">
               <mat-select-trigger>
                 <span *ngIf="selectedScenarioGroups.length ===0" >Select Scenario Group(s)</span>
                <span *ngIf="selectedScenarioGroups.length ===1" >{{selectedScenarioGroups.length ? selectedScenarioGroups[0].scenarioGroup.name : 'Selected'}}</span>
                <span *ngIf="selectedScenarioGroups.length > 1">
                  {{selectedScenarioGroups.length}} Groups Selected
                </span>
               </mat-select-trigger>
               <mat-option [disabled]="scenarioGroupAssignment.disabled" *ngFor="let scenarioGroupAssignment of scenarioGroupsAssignment" [value]="scenarioGroupAssignment">
                 {{scenarioGroupAssignment.scenarioGroup.name}}</mat-option>
            </mat-select>
         </mat-form-field>
      </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
   <button id="create-scenario-dialog-cancel" mat-button mat-dialog-close>Cancel</button>
   <button id="create-scenario-save" mat-raised-button [disabled]="hasError" color="primary" (click)="createScenario()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
