import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../services/report.service';

@Component({
  selector: 'app-optimization',
  templateUrl: './optimization.component.html',
  styleUrls: ['./optimization.component.scss']
})
export class OptimizationComponent implements OnInit {

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.reportService.activeReportSubject.next('optimization');
  }

}
