import {AfterViewChecked, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModelRunService} from './../../../../../services/model-run.service';
import {Scenario} from './../../../../../models/scenario.model';
import {Segment} from './../../../../../models/segment.model';
import {DropdownData} from './../../../../../components/dropdown/dropdown.data.model';
import {ScenarioGroup} from './../../../../../models/scenario-group.model';
import {MatDialog} from '@angular/material/dialog';
import {CreateScenarioComponent} from './../../../../../components/create-scenario/create-scenario.component';
import {ScenarioService} from '../../../../../services/scenario.service';
import {forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {ScenarioGroupService} from '../../../../../services/scenario-group.service';
import {ModelRun} from '@app/models/model-run.model';
import {ReadFile} from 'ngx-file-helpers';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {SkuGroup} from '@app/models/sku-group.model';
import {AccessPolicyService} from '@app/services/access-policy.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {Permission} from '@app/models/permission.model';
import {MetaData} from '@app/models/meta-data.model';
import {SkuGroupService} from '@app/services/sku-group.service';
import {ExportScenarioModalComponent} from '@app/components/export-scenario-modal/export-scenario-modal.component';
import {delay, filter, repeatWhen, take} from "rxjs/operators";
import {AsyncTask} from '@app/models/async-task';
import {AsyncTaskService} from '@app/services/async-task.service';
import {UtilService} from '@app/services/util.service';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {MetaDataService} from '@app/services/meta-data.service';
import {WebMessageService} from '@app/services/web-message.service';
import {
    GroupBasedScenarioFilterData,
    NonGroupBasedScenarioFilterData
} from '@app/interfaces/scenario-group-filter-data.interface';
import {ModelRunSku} from '@app/models/model-run-sku.model';
import {GeneralSetting} from '@app/models/general-setting.model';
import {GeneralSettingService} from '@app/services/general-setting.service';
import {Calibration} from '@app/models/calibration/calibration.model';
import {CalibrationService} from '@app/services/calibration.service';
import {UserManagementService} from '@app/services/user-management.service';

const SCENARIO_EXPORT_DOWNLOAD_POLL_PERIOD = 10000;
const LOW_POPULATION_THRESHOLD = 75;
const SCENARIO_LOCK_MSG_GROUP = 'scenario-lock';

@Component({
    selector: 'app-scenarios',
    templateUrl: './scenarios.component.html',
    styleUrls: ['./scenarios.component.scss']
})
export class ScenariosComponent implements OnInit, OnDestroy, AfterViewChecked {
    scenarios: Array<Scenario>;
    scenarioGroups: ScenarioGroup[];
    scenarioPermission: any;
    selectedScenarioId: string;
    selectedScenario: Scenario;
    hasSkuConfigInputValidationError = false;
    disableSimulateAndSave: boolean;
    disableBulkEdit: boolean;
    showPopulationFilter: boolean;
    projectId: number;
    modelRunId: string;
    modelRun: ModelRun;
    segments: Segment[];
    nSize: number;
    filters: Array<DropdownData<string>>;
    compareViewFilters: Array<DropdownData<string>>;
    bulkEditStatus = false;
    groupItemStatus = false;
    subscriptions: Subscription;
    importScenarioFailureMessage: string = null;
    skuGroups: Array<SkuGroup>;
    visibleSkuGroups: Array<SkuGroup>;
    metaData: MetaData;
    scenarioCompareView = false;
    isSelectedScenarioAvailable = true;
    scenarioGroupsData: Array<GroupBasedScenarioFilterData>;
    referenceScenarioData: NonGroupBasedScenarioFilterData;
    searchValue = '';
    userActivity;
    userInactive: Subject<any> = new Subject();
    disableLockScenario: boolean;
    scenarioLockedForEdit: boolean;
    lockMessage: string;
    lowPopulation = false;
    lowPopulationMsgType = 'warn';
    lowPopulationMsg: string;
    totalNSize: number;
    importScenarioPolling: any;
    reorderItemsPage = false;
    outputsFilteredOnNonSampleFilterToggleTooltip: string;
    resetPopulationFiltersStatus = false;
    hidePopulationFiltersReset = false;
    showCalibrateButton = false;
    disableCalibration = false;
    calibrationTooltip = 'Calibration';
    disableReport = false;
    reportTooltip = 'Create Report';
    disableCompareScenario = false;
    compareScenarioTooltip = 'Compare Scenarios';

    enableAdvanceSettings = false;
    enableAdvanceSettingsAdminView = false;
    modelRunSkus: Array<ModelRunSku>;
    generalSetting: GeneralSetting;
    simulateAndSaveButtonLabel: string;
    slideOpenAdvanceSettings = false;
    calibration: Calibration;
    hasCalibrationStarted = false;
    scenarioUsedInCalibrationTooltip = '';
    runDataNotAvailable = false;
    compareScenariosReturn = false;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private modelRunService: ModelRunService,
        private scenarioService: ScenarioService,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private scenarioGroupService: ScenarioGroupService,
        private uiBlockerService: UiBlockerService,
        private snackBarService: SnackBarService,
        private authProxyService: AuthProxyService,
        private accessPolicyService: AccessPolicyService,
        private appConstantsService: AppConstantsService,
        private skuGroupService: SkuGroupService,
        private ngZone: NgZone,
        private asyncTaskService: AsyncTaskService,
        private utilService: UtilService,
        private metaDataService: MetaDataService,
        private userConfigurationsService: UserConfigurationsService,
        private generalSettingService: GeneralSettingService,
        private webMessageService: WebMessageService,
        private calibrationService: CalibrationService,
        private userManagementService: UserManagementService) {
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    setActionButtonsState(modelRun: ModelRun) {
        this.runDataNotAvailable = !modelRun.runId;
        this.showCalibrateButton = this.authProxyService.userHasFeature('LPO_CALIBRATION') && this.authProxyService.user.isInternalUser;
        this.disableCalibration = this.runDataNotAvailable;
        this.calibrationTooltip = this.disableCalibration ? this.appConstantsService.RUN_NOT_AVAILABLE_LABEL : 'Calibration';

        this.disableReport = !this.isSelectedScenarioAvailable || this.runDataNotAvailable;
        this.reportTooltip = this.runDataNotAvailable ? this.appConstantsService.RUN_NOT_AVAILABLE_LABEL : 'Create Report';

        this.disableCompareScenario = !this.isSelectedScenarioAvailable || this.runDataNotAvailable;
        this.compareScenarioTooltip = this.runDataNotAvailable ? this.appConstantsService.RUN_NOT_AVAILABLE_LABEL : 'Compare Scenario';
    }

    ngOnInit(): void {
        this.enableAdvanceSettings = this.authProxyService.user.isInternalUser;
        this.enableAdvanceSettingsAdminView = this.authProxyService.user.isInternalUser && (this.authProxyService.userIsAdminUser() || this.authProxyService.userHasAllProjectAccessRole());
        this.disableLockScenario = true;
        this.projectId = +this.utilService.getRouteParameter(this.route.snapshot, 'projectId');
        this.modelRunId = this.utilService.getRouteParameter(this.route.snapshot, 'modelRunId');
        this.segments = this.route.snapshot.data.segments;
        this.metaData = this.route.parent.snapshot.data.metaData;
        this.modelRunSkus = this.route.parent.snapshot.data.modelRunSkus;
        this.generalSetting = this.route.parent.snapshot.data.generalSetting;
        this.calibration = this.route.snapshot.data.calibration;
        this.hasCalibrationStarted = this.calibrationService.started(this.calibration);
        this.subscriptions = new Subscription();
        this.runDataNotAvailable = !this.modelRunId;
        this.webMessageService.consumeMessage(`${this.appConstantsService.CALIBRATION_MSG_GROUP}-${this.modelRunId}`).subscribe({
            next: (eventData) => {
                const responseJson = JSON.parse(eventData).data;
                if (responseJson.modelRunId === this.modelRunId) {
                    this.calibrationService.fetch(this.projectId, this.modelRunId).subscribe((calibration: Calibration) => {
                        this.calibration = calibration;
                        this.hasCalibrationStarted = this.calibrationService.started(this.calibration);
                        const lockedByOther = (this.selectedScenario.lockedBy && this.selectedScenario.lockedBy !== this.authProxyService.user.userId);
                        this.disableLockScenario = !this.selectedScenario.editable || lockedByOther || !this.scenarioPermission.edit || this.isScenarioInCalibration(this.selectedScenario);
                        this.scenarioUsedInCalibrationTooltip = this.isScenarioInCalibration(this.selectedScenario) ? 'Scenarios used for calibration are locked once calibration has started' : '';
                    });
                }
            }
        });

        if (this.segments.length) {
            this.subscriptions.add(this.modelRunService.getNSize(this.projectId, this.modelRunId, this.segments.map(it => it.segment)).subscribe((data: any) => {
                this.totalNSize = data.nSize;
            }));
        }
        this.subscriptions.add(this.scenarioService.skuConfigInputValidationError.subscribe({
            next: (hasSkuConfigInputValidationError: boolean) => {
                this.hasSkuConfigInputValidationError = hasSkuConfigInputValidationError;
                this.toggleDisableSimulateAndSave();
            }
        }));
        this.subscriptions.add(this.scenarioService.activeScenario.subscribe({
            next: (scenarioId: string) => {
                this.selectedScenarioId = scenarioId;
            }
        }));
        /** on new scenario creation we need to update the scenarios and scenarioGroups and permissions */
        this.subscriptions.add(this.scenarioService.onCreateScenario().subscribe((data) => {
            this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId).subscribe(() => {
                this.scenarioService.getScenarios(this.modelRunId, false).subscribe((scenarios: Scenario[]) => {
                    this.scenarios = scenarios;
                    this.scenarioGroupService.getScenarioGroups(this.modelRunId).subscribe((scenarioGroups: ScenarioGroup[]) => {
                        this.scenarioGroups = this.scenarioGroupService.scenarioGroups;
                        this.accessPolicyService.getEntityPermissions(this.modelRunId).subscribe((permissions: Permission[]) => {
                            this.prepareScenarioGroups(this.searchValue);
                            this.changeScenario(data);
                        });
                    });
                });
            });
        }));
        this.subscriptions.add(this.scenarioService.importScenarioFailureSubject.subscribe({
            next: (error: any) => {
                this.importScenarioFailureMessage = error.error.message;
            }
        }));
        this.setupSnapshotData();
        this.redirectToDefaultScenario();
        this.skuGroups = this.skuGroupService.skuGroups;
        this.visibleSkuGroups = this.skuGroups.filter(it => it.showInSimulator);
        this.prepareScenarioGroups('');
        this.resetPopulationFiltersStatusCheck();
    }

    search(searchValue): void {
        this.searchValue = searchValue;
        this.prepareScenarioGroups(this.searchValue);
    }

    prepareScenarioGroups(filterString: string): void {
        const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups, filterString);
        this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
        this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
    }

    setupSnapshotData(): void {
        this.scenarios = this.scenarioService.scenarios;
        this.modelRun = this.modelRunService.getModelRun(this.modelRunId);
        this.simulateAndSaveButtonLabel = this.modelRun.runId ? 'Simulate & Save' : 'Save';
        this.showPopulationFilter = !this.segments.every((segment) => {
            return !segment.showInSimulator;
        });
        this.setFilters(this.segments);
        this.onFilterSelectionChange(null);
        this.setActionButtonsState(this.modelRun);
        this.scenarioGroups = this.scenarioGroupService.scenarioGroups;
        if (!this.scenarioGroups) {
            this.scenarioGroups = [];
        }
    }

    get referenceScenario(): Scenario {
        const referenceScenario = this.scenarios.find((scenario: Scenario) => {
            return scenario.name === 'Reference Scenario';
        });
        return referenceScenario;
    }

    /**
     * This method checks the child route's parameter to check if we have any scenarioId set in the URL path.
     * If it is then we will use that id as the selected scenario or else we will use the reference scenario
     * as the default selected scenario.
     */
    redirectToDefaultScenario(): void {
        const scenarioRouteSnapshot = this.route.firstChild ? this.route.firstChild.snapshot : null;
        let lastActiveScenarioId = localStorage.getItem('activeScenarioIndex_' + this.projectId + '_' + this.modelRunId);
        lastActiveScenarioId = this.isScenarioAvailable(this.projectId, this.modelRunId, lastActiveScenarioId) ? lastActiveScenarioId : this.scenarios[0].id;
        const scenarioId = (scenarioRouteSnapshot && scenarioRouteSnapshot.params['scenarioId']) ? this.route.firstChild.snapshot.params['scenarioId'] : lastActiveScenarioId;
        this.changeScenario(scenarioId);
    }

    isScenarioAvailable(projectId: number, modelRunId: string, scenarioId: string): Scenario {
        return this.scenarios.find(scenario => {
            return scenario.projectId === projectId && scenario.modelRunId === modelRunId && scenario.id === scenarioId;
        });
    }

    changeScenario(scenarioId: string): void {
        const lastSelectedScenario = this.selectedScenario;
        this.releaseScenarioLock(lastSelectedScenario).subscribe(() => {
            if (this.isScenarioAvailable(this.projectId, this.modelRunId, scenarioId)) {
                this.scenarioService.fetchById(scenarioId, {
                    projectId: this.projectId,
                    modelRunId: this.modelRunId
                }).subscribe((scenario: Scenario) => {
                    this.scenarioPermission = {
                        view: this.accessPolicyService.hasPermission(scenarioId, this.appConstantsService.VIEW_PERMISSION),
                        edit: this.accessPolicyService.hasPermission(scenarioId, this.appConstantsService.EDIT_PERMISSION)
                    };
                    this.setSelectedScenario(scenario);
                    this.disableIconsCheck(scenarioId);

                    this.router.navigate([scenarioId], {relativeTo: this.route}).then(() => {
                        localStorage.setItem('activeScenarioIndex_' + this.projectId + '_' + this.modelRunId, scenarioId);
                        this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId).subscribe(() => {
                            this.setFilters(this.segments);
                            this.isSelectedScenarioAvailable = true;
                            this.setActionButtonsState(this.modelRun);
                            this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
                            this.toggleDisableSimulateAndSave();
                            this.updateNSize();
                            if (this.scenarioPermission.edit) {
                                this.webMessageService.consumeMessage(`${SCENARIO_LOCK_MSG_GROUP}-${this.projectId}`).subscribe({
                                    next: (eventData) => {
                                        const toggledScenario = JSON.parse(eventData).data;
                                        if (toggledScenario && this.selectedScenarioId === toggledScenario.id) {
                                            this.setSelectedScenario(toggledScenario);
                                        }
                                    }
                                });
                                this.setScenarioLockTimeout();
                                this.userInactive.subscribe(() => {
                                    if (this.selectedScenario.lockedBy) {
                                        this.ngZone.run(() => {
                                            this.toggleScenarioLock();
                                        });
                                    }
                                });
                            }
                        });
                    });
                });
            } else {
                this.isSelectedScenarioAvailable = false;
                this.toggleDisableSimulateAndSave();
                this.setActionButtonsState(this.modelRun);
            }
        });
    }

    disableIconsCheck(scenarioId: any): void {
        this.disableBulkEdit = !this.accessPolicyService.hasPermission(scenarioId, this.appConstantsService.EDIT_PERMISSION);
    }

    /**
     * Returns filter data grouped by filter group to render in the sku config page.
     * NOTE: returns filters that are only visible to the client ie segment whose showInSimulator is true.
     * Hence don't reply only on filter data alone when populating N size or actually applying the filter since
     * these should also consider hidden segments(population group)
     */
    setFilters(segments: Segment[]): void {
        if (this.modelRun.runId) {
            const selectedSegments = this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun);
            this.filters = this.modelRunService.getPopulationFilters(selectedSegments, this.metaData, segments);
        }
    }


    resetPopulationFiltersStatusCheck(): void {
        if (this.modelRun.runId) {
            this.hidePopulationFiltersReset = this.metaData.projectType.toLowerCase().trim() === "als";
            this.resetPopulationFiltersStatus = this.modelRun.selectedSegments !== this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun)
                || this.modelRun.selectedSegments !== this.getSelectedSegments().join(',');
        }
    }

    getResetPopulationFiltersStatus(){
        this.resetPopulationFiltersStatusCheck();
        return this.resetPopulationFiltersStatus;
    }

    /**
     * Returns list of selected segment ids.
     */
    getSelectedSegments(): Array<number> {
        if (this.modelRun.runId) {
            return this.modelRunService.getSelectedSegments(this.modelRun, this.segments, this.filters);
        } else {
            return [-1];
        }
    }

    updateNSize(): void {
        if (this.modelRun.runId) {
            this.modelRunService.getNSize(this.projectId, this.modelRunId, this.getSelectedSegments()).subscribe({
                next: (data: any) => {
                    const oneThirdOfTotalNSize = Math.floor((1 / 3) * this.totalNSize);
                    this.nSize = data.nSize;
                    if (this.nSize <= LOW_POPULATION_THRESHOLD) {
                        this.lowPopulation = true;
                        this.lowPopulationMsgType = 'danger';
                        this.lowPopulationMsg = '75 or less respondents selected. Results are likely unreliable.';
                    } else if (this.nSize > Math.min(LOW_POPULATION_THRESHOLD, oneThirdOfTotalNSize) && this.nSize < Math.max(LOW_POPULATION_THRESHOLD, oneThirdOfTotalNSize)) {
                        this.lowPopulation = true;
                        this.lowPopulationMsgType = 'warn';
                        this.lowPopulationMsg = 'Less than 1/3 of total sample selected. Use extra caution when interpreting results.';
                    } else {
                        this.lowPopulation = false;
                        this.lowPopulationMsgType = null;
                    }
                }
            });
        }
    }

    /**
     * Action that is triggered when a population filter is updated to compute nSize.
     * NOTE : There can be segments that are not shown to the users.
     * These segments although not shown are selected by default, hence we
     * always need to add those segment ids when sending info to the server to calculate n size.
     */
    onFilterSelectionChange(filter: DropdownData<string>): void {
        this.updateNSize();
        this.scenarioService.skuConfigInputsResetSubject.next();
        this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
        this.scenarioService.MODEL_RUN_POPULATION_CHANGED_SUBJECT.next(this.filters);
        this.resetPopulationFiltersStatusCheck();
    }

    setOutputsFilteredOnNonSampleFilterToggleTooltip(): void {
        const hasUnSelectedNonSampleFilter = this.modelRunService.unSelectedNonSameFilterExists(this.filters);
        this.outputsFilteredOnNonSampleFilterToggleTooltip = hasUnSelectedNonSampleFilter ? this.metaDataService.tooltipOnNonSampleFilterEngage(this.metaData) : null;
    }

    resetPopulationFilters(): void {
        const userSelectedSegmentsConfig = this.userConfigurationsService.getUserSelectedSegmentsConfig();
        if (this.resetPopulationFiltersStatus) {
            userSelectedSegmentsConfig.configurations.userRunLevelSelectedSegments = this.modelRun.selectedSegments;
            this.uiBlockerService.block();
            this.userConfigurationsService.updateUserConfiguration(userSelectedSegmentsConfig).subscribe(userConfigurations => {
                this.uiBlockerService.unblock();
                this.setFilters(this.segments);
                this.updateNSize();
                this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
                this.scenarioService.MODEL_RUN_POPULATION_CHANGED_SUBJECT.next(this.filters);

                this.scenarioService.RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$.next();
                this.resetPopulationFiltersStatusCheck();
            },
                err => {
                    this.uiBlockerService.unblock();
                    this.snackBarService.openErrorSnackBar(err.error.message);
                });
        }

    }

    onBulkEditClick(): void {
        const currentScenario = this.scenarioService.scenarios.find(s => s.id === this.selectedScenarioId);
        if (!currentScenario.editable) {
            this.bulkEditStatus = false;
            return;
        }
        this.bulkEditStatus = !this.bulkEditStatus;
        this.scenarioService.showBulkEditFlyout.next(this.bulkEditStatus);
    }

    onBulkEditCloseClick($event): void {
        this.bulkEditStatus = $event;
        this.scenarioService.showBulkEditFlyout.next(this.bulkEditStatus);
    }

    onGroupItemClick(): void {
        this.groupItemStatus = !this.groupItemStatus;
        this.scenarioService.showGroupingsFlyout.next(this.groupItemStatus);
    }

    onGroupItemCloseClick($event): void {
        this.groupItemStatus = $event;
        this.scenarioService.showGroupingsFlyout.next(this.groupItemStatus);
    }

    onSkuGroupChanged(currentSkuGroupId): void {
        this.scenarioService.onChangeSkuGroupSubject.next(currentSkuGroupId);
    }

    openCreateScenarioDialog(): void {
        this.dialog.open(CreateScenarioComponent,
            {
                data: {
                    scenarios: this.scenarios,
                    scenarioGroups: this.scenarioGroups,
                    scenarioId: this.selectedScenarioId,
                    modelRunId: this.modelRunId,
                    projectId: this.projectId,
                    calibration: this.calibration
                }
            });
    }

    simulateAndSave(): any {
        this.scenarioService.SIMULATE_AND_SAVE_SUBJECT.next({
            projectId: this.projectId,
            modelRunId: this.modelRunId,
            userRunLevelSelectedSegments: this.getSelectedSegments().join(',')
        });
    }

    closeImportScenarioErrorPrompt(): void {
        this.importScenarioFailureMessage = null;
    }

    importScenario(file: ReadFile): void {
        this.uiBlockerService.block();
        this.closeImportScenarioErrorPrompt();
        this.scenarioService.importScenario(this.projectId, this.modelRunId, file).subscribe(
            (asyncTask: AsyncTask) => {
                this.setupImportScenarioPolling(asyncTask);
            },
            error => {
                this.scenarioService.importScenarioFailureSubject.next(error);
                this.uiBlockerService.unblock();
            });
    }

    setupImportScenarioPolling(asyncTask: AsyncTask): void {
        this.ngZone.runOutsideAngular(() => {
            this.importScenarioPolling = setTimeout(() => {
                this.asyncTaskService.getById(asyncTask.id, this.projectId).subscribe((updatedTask: AsyncTask) => {
                    if (updatedTask.status === 'COMPLETED') {
                        this.ngZone.run(() => {
                            forkJoin([
                                this.accessPolicyService.getEntityPermissions(this.modelRunId),
                                this.scenarioService.fetchAll(this.projectId, this.modelRunId),
                                this.scenarioGroupService.fetchAll(this.projectId, this.modelRunId)
                            ]).subscribe(results => {
                                this.scenarios = [].concat(results[1]);
                                this.scenarioGroups = [].concat(results[2]);

                                this.prepareScenarioGroups(this.searchValue);
                                this.snackBarService.openSuccessSnackBar('Scenarios uploaded successfully.');
                                this.uiBlockerService.unblock();
                            });
                        });
                    } else if (updatedTask.status === 'FAILED') {
                        this.snackBarService.openErrorSnackBar('Scenarios upload failed.');
                        this.uiBlockerService.unblock();
                    } else {
                        this.setupImportScenarioPolling(asyncTask);
                    }
                });
            }, SCENARIO_EXPORT_DOWNLOAD_POLL_PERIOD);
        });
    }

    get isInternalUser(): boolean {
        return this.authProxyService.isInternalUser();
    }

    onCreateReportClick(): void {
        if (!this.disableReport) {
            this.router.navigate(['reports'], {relativeTo: this.route.parent});
        }
    }

    onCalibrationClick(): void {
        if (!this.disableCalibration) {
            this.router.navigate(['calibration'], {relativeTo: this.route.parent});
        }
    }

    toggleScenarioCompareView(): void {
        if (!this.disableCompareScenario) {
            this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId).subscribe(() => {
                this.setFilters(this.segments);
                this.scenarioCompareView = !this.scenarioCompareView;
                if(this.scenarioCompareView) {
                    if (this.modelRun.runId) {
                        const selectedSegments = this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun);
                        this.compareViewFilters = this.modelRunService.getPopulationFilters(selectedSegments, this.metaData, this.segments);
                    }
                    this.updateNSize();
                    this.setOutputsFilteredOnNonSampleFilterToggleTooltip();
                } else {
                    this.setFilters(this.segments);
                    this.onFilterSelectionChange(null);
                    this.compareScenariosReturn = true;
                    this.scenarioService.onCompareScenarioReturnSubject.subscribe(() =>{
                        if(this.compareScenariosReturn) {
                            this.scenarioService.RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$.next();
                            this.compareScenariosReturn = false;
                        }
                    });
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.clearPollingAndReleaseLock();
        this.webMessageService.close(`${SCENARIO_LOCK_MSG_GROUP}-${this.projectId}`);
        this.webMessageService.close(`${this.appConstantsService.CALIBRATION_MSG_GROUP}-${this.modelRunId}`);
    }

    clearPollingAndReleaseLock() {
        this.clearImportScenarioImportPolling();
        this.releaseScenarioLock(this.selectedScenario).subscribe(() => {
            // do nothing
        });
    }

    exportScenario(): void {
        const dialogRef = this.dialog.open(ExportScenarioModalComponent,
            {               
                data: {
                    scenarios: this.scenarios,
                    scenarioGroups: this.scenarioGroups,
                    scenarioId: this.selectedScenarioId,
                    modelRunId: this.modelRunId,
                    projectId: this.projectId
                }
            });

        dialogRef.afterClosed().subscribe(data => {
            const selectedScenarioIds = data.exportScenarios;
            const exportingForChartingTool = data.exportingForChartingTool;
            const exportingForSharePoint = data.exportingForSharePoint;

            if (selectedScenarioIds) {     
                if(!exportingForSharePoint){           
                    this.scenarioService.asyncExport(this.projectId, this.modelRunId, selectedScenarioIds, exportingForChartingTool).subscribe((asyncTask: AsyncTask) => {
                        this.setupScenariosExportDownloadPolling(asyncTask,exportingForSharePoint);
                    }, () => {
                        this.snackBarService.openErrorSnackBar('Scenarios export failed.');
                    });  
                }
                else{
                    this.scenarioService.asyncPowerBIReportGeneration(this.projectId, this.modelRunId, selectedScenarioIds).subscribe((asyncTask: AsyncTask) => {
                        this.setupScenariosExportDownloadPolling(asyncTask,exportingForSharePoint);
                    }, () => {
                        this.snackBarService.openErrorSnackBar('Power BI report generation failed.');
                    });   
                }             
            }
           
        });
    }

    /**
     * Starts polling asyncTask api to check if a task has completed or failed.
     * If completed, it initiates another api call to download the task content.
     * Or else show export failed message.
     * */
    setupScenariosExportDownloadPolling(asyncTask: AsyncTask,exportingForSharePoint: boolean): void {
        this.uiBlockerService.block();
        this.asyncTaskService.getById(asyncTask.id, asyncTask.projectId).pipe(
            repeatWhen(obs => obs.pipe(delay(SCENARIO_EXPORT_DOWNLOAD_POLL_PERIOD))),
            filter((task: AsyncTask) => {
                return ('completed' === task.status.toLowerCase()) || ('failed' === task.status.toLowerCase()) || ('locked' === task.status.toLowerCase());
            }), take(1)
        ).subscribe((task: AsyncTask) => {
            this.ngZone.run(() => {
                if ('completed' === task.status.toLowerCase()) {
                    if(exportingForSharePoint){
                        this.snackBarService.openSuccessSnackBar('Scenarios exported to SharePoint successfully.');
                    }
                    else{
                        this.asyncTaskService.downloadScenarioExport(task);
                    }
                } else {
                    const errorMessage = 'locked' === task.status.toLowerCase() ? 'Export failed because the file is currently open and cannot be replaced.' : 'Scenarios export failed.';  
                    this.snackBarService.openErrorSnackBar(errorMessage);
                }
                this.scenarioService.RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$.next();
                this.uiBlockerService.unblock();
            });
        });
    }

    clearImportScenarioImportPolling(): void {
        if (this.importScenarioPolling) {
            clearTimeout(this.importScenarioPolling);
        }
    }

    setSelectedScenario(scenario: Scenario): void {
        this.selectedScenarioId = scenario.id;
        this.selectedScenario = scenario;
        const lockedByOther = (scenario.lockedBy && scenario.lockedBy !== this.authProxyService.user.userId);
        this.disableLockScenario = !this.selectedScenario.editable || lockedByOther || !this.scenarioPermission.edit || this.isScenarioInCalibration(scenario);
        this.scenarioLockedForEdit = scenario.lockedBy && scenario.lockedBy === this.authProxyService.user.userId;
        this.scenarioUsedInCalibrationTooltip = this.isScenarioInCalibration(scenario) ? 'Scenarios used for calibration are locked once calibration has started' : '';

        if (lockedByOther && this.scenarioPermission.edit) {
            this.userManagementService.getUser(scenario.lockedBy).subscribe((collaborator) => {
                this.lockMessage = `This scenario is currently being edited by ${collaborator.fullName}. You will be unable to make edits until the user exits the scenario.`;
            });
        } else {
            this.lockMessage = null;
        }
    }

    isScenarioInCalibration(scenario: Scenario): boolean {
        return this.hasCalibrationStarted && this.calibration && this.calibration.skus.findIndex((sku) => sku.scenarioId === scenario.id) !== -1;
    }

    toggleScenarioLock() {
        this.uiBlockerService.block();
        if (this.selectedScenario.lockedBy) {
            //relinquish lock
            if (this.selectedScenario.lockedBy === this.authProxyService.user.userId) {
                this.releaseScenarioLock(this.selectedScenario).subscribe((scenario) => {
                    this.setSelectedScenario(scenario);
                    this.scenarioService.scenarioLockToggleSubject.next(scenario);
                    this.toggleDisableSimulateAndSave();
                    this.uiBlockerService.unblock();
                });
            } else {
                this.uiBlockerService.unblock();
            }
        } else {
            //acquire lock
            this.scenarioService.lockScenario(this.projectId, this.modelRunId, this.selectedScenarioId).subscribe((scenario) => {
                this.setSelectedScenario(scenario);
                clearTimeout(this.userActivity);
                this.setScenarioLockTimeout();
                this.scenarioService.scenarioLockToggleSubject.next(scenario);
                this.toggleDisableSimulateAndSave();
                this.uiBlockerService.unblock();
            });
        }
    }

    releaseScenarioLock(scenario: Scenario): Observable<Scenario> {
        if (scenario && scenario.lockedBy === this.authProxyService.user.userId) {
            return this.scenarioService.unlockScenario(scenario.projectId, scenario.modelRunId, scenario.id);
        } else {
            return of(scenario);
        }
    }

    setScenarioLockTimeout() {
        this.ngZone.runOutsideAngular(() => {
            if (this.userActivity) {
                clearTimeout(this.userActivity);
            }
            this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.appConstantsService.AUTO_UNLOCK_SCENARIO_PERIOD);
        });
    }

    toggleDisableSimulateAndSave() {
        this.disableSimulateAndSave = this.hasSkuConfigInputValidationError || !this.isSelectedScenarioAvailable;
    }

    @HostListener('document:keydown', ['$event'])
    @HostListener('window:mousemove')
    refreshUserState() {
        clearTimeout(this.userActivity);
        this.setScenarioLockTimeout();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler() {
        this.clearPollingAndReleaseLock();
    }

    toggleListItem(): void {
        this.reorderItemsPage = !this.reorderItemsPage;
    }

    openSkuConfigsItemReorderPage(): void {
        this.router.navigate(['reorderItems'], {relativeTo: this.route.parent});
    }

    onAdvanceSettingsFlyoutToggle(expandState: boolean): void {
        this.slideOpenAdvanceSettings = expandState;
    }

    onAdvanceSettingsSave($event: { generalSetting: GeneralSetting; close: boolean }): void {
        this.uiBlockerService.block();
        this.generalSettingService.update($event.generalSetting).subscribe((updatedGeneralSetting: GeneralSetting) => {
            this.generalSetting = updatedGeneralSetting;
            this.snackBarService.openSuccessSnackBar('Advanced settings saved successfully');
            this.onAdvanceSettingsFlyoutToggle(!$event.close);
            this.scenarioService.RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$.next();
            this.uiBlockerService.unblock();
        }, () => {
            this.snackBarService.openErrorSnackBar('Saving advanced settings failed.');
            this.uiBlockerService.unblock();
        });
    }

    /**
     * Action that should be triggered everytime anything in skuConfig changes.
     * Since skuConfig is passed on to child components, it is imperative that we always
     * have the most up-to-date suGroups in this component. Hence if any child component is
     * changing skuGroups, this action is the way to sync those changes back to parent.
     * */
    skuGroupReload() {
        this.skuGroupService.fetchAll(this.projectId, this.modelRunId).subscribe((skuGroups) => {
            this.skuGroups = skuGroups;
            this.visibleSkuGroups = this.skuGroups.filter(it => it.showInSimulator);
        });
    }
}
