import {AfterViewInit, Component, Input, OnInit, OnDestroy} from '@angular/core';
import {HotTableRegisterer} from '@handsontable/angular';
import Handsontable from 'handsontable';
import {Subscription} from 'rxjs';
import {ScenarioService} from '../../services/scenario.service';
import {ModelRunService} from '../../services/model-run.service';
import {UiBlockerService} from '../../services/ui-blocker.service';
import {SnackBarService} from '../../components/snack-bar/snack-bar.service';
import {AppConstantsService} from '../../services/app-constants.service';
import {SkuConfig} from '@app/models/sku-config.model';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {SimulationRunInputService} from '@app/services/simulation-run-input.service';
import {ModelRunSku} from '@app/models/model-run-sku.model';
import {SimulationRunInput} from '@app/models/simulation-run-input.model';
import {MetaData} from '@app/models/meta-data.model';
import {SkuConfigTableColumn} from '@app/utils/sku-config-table-column';
import {SkuConfigTableCellRenderers} from '@app/utils/sku-config-table-cell-renderers';

@Component({
    selector: 'app-bulk-edit-config-table',
    templateUrl: './bulk-edit-config-table.component.html',
    styleUrls: ['./bulk-edit-config-table.component.scss'],
})
export class BulkEditConfigTableComponent implements OnInit, AfterViewInit, OnDestroy {
    tableSettings = {
        columns: [],
        columnHeaders: [],
        groupHeaders: [{label: 'ITEMS', colspan: 3}],
        collapsableColumns: [],
        allowFiltersForHeaders: [2],
        groupColumnStartIndex: 3,
        inputsColumnStartIndex: 3,
        outPutColumnStartIndex: 0
    };
    @Input() cellRenderers: SkuConfigTableCellRenderers;
    @Input() metaData: MetaData;
    @Input() scenarioId: string;
    @Input() modelRunSkus: Array<ModelRunSku>;
    subscriptions: Subscription;
    hotTableInstance: any;
    hotTableId = 'skuConfigTable';
    hotTableSettings: Handsontable.GridSettings = {};
    isAllSkusSelectedState: number;
    selectedSkuCount: number;
    allFilteredRows: any = [];
    allFilteredSkuIds: any = [];
    bulkEditHotData: any;
    skuConfigs: Array<SkuConfig>;

    constructor(private hotTableRegisterer: HotTableRegisterer,
                private modelRunService: ModelRunService,
                private scenarioService: ScenarioService,
                private snackBarService: SnackBarService,
                private uiBlockerService: UiBlockerService,
                private appConstantsService: AppConstantsService,
                private userConfigurationsService: UserConfigurationsService,
                private simulationRunInputService: SimulationRunInputService) {
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.constructBulkEditColumnHeaders();

        this.skuConfigs = this.scenarioService.cachedScenarioSkuConfigs[this.scenarioId].map(x => {
            return Object.assign({}, x);
        });
        this.constructHotTableData();
        this.setSelectAllCheckBoxState();
    }

    constructBulkEditColumnHeaders(): void {
        const isContinuousPromo = this.metaData.promo === 'continuous';
        const skuConfigTableColumn = new SkuConfigTableColumn(this.cellRenderers);
        ['skuIdColumn', 'selectSkuColumn', 'skuNameColumn'].forEach((key) => {
            const tableColumn = skuConfigTableColumn[key]();
            this.tableSettings.columnHeaders.push(tableColumn.displayName);
            this.tableSettings.columns.push(tableColumn);
        });
        let inputsColspan = 0;
        const requiredHeaders = ['priceInput', 'promoPrice', 'specialPromoPrice', 'promoDistribution', 'distribution'];
        requiredHeaders.forEach(header => {
            if (this.metaData && this.metaData.inputConfigurations[header] && this.metaData.inputConfigurations[header]['showInSimulator']) {
                let tableColumn = null;
                if (header === 'priceInput') {
                    tableColumn = skuConfigTableColumn.priceInputColumn(!this.metaData.hasPrice);
                } else if (header === 'promoPrice') {
                    tableColumn = skuConfigTableColumn.promoPriceColumn(isContinuousPromo);
                } else {
                    tableColumn = skuConfigTableColumn[`${header}Column`]();
                }
                this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${tableColumn.displayName}</span>`);
                this.tableSettings.columns.push(tableColumn);
                inputsColspan++;
            }
        });
        this.tableSettings.groupHeaders.push({label: 'INPUTS', colspan: inputsColspan});
        this.tableSettings.columns = this.tableSettings.columns.map(column => {
            column.readOnly = column.displayName !== 'selectAll';
            return column;
        });
    }

    saveChanges(): void {
        this.subscriptions.add(this.scenarioService.onBulkEditSaveChanges.subscribe({
            next: (data) => {
                this.uiBlockerService.block();
                const filteredSkuIds = [];
                // this.hotTableInstance.getData() - save only filtered and checked records.
                // Gives records as displayed in the bulk edit table [SKUID, Selection, Reporting Name. Reg Price, Promo Price, ... ]
                // record[0] = skuID; record[1] = checkbox selection
                this.getValidationData().map(record => {
                    filteredSkuIds.push(record.skuId);
                });
                const skuConfigObj = this.bulkEditHotData[0];
                this.simulationRunInputService.bulkEditInput(skuConfigObj.projectId, skuConfigObj.modelRunId, skuConfigObj.scenarioId, filteredSkuIds, data)
                    .subscribe((simulationRunInputs: SimulationRunInput[]) => {
                        const updatedSkuConfigs = this.scenarioService.generateSkuConfigs(this.modelRunSkus, simulationRunInputs, []);
                        this.scenarioService.cachedScenarioSkuConfigs[updatedSkuConfigs[0].scenarioId] = updatedSkuConfigs.map(x => {
                            return Object.assign({}, x);
                        });
                        this.skuConfigs = updatedSkuConfigs.map(x => {
                            return Object.assign({}, x);
                        });
                        this.constructHotTableData();
                        this.bulkEditHotData.forEach((obj) => {
                            const selectedDiscretePromo = obj.promotions && obj.promotions.length ? obj.promotions[(obj.promoDropdownIdx - 1)]
                                : null;
                            obj.promoPriceText = selectedDiscretePromo ? selectedDiscretePromo.text :
                                obj.promotions && obj.promotions.length ? 'Special Price' : null;
                            obj.specialPromoPrice = obj.specialPromoPrice ? obj.specialPromoPrice :
                                obj.reportingBasePromo ? parseFloat(obj.reportingBasePromo) : obj.promoPriceMin;
                        });
                        if (!data.closeBulkEditFlyout) {
                            this.allFilteredRows = [];
                            this.allFilteredSkuIds = [];
                            this.setSelectAllCheckBoxState();
                            this.snackBarService.openSnackBar(`Changes applied successfully`, 'success', 4000);
                            this.hotTableInstance.getPlugin('Filters').clearConditions();
                            this.scenarioService.onSaveBulkEditChangesSubject.next(data.closeBulkEditFlyout);
                            this.loadHotTable();
                        }
                        this.scenarioService.onSaveBulkEditChangesSubject.next(data.closeBulkEditFlyout);
                        this.userConfigurationsService.getUserConfigurations(skuConfigObj.projectId, skuConfigObj.modelRunId).subscribe();
                        this.uiBlockerService.unblock();
                    }, err => {
                        this.uiBlockerService.unblock();
                        this.snackBarService.openSnackBar(err.error.message, 'error', 4000);
                    });
            }
        }));
    }

    ngAfterViewInit(): void {
        this.loadHotTable();
        this.saveChanges();
        this.scenarioService.onBulkEditSkuSelectionChange.next(this.skuConfigs);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    loadHotTable(): void {
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        if (this.hotTableInstance) {
            this.hotTableInstance.loadData(this.bulkEditHotData);
            this.generateTable(this.bulkEditHotData, this.hotTableInstance);
            this.hotTableInstance.render();
        }
    }

    setSelectAllCheckBoxState(): void {
        const skuConfigRows = this.bulkEditHotData.filter(h => h.skuId > 0);
        const selectedSkuConfigs = skuConfigRows.filter(h => h.isSelected);
        const totalSkuCount = skuConfigRows.length;
        const selectedSkuCount = selectedSkuConfigs.length;
        const unSelectedSkuCount = totalSkuCount - selectedSkuCount;

        this.selectedSkuCount = selectedSkuCount - this.allFilteredRows.length;
        this.selectedSkuCount = this.selectedSkuCount > 0 ? this.selectedSkuCount : 0;
        if (selectedSkuCount === totalSkuCount) {
            this.isAllSkusSelectedState = this.appConstantsService.ALL_SKUS_SELECTED;
        } else if (unSelectedSkuCount === totalSkuCount) {
            this.isAllSkusSelectedState = this.appConstantsService.NO_SKUS_SELECTED;
        } else {
            this.isAllSkusSelectedState = this.appConstantsService.PARTIAL_SKUS_SELECTED;
        }
    }

    getSelectAllCheckBoxHtml(): string {
        let htmlText = `<input type='checkbox'`;
        htmlText += `class='checker ${this.isAllSkusSelectedState === 1 ? 'checked' : (this.isAllSkusSelectedState === 0 ? 'unchecked' : 'partial')}'>`;
        htmlText += `<span class='count-txt'>${this.selectedSkuCount}</span>`;
        return htmlText;
    }

    generateTable(tableData, hotTableInstance): void {
        const self = this;
        hotTableInstance.updateSettings({
            viewportColumnRenderingOffset: 27,
            viewportRowRenderingOffset: 'auto',
            allowInsertColumn: false,
            allowInsertRow: false,
            allowRemoveColumn: false,
            allowRemoveRow: false,
            autoWrapRow: false,
            autoWrapCol: false,
            manualRowResize: true,
            manualRowMove: true,
            manualColumnMove: true,
            filters: self.tableSettings.allowFiltersForHeaders,
            dropdownMenu: ['filter_by_value', 'filter_action_bar'],
            colHeaders: self.tableSettings.columnHeaders,
            columns: self.tableSettings.columns,
            height: document.documentElement.clientHeight - 300,
            width: '60%',
            rowHeights: 34,
            manualColumnResize: false,
            data: tableData,
            licenseKey: 'non-commercial-and-evaluation',
            nestedHeaders: [self.tableSettings.groupHeaders, self.tableSettings.columnHeaders],
            hiddenRows: {
                rows: [],
                indicators: false
            },
            collapsibleColumns: [{
                row: -2,
                col: 3,
                collapsible: true
            }],
            renderAllRows: true,
            fixedRowsBottom: 0,
            afterGetColHeader(index, th): void {
                if (index === 1) {
                    th.innerHTML = self.getSelectAllCheckBoxHtml();
                }
                const BUTTON_CLASS_NAME = 'changeType';
                const existingButton = th.querySelector('.' + BUTTON_CLASS_NAME);
                if (!this.enabled) {
                    if (existingButton) {
                        if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]'
                            && this.getSettings().filters.indexOf(index) === -1) {
                            existingButton.parentNode.removeChild(existingButton);
                        }
                    }
                    return;
                }
            },
            afterOnCellMouseDown: (event, coords, TD): void => {
                if (event.target.classList.contains('checker')) {
                    event.preventDefault(); // prevent selection quirk
                    event.stopImmediatePropagation();
                    self.headerCheckbox();
                    self.hotTableInstance.deselectCell();
                }
            },
            afterChange: (changes, source): void => {
                if (!changes) {
                    return;
                }
                changes.forEach((change) => {
                    const col = change[1];
                    if (col === 'isSelected') {
                        self.setSelectAllCheckBoxState();
                        self.hotTableInstance.render();
                        const filteredData = self.getValidationData();
                        self.scenarioService.onBulkEditSkuSelectionChange.next(filteredData);
                    }
                });
            },
            beforePaste: function (data, coords) {
                for (const coord of coords) {
                    for (let i = coord.startRow, j = 0; i <= coord.startRow + data.length - 1; i += 1, j += 1) {
                        for (let k = coord.startCol, l = 0; k <= coord.startCol + data[j].length - 1; k += 1, l += 1) {
                            const cellMeta = this.getCellMeta(i, k);
                            const type = cellMeta.type;
                            let parsedData;
                            if (type === 'checkbox') {
                                parsedData = data[j][l] === '1' ? 1 : 0;
                            } else {
                                parsedData = data[j][l];
                            }
                            if (!cellMeta.readOnly) {
                                this.setDataAtCell(i, k, parsedData);
                            }
                        }
                    }
                }
                return false;
            },
            beforeFilter: (conditions): boolean => {
                const plugin = self.hotTableInstance.getPlugin('hiddenRows');
                plugin.showRows(self.allFilteredRows);
                self.allFilteredSkuIds = [];
                self.allFilteredRows = [];
                /**
                 *  get rows to hide based on new conditions and hide them using plugin
                 */
                conditions.map((filter) => {
                    const hRows = self.getRowsByFilter(filter.conditions[0].args[0]);
                    plugin.hideRows(hRows);
                });

                /**
                 * remove hidden rows on no conditions
                 */
                if (!conditions.length) {
                    self.allFilteredRows = [];
                    this.allFilteredSkuIds = [];
                }
                self.hotTableInstance.render();
                return false;
            },
            afterFilter: (conditionsStack) => {
                self.setSelectAllCheckBoxState();
                const filteredData = self.getValidationData();
                self.scenarioService.onBulkEditSkuSelectionChange.next(filteredData);
            }

        });
    }

    filterAllRowsToHide(rowsToShow: Array<number>): void {
        this.allFilteredRows = this.allFilteredRows.filter(rowNumber => rowsToShow.indexOf(rowNumber) < 0);
    }

    getRowsByFilter(args): Array<number> {
        const rowCount = this.hotTableInstance.countRows();
        const resultRowIds = [];
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            if (!(rowData.isGroupLabel || rowData.isBrand) &&
                args.indexOf(rowData[('reportingName')]) === -1) {
                resultRowIds.push(i);
                this.allFilteredSkuIds.push(rowData.skuId);
            }
        }
        resultRowIds.map(r => {
            if (this.allFilteredRows.indexOf(r) === -1) {
                this.allFilteredRows.push(r);
            }
        });
        return resultRowIds;
    }


    getValidationData(): any {
        const resultData = [];
        this.bulkEditHotData.forEach(h => {
            if (h.isSelected && this.allFilteredSkuIds.indexOf(h.skuId) < 0) {
                resultData.push(h);
            }
        });
        return resultData;
    }

    headerCheckbox(): void {
        if (this.tableSettings.columns[1].readOnly) {
            return;
        }
        if (this.isAllSkusSelectedState === 0) {
            this.isAllSkusSelectedState = 1;
            this.bulkEditHotData.forEach((skuConfig: any) => {
                skuConfig['isSelected'] = skuConfig.isBrand || skuConfig.isGroupLabel ? skuConfig['isSelected'] : 1;
            });
        } else {
            this.isAllSkusSelectedState = 0;
            this.bulkEditHotData.forEach((skuConfig: any) => {
                skuConfig['isSelected'] = skuConfig.isBrand || skuConfig.isGroupLabel ? skuConfig['isSelected'] : 0;
            });
        }
        this.selectedSkuCount = this.selectedSkuCount = this.bulkEditHotData.filter((h) => {
            return h.skuId > 0 && h.isSelected;
        }).length;
        this.selectedSkuCount = this.selectedSkuCount - this.allFilteredRows.length;
        this.selectedSkuCount = this.selectedSkuCount > 0 ? this.selectedSkuCount : 0;
        this.hotTableInstance.render();
        const filteredData = this.getValidationData();
        this.scenarioService.onBulkEditSkuSelectionChange.next(filteredData);
    }

    constructHotTableData(): void {
        this.bulkEditHotData = [];
        const totalCalculation = {totalRevenue: 0, totalUnits: 0, totalEqVolume: 0, totalPsShare: 0, totalVolume: 0};
        this.skuConfigs = this.scenarioService.getSkuConfigsInDisplayOrder(this.skuConfigs);
        this.skuConfigs.forEach((obj) => {
            const selectedDiscretePromo = obj.promotions && obj.promotions.length ? obj.promotions[(obj.promoDropdownIdx - 1)]
                : null;
            obj.promoPriceText = selectedDiscretePromo ? selectedDiscretePromo.text :
                obj.promotions && obj.promotions.length ? 'Special Price' : null;
            obj.specialPromoPrice = obj.specialPromoPrice ? obj.specialPromoPrice :
                obj.reportingBasePromo ? parseFloat(obj.reportingBasePromo) : obj.promoPriceMin;
            obj.isSelected = 0;
            this.bulkEditHotData.push(obj);
        });
    }
}
