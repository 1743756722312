import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private activeReport$: Subject<string> = new Subject<string>();

  get activeReportSubject(): Subject<string> {
    return this.activeReport$;
  }
}
