export class PriceElasticityReport {
  id: string;
  projectId: number;
  modelRunId: string;
  scenarioId: string;
  name: string;
  description: string;
  skuGroupId: number;
  metric: string;
  status: string;
  scenarioOutOfSync: boolean;
  selectedSegments: string;
}
