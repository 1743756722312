import {Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import Handsontable from 'handsontable';
import {EnvironmentService} from '@app/services/environment.service';
import {MetaData} from '@app/models/meta-data.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SkuConfig} from '@app/models/sku-config.model';
import {costPerUnitValidator, percentageCellValidator} from '@app/utils/sku-config.validator';
import {SkuConfigTableCellRenderers} from '@app/utils/sku-config-table-cell-renderers';
import {GeneralSetting} from '@app/models/general-setting.model';
import {GeneralSettingService} from '@app/services/general-setting.service';
import {forkJoin} from 'rxjs';
import {HotTableRegisterer} from '@handsontable/angular';
import {ScenarioService} from '@app/services/scenario.service';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {Scenario} from '@app/models/scenario.model';
import {AppConstantsService} from '@app/services/app-constants.service';

@Component({
  selector: 'app-profit-modal',
  templateUrl: './profit-modal.component.html',
  styleUrls: ['./profit-modal.component.scss']
})
export class ProfitModalComponent implements OnInit, OnDestroy {
  saveToggleTimer: any;
  private hotRegisterer = new HotTableRegisterer();
  scenarioId: string;
  saveButtonTitle: string;
  cellRenderer: SkuConfigTableCellRenderers;
  metaData: MetaData;
  generalSetting: GeneralSetting;
  skuConfigs: SkuConfig[];
  selectedProfitType: string;
  profitTypes: Array<Record<string, string>> = [
    {id: 'margin', displayName: 'Margin'},
    {id: 'cost', displayName: 'Cost & Retailer Margin'}
  ];
  gridSettings: Handsontable.GridSettings = {};
  showTable: boolean;
  disableSave: boolean;
  profitInputColumns: Record<string, Record<any, any>>;
  dataChanges: Record<number, Set<string>>;
  /**
   * Maintains list of properties whose validation has failed.
   */
  validationMatrix: Record<number, Set<string>>;
  rollbackValues: Record<number, any>;
  rollbackProfitInputType: string;
  userActivity: any;

  constructor(private hotTableRegisterer: HotTableRegisterer,
              private dialogRef: MatDialogRef<ProfitModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private environmentService: EnvironmentService,
              private scenarioService: ScenarioService,
              private generalSettingService: GeneralSettingService,
              private uiBlockerService: UiBlockerService,
              private ngZone: NgZone,
              private snackBarService: SnackBarService,
              private appConstantsService: AppConstantsService,
              private _elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.validationMatrix = {};
    this.dataChanges = {};
    this.disableSave = true;
    this.metaData = this.data.metaData;
    this.scenarioId = this.data.scenarioId;
    this.saveButtonTitle = '';

    forkJoin([this.scenarioService.getSkuConfigs(this.metaData.projectId, this.metaData.modelRunId, this.scenarioId),
      this.generalSettingService.get(this.metaData.projectId, this.metaData.modelRunId)]).subscribe(results => {
      this.skuConfigs = results[0];
      this.skuConfigs = this.scenarioService.getSkuConfigsInDisplayOrder(this.skuConfigs);
      this.generalSetting = results[1];
      this.cellRenderer = this.generalSettingService.createSkuConfigTableCellRenderersInstance(this.generalSetting);
      this.profitInputColumns = this.setupProfitInputColumns();
      this.rollbackValues = this.getProfitInputRollbackValues(this.skuConfigs);
      this.rollbackProfitInputType = this.scenario.profitType;
      this.setProfitType(this.scenario.profitType ? this.scenario.profitType : this.profitTypes[0].id);
      this.bindEventToClearButton();
    });
  }

  bindEventToClearButton(): void {
    this._elementRef.nativeElement.addEventListener("click", (event) => {
      if (event.target.id === 'clear-profit-inputs') {
        this.clear();
      }
    }, false);
  }

  get projectId(): number {
    return this.metaData.projectId;
  }

  get modelRunId(): string {
    return this.metaData.modelRunId;
  }

  get scenario(): Scenario {
    return this.scenarioService.getScenario(this.scenarioId);
  }

  setProfitType(profitType: string): void {
    this.selectedProfitType = profitType;
    this.scenario.profitType = profitType;
    this.gridSettings = this.getTableSettings();
    const editableProfitInputColumns = this.editableProfitInputColumns();
    setTimeout(() => {
      // have to render this in delay so the table renders properly or else sometimes the table doesn't render properly
      const hotTableInstance = this.hotRegisterer.getInstance(`${this.selectedProfitType}-hot-table`);
      hotTableInstance.render();

      this.validationMatrix = {};
      this.dataChanges = {};
      this.skuConfigs.forEach((skuConfig, rowNo) => {
        this.checkSomeRowDataIsZero(rowNo);
        hotTableInstance.validateRows([rowNo]);
        editableProfitInputColumns.forEach((colName) => {
          this.detectDataChanges(rowNo, colName, skuConfig[colName]);
        });
      });

      this.toggleSaveDisableState();
    }, 500);
  }

  get handsontableLicenseKey(): string {
    const handsontableConfig = this.environmentService.environment.handsontable;
    const handsontableLicenseKey = handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
    return handsontableLicenseKey;
  }

  getProfitInputRollbackValues(skuConfigs: SkuConfig[]): Record<number, any> {
    const profitInputColumns = this.profitInputColumns;
    const profitInputProperties = Object.keys(profitInputColumns).map((key => profitInputColumns[key].data));
    const rollbackValues = {};
    skuConfigs.forEach((skuConfig) => {
      rollbackValues[skuConfig.skuId] = profitInputProperties.reduce((acc, name) => {
        acc[name] = skuConfig[name];
        return acc;
      }, {});
    });
    return rollbackValues;
  }

  rollback(): void {
    const profitInputColumns = this.profitInputColumns;
    const profitInputProperties = Object.keys(profitInputColumns).map((key => profitInputColumns[key].data));
    this.skuConfigs.forEach((skuConfig: SkuConfig) => {
      profitInputProperties.forEach((propName) => {
        skuConfig[propName] = this.rollbackValues[skuConfig.skuId][propName];
      });
    });
    this.scenario.profitType = this.rollbackProfitInputType;
  }

  setupProfitInputColumns(): Record<string, Record<any, any>> {
    const salesTaxColumn = {
      name: 'salesTax',
      type: 'numeric',
      displayName: 'Sales Tax',
      data: 'salesTax',
      readOnly: false,
      className: 'htRight',
      width: 110,
      dropdownMenu: false,
      index: 3,
      renderer: this.cellRenderer.salesTaxRenderer.bind(this.cellRenderer),
      validator: percentageCellValidator
    };
    const regularMarginColumn = {
      name: 'regularMargin',
      type: 'numeric',
      displayName: 'Regular Margin',
      data: 'regularMargin',
      readOnly: false,
      className: 'htRight',
      width: 100,
      dropdownMenu: false,
      index: 4,
      renderer: this.cellRenderer.regularMarginRenderer.bind(this.cellRenderer),
      validator: percentageCellValidator
    };
    const retailerRegularMarginColumn = {
      name: 'retailerRegularMargin',
      type: 'numeric',
      displayName: 'Retailer Regular Margin',
      data: 'retailerRegularMargin',
      readOnly: false,
      className: 'htRight',
      width: 100,
      dropdownMenu: false,
      index: 4,
      renderer: this.cellRenderer.retailerRegularMarginRenderer.bind(this.cellRenderer),
      validator: percentageCellValidator
    };
    const promoMarginColumn = {
      name: 'promoMargin',
      type: 'numeric',
      displayName: 'Promo Margin',
      data: 'promoMargin',
      readOnly: false,
      className: 'htRight',
      width: 100,
      dropdownMenu: false,
      index: 5,
      renderer: this.cellRenderer.promoMarginRenderer.bind(this.cellRenderer),
      validator: percentageCellValidator
    };
    const retailerPromoMarginColumn = {
      name: 'retailerPromoMargin',
      type: 'numeric',
      displayName: 'Retailer Promo Margin',
      data: 'retailerPromoMargin',
      readOnly: false,
      className: 'htRight',
      width: 100,
      dropdownMenu: false,
      index: 5,
      renderer: this.cellRenderer.retailerPromoMarginRenderer.bind(this.cellRenderer),
      validator: percentageCellValidator
    };
    const costPerUnitColumn = {
      name: 'costPerUnit',
      type: 'numeric',
      displayName: 'Cost Per Unit',
      data: 'costPerUnit',
      readOnly: false,
      className: 'htRight',
      width: 90,
      dropdownMenu: false,
      index: 5,
      renderer: this.cellRenderer.costPerUnitRenderer.bind(this.cellRenderer),
      validator: costPerUnitValidator
    };

    return {
      salesTaxColumn,
      regularMarginColumn,
      retailerRegularMarginColumn,
      promoMarginColumn,
      retailerPromoMarginColumn,
      costPerUnitColumn
    };
  }

  getApplicableColumns(): Array<Record<any, any>> {
    const margin = this.selectedProfitType;
    const hasPromo = this.metaData.promo !== 'none';
    const skuIdColumn = {
      name: 'id',
      displayName: 'ID',
      type: 'numeric',
      data: 'skuId',
      readOnly: true,
      className: 'htRight',
      width: 40,
      index: 1
    };
    const reportingNameColumn = {
      name: 'reportingName',
      type: 'text',
      displayName: 'Reporting Name',
      data: 'reportingName',
      readOnly: true,
      className: 'ellipsis htLeft',
      width: 300,
      filters: true,
      index: 2,
      renderer: this.cellRenderer.reportNameRenderer.bind(this.cellRenderer)
    };
    const profitInputColumns = this.profitInputColumns;
    const columns = [skuIdColumn, reportingNameColumn, profitInputColumns.salesTaxColumn];

    if (margin === 'margin') {
      columns.push(profitInputColumns.regularMarginColumn);
      if (hasPromo) {
        columns.push(profitInputColumns.promoMarginColumn);
      }
    } else if (margin === 'cost') {
      columns.push(profitInputColumns.retailerRegularMarginColumn);
      if (hasPromo) {
        columns.push(profitInputColumns.retailerPromoMarginColumn);
      }
      columns.push(profitInputColumns.costPerUnitColumn);
    }
    return columns;
  }

  debouncedToggleSaveDisableState(): void {
    if (this.saveToggleTimer) {
      clearTimeout(this.saveToggleTimer);
    }
    this.saveToggleTimer = setTimeout(() => {
      this.toggleSaveDisableState();
    }, 500);
  }

  toggleSaveDisableState(): void {
    if (Object.keys(this.dataChanges).length > 0) {
      const invalidRows = Object.keys(this.validationMatrix);
      const missingInput = invalidRows.find((rowNum: string) => {
        return this.checkSomeRowDataIsZero(parseInt(rowNum));
      });
      this.disableSave = invalidRows.length > 0;
      this.saveButtonTitle = this.disableSave ? (missingInput ? 'At least one input is missing' : '') : '';
    } else {
      this.disableSave = true;
      this.saveButtonTitle = '';
    }
  }

  detectDataChanges(rowNo: number, colName: string, newValue: number): boolean {
    const hotTableInstance = this.hotRegisterer.getInstance(`${this.selectedProfitType}-hot-table`);
    const rowData = hotTableInstance.getSourceDataAtRow(rowNo) as Record<any, any>;
    const skuId = rowData.skuId;
    const originalValue = this.rollbackValues[skuId][colName];

    if (originalValue !== newValue) {
      if (!this.dataChanges[skuId]) {
        this.dataChanges[skuId] = new Set();
      }
      this.dataChanges[skuId].add(colName);
    } else {
      if (this.dataChanges[skuId] && this.dataChanges[skuId].has(colName)) {
        this.dataChanges[skuId].delete(colName);
      }
    }
    if (this.dataChanges[skuId] && !this.dataChanges[skuId].size) {
      delete this.dataChanges[skuId];
    }
    return this.dataChanges[skuId] && this.dataChanges[skuId].size > 0;
  }

  detectValidationChanges(row: number, colName: string, isValid: boolean) {
    const validationMatrix = this.validationMatrix;
    if (isValid) {
      if (validationMatrix[row]) {
        validationMatrix[row].delete(colName);
        if (!validationMatrix[row].size) {
          delete validationMatrix[row];
        }
      }
    } else {
      if (!validationMatrix[row]) {
        validationMatrix[row] = new Set([colName]);
      } else {
        validationMatrix[row].add(colName);
      }
    }
  }

  checkSomeRowDataIsZero(row: number): boolean {
    const hotTableInstance = this.hotRegisterer.getInstance(`${this.selectedProfitType}-hot-table`);
    const data = hotTableInstance.getSourceDataAtRow(row);
    const columns = this.gridSettings.columns as Array<any>;
    const properties = columns.map(column => column.data).filter(prop => ['skuId', 'reportingName'].indexOf(prop) === -1);
    const hasAllZeroValue = properties.every(prop => data[prop] === 0 || data[prop] === null || data[prop] === undefined);
    const hasNonZeroValue = properties.some(prop => data[prop] > 0);

    if (hasAllZeroValue) {
      if (this.validationMatrix[row]) {
        delete this.validationMatrix[row];
      }
    } else {
      if (hasNonZeroValue) {
        properties.forEach((prop) => {
          if (!data[prop]) {
            if (!this.validationMatrix[row]) {
              this.validationMatrix[row] = new Set([]);
            }
            this.validationMatrix[row].add(prop);
          }
        });
        return properties.some(prop => !data[prop]);
      }
    }
    return false;
  }

  getTableSettings(): Record<any, any> {
    const columns = this.getApplicableColumns();
    const headers = columns.map(column => column.displayName);
    const outputHeaderTooltip = this.selectedProfitType === 'margin' ? 'Profit = Unit * [Margin * Price / (1+Sales Tax)]' : 'Profit = Unit * [(1-Margin) * Price / (1+Sales Tax) - Cost]';

    // readjust column width
    const outputsSize = headers.length - 2; // -2 because id and reporting name is always shown
    const outputColWidth = (600 - 340) / outputsSize;
    columns.forEach((column, index) => {
      if (index > 1) {
        column.width = outputColWidth;
      }
    });

    const afterChange = function (changes, src): void {
      const hotTableInstance = this.hotRegisterer.getInstance(`${this.selectedProfitType}-hot-table`);

      if (src === 'edit' || src === 'Autofill.fill' || src === 'CopyPaste.paste') {
        changes.forEach((change) => {
          const rowNo = change[0];
          const colName = change[1];
          const value = change[3];

          /**
           * Change percent values to absolute values
           */
          if (['salesTax', 'regularMargin', 'retailerRegularMargin', 'promoMargin', 'retailerPromoMargin'].indexOf(colName) !== -1) {
            const stringValue = `${value}`;
            if (stringValue && stringValue.length && stringValue[stringValue.length - 1] === '%') {
              // @see http://adripofjavascript.com/blog/drips/avoiding-problems-with-decimal-math-in-javascript.html
              const numericValue = Number.parseFloat(stringValue.replace('%', ''));
              hotTableInstance.setDataAtRowProp(rowNo, colName, (numericValue * 1000) / (100 * 1000));
            }
          }

          this.detectDataChanges(rowNo, colName, value);
          this.checkSomeRowDataIsZero(rowNo);
          this.debouncedToggleSaveDisableState();
        });
      }
    }.bind(this);

    const afterValidate = function (isValid, value, row, prop): void {
      this.detectValidationChanges(row, prop, isValid);
      this.debouncedToggleSaveDisableState();
    }.bind(this);


    return {
      data: this.skuConfigs,
      width: '100%',
      height: document.documentElement.clientHeight - 300,
      rowHeights: 30,
      filters: true,
      nestedHeaders: [
        [
          {
            label: 'ITEMS',
            colspan: 2
          },
          {
            label: `INPUTS &nbsp;<span class="sif sif-info-filled" title="${outputHeaderTooltip}"></span> &nbsp;<span id="clear-profit-inputs" class="sif sif-clear"></span>`,
            colspan: outputsSize
          }
        ],
        headers
      ],
      columns,
      afterChange,
      afterValidate
    };
  }

  onSaveChanges(): void {
    const profitInputColumns = this.profitInputColumns;
    const profitInputProperties = Object.keys(profitInputColumns).map((key => profitInputColumns[key].data));
    const applicableProfitInputProperties = this.getApplicableColumns().map((col => col.data));

    const requestPayload = this.skuConfigs.map((skuConfig: SkuConfig) => {
      return profitInputProperties.reduce((acc, prop) => {
        if (applicableProfitInputProperties.indexOf(prop) === -1) {
          skuConfig[prop] = null;
        }
        acc[prop] = skuConfig[prop];
        return acc;
      }, {skuId: skuConfig.skuId});
    });
    this.ngZone.run(() => {
      this.uiBlockerService.block();
      this.scenarioService.update(this.scenario).subscribe((scenario: Scenario) => {
        this.scenarioService.saveProfitInput(this.projectId, this.modelRunId, this.scenarioId, requestPayload).subscribe((simulationRunInputs) => {
          this.uiBlockerService.unblock();
          this.dialogRef.close(simulationRunInputs);
        }, () => {
          this.uiBlockerService.unblock();
          this.snackBarService.openErrorSnackBar('Failed applying changes.');
        });
      }, () => {
        this.uiBlockerService.unblock();
        this.snackBarService.openErrorSnackBar('Failed applying changes.');
      });
    });
  }

  close(): void {
    this.rollback();
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  editableProfitInputColumns(): Array<string> {
    const profitInputProperties = Object.keys(this.profitInputColumns).map((key => this.profitInputColumns[key].data));
    const profitInputColumns = this.getApplicableColumns().map(col => col.data).filter((col) => {
      return profitInputProperties.indexOf(col) !== -1;
    });
    return profitInputColumns;
  }

  clear(): void {
    const hotTableInstance = this.hotRegisterer.getInstance(`${this.selectedProfitType}-hot-table`);
    const profitInputColumns = this.editableProfitInputColumns();

    this.skuConfigs.forEach((skuConfig: SkuConfig, skuIndex) => {
      if (!profitInputColumns.every(prop => skuConfig[prop] === 0 || skuConfig[prop] === null || skuConfig[prop] === undefined)) {
        profitInputColumns.forEach((prop, popIndex) => {
          hotTableInstance.setDataAtRowProp(skuIndex, prop, 0);
        });
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.saveToggleTimer);
    clearTimeout(this.userActivity);
  }

  autoCloseModal() {
    this.ngZone.runOutsideAngular(() => {
      const timeout = this.appConstantsService.AUTO_UNLOCK_SCENARIO_PERIOD;
      this.userActivity = setTimeout(() => {
        this.ngZone.run(() => {
          this.close();
        });
      }, timeout);
    });
  }
  
  @HostListener('document:keydown', ['$event'])
  @HostListener('window:mousemove')
  refreshUserState() {
    clearTimeout(this.userActivity);
    this.autoCloseModal();
  }
}
