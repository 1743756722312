import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UiBlockerService} from '../../../../services/ui-blocker.service';
import {ModelRunService} from '../../../../services/model-run.service';
import {ModelRun} from '../../../../models/model-run.model';
import {GenericConfirmationModalComponent} from '../../../../components/generic-confirmation-modal/generic-confirmation-modal.component';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {SourcingMatrixReport} from '@app/models/sourcing-matrix-report.model';
import {SourcingMatrixService} from '@app/services/sourcing-matrix.service';
import {MetaData} from '@app/models/meta-data.model';
import {WebMessageService} from '@app/services/web-message.service';
const SOURCING_MATRIX_REPORT_MSG_GROUP = 'sourcing-matrix-report';

@Component({
  selector: 'app-list-sourcing-matrix-report',
  templateUrl: './list-sourcing-matrix-report.component.html',
  styleUrls: ['./list-sourcing-matrix-report.component.scss']
})
export class ListSourcingMatrixReportComponent implements OnInit, OnDestroy {

  modelRun: ModelRun;

  metaData: MetaData;

  reports: Array<SourcingMatrixReport>;

  subscriptions: Subscription;

  get projectId(): number {
    return this.modelRun.projectId;
  }

  get modelRunId(): string {
    return this.modelRun.id;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modelRunService: ModelRunService,
              private uiBlockerService: UiBlockerService,
              private sourcingMatrixService: SourcingMatrixService,
              private dialog: MatDialog,
              private snackBarService: SnackBarService,
              private ngZone: NgZone,
              private webMessageService: WebMessageService) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.metaData = this.route.parent.parent.snapshot.data.metaData;
    /**
     * Angular nuances, when parent route(reports) param changes we need to watch it and reset the data accordingly.
     * I am not sure why it just doesn't re-render the route like in Ember.
     */
    this.subscriptions.add(this.route.parent.parent.params.subscribe(() => {
      this.loadReports();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.webMessageService.close(`${SOURCING_MATRIX_REPORT_MSG_GROUP}-${this.modelRunId}`);
  }

  loadReports(): void {
    this.uiBlockerService.block();
    this.reports = [];
    this.modelRun = this.modelRunService.activeModelRun;
    this.sourcingMatrixService.fetchAll(this.projectId, this.modelRunId).subscribe((reports: SourcingMatrixReport[]) => {
      this.reports = reports;
      this.uiBlockerService.unblock();
      if (reports.length > 0) {
        this.webMessageService.consumeMessage(`${SOURCING_MATRIX_REPORT_MSG_GROUP}-${this.modelRunId}`).subscribe((eventData) => {
          const event = JSON.parse(eventData);
          const updatedSourcingMatrixReport = this.sourcingMatrixService.refreshCachedModel(event.data);
          if (updatedSourcingMatrixReport) {
            this.reports.splice(this.reports.findIndex(it => it.id === updatedSourcingMatrixReport.id), 1, updatedSourcingMatrixReport);
          }
        });
      }
    });
  }

  createReport(): void {
    this.router.navigate(['create'], {relativeTo: this.route.parent});
  }

  /**
   * Downloads optimization report file.
   * @see https://stackoverflow.com/questions/51960172/set-file-name-while-downloading-via-blob-in-angular-5
   */
  downloadReport(report: SourcingMatrixReport, exportForSharePoint: boolean): void {
    this.uiBlockerService.block();
    if(!exportForSharePoint){
      this.sourcingMatrixService.generateReport(report).subscribe((response) => {
        const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = `${report.name}.xlsx`;
        anchor.href = url;
        anchor.click();
        this.uiBlockerService.unblock();
      }, error => {
        this.uiBlockerService.unblock();
        this.snackBarService.openErrorSnackBar('Failed downloading report.');
      });
    }
    else{
      this.sourcingMatrixService.powerBIReportGeneration(report).subscribe((response) => {
        this.uiBlockerService.unblock();
        this.snackBarService.openSuccessSnackBar('Report exported to SharePoint successfully.');
      }, error => {
        this.uiBlockerService.unblock();
        const errorMessage = error.status == 423 ? 'Export failed because the file is currently open and cannot be replaced.' : 'Failed downloading report.';  
        this.snackBarService.openErrorSnackBar(errorMessage);
      });
    }
  }

  deleteReport(report: SourcingMatrixReport): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Delete?',
      body: 'Are you sure you want to delete the report?',
      cancelButtonLabel: 'CANCEL',
      confirmButtonLabel: 'DELETE'
    };

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      if (value === 'DELETE') {
        this.uiBlockerService.block();
        this.sourcingMatrixService.delete(report).subscribe(() => {
          this.sourcingMatrixService.getAll(this.projectId, this.modelRunId).subscribe((reports: SourcingMatrixReport[]) => {
            this.reports = reports;
            this.uiBlockerService.unblock();
          });
        }, error => {
          this.uiBlockerService.unblock();
        });
      }
    });
  }

  duplicate(report: SourcingMatrixReport) {
    this.router.navigate(['create'], {
      relativeTo: this.route.parent,
      queryParams: { source: report.id }
    });
  }
}
