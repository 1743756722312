<div [matTooltip]="tooltip">
    <div *ngIf="editMode;else viewMode">
        <input #inputElement
                type="text"
                class="input-box"
                [(ngModel)]="calibration.avgItemPriceElasticity"
                (change)="validateAvgItemPriceElasticity()"
                (paste)="validateAvgItemPriceElasticity()"
                (keyup)="validateAvgItemPriceElasticity()"
                (blur)="disableEditMode()" />
        <div *ngIf="invalidAvgItemPriceElasticity"><mat-error class="validation-error">Value must be negative and up to -7</mat-error></div>
    </div>
    <ng-template #viewMode>
        <div class="show-inputs show-benchmark" (click)="enableEditMode()">
            <span *ngIf="calibration.avgItemPriceElasticity; else showWithoutValue">Benchmark: <span class="benchmark-value" [class.disable]="disable">{{calibration.avgItemPriceElasticity}}</span></span>
            <ng-template #showWithoutValue>
                <span class="sif sif-add bench-mark-add"></span><span>PRICE ELASTICITY BENCHMARK</span>
            </ng-template>
        </div>
    </ng-template>
</div>
