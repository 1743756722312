<div class="export-report-container">
    <div class="export-report-header">
        <div class="price-report-name">
            <div class="report-name price-report-desc">
                <label>Run Name</label>
                <app-edit-input editInput class="mat-edit-name" [data]='report.name' [charCounter]="'true'" [maxLength]="reportNameMaxLength"
                    (changeEvent)="applyReportName($event, false)" (focusOutEvent)='applyReportName($event, true)'>
                </app-edit-input>
                <mat-error class="validation-error">{{this.validationErrors.name}}</mat-error>
            </div>
            <div class="report-description">
                <label>Description</label>
                <app-edit-input editInput class="mat-edit-name" [placeholder]="'Enter description'" [data]='report.description' [charCounter]="'true'" [maxLength]="reportDescriptionMaxLength" (changeEvent)="applyReportDescription($event)"></app-edit-input>
                <mat-error class="validation-error">{{this.validationErrors.description}}</mat-error>
            </div>
        </div>
        <div>
            <button class="close-btn" mat-button (click)="closeReport()">Close</button>
            <button class="start-run-btn" mat-flat-button color="primary" [disabled]="disableStartRun? true : null" (click)="generateReport()">Start Run</button>
        </div>
    </div>
    <div class="report-dropdown-filters">
        <div class="scenario-dropdown">
            <label>Scenario</label>
            <app-scenario-group-filter specificattr (dropDownChanged)="changeScenario($event)" (filter)="search($event)"
                                       [referenceScenarioData]="referenceScenarioData"
                                       [scenarioGroupsData]="scenarioGroupsData" [groupItemStatus]=false
                                       [enableActionMenu]=false
                                       [selectedScenarioIds]="report.scenarioId"></app-scenario-group-filter>
        </div>
        <div class="item-characteristics-dropdown">
            <label>Item Characteristic</label>
            <mat-select placeholder="Select Item Characteristic" [(value)]="report.skuGroupId" (selectionChange)="changeGroup($event.source.value)" class="mat-select-all">
                <mat-option [value]="-1">Item level</mat-option>
                <mat-option *ngFor="let skuGroup of skuGroups;" [value]="skuGroup.skuGroupId">{{skuGroup.displayName}}</mat-option>
            </mat-select>
        </div>
        <div class="metric-dropdown">
            <label>Metric(s)</label>
            <mat-select placeholder="Select Metric(s)" [(value)]="report.metrics" (selectionChange)="onMetricChange($event.source.value)" class="mat-select-all" multiple>
                <mat-option *ngFor="let metric of metrics;" [value]="metric.id">{{metric.data.displayName}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div class="table-data-all mr-t-2">
        <table>
            <thead>
            <tr>
                <th>ID</th>
                <th><span *ngIf="report.skuGroupId===-1">Reporting Name</span><span *ngIf="report.skuGroupId>-1">Grouping Name</span></th>
                <th>Group To Change</th>
            </tr>
            </thead>
            <ng-container *ngIf="report.skuGroupId===-1">
                <tbody class="selectable-table">
                <tr *ngFor="let skuConfig of reportSkuConfigs;" [id]="skuConfig.skuId">
                    <td>{{skuConfig.sku.skuId}}</td>
                    <td>{{skuConfig.sku.name}}</td>
                    <td><mat-radio-button class="mat-radio-btn" value="{{skuConfig.sku.skuId}}" [checked]="skuConfig.groupToChange" (change)="onSkuChange(skuConfig.sku.skuId)"></mat-radio-button></td>
                </tr>
                <tr *ngIf="!reportSkuConfigs || reportSkuConfigs.length===0">
                    <td colspan="3" class="select-scenario">Please select a scenario.
                    </td>
                </tr>
                </tbody>
            </ng-container>
            <ng-container *ngIf="report.skuGroupId>-1">
                <tbody class="selectable-table">
                <tr *ngFor="let itemGrouping of reportItemGroupings;" [id]="itemGrouping.itemGroupingId">
                    <td>{{itemGrouping.itemGroupingId}}</td>
                    <td>{{itemGrouping.displayName}}</td>
                    <td><mat-radio-button class="mat-radio-btn" value="{{itemGrouping.displayName}}" [checked]="itemGrouping.groupToChange" (change)="onItemGroupChange(itemGrouping.itemGroupingId)" [disabled]="itemGrouping.disabled" [title]="itemGrouping.disabled?'This scenario has no items selected for this Item Characteristic Grouping':''"></mat-radio-button></td>
                </tr>
                <tr *ngIf="!reportItemGroupings || reportItemGroupings.length===0">
                    <td colspan="3"  class="select-scenario">Please select a scenario.</td>
                </tr>
                </tbody>
            </ng-container>
        </table>
    </div>
    <div class="items-optimize">
        <span>PRICE POINTS TO TEST</span>
        <span class="price-text">The “Group to Change” is moved up and down by the given price points.</span>
    </div>
    <div class="price-points-to-change mr-t-2">
        <div>
            <label>Lowest</label>
            <span><input matInput small type="text" class="lowest-price-point" [ngClass]="{'error': this.validationErrors.lowest}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.lowest" (focusout)="validatePricePoint('lowest')" (keyup)="validatePricePoint('lowest')" /> % </span>
        </div>
        <div>
            <label>Lower</label>
            <span><input matInput small type="text" class="lower-price-point" [ngClass]="{'error': this.validationErrors.lower}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.lower" (focusout)="validatePricePoint('lower')" (keyup)="validatePricePoint('lower')" /> % </span>
        </div>
        <div>
            <label>Low</label>
            <span><input matInput small type="text" class="low-price-point" [ngClass]="{'error': this.validationErrors.low}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.low" (focusout)="validatePricePoint('low')" (keyup)="validatePricePoint('low')" /> % </span>
        </div>
        <div>
            <label>Middle</label>
            <span><input matInput small type="text" class="middle-price-point" [ngClass]="{'error': this.validationErrors.middle}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.middle" (focusout)="validatePricePoint('middle')" (keyup)="validatePricePoint('middle')" /> % </span>
        </div>
        <div>
            <label>High</label>
            <span><input matInput small type="text" class="high-price-point" [ngClass]="{'error': this.validationErrors.high}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.high" (focusout)="validatePricePoint('high')" (keyup)="validatePricePoint('high')" /> % </span>
        </div>
        <div>
            <label>Higher</label>
            <span><input matInput small type="text" class="higher-price-point" [ngClass]="{'error': this.validationErrors.higher}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.higher" (focusout)="validatePricePoint('higher')" (keyup)="validatePricePoint('higher')" /> % </span>
        </div>
        <div>
            <label>Highest</label>
            <span><input matInput small type="text" class="highest-price-point" [ngClass]="{'error': this.validationErrors.highest}" [disabled]="disablePricePoints" [(ngModel)]="report.pricePoints.highest" (focusout)="validatePricePoint('highest')" (keyup)="validatePricePoint('highest')" /> % </span>
        </div>
    </div>
    <mat-error class="validation-error price-points-error">{{this.validationErrors.pricePoints}}</mat-error>
</div>
