import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {Observable, of} from 'rxjs';
import {WebAnalytics} from '../models/web-analytics.model';
import {User} from '../models/user.model';
import {UserManagementService} from '@app/services/user-management.service';
import {map} from 'rxjs/operators';

const PENDO = (function (apiKey) {
  (function (p, e, n, d, o) {
    let v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] = o[m] || function () {
          o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
        };
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
});

@Injectable({
  providedIn: 'root'
})
export class WebAnalyticsService {

  constructor(private http: HttpClient,
              private userManagementService: UserManagementService,
              private environmentService: EnvironmentService) {
  }

  fetchWebAnalytics(): Observable<WebAnalytics> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/webAnalytics`;
    return this.http.get<WebAnalytics>(url);
  }

  start(user: User): void {
    this.initializePendo(user);
  }

  getUserOrganizationId(user: User): Observable<string> {
    if (user.organizationId) {
      return of(user.organizationId);
    } else {
      return this.userManagementService.getUser(user.userId).pipe(map((userData) => {
        return userData.organization;
      }));
    }
  }

  initializePendo(user: User): void {
    if (user) {
      this.fetchWebAnalytics().subscribe((webAnalytics: WebAnalytics) => {
        if (webAnalytics.pendo.apiKey) {
          PENDO(webAnalytics.pendo.apiKey);

          this.getUserOrganizationId(user).subscribe((organizationId: string) => {
            const userId = user.userId;
            const envIdentifier = window.location.host.split('.')[0];
            const prefix = this.environmentService.environment.production ? `prod-${envIdentifier}` : `dev-${envIdentifier}`;

            pendo.initialize({
              visitor: {
                id: `${prefix}-${userId}` // Required if user is logged in
              },
              account: { // You can add any additional account level key-values here
                id: `${prefix}-${organizationId}`
              }
            });
          });
        }
      });
    }
  }
}
