import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '../generic-confirmation-modal/generic-confirmation-modal.component';
import { CalibrationService } from 'src/app/services/calibration.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-calibration-settings',
  templateUrl: './calibration-settings.component.html',
  styleUrls: ['./calibration-settings.component.scss']
})
export class CalibrationSettingsComponent implements OnInit {

  @Input() generalSetting: any;
  @Input() calcVersion: string;
  @Input() adminView: boolean;
  @Input() triggerClose: Subject<boolean>;
  @Output() closeAdvancedSettings = new EventEmitter<boolean>();
  @Output() saveAdvancedSettings = new EventEmitter<{ generalSetting: any; close: boolean }>();

  disableSaveButton = true;

  selectedMetric: string;
  selectedVersion: string;
  metrics = [{ label: 'Unit Share', value: 'target_unit_share' }, { label: 'Equivalized Volume Share', value: 'target_eq_volume' }, { label: 'Volume Share', value: 'target_volume' }]
  versions = [
    { label: 'Latest (6.1)', value: '6_1' },
    { label: 'Legacy (6.0)', value: '6' }
  ]

  showPEBenchmark = false;
  peBenchmark: number;
  isPEBenchmarkValid = true;
  showVersion = false;

  constructor(private dialog: MatDialog,
    public calibrationService: CalibrationService
  ) {
  }

  ngOnInit(): void {
    this.initializeData();
    this.triggerClose.subscribe(d => {
      this.onClose();
    });
  }
  initializeData() {
    this.metrics = this.generalSetting.calibrationMetrics;
    this.selectedMetric = this.generalSetting.selectedCalibrationMetric;
    this.selectedVersion = this.calcVersion;
    this.showPEBenchmark = false;
    this.peBenchmark = null;
    if (this.generalSetting.avgItemPriceElasticity) {
      this.showPEBenchmark = true;
      this.peBenchmark = +this.generalSetting.avgItemPriceElasticity;
    }
    this.validateAvgItemPriceElasticity();
    this.showVersion = this.adminView && this.versions.map(it => it.value).includes(this.selectedVersion);
  }

  /**
   * If the inputs to the component change, then re-initialize the component
   * */
  ngOnChanges(): void {
    this.initializeData();
  }

  /**
   * Returns true if the user has changed any of the inputs.
   * */
  get generalSettingIsDirty(): boolean {
    const benchmark = this.peBenchmark ? this.peBenchmark : 0;
    return this.selectedMetric != this.generalSetting.selectedCalibrationMetric ||
      this.selectedVersion != this.generalSetting.calcVersion ||
      (this.showPEBenchmark && benchmark != +this.generalSetting.avgItemPriceElasticity);
  }

  /**
   * Disables save button if user has not made any changes or if form has errors.
   * */
  saveButtonDisabled(): boolean {
    this.disableSaveButton = !this.generalSettingIsDirty;
    if (!this.disableSaveButton && this.showPEBenchmark && this.peBenchmark && !this.isPEBenchmarkValid)
      {this.disableSaveButton = true;}
    return this.disableSaveButton;
  }

  /**
   * Action that is triggered when user clicks close button.
   * */
  onClose(): void {
    if (!this.peBenchmark) {
      this.togglePEBenchmark(false);
    }
    if (this.generalSettingIsDirty) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '800px';
      dialogConfig.data = {
        header: `Continue Without Saving?`,
        body: `Are you sure you want to continue without saving? This will discard all changes and cannot be undone.`,
        confirmButtonLabel: 'DISCARD',
        cancelButtonLabel: 'CANCEL',
        showSaveButton: false,
        saveChangesButtonLabel: ''
      };
      const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(value => {
        if (value == 'DISCARD') {
          this.closeAdvancedSettings.emit(false);
          this.initializeData();
        }
      });
    }
    else {
      this.closeAdvancedSettings.emit(false);
      this.initializeData();
    }
  }

  /**
   * Action that is triggered when user clicks save button.
   * This sets the user values to the general settings model data.
   * */
  onSave(close: boolean): void {
    const newSetting = {};
    newSetting['calibrationMetrics'] = this.metrics;
    newSetting['selectedCalibrationMetric'] = this.selectedMetric;
    newSetting['calcVersion'] = this.selectedVersion;
    newSetting['avgItemPriceElasticity'] = null;
    if (this.showPEBenchmark) {
      newSetting['avgItemPriceElasticity'] = this.peBenchmark;
    }
    this.saveAdvancedSettings.emit({ generalSetting: newSetting, close });
    if (!this.peBenchmark) {
      this.togglePEBenchmark(false);
      this.isPEBenchmarkValid = true;
    }
  }

  togglePEBenchmark(value) {
    this.showPEBenchmark = value;
  }

  validateAvgItemPriceElasticity(): void {
    this.isPEBenchmarkValid = this.calibrationService.validateAvgItemPriceElasticity(this.peBenchmark);
  }
}
