import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-disable-ui',
    templateUrl: './disable-ui.component.html',
    styleUrls: ['./disable-ui.component.scss']
})
export class DisableUiComponent {

    @Input() disable = false;

}
