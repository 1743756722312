import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthProxyService} from '../services/auth-proxy.service';
import {User} from '../models/user.model';

@Injectable()
export class InternalUserActivateGuard implements CanActivate {

  constructor(private authProxyService: AuthProxyService) {
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.authProxyService.getUserInfo().subscribe((user: User) => {
        resolve(user.isInternalUser);
      });
    });
  }
}
