import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy, AfterViewChecked, ChangeDetectorRef, Renderer2
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Scenario} from './../../models/scenario.model';
import {ScenarioService} from './../../services/scenario.service';
import {ReadFile} from 'ngx-file-helpers';
import {AuthProxyService} from './../../services/auth-proxy.service';
import {AccessPolicyService} from '../../services/access-policy.service';
import {ScenarioGroupService} from '../../services/scenario-group.service';
import {
    GroupBasedScenarioFilterData,
    NonGroupBasedScenarioFilterData
} from '@app/interfaces/scenario-group-filter-data.interface';
import {AppConstantsService} from "@app/services/app-constants.service";

@Component({
    selector: 'app-scenario-group-filter',
    templateUrl: './scenario-group-filter.component.html',
    styleUrls: ['./scenario-group-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScenarioGroupFilterComponent implements AfterViewChecked {
    @Input() selectedScenarioIds: any;
    @Input() scenarioGroupsData: Array<GroupBasedScenarioFilterData>;
    @Input() referenceScenarioData: NonGroupBasedScenarioFilterData;
    @Input() groupItemStatus: boolean;
    @Input() enableActionMenu: boolean;
    @Input() enableImportActionMenu: boolean;
    @Input() bulkEditStatus: boolean;
    @Input() showCheckbox: boolean;
    @Input() placeholder = 'Select Scenario';
    @Output() scenarioImportEvent = new EventEmitter<ReadFile>();
    @Output() scenarioExportEvent = new EventEmitter();
    @Output() dropDownChanged = new EventEmitter();
    @Output() filter = new EventEmitter();
    isExpandGroup: boolean[] = [];
    searchValue: string;
    dropDownSelectData: any;
    runDataNotAvailableTitle : string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private scenarioService: ScenarioService,
                private scenarioGroupService: ScenarioGroupService,
                private authProxyService: AuthProxyService,
                private accessPolicyService: AccessPolicyService,
                private cdRef: ChangeDetectorRef,
                private renderer: Renderer2,
                private appConstantsService: AppConstantsService) {
    }

    public matSelectCompare(option, value): boolean {
        return option && value && option.scenarioId === value.scenarioId && option.scenarioGroupId === value.scenarioGroupId;
    }


    get isInternalUser(): boolean {
        return this.authProxyService.isInternalUser();
    }

    ngAfterViewChecked(): void {
        this.runDataNotAvailableTitle = this.enableImportActionMenu ? "" : this.appConstantsService.RUN_NOT_AVAILABLE_LABEL;
        this.cdRef.detectChanges();
        this.updateDropDownSelectData();
    }

    selectionChanged($event): void {
        if (this.showCheckbox) {
            this.dropDownChanged.emit($event.value);
        } else {
            this.dropDownChanged.emit($event.value.scenarioId);
        }

        this.dropDownSelectData = $event.value;
    }

    updateDropDownSelectData(): void {
        const singleSelectConditionCheck = !this.dropDownSelectData || (!this.showCheckbox && this.selectedScenarioIds && (!this.dropDownSelectData.scenarioId || this.dropDownSelectData.scenarioId != this.selectedScenarioIds));
        const checkBoxConditionCheck = !this.dropDownSelectData || (this.showCheckbox && this.dropDownSelectData.length > 0 && !this.dropDownSelectData[0].scenarioId);
        if (checkBoxConditionCheck || singleSelectConditionCheck) {
            let dropDownSelectData;
            let scenarioGroupId;
            let scenarioId;
            if (this.showCheckbox) {
                scenarioId = this.selectedScenarioIds[0];
                scenarioGroupId = this.scenarioGroupService.findScenarioGroupByScenarioId(scenarioId);
                dropDownSelectData = {scenarioId: scenarioId, scenarioGroupId: scenarioGroupId};
                this.dropDownSelectData = [dropDownSelectData];
            } else {
                scenarioId = this.selectedScenarioIds;
                scenarioGroupId = this.scenarioGroupService.findScenarioGroupByScenarioId(scenarioId);
                dropDownSelectData = {scenarioId: scenarioId, scenarioGroupId: scenarioGroupId};
                this.dropDownSelectData = dropDownSelectData;
            }
        }
    }

    getScenarioById(id): Scenario {
        return this.scenarioService.getScenario(id);
    }

    expandScenarioGroup(group: any): any {
        this.isExpandGroup[group.id] = !this.isExpandGroup[group.id];
    }

    searchValueClickHandler(): any {
        this.searchValue = '';
        this.filter.emit(this.searchValue);
    }

    getDropDownDisplayName(): string {
        let disPlayName;
        const length = this.dropDownSelectData?.length;
        if (length > 1) {
            disPlayName = `${length} Scenarios`;
        } else if (length === 1) {
            const scenario = this.getScenarioById(this.dropDownSelectData[0].scenarioId);
            disPlayName = scenario ? `${scenario.name}` : '';
        } else {
            disPlayName = 'Select Scenario';
        }
        return disPlayName;
    }

    getDropDownDisplayDesc(): string {
        let desc = '';
        const length = this.dropDownSelectData?.length;
        if (length === 1) {
            const scenario = this.getScenarioById(this.dropDownSelectData[0].scenarioId);
            desc = scenario && scenario.description ? `${scenario.description}` : '';
        }
        return desc;
    }

    search(value): any {
        this.searchValue = value;
        this.filter.emit(value.toLowerCase());
    }

    openManageScenariosPage(): void {
        this.router.navigate(['manage'], {relativeTo: this.route.parent});
    }

    importScenario(file: ReadFile): void {
        this.scenarioImportEvent.emit(file);
    }

    openExportScenarioModal(): void {
        this.scenarioExportEvent.emit();
    }

    getTooltip(): string {
        let tooltip = '';
        if (this.showCheckbox && this.dropDownSelectData?.length === 1) {
            tooltip = `${this.getDropDownDisplayName()}\n${this.getDropDownDisplayDesc()}`;

        } else if (this.dropDownSelectData) {
            tooltip = `${this.getName()}\n${this.getDescription()}`;
        }
        return tooltip;
    }

    getDescription(): string {
        let description = '';
        if (this.dropDownSelectData) {
            const scenario = this.getScenarioById(this.dropDownSelectData.scenarioId);
            description = scenario && scenario.description ? `${scenario.description}` : '';
        }
        return description;
    }

    getName(): string {
        let name = '';
        if (this.dropDownSelectData) {
            const scenario = this.getScenarioById(this.dropDownSelectData.scenarioId);
            name = scenario && scenario.name ? `${scenario.name}` : '';
        }
        return name;
    }

    changeHeight(event: any, element: any) {
        if (event) {
            const height = window.innerHeight || document.documentElement.clientHeight ||
                document.body.clientHeight;
            this.renderer.setStyle(element.panel.nativeElement, 'max-height', (height - 10 - element.panel.nativeElement.getBoundingClientRect().y) + 'px');
        }
    }
}
