export class OptimizationReport {

  constructor() {
    this.competeSkuConfigs = new Array<number>();
    this.lockInSkuConfigs = new Array<number>();
    this.method = 'Fast';
    this.noOfCombinations = 1;
    this.jobId = null;
  }

  id: string;
  projectId: number;
  modelRunId: string;
  scenarioId: string;
  name: string;
  description: string;
  outputConfigMetric: string;
  method: string;
  rules: any;
  competeSkuConfigs: Array<number>;
  lockInSkuConfigs: Array<number>;
  minNoOfItems: number;
  maxNoOfItems: number;
  noOfCombinations: number;
  jobId: number;
  statusCode: number;
  status: string;
  scenarioOutOfSync: boolean;
  selectedSegments: string;
}
