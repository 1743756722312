import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent {
    @Input() additionalColumnOptions: any[];
    @Output() shareCheckedList = new EventEmitter();
    @Output() shareIndividualCheckedList = new EventEmitter();

    checkedList: Array<string>;
    currentSelected: { checked: boolean; name: string };
    showDropDown = false;

    constructor() {
        this.checkedList = [];
    }

    getSelectedValue(status: boolean, value: string): void {
        if (status) {
            this.checkedList.push(value);
        } else {
            const index = this.checkedList.indexOf(value);
            this.checkedList.splice(index, 1);
        }
        this.currentSelected = {checked: status, name: value};
        this.shareIndividualStatus();
    }

    shareIndividualStatus(): void {
        this.shareIndividualCheckedList.emit(this.currentSelected);
    }

    get tooltipText(): string {
        this.checkedList = this.additionalColumnOptions && this.additionalColumnOptions.length ? this.additionalColumnOptions.filter((item) => {
            return item.checked;
        }).map((i) => {
            return i.name;
        }) : [];
        return this.checkedList && this.checkedList.length ? this.checkedList.join(",") : 'None';
    }
}
