import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {AppConfig} from '@app/models/app-config.model';
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {GuardrailService} from '@app/services/guardrail.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  /**
   * We have to use httpClient this way because we have to avoid interceptor to avoid cyclic dependency since
   * HttpConfigInterceptor in turn uses EnvironmentService.
   * @see https://dev.to/bergermarko/dynamic-environment-variable-35dp
   * @see https://github.com/angular/angular/issues/26845
   */
  private httpClient: HttpClient;

  /** config.js file that will contain out environment variables */
  private readonly CONFIG_URL = 'assets/config/config.json';

  constructor(private handler: HttpBackend,
              private environmentService: EnvironmentService,
              private authProxyService: AuthProxyService,
              private guardrailService: GuardrailService) {
    this.httpClient = new HttpClient(handler);
  }

  /**
   * Method for loading configuration.
   * This method first loads the configuration json file, then loads the user information
   * to check if the user has valid session or not then loads guardrails for the application.
   */
  loadConfiguration(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient.get<AppConfig>(this.CONFIG_URL).subscribe((appConfig: AppConfig) => {
        this.environmentService.setAppConfig(appConfig);
        this.authProxyService.fetchUserInfo().subscribe(() => {
          this.guardrailService.fetchAll().subscribe(() => {
            resolve(null);
          });
        });
      });
    });
  }
}
