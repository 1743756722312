import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Scenario} from '../models/scenario.model';
import {EnvironmentService} from './environment.service';
import {HttpClient} from '@angular/common/http';
import {ModelRunService} from '../services/model-run.service';
import {SkuConfig} from '../models/sku-config.model';
import {ReadFile} from 'ngx-file-helpers';
import {ModelCacheUtil} from '../utils/model-cache-util';
import {AsyncTask} from '../models/async-task';
import {DropdownData} from '../components/dropdown/dropdown.data.model';
import {UserConfigurationsService} from '../services/user-configurations.service';
import {SimulationRun} from '../interfaces/simulation-run.interface';
import {SimulationRunInputService} from '../services/simulation-run-input.service';
import {SimulationRunResultService} from '../services/simulation-run-result.service';
import {ModelRunSku} from '../models/model-run-sku.model';
import {SimulationRunInput} from '../models/simulation-run-input.model';
import {SimulationRunResult} from '../models/simulation-run-result.model';
import {ModelRunSkuService} from '../services/model-run-sku.service';
import {UserConfigurations} from '../models/user-configurations.model';
import {AppConstantsService} from '../services/app-constants.service';
import {isEmpty} from '../utils/object-utils';
import {MetaData} from '../models/meta-data.model';

@Injectable({
    providedIn: 'root'
})
export class ScenarioService {

    private scenarioLockToggle$: Subject<Scenario> = new Subject<Scenario>();

    private cachedSkuConfigs: Record<string, Array<SkuConfig>>;

    cachedRunScenarios: ModelCacheUtil<Scenario>;

    get scenarios(): Array<Scenario> {
        return this.cachedRunScenarios.cachedModels;
    }

    get cachedScenarioSkuConfigs(): Record<string, Array<SkuConfig>> {
        return this.cachedSkuConfigs;
    }

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private modelRunService: ModelRunService,
                private userConfigurationsService: UserConfigurationsService,
                private simulationRunInputService: SimulationRunInputService,
                private simulationRunResultService: SimulationRunResultService,
                private modelRunSkuService: ModelRunSkuService,
                private appConstantsService: AppConstantsService) {
        this.cachedRunScenarios = new ModelCacheUtil<Scenario>();
        this.cachedSkuConfigs = {};
    }

    /**
     * We will use this observable subject to trigger simulate request to the server.
     * NOTE: "scenarios" route will actually trigger the next event on the subject.
     * For now we have single subscriber in the scenario component which handles the response.
     * So "scenarios" component is responsible for event trigger, but "scenario" component is
     * responsible to handle the response to the event.
     *
     * @see https://rxjs-dev.firebaseapp.com/guide/subject
     */
    readonly SIMULATE_AND_SAVE_SUBJECT: Subject<any> = new Subject<any>();

    readonly SIMULATION_COMPLETED$: Subject<Scenario> = new Subject<Scenario>();

    private skuConfigInputsReset$: Subject<any> = new Subject<any>();

    readonly RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$: Subject<void> = new Subject<void>();

    readonly MODEL_RUN_POPULATION_CHANGED_SUBJECT: Subject<any> = new Subject<Array<DropdownData<string>>>();

    private createScenarioEvent$: Subject<any> = new Subject<any>();

    private skuConfigInputValidationError$: Subject<boolean> = new Subject<boolean>();

    private showGroupingsFlyout$: Subject<boolean> = new Subject<boolean>();

    private showBulkEditFlyout$: Subject<boolean> = new Subject<boolean>();

    private onBulkEditSaveChanges$: Subject<any> = new Subject<any>();

    private onBulkEditSkuSelectionChange$: Subject<any> = new Subject<any>();

    private onSaveBulkEditChanges$: Subject<boolean> = new Subject<boolean>();

    private onCompareScenarioReturn$: Subject<boolean> = new Subject<boolean>();
    /**
     * Subject to observer change in the selected scenario
     */
    private activeScenario$: Subject<string> = new Subject<string>();

    private deleteScenario$: Subject<any> = new Subject<any>();

    private importScenarioFailure$: Subject<any> = new Subject<any>();

    private onChangeSkuGroupSubject$: Subject<any> = new Subject<any>();

    private onSkuItemGroupingChangedSubject$: Subject<any> = new Subject<any>();

    private onClickSaveChangesSubject$: Subject<any> = new Subject<any>();

    private onSkuSelectionChangesSubject$: Subject<boolean> = new Subject<boolean>();

    private onSaveGroupingItemsSubject$: Subject<boolean> = new Subject<boolean>();

    get scenarioLockToggleSubject(): Subject<any> {
        return this.scenarioLockToggle$;
    }

    get skuConfigInputsResetSubject(): Subject<any> {
        return this.skuConfigInputsReset$;
    }

    get skuConfigInputValidationError(): Subject<boolean> {
        return this.skuConfigInputValidationError$;
    }

    get showGroupingsFlyout(): Subject<boolean> {
        return this.showGroupingsFlyout$;
    }

    get showBulkEditFlyout(): Subject<boolean> {
        return this.showBulkEditFlyout$;
    }

    get onBulkEditSaveChanges(): Subject<any> {
        return this.onBulkEditSaveChanges$;
    }

    get onBulkEditSkuSelectionChange(): Subject<any> {
        return this.onBulkEditSkuSelectionChange$;
    }

    get activeScenario(): Subject<string> {
        return this.activeScenario$;
    }

    get importScenarioFailureSubject(): Subject<any> {
        return this.importScenarioFailure$;
    }

    createScenarioSuccess(data: any): void {
        this.createScenarioEvent$.next(data);
    }

    onCreateScenario(): Observable<any> {
        return this.createScenarioEvent$.asObservable();
    }

    get deleteScenarioSubject(): Subject<any> {
        return this.deleteScenario$;
    }

    get onChangeSkuGroupSubject(): Subject<any> {
        return this.onChangeSkuGroupSubject$;
    }

    get onSkuItemGroupingChangedSubject(): Subject<any> {
        return this.onSkuItemGroupingChangedSubject$;
    }

    get onClickSaveChangesSubject(): Subject<any> {
        return this.onClickSaveChangesSubject$;
    }

    get onSkuSelectionChangesSubject(): Subject<boolean> {
        return this.onSkuSelectionChangesSubject$;
    }

    get onSaveGroupingItemsSubject(): Subject<boolean> {
        return this.onSaveGroupingItemsSubject$;
    }


    get onSaveBulkEditChangesSubject(): Subject<boolean> {
        return this.onSaveBulkEditChanges$;
    }

    get onCompareScenarioReturnSubject(): Subject<boolean> {
        return this.onCompareScenarioReturn$;
    }


    /**
     * Lists all scenarios associated with given project and run id.
     */
    fetchAll(projectId: number, modelRunId: string): Observable<Array<Scenario>> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios`;
        return this.http.get<Array<Scenario>>(url).pipe(map((scenarios: Scenario[]) => {
            this.cachedRunScenarios.clear();
            this.cachedRunScenarios.appendAll(scenarios);
            return scenarios;
        }));
    }

    /**
     * Lists all scenarios associated with given project and run id.
     */
    getAll(projectId: number, modelRunId: string): Observable<Array<Scenario>> {
        const data = this.cachedRunScenarios.filter((it: Scenario) => {
            return it.projectId === projectId && it.modelRunId === modelRunId;
        });
        if (data.length) {
            return of(data);
        } else {
            return this.fetchAll(projectId, modelRunId);
        }
    }

    /**
     * @description Returns an observable of Scenario fetched using the API.
     * @param string id of model run
     * @returns Observable<Array<Scenario>> The run Scenarios observable.
     */
    getScenarios(modelRunId: string, fromCache = true): Observable<Array<Scenario>> {
        if (fromCache && this.scenarios.length > 0) {
            return of(this.scenarios);
        } else {
            const modelRun = this.modelRunService.getModelRun(modelRunId);
            if (modelRun) {
                const env = this.environmentService.environment.authProxy;
                const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${modelRun.projectId}/runs/${modelRun.id}/scenarios`;
                return this.http.get<Array<Scenario>>(url).pipe(map((scenarios: Array<Scenario>) => {
                    this.cachedRunScenarios.clear();
                    this.cachedRunScenarios.appendAll(scenarios);
                    return scenarios;
                }));
            } else {
                return null;
            }
        }

    }

    getScenario(scenarioId: string): Scenario {
        return this.cachedRunScenarios.find((scenario: Scenario) => {
            return scenario.id === scenarioId;
        });
    }

    generateSkuConfigs(skus: Array<ModelRunSku>, inputs: Array<SimulationRunInput>, results: Array<SimulationRunResult>): Array<SkuConfig> {
        const _inputs = (inputs && inputs.length) ? inputs.reduce((acc, input) => {
            acc[input.skuId] = input;
            return acc;
        }, {}) : {};
        const _results = (results && results.length) ? results.reduce((acc, result) => {
            acc[result.skuId] = result;
            return acc;
        }, {}) : {};
        return skus.map((sku) => {
            return Object.assign({}, sku, _inputs[sku.skuId], _results[sku.skuId]);
        });
    }

    /**
     * Returns an observable of sku configs for a given scenario
     * @param string:modelRunId
     * @param string:scenarioId
     * @returns Observable<Array<any>>: sku config observable
     */
    getSkuConfigs(projectId: number, modelRunId: string, scenarioId: string): Observable<Array<SkuConfig>> {
        return new Observable(subscriber => {
            forkJoin([
                this.modelRunSkuService.fetchAll(projectId, modelRunId),
                this.simulationRunInputService.fetchAll(projectId, modelRunId, scenarioId),
                this.simulationRunResultService.fetchAll(projectId, modelRunId, scenarioId)])
                .subscribe(([skus, inputs, results]) => {
                    const skuConfigs = this.generateSkuConfigs(skus, inputs, results);
                    this.cachedSkuConfigs[scenarioId] = skuConfigs;
                    subscriber.next(skuConfigs);
                    subscriber.complete();
                });
        });
    }

    createScenario(newScenario: Scenario, scenarioGroups: any): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${newScenario.projectId}/runs/${newScenario.modelRunId}/scenarios`;
        return this.http.post<Scenario>(url, {scenario: newScenario, scenarioGroups});
    }

    simulateAndSave(simulationRun: SimulationRun): Observable<SimulationRun> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${simulationRun.projectId}/runs/${simulationRun.modelRunId}/scenarios/${simulationRun.scenario.id}/simulateAndSave`;
        return this.http.post<SimulationRun>(url, simulationRun);
    }

    simulateScenarios(projectId: number, modelRunId: string, scenarioIds: Array<string>, userRunLevelSelectedSegments: string): Observable<Array<Scenario>> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/simulate?userRunLevelSelectedSegments=${userRunLevelSelectedSegments}&ids=${scenarioIds.join(',')}`;
        return this.http.get<Array<Scenario>>(url);
    }

    /**
     * Updates scenario details
     */
    updateScenario(projectId: number, modelRunId: string, scenarioId: string, data: { name: string; description: string; }): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/${scenarioId}`;
        return this.http.put<Scenario>(url, data);
    }

    update(scenario: Scenario): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${scenario.projectId}/runs/${scenario.modelRunId}/scenarios/${scenario.id}`;
        return this.http.put<Scenario>(url, {profitType: scenario.profitType}).pipe(map((updatedScenario: Scenario) => {
            this.cachedRunScenarios.append(updatedScenario);
            return updatedScenario;
        }));
    }

    /**
     * delete will delete scenario from all groups
     */
    delete(scenario: any): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${scenario.projectId}/runs/${scenario.modelRunId}/scenarios/${scenario.id}`;
        return this.http.delete<any>(url);
    }

    importScenario(projectId: number, modelRunId: string, file: ReadFile): Observable<AsyncTask> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios`;
        const formData = new FormData();
        formData.append('file', file.underlyingFile);
        return this.http.post<AsyncTask>(url, formData);
    }

    /**
     * Returns single price elasticity report associated with given project and run id.
     */
    getById(id: string, params: { projectId: number; modelRunId: string }): Observable<Scenario> {
        const scenario = this.cachedRunScenarios.find((it: Scenario) => {
            return it.id === id;
        });
        if (scenario) {
            return of(scenario);
        } else {
            return this.fetchById(id, params);
        }
    }

    fetchById(id: string, params: { projectId: number; modelRunId: string }): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${params.projectId}/runs/${params.modelRunId}/scenarios/${id}`;
        return this.http.get<Scenario>(url).pipe(map((sc: Scenario) => {
            this.cachedRunScenarios.append(sc);
            return sc;
        }));
    }

    asyncExport(projectId: number, modelRunId: string, scenarioIds: string[], exportingForChartingTool: boolean): Observable<AsyncTask> {
        const scenarioIdsParam = scenarioIds.join(',');
        const exportingForChartingToolParam = exportingForChartingTool ? "true" : "false";        
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/export?scenarios=${scenarioIdsParam}&exportingForChartingTool=${exportingForChartingToolParam}`;
        return this.http.get<AsyncTask>(`${url}`);
    }

    /**
     * Method is used to generate Power BI report
     */
    asyncPowerBIReportGeneration(projectId: number, modelRunId: string, scenarioIds: string[]): Observable<AsyncTask> {
        const scenarioIdsParam = scenarioIds.join(',');
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/powerBIReportGeneration?scenarios=${scenarioIdsParam}`;
        return this.http.get<AsyncTask>(`${url}`);
    }

    saveProfitInput(projectId: number,
                    modelRunId: string,
                    scenarioId: string,
                    profitInputs: Array<Record<any, any>>): Observable<Array<SimulationRunInput>> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/${scenarioId}/profitInput`;
        return this.http.put<Array<SimulationRunInput>>(url, profitInputs);
    }

    exportCompareScenarios(projectId: number, modelRunId: string, baseScenarioId: string, compareScenarioIds: string[], activeGroupId: string,hasUnSelectedNonSampleFilter:boolean): Observable<any> {
        const env = this.environmentService.environment.authProxy;
        let params = `compareScenarioIds=${compareScenarioIds.join(',')}&hasUnSelectedNonSampleFilter=${hasUnSelectedNonSampleFilter}`;
        params = `${params}&skuGroupId=${activeGroupId}`;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/${baseScenarioId}/exportCompare?${params}`;
        return this.http.get(`${url}`, {
                responseType: 'arraybuffer'
            }
        );
    }

    lockScenario(projectId: number, modelRunId: string, scenarioId: string): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/${scenarioId}/lock`;
        return this.http.get<Scenario>(url).pipe(map((sc: Scenario) => {
            this.cachedRunScenarios.append(sc);
            return sc;
        }));
    }

    unlockScenario(projectId: number, modelRunId: string, scenarioId: string): Observable<Scenario> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/${scenarioId}/unlock`;
        return this.http.get<Scenario>(url).pipe(map((sc: Scenario) => {
            this.cachedRunScenarios.append(sc);
            return sc;
        }));
    }

    getSimulatedOn(scenarioId: string): string {
        let simulatedOn = null;
        const userSelectedSegmentsConfig = this.userConfigurationsService.getUserSelectedSegmentsConfig();
        const configurations = userSelectedSegmentsConfig?.configurations;
        if (configurations) {
            const userScenarioConfig = configurations[scenarioId];
            if (userScenarioConfig && configurations.userRunLevelSelectedSegments === userScenarioConfig.userScenarioLevelSelectedSegments) {
                simulatedOn = userScenarioConfig.simulatedOn;
            }
        }
        return simulatedOn;
    }

    getSkuConfigsInDisplayOrder(skuConfigs: any): any {
        skuConfigs = skuConfigs.filter(x => x.skuId > 0);
        const configType = this.appConstantsService.ITEM_ORDER_CONFIG_TYPE;
        const skuConfigsItemDisplayOrderUserConfigurations = this.userConfigurationsService.getUserConfigurationsByType(configType);
        const skuConfigsItemDisplayOrder = skuConfigsItemDisplayOrderUserConfigurations ? skuConfigsItemDisplayOrderUserConfigurations : new UserConfigurations();
        skuConfigs.map(s => {
            if (skuConfigsItemDisplayOrder.configurations && skuConfigsItemDisplayOrder.configurations.skuOrder) {
                s['displayOrder'] = skuConfigsItemDisplayOrder.configurations.skuOrder.find(sku => {
                    return sku.skuId === s.skuId;
                }).displayOrder;
            } else {
                s['displayOrder'] = s.skuId;
            }
        });
        skuConfigs.sort((sku1, sku2) => {
            return sku1.displayOrder - sku2.displayOrder;
        });
        return skuConfigs;
    }

    /**
     * Return true if scenario is calibration scenario
     * */
    isCalibrationScenario(scenario: { name: string }): boolean {
        return scenario && this.isCalibrationScenarioName(scenario.name);
    }

    /**
     * Return true if given name is calibration scenario
     * */
    isCalibrationScenarioName(scenarioName: string): boolean {
        return scenarioName && scenarioName.toLowerCase() === this.appConstantsService.CALIBRATION_SCENARIO_NAME.toLowerCase();
    }

    prepareSimulationRunInputs(skuConfigs: Array<SkuConfig>, metaData: MetaData): Array<SimulationRunInput> {
        const inputs: Array<SimulationRunInput> = [];
        skuConfigs.filter(it => !isEmpty(it.skuId)).forEach(skuConfig => {
            const simulationRunInput: SimulationRunInput = {
                projectId: skuConfig.projectId,
                modelRunId: skuConfig.modelRunId,
                scenarioId: skuConfig.scenarioId,
                skuId: skuConfig.skuId,
                isSelected: parseFloat(skuConfig.distribution) > 0 ? skuConfig.isSelected : false,
                promoDropdownIdx: skuConfig.promoDropdownIdx,
                promoMappedIdx: skuConfig.promoMappedIdx,
                promoPriceText: skuConfig.promoPriceText,
                specialPromoPrice: skuConfig.specialPromoPrice,
                priceInput: skuConfig.priceInput,
                promoPrice: skuConfig.promoPrice,
                distribution: !metaData.hasDistribution ? '1' : skuConfig.distribution,
                promoDistribution: skuConfig.promoDistribution,
                baseSize: skuConfig.baseSize,
                featurePrice: skuConfig.featurePrice,
                featureDistribution: skuConfig.featureDistribution,
                displayPrice: skuConfig.displayPrice,
                displayDistribution: skuConfig.displayDistribution,
                featureAndDisplayPrice: skuConfig.featureAndDisplayPrice,
                featureAndDisplayDistribution: skuConfig.featureAndDisplayDistribution
            };

            if (metaData.promo !== 'none') {
                simulationRunInput.promoDropdownIdx = -1;
                simulationRunInput.promoMappedIdx = -1;

                if (skuConfig.promoPriceText === 'Special Price' && skuConfig.continuousMap !== -1) {
                    simulationRunInput.promoMappedIdx = skuConfig.continuousMap;
                    simulationRunInput.promoPrice = skuConfig.specialPromoPrice;
                } else if (metaData.promo !== 'continuous') {
                    if (skuConfig.promoPriceText) {
                        const selectedPromo = skuConfig.promotions.find(s => s.text === skuConfig.promoPriceText);
                        simulationRunInput.promoMappedIdx = +selectedPromo.map;
                        simulationRunInput.promoDropdownIdx = skuConfig.promotions.indexOf(selectedPromo) + 1;
                        simulationRunInput.promoPrice = +selectedPromo.value;
                    }
                    if (parseFloat(simulationRunInput.promoDistribution) > 0) {
                        simulationRunInput.priceInput = skuConfig.reportingBasePrice;
                    }
                }
            }
            inputs.push(simulationRunInput);
        });
        return inputs.sort((sku1, sku2) => {
            return sku1.skuId - sku2.skuId;
        });
    }
}
