<div class="mr-t-2 reorder-container">
    <div class="tool-bar create-reorder">
        <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnToScenarios()"><span
                class="sif sif-arrow-w"></span></button>
        <h2>REORDER {{modelRun.displayName}} ITEMS</h2>
    </div>
    <div>
        <div class="reorder-item-area">
            <span>Select the items to be reordered and drag and drop into their desired locations.</span>
        </div>
        <div class="item-reorder-page">
            <table>
                <thead>
                    <tr>
                        <td>ITEMS</td>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <th>Reporting Name</th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">

                    <tr *ngFor="let row of skuConfigs" cdkDrag class="item-box">

                        <td><span class="sif sif-drag-handler" cdkDragHandle></span>{{row.skuId}}</td>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <td>{{row.reportingName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>