import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-generic-confirmation-modal',
  templateUrl: './generic-confirmation-modal.component.html',
  styleUrls: ['./generic-confirmation-modal.component.scss']
})
export class GenericConfirmationModalComponent implements OnInit {

  header: string;
  body: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  saveChangesButtonLabel: string;
  showSaveButton: boolean;

  constructor(public dialogRef: MatDialogRef<GenericConfirmationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.header = this.data.header ? this.data.header : 'Are you sure?';
    this.body = this.data.body ? this.data.body : 'Are you sure?';
    this.cancelButtonLabel = this.data.cancelButtonLabel ? this.data.cancelButtonLabel : 'Cancel';
    this.confirmButtonLabel = this.data.confirmButtonLabel ? this.data.confirmButtonLabel : 'Confirm';
    this.saveChangesButtonLabel = this.data.saveChangesButtonLabel ? this.data.saveChangesButtonLabel : 'SaveChanges';
    this.showSaveButton = this.data.showSaveButton ? this.data.showSaveButton : false;
  }

  onConfirm(): void {
    this.dialogRef.close(this.confirmButtonLabel);
  }

  onSaveChanges(): void {
    this.dialogRef.close(this.saveChangesButtonLabel);
  }
}
