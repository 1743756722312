import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {CreatePriceChangeImpactComponent} from './create-price-change-impact.component';

@Injectable()
export class CreatePriceChangeImpactReportDeactivateGuard implements CanDeactivate<CreatePriceChangeImpactComponent> {

  canDeactivate(component): Promise<boolean> {
    return component.canDeactivate();
  }
}
