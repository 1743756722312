<div class="mr-t-2 calibration-container">
  <div class="tool-bar create-calibration">
    <div class="calibration-text">
      <button mat-icon-button aria-label="back-button" class="back-btn" (click)="returnToScenarios()"><span
          class="sif sif-arrow-w"></span></button>
      <h2>Database Calibration</h2>
      <span class="separator-vert"></span>
      <span *ngIf="isCalibrationStepStarted()" [ngClass]="calibrationStatusState"><span
          [innerHTML]="calibrationStatus"></span><a class="stop-calibration" *ngIf="isLongRuningCalibration(calibration,generalCalibrationSetting.calcVersion)" (click)="stopCalibration()">Stop</a></span>
    </div>
    <div style="margin-left: auto;">
      <mat-slide-toggle [id]="'calibration-lock-toggle'" [checked]="calibrationLocked"
        [disabled]="disableLockCalibration" [matTooltip]="calibrationLocked?'Edit Mode':'Read-only Mode'"
        (toggleChange)="toggleCalibrationLock()">
      </mat-slide-toggle>
    </div>
    <span class="separator-vert"></span>
    <button mat-icon-button class="sif-gear-button" id="calibration-settings" [disabled]="disableCalibrationSettings || !calibrationLocked || lockMessage || calibrating"
      (click)="onCalibrationSettingsFlyoutToggle(true)"><span class="sif sif-gear"></span></button>
    <div class="export-menu">
      <button mat-button [ngClass]="t.menuOpen? 'active':'inActive'" #t="matMenuTrigger" [matMenuTriggerFor]="menu">
        <span class="sif sif-ellipsis-vertical"></span>
      </button>
      <mat-menu #menu="matMenu">
        <span [matTooltip]="calibration.statusCode < 6 ? 'Calibration Not Ready': ''">
          <button [disabled]="calibration.statusCode < 6" mat-menu-item (click)="exportCalibrationData()">Export to
            Excel</button>
          <button [disabled]="calibration.statusCode < 6" mat-menu-item (click)="exportProjectData()">Export Project
            Data</button>
        </span>
      </mat-menu>
    </div>
  </div>

  <app-message-prompt *ngIf="lockMessage" [message]="lockMessage" [type]="'warn'"></app-message-prompt>
  <app-message-prompt *ngIf="!lockMessage && showScenarioInvalidWarningMessage" [message]="timeOnPromoWarningMessage"
    [type]="'danger'" [hideClose]="true"></app-message-prompt>

  <app-disable-ui class="blocker" [disable]="!calibrationLocked || lockMessage || calibrating">
    <div class="tab-bar">
      <div class="tabs-container">
        <button class="tab-btn" [ngClass]="{'active-tab': selectedCalibrationView == 'current'}" mat-button color="tertiary" (click)="updateCurrentType(currentNewTypes[0])">CURRENT ITEMS</button>
        <button class="tab-btn" [ngClass]="{'active-tab': selectedCalibrationView != 'current'}" mat-button color="tertiary" [disabled]="calibration.statusCode < 6 || !hasCorrelatedSkus" (click)="updateCurrentType(currentNewTypes[1])">NEW ITEMS</button>
      </div>
      <div class="action-buttons-container">
        <div *ngIf="calibration.statusCode < 6 ; then preStep2BlockActionButtons"></div>
        <div
          *ngIf="calibration.statusCode >= 6 && selectedCalibrationView == 'current'; then step2BlockCurrentItemActionButtons">
        </div>
        <div
          *ngIf="calibration.statusCode >= 6 && selectedCalibrationView != 'current'; then step2BlockNewItemActionButtons">
        </div>
      </div>
    </div>

    <div *ngIf="calibration.statusCode < 6 ; then preStep2Block else step2Block"></div>

  </app-disable-ui>

  <ng-template #preStep2Block>
    <mat-card class="mat-card-border">
      <div class="toolbar-container">
        <div>
          <div *ngIf=modelRun.runId class="reset-icon-position">
            <div class="filter-menu-list">
              <app-population-filter *ngIf="showPopulationFilter"
                [disable]="groupItemStatus || bulkEditStatus || slideOpenAdvanceSettings" [filters]="filters"
                [nSize]="nSize" [nSizeClass]="lowPopulationMsgType"
                (selectionChange)="onFilterSelectionChange($event)"></app-population-filter>
            </div>
            <button *ngIf=!hidePopulationFiltersReset mat-icon-button matTooltip="Reset Filters"
              [disabled]="!getResetPopulationFiltersStatus()" (click)="resetPopulationFilters()">
              <span class="sif sif-reset"></span>
            </button>
          </div>
        </div>
      </div>
      <app-message-prompt *ngIf="lowPopulation" [message]="lowPopulationMsg" [type]="lowPopulationMsgType"
        class="population-warning"></app-message-prompt>
      <app-prompt secondary color="warn" [showPrompt]="getIsFilterChanged()"
        [headerText]="'Filter(s) are out of sync from previous run.'" [messageList]="originalFilters"
        [isExpanded]="isFilterChangedExpanded" [hideDismiss]="true">
      </app-prompt>

      <app-prompt secondary color="information" [showPrompt]="!isCalibrationStepStarted()" [hideDismiss]="true">
        {{calibrationStatus}}
      </app-prompt>
      <div id="hot-table" class="hot-table-container">
        <div class="hot-table sku-config-table hot-calibration-table">
          <ng-template [ngIf]="dataSet && dataSet.length">
            <app-calibration-config-table [hotTableData]="dataSet" [cellRenderers]="cellRenderers"
              [tableSettings]="tableSettings" [metaData]="metaData" [skuGroups]="skuGroups" [scenarios]="scenarios"
              [isCalibrationInitiated]="isCalibrationInitiated" [scenarioSkuConfigs]="scenarioSkuConfigs"
              (errorMessageEvent)="tableErrorEventHandler($event)"
              (tableChangesEvent)="tableDataChangeEventHandler($event)" [reloadTableSubject]="reloadTable"
              [calibrationStatus]="calibration.statusCode"
              [invalidScenarioNames]="timeOnPromoInvalidScenariosList"></app-calibration-config-table>
          </ng-template>
        </div>
      </div>
      <div *ngIf="slideOpenCalibrationSettings" class="calibration-settings-cover" (click)="clickedOutsideDrawer()">
      </div>
      <app-calibration-settings *ngIf="!disableCalibrationSettings" class="flyout-menu"
        [ngClass]="slideOpenCalibrationSettings ? 'flyout-menu-open' : 'flyout-menu-close'"
        [generalSetting]="generalCalibrationSetting" [adminView]="enableCalibrationSettingsAdminView"
        [calcVersion]="generalCalibrationSetting.calcVersion"
        (closeAdvancedSettings)="onCalibrationSettingsFlyoutToggle($event)"
        (saveAdvancedSettings)="onCalibrationSettingsSave($event)"
        [triggerClose]="triggerClose"></app-calibration-settings>
    </mat-card>

  </ng-template>
  <ng-template #step2Block>
    <mat-card class="mat-card-border">

      <div class="toolbar-container">
        <div class="dropdown-container">
          <div class="collapsible" @bodyExpansion *ngIf="collapsed">
            <div class="show-inputs" *ngIf="scenarioDropdownShow">
              <app-scenario-group-filter id="selectDropDown" [enableActionMenu]=true
                [placeholder]="'Select Base Scenario'" specificattr (dropDownChanged)="changeScenario($event)"
                (filter)="search($event)" [referenceScenarioData]="referenceScenarioData"
                [scenarioGroupsData]="scenarioGroupsData" [groupItemStatus]=false [enableActionMenu]=false
                [selectedScenarioIds]="selectedScenarioId">
              </app-scenario-group-filter>
            </div>
          </div>
          <div class="filters-container">
            <div *ngIf=modelRun.runId class="reset-icon-position">
              <div class="filter-menu-list">
                <app-population-filter *ngIf="showPopulationFilter"
                  [disable]="groupItemStatus || bulkEditStatus || slideOpenAdvanceSettings" [filters]="filters"
                  [nSize]="nSize" [nSizeClass]="lowPopulationMsgType"
                  (selectionChange)="onFilterSelectionChange($event)"></app-population-filter>
              </div>
              <button *ngIf=!hidePopulationFiltersReset mat-icon-button matTooltip="Reset Filters"
                [disabled]="!getResetPopulationFiltersStatus()" (click)="resetPopulationFilters()">
                <span class="sif sif-reset"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-message-prompt *ngIf="lowPopulation" [message]="lowPopulationMsg" [type]="lowPopulationMsgType"
        class="population-warning"></app-message-prompt>
      <app-message-prompt *ngIf="showProgress" [type]="'warn'" [message]="exportWarnMessage"
        (promptCloseEvent)="messageBarCloseEventHandler()"></app-message-prompt>
      <app-prompt secondary color="warn" [showPrompt]="getIsFilterChanged()"
        [headerText]="'Filter(s) are out of sync from previous run.'" [messageList]="originalFilters"
        [isExpanded]="isFilterChangedExpanded" [hideDismiss]="true">
      </app-prompt>
      <ng-template [ngIf]="dataSet && dataSet.length &&  selectedCalibrationView==='current'">
        <app-calibration-results [hotTableData]="dataSet" [cellRenderers]="cellRenderers"
          [tableSettings]="tableSettings" [metaData]="metaData" [skuGroups]="skuGroups" [scenarios]="scenarios"
          [scenarioSkuConfigs]="scenarioSkuConfigs" [scenarioColumnVisible]=scenarioColumnVisible
          (errorMessageEvent)="tableCurrentResultsErrorEventHandler($event)" (tableChangesEvent)="tableDataChangeEventHandler($event)"
          [reloadCurrentResultsTableSubject]="reloadCurrentResultsTable"></app-calibration-results>
      </ng-template>
      <ng-template
        [ngIf]="newResultDataSet && !scenarioResultsTable && newResultDataSet.length &&  selectedCalibrationView==='new'">
        <app-calibration-results-new [hotTableData]="newResultDataSet" [cellRenderers]="cellRenderers"
          [tableSettings]="tableSettings" [metaData]="metaData" [skuGroups]="skuGroups" [scenarios]="scenarios"
          [scenarioColumnVisible]=scenarioColumnVisible [scenarioSkuConfigs]="scenarioSkuConfigs"
          (newErrorMessageEvent)="tableNewResultsErrorEventHandler($event)"
          (tableChangesEvent)="tableDataChangeEventHandler($event)"
          [reloadNewResultsTableSubject]="reloadNewResultsTable"></app-calibration-results-new>
      </ng-template>
      <ng-template [ngIf]="scenarioResultsTable">
        <app-calibration-scenario-results [hotTableData]="newResultDataSet" [cellRenderers]="cellRenderers"
          [tableSettings]="tableSettings" [metaData]="metaData" [skuGroups]="skuGroups" [scenarios]="scenarios"
          [scenarioColumnVisible]=scenarioColumnVisible [scenarioSkuConfigs]="scenarioSkuConfigs"
          (errorMessageEvent)="tableErrorEventHandler($event)" (tableChangesEvent)="tableDataChangeEventHandler($event)"
          [reloadScenarioResultsTableSubject]="reloadScenarioResultsTable"></app-calibration-scenario-results>
      </ng-template>
      <div *ngIf="slideOpenCalibrationSettings" class="calibration-settings-cover" (click)="clickedOutsideDrawer()">
      </div>
      <app-calibration-settings *ngIf="!disableCalibrationSettings" class="flyout-menu"
        [ngClass]="slideOpenCalibrationSettings ? 'flyout-menu-open' : 'flyout-menu-close'"
        [generalSetting]="generalCalibrationSetting" [adminView]="enableCalibrationSettingsAdminView"
        [calcVersion]="generalCalibrationSetting.calcVersion"
        (closeAdvancedSettings)="onCalibrationSettingsFlyoutToggle($event)"
        (saveAdvancedSettings)="onCalibrationSettingsSave($event)"
        [triggerClose]="triggerClose"></app-calibration-settings>
    </mat-card>
  </ng-template>

  <ng-template #preStep2BlockActionButtons>
    <div class="action-buttons">
      <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData" mat-icon-button class="sif-buttons"
        title="Upload weekly data file" ngxFilePicker
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        (filePick)="uploadWeeklyDataFile($event)"><span class="sif sif-upload"></span></button>
      <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData"
        [disabled]="!calibration.weeklyDataFileClaimCheckId" mat-icon-button class="sif-buttons"
        title="Download weekly data file" (click)="downloadWeeklyData()"><span class="sif sif-download"></span></button>
      <app-multi-select-dropdown multiselct1 [additionalColumnOptions]="additionalColumnOptions"
        (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
      </app-multi-select-dropdown>
      <span class="separator-vert"></span>
      <button *ngIf="isCalibrationInitiated" mat-icon-button class="sif-buttons" (click)="delete()"><span
          class="sif sif-delete"></span></button>
      <button *ngIf="calibration.statusCode<3" class="close-btn apply-btn" mat-flat-button color="secondary"
        title="{{disableApply?disableApplyReason: ''}}" [disabled]="disableApply"
        (click)="calibrateStep1()">Apply</button>
      <button *ngIf="calibration.statusCode>=3" class="start-run-btn calibrate-btn" mat-flat-button color="primary"
        title="{{disableCalibration?disableCalibrateReason: ''}}" [disabled]="disableCalibration"
        (click)="calibrateStep2WithConfirmation('CALIBRATE')">Calibrate</button>
    </div>
  </ng-template>
  <ng-template #step2BlockCurrentItemActionButtons>
    <div class="action-buttons action-buttons-current">
      <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData"
        [disabled]="!calibration.weeklyDataFileClaimCheckId" mat-icon-button class="sif-buttons"
        title="Download weekly data file" (click)="downloadWeeklyData()"><span class="sif sif-download"></span></button>
      <button mat-icon-button class="preview-button" *ngIf="showPreviewIcon" (click)="onClickPreviewResults()"
        [ngClass]="scenarioDropdownShow ? 'preview-active' : 'inActive'"><span
          class="sif sif-eye-enabled"></span></button><span class="separator-vert"></span>
      <button *ngIf="['discrete', 'continuous', 'both'].includes(metaData.promo)" mat-icon-button class="sif-buttons"
        matTooltip="Adjust Promotions" (click)="onPromotionClick()"><span class="sif sif-pricetag"></span></button>
      <app-multi-select-dropdown multiselct1 [additionalColumnOptions]="additionalColumnOptions"
        (shareIndividualCheckedList)="shareIndividualCheckedList($event)"></app-multi-select-dropdown>
      <span class="separator-vert"></span>
      <button mat-icon-button class="sif-buttons" matTooltip="Clear Calibration Data" (click)="delete()"><span
          class="sif sif-clear"></span></button>
      <button class="start-run-btn" mat-flat-button color="secondary"
        title="{{disableCalibration?disableCalibrateReason: ''}}" [disabled]="disableCalibration||calibration.elasticity"
        (click)="calibrateStep2WithConfirmation('RECALIBRATE')">RECALIBRATE</button>
      <div class="split-button">
        <button class="start-run-btn primary-action-btn" mat-flat-button color="primary" [disabled]="disableSave||calibration.elasticity"
          (click)="saveCalibrationWithConfirmation()">SAVE</button>
        <button mat-flat-button color="primary" class="secondary-action-btn" [matMenuTriggerFor]="psb2" [disabled]="disableSave||calibration.elasticity">
          <span class="sif sif-chevron-s"></span>
        </button>
        <mat-menu #psb2="matMenu" xPosition="before" class="split-button-menu">
          <button mat-menu-item (click)="saveCalibrationWithConfirmation(true)">Save adjustments and fetch elasticity</button>
        </mat-menu>
      </div>
    </div>
  </ng-template>
  <ng-template #step2BlockNewItemActionButtons>
    <div class="action-buttons">
      <button *ngIf="calibration.statusCode>=3 && enableUploadWeeklyData"
        [disabled]="!calibration.weeklyDataFileClaimCheckId" mat-icon-button class="sif-buttons"
        title="Download weekly data file" (click)="downloadWeeklyData()"><span class="sif sif-download"></span></button>
      <button mat-icon-button class="preview-button" *ngIf="showPreviewIcon" (click)="onClickPreviewResults()"
        [ngClass]="scenarioDropdownShow ? 'preview-active' : 'inActive'"><span
          class="sif sif-eye-enabled"></span></button><span class="separator-vert"></span>
      <button *ngIf="['discrete', 'continuous', 'both'].includes(metaData.promo)" mat-icon-button class="sif-buttons"
        matTooltip="Adjust Promotions" (click)="onPromotionClick()"><span class="sif sif-pricetag"></span></button>
      <app-multi-select-dropdown multiselct1 [additionalColumnOptions]="additionalColumnOptions"
        (shareIndividualCheckedList)="shareIndividualCheckedList($event)"></app-multi-select-dropdown>
      <span class="separator-vert"></span>
      <button mat-icon-button class="sif-buttons" matTooltip="Clear Calibration Data" (click)="delete()"><span
          class="sif sif-clear"></span></button>
      <button class="start-run-btn" mat-flat-button color="secondary"
        title="{{disableCalibration?disableCalibrateReason: ''}}" [disabled]="disableCalibration||calibration.elasticity"
        (click)="calibrateStep2WithConfirmation('CALIBRATE NEW')">CALIBRATE NEW</button>
      <button class="start-run-btn" mat-flat-button color="primary" [disabled]="disableSave||calibration.elasticity"
        (click)="saveCalibrationWithConfirmation()">SAVE</button>
    </div>
  </ng-template>
</div>