import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HotTableRegisterer} from '@handsontable/angular';
import Handsontable from 'handsontable';
import {roundTo, valueHasPercentageAtTheEnd, numericRangeValidator} from '../../utils/sku-config.validator';
import {SkuConfigTableCellRenderers} from '../../utils/sku-config-table-cell-renderers';
import {Subject, Subscription} from 'rxjs';
import {ScenarioService} from '@app/services/scenario.service';
import {EnvironmentService} from '@app/services/environment.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SkuGroup} from '../../models/sku-group.model';
import {UserConfigurations} from '../../models/user-configurations.model';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import CellChange = Handsontable.CellChange;
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {ActivatedRoute} from '@angular/router';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {MetaDataService} from '@app/services/meta-data.service';
import {ColumnChooserComponent} from '@app/components/column-chooser/column-chooser.component';
import {SkuConfig} from '@app/models/sku-config.model';
import {isEmpty} from '@app/utils/object-utils';

@Component({
    selector: 'app-calibration-config-table',
    templateUrl: './calibration-config-table.component.html',
    styleUrls: ['./calibration-config-table.component.scss'],
})
export class CalibrationConfigTableComponent implements OnInit, AfterViewInit {
    @Input() hotTableData: any;
    @Input() tableSettings: any;
    @Input() skuGroups: SkuGroup[];
    @Input() cellRenderers: SkuConfigTableCellRenderers;
    @Input() metaData: any;
    @Input() calibrationStatus : number;

    /**
     * If enabled, then the table should be in edit mode.
     * */
    @Input() editMode: boolean;

    @Input() reloadTableSubject: Subject<any>;
    @Output() errorMessageEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() tableChangesEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() scenarios: any;
    @Input() scenarioSkuConfigs: Record<string, SkuConfig[]>
    @Input() isCalibrationInitiated : boolean;
    @Input() invalidScenarioNames: {id: string, name: string}[];

    subscriptions: Subscription;
    hotTableInstance: any;
    hotTableId = 'skuConfigTable-config';
    hotTableSettings: Handsontable.GridSettings = {};
    invalidCells = new Map(); // holds invalid hot table cells
    errorMessages: string[] = [];
    activeGroup: any;
    activeBrandsList: string[] = [];
    groupsHierarchy: Array<any> = [];
    filterState: any = [];
    allFilteredRows: any = [];
    resetOutputs: boolean;
    dialogRef: any;
    skuConfigColumnChooser: UserConfigurations;
    tableSettingsWithColumnChooser: any = {
        visibleColumns: [],
        visibleColumnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [],
        groupingsStartAtIndex: 3,
        inputsStartAtIndex: 0,
        outputsStartAtIndex: 0
    };
    brandsSelected: any;
    isAllSkusSelectedState: number;
    selectedSkuCount: number;
    currentCollapsedColumns: any;

    constructor(private hotTableRegisterer: HotTableRegisterer,
                private scenarioService: ScenarioService,
                private environmentService: EnvironmentService,
                private dialog: MatDialog,
                private userConfigurationsService: UserConfigurationsService,
                private appConstantsService: AppConstantsService,
                private metaDataService: MetaDataService,
                private authProxyService: AuthProxyService,
                private route: ActivatedRoute,
                private uiBlockerService: UiBlockerService,
                private appConstantService: AppConstantsService) {
    }

    ngOnInit(): void {
        this.brandsSelected = [];
        this.activeGroup = '';
        this.currentCollapsedColumns = [];
        this.reloadTableSubject.subscribe(() => {
            this.loadHotTable();
        });
    }

    ngAfterViewInit(): void {
        this.loadHotTable();
    }

    loadHotTable(): void {
        this.uiBlockerService.block();
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        this.tableSettingsWithColumnChooser.allowFiltersForHeaders = [];
        this.tableSettingsWithColumnChooser.visibleColumns = [];
        this.tableSettingsWithColumnChooser.visibleColumnHeaders = [];
        this.tableSettingsWithColumnChooser.groupHeaders = [];
        this.tableSettingsWithColumnChooser.groupingsStartAtIndex = this.tableSettings.groupColumnStartIndex;
        this.tableSettingsWithColumnChooser.inputsStartAtIndex = this.tableSettings.inputsColumnStartIndex;
        this.tableSettingsWithColumnChooser.groupColumnEndIndex = this.tableSettings.groupColumnEndIndex;
        this.tableSettingsWithColumnChooser.inputsColumnEndIndex = this.tableSettings.inputsColumnEndIndex;
        this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex = this.tableSettings.unCalibratedHeadersStartIndex;
        this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex = this.tableSettings.unCalibratedHeadersEndIndex;
        this.tableSettingsWithColumnChooser.targetHeadersStartIndex = this.tableSettings.targetHeadersStartIndex;
        this.tableSettingsWithColumnChooser.targetHeadersEndIndex = this.tableSettings.targetHeadersEndIndex;
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettings.calibratedStartIndex;
        this.tableSettingsWithColumnChooser.calibratedEndIndex = this.tableSettings.calibratedEndIndex;
        this.applyUserConfigurationsForSkuConfigTable();
        if (this.hotTableInstance) {
            this.clearFilterState();
            this.hotTableInstance.loadData(this.hotTableData);
            this.generateTable(this.hotTableData, this.hotTableInstance);
            this.hotTableInstance.render();
        }
        this.uiBlockerService.unblockAll();
    }


    clearFilterState(): void {
        const conditionCollection = this.hotTableInstance && this.hotTableInstance.getPlugin('Filters').conditionCollection;
        if (conditionCollection) {
            this.hotTableInstance.getPlugin('Filters').clearConditions();
        }
    }


    getRowsByFilter(columnIndex, args): Array<number> {
        const rowCount = this.hotTableInstance.countRows();
        if (this.filterState[(columnIndex)] && this.filterState[(columnIndex)].length > 0) {
            this.allFilteredRows = this.allFilteredRows.filter(x => this.filterState[(columnIndex)].indexOf(x) < 0);
        }
        this.filterState[(columnIndex)] = [];
        const resultRowIds = [];
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            if (args.indexOf(rowData[(this.tableSettingsWithColumnChooser.visibleColumns[columnIndex].data)]) === -1) {
                resultRowIds.push(i);
            }
        }
        this.filterState[columnIndex].push(resultRowIds);
        resultRowIds.map(r => {
            if (this.allFilteredRows.indexOf(r) === -1) {
                this.allFilteredRows.push(r);
            }
        });
        return resultRowIds;
    }

    filterAllRowsToHide(rowsToShow: Array<number>): void {
        this.allFilteredRows = this.allFilteredRows.filter(rowNumber => rowsToShow.indexOf(rowNumber) < 0);
    }

    get handsontableLicenseKey(): string {
        const handsontableConfig = this.environmentService.environment.handsontable;
        const handsontableLicenseKey = handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
        return handsontableLicenseKey;
    }

    generateTable(tableData, hotTableInstance): void {
        const self = this;
        const handsontableLicenseKey = this.handsontableLicenseKey;
        const targetsColumnIndex = self.tableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'targetUnitShare');
        const targetsColumnName = targetsColumnIndex > 0 ? self.tableSettingsWithColumnChooser.visibleColumns[targetsColumnIndex].displayName : '';
        hotTableInstance.updateSettings({
            viewportColumnRenderingOffset: 27,
            viewportRowRenderingOffset: 'auto',
            allowInsertColumn: false,
            allowInsertRow: false,
            allowRemoveColumn: false,
            allowRemoveRow: false,
            autoWrapRow: false,
            height: document.documentElement.clientHeight - 180,
            autoWrapCol: false,
            manualRowResize: true,
            bindRowsWithHeaders: true,
            manualRowMove: true,
            manualColumnMove: false,
            fixedColumnsLeft: this.tableSettingsWithColumnChooser.groupHeaders[0].colspan,
            filters: self.tableSettingsWithColumnChooser.allowFiltersForHeaders,
            dropdownMenu: ['filter_by_value', 'filter_action_bar'],
            colHeaders: self.tableSettingsWithColumnChooser.visibleColumnHeaders,
            columns: self.tableSettingsWithColumnChooser.visibleColumns,
            rowHeights: 34,
            manualColumnResize: false,
            data: tableData,
            licenseKey: handsontableLicenseKey,
            nestedHeaders: [self.tableSettingsWithColumnChooser.groupHeaders, self.tableSettingsWithColumnChooser.visibleColumnHeaders],
            hiddenRows: {
                rows: [],
                indicators: false
            },
            collapsibleColumns: [{
                row: -2,
                col: self.tableSettingsWithColumnChooser.groupColumnEndIndex > 0 ? self.tableSettingsWithColumnChooser.groupingsStartAtIndex : 0,
                collapsible: true
            }, {
                row: -2,
                col: self.tableSettingsWithColumnChooser.inputsStartAtIndex,
                collapsible: true
            }],
            renderAllRows: true,
            fixedRowsBottom: 0,
            afterOnCellMouseDown: (event, coords, TD): void => {
                if (event && event.target && ((event.target.firstElementChild && event.target.firstElementChild.classList.contains('sif-column-chooser')) ||
                     event.target.classList.contains('sif-column-chooser'))) {
                     self.openColumnChooserDialog(event);
                     self.hotTableInstance.deselectCell();
                 }
            },
            afterOnCellMouseOver: (event, coords, TD): void => {
                if (event && event.target && ((event.target.firstElementChild && event.target.firstElementChild.classList.contains('tooltip-class')) ||
                    event.target.classList.contains('tooltip-class'))) {
                    if (coords.col === 2 && coords.row >= 0) {
                        const rowData = self.hotTableInstance.getSourceDataAtRow(coords.row);
                        TD.title = rowData.scenarioName;
                    }
                }

            },
            afterGetColHeader(index, th): void {
                if (targetsColumnIndex > 0 && targetsColumnIndex === index && th.textContent === targetsColumnName) {
                    const gearIcon = `<span class='sif sif-sync sync-icon' style="display:none";></span>`;
                    th.innerHTML = `${gearIcon}${th.innerHTML}`;
                }
                const BUTTON_CLASS_NAME = 'changeType';
                const existingButton = th.querySelector('.' + BUTTON_CLASS_NAME);
                if (!this.enabled) {
                    if (existingButton) {
                        if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]'
                            && this.getSettings().filters.indexOf(index) === -1) {
                            existingButton.parentNode.removeChild(existingButton);
                        }
                    }
                    return;
                }
            },
            afterValidate: (isValid, value, row, prop, source): void => {
                self.afterValidate(hotTableInstance, isValid, value, row, prop, source);
            },
            /*
              Need to update the original data source because handsontable is in a different component
              whose data is passed in as an input. Since it's one way data binding, the changes
              that have been made to the table are not reflected in original data source.
              An additional logic to update the original data source is written below
            */
            afterCreateRow: (...args): void => {
                self.updateDataSource();
            },
            afterRemoveRow: (...args): void => {
                self.updateDataSource();
            },
            beforeColumnCollapse: (): void => {
                self.toggleCurrentCell();
            },
            beforeColumnExpand: (): void => {
                self.toggleCurrentCell();
            },
            beforeFilter: (conditions): boolean => {
                const plugin = self.hotTableInstance.getPlugin('hiddenRows');
                plugin.showRows(this.allFilteredRows);
                /**
                 *  get rows to hide based on new conditions and hide them using plugin
                 */
                conditions.map((filter) => {
                    if (self.filterState[filter.column] && self.filterState[filter.column].length > 0 &&
                        self.allFilteredRows && self.allFilteredRows.length > 0) {
                        self.allFilteredRows = self.allFilteredRows.filter(rowNumber =>
                            self.filterState[(filter.column)].indexOf(rowNumber) < 0);
                    }
                    self.filterState[filter.column] = [];
                    const hRows = self.getRowsByFilter(filter.column, filter.conditions[0].args[0]);
                    plugin.hideRows(hRows);
                });
                Object.keys(self.filterState).forEach((column) => {
                    const col = conditions.find(condition => condition.column === +column);
                    if (!col) {
                        this.filterAllRowsToHide(self.filterState[(column)]);
                        self.filterState[column] = [];
                    }
                });

                /**
                 * remove hidden rows on no conditions
                 */
                if (!conditions.length) {
                    self.allFilteredRows = [];
                    self.filterState = [];
                }
                self.hotTableInstance.render();
                return false;
            },
            /**
             * Checks and cleans up input values before they are applied to the underlying model.
             * Here the assumption is that any value updated(via paste or otherwise) will be of the format ##.## for price
             * and ##.##(%*) for distribution.
             * For price based values, any characters outside of the format will be removed for eg. currency symbols, thousand separators etc.
             * For distribution, if the value is in percent then its converted to absolute value.
             */
            beforeChange: (changes: CellChange[]): void => {
                if (!changes) {
                    return;
                }
                const handsontable = self.hotTableInstance;
                const nonNumericInputRegex = /[^0-9\.]/ig;
                const inputShareFields = new Set(['targetUnitShare', 'targetPercentageUnitsOnPromoShare']);
                const cleanValue = (val): string => {
                    const parts = `${val}`.split('.').map(it => it.replace(nonNumericInputRegex, '')).filter(it => it.length);
                    const decimals = parts.pop();
                    return parts.length ? `${parts.join('')}.${decimals}` : decimals;
                };
                changes.forEach((change) => {
                    const row = change[0];
                    const propName = `${change[1]}`;
                    const rowData = handsontable.getSourceDataAtRow(row);
                    if (rowData.id && inputShareFields.has(propName)) {
                        let newValue = change[3];
                        if (newValue && newValue[newValue.length - 1] === '%') {
                            newValue = cleanValue(newValue.replace('%', ''));
                            change[3] = (Number.parseFloat(newValue) * 1000) / (100 * 1000);
                        } else {
                            const changedValue:string = cleanValue(newValue);
                            if(changedValue === undefined || (Number.parseFloat(changedValue) === 0)){
                                change[3] = propName == 'targetPercentageUnitsOnPromoShare'  && isNaN(parseFloat(changedValue)) ? null : 0;
                            }else{
                                change[3] = changedValue;
                            }
                        }
                    }

                    if(propName === 'scenarioName' && change[2] !== change[3] && !(change[2] === null && change[3] === '')){

                        if(this.invalidScenarioNames.find(value => value.name === change[3])){
                            self.hotTableInstance.setCellMeta(change[0],2,'className','ellipsis invalid-cell-border tooltip-class');
                        }else{
                            self.hotTableInstance.setCellMeta(change[0],2,'className',`ellipsis ${change[3] === 'Select Scenario'?'left-border':''} tooltip-class`);
                        }
                        
                    }
                });
            },
            beforePaste: function (data, coords) {
                data = self.fillWithBlanks(data, coords);
            },
            afterChange: (changes, source): void => {
                if (!changes) {
                    return;
                }
                const hasAtLeastOneChange = changes.find((it) => it[2] != it[3] && !(it[2] === null && it[3] === ''));
                if (hasAtLeastOneChange) {
                    changes.forEach((change) => {
                        const row = change[0];
                        const col = change[1];
                        const newVal = change[3];
                        if (col === 'targetUnitShare') {
                            if (valueHasPercentageAtTheEnd(newVal)) {
                                const actualValue = newVal.substr(0, newVal.length - 1);
                                if (!isNaN(actualValue)) {
                                    self.hotTableInstance.setDataAtRowProp(row, col, roundTo(parseFloat(actualValue) / 100, 2));
                                }
                            }
                        }
                    });
                    self.updateDataSource();
                }
            }
        });
    }

    /**
     * used to handle floating cell which is in edit state, on click of collapse and expand columns
     */
    toggleCurrentCell(): void {
        if (this.activeGroup) {
            this.hotTableInstance.setDataAtCell(0, 0, null);
        } else {
            this.hotTableInstance.setDataAtCell(0, 0, 1);
        }
    }

    fillWithBlanks(data, coords): any {
        let dataLength = data.length;
        for (const coord of coords) {
            for (let i = coord.startRow, j = 0; i <= coord.startRow + dataLength - 1; i += 1, j += 1) {
                for (let k = coord.startCol, l = 0; k <= coord.startCol + dataLength - 1; k += 1, l += 1) {
                    const cellMeta = this.hotTableInstance.getCellMeta(i, k);
                    const cellValue = this.hotTableInstance.getDataAtCell(i, k);
                    if ((!cellValue || cellValue === '--') && cellMeta.readOnly) {
                        data.splice(j, l, ['--', k]);
                        dataLength = dataLength + 1;
                    }
                }
            }
        }
        return data;
    }

    afterValidate(hotInstance, isValid, value, row, prop, source): void {
        if (source !== 'loadData') {
            if (!isValid) {
                if (!this.invalidCells.has(row)) {
                    this.invalidCells.set(row, new Set<number>());
                }
                this.invalidCells.get(row).add(prop);
            } else if (this.invalidCells.has(row)) {
                this.invalidCells.get(row).delete(prop);
                if (this.invalidCells.get(row).size === 0) {
                    this.invalidCells.delete(row);
                }
            }
            this.setErrorMessages();
            this.errorMessageEvent.emit({
                nonFormErrorMessages: this.errorMessages,
            });
        }
    }

    updateDataSource(): void {
        if (!this.errorMessages || this.errorMessages.length === 0) {
            this.tableChangesEvent.emit(this.hotTableInstance.getSourceData());
        }
    }

    setErrorMessages(): void {
        this.errorMessages = [];
        const it = this.invalidCells.values();
        let result = it.next();
        while (!result.done) {
            this.errorMessages.push('One or more cells has invalid data please check');
            result = it.next();
        }
    }

    makeCopyOfTableSettings(): void {
        this.tableSettings.allowFiltersForHeaders.forEach(h => {
            this.tableSettingsWithColumnChooser.allowFiltersForHeaders.push(h);
        });
        this.tableSettings.groupHeaders.forEach(s => {
            this.tableSettingsWithColumnChooser.groupHeaders.push(Object.assign({}, s));
        });

        this.tableSettings.columnHeaders.forEach(c => {
            this.tableSettingsWithColumnChooser.visibleColumnHeaders.push(c);
        });
        this.tableSettings.columns.forEach(c => {
            this.tableSettingsWithColumnChooser.visibleColumns.push(Object.assign({}, c));
        });
    }

    /**
     *
     * @param  type which is columnChooser type either GROUPS/INPUTS/OUTPUTS
     * returns number of columns hidden due to user configurations
     */
    removeColumnsWhichAreNotSelected(type: string, configurations: any): number {
        const columnGroupHeader = this.tableSettingsWithColumnChooser.groupHeaders.find(h => h.type === type);
        let noOfHiddenColumns = 0;

        this.tableSettings.columns.forEach((column) => {
            const columnChooser = configurations.find(configuration => configuration.name === column.name);
            if (columnChooser && !columnChooser.showInColumn) {
                const index = this.tableSettingsWithColumnChooser.visibleColumns.findIndex(a => a.name === columnChooser.name);
                this.tableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
                this.tableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
                if (type === 'GROUPS') {
                    this.tableSettingsWithColumnChooser.allowFiltersForHeaders.splice(this.tableSettingsWithColumnChooser.visibleColumnHeaders.indexOf(index));
                }
                noOfHiddenColumns++;
            }
        });

        if (columnGroupHeader) {
            columnGroupHeader.colspan = columnGroupHeader.colspan - noOfHiddenColumns;
            if (!columnGroupHeader.colspan) {
                const headerIndex = this.tableSettingsWithColumnChooser.groupHeaders.indexOf(columnGroupHeader);
                this.tableSettingsWithColumnChooser.groupHeaders.splice(headerIndex, 1);
            }
        }

        return noOfHiddenColumns;
    }


    setUserConfigurations(): void {
        const configType = this.appConstantsService.calibrationColumnChooserConfigType;
        this.skuConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
        this.skuConfigColumnChooser = this.skuConfigColumnChooser ? this.skuConfigColumnChooser : new UserConfigurations();
        this.skuConfigColumnChooser.configurations = this.skuConfigColumnChooser.configurations ? this.skuConfigColumnChooser.configurations : {
            hideGroups: [],
            hideInputs: [],
            hideOutputs: [],
            hideUncalibratedColumns:[],
            hideTargetsAndAdjustments:[]
        };
    }
    applyUserConfigurationsForSkuConfigTable(): void {
        this.makeCopyOfTableSettings();
        this.setUserConfigurations();
        this.setGroupingsColumnChooser();
        let hiddenColumnCount = this.removeColumnsWhichAreNotSelected('GROUPS', this.skuConfigColumnChooser.configurations['hideGroups']);
        if(this.isCalibrationInitiated){
            this.tableSettingsWithColumnChooser.inputsStartAtIndex = this.tableSettings.inputsColumnStartIndex - hiddenColumnCount;
            this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex = this.tableSettings.unCalibratedHeadersStartIndex - hiddenColumnCount;
            this.tableSettingsWithColumnChooser.targetHeadersStartIndex =  this.tableSettingsWithColumnChooser.targetHeadersStartIndex -hiddenColumnCount;
            this.setInputsColumnChooser();
            hiddenColumnCount = this.removeColumnsWhichAreNotSelected('INPUTS', this.skuConfigColumnChooser.configurations['hideInputs']);
            this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex = this.tableSettings.unCalibratedHeadersStartIndex - hiddenColumnCount;
            this.tableSettingsWithColumnChooser.targetHeadersStartIndex =  this.tableSettingsWithColumnChooser.targetHeadersStartIndex - hiddenColumnCount;
            this.setUncalibratedColumnChooser();
            this.setTargetColumnChooser();
            hiddenColumnCount = this.removeColumnsWhichAreNotSelected('TARGETS', this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments']);
            this.tableSettingsWithColumnChooser.targetHeadersEndIndex = this.tableSettingsWithColumnChooser.targetHeadersEndIndex - hiddenColumnCount;
        }

    }

    removeFilterOnReportingNameColumn(): void {
        const reportColumnFilterIndex = this.tableSettingsWithColumnChooser.allowFiltersForHeaders.indexOf(2);
        this.tableSettingsWithColumnChooser.allowFiltersForHeaders.splice(reportColumnFilterIndex, 1);
    }

    applyColumnSelectionChange(): void {
        if (this.skuConfigColumnChooser.id) {
            this.userConfigurationsService.updateUserConfiguration(this.skuConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.loadHotTable();
            });
        } else {
            this.skuConfigColumnChooser.configType = this.appConstantsService.calibrationColumnChooserConfigType;
            this.skuConfigColumnChooser.projectId = this.metaData.projectId;
            this.skuConfigColumnChooser.modelRunId = this.metaData.modelRunId;
            this.userConfigurationsService.createNewUserConfiguration(this.skuConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.loadHotTable();
            });
        }
    }


    setGroupingsColumnChooser(): void {
        if (this.skuGroups.length > 0) {
            this.skuGroups.forEach((skuGroup: SkuGroup) => {
                const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
                    return c.name === skuGroup.name;
                });
                if (!groupChooser) {
                    this.skuConfigColumnChooser.configurations['hideGroups'].push({
                        displayName: skuGroup.displayName,
                        name: skuGroup.name,
                        showInRow: true,
                        showInColumn: true
                    });
                } else {
                    groupChooser.displayName = skuGroup.displayName;
                }
            });
        }
        this.skuConfigColumnChooser.configurations['hideGroups'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideGroups']);
    }

    /**
     *
     * @param data columns to be arranged
     */
    orderColumnsForColumnChooser(data: any): any {
        const columnData = [];
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column) => {
            const columnChooser = data.find((c) => {
                return c.name === column.name;
            });
            if (columnChooser) {
                columnData.push(columnChooser);
            }
        });
        return columnData;
    }

    setInputsColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.inputsStartAtIndex &&
                index < this.tableSettingsWithColumnChooser.inputsColumnEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideInputs'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideInputs'].push({
                        displayName: column.displayName,
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
        this.skuConfigColumnChooser.configurations['hideInputs'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideInputs']);
    }

    setUncalibratedColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex &&
                index < this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'].push({
                        displayName: column.displayName.replace('<br>',''),
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
    }

    setTargetColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.targetHeadersStartIndex &&
                index < this.tableSettingsWithColumnChooser.targetHeadersEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'].push({
                        displayName: column.displayName.replace('<br>',''),
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });

        this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments']);
    }


    openColumnChooserDialog(event): void {
        const outputsOnNonSampleFilterToggle = this.metaDataService.outputsOnNonSampleFilterToggle(this.metaData);
        const targetAttr = event.target.getBoundingClientRect();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.hasBackdrop = true;
        dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';
        if (event.target.classList.contains('groups')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideGroups'],
                customDialogOpen: true,
                groupHeaderLabel: 'calibration-groups'
            };
        } else if (event.target.classList.contains('inputs')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideInputs'],
                customDialogOpen: true,
                groupHeaderLabel: 'inputs'
            };
        } else if (event.target.classList.contains('scenario')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideScenario'],
                customDialogOpen: true,
                groupHeaderLabel: 'scenario',
                calibrationStatus : this.calibrationStatus
            };
        }else if (event.target.classList.contains('unCalibrated')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'],
                customDialogOpen: true,
                groupHeaderLabel: 'unCalibrated',
                calibrationStatus : this.calibrationStatus
            };
        }else if (event.target.classList.contains('target')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'],
                customDialogOpen: true,
                groupHeaderLabel: 'target',
                calibrationStatus : this.calibrationStatus
            };
        }
        dialogConfig.position = {
            top: `${targetAttr.y + targetAttr.height + 5}px`,
            left: `${targetAttr.x - targetAttr.width - 200}px`
        };
        this.dialogRef = this.dialog.open(ColumnChooserComponent, dialogConfig);
        this.dialogRef.afterClosed().subscribe(() => {
            if (this.dialogRef.componentInstance.hasChanges) {
                this.applyColumnSelectionChange();
                this.currentCollapsedColumns = [];
                this.dialogRef = null;
            }
        });
    }

}
