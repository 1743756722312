<div *ngIf="showDataSaveWarningMessage">
    <app-message-prompt type="warn" (promptCloseEvent)="messageBarCloseEventHandler($event)"
                        message="There are changes that require a save to the database in order to see them in results."></app-message-prompt>
</div>

<div *ngIf="showCalibrationNeedWarningMessage">
    <app-message-prompt type="warn" (promptCloseEvent)="messageBarCloseEventHandler($event)"
                        message="Last change(s) require a new calibration run."></app-message-prompt>
</div>

<div class="hot-table-container">
    <div class="hot-table sku-config-table hot-custom-check-box hot-calibration-table1">
        <hot-table [hotId]="hotTableId" [settings]="hotTableSettings"
                   [licenseKey]="handsontableLicenseKey">
        </hot-table>
    </div>
</div>