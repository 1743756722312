import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

export interface SingleSelectDropdownOption {
  label: string;
  value: string;
  selected: boolean;
}

@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html',
  styleUrls: ['./single-select-dropdown.component.scss']
})
export class SingleSelectDropdownComponent implements OnInit, OnChanges {

  @Input() options: Array<SingleSelectDropdownOption>;
  @Input() selectedOption: SingleSelectDropdownOption;
  @Input() disable: boolean;
  @Output() optionChanged = new EventEmitter<SingleSelectDropdownOption>();

  checkedList: Array<string>;
  showDropDown = false;
  selectedOptionLabel: string;

  ngOnInit() {
    this.checkedList = [];
    this.selectedOptionLabel = this.getSelectedOptionLabel();
  }

  toggleDropDown(): void {
    if (!this.disable) {
      this.showDropDown = !this.showDropDown;
    }
  }

  hideDropDown(): void {
    this.showDropDown = false;
  }

  getSelectedOptionLabel(): string {
    const selectedOption = this.options.find(it => it.selected);
    if (selectedOption) {
      return selectedOption.label;
    } else {
      return 'None';
    }
  }

  selectOption(option: SingleSelectDropdownOption): void {
    this.optionChanged.emit(option);
  }

  ngOnChanges() {
    this.selectedOptionLabel = this.getSelectedOptionLabel();
  }

}
