import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ModelRun} from '../models/model-run.model';
import {ModelRunService} from '../services/model-run.service';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {catchError, map} from 'rxjs/operators';
import {UtilService} from '@app/services/util.service';

@Injectable({providedIn: 'root'})
export class ModelRunResolver implements Resolve<ModelRun> {

    constructor(private service: ModelRunService,
                private uiBlockerService: UiBlockerService,
                private utilService: UtilService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<ModelRun> {
        this.uiBlockerService.block();
        const projectId = +this.utilService.getRouteParameter(route, 'projectId');
        const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
        return this.service.getById(null, {projectId, modelRunId}).pipe(map((modelRun) => {
            this.uiBlockerService.unblock();
            return modelRun;
        }), catchError(() => {
            this.uiBlockerService.unblock();
            this.router.navigate(['/404']);
            return of(null);
        }));
    }
}
