export class Scenario {
  id: string;
  projectId: number;
  modelRunId: string;
  name: string;
  editable: boolean;
  description: string;
  basedOnScenarioId: string;
  createdBy: string;
  updatedBy: string;
  profitInputModifiedBy: string;
  profitType: string;
  lockedBy: string;
  lockedOn: Date;
  inputsModifiedOn: Date;
}

interface ExportOptions {
  id: string;
  name: string;  
}

export interface ExportGroup {
  disabled?: boolean;
  name: string;
  exportOptions: ExportOptions[];
}
