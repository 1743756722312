import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {PriceChangeImpactReport} from '../models/price-change-impact-report.model';
import {SkuConfig} from '../models/sku-config.model';
import {UtilService} from '@app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class PriceChangeImpactService {

  /**
   * Cached list of optimization reports
   */
  private cache: Array<PriceChangeImpactReport>;

  constructor(private http: HttpClient, private environmentService: EnvironmentService, private utilService: UtilService) {
    this.cache = [];
  }

  get priceChangeImpactReports(): Array<PriceChangeImpactReport> {
    return this.cache;
  }

  get pricePointRanges(): Array<string> {
    return ['lowest', 'lower', 'low', 'middle', 'high', 'higher', 'highest'];
  }

  getNewReportName(maxLength: number): string {
    const cachedReports = this.cache;
    const prependName = 'Untitled Price Change Impact';
    return this.utilService.generateReportName(prependName, maxLength, cachedReports);
  }

  getDuplicateReportName(prependName: string, reportNameMaxLength: number): string {
    const cachedReports = this.cache;
    return this.utilService.generateDuplicateReportName(prependName, reportNameMaxLength, cachedReports);
  }

  fetchAll(projectId: number, modelRunId: string): Observable<Array<PriceChangeImpactReport>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/priceChangeImpactReports`;
    return this.http.get<Array<PriceChangeImpactReport>>(url).pipe(map((multipleReports: PriceChangeImpactReport[]) => {
      this.cache = multipleReports;
      return multipleReports;
    }));
  }

  /**
   * Lists all price elasticity reports associated with given project and run id.
   */
  getAll(projectId: number, modelRunId: string): Observable<Array<PriceChangeImpactReport>> {
    const reports = this.cache.filter((it: PriceChangeImpactReport) => {
      return it.projectId === projectId && it.modelRunId === modelRunId;
    });
    if (reports.length) {
      return of(reports);
    } else {
      return this.fetchAll(projectId, modelRunId);
    }
  }

  /**
   * Returns single price elasticity report associated with given project and run id.
   */
  getById(id: string, params: { projectId: number; modelRunId: string }): Observable<PriceChangeImpactReport> {
    const report = this.cache.find((it: PriceChangeImpactReport) => {
      return it.id === id;
    });
    if (report) {
      return of(report);
    } else {
      const env = this.environmentService.environment.authProxy;
      const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
      const url = `${contextPath}/projects/${params.projectId}/runs/${params.modelRunId}/priceChangeImpactReports/${id}`;
      return this.http.get<PriceChangeImpactReport>(url).pipe(map((singleReport: PriceChangeImpactReport) => {
        this.cache.push(singleReport);
        return singleReport;
      }));
    }
  }

  /**
   * Generates price elasticity report associated with given project and run id.
   */
  add(priceChangeImpactReport: PriceChangeImpactReport): Observable<PriceChangeImpactReport> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${priceChangeImpactReport.projectId}/runs/${priceChangeImpactReport.modelRunId}/priceChangeImpactReports`;
    return this.http.post<PriceChangeImpactReport>(url, {priceChangeImpactReport}).pipe(map((singleReport: PriceChangeImpactReport) => {
      this.cache.push(singleReport);
      return singleReport;
    }));
  }

  delete(priceChangeImpactReport: PriceChangeImpactReport): Observable<string> {
    const env = this.environmentService.environment.authProxy;
    const projectId = priceChangeImpactReport.projectId;
    const modelRunId = priceChangeImpactReport.modelRunId;
    const id = priceChangeImpactReport.id;
    const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
    const url = `${contextPath}/projects/${projectId}/runs/${modelRunId}/priceChangeImpactReports/${id}`;
    return this.http.delete<PriceChangeImpactReport>(url).pipe(map(() => {
      this.cache.splice(this.cache.indexOf(priceChangeImpactReport), 1);
      return priceChangeImpactReport.id;
    }));
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  generateReport(report: PriceChangeImpactReport): Observable<any> {
    const env = this.environmentService.environment.authProxy;
    const projectId = report.projectId;
    const modelRunId = report.modelRunId;
    const id = report.id;
    const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
    const url = `${contextPath}/projects/${projectId}/runs/${modelRunId}/priceChangeImpactReports/${id}/download`;
    return this.http.get(`${url}`, {
        responseType: 'arraybuffer'
      }
    );
  }

  maxAllowedPriceChangePercent(sku: SkuConfig): number {
    return (sku.priceMax / sku.priceInput - 1) * 100;
  }

  minAllowedPriceChangePercent(sku: SkuConfig): number {
    return (sku.priceMin / sku.priceInput - 1) * 100;
  }

  getRoundedPricePointRange(pricePoints: Record<string, number>): Record<string, number> {
    return {
      lowest: Math.ceil(pricePoints.lowest),
      lower: Math.ceil(pricePoints.lower),
      low: Math.ceil(pricePoints.low),
      middle: 0,
      high: Math.floor(pricePoints.high),
      higher: Math.floor(pricePoints.higher),
      highest: Math.floor(pricePoints.highest)
    };
  }

  getPricePointRange(minPercent: number, maxPercent: number): Record<string, number> {
    const lowest = minPercent;
    const lower = minPercent - (lowest / 3);
    const low = lower - (lowest / 3);
    const highest = maxPercent;
    const higher = highest - (highest / 3);
    const high = higher - (highest / 3);
    return {
      lowest: Math.round(lowest * 100) / 100,
      lower: Math.round(lower * 100) / 100,
      low: Math.round(low * 100) / 100,
      middle: 0,
      high: Math.round(high * 100) / 100,
      higher: Math.round(higher * 100) / 100,
      highest: Math.round(highest * 100) / 100
    };
  }

  /**
   * Returns the price range for given skus.
   * The lowest price point will be the "maximum" lowest price point among all skus.
   * The highest price point will be the "minimum" highest price point among all skus.
   * This is because the "maximum" lowest price(which is negative percentage) is the most you can reduce the price without
   * violating priceMin across multiple skus. And "minimum" highest price(which is positive percentage) is the most
   * you can add to the base reporting price without violating the priceMax across multiple skus.
   *
   * For eg, let assume we have 2 skus with following price points:
   * sku 1: [-30,-20,-10,0,10,20,30]
   * sku 2: [-15,-10,-5,0,5,10,15]
   * Then the lowest price point should be -15 and highest should be 15. (yes could be bit confusing because -15 > -30)
   */
  getPricePointsToTestForSkus(skus: Array<SkuConfig>): Record<string, number> {
    let maxPercent = this.maxAllowedPriceChangePercent(skus[0]);
    let minPercent = this.minAllowedPriceChangePercent(skus[0]);

    skus.forEach((sku) => {
      if (this.maxAllowedPriceChangePercent(sku) < maxPercent) {
        maxPercent = this.maxAllowedPriceChangePercent(sku);
      }
      if (this.minAllowedPriceChangePercent(sku) > minPercent) {
        minPercent = this.minAllowedPriceChangePercent(sku);
      }
    });
    return this.getPricePointRange(minPercent, maxPercent);
  }

   /**
   * Method is used to generate Power BI report
   */
    powerBIReportGeneration(report: PriceChangeImpactReport): Observable<any> {
      const env = this.environmentService.environment.authProxy;
      const projectId = report.projectId;
      const modelRunId = report.modelRunId;
      const id = report.id;
      const contextPath = `${env.url}/${env.lpoSimulatorContextPath}`;
      const url = `${contextPath}/projects/${projectId}/runs/${modelRunId}/priceChangeImpactReports/${id}/powerBIReportGeneration`;
      return this.http.get(url);
    }
}
