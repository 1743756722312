import {Injectable} from '@angular/core';
import {EnvironmentService} from './environment.service';
import {WebSocketClient} from "./../utils/web-socket-client";
import {Observable, of} from "rxjs";
import {flatMap, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class WebMessageService {

  private token: string;
  private hub: string;
  private sockets: Record<string, WebSocketClient> = {};

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService) {
    this.getToken();
  }

  /**
   * Request a new web socket connection token from the server.
   * */
  getToken(reload = false): Observable<string> {
    if (this.token && !reload) {
      return of(this.token);
    } else {
      const env = this.environmentService.environment.authProxy;
      const url = `${env.url}/${env.lpoSimulatorContextPath}/webMessage/token`;
      return this.http.get(url).pipe(map((token: Record<string, string>) => {
        this.token = token.token;
        this.hub = token.hub;
        return this.token;
      }));
    }
  }

  /**
   * Creates a web socket client connection and return a subject on which we can
   * subscribe to read messages.
   * */
  consumeMessage(groupName: string): Observable<string> {
    if (this.sockets[groupName]) {
      return this.sockets[groupName].message$;
    } else {
      return this.getToken().pipe(flatMap((token) => {
        this.sockets[groupName] = new WebSocketClient(token, groupName, () => {
          return this.getToken(true);
        });
        this.sockets[groupName].connect();
        return this.sockets[groupName].message$;
      }));
    }
  }

  /**
   * Closes connection to web socket client
   * */
  close(groupName: string): void {
    if (this.sockets[groupName]) {
      if (this.sockets[groupName].webSocket) {
        this.sockets[groupName].isDestroying = true;
        this.sockets[groupName].message$.unsubscribe();
        this.sockets[groupName].leaveGroup();
        this.sockets[groupName].webSocket.close();
      }
      delete this.sockets[groupName];
    }
  }
}
