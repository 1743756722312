import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../services/report.service';

@Component({
  selector: 'app-price-elasticity',
  templateUrl: './price-elasticity.component.html',
  styleUrls: ['./price-elasticity.component.scss']
})
export class PriceElasticityComponent implements OnInit {

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.reportService.activeReportSubject.next('price-elasticity');
  }

}
