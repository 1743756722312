import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ModelRunService} from '../services/model-run.service';
import {Segment} from '../models/segment.model';
import {UtilService} from '../services/util.service';

@Injectable({providedIn: 'root'})
export class SegmentResolver implements Resolve<Segment[]> {

  constructor(private service: ModelRunService, private utilService: UtilService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Segment[]> {
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    const projectId = +this.utilService.getRouteParameter(route, 'projectId');
    return this.service.fetchRunSegments(projectId, modelRunId);
  }
}
