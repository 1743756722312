import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DropdownData, DropdownItem} from './../dropdown/dropdown.data.model';

@Component({
  selector: 'app-population-filter',
  templateUrl: './population-filter.component.html',
  styleUrls: ['./population-filter.component.scss']
})
export class PopulationFilterComponent implements OnChanges {

  @Input() filters: Array<DropdownData<string>>;

  @Input() nSize: number;

  @Input() disable: boolean;

  @Input() nSizeClass: string;

  @Output() selectionChange = new EventEmitter<DropdownData<string>>();

  nSizeText: string = null;

  onFilterSelectionChange(filter: DropdownData<string>): void {
    this.selectionChange.emit(filter);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nSize) {
      this.nSizeText = this.getNSizeText(this.filters, this.nSize);
    }
  }

  /**
   * Returns the n size population text based on filter selections
   */
  getNSizeText(filters: Array<DropdownData<string>>, nSize: number): string {
    let augmentDropDownItem: DropdownItem<string> = null;
    const nonAugmentDropDownItems: Array<DropdownItem<string>> = [];
    filters.forEach((filter: DropdownData<string>) => {
      filter.data.forEach((filterData: DropdownItem<string>) => {
        if (filterData.name.toLowerCase().trim() === 'augment') {
          augmentDropDownItem = filterData;
        } else {
          nonAugmentDropDownItems.push(filterData);
        }
      });
    });
    const isAllNonAugmentSegmentSelected = nonAugmentDropDownItems.every((item: DropdownItem<string>) => {
      return item.selected;
    });
    let nSizeText = isAllNonAugmentSegmentSelected ? `Sample (n = ${nSize})` : `Filtered Sample (n = ${nSize})`;

    if (augmentDropDownItem && augmentDropDownItem.selected) {
      nSizeText = `Sample with Over Quota (n = ${nSize})`;
    }
    return nSizeText;
  }
}
