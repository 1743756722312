<div class="multi-select-container">
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="showDropDown = !showDropDown">
            <span class="sif sif-column-chooser"></span>
            <span class="none-value">{{tooltipText}}</span>
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <div class="multi-column-head">SHOW / HIDE COLUMNS</div>
            <label *ngFor="let item of additionalColumnOptions">
                <mat-checkbox [(ngModel)]="item.checked"
                    (change)="getSelectedValue(item.checked, item.name)" ></mat-checkbox>
                <span>{{ item.name }}</span>
            </label>
            <div class="edit-rate-value"><input type="text" /></div>
            <div><button class="close-btn" mat-flat-button color="secondary">Apply</button></div>
        </div>
    </div>
</div>