import {Injectable, OnInit} from '@angular/core';
import {AuthProxyService} from './auth-proxy.service';
import {ScenarioService} from './scenario.service';
import {ScenarioGroupService} from './scenario-group.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {

  constructor(private authProxyService: AuthProxyService,
              private scenarioService: ScenarioService,
              private scenarioGroupService: ScenarioGroupService) {
  }

  onConfirm(data: any): void {
    if (data.action === 'deleteScenario') {
      this.scenarioService.deleteScenarioSubject.next({
        scenario: data.scenario
      });
    } else if (data.action === 'deleteScenarioGroup') {
      this.scenarioGroupService.deleteScenarioGroupSubject.next({
        scenarioGroup: data.scenarioGroup
      });
    } else {
      this.authProxyService.logout();
    }
  }
}
