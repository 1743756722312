import {HotTableColumn} from '../interfaces/hot-table.interface';
import {SkuConfigTableCellRenderers} from './sku-config-table-cell-renderers';
import {
    percentageCellValidator,
    priceCellValidator,
    promoPriceCellValidator,
    specialPromoPriceCellValidator
} from '@app/utils/sku-config.validator';

export class SkuConfigTableColumn {

    cellRenderers: SkuConfigTableCellRenderers;

    constructor(cellRenderers: SkuConfigTableCellRenderers) {
        this.cellRenderers = cellRenderers;
    }

    skuIdColumn(): HotTableColumn {
        return {
            name: 'id',
            type: 'numeric',
            data: 'skuId',
            readOnly: true,
            className: 'htRight',
            width: 56,
            index: 1,
            filters: false,
            displayName: 'ID'
        };
    }

    selectSkuColumn(readonly = true): HotTableColumn {
        return {
            name: 'isSelected',
            type: 'checkbox',
            data: 'isSelected',
            readOnly: readonly,
            className: 'htCenter',
            width: 50,
            index: 0,
            filters: false,
            displayName: 'selectAll',
            checkedTemplate: 1,
            uncheckedTemplate: 0,
            renderer: this.cellRenderers.checkBoxRenderer.bind(this.cellRenderers)
        };
    }

    skuNameColumn(): HotTableColumn {
        return {
            name: 'reportingName',
            type: 'text',
            displayName: 'Reporting Name',
            data: 'reportingName',
            readOnly: true,
            className: 'ellipsis htLeft',
            width: 416,
            filters: true,
            index: 2,
            renderer: this.cellRenderers.reportNameRenderer.bind(this.cellRenderers)
        };
    }

    itemCharacteristicsGroupingColumn(name: string): HotTableColumn {
        return {
            name: 'itemCharacteristics',
            type: 'text',
            data: 'itemGroupingText',
            displayName: name,
            readOnly: true,
            className: 'ellipsis htLeft',
            width: 210,
            renderer: this.cellRenderers.toolTipRenderer.bind(this.cellRenderers)
        };
    }

    priceInputColumn(readOnly = true): HotTableColumn {
        return {
            name: 'priceInput',
            type: 'numeric',
            data: 'priceInput',
            displayName: 'Regular Price',
            readOnly: readOnly,
            className: 'htRight',
            width: 124,
            dropdownMenu: false,
            renderer: this.cellRenderers.priceInputRenderer.bind(this.cellRenderers),
            validator: priceCellValidator,
        };
    }

    promoPriceColumn(isContinuousPromo = false): HotTableColumn {
        return {
            name: 'promoPrice',
            type: isContinuousPromo ? 'numeric' : 'dropdown',
            data: isContinuousPromo ? 'promoPrice' : 'promoPriceText',
            readOnly: false,
            className: 'htRight',
            dropdownMenu: false,
            displayName: 'Promo Price',
            width: 124,
            isPromoHeader: true,
            renderer: isContinuousPromo ? this.cellRenderers.continuousPromoPriceRenderer.bind(this.cellRenderers) : this.cellRenderers.promoPriceDropdownRenderer.bind(this.cellRenderers),
            validator: promoPriceCellValidator
        };
    }

    specialPromoPriceColumn(): HotTableColumn {
        return {
            name: 'specialPromoPrice',
            displayName: 'Special Promo Price',
            type: 'numeric',
            data: 'specialPromoPrice',
            readOnly: true,
            className: 'htRight',
            width: 130,
            isPromoHeader: true,
            renderer: this.cellRenderers.specialPromoPriceCellRenderer.bind(this.cellRenderers),
            validator: specialPromoPriceCellValidator
        };
    }

    promoDistributionColumn(): HotTableColumn {
        return {
            name: 'promoDistribution',
            type: 'numeric',
            data: 'promoDistribution',
            displayName: 'Time on Promo',
            readOnly: false,
            className: 'htRight',
            width: 110,
            dropdownMenu: false,
            renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
            validator: percentageCellValidator,
            isPromoHeader: true
        };
    }

    distributionColumn(): HotTableColumn {
        return {
            name: 'distribution',
            type: 'text',
            data: 'distribution',
            readOnly: true,
            className: 'htRight',
            width: 80,
            displayName: 'Distribution',
            dropdownMenu: false,
            renderer: this.cellRenderers.distributionRenderer.bind(this.cellRenderers),
            validator: percentageCellValidator
        };
    }
}


