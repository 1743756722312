import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {CreateSizeElasticityReportComponent} from './create-size-elasticity-report.component';

@Injectable()
export class CreateSizeElasticityReportDeactivateGuard implements CanDeactivate<CreateSizeElasticityReportComponent> {

  canDeactivate(component): Promise<boolean> {
    return component.canDeactivate();
  }
}
