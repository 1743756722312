import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ModelRunService} from '../../../../../services/model-run.service';
import {SkuGroup} from '../../../../../models/sku-group.model';
import {SnackBarService} from '../../../../../components/snack-bar/snack-bar.service';
import {UiBlockerService} from '../../../../../services/ui-blocker.service';
import {ScenarioService} from '../../../../../services/scenario.service';
import {Subscription} from 'rxjs';
import {GenericConfirmationModalComponent} from '../../../../../components/generic-confirmation-modal/generic-confirmation-modal.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SkuGroupService} from '../../../../../services/sku-group.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ItemGrouping} from '../../../../../models/item-grouping.model';

@Component({
    selector: 'app-group-items',
    templateUrl: './group-items.component.html',
    styleUrls: ['./group-items.component.scss']
})
export class GroupItemsComponent implements OnInit {
    /**
     * Currently the implementation of the components do not follow DDAU, hence
     * we have to have this emitter called explicitly when we perform CRUD on data
     * to notify parent the model has changed and hence update the skuGroups.
     * Ideally we should fix this component so that all CRUD operations happen in the
     * parent component instead of within this component.
     * */
    @Output() skuGroupReload = new EventEmitter();
    @Output() clicked = new EventEmitter();
    @Input() skuGroups: Array<SkuGroup>;
    selectedSkuGroupId: number;
    showNoBrandInfo: boolean;
    addCharacteristic: boolean;
    cloneCharacteristic: boolean;
    validations: any;
    hasError = true;
    activeSkuGroup: SkuGroup;
    addItemCharacteristicGrouping: boolean;
    newItemCharacteristicGroupName: string;
    selectedItemGrouping: string;
    selectedItemGroupingId: number;
    activeItemGrouping: ItemGrouping;
    hasNoChangesToSave: boolean;
    subscriptions: Subscription;
    itemCharacteristicName: string;

    constructor(
        private modelRunService: ModelRunService,
        private snackBarService: SnackBarService,
        private uiBlockerService: UiBlockerService,
        private scenarioService: ScenarioService,
        private dialog: MatDialog,
        private skuGroupService: SkuGroupService) {

    }

    ngOnInit(): void {
        this.activeItemGrouping = new ItemGrouping();
        this.activeSkuGroup = new SkuGroup();
        this.selectedSkuGroupId = 0;
        this.showNoBrandInfo = true;
        this.clearValidations();
        this.hasNoChangesToSave = true;
        this.subscriptions = new Subscription();
        this.skuSelectionChangeSubscription();
        this.onSkuGroupToggle(0);
        this.onSaveGroupItemsSubscription();
    }

    skuSelectionChangeSubscription(): void {
        this.subscriptions.add(this.scenarioService.onSkuSelectionChangesSubject.subscribe({
            next: (hasChangesToSave) => {
                this.hasNoChangesToSave = !hasChangesToSave;
            }
        }));
    }

    onSaveCharacteristic(inFromReOrder = false): void {
        const modelRun = this.modelRunService.activeModelRun;
        this.uiBlockerService.block();
        this.activeSkuGroup.projectId = modelRun.projectId;
        this.activeSkuGroup.modelRunId = modelRun.id;
        this.activeSkuGroup.name = this.activeSkuGroup.name ? this.activeSkuGroup.name : this.itemCharacteristicName.trim();
        this.activeSkuGroup.displayName = inFromReOrder ? this.activeSkuGroup.displayName : this.itemCharacteristicName.trim();
        this.activeSkuGroup.showInSimulator = true;
        this.skuGroupService.add(this.activeSkuGroup).subscribe((skuGroup) => {
            this.skuGroupReload.emit();
            const message = this.activeSkuGroup && this.activeSkuGroup.skuGroupId > 0 ? 'updated' : 'created';
            this.onAddOrEditSkuGroup(skuGroup);
            this.snackBarService.openSuccessSnackBar(`Characteristic ${message} successfully`);
            this.uiBlockerService.unblock();
        }, err => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar(err.error.message);
        });
    }

    onAddOrEditSkuGroup(newSkuGroup: SkuGroup): void {
        this.addCharacteristic = false;
        this.selectedSkuGroupId = newSkuGroup.skuGroupId;
        this.activeSkuGroup = newSkuGroup;
        this.showNoBrandInfo = !this.activeSkuGroup || !this.activeSkuGroup.itemGroupings.length;
        this.activeItemGrouping = new ItemGrouping();
        this.scenarioService.onChangeSkuGroupSubject.next({
            skuGroup: newSkuGroup,
            reload: true,
            activeItemGrouping: this.activeItemGrouping
        });
        this.itemCharacteristicName = '';
        this.skuGroupReload.emit();
    }

    selectItemGrouping(selectedItemGrouping, reload = false): void {
        this.activeItemGrouping = selectedItemGrouping;
        this.selectedItemGrouping = selectedItemGrouping.displayName;
        this.selectedItemGroupingId = selectedItemGrouping.itemGroupingId;
        this.hasNoChangesToSave = true;
        this.addCharacteristic = false;
        this.addItemCharacteristicGrouping = false;
        this.scenarioService.onSkuItemGroupingChangedSubject.next({
            skuGroup: this.activeSkuGroup,
            reload: true,
            activeItemGrouping: this.activeItemGrouping
        });
    }

    onClickAddCharacteristic(): void {
        this.validations = {name: {warning: false, error: ''}, groupingName: {warning: false, error: ''}};
        this.selectedSkuGroupId = 0;
        this.onSkuGroupToggle(0);
        this.activeSkuGroup = new SkuGroup();
        this.addCharacteristic = true;
        this.itemCharacteristicName = '';
        this.addItemCharacteristicGrouping = false;
        this.cloneCharacteristic = false;
    }

    onClickAddItemCharacteristicGrouping(): void {
        this.validations = {name: {warning: false, error: ''}, groupingName: {warning: false, error: ''}};
        this.activeItemGrouping = new ItemGrouping();
        this.selectItemGrouping(this.activeItemGrouping);
        this.newItemCharacteristicGroupName = '';
        this.addItemCharacteristicGrouping = true;
        this.addCharacteristic = false;
        this.cloneCharacteristic = false;
    }

    onCancelAddCharacteristic(): void {
        if (this.activeSkuGroup && this.activeSkuGroup.skuGroupId) {
            this.addCharacteristic = false;
            this.addItemCharacteristicGrouping = false;
            this.newItemCharacteristicGroupName = '';
            this.hasNoChangesToSave = true;
            this.cloneCharacteristic = false;
            this.validations = {name: {warning: false, error: ''}, groupingName: {warning: false, error: ''}};
        } else {
            this.clearValidations();
            if (this.selectedSkuGroupId) {
                this.activeSkuGroup = this.skuGroups.find(sg => sg.skuGroupId === this.selectedSkuGroupId);
            } else {
                this.selectedSkuGroupId = 0;
                this.onSkuGroupToggle(0);
            }
        }
    }

    isNameDuplicate(name: string): boolean {
        if (this.activeSkuGroup.skuGroupId) {
            return this.skuGroups.find(s => this.activeSkuGroup.skuGroupId !== s.skuGroupId && (name &&
                name.trim().toLowerCase() === s.name.trim().toLowerCase() ||
                name.trim().toLowerCase() === s.displayName.trim().toLowerCase())
            ) ? true : false;
        } else {
            return this.skuGroups.find(s => name &&
                name.trim().toLowerCase() === s.name.trim().toLowerCase() ||
                name.trim().toLowerCase() === s.displayName.trim().toLowerCase()
            ) ? true : false;
        }
    }

    validateName(name: string): void {
        switch(true){
            case this.isNameDuplicate(name):
                this.validations.name.error = 'An item characteristic with that name already exists. Please try another name.';
                break;
            case name.length < 1 || (name.length > 0 && name.trim().length === 0):
                this.validations.name.error = 'This is required. Please enter a name.'; 
                break;
            case name && name.length > 25:
                this.validations.name.error = 'You have reached the allowed character limit.'; 
                break;
            case name && name.includes('.'):
                this.validations.name.error = 'Item Characteristic name cannot include \".\"';
                break;
            default:
                this.validations.name.error = '';
                this.validations.name.warning = name.length >= 13 && name.length <= 25;
        }
        this.hasError = this.validations.name.error || (this.activeSkuGroup.skuGroupId && this.activeSkuGroup.name === name.trim()) ? true :
            name.length === 0 ? true : false;
    }

    editGroupSet(skuGroup): void {
        this.hasError = true;
        this.cloneCharacteristic = false;
        this.activeSkuGroup = skuGroup;
        this.itemCharacteristicName = this.activeSkuGroup.displayName;
        this.validations = {name: {warning: false, error: ''}, groupingName: {warning: false, error: ''}};
        this.addCharacteristic = true;
    }

    cloneGroupSet(skuGroup): void {
        this.hasError = true;
        this.addCharacteristic = false;
        this.addItemCharacteristicGrouping = false;
        this.activeSkuGroup = skuGroup;
        this.itemCharacteristicName = `${skuGroup.displayName}(1)`;
        this.validations = {name: {warning: false, error: ''}, groupingName: {warning: false, error: ''}};
        this.validateName(this.itemCharacteristicName);
        this.cloneCharacteristic = true;
    }

    onSaveOfDuplicateCharacteristic() {
        this.cloneCharacteristic = false;
        this.skuGroupService.clone(this.activeSkuGroup, this.itemCharacteristicName).subscribe((skuGroup) => {
            this.onAddOrEditSkuGroup(skuGroup);
            this.snackBarService.openSnackBar(`Characteristic cloned successfully`, 'success', 4000);
            this.uiBlockerService.unblock();
        }, err => {
            this.uiBlockerService.unblock();
            this.snackBarService.openSnackBar(err.error.message, 'error', 4000);
        });
    }

    onGroupItemClose(): void {
        if (!this.hasNoChangesToSave) {
            this.showConfirmationDialog();
        } else {
            this.selectedSkuGroupId = 0;
            this.showNoBrandInfo = true;
            this.clearValidations();
            this.onSkuGroupToggle(0);
            this.clicked.emit(false);
        }
    }

    clearValidations(): void {
        this.addCharacteristic = false;
        this.addItemCharacteristicGrouping = false;
        this.activeSkuGroup = new SkuGroup();
        this.newItemCharacteristicGroupName = '';
        this.selectedItemGrouping = '';
        this.hasNoChangesToSave = true;
        this.validations = {name: {warning: false, error: ''}, groupingName: {warning: false, error: ''}};
    }

    onSkuGroupToggle(skuGroupId: number): void {
        this.selectedSkuGroupId = skuGroupId;
        this.activeSkuGroup = this.skuGroups ? this.skuGroups.find(s => s.skuGroupId === this.selectedSkuGroupId) : new SkuGroup();
        if (this.selectedSkuGroupId === 0 || this.activeSkuGroup.displayName !== this.itemCharacteristicName) {
            this.addCharacteristic = false;
        }
        if (this.activeSkuGroup) {
            const itemGroupingWithoutAllOther = this.activeSkuGroup.itemGroupings.find(it => it.displayName !== 'All Other');
            this.activeItemGrouping = itemGroupingWithoutAllOther ? itemGroupingWithoutAllOther : new ItemGrouping();
        }

        this.addItemCharacteristicGrouping = false;
        this.showNoBrandInfo = this.activeSkuGroup && this.activeSkuGroup.itemGroupings ? !(this.activeSkuGroup.itemGroupings.length - 1) : true;
        this.selectedItemGrouping = '';
        this.hasNoChangesToSave = true;
        this.scenarioService.onChangeSkuGroupSubject.next({
            skuGroup: this.activeSkuGroup,
            reload: true,
            activeItemGrouping: this.activeItemGrouping
        });
        if (this.activeSkuGroup && `${this.activeSkuGroup.displayName}(1)` !== this.itemCharacteristicName) {
            this.cloneCharacteristic = false;
        }
    }

    validateItemCharacteristicGroupingName(groupingName): void {
        if (this.activeSkuGroup.itemGroupings.find(itemGrouping => itemGrouping.displayName.toLowerCase() ===
            groupingName.toLowerCase())) {
            if (this.activeItemGrouping && this.activeItemGrouping.itemGroupingId && this.activeItemGrouping.displayName === groupingName) {
                this.validations.groupingName.error = '';
            } else {
                this.validations.groupingName.error = 'An item characteristic grouping with that name already exists. Please try another name.';
            }
        } else if (groupingName.length < 1 || (groupingName.length > 0 && groupingName.trim().length === 0)) {
            this.validations.groupingName.error = 'This is required. Please enter a name.';
        } else if (groupingName && groupingName.length > 50) {
            this.validations.groupingName.error = 'You have reached the allowed character limit.';
        } else {
            this.validations.groupingName.error = '';
            this.validations.groupingName.warning = groupingName.length >= 25 && groupingName.length <= 50;
        }
        this.hasError = this.validations.groupingName.error || (this.activeItemGrouping && this.activeItemGrouping.itemGroupingId && this.activeItemGrouping.displayName === groupingName.trim()) ? true :
            groupingName.length === 0 ? true : false;
    }

    onSaveCharacteristicGrouping(newItemCharacteristicGroupName: string): void {
        this.uiBlockerService.block();
        this.activeItemGrouping = this.activeItemGrouping ? this.activeItemGrouping : new ItemGrouping();
        if (this.activeItemGrouping && this.activeItemGrouping.itemGroupingId) {
            this.activeItemGrouping.displayName = newItemCharacteristicGroupName;
        } else {
            this.activeItemGrouping.displayName = newItemCharacteristicGroupName;
            this.activeItemGrouping.name = newItemCharacteristicGroupName;
        }
        this.skuGroupService.createNewItemGrouping(this.activeSkuGroup, this.activeItemGrouping).subscribe(skuGroup => {
            this.skuGroupReload.emit();
            this.activeSkuGroup = skuGroup;
            this.addItemCharacteristicGrouping = false;
            this.activeItemGrouping = skuGroup.itemGroupings.find(i => i.displayName === newItemCharacteristicGroupName);
            this.scenarioService.onChangeSkuGroupSubject.next({
                skuGroup: skuGroup,
                reload: true,
                activeItemGrouping: skuGroup.itemGroupings.find(i => i.displayName === newItemCharacteristicGroupName)
            });
            this.snackBarService.openSuccessSnackBar('Item characteristic grouping added successfully');
            this.uiBlockerService.unblock();
        }, err => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar('An item characteristic with that name already exists. Please try another name.');
        });

    }

    applyChanges(closeFlyout: boolean): void {
        this.scenarioService.onClickSaveChangesSubject.next({
            skuGroup: this.activeSkuGroup,
            activeItemGrouping: this.activeItemGrouping,
            closeFlyout
        });
        this.hasNoChangesToSave = true;
    }

    onSaveGroupItemsSubscription(): void {
        this.subscriptions.add(this.scenarioService.onSaveGroupingItemsSubject.subscribe(closeFlyout => {
            if (closeFlyout) {
                this.hasNoChangesToSave = true;
                this.onGroupItemClose();
            }
        }));
    }

    showConfirmationDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: 'Discard Changes Made?',
            body: 'Discarding will result in the loss of unsaved changes. Press \'Discard\' to continue, otherwise press \'Cancel\'',
            confirmButtonLabel: 'DISCARD',
            cancelButtonLabel: 'CANCEL',
            showSaveButton: true,
            saveChangesButtonLabel: 'SAVE CHANGES'
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            const isExiting = value === 'DISCARD';
            if (!isExiting && value === 'SAVE CHANGES') {
                this.applyChanges(true);
            } else if (value === 'DISCARD') {
                this.selectedSkuGroupId = 0;
                this.showNoBrandInfo = true;
                this.clearValidations();
                this.clicked.emit(false);
            }
        });
    }

    deleteGroupSet(skuGroup): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: 'Delete Item Characteristic?',
            body: `Are you sure you want to delete this "${skuGroup.displayName}" and all its groupings permanently?`,
            confirmButtonLabel: 'DELETE',
            cancelButtonLabel: 'CANCEL',
            showSaveButton: false,
            saveChangesButtonLabel: ''
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            if (value === 'DELETE') {
                this.deleteSkuGroup(skuGroup);
            }
        });
    }

    deleteSkuGroup(skuGroup: SkuGroup): void {
        this.uiBlockerService.block();
        this.skuGroupService.remove(skuGroup).subscribe(() => {
            this.skuGroupReload.emit();
            this.onSkuGroupToggle(0);
            this.snackBarService.openSuccessSnackBar('Item Characteristic deleted successfully');
            this.uiBlockerService.unblock();
        }, err => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar(err.error.message);
        });
    }

    drop(event: CdkDragDrop<string[]>): void {
        const allOtherItemGrouping = this.activeSkuGroup.itemGroupings.find(i => i.name === 'All Other');
        this.activeSkuGroup.itemGroupings = this.activeSkuGroup.itemGroupings.filter(i => {
            return i.displayName !== 'All Other';
        });
        moveItemInArray(this.activeSkuGroup.itemGroupings, event.previousIndex, event.currentIndex);
        let index = 1;
        this.activeSkuGroup.itemGroupings.map(itemGrouping => {
            itemGrouping.displayOrder = index;
            index++;
            return itemGrouping;
        });
        allOtherItemGrouping.displayOrder = this.activeSkuGroup.itemGroupings.length + 1;
        this.activeSkuGroup.itemGroupings.push(allOtherItemGrouping);
        this.saveReorder();
    }

    saveReorder() {
        this.uiBlockerService.block();
        this.skuGroupService.add(this.activeSkuGroup).subscribe((skuGroup) => {
            this.onAddOrEditSkuGroup(skuGroup);
            this.snackBarService.openSuccessSnackBar(`Item Groupings order saved successfully`);
            this.uiBlockerService.unblock();
        }, err => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar(err.error.message);
        });
    }

    editItemGrouping(itemGrouping: any): void {
        this.newItemCharacteristicGroupName = itemGrouping.displayName;
        this.addItemCharacteristicGrouping = true;
        this.hasError = true;
    }

    deleteItemGroupingSet(itemGrouping: any): void {
        this.activeItemGrouping = itemGrouping;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: 'Delete Item Characteristic Grouping?',
            body: `Are you sure you want to delete this "${itemGrouping.displayName}" permanently ?`,
            confirmButtonLabel: 'DELETE',
            cancelButtonLabel: 'CANCEL',
            showSaveButton: false,
            saveChangesButtonLabel: ''
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            if (value === 'DELETE') {
                this.deleteItemGrouping(itemGrouping);
            }
        });
    }

    deleteItemGrouping(itemGrouping: ItemGrouping): void {
        this.uiBlockerService.block();
        this.skuGroupService.removeItemGrouping(itemGrouping, this.activeSkuGroup).subscribe((skuGroup) => {
            this.skuGroupReload.emit();
            this.activeSkuGroup = skuGroup;
            this.skuGroups = this.skuGroupService.skuGroups;
            this.activeItemGrouping = new ItemGrouping();
            this.scenarioService.onChangeSkuGroupSubject.next({
                skuGroup: this.activeSkuGroup,
                reload: true,
                activeItemGrouping: this.activeItemGrouping
            });
            this.snackBarService.openSuccessSnackBar('Item Characteristic Grouping deleted successfully');
            this.uiBlockerService.unblock();
        }, err => {
            this.uiBlockerService.unblock();
            this.snackBarService.openErrorSnackBar(err.error.message);
        });
    }
}
