import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

/**
 * @example
 * `<button mat-stroked-button (click)="openSnackBarHandler()" aria-label="Show snack-bar">snack bar</button>
 * import {SnackBarService} from '../../components/snack-bar/snack-bar.service';
 * constructor(private snackBarService: SnackBarService) {}
 * openSnackBarHandler() {
 *   this.snackBarService.openSnackBar('Success Snack bar', 'error', 2000)
 * }`
 */

@Injectable({
  providedIn: 'root',
})

export class SnackBarService {

  constructor(private snackBar: MatSnackBar) {
  }

  openSnackBar(message: string, className: string, duration = 4000, action?: string): any {
    this.snackBar.open(message, action, {
      duration,
      panelClass: [className],
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
  }

  openErrorSnackBar(message: string): void {
    this.openSnackBar(message, 'error');
  }

  openSuccessSnackBar(message: string): void {
    this.openSnackBar(message, 'success');
  }

  openWarningSnackBar(message: string): void {
    this.openSnackBar(message, 'warning');
  }
}
