import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy, NgZone
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SkuConfig} from '@app/models/sku-config.model';
import {ModelRunService} from '@app/services/model-run.service';
import {MetaData} from '@app/models/meta-data.model';
import {
    displayPriceCellValidator, featureAndDisplayPriceCellValidator,
    featurePriceCellValidator,
    percentageCellValidator,
    priceCellValidator,
    promoPriceCellValidator, sizeCellValidator,
    specialPromoPriceCellValidator
} from '@app/utils/sku-config.validator';
import {ScenarioService} from '@app/services/scenario.service';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {isEmpty} from '@app/utils/object-utils';
import {AccessPolicyService} from '@app/services/access-policy.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import {SkuGroupService} from '@app/services/sku-group.service';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {SkuConfigTableCellRenderers} from '@app/utils/sku-config-table-cell-renderers';
import {GeneralSettingService} from '@app/services/general-setting.service';
import {ProfitModalComponent} from '@app/components/profit-modal/profit-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Scenario} from '@app/models/scenario.model';
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {Segment} from '@app/models/segment.model';
import {ModelRun} from '@app/models/model-run.model';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {ModelRunSku} from '@app/models/model-run-sku.model';
import {SimulationRunInput} from '@app/models/simulation-run-input.model';
import {SimulationRunResult} from '@app/models/simulation-run-result.model';
import {UserConfigurations} from '@app/models/user-configurations.model';
import {SkuGroup} from '@app/models/sku-group.model';
import {ItemCharacteristicsSku} from '@app/interfaces/item-characteristics.interface';
import {ModelRunSkuService} from '@app/services/model-run-sku.service';
import {ItemGrouping} from '@app/models/item-grouping.model';
import {SimulationRunResultService} from '@app/services/simulation-run-result.service';

@Component({
    selector: 'app-scenario',
    templateUrl: './scenario.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ScenarioComponent implements OnInit, AfterViewChecked, OnDestroy {
    dropdownChange: Subject<any> = new Subject();
    skuConfigs: Array<SkuConfig>;
    modelRunSkus: Array<ModelRunSku>;
    simulationRunInputs: Array<SimulationRunInput>;
    simulationRunResults: Array<SimulationRunResult>;
    skuOrderConfig: UserConfigurations;
    metaData: MetaData;
    modelRun: ModelRun;
    hotData: Array<any>;
    cellRenderers: SkuConfigTableCellRenderers;
    editableInputColumns: Array<string>;
    nonFormErrorMessages: string[] = [];
    tableSettings: any = {
        columns: [],
        columnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [2],
        groupColumnStartIndex: 3,
        inputsColumnStartIndex: 0,
        outPutColumnStartIndex: 0
    };
    bulkEditColumns: any;
    scenarioId: string;
    skuGroups: Array<SkuGroup>;
    visibleColumns: any;
    conditionalColumns: any;
    groupingsTableColumns: any;
    outPutFieldsTotal = {totalRevenue: 0, totalUnits: 0, totalEqVolume: 0, totalPsShare: 0, totalVolume: 0};
    simulateAndSaveScenarioSubscription: Subscription;
    showSkuConfigTableWithFlyout: boolean;
    subscriptions: Subscription;
    showSkuConfigTableWithBulkEditFlyout: boolean;
    profitInputDialogRef: MatDialogRef<ProfitModalComponent>;
    hasUnSelectedNonSampleFilter: boolean;
    segments: Segment[];
    clearOutputs = false;
    reloadTable: Subject<any> = new Subject<any>();
    editMode = false;
    activeItemGrouping: ItemGrouping;
    activeSkuGroup: SkuGroup;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private modelRunService: ModelRunService,
                private scenarioService: ScenarioService,
                private cdRef: ChangeDetectorRef,
                private dialog: MatDialog,
                private uiBlockerService: UiBlockerService,
                private accessPolicyService: AccessPolicyService,
                private appConstantsService: AppConstantsService,
                private skuGroupService: SkuGroupService,
                private snackBarService: SnackBarService,
                private generalSettingService: GeneralSettingService,
                private authProxyService: AuthProxyService,
                private ngZone: NgZone,
                private userConfigurationsService: UserConfigurationsService,
                private modelRunSkuService: ModelRunSkuService,
                private simulationRunResultService: SimulationRunResultService
    ) {
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        const metaData = this.route.parent.parent.snapshot.data.metaData;
        const generalSetting = this.route.parent.parent.snapshot.data.generalSetting;
        this.cellRenderers = this.generalSettingService.createSkuConfigTableCellRenderersInstance(generalSetting);
        this.activeSkuGroup = null;
        this.activeItemGrouping = null;

        this.route.params.subscribe({
            next: (params: Params) => {
                this.scenarioId = params.scenarioId;
                this.tableSettings.columnHeaders = null;
                this.tableSettings.columns = null;
                this.hotData = null;
                this.metaData = metaData;
                this.modelRunSkus = this.route.parent.parent.snapshot.data.modelRunSkus;
                this.simulationRunInputs = this.route.snapshot.data.simulationRunInputs;
                this.simulationRunResults = this.route.snapshot.data.simulationRunResults;
                this.skuGroups = this.route.parent.parent.snapshot.data.skuGroups.filter(skuGroup => skuGroup.showInSimulator);
                this.skuConfigs = this.scenarioService.generateSkuConfigs(this.modelRunSkus, this.simulationRunInputs, this.simulationRunResults);
                this.segments = this.route.parent.snapshot.data.segments;
                this.modelRun = this.modelRunService.activeModelRun;
                this.scenarioService.cachedScenarioSkuConfigs[this.scenarioId] = this.skuConfigs;
                this.loadUserConfigurations();
                this.initializeColumns();
                if (this.skuConfigs) {
                    this.scenarioService.activeScenario.next(this.scenarioId);
                    const selectedSegments = this.modelRunService.getUserRunLevelSelectedSegments(this.modelRun);
                    this.hasUnSelectedNonSampleFilter = this.modelRunService.unSelectedNonSameFilterExists(this.modelRunService.getPopulationFilters(selectedSegments, this.metaData, this.segments));
                    this.editMode = this.lockedToEdit(this.scenarioService.getScenario(this.scenarioId));
                    this.renderTable();
                }
            }

        });
        this.simulateAndSaveScenarioSubscription = this.scenarioService.SIMULATE_AND_SAVE_SUBJECT.subscribe((data) => {
            this.simulateAndSave(data);
        });
        this.subscriptions.add(this.scenarioService.showGroupingsFlyout.subscribe((flyoutStatus) => {
            this.showSkuConfigTableWithFlyout = flyoutStatus;
            if (!flyoutStatus) {
                this.renderTable(false, true, false);
            }
        }));
        this.subscriptions.add(this.scenarioService.showBulkEditFlyout.subscribe((flyoutStatus) => {
            this.showSkuConfigTableWithBulkEditFlyout = flyoutStatus;
            if (!flyoutStatus) {
                this.renderTable(false, true, false);
            }
        }));
        this.subscriptions.add(this.scenarioService.scenarioLockToggleSubject.subscribe((scenario: Scenario) => {
            this.setupInputColumnEditMode(scenario);
            this.editMode = this.lockedToEdit(scenario);
            this.reloadSkuConfigTable();
        }));
        this.subscriptions.add(this.scenarioService.MODEL_RUN_POPULATION_CHANGED_SUBJECT.subscribe((filters) => {
            this.clearOutputs = (this.modelRun.selectedSegments !== this.modelRunService.getSelectedSegments(this.modelRun, this.segments, filters).join(','));
            this.hasUnSelectedNonSampleFilter = this.modelRunService.unSelectedNonSameFilterExists(filters);
            this.reloadSkuConfigTable();
        }));
        this.subscriptions.add(this.scenarioService.RELOAD_ACTIVE_SCENARIO_SKU_CONFIGS$.subscribe(() => {
            this.scenarioService.getSkuConfigs(this.metaData.projectId, this.metaData.modelRunId, this.scenarioId).subscribe((skuConfigs) => {
                this.scenarioService.cachedScenarioSkuConfigs[(this.scenarioId)] = skuConfigs;
                this.renderTable();
            });
        }));
        this.scenarioService.onChangeSkuGroupSubject.subscribe((data) => {
            this.onSkuGroupOrItemGroupingSelectionChange(data);
        });
        this.scenarioService.onSkuItemGroupingChangedSubject.subscribe((data) => {
            this.onSkuGroupOrItemGroupingSelectionChange(data);
        });
        this.scenarioService.onCompareScenarioReturnSubject.next();
    }

    get projectId(): number {
        return this.modelRun.projectId;
    }

    get modelRunId(): string {
        return this.modelRun.id;
    }

    /**
     * Subscription handler when sku group or item grouping are updated or changed.
     * Here we just propagate the changes to child component, which in turns re-render
     * the content.
     * */
    onSkuGroupOrItemGroupingSelectionChange(data): void {
        const modelRun = this.modelRun;
        if (data.reload) {
            forkJoin([this.skuGroupService.fetchAll(modelRun.projectId, modelRun.id), this.modelRunSkuService.fetchAll(modelRun.projectId, modelRun.id)]).subscribe(([skuGroups, modelRunSkus]) => {
                this.skuGroups = skuGroups.filter(it => it.showInSimulator);
                this.modelRunSkus = modelRunSkus;
                this.activeSkuGroup = data.skuGroup;
                this.activeItemGrouping = data.activeItemGrouping;
            });
        } else {
            this.activeSkuGroup = data.skuGroup;
            this.activeItemGrouping = data.activeItemGrouping;
        }

    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.simulateAndSaveScenarioSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    loadUserConfigurations(): void {
        this.userConfigurationsService.getUserConfigurations(this.metaData.projectId, this.metaData.modelRunId).subscribe(response => {
            this.skuOrderConfig = this.userConfigurationsService.getSkuOrderConfig();
        });
    }

    initializeColumns(): void {
        const continuousPromoPriceRenderer = this.cellRenderers.continuousPromoPriceRenderer.bind(this.cellRenderers);
        const promoPriceDropdownRenderer = this.cellRenderers.promoPriceDropdownRenderer.bind(this.cellRenderers);

        const isContinuousPromo = this.metaData.promo === 'continuous';
        const distribution = {
            name: 'distribution',
            type: 'text',
            data: 'distribution',
            readOnly: true,
            className: 'htRight',
            width: 80,
            displayName: 'Distribution',
            dropdownMenu: false,
            renderer: this.cellRenderers.distributionRenderer.bind(this.cellRenderers),
            validator: percentageCellValidator
        };
        const id = {
            name: 'id',
            type: 'numeric',
            data: 'skuId',
            readOnly: true,
            className: 'htRight',
            width: 56,
            index: 1,
            displayName: 'ID'
        };
        const isSelected = {
            name: 'isSelected',
            type: 'checkbox',
            data: 'isSelected',
            readOnly: false,
            className: 'htCenter',
            width: 50,
            index: 0,
            displayName: 'selectAll',
            checkedTemplate: 1,
            uncheckedTemplate: 0,
            renderer: this.cellRenderers.checkBoxRenderer.bind(this.cellRenderers)
        };
        const reportingName = {
            name: 'reportingName',
            type: 'text',
            displayName: 'Reporting Name',
            data: 'reportingName',
            readOnly: true,
            className: 'ellipsis htLeft',
            width: 416,
            filters: true,
            index: 2,
            renderer: this.cellRenderers.reportNameRenderer.bind(this.cellRenderers)
        };
        const priceInput = {
            name: 'priceInput',
            type: 'numeric',
            data: 'priceInput',
            displayName: 'Regular Price',
            readOnly: !this.metaData.hasPrice,
            className: 'htRight',
            width: 124,
            dropdownMenu: false,
            renderer: this.cellRenderers.priceInputRenderer.bind(this.cellRenderers),
            validator: priceCellValidator,
        };
        const promoPrice = {
            name: 'promoPrice',
            type: isContinuousPromo ? 'numeric' : 'dropdown',
            data: isContinuousPromo ? 'promoPrice' : 'promoPriceText',
            readOnly: false,
            className: 'htRight',
            dropdownMenu: false,
            displayName: 'Promo Price',
            source: '',
            width: 124,
            isPromoHeader: true,
            renderer: isContinuousPromo ? continuousPromoPriceRenderer : promoPriceDropdownRenderer,
            validator: promoPriceCellValidator
        };
        const promoDistribution = {
            name: 'promoDistribution',
            type: 'numeric',
            data: 'promoDistribution',
            displayName: 'Time on Promo',
            readOnly: false,
            className: 'htRight',
            width: 110,
            dropdownMenu: false,
            renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
            validator: percentageCellValidator,
            isPromoHeader: true
        };
        const specialPromoPrice = {
            name: 'specialPromoPrice',
            displayName: 'Special Promo Price',
            type: 'numeric',
            data: 'specialPromoPrice',
            readOnly: true,
            className: 'htRight',
            width: 130,
            isPromoHeader: true,
            renderer: this.cellRenderers.specialPromoPriceCellRenderer.bind(this.cellRenderers),
            validator: specialPromoPriceCellValidator
        };

        this.visibleColumns = {
            id,
            isSelected,
            reportingName
        };
        this.groupingsTableColumns = {
            id,
            isSelected: {
                name: 'isSelected',
                type: 'checkbox',
                data: 'isSelected',
                readOnly: false,
                className: 'htCenter',
                width: 50,
                index: 0,
                displayName: 'selectAll',
                checkedTemplate: 1,
                uncheckedTemplate: 0,
                renderer: this.cellRenderers.inputsCheckboxRenderer.bind(this.cellRenderers)
            },
            reportingName
        };
        this.conditionalColumns = {
            priceInput,
            priceInputRange: {
                name: 'priceInputRange',
                type: 'text',
                data: 'priceInputRange',
                displayName: 'Regular Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.priceRangeRenderer.bind(this.cellRenderers)
            },
            distribution: Object.assign({}, distribution, {readOnly: false, width: 104}),
            promoPrice,
            specialPromoPrice,
            promoPriceRange: {
                name: 'promoPriceRange',
                type: 'text',
                data: 'promoPriceRange',
                displayName: 'Promo Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            promoDistribution,
            baseSize: {
                name: 'baseSize',
                type: 'numeric',
                data: 'baseSize',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Size',
                source: '',
                width: 124,
                renderer: this.cellRenderers.baseSizeRenderer.bind(this.cellRenderers),
                validator: sizeCellValidator
            },
            sizeRange: {
                name: 'sizeRange',
                type: 'text',
                data: 'sizeRange',
                displayName: 'Size Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.sizeRangeRenderer.bind(this.cellRenderers),
            },
            featurePrice: {
                name: 'featurePrice',
                type: 'numeric',
                data: 'featurePrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Feature Only Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.featurePriceRenderer.bind(this.cellRenderers),
                validator: featurePriceCellValidator
            },
            featureDistribution: {
                name: 'featureDistribution',
                type: 'numeric',
                data: 'featureDistribution',
                displayName: 'Time on Feature Only',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            featurePriceRange: {
                name: 'featurePriceRange',
                type: 'text',
                data: 'featurePriceRange',
                displayName: 'Feature Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.featurePriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            displayPrice: {
                name: 'displayPrice',
                type: 'numeric',
                data: 'displayPrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Display Only Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.displayPriceRenderer.bind(this.cellRenderers),
                validator: displayPriceCellValidator
            },
            displayDistribution: {
                name: 'displayDistribution',
                type: 'numeric',
                data: 'displayDistribution',
                displayName: 'Time on Display Only',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            displayPriceRange: {
                name: 'displayPriceRange',
                type: 'text',
                data: 'displayPriceRange',
                displayName: 'Display Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.displayPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            featureAndDisplayPrice: {
                name: 'featureAndDisplayPrice',
                type: 'numeric',
                data: 'featureAndDisplayPrice',
                readOnly: false,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Feature & Display Price',
                source: '',
                width: 124,
                isPromoHeader: true,
                renderer: this.cellRenderers.featureAndDisplayPriceRenderer.bind(this.cellRenderers),
                validator: featureAndDisplayPriceCellValidator
            },
            featureAndDisplayDistribution: {
                name: 'featureAndDisplayDistribution',
                type: 'numeric',
                data: 'featureAndDisplayDistribution',
                displayName: 'Time on Feature and Display',
                readOnly: false,
                className: 'htRight',
                width: 110,
                dropdownMenu: false,
                renderer: this.cellRenderers.promoDistributionRenderer.bind(this.cellRenderers),
                validator: percentageCellValidator,
                isPromoHeader: true
            },
            featureAndDisplayPriceRange: {
                name: 'featureAndDisplayPriceRange',
                type: 'text',
                data: 'featureAndDisplayPriceRange',
                displayName: 'Feature & Display Price Range',
                readOnly: true,
                className: 'htRight',
                width: 124,
                dropdownMenu: false,
                renderer: this.cellRenderers.featureAndDisplayPriceRangeRenderer.bind(this.cellRenderers),
                isPromoHeader: true
            },
            units: {
                name: 'units',
                displayName: 'Units',
                type: 'text',
                data: 'unit',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitRenderer.bind(this.cellRenderers)
            },
            unitsShare: {
                name: 'unitsShare',
                displayName: 'unitsShare',
                type: 'text',
                data: 'unitsShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitShareRenderer.bind(this.cellRenderers)
            },
            unitsPromoShare: {
                name: 'unitsPromoShare',
                displayName: 'Units on Promo Share',
                type: 'text',
                data: 'unitsPromoShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.unitsPromoShareRenderer.bind(this.cellRenderers)
            },
            equivalizedVolume: {
                name: 'equivalizedVolume',
                displayName: 'equivalizedVolume',
                type: 'text',
                data: 'equivalizedVolume',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.equivalizedVolumeRenderer.bind(this.cellRenderers)
            },
            equivalizedVolumeShare: {
                name: 'equivalizedVolumeShare',
                displayName: 'equivalizedVolumeShare',
                type: 'text',
                data: 'equivalizedVolumeShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.equivalizedVolumeShareRenderer.bind(this.cellRenderers)
            },
            revenue: {
                name: 'revenue',
                displayName: 'revenue',
                type: 'text',
                data: 'revenue',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.revenueRenderer.bind(this.cellRenderers)
            },
            revenueShare: {
                name: 'revenueShare',
                displayName: 'revenueShare',
                type: 'text',
                data: 'revenueShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.revenueShareRenderer.bind(this.cellRenderers)
            },
            psShare: {
                name: 'psShare',
                type: 'text',
                data: 'psShare',
                readOnly: true,
                className: 'htRight',
                displayName: 'psShare',
                width: 150,
                renderer: this.cellRenderers.preferenceShareRenderer.bind(this.cellRenderers)
            },
            profit: {
                name: 'profit',
                type: 'text',
                data: 'profit',
                readOnly: true,
                className: 'htRight',
                dropdownMenu: false,
                displayName: 'Profit',
                source: '',
                width: 150,
                renderer: this.cellRenderers.profitRenderer.bind(this.cellRenderers)
            },
            volume: {
                name: 'volume',
                displayName: 'volume',
                type: 'text',
                data: 'volume',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.volumeRenderer.bind(this.cellRenderers)
            },
            volumeShare: {
                name: 'volumeShare',
                displayName: 'volumeShare',
                type: 'text',
                data: 'volumeShare',
                readOnly: true,
                className: 'htRight',
                width: 130,
                renderer: this.cellRenderers.volumeShareRenderer.bind(this.cellRenderers)
            },

        };
        this.bulkEditColumns = {
            id: Object.assign({}, id, {width: 56}),
            isSelected: Object.assign({}, isSelected, {width: 50}),
            reportingName: Object.assign({}, reportingName, {width: 416}),
            priceInput: Object.assign({}, priceInput, {readOnly: true, width: 100}),
            promoPrice: Object.assign({}, promoPrice, {readOnly: true, width: 80}),
            specialPromoPrice: Object.assign({}, specialPromoPrice, {readOnly: true, width: 100}),
            promoDistribution: Object.assign({}, promoDistribution, {readOnly: true}),
            distribution
        };
    }

    tableErrorEventHandler(errorMessage): void {
        this.nonFormErrorMessages = errorMessage.nonFormErrorMessages;
        this.scenarioService.skuConfigInputValidationError.next(this.nonFormErrorMessages.length > 0);
    }

    tableDataChangeEventHandler(updatedTableData): void {
        this.skuConfigs = updatedTableData;
    }

    constructColumnHeaders(): void {
        let columnCount = 0;
        this.tableSettings.groupHeaders.push({label: '', colspan: 3});
        Object.keys(this.visibleColumns).forEach((key) => {
            if (this.metaData.inputConfigurations[key]) {
                this.tableSettings.columnHeaders.push(this.metaData.inputConfigurations[key]['displayName']);
            } else {
                this.tableSettings.columnHeaders.push(this.visibleColumns[key]['displayName']);
            }
            columnCount = columnCount + 1;
            this.tableSettings.columns.push(this.visibleColumns[key]);
        });
        this.addGroupColumns();
        let inputsColumnCount = 0;
        let outputColumnCount = 0;
        inputsColumnCount = this.constructPriceInputColumnHeaders(inputsColumnCount);
        inputsColumnCount = this.constructPromoInputColumnHeaders(inputsColumnCount);
        outputColumnCount = this.constructOutputColumnHeaders(outputColumnCount);
        this.tableSettings.inputsColumnStartIndex = this.tableSettings.groupColumnStartIndex
            + (this.skuGroups ? this.skuGroups.length : 1);
        this.tableSettings.outPutColumnStartIndex = this.tableSettings.inputsColumnStartIndex
            + inputsColumnCount;
        if (inputsColumnCount > 0) {
            const inputColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser inputs"></span>`;
            this.tableSettings.groupHeaders.push({
                label: `INPUTS ${inputColumnChooserButton}`,
                type: 'INPUTS',
                colspan: inputsColumnCount
            });
        }
        const outputColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser outputs"></span>`;
        this.tableSettings.groupHeaders.push({
            label: `RESULTS ${outputColumnChooserButton}`,
            type: 'OUTPUTS',
            colspan: outputColumnCount
        });
        this.editableInputColumns = this.tableSettings.columns.filter(column => !column.readOnly).map(column => column.name);
    }

    constructPriceInputColumnHeaders(inputsColumnCount): number {
        for (const key in this.conditionalColumns) {
            if (!this.conditionalColumns[key].isPromoHeader && (this.metaData.inputConfigurations[key]
                && this.metaData.inputConfigurations[key]['showInSimulator'])) {
                const PriceInputColHeaders = this.metaData.inputConfigurations[key]['displayName'];
                this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${PriceInputColHeaders}</span>`);
                this.conditionalColumns[key].displayName = this.metaData.inputConfigurations[key]['displayName'];
                this.tableSettings.columns.push(this.conditionalColumns[key]);
                inputsColumnCount = inputsColumnCount + 1;
            }
        }
        return inputsColumnCount;
    }

    constructPromoInputColumnHeaders(inputsColumnCount): number {
        if (this.metaData && this.metaData.promo !== 'none') {
            let requiredHeaders = ['promoPrice', 'specialPromoPrice', 'promoPriceRange', 'promoDistribution'];
            if(this.metaData.hasCoeffsFnd) {
                const tradePromoColumns = ['featurePrice','featurePriceRange', 'featureDistribution', 'displayPrice', 'displayPriceRange', 'displayDistribution',
                                           'featureAndDisplayPrice','featureAndDisplayPriceRange', 'featureAndDisplayDistribution'];
                requiredHeaders = requiredHeaders.concat(tradePromoColumns);
            }
            requiredHeaders.forEach(header => {
                if (this.metaData.inputConfigurations[header] && this.metaData.inputConfigurations[header]['showInSimulator']) {
                    const promoInputColHeaders = this.metaData.inputConfigurations[header]['displayName'];
                    this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${promoInputColHeaders}</span>`);
                    this.conditionalColumns[header].displayName = this.metaData.inputConfigurations[header]['displayName'];
                    this.tableSettings.columns.push(this.conditionalColumns[header]);
                    inputsColumnCount = inputsColumnCount + 1;
                }
            });
        }
        return inputsColumnCount;
    }

    constructOutputColumnHeaders(outputColumnCount): number {
        for (const key in this.conditionalColumns) {
            if (this.metaData.outputConfigurations && this.metaData.outputConfigurations[key] && this.metaData.outputConfigurations[key]['showInSimulator']) {
                const outPutColHeaders = this.metaData.outputConfigurations[key]['displayName'];
                this.tableSettings.columnHeaders.push(`<span class="right-align-headers">${outPutColHeaders}</span>`);
                this.conditionalColumns[key].displayName = this.metaData.outputConfigurations[key]['displayName'];
                this.tableSettings.columns.push(this.conditionalColumns[key]);
                outputColumnCount = outputColumnCount + 1;
            }
        }
        return outputColumnCount;
    }

    constructHotTableData(): void {
        this.hotData = [];
        const totalCalculation = {totalRevenue: 0, totalUnits: 0, totalEqVolume: 0, totalPsShare: 0, totalVolume: 0};
        this.skuConfigs.forEach((obj) => {
            totalCalculation.totalRevenue = !isEmpty(obj['revenue']) ? totalCalculation.totalRevenue + parseFloat(obj['revenue']) :
                totalCalculation.totalRevenue;
            totalCalculation.totalUnits = !isEmpty(obj['unit']) ? totalCalculation.totalUnits + parseFloat(obj['unit']) :
                totalCalculation.totalUnits;
            totalCalculation.totalEqVolume = !isEmpty(obj['equivalizedVolume']) ? totalCalculation.totalEqVolume + parseFloat(obj['equivalizedVolume']) :
                totalCalculation.totalEqVolume;
            totalCalculation.totalPsShare = !isEmpty(obj['psShare']) ? totalCalculation.totalPsShare + parseFloat(obj['psShare']) :
                totalCalculation.totalPsShare;
            totalCalculation.totalVolume = !isEmpty(obj['volume']) ? totalCalculation.totalVolume + parseFloat(obj['volume']) :
                totalCalculation.totalVolume;

        });
        this.outPutFieldsTotal = totalCalculation;
        this.skuConfigs.forEach((obj) => {
            obj.promotions = obj.promotions && obj.promotions.length ? obj.promotions.filter((s) => {
                return s.map !== '-1.0';
            }) : [];
            const selectedDiscretePromo = obj.promotions && obj.promotions.length ? obj.promotions[(obj.promoDropdownIdx - 1)]
                : null;
            obj.promoPriceText = selectedDiscretePromo ? selectedDiscretePromo.text :
                this.metaData.promo === 'both' && obj.continuousMap !== -1 ? 'Special Price' : null;
            obj.specialPromoPrice = obj.specialPromoPrice ? obj.specialPromoPrice :
                obj.reportingBasePromo ? parseFloat(obj.reportingBasePromo) : obj.promoPriceMin;
            const hotObject = obj;
            hotObject['psShare'] = obj.psShare ? `${parseFloat(obj.psShare)}` : '';
            hotObject['unitsShare'] = obj.unitShare ? `${parseFloat(obj.unitShare)}` : '';
            hotObject['unitsPromoShare'] = obj.unitPromoShare ? `${parseFloat(obj.unitPromoShare)}` : '';
            hotObject['volumeShare'] = obj.volumeShare ? `${parseFloat(obj.volumeShare)}` : '';
            hotObject['equivalizedVolumeShare'] = obj.equivalizedVolumeShare ?
                `${parseFloat(obj.equivalizedVolumeShare)}` : '';
            hotObject['revenueShare'] = obj.revenueShare ? `${parseFloat(obj.revenueShare)}` : '';
            this.addGroupData(hotObject, obj);
            this.hotData.push(hotObject);
        });
    }

    addGroupColumns(): void {
        let colIndex = 2;
        if (this.skuGroups && this.skuGroups.length) {
            this.skuGroups.forEach(group => {
                colIndex = colIndex + 1;
                const groupName = group.displayName;
                const colObject = {
                    name: group.name,
                    type: 'text',
                    data: groupName,
                    readOnly: true,
                    className: 'ellipsis htLeft',
                    width: 220,
                    renderer: this.cellRenderers.toolTipRenderer.bind(this.cellRenderers)
                };
                this.tableSettings.columnHeaders.push(groupName);
                this.tableSettings.columns.push(colObject);
                this.tableSettings.allowFiltersForHeaders.push(colIndex);
            });
            if (this.skuGroups.length) {
                const groupColumnChooserButton = `<span class="menu-btn padding-left-1 col-chooser-btn sif sif-column-chooser groups"></span>`;
                this.tableSettings.groupHeaders.push({
                    label: `ITEM CHARACTERISTICS ${groupColumnChooserButton}`,
                    type: 'GROUPS',
                    colspan: this.skuGroups.length
                });
            } else {
                this.tableSettings.allowFiltersForHeaders.push(2);
            }
        }
    }

    addGroupData(hotObject, skuConfig): void {
        const groups = skuConfig.groups;
        this.skuGroups.forEach(group => {
            const matchedSkuGroup = groups.find((skuGroup) => {
                return skuGroup.skuGroupId === group.skuGroupId;
            });
            const itemGroupings = group.itemGroupings;
            const itemGrouping = itemGroupings.find(i => {
                return matchedSkuGroup && i.itemGroupingId === matchedSkuGroup.itemGroupingId;
            });
            /**
             * NOTE: this should not happen in real case scenario, we had to add this for our test cases
             * where the suGroup data do not match with the groups in sku config
             */
            hotObject[group.displayName] = itemGrouping ? itemGrouping.displayName : 'All Other';
        });
    }

    sortSkuConfigBySkuId(skuConfigs): Array<SkuConfig> {
        skuConfigs.sort((sku1, sku2) => {
            return sku1.skuId - sku2.skuId;
        });
        return skuConfigs;
    }

    simulateAndSave(data): any {
        this.uiBlockerService.block();
        const simulationRunInputs = this.scenarioService.prepareSimulationRunInputs(this.skuConfigs, this.metaData);
        this.scenarioService.simulateAndSave({
            projectId: data.projectId,
            modelRunId: data.modelRunId,
            scenario: this.scenarioService.getScenario(this.scenarioId),
            selectedSegments: data.userRunLevelSelectedSegments,
            simulationRunInputs: simulationRunInputs,
            simulationRunResults: []
        }).subscribe((result) => {
                const updatedScenario = result.scenario;
                this.clearOutputs = false;
                this.simulationRunInputs = result.simulationRunInputs;
                this.simulationRunResults = result.simulationRunResults;
                this.skuConfigs = this.scenarioService.generateSkuConfigs(this.modelRunSkus, result.simulationRunInputs, result.simulationRunResults);
                this.loadUserConfigurations();
                this.scenarioService.cachedRunScenarios.append(updatedScenario);
                this.scenarioService.cachedScenarioSkuConfigs[(updatedScenario.id)] = this.skuConfigs;
                this.renderTable(true, false, false);
                this.uiBlockerService.unblock();
                const message = this.modelRun.runId ? 'Simulated scenario successfully.' : 'Scenario saved successfully.';
                this.snackBarService.openSuccessSnackBar(message);
                this.scenarioService.SIMULATION_COMPLETED$.next(updatedScenario);
            },
            () => {
                this.uiBlockerService.unblock();
                this.snackBarService.openErrorSnackBar('Failed simulating scenario.');
            }
        );
    }

    sendLoadConfigTableMessage() {
        setTimeout(() => {
            this.dropdownChange.next({
                scenarioId: this.scenarioId,
                tableSettings: this.tableSettings,
                hotData: this.hotData,
                isFromSimulateSave: true,
                outPutFieldsTotal: this.outPutFieldsTotal,
                skuGroups: this.skuGroups,
                isFromfluoutClose: false
            });
        }, 0);
    }

    reloadData(selectedScenario: Scenario, isFromSimulateAndSave: boolean, isFromflyOutClose: boolean): void {
        this.initializeColumns();
        this.hotData = [];
        this.tableSettings.columnHeaders = [];
        this.tableSettings.groupHeaders = [];
        this.tableSettings.columns = [];
        this.tableSettings.allowFiltersForHeaders = [2];
        this.tableSettings.groupColumnStartIndex = 3;
        this.tableSettings.inputsColumnStartIndex = 0;
        this.tableSettings.outPutColumnStartIndex = 0;
        this.constructColumnHeaders();
        this.constructHotTableData();
        this.setupInputColumnEditMode(selectedScenario);
        this.sendLoadConfigTableMessage();
    }

    setupInputColumnEditMode(selectedScenario) {
        const acquiredLock = this.lockedToEdit(selectedScenario);
        this.tableSettings.columns.forEach((column) => {
            if (this.editableInputColumns.indexOf(column.name) !== -1) {
                column.readOnly = !(selectedScenario.editable && acquiredLock);
            }
        });
    }

    renderTable(isFromSimulateAndSave = false, isFromFlyoutMenuClose = false, isFromBulkEditFlyoutMenuClose = false): void {
        this.uiBlockerService.block();
        const selectedScenario = this.scenarioService.getScenario(this.scenarioId);
        this.skuConfigs = this.scenarioService.cachedScenarioSkuConfigs[selectedScenario.id].map(x => {
            return Object.assign({}, x);
        });
        this.skuGroups = this.skuGroups.filter(skuGroup => skuGroup.showInSimulator);
        this.reloadData(selectedScenario, isFromSimulateAndSave, isFromFlyoutMenuClose);
        this.uiBlockerService.unblock();
    }

    /**
     * Shows profit modal dialog for the user when the profit input gear is clicked in the sku config table component.
     * */
    profitInputClickEvent(): void {
        this.profitInputDialogRef = this.dialog.open(ProfitModalComponent, {
            disableClose: true,
            maxWidth: '100vw',
            width: '950px',
            data: {
                metaData: this.metaData,
                scenarioId: this.scenarioId
            }
        });

        this.profitInputDialogRef.afterClosed().subscribe(updatedSimulationRunInputs => {
            if (updatedSimulationRunInputs) {
                this.uiBlockerService.block();
                this.simulationRunResultService.fetchAll(this.projectId, this.modelRunId, this.scenarioId).subscribe((simulationRunResults) => {
                    this.skuConfigs = this.scenarioService.generateSkuConfigs(this.modelRunSkus, updatedSimulationRunInputs, simulationRunResults);
                    const selectedScenario = this.scenarioService.getScenario(this.scenarioId);
                    this.reloadData(selectedScenario, true, false);
                    this.uiBlockerService.unblock();
                });
            }
        });
    }

    /**
     * Returns true if the scenario has been locked to edit by current user.
     * */
    lockedToEdit(scenario: Scenario): boolean {
        const hasScenarioEditPermission = this.accessPolicyService.hasPermission(this.scenarioId, this.appConstantsService.EDIT_PERMISSION);
        const lockAcquired = (scenario.lockedBy && scenario.lockedBy === this.authProxyService.user.userId);
        return hasScenarioEditPermission && lockAcquired;
    }

    /**
     * Sends a signal to sku config table component to re-render.
     * NOTE: Make sure to set all the data that is being passed down to the component first before calling this method.
     * */
    reloadSkuConfigTable(): void {
        setTimeout(() => {
            this.reloadTable.next();
        }, 0);
    }

    updateSkusItemGrouping(data: { closeFlyout: boolean; skus: Array<ItemCharacteristicsSku> }): void {
        const projectId = this.metaData.projectId;
        const modelRunId = this.metaData.modelRunId;

        this.modelRunSkuService.updateSkusGroup(projectId, modelRunId, data.skus).subscribe((modelRunSkus) => {
            this.modelRunSkus = modelRunSkus;
            this.scenarioService.cachedScenarioSkuConfigs[(this.scenarioId)] = this.scenarioService.generateSkuConfigs(this.modelRunSkus, this.simulationRunInputs, this.simulationRunResults);
            this.scenarioService.onSaveGroupingItemsSubject.next(data.closeFlyout);
        });
    }
}
