import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {UserConfigurations} from '../models/user-configurations.model';
import {AppConstantsService} from './app-constants.service';

@Injectable({
  providedIn: 'root'
})
export class UserConfigurationsService {

  userConfigurations: Array<UserConfigurations> = [];

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private appConstantsService: AppConstantsService) {
  }

  /**
   * returns the session user configurations
   * @param projectId is the project Id
   * @param modelRunId is the id of model run
   */
  getUserConfigurations(projectId: number, modelRunId: string): Observable<Array<UserConfigurations>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/userConfigurations`;
    return this.http.get<Array<UserConfigurations>>(url).pipe(map((configs: UserConfigurations[]) => {
        this.userConfigurations = configs;
        return configs;
      }
    ));
  }

  getUserConfigurationsByType(configType: string): UserConfigurations {
    return this.userConfigurations.find((userConfigurations: UserConfigurations) => {
      return userConfigurations.configType === configType;
    });
  }

  createNewUserConfiguration(userConfiguration: UserConfigurations): Observable<Array<UserConfigurations>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${userConfiguration.projectId}/runs/${userConfiguration.modelRunId}/userConfigurations`;
    return this.http.post<Array<UserConfigurations>>(url, {userConfiguration}).pipe(map((configs: UserConfigurations[]) => {
        this.userConfigurations = configs;
        return configs;
      }
    ));
  }

  updateUserConfiguration(userConfiguration: UserConfigurations): Observable<Array<UserConfigurations>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${userConfiguration.projectId}/runs/${userConfiguration.modelRunId}/userConfigurations`;
    return this.http.put<Array<UserConfigurations>>(url, {userConfiguration}).pipe(map((configs: UserConfigurations[]) => {
        this.userConfigurations = configs;
        return configs;
      }
    ));
  }

  getUserSelectedSegmentsConfig(): UserConfigurations {
    const configType = this.appConstantsService.USER_RUN_SEGMENTS;
    return this.getUserConfigurationsByType(configType);
  }

  getSkuOrderConfig(): UserConfigurations {
    const configType = this.appConstantsService.ITEM_ORDER_CONFIG_TYPE;
    return this.getUserConfigurationsByType(configType);
  }
}

