<div class="adjust-profit-modal">
    <div class="profit-title">
        <h2 mat-dialog-title>Profit Inputs</h2>
        <p class="mat-dialog-description">For privacy reasons profit inputs are stored at scenario level</p>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close (click)="close()" [class]="'cancel-btn'">Cancel</button>
        <button mat-raised-button cdkFocusInitial color="primary" (click)="onSaveChanges()" [disabled]="disableSave? true : null" [title]="saveButtonTitle" [class]="'save-btn'">Save</button>
    </div>
</div>

<mat-radio-group class="profit-radio-group">
    <mat-radio-button class="profit-radio-button" *ngFor="let margin of profitTypes" [value]="margin.id" (change)="setProfitType(margin.id)" [checked]="selectedProfitType===margin.id">
        {{margin.displayName}}
    </mat-radio-button>
</mat-radio-group>

<div id="profit-modal-hot-table" class="hot-table-container">
    <div class="hot-table sku-config-table profit-table" *ngIf="selectedProfitType==='margin'">
        <hot-table [hotId]="'margin-hot-table'" [settings]="gridSettings" [licenseKey]="handsontableLicenseKey"></hot-table>
    </div>
    <div class="hot-table sku-config-table profit-table" *ngIf="selectedProfitType==='cost'">
        <hot-table [hotId]="'cost-hot-table'" [settings]="gridSettings" [licenseKey]="handsontableLicenseKey"></hot-table>
    </div>
</div>
