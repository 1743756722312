<div class="scenario-group-dropdown">
    <mat-form-field appearance="standard">
        <mat-select [multiple]="showCheckbox" [placeholder]="placeholder"
                    [disabled]="groupItemStatus || bulkEditStatus" flat
                    class="mat-select scenario-group-select" disableOptionCentering [(value)]="dropDownSelectData"
                    (selectionChange)="selectionChanged($event)" #select (openedChange)="changeHeight($event,select)"
                    panelClass="scenario-group-select-panel"
                    [matTooltip]="getTooltip()" matTooltipPosition="above" matTooltipClass="scenario-tooltip"
                    [compareWith]="matSelectCompare">
            <mat-select-trigger *ngIf="showCheckbox">
                <span class="scenario-name">{{getDropDownDisplayName()}}</span><br/>
                <span class="scenario-desc">{{getDropDownDisplayDesc()}}</span>
            </mat-select-trigger>
            <mat-select-trigger *ngIf="!showCheckbox">
                <span class="scenario-name">{{getName()}}</span><br/>
                <span class="scenario-desc">{{getDescription()}}</span>
            </mat-select-trigger>
            <div class="filter-box"><input matInput class="filter-scenarios-input" [(ngModel)]="searchValue"
                                           (keyup)="search($event.target.value)" placeholder="Search Scenarios"
                                           (keydown)="$event.stopPropagation()"/>
                <span *ngIf="!searchValue" class="sif sif-filter filter-close"></span>
                <button class="filter-close" mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                        (click)="searchValueClickHandler()">
                    <span class="sif sif-delete"></span>
                </button>
                <mat-option [id]="referenceScenarioData.scenario.id" class="reference-scenario"
                            *ngIf='referenceScenarioData && referenceScenarioData.scenario'
                            [value]="{scenarioId:referenceScenarioData.scenario.id, scenarioGroupId:''}"
                            [disabled]="referenceScenarioData.disabled"
                            [ngClass]="[referenceScenarioData.isVisible ? 'show' : 'hide',referenceScenarioData.disabled ? 'disabled': '']">{{referenceScenarioData.scenario.name}}
                </mat-option>
                <mat-optgroup class="scenario-group-optgroup" *ngFor="let scenarioGroup of scenarioGroupsData"
                              [ngClass]="scenarioGroup.isVisible? 'show' : 'hide'">
                    <button class="opt-group-btn" mat-button (click)="expandScenarioGroup(scenarioGroup)">
                        <span class="sif sif-chevron-s"></span> {{scenarioGroup.name}}
                    </button>
                    <mat-option [id]="scenarioData.scenario.id" *ngFor="let scenarioData of scenarioGroup.scenarios"
                                [value]="{scenarioId:scenarioData.scenario.id, scenarioGroupId:scenarioGroup.id}"
                                [ngClass]="[isExpandGroup[scenarioGroup.id] || !scenarioData.isVisible? 'list-hide' : 'list-show', scenarioData.disabled ? 'disabled': '']"
                                [disabled]="scenarioData.disabled">
                        {{scenarioData.scenario.name}}
                    </mat-option>
                </mat-optgroup>
            </div>
        </mat-select>
    </mat-form-field>
    <div>
        <button mat-button [disabled]="groupItemStatus || bulkEditStatus" [ngClass]="t.menuOpen? 'active':'inActive'" #t="matMenuTrigger" [matMenuTriggerFor]="menu" *ngIf="enableActionMenu">
            <span class="sif sif-ellipsis-vertical"></span>
        </button>
    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item class="hide-options">Edit</button>
        <button mat-menu-item class="hide-options">Duplicate</button>
        <button mat-menu-item id="manageScenarios" (click)="openManageScenariosPage()">Manage Scenarios</button>
        <div [matTooltip]="runDataNotAvailableTitle"><button mat-menu-item *ngIf="isInternalUser" [disabled]="!enableImportActionMenu" ngxFilePicker accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (filePick)="importScenario($event)" class="import-scenario-btn">Import Scenarios</button></div>
        <div [matTooltip]="runDataNotAvailableTitle"><button mat-menu-item  [disabled]="!enableImportActionMenu" (click)="openExportScenarioModal()" class="export-scenario-btn">Export Scenarios</button></div>
        <button mat-menu-item class="hide-options">Delete</button>
    </mat-menu>
</div>


