import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ModelRunService} from '../../../../../services/model-run.service';
import {UiBlockerService} from '../../../../../services/ui-blocker.service';
import {ScenarioService} from '../../../../..//services/scenario.service';
import {MetaData} from '../../../../..//models/meta-data.model';
import {AppConstantsService} from '@app/services/app-constants.service';
import {Subscription} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {BulkEditInput} from '@app/interfaces/bulk-edit-input.interface';

@Component({
  selector: 'app-bulk-edit',
  templateUrl: './bulk-edit.component.html',
  styleUrls: ['./bulk-edit.component.scss']
})
export class BulkEditComponent implements OnInit {
  @Output() clicked = new EventEmitter();
  @Input() scenarioId: string;
  @Input() modelRunId: string;
  @Input() projectId: number;
  @Input() disableBulkEditApplyChanges: boolean;
  @Input() metaData: MetaData;
  @Input() selectedScenarioId: string;
  subscriptions: Subscription;
  filteredSkuConfigs: any;
  hasError: boolean;
  bulkEditInput: BulkEditInput = {
    regularPrice: {selectedBulkEdit: '', value: '', percentage: ''},
    distribution: {selectedBulkEdit: '', percentage: ''},
    promotion: {selectedBulkEdit: '', promotionPercentage: '', specialPromotionPercentage: ''},
    timeOnPromo: {selectedBulkEdit: '', percentage: ''},
  };

  validations: any = {
    regularPrice: {error: ''},
    distribution: {error: ''},
    promotions: {error: ''},
    timeOnPromo: {error: ''}
  };

  constructor(
    private modelRunService: ModelRunService,
    private uiBlockerService: UiBlockerService,
    private scenarioService: ScenarioService,
    private appConstantsService: AppConstantsService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.skuSelectionChangeSubscription();
    this.onSaveChangesCloseFlyoutSubscription();
    this.disableBulkEditApplyChanges = true;
  }

  skuSelectionChangeSubscription(): void {
    this.subscriptions.add(this.scenarioService.onBulkEditSkuSelectionChange.subscribe({
      next: (skuConfigs) => {
        this.filteredSkuConfigs = skuConfigs;
        Object.keys(this.bulkEditInput).forEach(key => {
          if (this.bulkEditInput[key].selectedBulkEdit.length) {
            this.validateSkuConfigs(this.bulkEditInput[key].selectedBulkEdit);
          }
        });
      }
    }));
  }

  isSetToDefaultOption(bulkEditInput): boolean {
    const setToDefaultOptions = [this.appConstantsService.setRegularPriceToDefault, this.appConstantsService.setDistributionToDefault,
      this.appConstantsService.setTimeOnPromoToDefault, this.appConstantsService.setPromotionPriceToDefault,
      this.appConstantsService.setSpecialPromotionPriceToDefault];
    return setToDefaultOptions.indexOf(bulkEditInput) >= 0;
  }

  validateSkuConfigs(bukEditOption): void {
    if (this.isSetToDefaultOption(bukEditOption)) {
      this.modelValidators.disableApplyChanges(bukEditOption);
      this.hasError = this.validations.regularPrice.error.length > 0 || this.validations.distribution.error.length > 0 ||
        this.validations.promotions.error.length > 0 || this.validations.timeOnPromo.error.length > 0;
      return;
    }
    switch (bukEditOption) {
      case this.appConstantsService.setRegularPriceToValue: {
        this.bulkEditInput.regularPrice.percentage = '';
        this.modelValidators.validateRegularPriceToValue();
        break;
      }
      case this.appConstantsService.changeRegularPriceByPercentage: {
        this.bulkEditInput.regularPrice.value = '';
        this.modelValidators.validateRegularPriceByPercentage();
        break;
      }
      case this.appConstantsService.setDistributionToPercentage: {
        this.modelValidators.validateDistributionPercentage();
        break;
      }
      case this.appConstantsService.setTimeOnPromoToPercentage: {
        this.modelValidators.validateTimeOnPromoPercentage();
        break;
      }
      case this.appConstantsService.changePromotionPriceByPercentage:
        this.modelValidators.validatePromoPriceByPercentage();
        break;
      case this.appConstantsService.changeSpecialPromotionPriceByPercentage:
        this.modelValidators.validateSpecialPromoPriceByPercentage();
        break;
    }
    this.hasError = this.validations.regularPrice.error.length > 0 || this.validations.distribution.error.length > 0 ||
      this.validations.promotions.error.length > 0 || this.validations.timeOnPromo.error.length > 0;
  }

  onBulkEditClose(): void {
    this.clearValues();
    this.disableBulkEditApplyChanges = true;
    this.clicked.emit(false);
  }

  onSaveChangesCloseFlyoutSubscription(): void {
    this.subscriptions.add(this.scenarioService.onSaveBulkEditChangesSubject.subscribe((closeFlyout) => {
      if (closeFlyout) {
        this.onBulkEditClose();
      } else {
        this.disableBulkEditApplyChanges = true;
      }
    }));
  }

  clearValues(): void {
    this.filteredSkuConfigs = [];
    this.clearValidations();
    this.bulkEditInput = {
      regularPrice: {selectedBulkEdit: '', value: '', percentage: ''},
      distribution: {selectedBulkEdit: '', percentage: ''},
      promotion: {selectedBulkEdit: '', promotionPercentage: '', specialPromotionPercentage: ''},
      timeOnPromo: {selectedBulkEdit: '', percentage: ''},
    };
  }

  hasSelectedSkus(selectedSkuConfigs): string {
    let error = '';
    if (selectedSkuConfigs && selectedSkuConfigs.length === 0) {
      error = 'Please select  SKU';
      this.disableBulkEditApplyChanges = true;
    }
    return error;
  }

  get modelValidators(): any {
    const skuConfigs = this.filteredSkuConfigs;
    const skuConfigRows = skuConfigs && skuConfigs.length ? skuConfigs.filter(h => h.skuId > 0) : null;
    const selectedSkuConfigs = skuConfigRows ? skuConfigRows.filter(h => h.isSelected) : [];
    const selectedSkuConfigsData = skuConfigs && skuConfigs.length ? skuConfigs : skuConfigs;
    const numericNumberReg = '\\-?\\d*\\.?\\d*';
    const error = this.hasSelectedSkus(selectedSkuConfigs);
    this.disableBulkEditApplyChanges = error.length !== 0;
    return {
      disableApplyChanges: (bukEditOption): void => {
        switch (bukEditOption) {
          case this.appConstantsService.setDistributionToDefault:
            this.bulkEditInput.distribution.percentage = '';
            this.validations.distribution.error = error;
            break;
          case this.appConstantsService.setTimeOnPromoToDefault:
            this.bulkEditInput.timeOnPromo.percentage = '';
            this.validations.timeOnPromo.error = error;
            break;
          case this.appConstantsService.setRegularPriceToDefault:
            this.bulkEditInput.regularPrice.percentage = '';
            this.bulkEditInput.regularPrice.value = '';
            this.validations.regularPrice.error = error;
            break;
          case this.appConstantsService.setPromotionPriceToDefault:
            this.bulkEditInput.promotion.promotionPercentage = '';
            this.bulkEditInput.promotion.specialPromotionPercentage = '';
            this.validations.promotions.error = error;
            break;
          case this.appConstantsService.setSpecialPromotionPriceToDefault:
            this.bulkEditInput.promotion.promotionPercentage = '';
            this.bulkEditInput.promotion.specialPromotionPercentage = '';
            this.validations.promotions.error = error;
            break;
          case this.appConstantsService.changePromotionPriceByPercentage:
            this.bulkEditInput.promotion.specialPromotionPercentage = '';
            this.validations.promotions.error = error;
            break;
          case this.appConstantsService.changeSpecialPromotionPriceByPercentage:
            this.bulkEditInput.promotion.promotionPercentage = '';
            this.validations.promotions.error = error;
            break;
        }
      },
      validateRegularPriceToValue: (): void => {
        let error = this.hasSelectedSkus(selectedSkuConfigs);
        if (!error.length) {
          const validate = new FormControl(this.bulkEditInput.regularPrice.value, [
            Validators.required,
            Validators.pattern(numericNumberReg),
          ]);
          error = validate.invalid ? 'Please enter valid value' : '';
          if (!validate.invalid) {
            const value = parseFloat(this.bulkEditInput.regularPrice.value);
            const skuWithInvalidValue = selectedSkuConfigsData.find(skuConfig => {
              return skuConfig.isSelected && (value >= skuConfig.priceMax || value <= skuConfig.priceMin);
            });
            error = skuWithInvalidValue ? 'Price change is out of range for at least one item.' : '';
          }
        }
        this.validations.regularPrice.error = error;
      },
      validateRegularPriceByPercentage: (): void => {
        let error = this.hasSelectedSkus(selectedSkuConfigs);
        if (!error.length) {
          const validate = new FormControl(this.bulkEditInput.regularPrice.percentage, [
            Validators.required,
            Validators.pattern(numericNumberReg),
          ]);
          error = validate.invalid ? 'Please enter valid value' : '';
          this.disableBulkEditApplyChanges = error.length !== 0;
          if (!validate.invalid) {
            const percentageValue = parseFloat(this.bulkEditInput.regularPrice.percentage);
            const skuWithInvalidValue = selectedSkuConfigsData.find(skuConfig => {
              const value = (skuConfig.priceInput) + ((percentageValue / 100) * skuConfig.priceInput);
              return skuConfig.isSelected && (value >= skuConfig.priceMax || value <= skuConfig.priceMin);
            });
            error = skuWithInvalidValue ? 'Price change is out of range for at least one item.' : '';
          }
        }
        this.disableBulkEditApplyChanges = error.length !== 0;
        this.validations.regularPrice.error = error;
      },
      validateDistributionPercentage: (): void => {
        let error = this.hasSelectedSkus(selectedSkuConfigs);
        if (!error.length) {
          const validate = new FormControl(this.bulkEditInput.distribution.percentage, [
            Validators.required,
            Validators.pattern(numericNumberReg),
          ]);
          error = validate.invalid ? 'Please enter valid value' : '';
          this.disableBulkEditApplyChanges = error.length !== 0;
          if (!validate.invalid) {
            const percentageValue = parseFloat(this.bulkEditInput.distribution.percentage);
            if (percentageValue < 0 || percentageValue > 100) {
              error = 'Please enter a value between 0 and 100';
            }
          }
        }
        this.disableBulkEditApplyChanges = error.length !== 0;
        this.validations.distribution.error = error;
      },
      validateTimeOnPromoPercentage: (): void => {
        let error = this.hasSelectedSkus(selectedSkuConfigs);
        if (!error.length) {
          this.disableBulkEditApplyChanges = error.length !== 0;
          const validate = new FormControl(this.bulkEditInput.timeOnPromo.percentage, [
            Validators.required,
            Validators.pattern(numericNumberReg),
          ]);
          error = validate.invalid ? 'Please enter valid value' : '';
          this.disableBulkEditApplyChanges = error.length !== 0;
          if (!validate.invalid) {
            const percentageValue = parseFloat(this.bulkEditInput.timeOnPromo.percentage);
            if (percentageValue < 0 || percentageValue > 100) {
              error = 'Please enter a value between 0 and 100';
            }
          }
        }
        this.disableBulkEditApplyChanges = error.length !== 0;
        this.validations.timeOnPromo.error = error;
      },
      validatePromoPriceByPercentage: (): void => {
        let error = this.hasSelectedSkus(selectedSkuConfigs);
        if (!error.length) {
          const validate = new FormControl(this.bulkEditInput.promotion.promotionPercentage, [
            Validators.required,
            Validators.pattern(numericNumberReg),
          ]);
          error = validate.invalid ? 'Please enter valid value' : '';
          this.disableBulkEditApplyChanges = error.length !== 0;
          if (!validate.invalid) {
            error = this.isPromoPercentageValid(selectedSkuConfigsData, this.bulkEditInput.promotion.promotionPercentage, 'promoPrice');
          }
        }
        this.disableBulkEditApplyChanges = error.length !== 0;
        this.validations.promotions.error = error;
      },
      validateSpecialPromoPriceByPercentage: (): void => {
        let error = this.hasSelectedSkus(selectedSkuConfigs);
        if (!error.length) {
          const validate = new FormControl(this.bulkEditInput.promotion.specialPromotionPercentage, [
            Validators.required,
            Validators.pattern(numericNumberReg),
          ]);
          error = validate.invalid ? 'Please enter valid value' : '';
          this.disableBulkEditApplyChanges = error.length !== 0;
          if (!validate.invalid) {
            error = this.isPromoPercentageValid(selectedSkuConfigsData, this.bulkEditInput.promotion.specialPromotionPercentage, 'specialPromoPrice');
          }
        }
        this.disableBulkEditApplyChanges = error.length !== 0;
        this.validations.promotions.error = error;
      },
    };
  }

  isPromoPercentageValid(skuConfigs, value, property): string {
    const percentageValue = parseFloat(value);
    const skuWithInvalidValue = skuConfigs.find(skuConfig => {
      const floatValue = parseFloat(skuConfig[(property)]);
      const calculatedValue = (floatValue) + ((percentageValue / 100) * floatValue);
      return skuConfig.isSelected && (calculatedValue >= skuConfig.promoPriceMax || calculatedValue <= skuConfig.promoPriceMin);
    });
    return skuWithInvalidValue ? 'Price change is out of range for at least one item.' : '';
  }

  applyChanges(closeBulkEditFlyout: boolean): void {
    this.scenarioService.onBulkEditSaveChanges.next({
      bulkEditInput: this.bulkEditInput,
      closeBulkEditFlyout
    });
    this.disableBulkEditApplyChanges = true;
    this.clearValues();
  }

  radioChangeHandler(event: any, bulkEditInput: string): void {
    this.bulkEditInput[(bulkEditInput)].selectedBulkEdit = event.value;
    this.validateSkuConfigs(event.value);
    this.hasError = this.validations.regularPrice.error.length > 0 || this.validations.distribution.error.length > 0 ||
      this.validations.promotions.error.length > 0 || this.validations.timeOnPromo.error.length > 0;
  }

  clearValidations(): void {
    this.validations = {
      regularPrice: {error: ''},
      distribution: {error: ''},
      promotions: {error: ''},
      timeOnPromo: {error: ''}
    };
  }
}
