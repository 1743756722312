import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {DropdownFilterService} from './../../services/dropdown-filter.service';
import {DropdownData, DropdownItem, DataType} from './dropdown.data.model';

@Component({
  selector: 'app-ns-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent<T> implements OnChanges {

  @Input() dropDownData: DropdownData<T>;
  @Input() disable = false;
  @Output() selectionChange = new EventEmitter<DropdownData<T>>();

  isAllCompleted: boolean;
  newLabel: string;
  isDropDownData = false;
  isListItems = false;
  isCheckboxes = false;
  isRadioButtons = false;
  defaultLabel: string;

  constructor(private dropdownService: DropdownFilterService<T>) {
  }

  ngOnChanges(): void {
    if (this.dropDownData != null && this.dropDownData.data.length) {
      this.isDropDownData = true;
      this.defaultLabel = 'No ' + this.dropDownData.dropdownLabel + ' Selected';
      switch (this.dropDownData.dataType) {
        case DataType.LIST: {
          this.isListItems = true;
          this.isRadioButtons = false;
          this.isCheckboxes = false;
          const selectedListItem = this.dropDownData.data.filter(itemDataValue => {
            return itemDataValue.selected === true;
          });
          if (selectedListItem.length === 1) {
            this.newLabel = selectedListItem[0].label;
          }
          break;
        }
        case DataType.CHECKBOX: {
          this.isCheckboxes = true;
          this.isListItems = false;
          this.isRadioButtons = false;
          if (this.dropDownData.groupSelect) {
            this.setCheckboxGroupDefaultLabels(this.dropDownData);
          } else {
            this.setLabels(this.dropDownData);
          }
          break;
        }
        case DataType.RADIO: {
          this.isRadioButtons = true;
          this.isCheckboxes = false;
          this.isListItems = false;
          const selectedRadioItem = this.dropDownData.data.filter(itemDataValue => {
            return itemDataValue.selected === true;
          });
          if (selectedRadioItem.length === 1) {
            this.newLabel = selectedRadioItem[0].label;
          }
          break;
        }
      }
    }
  }

  onSelect(dataset: DropdownData<T>, item: DropdownItem<T>): void {
    if (dataset.dataType === DataType.CHECKBOX) {
      this.setLabels(dataset);
    } else {
      dataset.data.forEach(dataItem => {
        dataItem.selected = false;
      });
      item.selected = true;
      this.newLabel = item.label;
    }
    this.selectionChange.emit(dataset);
  }

  updateAllSelectCheckboxes(): void {
    this.setCheckboxGroupDefaultLabels(this.dropDownData);
    this.selectionChange.emit(this.dropDownData);
  }

  selectAllCheckboxes(isCompleted: boolean): void {
    this.isAllCompleted = isCompleted;
    if (this.dropDownData == null) {
      return;
    }
    this.dropDownData.data.forEach(i => {
      i.selected = isCompleted;
    });
    this.setLabels(this.dropDownData);
    this.selectionChange.emit(this.dropDownData);
  }

  setCheckboxGroupDefaultLabels(item: DropdownData<T>): void {
    this.isAllCompleted = item != null && item.data.every(t => t.selected);
    this.setLabels(item);
  }

  setLabels(item: DropdownData<T>): void {
    this.newLabel = this.dropdownService.getLabels(item);
  }

}
