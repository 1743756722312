import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DefaultDropdownComponent} from './default-dropdown.component';
import {FormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [
    DefaultDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
  ],
  exports: [DefaultDropdownComponent],
  providers: [],
  bootstrap: [DefaultDropdownComponent]
})
export class DefaultDropdownModule {
}
