import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {ModelCacheUtil} from '../utils/model-cache-util';
import {GeneralSetting} from '../models/general-setting.model';
import {SkuConfigTableCellRenderers} from '@app/utils/sku-config-table-cell-renderers';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingService {

  private cache: ModelCacheUtil<GeneralSetting>;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    this.cache = new ModelCacheUtil<GeneralSetting>();
  }

  fetch(projectId: number, modelRunId: string): Observable<GeneralSetting> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/generalSettings`;
    return this.http.get<GeneralSetting>(url).pipe(map((generalSetting: GeneralSetting) => {
      this.cache.clear();
      this.cache.appendAll([generalSetting]);
      return generalSetting;
    }));
  }

  get(projectId: number, modelRunId: string): Observable<GeneralSetting> {
    const data = this.cache.find((it: GeneralSetting) => {
      return it.projectId === projectId && it.modelRunId === modelRunId;
    });
    if (data) {
      return of(data);
    } else {
      return this.fetch(projectId, modelRunId);
    }
  }

  createSkuConfigTableCellRenderersInstance(generalSetting: GeneralSetting): SkuConfigTableCellRenderers {
    return new SkuConfigTableCellRenderers({
      symbol: generalSetting.currencyText ? generalSetting.currencyText : '',
      decimal: generalSetting.currencyDecimalSeparator ? generalSetting.currencyDecimalSeparator : ' ',
      separator: generalSetting.currencyThousandsSeparator ? generalSetting.currencyThousandsSeparator : '',
      precision: generalSetting.currencyNumberOfDecimals ? generalSetting.currencyNumberOfDecimals : 0,
      pattern: generalSetting.showCurrencyTextAfterValue ? `#!` : `!#`,
      formatWithSymbol: true
    }, {
      symbol: '%',
      pattern: `#!`,
      decimal: generalSetting.sharesDecimalSeparator ? generalSetting.sharesDecimalSeparator : ' ',
      separator: generalSetting.sharesThousandsSeparator ? generalSetting.sharesThousandsSeparator : '',
      precision: generalSetting.sharesNumberOfDecimals ? generalSetting.sharesNumberOfDecimals : 0,
      negativePattern: `-#!`,
    }, {
      symbol: '',
      decimal: generalSetting.absolutesDecimalSeparator ? generalSetting.absolutesDecimalSeparator : ' ',
      separator: generalSetting.absolutesThousandsSeparator ? generalSetting.absolutesThousandsSeparator : '',
      precision: generalSetting.absolutesNumberOfDecimals ? generalSetting.absolutesNumberOfDecimals : 0
    }, {
      symbol: '',
      decimal: generalSetting.indicesDecimalSeparator ? generalSetting.indicesDecimalSeparator : ' ',
      precision: generalSetting.indicesNumberOfDecimals ? generalSetting.indicesNumberOfDecimals : 0
    });
  }

  update(generalSetting: GeneralSetting): Observable<GeneralSetting> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${generalSetting.projectId}/runs/${generalSetting.modelRunId}/generalSettings`;
    return this.http.put<GeneralSetting>(url, generalSetting).pipe(map((updatedGeneralSetting: GeneralSetting) => {
      this.cache.append(updatedGeneralSetting);
      return updatedGeneralSetting;
    }));
  }
}
