import {Observable, Subject} from "rxjs";

const K = (): void => {
  //do nothing
};

/**
 * Util class to wrap web socket functionality.
 * Inspired solution from : https://github.com/Azure/azure-webpubsub/blob/main/samples/javascript/whiteboard/src/websocketclient.js
 * */
export class WebSocketClient {
  /**
   * URL end point from which we can get a token
   * */
  token: string;
  urlFactory: string;
  protocol = 'json.webpubsub.azure.v1';
  reconnectInterval: number = (15 * 1000);
  webSocket: WebSocket = null;
  group: string;
  isDestroying = false;
  message$: Subject<string>;
  onopen: (e) => void = K;
  onclose: (e) => void = K;
  refreshToken: () => Observable<string>;

  constructor(token: string, group: string, refreshToken: () => Observable<string>) {
    this.token = token;
    this.group = group;
    this.message$ = new Subject();
    this.refreshToken = refreshToken;
  }

  joinGroup(): void {
    const groupName = this.group;
    if (groupName) {
      this.webSocket.send(JSON.stringify(
        {
          type: "joinGroup",
          group: groupName
        }
      ));
    }
  }

  leaveGroup(): void {
    const groupName = this.group;
    if (groupName) {
      this.webSocket.send(JSON.stringify(
        {
          type: "leaveGroup",
          group: groupName
        }
      ));
    }
  }

  async reConnect(): Promise<void> {
    this.refreshToken().subscribe((newToken) => {
      this.token = newToken;
      this.connect();
    });
  }

  async connect(): Promise<void> {
    if (this.token) {
      this.webSocket = new WebSocket(this.token, this.protocol);
      this.webSocket.onopen = (e) => {
        console.log(`Opened websocket for group ${this.group}`);
        this.onopen(e);
        this.joinGroup();
      };
      this.webSocket.onclose = (e) => {
        console.log(`Disconnecting websocket for group ${this.group}`);
        this.onclose(e);
        if (!this.isDestroying) {
          if (this.reconnectInterval > 0) {
            console.log(`Reconnect in ${this.reconnectInterval} ms`);
            setTimeout(() => this.reConnect(), this.reconnectInterval);
          }
        }
      };
      this.webSocket.onmessage = (e) => {
        const message = JSON.parse(e.data);
        if (message && message.type === "message") {
          this.message$.next(e.data);
        }
      };
    }
  }
}
