import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Handsontable from 'handsontable';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {HotTableRegisterer} from '@handsontable/angular';
import {EnvironmentService} from 'src/app/services/environment.service';
import {SkuConfigTableCellRenderers} from 'src/app/utils/sku-config-table-cell-renderers';
import {SkuGroup} from '@app/models/sku-group.model';
import {Subject, Subscription} from 'rxjs';
import {SkuConfig} from '@app/models/sku-config.model';
import {UserConfigurations} from '@app/models/user-configurations.model';
import {ScenarioService} from '@app/services/scenario.service';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import {MetaDataService} from '@app/services/meta-data.service';
import {TauAuditTrailEditDialogComponent} from '../tau-audit-trail-edit-dialog/tau-audit-trail-edit-dialog.component';
import {TauAuditTrailViewDialogComponent} from '../tau-audit-trail-view-dialog/tau-audit-trail-view-dialog.component';
import {MetaData} from "@app/models/meta-data.model";
import {GenericConfirmationModalComponent} from "@app/components/generic-confirmation-modal/generic-confirmation-modal.component";
import * as moment from "moment";
import {UserManagementService} from '@app/services/user-management.service';
import {isEmpty} from '@app/utils/object-utils';
import CellChange = Handsontable.CellChange;
import {AuditTrialService} from "@app/services/audit-trial.service";
import {ColumnChooserComponent} from "@app/components/column-chooser/column-chooser.component";
import {UiBlockerService} from '@app/services/ui-blocker.service';
import { CalibrationService } from 'src/app/services/calibration.service';
import { RhoAuditTrailViewDialogComponent } from '../rho-audit-trail-view-dialog/rho-audit-trail-view-dialog.component';
import { RhoAuditTrailEditDialogComponent } from '../rho-audit-trail-edit-dialog/rho-audit-trail-edit-dialog.component';

@Component({
    selector: 'app-calibration-results-new',
    templateUrl: './calibration-results-new.component.html',
    styleUrls: ['./calibration-results-new.component.scss']
})
export class CalibrationResultsNewComponent implements OnInit {

    @Input() hotTableData: any;
    @Input() tableSettings: any;
    @Input() skuGroups: SkuGroup[];
    @Input() cellRenderers: SkuConfigTableCellRenderers;
    @Input() metaData: MetaData;

    /**
     * If enabled, then the table should be in edit mode.
     * */
    @Input() editMode: boolean;

    @Input() reloadNewResultsTableSubject: Subject<any>;
    @Output() newErrorMessageEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() tableChangesEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() scenarios: any;
    @Input() scenarioSkuConfigs: Record<string, SkuConfig[]>
    @Input() scenarioColumnVisible: boolean;

    sourceHotTableData : any;
    subscriptions: Subscription;
    hotTableInstance: any;
    hotTableId = 'skuConfigTable-new';
    hotTableSettings: Handsontable.GridSettings = {};
    invalidCells = new Map(); // holds invalid hot table cells
    errorMessages: string[] = [];
    activeNewItem: any;
    filterState: any = [];
    allFilteredRows: any = [];
    resetOutputs: boolean;
    tauAuditTrailEditDialogRef: MatDialogRef<TauAuditTrailEditDialogComponent>;
    tauAuditTrailViewDialogRef: MatDialogRef<TauAuditTrailViewDialogComponent>;
    skuConfigColumnChooser: UserConfigurations;
    tableSettingsWithColumnChooser: any = {
        visibleColumns: [],
        visibleColumnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [],
        groupingsStartAtIndex: 3,
        inputsStartAtIndex: 0,
        outputsStartAtIndex: 0
    };
    brandsSelected: any;
    isAllSkusSelectedState: number;
    selectedSkuCount: number;
    currentCollapsedColumns: any;
    showDataSaveWarningMessage = false;
    showCalibrationNeedWarningMessage = false;
    dialogRef: any;
    badFitCountObject: any = {
        unitShareFitIndex: 0,
        unitShareFitDifference: 0,
        promoSplitFitIndex: 0
    };
    rhoAuditTrailViewDialogRef: MatDialogRef<RhoAuditTrailViewDialogComponent>;
    rhoAuditTrailEditDialogRef: MatDialogRef<RhoAuditTrailEditDialogComponent>;
    modifiedHotTableData:any = [];

    constructor(private hotTableRegisterer: HotTableRegisterer,
                private scenarioService: ScenarioService,
                private environmentService: EnvironmentService,
                private dialog: MatDialog,
                private userConfigurationsService: UserConfigurationsService,
                private appConstantsService: AppConstantsService,
                private metaDataService: MetaDataService,
                private userManagementService: UserManagementService,
                private auditTrialService: AuditTrialService,
                private uiBlockerService: UiBlockerService,
                private calibrationService: CalibrationService
    ) {
    }

    ngOnInit(): void {
        this.brandsSelected = [];
        this.activeNewItem = '';
        this.currentCollapsedColumns = [];
        this.sourceHotTableData = JSON.parse(JSON.stringify(this.hotTableData));
    }

    ngAfterViewInit(): void {
        this.setSelectAllCheckBoxState();
        this.loadHotTable();
        this.reloadNewResultsTableSubject.subscribe(() => {
            this.activeNewItem = '';
            this.currentCollapsedColumns = [];
            this.selectedSkuCount = 0;
            this.isAllSkusSelectedState = this.appConstantsService.NO_SKUS_SELECTED;
            this.loadHotTable();
        });
    }

    setSelectAllCheckBoxState(): void {
        if (this.activeNewItem) {
            const skuConfigRows = (this.hotTableData || []).filter(h => h.parentSkuId === this.activeNewItem);
            const selectedSkuConfigs = skuConfigRows.filter(h => h.isSelected && h.parentSkuId === this.activeNewItem);
            const totalSkuCount = skuConfigRows.length;
            const selectedSkuCount = selectedSkuConfigs.length;
            const unSelectedSkuCount = totalSkuCount - selectedSkuCount;
            this.selectedSkuCount = selectedSkuCount;
            if (selectedSkuCount === totalSkuCount) {
                this.isAllSkusSelectedState = this.appConstantsService.ALL_SKUS_SELECTED;
            } else if (unSelectedSkuCount === totalSkuCount) {
                this.isAllSkusSelectedState = this.appConstantsService.NO_SKUS_SELECTED;
            } else {
                this.isAllSkusSelectedState = this.appConstantsService.PARTIAL_SKUS_SELECTED;
            }
        } else {
            this.isAllSkusSelectedState = this.appConstantsService.NO_SKUS_SELECTED;
            this.selectedSkuCount = 0;
        }

    }

    loadHotTable(): void {
        this.uiBlockerService.block();
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        this.tableSettingsWithColumnChooser.allowFiltersForHeaders = [];
        this.tableSettingsWithColumnChooser.visibleColumns = [];
        this.tableSettingsWithColumnChooser.visibleColumnHeaders = [];
        this.tableSettingsWithColumnChooser.groupHeaders = [];
        this.tableSettingsWithColumnChooser.groupingsStartAtIndex = this.tableSettings.groupColumnStartIndex;
        this.tableSettingsWithColumnChooser.inputsStartAtIndex = this.tableSettings.inputsColumnStartIndex;
        this.tableSettingsWithColumnChooser.groupColumnEndIndex = this.tableSettings.groupColumnEndIndex;
        this.tableSettingsWithColumnChooser.inputsColumnEndIndex = this.tableSettings.inputsColumnEndIndex;
        this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex = this.tableSettings.unCalibratedHeadersStartIndex;
        this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex = this.tableSettings.unCalibratedHeadersEndIndex;
        this.tableSettingsWithColumnChooser.targetHeadersStartIndex = this.tableSettings.targetHeadersStartIndex;
        this.tableSettingsWithColumnChooser.targetHeadersEndIndex = this.tableSettings.targetHeadersEndIndex;
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettings.calibratedStartIndex;
        this.tableSettingsWithColumnChooser.calibratedEndIndex = this.tableSettings.calibratedEndIndex;
        this.showDataSaveWarningMessage = false;
        if (this.hotTableInstance) {
            
            this.hotTableInstance.batch(()=>{
                this.clearFilterState();
                const configType = this.appConstantsService.calibrationColumnChooserConfigType;
                this.skuConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
                this.applyUserConfigurationsForSkuConfigTable();
                this.hotTableInstance.loadData(this.hotTableData);
                this.generateTable(this.hotTableData, this.hotTableInstance);
                this.badFitCountObject = this.calibrationService.getBadFitCount(this.hotTableData,this.hotTableId);
                this.hotTableInstance.render();
                this.hideCorrelatedSkus();
                this.validateColumnsOnLoad();
            });

            this.hotTableInstance.batch(()=>{
                this.hotTableInstance.updateSettings({fixedColumnsLeft: this.tableSettingsWithColumnChooser.groupHeaders[0].colspan});
                this.hideCorrelatedSkus();
            });
              
        }
        this.uiBlockerService.unblockAll();
    }

    hideCorrelatedSkus(): void {
        const plugin = this.hotTableInstance.getPlugin('hiddenRows');
        const rowCount = this.hotTableInstance.countRows();
        const correlatedSkus = [];
        for (let i = 0; i < rowCount; i++) {
            const childRowData = this.hotTableInstance.getSourceDataAtRow(i);
            const cellMeta = this.hotTableInstance.getCellMeta(i, 2);
            if (childRowData.parentSkuId && (!this.activeNewItem || childRowData.parentSkuId != this.activeNewItem)) {
                correlatedSkus.push(i);
            }
            if (this.activeNewItem && this.activeNewItem === childRowData.skuId) {
                childRowData.isSelected = true;
            }
        }
        plugin.hideRows(correlatedSkus);
        this.hotTableInstance.render();

    }

    clearFilterState(): void {
        const conditionCollection = this.hotTableInstance && this.hotTableInstance.getPlugin('Filters').conditionCollection;
        if (conditionCollection) {
            this.hotTableInstance.getPlugin('Filters').clearConditions();
        }
    }


    getRowsByFilter(columnIndex, args): Array<number> {
        const rowCount = this.hotTableInstance.countRows();
        if (this.filterState[(columnIndex)] && this.filterState[(columnIndex)].length > 0) {
            this.allFilteredRows = this.allFilteredRows.filter(x => this.filterState[(columnIndex)].indexOf(x) < 0);
        }
        this.filterState[(columnIndex)] = [];
        const resultRowIds = [];
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            if (!(rowData.isGroupLabel || rowData.isBrand) &&
                args.indexOf(rowData[(this.tableSettingsWithColumnChooser.visibleColumns[columnIndex].data)]) === -1) {
                resultRowIds.push(i);
            }
        }
        this.filterState[columnIndex].push(resultRowIds);
        resultRowIds.map(r => {
            if (this.allFilteredRows.indexOf(r) === -1) {
                this.allFilteredRows.push(r);
            }
        });
        return resultRowIds;
    }

    filterAllRowsToHide(rowsToShow: Array<number>): void {
        this.allFilteredRows = this.allFilteredRows.filter(rowNumber => rowsToShow.indexOf(rowNumber) < 0);
    }

    getSelectAllCheckBoxHtml(): string {
        let htmlText = `<input type='checkbox'`;
        htmlText += `class='checker ${this.isAllSkusSelectedState === 1 ? 'checked' : (this.isAllSkusSelectedState === 0 ? 'unchecked' : 'partial')}'>`;
        htmlText += `<span class='count-txt'>${this.selectedSkuCount}</span>`;
        return htmlText;
    }

    get handsontableLicenseKey(): string {
        const handsontableConfig = this.environmentService.environment.handsontable;
        const handsontableLicenseKey = handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
        return handsontableLicenseKey;
    }

    generateTable(tableData, hotTableInstance): void {
        const self = this;
        const handsontableLicenseKey = this.handsontableLicenseKey;
        const offsetHeight = Math.min(document.getElementById("hotTableContainer2").getBoundingClientRect().top-10,350);
        hotTableInstance.updateSettings({
            viewportColumnRenderingOffset: 27,
            viewportRowRenderingOffset: 10000,
            allowInsertColumn: false,
            allowInsertRow: false,
            allowRemoveColumn: false,
            allowRemoveRow: false,
            autoWrapRow: false,
            height: document.documentElement.clientHeight - offsetHeight,
            autoWrapCol: false,
            manualRowResize: true,
            bindRowsWithHeaders: true,
            manualRowMove: true,
            manualColumnMove: false,
            fixedColumnsLeft: self.tableSettingsWithColumnChooser.groupHeaders[0].colspan,
            dropdownMenu: ['filter_by_value', 'filter_action_bar'],
            filters: self.tableSettingsWithColumnChooser.allowFiltersForHeaders,
            colHeaders: self.tableSettingsWithColumnChooser.visibleColumnHeaders,
            columns: self.tableSettingsWithColumnChooser.visibleColumns,
            rowHeights: 34,
            manualColumnResize: false,
            data: tableData,
            licenseKey: handsontableLicenseKey,
            nestedHeaders: [
                self.tableSettingsWithColumnChooser.groupHeaders,
                self.tableSettingsWithColumnChooser.visibleColumnHeaders
            ], hiddenRows: {
                rows: [],
                indicators: false
            },
            collapsibleColumns: [{
                row: -2,
                col: self.tableSettingsWithColumnChooser.groupColumnEndIndex > 0 ? self.tableSettingsWithColumnChooser.groupingsStartAtIndex : 0,
                collapsible: true
            }, {
                row: -2,
                col: self.tableSettingsWithColumnChooser.inputsStartAtIndex,
                collapsible: true
            }],
            renderAllRows: true,

            afterRender: () => {
                const tauAuditButtons = document.getElementsByClassName('tau-audit-trail-btn');
                const rhoAuditButtons = document.getElementsByClassName('rho-audit-trail-btn');
                Array.from(tauAuditButtons).forEach((it) => {
                    it.addEventListener('click', this.openAuditTrailEditDialog.bind(this,'tau'));
                    it.addEventListener('mouseenter', this.openAuditTrailViewDialog.bind(this,'tau'));
                });
                Array.from(rhoAuditButtons).forEach((it) => {
                    it.addEventListener('click', this.openAuditTrailEditDialog.bind(this,'rho'));
                    it.addEventListener('mouseenter', this.openAuditTrailViewDialog.bind(this,'rho'));
                });
            },

            afterOnCellMouseOut: (event, coords, TD): void => {
                if (event && event.target && 
                    ((event.target.firstElementChild && (event.target.firstElementChild.classList.contains('tau-audit-trail-btn') || event.target.firstElementChild.classList.contains('rho-audit-trail-btn'))) || 
                     (event.target.classList.contains('tau-audit-trail-btn') || event.target.classList.contains('rho-audit-trail-btn')))) {
                    self.closeAuditTrailViewDialog(event);
                }
            },

            afterOnCellMouseDown: (event, coords, TD): void => {
                self.hotTableInstance.batch(()=>{
                    const rowData = this.hotTableInstance.getSourceDataAtRow(coords.row);
                    if (event.target.classList.contains('checker')) {
                        event.preventDefault(); // prevent selection quirk
                        event.stopImmediatePropagation();
                        self.headerCheckbox();
                        self.hotTableInstance.deselectCell();
                    } else if (coords.col === 2 && coords.row >= 0 && rowData.hasCoRelatedSkus) {
                        self.expandCollapseRows(coords.row, coords.col);
                        self.setSelectAllCheckBoxState();
                        this.hotTableInstance.render();

                    } else if (event && event.target && ((event.target.firstElementChild && event.target.firstElementChild.classList.contains('sif-column-chooser')) ||
                        event.target.classList.contains('sif-column-chooser'))) {
                        self.openColumnChooserDialog(event);
                        self.hotTableInstance.deselectCell();
                    }
                });
            },


            afterGetColHeader(index, th): void {
                const BUTTON_CLASS_NAME = 'changeType';
                const existingButton = th.querySelector('.' + BUTTON_CLASS_NAME);
                const colName = this.getSettings().columns[index].name;

                if (index === 1) {
                    th.innerHTML = self.getSelectAllCheckBoxHtml();
                }
                if (colName === 'index'){
                    if(self.badFitCountObject && self.badFitCountObject.unitShareFitIndex > 0){
                        th.innerHTML = `<div class="relative"><span class="colHeader">${self.calibrationService.getBadFitCountBadge(self.badFitCountObject.unitShareFitIndex)}<span class="right-align-headers">Units Share <br> Fit Index</span></span></div>`;
                    }else{
                        th.innerHTML = `<div class="relative"><span class="colHeader"><span class="right-align-headers">Units Share <br> Fit Index</span></span></div>`;
                    }                    
                }
                if(colName === 'absolute'){
                    if(self.badFitCountObject && self.badFitCountObject.unitShareFitDifference > 0){
                        th.innerHTML = `<div class="relative"><span class="colHeader">${self.calibrationService.getBadFitCountBadge(self.badFitCountObject.unitShareFitDifference)}<span class="right-align-headers">Units Share <br> Fit Difference</span></span></div>`;
                    }else{
                        th.innerHTML = `<div class="relative"><span class="colHeader"><span class="right-align-headers">Units Share <br> Fit Difference</span></span></div>`;
                    }                    
                }
                if(colName === 'promoSplitFitIndex'){
                    if(self.badFitCountObject && self.badFitCountObject.promoSplitFitIndex > 0){
                        th.innerHTML = `<div class="relative"><span class="colHeader">${self.calibrationService.getBadFitCountBadge(self.badFitCountObject.promoSplitFitIndex)}<span class="right-align-headers">Promo Split <br> Fit Index</span></span></div>`;
                    }else{
                        th.innerHTML = `<div class="relative"><span class="colHeader"><span class="right-align-headers">Promo Split <br> Fit Index</span></span></div>`;
                    }                    
                }
                if (!this.enabled) {
                    if (existingButton) {
                        if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]'
                            && this.getSettings().filters.indexOf(index) === -1) {
                            existingButton.parentNode.removeChild(existingButton);
                        }
                    }
                    return;
                }
            },
            beforeChange: (changes: CellChange[]): void => {
                if (!changes) {
                    return;
                }
                const handsontable = self.hotTableInstance;
                const nonNumericInputRegex = /[^0-9\.]/ig;
                const inputShareFields = new Set(['targetUnitShare', 'targetPercentageUnitsOnPromoShare']);
                const cleanValue = (val): string => {
                    const parts = `${val}`.split('.').map(it => it.replace(nonNumericInputRegex, '')).filter(it => it.length);
                    const decimals = parts.pop();
                    return parts.length ? `${parts.join('')}.${decimals}` : decimals;
                };
                changes.forEach((change) => {
                    const row = change[0];
                    const propName = `${change[1]}`;
                    const rowData = handsontable.getSourceDataAtRow(row);
                    if (rowData.id && inputShareFields.has(propName)) {
                        let newValue = change[3];
                        if (newValue && newValue[newValue.length - 1] === '%') {
                            newValue = cleanValue(newValue.replace('%', ''));
                            change[3] = (Number.parseFloat(newValue) * 1000) / (100 * 1000);
                        } else {
                            change[3] = newValue ? cleanValue(newValue) : null;
                        }
                    }
                });
            },
            beforePaste: function (data, coords) {
                self.hotTableInstance.batch(()=>{
                    for (const coord of coords) {
                        for (let i = coord.startRow, j = 0; i <= coord.startRow + data.length - 1; i += 1, j += 1) {
                            for (let k = coord.startCol, l = 0; k <= coord.startCol + data[j].length - 1; k += 1, l += 1) {
                                const cellMeta = this.getCellMeta(i, k);
                                const type = cellMeta.type;
                                let parsedData;
                                if (type === 'checkbox') {
                                    parsedData = data[j][l] === '1' ? 1 : 0;
                                } else {
                                    parsedData = data[j][l];
                                }
                                if (!cellMeta.readOnly) {
                                    this.setDataAtCell(i, k, parsedData);
                                }
                            }
                        }
                    }
                });
                return false;
            },
            beforeFilter: (conditions): boolean => {
                self.hotTableInstance.batch(()=>{
                    const plugin = self.hotTableInstance.getPlugin('hiddenRows');
                plugin.showRows(this.allFilteredRows);
                /**
                 *  get rows to hide based on new conditions and hide them using plugin
                 */
                conditions.map((filter) => {
                    if (self.filterState[filter.column] && self.filterState[filter.column].length > 0 &&
                        self.allFilteredRows && self.allFilteredRows.length > 0) {
                        self.allFilteredRows = self.allFilteredRows.filter(rowNumber =>
                            self.filterState[(filter.column)].indexOf(rowNumber) < 0);
                    }
                    self.filterState[filter.column] = [];
                    const hRows = self.getRowsByFilter(filter.column, filter.conditions[0].args[0]);
                    plugin.hideRows(hRows);
                });
                Object.keys(self.filterState).forEach((column) => {
                    const col = conditions.find(condition => condition.column === +column);
                    if (!col) {
                        this.filterAllRowsToHide(self.filterState[(column)]);
                        self.filterState[column] = [];
                    }
                });

                /**
                 * remove hidden rows on no conditions
                 */
                if (!conditions.length) {
                    self.allFilteredRows = [];
                    self.filterState = [];
                }
                self.hotTableInstance.render();
                });
                return false;
            },
            afterValidate: (isValid, value, row, prop, source): void => {
                self.afterValidate(hotTableInstance, isValid, value, row, prop, source);
            },
            afterChange: (changes, source): void => {
                if (!changes) {
                    return;
                }
                const hasAtLeastOneChange = changes.find((it) => it[2] != it[3] && !((it[2] === null && it[1] != 'targetPercentageUnitsOnPromoShare') && (it[3] === '' || it[3] !== undefined || isEmpty(it[3]))));
                if (hasAtLeastOneChange) {
                    changes.forEach((change) => {
                        const row = change[0];
                        const col = change[1];
                        const rowData = self.hotTableInstance.getSourceDataAtRow(row);
                        const newVal = change[3] ? parseInt(change[3]) : change[3];
                        const originalValue = this.sourceHotTableData.find(sku => sku.skuId === rowData.skuId)[col];
                        if (col === 'isSelected' && rowData.parentSkuId) {
                            self.setCorrelatedSkusSelectionForTau(newVal, rowData);
                            self.setTouValues();
                            this.setSelectAllCheckBoxState();
                            self.showDataSaveWarningMessage = true;
                            this.hotTableInstance.render();
                        }
                        if (col === 'tau' && rowData.isNewItem) {
                            const oldVal = change[2];
                            const newVal1 = change[3];
                            if (oldVal !== newVal1) {
                                const newVal = change[3] ? parseFloat(change[3]) : change[3];
                                this.activeNewItem = rowData.skuId;
                                this.hotTableData.forEach((s) => {
                                    if (s.parentSkuId === rowData.skuId && rowData.tauValueFromStep2 !== newVal) {
                                        s.isSelected = 0;
                                        s.userDefinedTau = true;
                                        self.showDataSaveWarningMessage = oldVal !== newVal1;
                                        self.setCorrelatedSkusSelectionForTau(0, s);
                                    } else if (s.parentSkuId === rowData.skuId && rowData.tauValueFromStep2 === newVal) {
                                        s.isSelected = 1;
                                        s.userDefinedTau = false;
                                        self.showDataSaveWarningMessage = oldVal !== newVal1;
                                        this.setTouValues();
                                        self.setCorrelatedSkusSelectionForTau(1, s);
                                    }
                                });
                                this.handleWarningMessage(newVal, originalValue, rowData.skuId, col);
                                this.setSelectAllCheckBoxState();
                                this.hotTableInstance.render();
                            }
                        }
                        if(col === 'rho1' && rowData.isNewItem){
                            const oldVal = change[2];
                            const newVal1 = change[3];
                            if (oldVal !== newVal1) {
                                const newVal = change[3] ? parseFloat(change[3]) : change[3];
                                self.showDataSaveWarningMessage = oldVal !== newVal;
                                self.hotTableInstance.render();
                                this.handleWarningMessage(newVal, originalValue, rowData.skuId, col);
                            }
                        }
                        if (col === 'targetUnitShare' && rowData.isNewItem) {
                            this.activeNewItem = rowData.skuId;
                            const oldVal = change[2];
                            const newVal = change[3];
                            if (oldVal !== newVal && newVal > 0) {
                                self.setTauValueOnChangeOfTargetShares(newVal, oldVal, rowData);
                                self.setSelectAllCheckBoxState();
                                self.setTouValues(rowData);
                            } else if (oldVal !== newVal) {
                                this.hotTableData.forEach((s) => {
                                    if (s.parentSkuId === rowData.skuId) {
                                        self.setTauValueOnChangeOfTargetShares(newVal, oldVal, rowData);
                                        self.setCorrelatedSkusSelectionForTau(1, s);
                                    }
                                });
                                this.setSelectAllCheckBoxState();
                                self.setTouValues(rowData);
                            }
                            this.handleWarningMessage(newVal, originalValue, rowData.skuId, col);
                            self.hotTableInstance.render();
                            self.validateColumnsOnLoad();
                        }
                        if (col === 'targetPriceElasticity' && rowData.isNewItem) {
                            this.activeNewItem = rowData.skuId;
                            const oldVal = change[2];
                            const newVal = change[3];
                            this.showCalibrationNeedWarningMessage = oldVal !== newVal;
                            this.handleWarningMessage(newVal, originalValue, rowData.skuId, col);
                        }                        
                        if (col === 'targetPercentageUnitsOnPromoShare' && rowData.isNewItem) {
                            this.activeNewItem = rowData.skuId;
                            const oldVal = change[2];
                            const newVal = change[3];
                            this.showCalibrationNeedWarningMessage = oldVal !== newVal;
                            this.handleWarningMessage(newVal, originalValue, rowData.skuId, col);
                        }
                    });

                    this.showDataSaveWarningMessage = this.modifiedHotTableData.length && this.modifiedHotTableData.find(item => item.columnName == 'tau' || item.columnName == 'rho1');             
                    this.showCalibrationNeedWarningMessage = this.modifiedHotTableData.length && this.modifiedHotTableData.find(item => item.columnName == 'targetUnitShare' || item.columnName == 'targetPriceElasticity' || item.columnName == 'targetPercentageUnitsOnPromoShare');
                    self.updateDataSource();
                }
            }
        });
    }

    afterValidate(hotInstance, isValid, value, row, prop, source): void {
        if (source !== 'loadData') {
            if (!isValid) {
                if (!this.invalidCells.has(row)) {
                    this.invalidCells.set(row, new Set<number>());
                }
                this.invalidCells.get(row).add(prop);
            } else if (this.invalidCells.has(row)) {
                this.invalidCells.get(row).delete(prop);
                if (this.invalidCells.get(row).size === 0) {
                    this.invalidCells.delete(row);
                }
            }
            this.setErrorMessages();
            this.newErrorMessageEvent.emit({
                nonFormErrorMessages: this.errorMessages,
            });
        }
    }

    setErrorMessages(): void {
        this.errorMessages = [];
        const it = this.invalidCells.values();
        let result = it.next();
        while (!result.done) {
            this.errorMessages.push('One or more cells has invalid data please check');
            result = it.next();
        }
    }

    setTauValueOnChangeOfTargetShares(newVal, oldVal, rowData): void {
        this.hotTableData.filter(it => it.parentSkuId === rowData.skuId).forEach((s) => {
            if (newVal && parseFloat(newVal) > 0) {
                s.isSelected = 0;
                s.userDefinedTau = true;
                this.showCalibrationNeedWarningMessage = oldVal !== newVal;
                this.setCorrelatedSkusSelectionForTau(0, s);
            } else {
                s.isSelected = 1;
                s.userDefinedTau = false;
                this.showDataSaveWarningMessage = oldVal !== newVal;
                this.setCorrelatedSkusSelectionForTau(1, s);
            }
        });
    }


    headerCheckbox(): void {
        if (!this.activeNewItem || this.hotTableData.find((it) => {
            return it.skuId === this.activeNewItem && it.targetUnitShare && it.targetUnitShare > 0;
        })) {
            return;
        }
        if (this.isAllSkusSelectedState === 0) {
            this.isAllSkusSelectedState = 1;
            this.hotTableData.forEach((skuConfig: any) => {
                if (skuConfig.parentSkuId === this.activeNewItem) {
                    skuConfig['isSelected'] = 1;
                    skuConfig.userDefinedTau = false;
                    this.setCorrelatedSkusSelectionForTau(1, skuConfig);
                }
            });
        } else {
            this.isAllSkusSelectedState = 0;
            this.hotTableData.forEach((skuConfig: any) => {
                if (skuConfig.parentSkuId === this.activeNewItem) {
                    skuConfig['isSelected'] = 0;
                    skuConfig.userDefinedTau = false;
                    this.setCorrelatedSkusSelectionForTau(0, skuConfig);
                }

            });
        }
        this.setTouValues();
        this.showDataSaveWarningMessage = true;
        this.updateDataSource();
        this.selectedSkuCount = this.hotTableData.filter((h) => {
            return h.parentSkuId === this.activeNewItem && h.isSelected;
        }).length;
        this.hotTableInstance.render();
    }


    updateDataSource(): void {
        if (!this.errorMessages || this.errorMessages.length === 0) {
            this.tableChangesEvent.emit(this.hotTableInstance.getSourceData());
        }
    }


    allCorrelatedSkusSelected(correlatedSkus: any): boolean {
        let result = false;
        correlatedSkus.forEach((sku) => {
            if (!sku.isSelected) {
                result = true;
            }
        });
        return !result;
    }


    /**
     * if all correlated skus are selected set tau value to step2 correlation tau else on weight calculation
     * if all weights are empty then set to it's tau
     * @param rowData
     */
    setTouValues(rowData: any = null): void {
        const sumProduct = this.hotTableData.reduce((sumProduct, row) => sumProduct + (row.parentSkuId === this.activeNewItem && row.isSelected ? ((row.correlatedWeight ? row.correlatedWeight :row.weight) * row.tau) : 0), 0);
        const activeSku = this.hotTableData.find(it => it.skuId === this.activeNewItem);
        if (activeSku) {
            const correlatedSkus = this.hotTableData.filter((it) => {
                return it.parentSkuId === this.activeNewItem;
            });
            activeSku.tau = this.allCorrelatedSkusSelected(correlatedSkus) ? activeSku.tauValueFromStep2 : sumProduct;
        }
        if (rowData) {
            const correlatedSkus = this.hotTableData.filter((it) => {
                return it.parentSkuId === rowData.skuId;
            });
            rowData.tau = this.allCorrelatedSkusSelected(correlatedSkus) ? rowData.tauValueFromStep2 : sumProduct;
        }

    }

   setCorrelatedSkusSelectionForTau(value: number, row: any): void {
        const correlatedSkuSelection = this.hotTableData.find((sku) => sku.skuId === row.parentSkuId);
        const correlatedSkuOfActiveSkus = this.hotTableData.filter((sku) => sku.parentSkuId === row.parentSkuId);
        if (correlatedSkuSelection) {
            correlatedSkuSelection.correlatedSkus.find((it) => it.skuId === row.skuId).isSelected = value !== 0;
        }
        const rThreshold = 0;
        const sThreshold = 0.15;
        correlatedSkuOfActiveSkus.forEach((it) => {
            it['correlationFactor'] = Math.pow(it.correlation - rThreshold, 2);
        });
        const sumOfAdjustmentCorrelation = correlatedSkuOfActiveSkus.reduce((sum, corSku) => sum + (corSku.correlationFactor), 0);
        const sumOfAdjustmentCorrelationOfSelected = correlatedSkuOfActiveSkus.reduce((sumProduct, corSku) => sumProduct + (corSku.isSelected ? (corSku.correlationFactor) : 0), 0);
        const maxValue = sThreshold > sumOfAdjustmentCorrelation? sThreshold : sumOfAdjustmentCorrelation ;
        const sumOfAdjustmentWeight = sumOfAdjustmentCorrelationOfSelected / maxValue;
        correlatedSkuOfActiveSkus.forEach((it) => {
            const sum = maxValue * sumOfAdjustmentWeight;
            it.correlatedWeight = it.isSelected ? (it.correlationFactor / sum) : 0;
        });
    }

    getTauValue(rowData: any): number {
        return this.hotTableData.reduce((sumProduct, row) => sumProduct + (row.parentSkuId === rowData.skuId ? (row.weight * row.tau) : 0), 0);
    }

    /**
     * on each click on group/brand from reportingName column it does show and hise of corresponding rows
     * @description rowNumber which is clicked
     * @param row contains row number
     * @description column number which is clicked on
     * @param col contains column number
     */
    expandCollapseRows(row, col): void {
        const tableSettingsOptions = this.hotTableInstance.getSettings();
        const rowData = this.hotTableInstance.getSourceDataAtRow(row);
        const plugin = this.hotTableInstance.getPlugin('hiddenRows');
        const rowCount = this.hotTableInstance.countRows();
        const activeNewSkuCorrelatedSkus = [];
        const nonActiveNewSkuCorrelatedSkus = [];
        const otherSkus = [];
        this.hotTableInstance.setDataAtCell(row, col - 1, !rowData.isSelected);
        if (rowData.hasCoRelatedSkus && rowData.correlatedSkus.length) {
            this.activeNewItem = rowData.skuId;
            for (let i = 0; i < rowCount; i++) {
                const childRowData = this.hotTableInstance.getSourceDataAtRow(i);
                const cellMeta = this.hotTableInstance.getCellMeta(i, 2);
                const paddingClassName = childRowData.hasCoRelatedSkus && childRowData.correlatedSkus.length ? 'padding-left-1' : 'padding-left-3-point-4';
                cellMeta.className = childRowData.isSelected && childRowData.skuId === this.activeNewItem ? `ellipsis nota-cell htLeft ${paddingClassName} sif-13 sif sif-chevron-s` : `ellipsis  nota-cell htLeft ${paddingClassName} sif-13 sif sif-chevron-e`;
                if (childRowData.parentSkuId === rowData.skuId) {
                    activeNewSkuCorrelatedSkus.push(i);
                } else if (childRowData.parentSkuId && childRowData.parentSkuId !== rowData.skuId) {
                    nonActiveNewSkuCorrelatedSkus.push(i);
                } else {
                    otherSkus.push(i);
                }
                if (childRowData.skuId !== this.activeNewItem && childRowData.hasCoRelatedSkus && childRowData.correlatedSkus.length) {
                    this.hotTableInstance.setDataAtCell(i, col - 1, false);
                }
            }
            if (rowData.isSelected) {
                plugin.hideRows(activeNewSkuCorrelatedSkus);
                this.activeNewItem = '';
            } else {
                plugin.showRows(activeNewSkuCorrelatedSkus);
            }
            plugin.hideRows(nonActiveNewSkuCorrelatedSkus);
            plugin.showRows(otherSkus);
            this.hotTableInstance.render();

        }
        return;
    }


    /**
     *   used to show the rows belongs to selected brands
     */
    showOnlyActiveGroupCoRelatedSkus(isActiveBrand = false): Array<number> {
        const rowsToShow = [];
        this.allFilteredRows.forEach(i => {
            let rowOpen = 0;
            const rData = this.hotTableInstance.getSourceDataAtRow(i);
            rowOpen = this.hotTableInstance.getSourceData().filter((r) => {
                return rData.parentSkuId === r.skuId && rData.correlatedSkus.find(it => it.skuId === this.activeNewItem);
            }).length;
            if (rowOpen) {
                rowsToShow.push(i);
            }
        });
        return rowsToShow;
    }


    makeCopyOfTableSettings(): void {
        this.tableSettings.allowFiltersForHeaders.forEach(h => {
            this.tableSettingsWithColumnChooser.allowFiltersForHeaders.push(h);
        });
        this.tableSettings.groupHeaders.forEach(s => {
            this.tableSettingsWithColumnChooser.groupHeaders.push(Object.assign({}, s));
        });

        this.tableSettings.columnHeaders.forEach(c => {
            this.tableSettingsWithColumnChooser.visibleColumnHeaders.push(c);
        });
        this.tableSettings.columns.forEach(c => {
            this.tableSettingsWithColumnChooser.visibleColumns.push(Object.assign({}, c));
        });
    }

    /**
     *
     * @param  type which is columnChooser type either GROUPS/INPUTS/OUTPUTS
     * returns number of columns hidden due to user configurations
     */
    removeColumnsWhichAreNotSelected(type: string, configurations: any): number {
        const columnGroupHeader = this.tableSettingsWithColumnChooser.groupHeaders.find(h => h.type === type);
        let noOfHiddenColumns = 0;

        this.tableSettings.columns.forEach((column) => {
            const columnChooser = configurations.find(configuration => configuration.name === column.name);
            if (columnChooser && !columnChooser.showInColumn) {
                const index = this.tableSettingsWithColumnChooser.visibleColumns.findIndex(a => a.name === columnChooser.name);
                this.tableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
                this.tableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
                if (type === 'GROUPS') {
                    this.tableSettingsWithColumnChooser.allowFiltersForHeaders.splice(this.tableSettingsWithColumnChooser.visibleColumnHeaders.indexOf(index));
                }
                noOfHiddenColumns++;
            }
        });

        if (columnGroupHeader) {
            columnGroupHeader.colspan = columnGroupHeader.colspan - noOfHiddenColumns;
            if (!columnGroupHeader.colspan) {
                const headerIndex = this.tableSettingsWithColumnChooser.groupHeaders.indexOf(columnGroupHeader);
                this.tableSettingsWithColumnChooser.groupHeaders.splice(headerIndex, 1);
            }
        }
        return noOfHiddenColumns;
    }


    applyUserConfigurationsForSkuConfigTable(): void {
        this.makeCopyOfTableSettings();
        this.setUserConfigurations();
        this.setGroupingsColumnChooser();
        let hiddenColumnCount = this.removeColumnsWhichAreNotSelected('GROUPS', this.skuConfigColumnChooser.configurations['hideGroups']);
        this.tableSettingsWithColumnChooser.inputsStartAtIndex = this.tableSettingsWithColumnChooser.inputsStartAtIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex = this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.targetHeadersStartIndex = this.tableSettingsWithColumnChooser.targetHeadersStartIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettingsWithColumnChooser.calibratedStartIndex - hiddenColumnCount;

        this.tableSettingsWithColumnChooser.inputsColumnEndIndex = this.tableSettingsWithColumnChooser.inputsColumnEndIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex = this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.targetHeadersEndIndex = this.tableSettingsWithColumnChooser.targetHeadersEndIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedEndIndex = this.tableSettingsWithColumnChooser.calibratedEndIndex - hiddenColumnCount;
        this.setInputsColumnChooser();
        hiddenColumnCount = this.removeColumnsWhichAreNotSelected('INPUTS', this.skuConfigColumnChooser.configurations['hideInputs']);
        this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex = this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.targetHeadersStartIndex = this.tableSettingsWithColumnChooser.targetHeadersStartIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettingsWithColumnChooser.calibratedStartIndex - hiddenColumnCount;

        this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex = this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.targetHeadersEndIndex = this.tableSettingsWithColumnChooser.targetHeadersEndIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedEndIndex = this.tableSettingsWithColumnChooser.calibratedEndIndex - hiddenColumnCount;
        this.setUncalibratedColumnChooser();
        hiddenColumnCount = this.removeColumnsWhichAreNotSelected('UNCALIBRATED', this.skuConfigColumnChooser.configurations['hideUncalibratedColumns']);
        this.tableSettingsWithColumnChooser.targetHeadersStartIndex = this.tableSettingsWithColumnChooser.targetHeadersStartIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettingsWithColumnChooser.calibratedStartIndex - hiddenColumnCount;

        this.tableSettingsWithColumnChooser.targetHeadersEndIndex = this.tableSettingsWithColumnChooser.targetHeadersEndIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedEndIndex = this.tableSettingsWithColumnChooser.calibratedEndIndex - hiddenColumnCount;
        this.setTargetColumnChooser();
        hiddenColumnCount = this.removeColumnsWhichAreNotSelected('TARGETS', this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments']);
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettingsWithColumnChooser.calibratedStartIndex - hiddenColumnCount;
        this.tableSettingsWithColumnChooser.calibratedEndIndex = this.tableSettingsWithColumnChooser.calibratedEndIndex - hiddenColumnCount;
        this.setCalibratedColumnChooser();
        hiddenColumnCount = this.removeColumnsWhichAreNotSelected('CALIBRATED', this.skuConfigColumnChooser.configurations['hideOutputs']);
        this.tableSettingsWithColumnChooser.calibratedStartIndex = this.tableSettingsWithColumnChooser.calibratedStartIndex - hiddenColumnCount;
    }

    removeFilterOnReportingNameColumn(): void {
        const reportColumnFilterIndex = this.tableSettingsWithColumnChooser.allowFiltersForHeaders.indexOf(2);
        this.tableSettingsWithColumnChooser.allowFiltersForHeaders.splice(reportColumnFilterIndex, 1);
    }

    applyColumnSelectionChange(): void {
        if (this.skuConfigColumnChooser.id) {
            this.userConfigurationsService.updateUserConfiguration(this.skuConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.loadHotTable();
            });
        } else {
            this.skuConfigColumnChooser.configType = this.appConstantsService.calibrationColumnChooserConfigType;
            this.skuConfigColumnChooser.projectId = this.metaData.projectId;
            this.skuConfigColumnChooser.modelRunId = this.metaData.modelRunId;
            this.userConfigurationsService.createNewUserConfiguration(this.skuConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                this.loadHotTable();
            });
        }
    }

    setUserConfigurations(): void {
        this.skuConfigColumnChooser = this.skuConfigColumnChooser ? this.skuConfigColumnChooser : new UserConfigurations();
        this.skuConfigColumnChooser.configurations = this.skuConfigColumnChooser.configurations ? this.skuConfigColumnChooser.configurations : {
            hideGroups: [],
            hideInputs: [],
            hideOutputs: [],
            hideUncalibratedColumns: [],
            hideTargetsAndAdjustments: []
        };
    }

    setGroupingsColumnChooser(): void {
        if (this.skuGroups.length > 0) {
            this.skuGroups.forEach((skuGroup: SkuGroup) => {
                const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
                    return c.name === skuGroup.name;
                });
                if (!groupChooser) {
                    this.skuConfigColumnChooser.configurations['hideGroups'].push({
                        displayName: skuGroup.displayName,
                        name: skuGroup.name,
                        showInRow: true,
                        showInColumn: true
                    });
                } else {
                    groupChooser.displayName = skuGroup.displayName;
                }
            });
        }
        this.skuConfigColumnChooser.configurations['hideGroups'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideGroups']);
    }

    /**
     *
     * @param data columns to be arranged
     */
    orderColumnsForColumnChooser(data: any): any {
        const columnData = [];
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column) => {
            const columnChooser = data.find((c) => {
                return c.name === column.name;
            });
            if (columnChooser) {
                columnData.push(columnChooser);
            }
        });
        return columnData;
    }

    setInputsColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.inputsStartAtIndex &&
                index < this.tableSettingsWithColumnChooser.inputsColumnEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideInputs'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideInputs'].push({
                        displayName: column.displayName,
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
        this.skuConfigColumnChooser.configurations['hideInputs'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideInputs']);
    }

    setUncalibratedColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.unCalibratedHeadersStartIndex &&
                index < this.tableSettingsWithColumnChooser.unCalibratedHeadersEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'].push({
                        displayName: column.displayName.replace('<br>', ''),
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
        this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideUncalibratedColumns']);
    }

    setTargetColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.targetHeadersStartIndex &&
                index < this.tableSettingsWithColumnChooser.targetHeadersEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'].push({
                        displayName: column.displayName.replace('<br>', ''),
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
        this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments']);
    }

    setCalibratedColumnChooser(): void {
        this.tableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index >= this.tableSettingsWithColumnChooser.calibratedStartIndex &&
                index < this.tableSettingsWithColumnChooser.calibratedEndIndex) {
                const inputColumnChooser = this.skuConfigColumnChooser.configurations['hideOutputs'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.skuConfigColumnChooser.configurations['hideOutputs'].push({
                        displayName: column.displayName.replace('<br>', ''),
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
        this.skuConfigColumnChooser.configurations['hideOutputs'] = this.orderColumnsForColumnChooser(this.skuConfigColumnChooser.configurations['hideOutputs']);
    }


    openColumnChooserDialog(event): void {
        const outputsOnNonSampleFilterToggle = this.metaDataService.outputsOnNonSampleFilterToggle(this.metaData);
        const targetAttr = event.target.getBoundingClientRect();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.hasBackdrop = true;
        dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';
        if (event.target.classList.contains('groups')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideGroups'],
                customDialogOpen: true,
                groupHeaderLabel: 'calibration-groups'
            };
        } else if (event.target.classList.contains('inputs')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideInputs'],
                customDialogOpen: true,
                groupHeaderLabel: 'inputs'
            };
        } else if (event.target.classList.contains('scenario')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideScenario'],
                customDialogOpen: true,
                groupHeaderLabel: 'scenario',
                calibrationStatus: 6
            };
        } else if (event.target.classList.contains('unCalibrated')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideUncalibratedColumns'],
                customDialogOpen: true,
                groupHeaderLabel: 'unCalibrated',
                calibrationStatus: 6
            };
            dialogConfig.data.columnData.forEach((column) => {
                if (column.name === 'uncalibratedUnitShare') {
                    column.showAlways = true;
                    column.showInColumn = true;
                }
            });

        } else if (event.target.classList.contains('target')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideTargetsAndAdjustments'],
                customDialogOpen: true,
                groupHeaderLabel: 'target',
                calibrationStatus: 6
            };
            dialogConfig.data.columnData.forEach((column) => {
                if (column.name === 'tau') {
                    column.showAlways = true;
                    column.showInColumn = true;
                }
                if (column.name === 'targetUnitShare') {
                    column.showAlways = true;
                    column.showInColumn = true;
                }
                if (column.name === 'correlation' || column.name === 'weight') {
                    column.hide = false;
                }
            });
        } else if (event.target.classList.contains('calibrated')) {
            dialogConfig.data = {
                columnData: this.skuConfigColumnChooser.configurations['hideOutputs'],
                customDialogOpen: true,
                groupHeaderLabel: 'outputs',
                calibrationStatus: 6
            };
            dialogConfig.data.columnData.forEach((column) => {
                if (column.name === 'calibratedUnitShare') {
                    column.showAlways = true;
                    column.showInColumn = true;
                }
            });
        }
        dialogConfig.position = {
            top: `${targetAttr.y + targetAttr.height + 5}px`,
            left: `${targetAttr.x - targetAttr.width - 200}px`
        };
        this.dialogRef = this.dialog.open(ColumnChooserComponent, dialogConfig);
        this.dialogRef.afterClosed().subscribe(() => {
            if (this.dialogRef.componentInstance.hasChanges) {
                this.applyColumnSelectionChange();
                this.currentCollapsedColumns = [];
                this.dialogRef = null;
            }
        });
    }

    messageBarCloseEventHandler(message): void {
        if (message === 'Last change(s) require a new calibration run.') {
            this.showCalibrationNeedWarningMessage = false;
        } else 
        if(message === 'There are changes that require a save to the database in order to see them in results.'){
            this.showDataSaveWarningMessage = false;
        }
    }

    openAuditTrailEditDialog(column: string, event: MouseEvent): void {
        const btnElement: HTMLElement = <HTMLElement>event.target;
        const skuId = +(btnElement.getAttribute('sku-id'));
        this.hotTableInstance.deselectCell();

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.hasBackdrop = true;

        if(column === 'tau'){
            this.handleTauAuditTrailEditDialog(skuId,dialogConfig);
        }else
        if(column === 'rho'){
            this.handleRhoAuditTrailEditDialog(skuId,dialogConfig);
        }
    }

    handleTauAuditTrailEditDialog(skuId, dialogConfig){
        const skuRow = this.hotTableData.find(it => it.skuId === skuId && it.hasCoRelatedSkus);
        dialogConfig.data = this.formattedAuditTrailLogs(skuRow);
        const openDialog = this.dialog.openDialogs.find(dialog => dialog.componentInstance instanceof TauAuditTrailEditDialogComponent);
            if(!openDialog){
                this.tauAuditTrailEditDialogRef = this.dialog.open(TauAuditTrailEditDialogComponent, dialogConfig);
            }
            this.tauAuditTrailEditDialogRef.componentInstance.onRevert.subscribe((data) => {
                const revert = data.name === 'RESET';
                const apply = data.name === 'APPLY';
                if (revert) {
                    this.showRevertDialog(data.skuData,'tau');
                }
                if (apply) {
                    const currentAuditTrail = skuRow.auditTrailLogs.find((it) => {
                        return it.id === data.activeAuditLog.id && it.property === 'adjustedTauValue';
                    });
                    currentAuditTrail.reasonForAdjustment = currentAuditTrail ? data.activeAuditLog.reasonForAdjustment : '';
                    this.auditTrialService.update(this.metaData.projectId, this.metaData.modelRunId, data.activeAuditLog).subscribe((auditTrail) => {
                        const tauAuditTrails = skuRow.auditTrailLogs.filter((it) => { return it.skuId === auditTrail.skuId && it.property === 'adjustedTauValue'; });
                        skuRow.hasTauAdjustment = (tauAuditTrails.length > 0) ? true : false;
                        skuRow.hasTauAdjustmentDescription = ((tauAuditTrails.length > 0) && (tauAuditTrails[tauAuditTrails.length - 1].reasonForAdjustment)) ? true : false;
                        this.hotTableInstance.render();
                        if (this.tauAuditTrailEditDialogRef) {
                            this.tauAuditTrailEditDialogRef.close();
                        }
                    });
                }
            });
    }

    handleRhoAuditTrailEditDialog(skuId, dialogConfig){
        const skuRow = this.hotTableData.find(it => it.skuId === skuId && it.hasCoRelatedSkus);
        dialogConfig.data = this.formattedAuditTrailLogs(skuRow);
        const openDialog = this.dialog.openDialogs.find(dialog => dialog.componentInstance instanceof RhoAuditTrailEditDialogComponent);
            if(!openDialog){
                this.rhoAuditTrailEditDialogRef = this.dialog.open(RhoAuditTrailEditDialogComponent, dialogConfig);
            }
            this.rhoAuditTrailEditDialogRef.componentInstance.onRevert.subscribe((data) => {
                const revert = data.name === 'RESET';
                const apply = data.name === 'APPLY';
                if (revert) {
                    this.showRevertDialog(data.skuData,'rho');
                }
                if (apply) {
                    const currentAuditTrail = skuRow.auditTrailLogs.find((it) => {
                        return it.id === data.activeAuditLog.id && it.property === 'adjustedRho1Value';
                    });
                    currentAuditTrail.reasonForAdjustment = currentAuditTrail ? data.activeAuditLog.reasonForAdjustment : '';
                    this.auditTrialService.update(this.metaData.projectId, this.metaData.modelRunId, data.activeAuditLog).subscribe((auditTrail) => {
                        const rhoAuditTrails = skuRow.auditTrailLogs.filter((it) => { return it.skuId === auditTrail.skuId && it.property === 'adjustedRho1Value'; });
                        skuRow.hasRhoAdjustment = (rhoAuditTrails.length > 0) ? true : false;
                        skuRow.hasRhoAdjustmentDescription = ((rhoAuditTrails.length > 0) && (rhoAuditTrails[rhoAuditTrails.length - 1].reasonForAdjustment)) ? true : false;

                        this.hotTableInstance.render();
                        if (this.rhoAuditTrailEditDialogRef) {
                            this.rhoAuditTrailEditDialogRef.close();
                        }
                    });
                }
            });
    }

    openAuditTrailViewDialog(column: string, event: MouseEvent): void {
        this.closeAuditTrailViewDialog(event);
        const btnElement: HTMLElement = <HTMLElement>event.target;
        const skuId = +(btnElement.getAttribute('sku-id'));
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.hasBackdrop = false;
        dialogConfig.panelClass = 'audit-trail-modal';
        dialogConfig.position = {left: `${event.pageX - 250}px`, top: `${event.pageY - 180}px`};
        const sourceData = this.hotTableInstance.getSourceData();
        const skuRow = sourceData.find((it) => it.skuId === skuId && it.hasCoRelatedSkus);
        dialogConfig.data = this.formattedAuditTrailLogs(skuRow);
        if(column === 'tau'){
            this.tauAuditTrailViewDialogRef = this.dialog.open(TauAuditTrailViewDialogComponent, dialogConfig);
        }else
        if(column === 'rho'){
            this.rhoAuditTrailViewDialogRef = this.dialog.open(RhoAuditTrailViewDialogComponent, dialogConfig);
        }
    }

    closeAuditTrailViewDialog(event): void {
        if (this.tauAuditTrailViewDialogRef) {
            this.tauAuditTrailViewDialogRef.close();
        }
        if(this.rhoAuditTrailViewDialogRef){
            this.rhoAuditTrailViewDialogRef.close();
        }
    }

    showRevertDialog(skuData, column): any {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: 'Reset Adjustment?',
            body: 'Are you sure you want to delete this adjustment? This will discard all adjustment details and cannot be undone. Click save after hitting reset to apply changes.',
            confirmButtonLabel: 'RESET',
            cancelButtonLabel: 'CANCEL'
        };
        let dialogRef = this.dialog.openDialogs.find(dialog => dialog.componentInstance instanceof GenericConfirmationModalComponent);
        if(!dialogRef)
        {
            dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        }
        dialogRef.afterClosed().subscribe(value => {
            const revert = value === 'RESET';
            const sourceDataSku = this.hotTableData.find(it => it.skuId === skuData.skuId && it.hasCoRelatedSkus);
            if (revert) {
                if(column === 'tau'){
                    sourceDataSku.tau = sourceDataSku.targetUnitShare && sourceDataSku.targetUnitShare > 0 ? sourceDataSku.baseTau : sourceDataSku.tauValueFromStep2;
                    sourceDataSku.userDefinedTau = false;
                    sourceDataSku.correlatedSkus.forEach((s) => {
                        s.isSelected = true;
                    });
                    this.hotTableData.filter(it => it.parentSkuId === skuData.skuId).forEach((h) => {
                        h.isSelected = 1;
                        h.userDefinedTau = false;
                    });
                }else
                if(column === 'rho'){
                    sourceDataSku.rho1 = sourceDataSku.baseRho1ValueFromStep2;
                }
                this.setSelectAllCheckBoxState();
                this.hotTableInstance.render();
                this.showDataSaveWarningMessage = true;
                this.updateDataSource();
            }
        });
    }

    formattedAuditTrailLogs(skuData: any): any {
        if (skuData.auditTrailLogs) {
            skuData.auditTrailLogs.forEach((audit) => {
                this.userManagementService.getUser(audit.createdBy).subscribe((user) => {
                    audit['userName'] = user.fullName;
                });
                audit['lastUpdatedDate'] = `${moment(audit.lastUpdated).fromNow()}`;
            });
        }
        return skuData;
    }

    validateColumnsOnLoad(){
        this.calibrationService.validateTauRhoColumns(this.hotTableData, this.invalidCells, 'new');
        this.setErrorMessages();
        this.newErrorMessageEvent.emit({
                nonFormErrorMessages: this.errorMessages,
        });
    }

    handleWarningMessage(newVal, originalValue, skuId, columnName){
        if( newVal !== originalValue){
            this.modifiedHotTableData.push({skuId: skuId,columnName: columnName});
        }
        else{
            this.modifiedHotTableData = this.modifiedHotTableData.filter(item => !(item.skuId == skuId && item.columnName == columnName));           
        }
    }
}
