import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModelRunService} from '../../../../services/model-run.service';
import {Scenario} from '../../../../models/scenario.model';
import {ScenarioGroup} from '../../../../models/scenario-group.model';
import {ScenarioService} from '../../../../services/scenario.service';
import {ScenarioGroupService} from '../../../../services/scenario-group.service';
import {SkuGroup} from '../../../../models/sku-group.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UiBlockerService} from '../../../../services/ui-blocker.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GenericConfirmationModalComponent} from '../../../../components/generic-confirmation-modal/generic-confirmation-modal.component';
import {SnackBarService} from '@app/components/snack-bar/snack-bar.service';
import {ReportService} from '@app/services/report.service';
import {forkJoin} from "rxjs";
import {
  GroupBasedScenarioFilterData,
  NonGroupBasedScenarioFilterData
} from '@app/interfaces/scenario-group-filter-data.interface';
import {AppConstantsService} from '@app/services/app-constants.service';
import {SizeElasticityReport} from "@app/models/size-elasticity-report.model";
import {SizeElasticityService} from "@app/services/size-elasticity.service";

@Component({
  selector: 'app-create-size-elasticity-report',
  templateUrl: './create-size-elasticity-report.component.html',
  styleUrls: ['./create-size-elasticity-report.component.scss']
})
export class CreateSizeElasticityReportComponent implements OnInit {

  report: SizeElasticityReport;

  validationErrors: Record<string, string>;

  projectId: number;

  modelRunId: string;

  scenarios: Array<Scenario>;

  scenarioGroups: Array<ScenarioGroup>;

  skuGroups: Array<SkuGroup>;

  selectedScenarioId: string;

  reportNameMaxLength: number;

  reportDescriptionMaxLength: number;

  disableStartRun: boolean;

  existingReports: Array<SizeElasticityReport>;

  scenarioGroupsData: Array<GroupBasedScenarioFilterData>;

  referenceScenarioData: NonGroupBasedScenarioFilterData;

  searchValue = '';

  constructor(private sizeElasticityService: SizeElasticityService,
              private modelRunService: ModelRunService,
              private scenarioService: ScenarioService,
              private scenarioGroupService: ScenarioGroupService,
              private router: Router,
              private route: ActivatedRoute,
              private uiBlockerService: UiBlockerService,
              private dialog: MatDialog,
              private reportService: ReportService,
              private snackBarService: SnackBarService,
              private appConstantsService: AppConstantsService) {
  }

  setupNewReport(projectId: number, modelRunId: string): SizeElasticityReport {
    const sizeElasticityReport = new SizeElasticityReport();
    sizeElasticityReport.name = this.sizeElasticityService.getNewReportName();
    sizeElasticityReport.description = '';
    sizeElasticityReport.projectId = projectId;
    sizeElasticityReport.modelRunId = modelRunId;
    sizeElasticityReport.metric = null;
    return sizeElasticityReport;
  }

  duplicateReport(source: SizeElasticityReport): SizeElasticityReport {
    const sizeElasticityReport = new SizeElasticityReport();
    sizeElasticityReport.name = this.sizeElasticityService.getDuplicateReportName(source.name, this.reportNameMaxLength);
    sizeElasticityReport.description = source.description;
    sizeElasticityReport.projectId = source.projectId;
    sizeElasticityReport.modelRunId = source.modelRunId;
    sizeElasticityReport.metric = source.metric;
    sizeElasticityReport.scenarioId = this.scenarios.find(it => it.id === source.scenarioId) ? source.scenarioId : null;
    sizeElasticityReport.skuGroupId = this.skuGroups.find(it => it.skuGroupId === source.skuGroupId) ? source.skuGroupId : (source.skuGroupId === -1 ? -1 : null);
    return sizeElasticityReport;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        const source = params.source;
        const modelRun = this.modelRunService.activeModelRun;
        this.scenarios = [];
        this.scenarioGroups = [];
        this.projectId = modelRun.projectId;
        this.modelRunId = modelRun.id;
        this.validationErrors = {};
        this.skuGroups = this.route.parent.parent.snapshot.data.skuGroups;
        this.reportNameMaxLength = 30;
        this.reportDescriptionMaxLength = 50;
        this.disableStartRun = true;
        this.report = this.setupNewReport(this.projectId, this.modelRunId);

        forkJoin([this.scenarioService.getAll(this.projectId, this.modelRunId),
          this.scenarioGroupService.getAll(this.projectId, this.modelRunId),
          this.sizeElasticityService.getAll(this.projectId, this.modelRunId)]
        ).subscribe(([scenarios, scenarioGroups, reports]) => {
          this.scenarios = scenarios;
          this.scenarioGroups = scenarioGroups;
          this.existingReports = reports;
          this.prepareScenarioGroups('');

          if (source) {
            this.sizeElasticityService.getById(source, {
              projectId: this.projectId,
              modelRunId: this.modelRunId
            }).subscribe((sourceSizeElasticity: SizeElasticityReport) => {
              this.report = this.duplicateReport(sourceSizeElasticity);
              if (this.report.scenarioId) {
                this.changeScenario(this.report.scenarioId);
              }
              if (this.report.skuGroupId) {
                this.changeGroup(this.report.skuGroupId);
              }
              this.changeMetric(this.report.metric);
            });
          }
        });
      }
    );
  }

  search(searchValue: string): void {
    this.searchValue = searchValue;
    this.prepareScenarioGroups(this.searchValue);
  }

  prepareScenarioGroups(filterString: string): void {
    const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroups, filterString);
    this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
    this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
  }

  applyReportName(name: string, focusOut: boolean): void {
    if (focusOut && name.trim() === '') {
      name = this.sizeElasticityService.getNewReportName();
    }
    this.report.name = name;
    this.validateReportName();
  }

  applyReportDescription(description: string): void {
    this.report.description = description;
    this.validateReportDescription();
  }

  async changeScenario(scenarioId: string): Promise<void> {
    this.selectedScenarioId = scenarioId;
    this.report.scenarioId = scenarioId;
    await this.toggleStartRunButton();
  }

  async changeGroup(skuGroupId: number): Promise<void> {
    this.report.skuGroupId = skuGroupId;
    await this.toggleStartRunButton();
  }

  async changeMetric(metric: string): Promise<void> {
    this.report.metric = metric;
    await this.toggleStartRunButton();
  }

  closeReport(): void {
    this.router.navigateByUrl(`/projects/${this.projectId}/runs/${this.modelRunId}/reports/size-elasticity`);
  }

  generateReport(): void {
    this.uiBlockerService.block();
    this.report.name = this.report.name.trim();
    this.report.description = this.report.description?.trim();
    this.sizeElasticityService.add(this.report).subscribe((report: SizeElasticityReport) => {
      this.snackBarService.openSnackBar('Size elasticity run submitted successfully.', 'success');
      this.report = report;
      this.uiBlockerService.unblock();
      this.closeReport();
    }, () => {
      this.uiBlockerService.unblock();
    });
  }

  async canDeactivate(): Promise<boolean> {
    /**
     * Once the report is successfully generated, we don't want to show confirmation when redirecting to the list route.
     */
    if (this.report.id) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      header: 'Exit Size Elasticity Run Setup?',
      body: 'Changes will not be saved. Press \'Exit\' to continue, otherwise press \'Cancel\'.',
      confirmButtonLabel: 'EXIT',
      cancelButtonLabel: 'CANCEL'
    };
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(value => {
        const isExiting = value === 'EXIT';
        if (!isExiting) {
          // set the selected tab to the size elasticity tab if user decides to cancel out of transition
          this.reportService.activeReportSubject.next('size-elasticity');
        }
        resolve(isExiting);
      });
    });
  }

  async validateModelReportName(): Promise<string> {
    return new Promise<string>((resolve) => {
      const name = this.report.name;
      const maxLength = this.reportNameMaxLength;
      const reports = this.existingReports || [];
      const preExistingReportWithSameName = reports.find((report: SizeElasticityReport) => {
        return report.name.toLowerCase().trim() === name.toLowerCase().trim();
      });
      if (preExistingReportWithSameName) {
        resolve(this.appConstantsService.REPORT_ALREADY_EXISTS);
      } else {
        const validate = new FormControl(name, [
          Validators.maxLength(maxLength),
          Validators.required
        ]);
        resolve(validate.invalid ? validate.errors.required ? `Report name is required.` : `The maximum number of characters allowed is ${maxLength}.` : null);
      }
    });
  }

  validateModelReportDescription(): string {
    let reportDescriptionError = null;
    const reportDescriptionMaxLength = this.reportDescriptionMaxLength;
    const description = this.report.description;
    if (description && description.length > reportDescriptionMaxLength) {
      reportDescriptionError = `The maximum number of characters allowed is ${reportDescriptionMaxLength}.`;
    }
    return reportDescriptionError;
  }

  async validateModelScenario(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.scenarioId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Scenario is required.' : null);
    });
  }

  async validateModelItemCharacteristics(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.skuGroupId, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Item Characteristics is required.' : null);
    });
  }

  async validateModelMetrics(): Promise<string> {
    return new Promise<string>((resolve) => {
      const validate = new FormControl(this.report.metric, [
        Validators.required
      ]);
      return resolve(validate.invalid ? 'Metrics is required.' : null);
    });
  }

  async validateReportName(): Promise<void> {
    const errorMsg = await this.validateModelReportName();
    this.validationErrors.name = errorMsg;
    await this.toggleStartRunButton();
  }

  async validateReportDescription(): Promise<void> {
    const errorMsg = await this.validateModelReportDescription();
    this.validationErrors.description = errorMsg;
    await this.toggleStartRunButton();
  }


  async toggleStartRunButton(): Promise<void> {
    const reportNameError = await this.validateModelReportName();
    const scenarioError = await this.validateModelScenario();
    const itemCharacteristicsError = await this.validateModelItemCharacteristics();
    const metricsError = await this.validateModelMetrics();
    this.disableStartRun = (reportNameError || scenarioError || itemCharacteristicsError || metricsError) !== null;
  }
}
