import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UiBlockerService} from 'src/app/services/ui-blocker.service';
import {flatMap, map} from 'rxjs/operators';
import {UtilService} from '../services/util.service';
import {CalibrationService} from '../services/calibration.service';
import {Calibration} from '../models/calibration/calibration.model';

/**
 * This resolver will return calibration document if one exists. If one however doesn't exist then it will initialize
 * a calibration document.
 * IMPORTANT: DO NOT CALL THIS RESOLVER IN ANY OTHER ROUTE OTHER THAN CALIBRATION.
 * */
@Injectable({providedIn: 'root'})
export class CalibrationInitResolver implements Resolve<Calibration> {

    constructor(private service: CalibrationService,
                private utilService: UtilService,
                private uiBlockerService: UiBlockerService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Calibration> {
        this.uiBlockerService.block();
        const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
        const projectId = +this.utilService.getRouteParameter(route, 'projectId');
        return this.service.fetch(projectId, modelRunId).pipe(flatMap((calibration: Calibration) => {
            if (calibration.id) {
                this.uiBlockerService.unblock();
                return of(calibration);
            } else {
                return this.service.create(projectId, modelRunId).pipe(map((calibration: Calibration) => {
                    this.uiBlockerService.unblock();
                    return calibration;
                }));
            }
        }));
    }
}
