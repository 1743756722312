import {Component, Inject, OnInit} from '@angular/core';
import {ScenarioGroupService} from './../../services/scenario-group.service';
import {ProjectService} from './../../services/project.service';
import {Collaborator} from './../../models/collaborator.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ScenarioGroup} from './../../models/scenario-group.model';
import {UiBlockerService} from './../../services/ui-blocker.service';
import {SnackBarService} from './../../components/snack-bar/snack-bar.service';
import {cloneJson} from '../../utils/object-utils';
import {AuthProxyService} from './../../services/auth-proxy.service';
import {AccessPolicy} from './../../models/access-policy.model';
import {AccessPolicyService} from './../../services/access-policy.service';
import {AppConstantsService} from '../../services/app-constants.service';

@Component({
  selector: 'app-sharing-modal',
  templateUrl: './sharing-modal.component.html',
  styleUrls: ['./sharing-modal.component.scss']
})
export class SharingModalComponent implements OnInit {

  isShown = false;
  sharedToAllNielsen = false;
  sharedToAllNielsenChanged = false;
  sharedToAllClients = false;
  sharedToAllClientsChanged: boolean;
  collaboratorsAssignmentSave = false;
  clientCollaboratorsCount: number;
  collaboratorsMessage: string;
  collaboratorsAssignment: any;
  scenarioGroup: ScenarioGroup;
  modelRunId: string;
  projectCollaborators: Collaborator[];
  accessPolicies: AccessPolicy[];

  constructor(public dialogRef: MatDialogRef<SharingModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private scenarioGroupService: ScenarioGroupService,
              private authProxyService: AuthProxyService,
              private projectService: ProjectService,
              private uiBlockerService: UiBlockerService,
              private snackBarService: SnackBarService,
              private accessPolicyService: AccessPolicyService,
              private appConstantsService: AppConstantsService) {
  }

  ngOnInit(): void {
    this.collaboratorsAssignment = [];
    this.scenarioGroup = cloneJson(this.data.scenarioGroup);
    this.modelRunId = this.data.modelRunId;
    this.projectCollaborators = this.projectService.collaborators;
    this.accessPolicies = this.data.accessPolicies;
    this.sharedToAllClientsChanged = false;
    this.projectCollaborators.filter(collaborator => {
      return !(collaborator.isInternalUser || collaborator.isRemoved);
    }).forEach(collaborator => {
      const collaboratorChecked = this.accessPolicies.findIndex(
        accessPolicy => collaborator.userManagementId === accessPolicy.userManagementId) !== -1;
      this.collaboratorsAssignment.push({
        collaborator,
        isChecked: collaboratorChecked,
        changed: false,
        isVisible: true
      });
    });
    this.sharedToAllNielsen = this.accessPolicies.findIndex(
      accessPolicy => accessPolicy.group === 'internal') !== -1;
    this.allSelectedCheck();
  }

  collaboratorAssignmentChanged(collaboratorAssignment: any, event: any): void {
    collaboratorAssignment.isChecked = event.checked;
    collaboratorAssignment.changed = !collaboratorAssignment.changed;
    this.allSelectedCheck();
    this.collaboratorsAssignmentSaveCheck();
  }

  shareToAllNielsen($event): void {
    this.sharedToAllNielsen = $event.checked;
    this.sharedToAllNielsenChanged = !this.sharedToAllNielsenChanged;
    this.collaboratorsAssignmentSaveCheck();
  }

  shareToAllClients($event): void {
    this.sharedToAllClients = $event.checked;
    this.sharedToAllClientsChanged = !this.sharedToAllClientsChanged;
    this.collaboratorsAssignment.forEach(collaboratorAssignment => {
      if ($event.checked && !collaboratorAssignment.isChecked) {
        collaboratorAssignment.changed = !collaboratorAssignment.changed;
        collaboratorAssignment.isChecked = $event.checked;
      } else if (!$event.checked && !this.disableCollaborator(collaboratorAssignment)) {
        collaboratorAssignment.changed = !collaboratorAssignment.changed;
        collaboratorAssignment.isChecked = $event.checked;
      }
    });
    this.clientCollaboratorsCountCheck();
    this.prepareCollaboratorsMessage();
    this.collaboratorsAssignmentSaveCheck();
  }

  allSelectedCheck(): void {
    this.clientCollaboratorsCountCheck();
    const totalLength = this.collaboratorsAssignment.length;
    const sharedToAllClientsOld = this.sharedToAllClients;
    this.sharedToAllClients = (this.clientCollaboratorsCount === totalLength);
    if (sharedToAllClientsOld !== this.sharedToAllClients) {
      this.sharedToAllClientsChanged = !this.sharedToAllClientsChanged;
    }
    this.prepareCollaboratorsMessage();
  }

  clientCollaboratorsCountCheck(): void {
    this.clientCollaboratorsCount = this.collaboratorsAssignment.filter(collaboratorAssignment => {
      return collaboratorAssignment.isChecked;
    }).length;
  }

  prepareCollaboratorsMessage(): void {
    if (this.sharedToAllClients) {
      this.collaboratorsMessage = `All (${this.clientCollaboratorsCount}) Collaborators`;
    } else if (this.clientCollaboratorsCount === 1) {
      const selectedCollaborator = this.collaboratorsAssignment.find(collaboratorAssignment => {
        return collaboratorAssignment.isChecked;
      });
      this.collaboratorsMessage = `${selectedCollaborator.collaborator.fullName}`;
    } else if (this.clientCollaboratorsCount > 1) {
      this.collaboratorsMessage = `(${this.clientCollaboratorsCount}) Collaborators`;
    } else {
      this.collaboratorsMessage = 'No Collaborators Selected';
    }
  }

  /**
   * this will check save need to be enable/disable.
   */
  collaboratorsAssignmentSaveCheck(): void {
    const collaboratorsAssignmentSave = this.collaboratorsAssignment.findIndex(collaboratorAssignment => {
      return collaboratorAssignment.changed;
    }) !== -1;
    this.collaboratorsAssignmentSave = this.sharedToAllNielsenChanged || this.sharedToAllClientsChanged || collaboratorsAssignmentSave;
  }

  /**
   * this method will collect the checked/unChecked collaborators for the ScenarioGroup and update to backend.
   */
  onSaveCollaboratorsAssigment(): void {
    this.uiBlockerService.block();
    const updateCollaborators = {scenarioGroupId: this.scenarioGroup.id, add: [], remove: []};
    this.collaboratorsAssignment.forEach(collaboratorAssignment => {
      if (collaboratorAssignment.changed) {
        if (collaboratorAssignment.isChecked) {
          updateCollaborators.add.push(collaboratorAssignment.collaborator.userManagementId);
        } else {
          updateCollaborators.remove.push(collaboratorAssignment.collaborator.userManagementId);
        }
      }
    });

    if (this.sharedToAllNielsenChanged) {
      updateCollaborators['shareToNielsen'] = this.sharedToAllNielsen;
    }
    this.accessPolicyService.updateCollaborators(this.modelRunId, updateCollaborators).subscribe(res => {
        this.onSuccess('Scenario Group Collaborators Updated Successfully.');
        this.scenarioGroupService.createScenarioGroupSuccess('');
      },
      err => {
        this.onFailure(err.error.message);
      });
  }

  search(filterString): void {
    this.collaboratorsAssignment.forEach(collaboratorAssignment => {
      const isCollaboratorVisible = (!filterString.trim() ||
        collaboratorAssignment.collaborator.fullName.toLowerCase().indexOf(filterString.trim().toLowerCase()) > -1);
      collaboratorAssignment.isVisible = isCollaboratorVisible;
    });
  }

  onSuccess(message: string): void {
    this.snackBarService.openSnackBar(message, 'success', 4000);
    this.uiBlockerService.unblock();
  }

  onFailure(message: string): void {
    this.uiBlockerService.unblock();
    this.snackBarService.openSnackBar(message, 'error', 4000);
  }

  toggleCollaboratorsView(): void {
    this.isShown = !this.isShown;
  }

  disableCollaborator(collaboratorAssignment): boolean {
    return this.scenarioGroup.createdBy === collaboratorAssignment.collaborator.userManagementId ||
      this.authProxyService.user.userId === collaboratorAssignment.collaborator.userManagementId;
  }

  get isInternalUser(): boolean {
    return this.authProxyService.isInternalUser();
  }

  get isScenarioGroupInternal(): boolean {
    return this.projectService.getCollaboratorById(this.scenarioGroup.createdBy).isInternalUser;
  }
}
