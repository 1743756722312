import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit, OnChanges {
  primary = false;
  secondary = false;
  hero = false;
  tertiary = false;

  isSuccess = false;
  isError = false;
  isInformation = false;
  isWarn = false;
  isDefault = false;

  show = false;
  @Input() isExpanded = false;

  @Input() color: 'error' | 'information' | 'success' | 'warn' | 'default' = 'default';
  @Input() duration = 15;
  @Input() showPrompt = false;
  @Input() messageList: string[] = [];
  @Input() hideDismiss = false;
  @Input() headerText = '';
  @Output() promptClosed = new EventEmitter<boolean>();

  @ViewChild('prompt')
  promptTemplate!: TemplateRef<any>;

  constructor(
    private elementRef: ElementRef,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    this.initializeAttributes();
  }

  ngOnInit(): void {
    this.setColor();
    this.updatePromptDisplay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showPrompt']) {
      this.updatePromptDisplay();
    }
  }

  private initializeAttributes(): void {
    const element = this.elementRef.nativeElement;
    if (element.hasAttribute('secondary')) {this.secondary = true;}
    else if (element.hasAttribute('hero')) {this.hero = true;}
    else if (element.hasAttribute('tertiary')) {this.tertiary = true;}
    else {this.primary = true;}
  }

  private setColor(): void {
    switch (this.color) {
      case 'success':
        this.isSuccess = true;
        break;
      case 'error':
        this.isError = true;
        break;
      case 'information':
        this.isInformation = true;
        break;
      case 'warn':
        this.isWarn = true;
        break;
      default:
        this.isDefault = true;
        break;
    }
  }

  private updatePromptDisplay(): void {
    this.show = this.showPrompt;
    if (this.show && this.primary) {
      this._snackBar
        .openFromTemplate(this.promptTemplate, {
          duration: this.duration * 1000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: 'common-modalbox',
        })
        .afterDismissed()
        .subscribe(() => {
          this.closePrompt();
          this.cd.detectChanges();
        });
    }
  }

  closePrompt(): void {
    if (this.primary) {this._snackBar.dismiss();}
    else {
      this.show = false;
    }
    this.promptClosed.emit(true);
  }

  getHeaderText(): SafeHtml {
    if (this.headerText) {
      const headerStr = this.headerText.replace('{{count}}', this.messageList.length.toString());
      return headerStr;
    } else {
      const count = this.messageList.length.toString();
      if (this.isError) {return `${count} Errors found.`;}
      else if (this.isWarn) {return `${count} Warnings found.`;}
      else if (this.isInformation) {return `${count} Informations found.`;}
      else if (this.isSuccess) {return `${count} Success found.`;}
      else {return `${count} found.`;}
    }
  }

  toggleExpansion(): void {
    this.isExpanded = !this.isExpanded;
  }

}
