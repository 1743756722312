import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ModelRun} from '../models/model-run.model';
import {ModelRunService} from '../services/model-run.service';

@Injectable({providedIn: 'root'})
export class RunsResolver implements Resolve<ModelRun[]> {

  constructor(private service: ModelRunService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ModelRun[]> {
    return this.service.getModelRuns(+route.paramMap.get('projectId'));
  }
}
