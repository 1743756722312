import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {GeneralSetting} from '@app/models/general-setting.model';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GenericConfirmationModalComponent} from '@app/components/generic-confirmation-modal/generic-confirmation-modal.component';
import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations';

@Component({
    selector: 'app-advanced-settings',
    templateUrl: './advanced-settings.component.html',
    styleUrls: ['./advanced-settings.component.scss'],
    animations: [
        trigger('tauExpansion', [
            transition(':enter', [
                style({height: '0px', minHeight: '0'}),
                animate('300ms ease-in', style({height: '*', visibility: 'visible'})),
            ]),
            transition(':leave', [
                animate('300ms ease-out', style({height: '0px', minHeight: '0'})),
            ]),
        ]),
    ]
})
export class AdvancedSettingsComponent implements OnInit, OnChanges {

    @Input() generalSetting: GeneralSetting;
    @Input() adminView: boolean;
    @Output() closeAdvancedSettings = new EventEmitter<boolean>();
    @Output() saveAdvancedSettings = new EventEmitter<{ generalSetting: GeneralSetting; close: boolean }>();
    totalCategoryError: string;
    linearScaleError: string;
    exponentialScaleError: string;
    disableSaveButton: boolean;

    usePackRateAdjuster: boolean;
    ignorePackRateAdjuster: boolean;
    totalCategory: number;
    linearScale: number;
    exponentialScale: number;
    calibratePrice: boolean;
    isTauThreshold = false;
    minTau = -4;
    maxTau = 4;
    tauValueMinError: string;
    tauValueMaxError: string;

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.totalCategoryError = null;
        this.linearScaleError = null;
        this.exponentialScaleError = null;
        this.tauValueMinError = null;
        this.tauValueMaxError = null;
        this.disableSaveButton = false;
        this.ignorePackRateAdjuster = this.generalSetting.ignorePackRateAdjuster;
        this.usePackRateAdjuster = !this.generalSetting.ignorePackRateAdjuster;
        this.totalCategory = this.generalSetting.totalCategory;
        this.calibratePrice = this.generalSetting.calibratePrice === 0 ? false : true;
        this.generalSetting.calibratePrice = this.calibratePrice ? 1 : 0;
        this.generalSetting.isTauBoundDefined = this.generalSetting.isTauBoundDefined ? true : false;
        this.generalSetting.maxTau = this.generalSetting.maxTau ? this.generalSetting.maxTau : 4;
        this.generalSetting.minTau = this.generalSetting.minTau ? this.generalSetting.minTau : -4;
        this.linearScale = this.generalSetting.linearScale || 0.75;
        this.exponentialScale = this.generalSetting.exponentialScale || 0.83;
        this.minTau = this.generalSetting.minTau;
        this.maxTau = this.generalSetting.maxTau;
        this.isTauThreshold = this.generalSetting.isTauBoundDefined;
        this.toggleSaveButton();
    }

    /**
     * If the inputs to the component change, then re-initialize the component
     * */
    ngOnChanges(): void {
        this.ngOnInit();
    }

    /**
     * Returns true if the user has changed any of the inputs.
     * */
    get generalSettingIsDirty(): boolean {
        return this.generalSetting.ignorePackRateAdjuster !== this.ignorePackRateAdjuster ||
            this.generalSetting.totalCategory !== +this.totalCategory ||
            this.generalSetting.linearScale !== +this.linearScale ||
            this.generalSetting.exponentialScale !== +this.exponentialScale ||
            (this.generalSetting.calibratePrice !== +this.calibratePrice) ||
            (this.generalSetting.isTauBoundDefined !== this.isTauThreshold)||
            (this.generalSetting.minTau !== this.minTau)||
            (this.generalSetting.maxTau !== this.maxTau);
    }

    /**
     * Validates total category value set by user.
     * */
    validateTotalCategory(): void {
        const maxValue = 9007199254740991;
        const validate = new FormControl(this.totalCategory, [
            Validators.required,
            Validators.pattern(/^\d+$/),
            Validators.min(1),
            Validators.max(maxValue)
        ]);
        this.totalCategoryError = validate.invalid ? `Value must be integer between 0 and ${maxValue}.` : null;
        this.toggleSaveButton();
    }

    /**
     * Validates scale values.
     * */
    validateScale(value: number): FormControl {
        return new FormControl(value, [
            Validators.required,
            Validators.pattern(/^((|-?\d+)|((|-?\d+)\.(\d+)))$/),
            Validators.min(0),
            Validators.max(1)
        ]);
    }

    /**
     * Validates linear scale value set by user.
     * */
    validateLinearScale(): void {
        this.linearScaleError = this.validateScale(this.linearScale).invalid ? 'Value must be between 0 and 1.' : null;
        this.toggleSaveButton();
    }

    /**
     * Validates exponential scale value set by user.
     * */
    validateExponentialScale(): void {
        this.exponentialScaleError = this.validateScale(this.exponentialScale).invalid ? 'Value must be between 0 and 1.' : null;
        this.toggleSaveButton();
    }

    /**
     * Action that is triggered when pack rate adjuster checkbox is changed.
     * */
    togglePackRateAdjuster(): void {
        const newState = this.ignorePackRateAdjuster ? 'On' : 'Off';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: `Turn Pack Rate Adjuster ${newState}?`,
            body: `Are you sure you want to turn the Pack Rate Adjuster ${newState}? Once this parameter is toggled all results will change, including calibration, scenarios and reports.`,
            confirmButtonLabel: 'OK',
            cancelButtonLabel: 'CANCEL',
            showSaveButton: false,
            saveChangesButtonLabel: ''
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            if (value !== 'OK') {
                //CANCEL, when coming here the value of the usePackRateAdjuster would have been toggled hence reverting
                this.usePackRateAdjuster = !this.usePackRateAdjuster;
            }
            this.ignorePackRateAdjuster = !this.usePackRateAdjuster;
            this.toggleSaveButton();
        });
    }

    /**
     * Disables save button if user has not made any changes or if form has errors.
     * */
    toggleSaveButton(): void {
        this.disableSaveButton = !!(this.totalCategoryError || this.linearScaleError || this.exponentialScaleError || this.tauValueMinError || this.tauValueMaxError) || !this.generalSettingIsDirty;
    }

    /**
     * Action that is triggered when user clicks close button.
     * */
    onClose(): void {
        this.closeAdvancedSettings.emit(false);
        this.ngOnInit();
    }

    /**
     * Action that is triggered when user clicks save button.
     * This sets the user values to the general settings model data.
     * */
    onSave(close: boolean): void {
        this.generalSetting.ignorePackRateAdjuster = this.ignorePackRateAdjuster;
        this.generalSetting.totalCategory = +this.totalCategory;
        this.generalSetting.linearScale = +this.linearScale;
        this.generalSetting.exponentialScale = +this.exponentialScale;
        this.generalSetting.calibratePrice = this.calibratePrice ? 1 : 0;
        this.generalSetting.isTauBoundDefined = this.isTauThreshold;
        if (this.isTauThreshold) {
            this.generalSetting.minTau = +this.minTau;
            this.generalSetting.maxTau = +this.maxTau;
        } else {
            this.generalSetting.minTau = -4;
            this.generalSetting.maxTau = 4;
        }
        this.saveAdvancedSettings.emit({generalSetting: this.generalSetting, close});
    }

    toggleOptimizeCalibrationLock(): void {
        const newState = this.calibratePrice ? 'Off' : 'On';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: `Turn Calibrate price ${newState}?`,
            body: `Are you sure you want to change price calibration settings? This parameter will not impact results until calibration is run.`,
            confirmButtonLabel: 'OK',
            cancelButtonLabel: 'CANCEL',
            showSaveButton: false,
            saveChangesButtonLabel: ''
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            if (value !== 'OK') {
                //CANCEL, when coming here the value of the usePackRateAdjuster would have been toggled hence reverting
                this.calibratePrice = !this.calibratePrice;
            }
            this.toggleSaveButton();
        });
    }

    warningOnTauBoundsVisibility(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            header: `Reset tau bounds to default?`,
            body: `Are you sure you want to reset tau bounds to the default values (-4, 4)? This parameter will not impact calibration results until calibration is run.`,
            confirmButtonLabel: 'OK',
            cancelButtonLabel: 'CANCEL',
            showSaveButton: false,
            saveChangesButtonLabel: ''
        };
        const dialogRef = this.dialog.open(GenericConfirmationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(value => {
            if (value !== 'OK') {
                //CANCEL, when coming here the value of the usePackRateAdjuster would have been toggled hence reverting
                this.isTauThreshold = !this.isTauThreshold;
            }else{
                this.minTau =-4;
                this.maxTau = 4;
                this.tauValueMinError = null;
                this.tauValueMaxError = null;
            }
            this.toggleSaveButton();
        });
    }

    toggleTauThreshold(): void {
      if((this.minTau !==-4 || this.maxTau !== 4 ) && !this.isTauThreshold){
          this.warningOnTauBoundsVisibility();
      }else{
          this.toggleSaveButton();
      }
    }

    validateMinTau(): void {
        const validate = new FormControl(this.minTau, [
            Validators.required,
            Validators.pattern(/^-?\d+(?:\.\d+)?$/),
            Validators.min(-10),
            Validators.max(0)
        ]);
        this.tauValueMinError = validate.invalid ? "Please enter a value between -10 and 0" : null;
        this.toggleSaveButton();
    }

    validateMaxTau(): void {
        const validate = new FormControl(this.maxTau, [
            Validators.required,
            Validators.pattern(/^((|-?\d+)|((|-?\d+)\.(\d+)))$/),
            Validators.min(0),
            Validators.max(10)
        ]);
        this.tauValueMaxError = validate.invalid ? "Please enter a value between 0 and 10" : null;
        this.toggleSaveButton();
    }

}
