import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {UserManagementService} from '../../services/user-management.service';

@Component({
  selector: 'app-rho-audit-trail-view-dialog',
  templateUrl: './rho-audit-trail-view-dialog.component.html',
  styleUrls: ['./rho-audit-trail-view-dialog.component.scss']
})
export class RhoAuditTrailViewDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RhoAuditTrailViewDialogComponent>,
    public userManagementService: UserManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
) {
}

skuData: any;
userName: string;
rhoAuditTrailLogs: any


ngOnInit(): void {
    this.skuData = this.data;
    this.userManagementService.getUser(this.skuData.step2StartedBy).subscribe((user) => {
        this.userName = user.fullName;
    });
    this.rhoAuditTrailLogs = this.skuData.auditTrailLogs.filter(it => it.property === 'adjustedRho1Value');
}

get userFullName(): string {
    return this.userName;
}

get lastUpdated(): string {
    return `${moment(this.skuData.step2Ended).fromNow()}`;
}

dialogOut(): void{
    this.dialogRef.close(true);
}

get hasAdjustment(){
    return this.skuData.hasRhoAdjustmentDescription;
}

getRoundedRhoValue(value : number){
    return this.roundTo(value,5);
}

roundTo(n, digits): any {
    const multiplicand = Math.pow(10, digits);
    n = parseFloat((n * multiplicand).toFixed(12));
    n = (Math.round(n) / multiplicand).toFixed(digits);
    return n;
}

}