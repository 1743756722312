import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Handsontable from 'handsontable';
import {HotTableRegisterer} from '@handsontable/angular';
import {EnvironmentService} from '@app/services/environment.service';
import {Subject} from "rxjs";
import {ScenarioService} from "@app/services/scenario.service";
import {UiBlockerService} from "@app/services/ui-blocker.service";
import {isEmpty} from "@app/utils/object-utils";

@Component({
    selector: 'app-promotions-config-table',
    templateUrl: './promotions-config-table.component.html',
    styleUrls: ['./promotions-config-table.component.scss']
})
export class PromotionsConfigTableComponent implements OnInit, AfterViewInit {

    @Input() hotTableData: any;
    @Input() tableSettings: any;
    @Input() reloadTableSubject: Subject<any>;
    @Output() errorEvent: EventEmitter<{ disableApply: boolean }> = new EventEmitter<{ disableApply: boolean }>();
    @Output() tableChangesEvent: EventEmitter<any> = new EventEmitter<any>();

    hotTableSettings: Handsontable.GridSettings = {};
    hotTableId = 'promotionsConfigTable';
    hotTableInstance: Handsontable;
    disableApply = true;
    invalidCells = new Map(); // holds invalid hot table cells


    constructor(private hotTableRegisterer: HotTableRegisterer,
                private router: Router,
                private route: ActivatedRoute,
                private environmentService: EnvironmentService,
                private scenarioService: ScenarioService,
                private uiBlockerService: UiBlockerService,) {
    }

    ngOnInit(): void {
        this.reloadTableSubject.subscribe(() => {
            this.loadHotTable();
        });
    }

    ngAfterViewInit(): void {
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        if (this.hotTableInstance && this.hotTableData.length > 0) {
            this.loadHotTable();
        }
    }

    loadHotTable(): void {
        this.uiBlockerService.block();
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        if (this.hotTableInstance && this.hotTableData.length > 0) {
            this.hotTableInstance.loadData(this.hotTableData);
            this.generateTable();
            this.hotTableInstance.render();
        }
        this.uiBlockerService.unblockAll();
    }

    generateTable(): void {
        const handsontableLicenseKey = this.handsontableLicenseKey;
        this.hotTableInstance.updateSettings({
            viewportRowRenderingOffset: 'auto',
            allowInsertColumn: false,
            allowInsertRow: false,
            allowRemoveColumn: false,
            allowRemoveRow: false,
            autoWrapRow: false,
            autoWrapCol: false,
            manualRowResize: false,
            manualRowMove: false,
            manualColumnMove: false,
            colHeaders: this.tableSettings.columnHeaders,
            columns: this.tableSettings.columns,
            height: document.documentElement.clientHeight - 180,
            rowHeights: 34,
            manualColumnResize: false,
            data: this.hotTableData,
            licenseKey: handsontableLicenseKey,
            nestedHeaders: this.tableSettings.nestedHeaders,
            renderAllRows: true,
            fixedRowsBottom: 0,
            afterValidate: (...args): void => {
                this.afterValidate(args);
            },
            afterChange: (changes, source): void => {
                if (!changes) {
                    return;
                }
                const hasAtLeastOneChange = changes.find((it) => it[2] !== it[3] && !(it[2] === null && (it[3] === '' || it[3] !== undefined || isEmpty(it[3]))));
                if (hasAtLeastOneChange) {
                    this.updateDataSource();
                }
            }
        });
    }

    updateDataSource(): void {
        if (!this.disableApply) {
            this.tableChangesEvent.emit(this.hotTableInstance.getSourceData());
        }
    }

    afterValidate(args): void {
        const isValid = args[0];
        const row = args[2];
        const prop = args [3];
        const source = args[4];

        if (source !== 'loadData') {
            if (!isValid) {
                if (!this.invalidCells.has(row)) {
                    this.invalidCells.set(row, new Set<number>());
                }
                this.invalidCells.get(row).add(prop);
            } else if (this.invalidCells.has(row)) {
                this.invalidCells.get(row).delete(prop);
                if (this.invalidCells.get(row).size === 0) {
                    this.invalidCells.delete(row);
                }
            }
            const it = this.invalidCells.values();
            this.disableApply = false;
            let result = it.next();
            while (!result.done && !this.disableApply) {
                this.disableApply = true;
                result = it.next();
            }
            this.errorEvent.emit({disableApply: this.disableApply});
        }
    }

    get handsontableLicenseKey(): string {
        const handsontableConfig = this.environmentService.environment.handsontable;
        return handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
    }
}
