// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'development',
    studio: {
        url: 'htp://localhost:8080',
    },
    authProxy: {
        url: 'http://localhost:4000',
        lpoSimulatorContextPath: 'lposimulatorservice/lpo-simulator',
        studioContextPath: 'studio/platformservices'
    },
    heartbeat: {
        enabled: false,
        url: 'http://localhost:4000/heartbeat'
    },
    handsontable: {
      licenseKey: 'non-commercial-and-evaluation'
    }
};
