export enum DataType {
  LIST = 'list',
  CHECKBOX = 'checkbox',
  RADIO = 'radio'
}

export interface DropdownItem<T> {
  name: string;
  label: string;
  value: T;
  selected: boolean;
}

export interface DropdownData<T> {
  name: string;
  dropdownLabel: string | (() => string);
  dataType: DataType.LIST | DataType.CHECKBOX | DataType.RADIO;
  contentTitle?: string;
  groupSelect?: string;
  data: Array<DropdownItem<T>>;
}
