import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ScenarioService} from './../../services/scenario.service';
import {Scenario} from './../../models/scenario.model';
import {ScenarioGroupService} from './../../services/scenario-group.service';
import {ScenarioGroup} from './../../models/scenario-group.model';
import {SnackBarService} from '../../components/snack-bar/snack-bar.service';
import {UiBlockerService} from './../../services/ui-blocker.service';
import {AccessPolicyService} from '../../services/access-policy.service';
import {AppConstantsService} from '../../services/app-constants.service';
import {AuthProxyService} from '../../services/auth-proxy.service';
import {User} from '../../models/user.model';
import {Collaborator} from '../../models/collaborator.model';
import {ProjectService} from '../../services/project.service';
import {Calibration} from '@app/models/calibration/calibration.model';
import {CalibrationService} from '@app/services/calibration.service';
import {WebMessageService} from '@app/services/web-message.service';
import {
    GroupBasedScenarioFilterData,
    NonGroupBasedScenarioFilterData
} from "@app/interfaces/scenario-group-filter-data.interface";

interface ScenarioGroupAssignment {
    scenarioGroup: ScenarioGroup;
    isChecked: boolean;
    disabled: boolean;
}

@Component({
    selector: 'app-create-scenario',
    templateUrl: './create-scenario.component.html',
    styleUrls: ['./create-scenario.component.scss']
})
export class CreateScenarioComponent implements OnInit {
    scenarios: Scenario[];
    scenarioGroupsList: ScenarioGroup[];
    selectedScenarioGroups: Array<ScenarioGroupAssignment>;
    scenarioGroupsAssignment: Array<ScenarioGroupAssignment>;
    scenarioName: string;
    scenarioDescription: string;
    selectedScenarioId: string;
    modelRunId: string;
    projectId: number;
    scenarioGroupsData: Array<GroupBasedScenarioFilterData>;
    referenceScenarioData: NonGroupBasedScenarioFilterData;
    validations: Record<string, { warning: boolean; error: string }>;
    hasError = true;
    currentUser: User;
    collaborators: Collaborator[];
    calibration: Calibration;
    hasCalibrationStarted = false;

    constructor(public dialogRef: MatDialogRef<CreateScenarioComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { scenarios: Array<Scenario>; scenarioGroups: Array<ScenarioGroup>; scenarioId: string; modelRunId: string; projectId: number, calibration: Calibration },
                private scenarioService: ScenarioService,
                private scenarioGroupService: ScenarioGroupService,
                private snackBarService: SnackBarService,
                private uiBlockerService: UiBlockerService,
                private accessPolicyService: AccessPolicyService,
                private appConstantsService: AppConstantsService,
                private authProxyService: AuthProxyService,
                private projectService: ProjectService,
                private calibrationService: CalibrationService,
                private webMessageService: WebMessageService) {

    }

    ngOnInit(): void {
        this.scenarios = this.data.scenarios;
        this.scenarioGroupsList = this.data.scenarioGroups;
        this.selectedScenarioId = this.data.scenarioId;
        this.modelRunId = this.data.modelRunId;
        this.projectId = this.data.projectId;
        this.calibration = this.data.calibration;
        const dropDownScenarioGroups = this.scenarioGroupService.prepareScenarioGroups(this.scenarios, this.scenarioGroupsList, '');
        this.hasCalibrationStarted = this.calibrationService.started(this.calibration);
        this.referenceScenarioData = dropDownScenarioGroups.referenceScenario;
        this.scenarioGroupsData = dropDownScenarioGroups.scenarioGroupsData;
        this.loadScenarioGroupAssignment();
        this.addUnassociatedScenarioGroupToList();
        this.validations = {name: {warning: false, error: null}, description: {warning: false, error: null}};
        this.currentUser = this.authProxyService.user;
        this.collaborators = this.projectService.collaborators;
        this.webMessageService.consumeMessage(`${this.appConstantsService.CALIBRATION_MSG_GROUP}-${this.modelRunId}`).subscribe({
            next: (eventData) => {
                const responseJson = JSON.parse(eventData).data;
                if (responseJson.modelRunId === this.modelRunId) {
                    this.calibrationService.fetch(this.projectId, this.modelRunId).subscribe((calibration: Calibration) => {
                        this.calibration = calibration;
                        this.hasCalibrationStarted = this.calibrationService.started(this.calibration);
                        this.loadScenarioGroupAssignment();
                    });
                }
            }
        });
    }

    setupNewScenario(): Scenario {
        const newScenario = new Scenario();
        newScenario.projectId = this.projectId;
        newScenario.modelRunId = this.modelRunId;
        newScenario.basedOnScenarioId = this.selectedScenarioId;
        newScenario.name = this.scenarioName.trim();
        newScenario.description = this.scenarioDescription?.trim();
        return newScenario;
    }

    createScenario(): void {
        this.uiBlockerService.block();
        const selectedGroups = this.selectedScenarioGroups.map(it => it.scenarioGroup.id);
        this.scenarioService.createScenario(this.setupNewScenario(), selectedGroups).subscribe(newScenario => {
                const updateGroups = {addToGroups: selectedGroups};
                this.scenarioGroupService.manageScenarioToGroupAssignments(this.projectId, this.modelRunId, newScenario.id, updateGroups).subscribe(() => {
                    this.snackBarService.openSuccessSnackBar('Scenario created successfully!');
                    this.uiBlockerService.unblock();
                    this.scenarioService.createScenarioSuccess(newScenario.id);
                    this.dialogRef.close();
                });
            },
            err => {
                this.uiBlockerService.unblock();
                this.snackBarService.openErrorSnackBar(err.error.message);
            });
    }

    /**
     * this will load initial scenarioGroup selection details, on click on add/remove ScenarioGroup icon.
     */
    loadScenarioGroupAssignment(): void {
        this.scenarioGroupsAssignment = this.scenarioGroupsList.map(scenarioGroup => {
            return {
                scenarioGroup: scenarioGroup,
                isChecked: this.scenarioGroupService.isUnAssignedScenariosGroup(scenarioGroup),
                disabled: this.disableScenarioGroup(scenarioGroup) || this.restrictCalibrationScenarios(scenarioGroup)
            };
        });
    }

    restrictCalibrationScenarios(scenarioGroup: ScenarioGroup): boolean {
        return this.hasCalibrationStarted && this.scenarioGroupService.isCalibrationScenarioGroup(scenarioGroup);
    }

    onScenarioGroupChange(): void {
        if (this.selectedScenarioGroups.length === 0) {
            this.addUnassociatedScenarioGroupToList();
        } else if (this.selectedScenarioGroups.length > 1) {
            this.removeUnassociatedScenarioGroupFromList();
        }

        const hasCalibrationGroupSelected = this.selectedScenarioGroups.find(it => this.scenarioGroupService.isCalibrationScenarioGroup(it.scenarioGroup));

        if (hasCalibrationGroupSelected) {
            this.addCalibrationScenarioGroupToList();
            this.scenarioGroupsAssignment.forEach(scenarioGroupAssignment => {
                if (!this.scenarioGroupService.isCalibrationScenarioGroup(scenarioGroupAssignment.scenarioGroup) &&
                    !this.disableScenarioGroup(scenarioGroupAssignment.scenarioGroup)) {
                    scenarioGroupAssignment.disabled = true;
                }
            });
        } else {
            this.scenarioGroupsAssignment.forEach(scenarioGroupAssignment => {
                scenarioGroupAssignment.disabled = this.disableScenarioGroup(scenarioGroupAssignment.scenarioGroup) || this.restrictCalibrationScenarios(scenarioGroupAssignment.scenarioGroup);
            });
        }
    }

    addUnassociatedScenarioGroupToList(): void {
        const unAssociatedScenarioGroupAssignment = this.scenarioGroupsAssignment.find(scenarioGroupsAssignment => {
            return this.scenarioGroupService.isUnAssignedScenariosGroup(scenarioGroupsAssignment.scenarioGroup);
        });
        this.selectedScenarioGroups = [unAssociatedScenarioGroupAssignment];
    }

    addCalibrationScenarioGroupToList(): void {
        const calibrationScenarioGroupAssignment = this.scenarioGroupsAssignment.find(scenarioGroupsAssignment => {
            return this.scenarioGroupService.isCalibrationScenarioGroup(scenarioGroupsAssignment.scenarioGroup);
        });
        this.selectedScenarioGroups = [calibrationScenarioGroupAssignment];
    }

    removeUnassociatedScenarioGroupFromList(): void {
        this.selectedScenarioGroups = this.selectedScenarioGroups.filter(it => !this.scenarioGroupService.isUnAssignedScenariosGroup(it.scenarioGroup));
    }

    isNameDuplicate(scenarioName: string): boolean {
        const matchedScenarios = this.scenarios.filter(it => scenarioName && it.name.trim().toLowerCase() === scenarioName.trim().toLowerCase());
        return matchedScenarios.findIndex(s => {
            if(s.createdBy){
                if (this.currentUser.isInternalUser) {
                    return this.isScenarioOwnerInternal(s);
                } else {
                    return s.createdBy === this.currentUser.userId;
                }
            }
            else{
                return true;
            }
        }) !== -1;
    }

    isScenarioOwnerInternal(scenario: Scenario): boolean {
        const owner = this.collaborators.find(collaborator => scenario.createdBy === collaborator.userManagementId);
        return owner ? owner.isInternalUser : false;
    }

    validateName(scenarioName: string): void {
        if (this.isNameDuplicate(scenarioName)) {
            this.validations.name.error = 'A scenario with that name already exists. Please try another name.';
        } else if (scenarioName.length < 1 || (scenarioName.length > 0 && scenarioName.trim().length === 0)) {
            this.validations.name.error = 'This is required. Please enter a name.';
        } else if (scenarioName.length > 50) {
            this.validations.name.error = 'You have reached the allowed character limit.';
        } else {
            this.validations.name.error = null;
            this.validations.name.warning = scenarioName.length >= 25 && scenarioName.length <= 50;
        }
        this.setHasError();
    }

    validateDescription(scenarioDescription: string): void {
        if (scenarioDescription && scenarioDescription.length > 50) {
            this.validations.description.error = 'You have reached the allowed character limit.';
        } else if (scenarioDescription?.length > 0 && scenarioDescription.trim().length === 0) {
            this.validations.description.error = 'Enter valid Scenario Description.';
        } else {
            this.validations.description.error = null;
            this.validations.description.warning = scenarioDescription.length >= 25 && scenarioDescription.length <= 50;
        }
        this.setHasError();
    }

    setHasError(): void {
        this.hasError = this.validations.name.error?.length > 0 || this.validations.description.error?.length > 0;
    }

    disableScenarioGroup(scenarioGroup: ScenarioGroup): boolean {
        return !this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.addScenarioInGroup) ||
            this.scenarioGroupService.isUnAssignedScenariosGroup(scenarioGroup);
    }
}

