import {switchMap, skipWhile, take} from 'rxjs/operators';
import {HeartBeatService} from '@basesstudio/ngx-components';
import {interval, of} from 'rxjs';
import {EnvironmentService} from './environment.service';

/**
 * Heartbeat service implementation.
 */
export class HeartBeatServiceImpl implements HeartBeatService {

  constructor(private environmentService: EnvironmentService) {
  }

  /**
   * Returns heartbeat url promise.
   *
   * @returns heartbeat url
   */
  getHeartBeatURL(): string | Promise<string> {
    const env = this.environmentService.environment;
    if (env.heartbeat && env.heartbeat.enabled && env.heartbeat.url) {
      const promise = interval(1000).pipe(
        skipWhile(() => {
          return !this.environmentService.externalConfigHasLoaded ||
            !(this.environmentService.environment.heartbeat &&
              this.environmentService.environment.heartbeat.enabled &&
              this.environmentService.environment.heartbeat.url);
        }),
        take(1),
        switchMap(() => of(this.environmentService.environment.heartbeat.url))
      ).toPromise();
      return promise;
    } else {
      return null;
    }
  }
}
