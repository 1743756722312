import {Component, OnInit, Inject, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmationModalService} from '../../services/confirmation-modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {

  public confirm: EventEmitter<boolean> = new EventEmitter();
  header = '';
  body = '';
  footer = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private confirmationModalService: ConfirmationModalService) {
  }

  ngOnInit(): void {
    this.header = this.data.header;
    this.body = this.data.body;
    this.footer = this.data.footer;
  }

  onConfirm(): void {
    this.confirmationModalService.onConfirm(this.data);
    this.dialogRef.close(true);
    this.confirm.emit(true);
  }
}
