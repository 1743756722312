import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {ModelRun} from './../../../../models/model-run.model';
import {ModelRunService} from './../../../../services/model-run.service';
import {Scenario} from './../../../../models/scenario.model';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-run',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit, OnDestroy {

  modelRun: ModelRun;
  scenarios: Scenario[];
  subscriptions: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modelRunService: ModelRunService,
              public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();

    this.subscriptions.add(this.router.events.subscribe((navigationEvent) => {
      if (navigationEvent instanceof NavigationEnd) {
        if (navigationEvent.url.match(/^\/projects\/[0-9]+\/runs\/[a-zA-Z0-9]+$/)) {
          const modelRunId = this.route.snapshot.paramMap.get('modelRunId');
          this.redirectToDefaultScenario(modelRunId);
        }
      }
    }));
    this.subscriptions.add(this.route.params.subscribe({
      next: (params: Params) => {
        const modelRunId = params.modelRunId;
        this.redirectToDefaultScenario(modelRunId);
      }
    }));
  }

  redirectToDefaultScenario(modelRunId: string): void {
    this.modelRun = this.modelRunService.getModelRun(modelRunId);
    if (this.modelRun) {
      this.modelRunService.onRunIdChangedSubject.next(modelRunId);
      this.modelRunService.activeModelRun = this.modelRun;
      if (this.route.firstChild) {
        const path = this.route.firstChild.snapshot.routeConfig.path;
        this.router.navigate([path], {relativeTo: this.route});
      } else {
        this.router.navigate(['scenarios'], {relativeTo: this.route});
      }
    } else {
      this.router.navigate(['/404']);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
