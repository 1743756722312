import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {User} from '@app/models/user.model';
import {ModelCacheUtil} from '@app/utils/model-cache-util';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    cache: ModelCacheUtil<User>;

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
        this.cache = new ModelCacheUtil<User>();
    }

    getUser(userManagementId: string): Observable<User> {
        const user = this.cache.find(it => it.id === userManagementId);
        if (user) {
            return of(user);
        } else {
            return this.fetchUser(userManagementId);
        }
    }

    fetchUser(userManagementId: string): Observable<User> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/users/${userManagementId}`;
        return this.http.get<User>(url).pipe(map((userData) => {
            userData.fullName = `${userData.firstName} ${userData.lastName}`;
            this.cache.append(userData);
            return userData;
        }));
    }
}
