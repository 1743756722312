import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {UtilService} from '../services/util.service';
import {ModelRunService} from '../services/model-run.service';

@Injectable()
export class SyncedModelRunGuard implements CanActivate {

    constructor(private utilService: UtilService,
                private modelRunService: ModelRunService) {
    }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            const projectId = +this.utilService.getRouteParameter(route, 'projectId');
            const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
            return this.modelRunService.getById(null, {projectId, modelRunId}).subscribe((modelRun) => {
                resolve(!!modelRun.runId);
            });
        });
    }
}
