import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {CalibrationComponent} from "./calibration.component";

@Injectable()
export class CalibrationDeactivateGuard implements CanDeactivate<CalibrationComponent> {

  canDeactivate(component): Promise<boolean> {
    return component.canDeactivate();
  }
}
