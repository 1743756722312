import {Component, Input} from '@angular/core';

/**
 * @example
 * `DefaultDropdownComponent
 * <app-ns-default-dropdown></ns-default-dropdown>`
 */
@Component({
  selector: 'app-ns-default-dropdown',
  templateUrl: './default-dropdown.component.html',
  styleUrls: ['./default-dropdown.component.scss']
})

export class DefaultDropdownComponent {

  @Input() label: string;
  @Input() isDisable = false;
  @Input() contentTitle: string;

}
