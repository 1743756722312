import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {AppConstantsService} from '../services/app-constants.service';
import {UtilService} from './util.service';
import {SimulationRunInput} from '../models/simulation-run-input.model';
import {BulkEditInput} from '../interfaces/bulk-edit-input.interface';

@Injectable({
  providedIn: 'root'
})
export class SimulationRunInputService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private appConstantsService: AppConstantsService,
    private utilService: UtilService) {
  }

  fetchAll(projectId: number, modelRunId: string, scenarioId: string): Observable<Array<SimulationRunInput>> {
    const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, `scenarios/${scenarioId}/inputs`);
    return this.http.get<Array<SimulationRunInput>>(url).pipe(map((inputs: Array<SimulationRunInput>) => {
      return inputs;
    }));
  }

  bulkEditInput(projectId: number, id: string, scenarioId: string, filteredSkuIds: Array<number>, data: BulkEditInput): Observable<Array<SimulationRunInput>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${id}/scenarios/${scenarioId}/resetInput`;
    return this.http.put<Array<SimulationRunInput>>(url, {filteredSkuIds, data});
  }
}
