import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DropdownComponent} from './dropdown.component';
import {DefaultDropdownModule} from '../default-dropdown/default-dropdown.module';

import {FormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    DropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatListModule,
    MatRadioModule,
    DefaultDropdownModule
  ],
  exports: [DropdownComponent],
  providers: [],
  bootstrap: [DropdownComponent]
})
export class DropdownModule {
}
