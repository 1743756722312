import {Pipe, PipeTransform} from '@angular/core';
import {SkuGroupService} from '../services/sku-group.service';
import {SkuGroup} from '../models/sku-group.model';

@Pipe({
  name: 'getGroupName',
  pure: true
})
export class GetGroupNamePipe implements PipeTransform {

  private name = '';

  constructor(private service: SkuGroupService) {
  }

  transform(skuGroupId: number, projectId?: number, modelRunId?: string): string {
    if (skuGroupId === -1) {
      this.name = 'Item Level';
    } else {
      this.service.getById(skuGroupId, {projectId, modelRunId}).subscribe((skuGroup: SkuGroup) => {
        this.name = skuGroup.name;
      });
    }
    return this.name;
  }
}
