import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ScenarioGroup} from '../models/scenario-group.model';
import {EnvironmentService} from './environment.service';
import {HttpClient} from '@angular/common/http';
import {ModelRunService} from '../services/model-run.service';
import {Scenario} from '../models/scenario.model';
import {ScenarioService} from '../services/scenario.service';
import {AppConstantsService} from '../services/app-constants.service';
import {UserConfigurationsService} from '../services/user-configurations.service';
import {UserConfigurations} from '../models/user-configurations.model';
import {ScenarioGroupFilterData} from '../interfaces/scenario-group-filter-data.interface';
import {UtilService} from '../services/util.service';

@Injectable({
    providedIn: 'root'
})
export class ScenarioGroupService {

    private cachedScenarioGroups: Array<ScenarioGroup> = [];
    private createScenarioGroupEvent$: Subject<any> = new Subject<any>();
    private deleteScenarioGroup$: Subject<any> = new Subject<any>();
    private scenarioGroupsReOrderSubject$: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private modelRunService: ModelRunService,
                private utilService: UtilService,
                private scenarioService: ScenarioService, private appConstantsService: AppConstantsService,
                private userConfigurationsService: UserConfigurationsService) {
    }

    createScenarioGroupSuccess(data: any): void {
        this.createScenarioGroupEvent$.next(data);
    }

    onCreateScenarioGroup(): Observable<any> {
        return this.createScenarioGroupEvent$.asObservable();
    }

    get deleteScenarioGroupSubject(): Subject<any> {
        return this.deleteScenarioGroup$;
    }

    get scenarioGroupsReOrderSubject(): Subject<any> {
        return this.scenarioGroupsReOrderSubject$;
    }

    get scenarioGroups(): Array<ScenarioGroup> {
        const scenarioGroups = [];
        if (this.scenarioGroupsOrder?.id) {
            this.scenarioGroupsOrder.configurations.scenarioGroupIds.forEach(scenarioGroupId => {
                const scenarioGroup = this.cachedScenarioGroups.find(scenarioGroup => {
                    return scenarioGroup.id === scenarioGroupId;
                });
                if (scenarioGroup) {
                    scenarioGroups.push(scenarioGroup);
                }
            });
            return this.moveUnAssociatedScenariosToBottom(scenarioGroups);
        }
        return this.moveUnAssociatedScenariosToBottom(this.cachedScenarioGroups);
    }

    get scenarioGroupsOrder(): UserConfigurations {
        const configType = this.appConstantsService.scenarioGroupOrderConfigTye;
        const scenarioGroupOrder = this.userConfigurationsService.getUserConfigurationsByType(configType);
        return scenarioGroupOrder;
    }

    get scenariosOrder(): UserConfigurations {
        const configType = this.appConstantsService.scenarioOrderConfigTye;
        return this.userConfigurationsService.getUserConfigurationsByType(configType);
    }

    scenariosOrderForGroup(scenarioGroup: ScenarioGroup): any {
        return this.scenariosOrder && this.scenariosOrder.configurations[scenarioGroup.id] ? this.scenariosOrder.configurations[scenarioGroup.id] : scenarioGroup.assignedScenarios;
    }

    /**
     * Lists all scenario groups associated with given project and run id.
     */
    getAll(projectId: number, modelRunId: string): Observable<Array<ScenarioGroup>> {
        const data = this.cachedScenarioGroups.filter((it: ScenarioGroup) => {
            return it.projectId === projectId && it.modelRunId === modelRunId;
        });
        if (data.length) {
            return of(data);
        } else {
            return this.fetchAll(projectId, modelRunId);
        }
    }

    fetchAll(projectId: number, modelRunId: string): Observable<Array<ScenarioGroup>> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarioGroups`;
        return this.http.get<Array<ScenarioGroup>>(url).pipe(map((scenarioGroups: ScenarioGroup[]) => {
            this.cachedScenarioGroups = scenarioGroups;
            return scenarioGroups;
        }));
    }

    getScenarioGroups(modelRunId: string): Observable<Array<ScenarioGroup>> {
        const modelRun = this.modelRunService.getModelRun(modelRunId);
        if (modelRun) {
            const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, 'scenarioGroups')}`;
            return this.http.get<Array<ScenarioGroup>>(url).pipe(map((scenarioGroups: ScenarioGroup[]) => {
                    this.cachedScenarioGroups = scenarioGroups;
                    return scenarioGroups;
                }
            ));
        } else {
            return null;
        }
    }

    createScenarioGroup(scenarioGroup: ScenarioGroup): Observable<ScenarioGroup> {
        const modelRun = this.modelRunService.getModelRun(scenarioGroup.modelRunId);
        if (modelRun) {
            const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, 'scenarioGroups')}`;
            return this.http.post<any>(url, {scenarioGroup});
        } else {
            return null;
        }
    }

    /**
     * updates scenarioGroup name, collaborators
     */
    updateScenarioGroup(modelRunId: string, scenarioGroup: any): Observable<ScenarioGroup> {
        const modelRun = this.modelRunService.getModelRun(modelRunId);
        if (modelRun) {
            const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, 'scenarioGroups')}`;
            return this.http.put<any>(url, {scenarioGroup});
        } else {
            return null;
        }
    }

    /**
     * updates scenarioGroup name, collaborators
     */
    manageScenarios(modelRunId: string, scenarioGroupId: any, scenarioChanges: any): Observable<ScenarioGroup> {
        const modelRun = this.modelRunService.getModelRun(modelRunId);
        if (modelRun) {
            const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, `scenarioGroup/${scenarioGroupId}/manageScenarios`)}`;
            return this.http.put<any>(url, {scenarioChanges});
        } else {
            return null;
        }
    }

    manageScenarioToGroupAssignments(projectId: number, modelRunId: string, scenarioId: string, groupChanges: Record<string, Array<string>>): Observable<ScenarioGroup> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/scenarios/${scenarioId}/manageScenarioGroups`;
        return this.http.put<any>(url, {groupChanges});
    }

    /**
     * delete will delete scenario Group
     */
    delete(scenarioGroup: any): Observable<ScenarioGroup> {
        const modelRun = this.modelRunService.getModelRun(scenarioGroup.modelRunId);
        const env = this.environmentService.environment.authProxy;
        const options = {
            headers: null,
            body: {scenarioGroup},
        };
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${modelRun.projectId}/runs/${modelRun.id}/scenarioGroups`;
        return this.http.delete<any>(url, options);
    }

  /**
   * Returns true if scenarioGroup is un-associated scenario group
   */
  isUnAssignedScenariosGroup(scenarioGroup: {name: string}): boolean {
    return scenarioGroup && this.isUnAssignedScenariosGroupName(scenarioGroup.name);
  }

  isCalibrationScenarioGroup(scenarioGroup: {name: string}): boolean {
    return scenarioGroup && scenarioGroup.name.toUpperCase() === this.appConstantsService.CALIBRATION_SCENARIOS_FOLDER_NAME;
  }

  /**
   * Returns true if scenarioGroupName is un-associated scenario group
   */
  isUnAssignedScenariosGroupName(scenarioGroupName: string): boolean {
    return scenarioGroupName && scenarioGroupName.toUpperCase() === this.appConstantsService.UNASSIGNED_SCENARIOS_GROUP_NAME;
  }

  findUnAssignedScenariosGroup(scenarioGroups: ScenarioGroup[]): ScenarioGroup {
    return scenarioGroups.find(it => this.isUnAssignedScenariosGroup(it));
  }

  getCalibrationScenariosGroup(scenarioGroups: ScenarioGroup[]): ScenarioGroup {
      return scenarioGroups.find(scenarioGroup => {
        return this.isCalibrationScenarioGroup(scenarioGroup);
      });
  }

  /**
   * prepares scenario groups and scenarios to provide to template.
   */
  prepareScenarioGroups(scenarios: Scenario[],
                        scenarioGroups: ScenarioGroup[],
                        filterString: string,
                        disableScenarioIds: string[] = [], multiSelectDropDownData: any = []): ScenarioGroupFilterData {
    let scenarioGroupsDetails = [];
    let referenceScenarioData = null;
    const referenceScenario = scenarios.find((scenario: Scenario) => {
      return scenario.name.toLowerCase() === this.appConstantsService.REFERENCE_SCENARIO_NAME.toLowerCase();
    });

    if (referenceScenario) {
      referenceScenarioData = {
        scenario: referenceScenario,
        isVisible: !filterString || referenceScenario.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1,
        disabled: referenceScenario && disableScenarioIds.indexOf(referenceScenario.id) !== -1
      };
    }

    const selectedScenarioIds = multiSelectDropDownData.map((dropDownData) => {
      return dropDownData.scenarioId;
    });
    scenarioGroups.forEach(scenarioGroup => {
      const scenariosList = [];
      let scenariosVisible = 0;
      this.scenariosOrderForGroup(scenarioGroup).forEach(scenarioId => {
        const scenario = scenarios.find(it => it.id === scenarioId);
        if (scenario) {
          let disabled = disableScenarioIds.indexOf(scenario.id) !== -1;
          if (!disabled && selectedScenarioIds && selectedScenarioIds.indexOf(scenario.id) !== -1) {
            disabled = multiSelectDropDownData.findIndex((dropDownData) => {
              return dropDownData.scenarioId === scenario.id &&
                dropDownData.scenarioGroupId === scenarioGroup.id;
            }) === -1;
          }
          const isVisible = !filterString || scenario.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1;
          if (isVisible) {
            scenariosVisible++;
          }
          scenariosList.push({isVisible, disabled, scenario});
        }
      });
      scenarioGroupsDetails.push({
        id: scenarioGroup.id,
        name: scenarioGroup.name,
        scenarios: scenariosList,
        isVisible: (scenariosVisible > 0 || scenarioGroup.name.toLowerCase().indexOf(filterString.toLowerCase()) > -1)
      });
    });
    scenarioGroupsDetails = this.moveUnAssociatedScenariosToBottom(scenarioGroupsDetails);
    return {
      referenceScenario: referenceScenarioData,
      scenarioGroupsData: scenarioGroupsDetails
    };
  }

    /**
     * moves the UnAssociated Scenario Group to bottom
     */
    private moveUnAssociatedScenariosToBottom(groupsDetails: ScenarioGroup[]): ScenarioGroup[] {
        if (groupsDetails.length) {
            const unAssociatedScenarioIndex = groupsDetails.findIndex((scenarioGroup: ScenarioGroup, index: number) => {
                return this.isUnAssignedScenariosGroup(scenarioGroup);
            });
            if (unAssociatedScenarioIndex || unAssociatedScenarioIndex === 0) {
                const unAssociatedScenario = groupsDetails[unAssociatedScenarioIndex];
                groupsDetails.splice(unAssociatedScenarioIndex, 1);
                groupsDetails.push(unAssociatedScenario);
            }
        }
        return groupsDetails;
    }

    findScenarioGroupByScenarioId(scenarioId: string): string {
        const scenarioGroup = this.scenarioGroups.find((sg: ScenarioGroup) => {
            return sg.assignedScenarios.indexOf(scenarioId) !== -1;
        });
        return scenarioGroup ? scenarioGroup.id : '';
    }
}
