<ng-template [ngIf]="runsAvailable" [ngIfElse]="noRunsAvailable">
  <mat-tab-group class="model-runs" [selectedIndex]="selectedRunIndex" (selectedTabChange)="changeRun($event.index)">
    <mat-tab *ngFor="let run of runs" [label]="run.displayName"></mat-tab>
  </mat-tab-group>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #noRunsAvailable>
  <app-empty-state
    [size]="'medium'"
    [icon]="'sif-simulator'"
    [iconColor]="'primary'"
    [title]="'AWAITING FOR SIMULATOR RUN DATA'"
    [message]="'You will receive a notification once our team completes running the Analytics and the Simulator is ready'">
  </app-empty-state>
</ng-template>

