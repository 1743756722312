<div class="mat-card">
    <h3 class="group-items-header">
        Create Item Characteristics
        <button mat-icon-button aria-label="Close" (click)="onGroupItemClose()"><span class="sif sif-close"></span>
        </button>
    </h3>
    <div class="group-items-body">
        <mat-form-field appearance="standard">
            <label id="group-set-label">Characteristic Name</label>
            <mat-select class="mat-select" [(value)]="selectedSkuGroupId" disableOptionCentering
                        panelClass="group-set-selection" (selectionChange)="onSkuGroupToggle($event.value)">
                <mat-option class="mat-option" [value]="0">
                    Select One
                </mat-option>
                <div *ngFor="let skuGroup of skuGroups">
                    <mat-option class="mat-option" *ngIf="skuGroup.showInSimulator" [value]="skuGroup.skuGroupId">
                        {{skuGroup.displayName}}
                        <div class="opt-btn-group">
                            <button mat-icon-button aria-label="Duplicate" (click)="cloneGroupSet(skuGroup);"><span class="sif sif-copy" title="Duplicate"></span></button>
                            <button mat-icon-button aria-label="Edit"  title="Edit" (click)="editGroupSet(skuGroup);"><span class="sif sif-edit"></span></button>
                            <button mat-icon-button aria-label="Delete" title="Delete" (click)="deleteGroupSet(skuGroup);"><span class="sif sif-delete"></span></button>
                        </div>
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <div class="flex">
            <button [disabled]="addCharacteristic" class="add-group-btn" (click)="onClickAddCharacteristic();"
                    mat-button color="primary"><span class="sif sif-add"></span> CHARACTERISTIC
            </button>
        </div>
        <div *ngIf="selectedSkuGroupId===0">
            <p class="primary-text">Click ‘+ CHARACTERISTIC’ to create a new characteristic.</p>
        </div>
        <div *ngIf="skuGroups && !skuGroups.length">
            <p class="primary-text">There are currently no CHARACTERISTIC <span class="sif sif-add sif-11"></span>
                CHARACTERISTIC ' to begin adding CHARACTERISTICS.</p>
        </div>
        <div class="well" *ngIf="addCharacteristic">
            <mat-form-field appearance="standard">
                <label>Characteristic Name</label>
                <input matInput
                       #newSkuGroupName
                       class="mat-input-element"
                       [ngClass]="{'warning':validations.name.warning,'error':validations.name.error}"
                       placeholder="Enter Characteristic Name"
                       id="skuGroupName"
                       [(ngModel)]="itemCharacteristicName"
                       (keyup)="validateName(itemCharacteristicName)">
                <div class="flex mat-error-div">
                    <mat-error *ngIf="validations.name.error">
                        {{validations.name.error}}
                    </mat-error>
                    <mat-hint>{{newSkuGroupName.value.length}} / 25</mat-hint>
                </div>
            </mat-form-field>
            <div class="button-group">
                <button mat-button class="cancel-btn" (click)="onCancelAddCharacteristic()">Cancel</button>
                <button mat-flat-button [disabled]="hasError || !newSkuGroupName.value.length"
                        (click)="onSaveCharacteristic()" color="primary">Save
                </button>
            </div>
        </div>

        <div class="well" *ngIf="cloneCharacteristic">
            <mat-form-field appearance="standard">
                <label>Characteristic Name</label>
                <input matInput
                       #duplicatedSkuGroupName
                       class="mat-input-element"
                       [ngClass]="{'warning':validations.name.warning,'error':validations.name.error}"
                       placeholder="Enter Characteristic Name"
                       id="skuGroupName"
                       [(ngModel)]="itemCharacteristicName"
                       (keyup)="validateName(itemCharacteristicName)">
                <div class="flex mat-error-div">
                    <mat-error *ngIf="validations.name.error">
                        {{validations.name.error}}
                    </mat-error>
                    <mat-hint>{{duplicatedSkuGroupName.value.length}} / 25</mat-hint>
                </div>
            </mat-form-field>
            <div class="button-group">
                <button mat-button class="cancel-btn" (click)="onCancelAddCharacteristic()">Cancel</button>
                <button mat-flat-button [disabled]="hasError || !duplicatedSkuGroupName.value.length"
                        (click)="onSaveOfDuplicateCharacteristic()" color="primary">Save
                </button>
            </div>
        </div>


        <h4 *ngIf="!showNoBrandInfo && selectedSkuGroupId>0" class="sub-heading">ITEM CHARACTERISTICS GROUPINGS</h4>
        <div *ngIf="activeSkuGroup && showNoBrandInfo && selectedSkuGroupId>0">
            <p class="primary-text">There are currently no groupings in {{activeSkuGroup.name}}. Click <span
                    class="sif sif-add sif-11"></span> ITEM CHARACTERISTIC GROUPING to begin adding groupings.</p>
        </div>
        <div>
            <p class="help-text">Note: All ungrouped items will be put into "All Other"</p>
        </div>
        <button [disabled]="addItemCharacteristicGrouping" *ngIf="selectedSkuGroupId>0" class="add-group-btn" mat-button
                color="primary" (click)="onClickAddItemCharacteristicGrouping()"><span class="sif sif-add"></span> ITEM
            CHARACTERISTIC GROUPING
        </button>
        <div class="well" *ngIf="addItemCharacteristicGrouping">
            <mat-form-field appearance="standard">
                <label>Item Characteristic Grouping Name</label>
                <input matInput
                       #newItemCharacteristicGroupingName
                       class="mat-input-element"
                       [ngClass]="{'warning':validations.groupingName.warning,'error':validations.groupingName.error}"
                       placeholder="Enter Item Characteristic Grouping Name"
                       id="addItemCharacteristicGroupingName"
                       [(ngModel)]="newItemCharacteristicGroupName"
                       (keyup)="validateItemCharacteristicGroupingName(newItemCharacteristicGroupName)"
                >
                <div class="flex mat-error-div">
                    <mat-error *ngIf="validations.groupingName.error">
                        {{validations.groupingName.error}}
                    </mat-error>
                    <mat-hint align="end">{{newItemCharacteristicGroupingName.value.length}} / 50</mat-hint>
                </div>
            </mat-form-field>
            <div class="button-group">
                <button mat-button class="cancel-btn" (click)="onCancelAddCharacteristic()">Cancel</button>
                <button mat-flat-button [disabled]="hasError || !newItemCharacteristicGroupName.length" color="primary"
                        (click)="onSaveCharacteristicGrouping(newItemCharacteristicGroupName)">Save
                </button>
            </div>
        </div>
        <div *ngIf="activeSkuGroup" id="sku-groups-list" class="drag-list item-group-list" cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let itemGrouping of activeSkuGroup.itemGroupings">
                <a *ngIf="itemGrouping.displayName!=='All Other'" cdkDrag class="drag-box">
                    <div [ngClass]="activeItemGrouping.displayName === itemGrouping.displayName ? 'ellipsis  selected-div item-grouping' : 'ellipsis item-grouping'"
                         title="{{itemGrouping.displayName}}" (click)="selectItemGrouping(itemGrouping)"><span
                            class="sif sif-drag-handler" cdkDragHandle></span>{{itemGrouping.displayName}}
                        <button *ngIf="activeItemGrouping.displayName === itemGrouping.displayName" class="menu-btn" mat-button
                                [ngClass]="t.menuOpen ? 'active':'inActive'" #t="matMenuTrigger"
                                [matMenuTriggerFor]="menu"><span class="sif sif-ellipsis-vertical"></span></button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button mat-menu-item class="hide-options" disabled>Add Selection to Group</button>
                            <button mat-menu-item class="edit-item-grouping" (click)="editItemGrouping(itemGrouping)">Edit</button>
                            <button mat-menu-item class="delete-item-grouping" (click)="deleteItemGroupingSet(itemGrouping)">Delete</button>
                        </mat-menu>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="group-items-footer">
        <button mat-button [disabled]="hasNoChangesToSave" (click)="applyChanges(false)">Apply Changes</button>
        <button [disabled]="hasNoChangesToSave" id="save-close-groups" mat-flat-button color="primary"
                (click)="applyChanges(true)">Save & Close
        </button>
    </div>
</div>
