import {AfterViewInit, Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {HotTableRegisterer} from '@handsontable/angular';
import Handsontable from 'handsontable';
import {roundTo} from '../../utils/sku-config.validator';
import {Subject, Subscription} from 'rxjs';
import {ScenarioService} from '../../services/scenario.service';
import {EnvironmentService} from '../../services/environment.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SkuGroup} from '../../models/sku-group.model';
import {UserConfigurations} from '../../models/user-configurations.model';
import {UserConfigurationsService} from '../../services/user-configurations.service';
import {AppConstantsService} from '../../services/app-constants.service';
import {isEmpty} from '@app/utils/object-utils';
import {SkuConfigTableCellRenderers} from '../../utils/sku-config-table-cell-renderers';
import {ColumnChooserComponent} from '@app/components/column-chooser/column-chooser.component';
import {GeneralSetting} from '@app/models/general-setting.model';
import {MetaDataService} from "@app/services/meta-data.service";
import {MetaData} from "@app/models/meta-data.model";

@Component({
    selector: 'app-compare-sku-config-table',
    templateUrl: './compare-sku-config-table.component.html',
    styleUrls: ['./compare-sku-config-table.component.scss'],
})
export class CompareSkuConfigTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() hotTableData: any;
    @Input() compareTableSettings: any;
    @Input() scenarioId: string;
    @Input() outPutFieldsTotal: any;
    @Input() metaData: any;
    @Input() dropdownNotifier: Subject<any>;
    @Input() skuGroups: Array<SkuGroup>;
    @Input() compareScenarioIds: any;
    @Input() baseScenarioId: any;
    @Input() generalSetting: GeneralSetting;
    @Input() cellRenderers: SkuConfigTableCellRenderers;
    @Output() activeGroupClick = new EventEmitter();
    @Output() columnChooserClick = new EventEmitter();
    @Input() hasUnSelectedNonSampleFilter: boolean;

    subscriptions: Subscription;
    hotTableInstance: any;
    hotTableId = 'skuConfigTable';
    hotTableSettings: Handsontable.GridSettings = {};
    columnHeadersTooltips = [];
    invalidCells = new Map(); // holds invalid hot table cells
    errorMessages: string[] = [];
    activeGroup: any;
    activeBrandsList: string[] = [];
    groupsHierarchy: Array<any> = [];
    onChangeScenarioSubscription: Subscription;
    filterState: any = [];
    allFilteredRows: any = [];
    resetOutputs: boolean;
    dialogRef: any;
    compareSkuConfigColumnChooser: UserConfigurations;
    skuConfigColumnChooser: UserConfigurations;
    compareTableSettingsWithColumnChooser: any = {
        visibleColumns: [],
        visibleColumnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [],
        groupingsStartAtIndex: 0,
        inputsStartAtIndex: 0,
        outputsStartAtIndex: 0
    };
    brandsSelected: any;
    isAllSkusSelectedState: number;
    selectedSkuCount: number;
    currentCollapsedColumns: any;
    nestedHeaders: any;

    constructor(private hotTableRegisterer: HotTableRegisterer,
                private scenarioService: ScenarioService,
                private environmentService: EnvironmentService,
                private dialog: MatDialog,
                private userConfigurationsService: UserConfigurationsService,
                private appConstantsService: AppConstantsService,
                private metaDataService: MetaDataService) {
    }

    ngOnInit(): void {
        this.brandsSelected = [];
        this.activeGroup = '';
        this.subscriptions = new Subscription();
        this.currentCollapsedColumns = [];
        this.onChangeScenarioSubscription = this.dropdownNotifier.subscribe((data) => {
            this.nestedHeaders = [];
            this.compareTableSettings.columns = data.compareTableSettings.columns;
            this.compareTableSettings.columnHeaders = data.compareTableSettings.columnHeaders;
            this.hotTableData = data.hotData;
            this.skuGroups = data.skuGroups;
            this.compareScenarioIds = data.compareScenarioIds;
            this.baseScenarioId = data.baseScenarioId;
            this.outPutFieldsTotal = data.outPutFieldsTotal;
            this.loadHotTable();
        });
    }

    ngAfterViewInit(): void {
        this.loadHotTable();
    }

    ngOnDestroy(): void {
        this.onChangeScenarioSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    loadHotTable(): void {
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        this.groupsHierarchy = [];
        this.activeBrandsList = [];
        this.compareTableSettingsWithColumnChooser.allowFiltersForHeaders = [];
        this.compareTableSettingsWithColumnChooser.visibleColumns = [];
        this.compareTableSettingsWithColumnChooser.visibleColumnHeaders = [];
        this.compareTableSettingsWithColumnChooser.groupHeaders = this.compareTableSettings.groupHeaders;
        this.compareTableSettingsWithColumnChooser.subHeaders = this.compareTableSettings.subHeaders;
        this.compareTableSettingsWithColumnChooser.inputsStartAtIndex = this.compareTableSettings.inputsColumnStartIndex;
        this.compareTableSettingsWithColumnChooser.outputsStartAtIndex = this.compareTableSettings.outPutColumnStartIndex;
        this.applyUserConfigurationsForSkuConfigTable();
        const hasActiveGroupIsHidden = this.skuConfigColumnChooser.configurations['hideGroups'].find(h => {
            return this.activeGroup && h.displayName === this.activeGroup.displayName && !h.showInRow;
        });
        if (hasActiveGroupIsHidden) {
            this.activeGroup = '';
            this.brandsSelected = [];
        }
        if (this.hotTableInstance) {
            if (this.skuGroups && this.skuGroups.length) {
                let index = 0;
                this.skuGroups.forEach((skuGroup) => {
                    const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
                        return c.name === skuGroup.name;
                    });
                    if (!groupChooser || groupChooser.showInRow) {
                        this.activeGroup = !this.activeGroup && index === 0 ? skuGroup : this.activeGroup;
                        index++;
                        this.groupsHierarchy[(skuGroup.skuGroupId)] = [];
                        this.groupsHierarchy[skuGroup.skuGroupId] = this.sortOnDisplayOrder(skuGroup).map(sg => {
                            return sg.displayName;
                        });
                    }
                });
                if (this.activeGroup) {
                    this.activeGroupClick.emit(this.activeGroup);
                    this.activeBrandsList = this.activeGroup.itemGroupings.map(sg => {
                        return sg.displayName;
                    });
                    this.constructGroupsHotData();
                } else {
                    this.hotTableData.map((hotObj) => {
                        hotObj['hasActiveGroup'] = false;
                        return hotObj;
                    });
                    this.activeBrandsList = [];
                    this.hotTableData = this.scenarioService.getSkuConfigsInDisplayOrder(this.hotTableData);
                }
            }
            this.hotTableInstance.loadData(this.hotTableData);
            this.generateTable(this.hotTableData, this.hotTableInstance);
            this.insertPsShareNotaValueAtLastRow();
            if (this.skuGroups.length && this.activeGroup) {
                this.showOnlyActiveGroupBrands();
            }
            this.hotTableInstance.render();
        }
    }

    sortOnDisplayOrder(skuGroup): Array<any> {
        const itemGroupingsFinal = [];
        skuGroup.itemGroupings.sort((itemGrouping1, itemGrouping2) => {
            return itemGrouping1.displayOrder - itemGrouping2.displayOrder;
        });
        skuGroup.itemGroupings.forEach(iGrouping => {
            if (iGrouping.name === 'All Other') {
                const skuWithAllOther = this.hotTableData.find(sku => {
                    return (sku.skuId && !sku.groups) || sku.groups && sku.groups.find(sg => {
                        return sg.itemGroupingId === iGrouping.itemGroupingId
                            && skuGroup.skuGroupId === sg.skuGroupId;
                    });
                });
                if (skuWithAllOther) {
                    itemGroupingsFinal.push(iGrouping);
                }
            } else {
                itemGroupingsFinal.push(iGrouping);
            }
        });
        return itemGroupingsFinal;
    }

    /**
     *   this method handles default state of the skuGroups and its brands
     */
    showOnlyActiveGroupBrands(): void {
        const rowCount = this.hotTableInstance.countRows();
        const rowsToHide = [];
        const plugin = this.hotTableInstance.getPlugin('hiddenRows');
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            const cellMeta = this.hotTableInstance.getCellMeta(i, 2);
            let paddingClassName = '';
            if (rowData.isGroupLabel || rowData.isBrand) {
                paddingClassName = rowData.isGroupLabel ? 'padding-left-1' : 'padding-left-3-point-4';
                cellMeta.className = rowData.isSelected ? `ellipsis nota-cell htLeft ${paddingClassName} sif-13 sif sif-chevron-s` : `ellipsis  nota-cell htLeft ${paddingClassName} sif-13 sif sif-chevron-e`;
                for (let j = 0; j < this.compareTableSettingsWithColumnChooser.visibleColumns.length; j++) {
                    if (j !== 2) {
                        const idColProps = this.hotTableInstance.getCellMeta(i, j);
                        const cellValue = this.hotTableInstance.getDataAtCell(i, j);
                        idColProps.className = cellValue && j !== 0 && j !== 1 ? 'htRight nota-cell' : 'hatch-cell';
                        idColProps.type = 'text';
                        idColProps.readOnly = true;
                        idColProps.disableVisualSelection = idColProps.className === 'hatch-cell';
                        this.hotTableInstance.setCellMeta(i, j, idColProps);
                    }
                }
            } else {
                cellMeta.className = 'ellipsis padding-left-4-point-6';
            }
            this.hotTableInstance.setCellMeta(i, 2, cellMeta);
            if (!rowData.isGroupLabel && (rowData.groupLabel !== this.activeGroup.displayName && rowData.reportingName !== 'NOTA')) {
                const brandRow = this.hotTableData.find((h) => {
                    return h.brandName === rowData.brandName && h.groupId === rowData.groupId && h.isBrand && h.isSelected;
                });
                if (rowData.groupLabel !== this.activeGroup.displayName && !brandRow) {
                    if (rowsToHide.indexOf(i) === -1) {
                        rowsToHide.push(i);
                    }
                }
            }
        }
        plugin.hideRows(rowsToHide);
    }

    /**
     *   used to show the rows belongs to selected brands
     */
    showOnlyActiveGroupItems(isActiveBrand = false): Array<number> {
        const rowsToShow = [];
        this.allFilteredRows.forEach(i => {
            let rowOpen = 0;
            const rData = this.hotTableInstance.getSourceDataAtRow(i);
            rowOpen = this.hotTableInstance.getSourceData().filter((r) => {
                return rData.brandName === r.brandName && (isActiveBrand || r.isSelected) && r.isBrand;
            }).length;
            if (rowOpen) {
                rowsToShow.push(i);
            }
        });
        return rowsToShow;
    }

    /**
     *  Used to  insert NOTA row at the bottom of the sku-config table.
     */
    insertPsShareNotaValueAtLastRow(): void {
        const psShareColumnIndex = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'psShare');
        const psShareColumnIndexes = [];
        const psShareIndexColumnIndexes = [];
        if (psShareColumnIndex && psShareColumnIndex > 0 && this.metaData.outputConfigurations.psShare &&
            this.metaData.outputConfigurations.psShare.showInSimulator && this.outPutFieldsTotal.get(this.baseScenarioId) > 0) {
            psShareColumnIndexes.push(psShareColumnIndex);
            const baseScenarioPsShare = 1 - this.outPutFieldsTotal.get(this.baseScenarioId);
            const baseScenarioPsSharePercentage = 1 - (this.outPutFieldsTotal.get(this.baseScenarioId) / 100);
            this.compareScenarioIds.forEach(cId => {
                const compareScenarioShareColumnIndex = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex(
                    (column) => column.data === `psShare_${cId}`);
                if (compareScenarioShareColumnIndex) {
                    psShareColumnIndexes.push(compareScenarioShareColumnIndex);
                }
                const compareScenarioShareIndexColumnIndex = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex(
                    (column) => column.data === `psShare_${cId}_index`);
                if (compareScenarioShareIndexColumnIndex) {
                    psShareIndexColumnIndexes.push(compareScenarioShareIndexColumnIndex);
                }
            });
            this.hotTableInstance.alter('insert_row', this.hotTableInstance.countRows());
            const rowCount = this.hotTableInstance.countRows();
            const nameColumnIndex = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'reportingName');
            const promoPriceColumnIndex = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex((column) => column.name === 'promoPrice');
            for (let i = 0; i < this.compareTableSettingsWithColumnChooser.visibleColumns.length; i++) {
                const cellMeta = this.hotTableInstance.getCellMeta(rowCount - 1, i);
                cellMeta.readOnly = true;
                if (i === promoPriceColumnIndex) {
                    cellMeta.type = 'text';
                }
                if (psShareColumnIndexes.indexOf(i) >= 0) {
                    cellMeta.className = 'htRight nota-cell';
                    const psShareColumnScenarioId = this.compareTableSettingsWithColumnChooser.visibleColumns[i].data.split('_')[1];
                    this.hotTableInstance.setDataAtCell(rowCount - 1, i, this.cellRenderers.getFormattedOutputShareValue((`${1 - this.outPutFieldsTotal.get(psShareColumnScenarioId)}`)));
                } else if (psShareIndexColumnIndexes.indexOf(i) >= 0) {
                    cellMeta.className = 'htRight nota-cell';
                    this.compareScenarioIds.forEach(cId => {
                        const compareScenarioShareColumnIndex = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex(
                            (column) => column.data === `psShare_${cId}_index`);
                        if (compareScenarioShareColumnIndex === i) {
                            const compareScenarioPsShare = 1 - this.outPutFieldsTotal.get(cId);
                            this.hotTableInstance.setDataAtCell(rowCount - 1, i, this.cellRenderers.indexFormatForBrandRow(this.getIndexValue(compareScenarioPsShare, baseScenarioPsShare) / 100));
                        }
                    });
                } else if (i === nameColumnIndex) {
                    this.hotTableInstance.setDataAtCell(rowCount - 1, i, 'NOTA');
                    cellMeta.className = 'nota-cell';
                } else {
                    cellMeta.className = 'hatch-cell';
                    cellMeta.disableVisualSelection = cellMeta.readOnly;
                    this.hotTableInstance.setDataAtCell(rowCount - 1, i, '');
                }
            }
        }
    }

    sortHotTableDatBySkuId(skuConfigs): Array<any> {
        skuConfigs = skuConfigs.filter(x => x.skuId > 0);
        skuConfigs.sort((sku1, sku2) => {
            return sku1.skuId - sku2.skuId;
        });
        return skuConfigs;
    }

    /**
     *   Constructs the skuConfig data in Group(groupSet)->Brand(itemGrouping)->item(sku) hierarchy
     *   to recognise the group/brand row isGroupLabel/isBrand filed is used.
     *   each skuItem has given its group name and brand name respectively.
     *   outputs for each brand row is calculated and assign to brand row
     */
    constructGroupsHotData(): void {
        this.hotTableData = this.scenarioService.getSkuConfigsInDisplayOrder(this.hotTableData);
        const groupsHotData = [];
        this.allFilteredRows = [];
        this.filterState = [];
        if (this.activeGroup) {
            this.skuGroups.forEach((skuGroup) => {
                const groupChooser = this.skuConfigColumnChooser.configurations['hideGroups'].find((c) => {
                    return c.name === skuGroup.name;
                });
                if (!groupChooser || groupChooser.showInRow) {
                    const selectGroup = this.activeGroup.skuGroupId === skuGroup.skuGroupId;
                    const groupObj = {
                        reportingName: skuGroup.displayName,
                        isGroupLabel: true,
                        groupLabel: skuGroup.displayName,
                        isSelected: selectGroup,
                        groupId: skuGroup.skuGroupId,
                        promoPrice: null
                    };
                    groupObj[(`psShare_${this.baseScenarioId}`)] = this.outPutFieldsTotal && this.outPutFieldsTotal.get(this.baseScenarioId) && selectGroup ? `${roundTo(this.outPutFieldsTotal.get(this.baseScenarioId) * 100, 1)}%` : null;
                    this.compareScenarioIds.forEach(cId => {
                        groupObj[(`psShare_${cId}`)] = this.outPutFieldsTotal && this.outPutFieldsTotal.get(cId) && selectGroup ? `${roundTo(this.outPutFieldsTotal.get(cId) * 100, 1)}%` : null;
                        if (isEmpty(groupObj[(`psShare_${cId}`)]) || isEmpty(groupObj[(`psShare_${this.baseScenarioId}`)])) {
                            groupObj[(`psShare_${cId}_index`)] = '--';
                        } else {
                            groupObj[(`psShare_${cId}_index`)] = this.cellRenderers.indexFormatForBrandRow(this.getIndexValue(groupObj[(`psShare_${cId}`)], groupObj[(`psShare_${this.baseScenarioId}`)]) / 100);
                        }

                    });
                    groupsHotData.push(groupObj);
                    const brandsInGroup = this.groupsHierarchy[skuGroup.skuGroupId];
                    brandsInGroup.forEach((brand) => {
                        let brandRow =
                            {
                                brandName: brand,
                                reportingName: brand,
                                isBrand: true,
                                groupLabel: skuGroup.displayName,
                                isGroupLabel: false,
                                isSelected: this.brandsSelected.indexOf(brand) !== -1 || selectGroup ? 1 : 0,
                                groupId: skuGroup.skuGroupId
                            };
                        if (this.baseScenarioId) {
                            brandRow = this.setOutputsForBrandRow(brandRow, this.hotTableData, this.activeGroup, brand);
                        }
                        groupsHotData.push(brandRow);
                        if (this.activeGroup.skuGroupId === skuGroup.skuGroupId) {
                            this.hotTableData.forEach((hotObj) => {
                                if (hotObj[skuGroup.displayName] === brand) {
                                    hotObj['brandName'] = brand,
                                        hotObj['groupName'] = skuGroup.displayName;
                                    hotObj['groupId'] = skuGroup.skuGroupId;
                                    hotObj['hasActiveGroup'] = true;
                                    groupsHotData.push(hotObj);
                                }
                            });
                        }
                    });
                }
            });
            this.hotTableData = groupsHotData;
        }
    }

    createRequiredOutPutFields(brandObj, outputName, isIndexRequired, scenarioId, baseScenarioId): void {
        const key = `${outputName}_${scenarioId}`;
        const valueKey = `${outputName}_${scenarioId}_value`;
        if (outputName.substr(outputName.length - 5, outputName.length) === 'Share') {
            if(outputName === 'unitsPromoShare'){
                const unitsShare = brandObj[(`unitsShare_${scenarioId}`)] ? parseFloat(`${brandObj[(`unitsShare_${scenarioId}`)]}`) : 0 ;
                const unitsOnPromoShare = unitsShare && brandObj[(`${outputName}_${scenarioId}`)] ? (brandObj[(`${outputName}_${scenarioId}`)]/unitsShare) : 0 ;
                brandObj[(valueKey)] = brandObj[(key)] ? unitsOnPromoShare : '--';
                brandObj[(`${outputName}_${scenarioId}`)] = unitsOnPromoShare ? this.cellRenderers.getFormattedOutputShareValue(unitsOnPromoShare.toString()) : '--';
            }else{
                brandObj[(valueKey)] = brandObj[(key)] ? brandObj[(`${outputName}_${scenarioId}`)] : '--';
                brandObj[(key)] = brandObj[(key)] ? this.cellRenderers.getFormattedOutputShareValue(`${brandObj[(`${outputName}_${scenarioId}`)]}`) : '--';
            }

        } else if (outputName === 'revenue' || outputName === 'profit') {
            brandObj[(valueKey)] = brandObj[(key)] ? brandObj[(`${outputName}_${scenarioId}`)] : '--';
            brandObj[(key)] = brandObj[(key)] ? this.cellRenderers.getFormattedOutputValueForRevenue(`${brandObj[(`${outputName}_${scenarioId}`)]}`) : '--';
        } else {
            brandObj[(valueKey)] = brandObj[(key)] ? brandObj[(`${outputName}_${scenarioId}`)] : '--';
            brandObj[(key)] = brandObj[(key)] ? this.cellRenderers.getFormattedOutputValue(`${brandObj[(`${outputName}_${scenarioId}`)]}`) : '--';
        }
        if (isIndexRequired) {
            const compareKey = `${outputName}_${scenarioId}_value`;
            const baseKey = `${outputName}_${baseScenarioId}_value`;
            const indexKey = `${outputName}_${scenarioId}_index`;
            const compareValue = this.valueHasPercentageAtTheEnd(brandObj[compareKey]) ?
                brandObj[compareKey].substr(0, brandObj[compareKey].length - 1) : brandObj[compareKey];
            const baseValue = this.valueHasPercentageAtTheEnd(brandObj[baseKey]) ?
                brandObj[baseKey].substr(0, brandObj[baseKey].length - 1) : brandObj[baseKey];
            if (brandObj[baseKey] === '0.0%' || brandObj[compareKey] === '0.0%' || isEmpty(brandObj[compareKey]) || isEmpty(brandObj[baseKey]) ||
                brandObj[compareKey] <= 0 || brandObj[baseKey] <= 0) {
                brandObj[indexKey] = '--';
            } else {
                brandObj[indexKey] = this.getIndexValue(compareValue, baseValue);
            }
            if (isNaN(brandObj[indexKey])) {
                brandObj[indexKey] = '--';
            }
        }
    }

    valueHasPercentageAtTheEnd(value): boolean {
        return value && value.length > 1 && value.toString().substr(value.length - 1, value.length) === '%';
    }

    brandObjectForAllScenarios(brandObj, isIndexRequired, dataKey, scenarioId, baseScenarioId, sku): void {
        if (!brandObj[(`${dataKey}_${scenarioId}`)]) {
            brandObj[(`${dataKey}_${scenarioId}`)] = 0;
        }
        if(dataKey === 'unitsPromoShare'){
            const sumProduct = sku[(`${dataKey}_${scenarioId}`)] &&  sku[(`unitsShare_${scenarioId}`)] ? parseFloat(sku[(`${dataKey}_${scenarioId}`)]) * parseFloat( sku[(`unitsShare_${scenarioId}`)]) : 0 ;
            brandObj[(`${dataKey}_${scenarioId}`)] =  brandObj[(`${dataKey}_${scenarioId}`)] + sumProduct ;
        }else {
            brandObj[(`${dataKey}_${scenarioId}`)] = brandObj[(`${dataKey}_${scenarioId}`)] + (sku[(`${dataKey}_${scenarioId}`)] ? parseFloat(sku[(`${dataKey}_${scenarioId}`)]) : 0);
        }
        if (isIndexRequired) {
            brandObj[(`${dataKey}_${scenarioId}_index`)] = this.getIndexValue(brandObj[(`${dataKey}_${scenarioId}_index`)], brandObj[(`${dataKey}_${baseScenarioId}_index`)]);
        }
    }

    /**
     * calculates the outputs for brand row using totals provided
     * @param brandObj is to push brandRow
     * @param hotTableData is hotData to use for calculation
     * @param activeGroup is activeGroup which is operating now
     * @param brand is a brandName
     */
    setOutputsForBrandRow(brandObj, hotTableData, activeGroup, brand): any {
        const outputFields = ['units', 'unitsShare', 'unitsPromoShare', 'revenue', 'revenueShare', 'profit', 'profitShare', 'volume',
            'volumeShare', 'equivalizedVolume', 'equivalizedVolumeShare', 'psShare'];
        hotTableData.forEach((sku) => {
            const groupObj = sku.groups ? sku.groups.find((group) => {
                const itemGrouping = this.activeGroup.itemGroupings.find(sg => {
                    return sg.displayName === brand;
                });
                return itemGrouping && group.skuGroupId === this.activeGroup.skuGroupId && (group.itemGroupingId === itemGrouping.itemGroupingId);
            }) : null;
            outputFields.forEach((dataKey) => {
                if (dataKey && groupObj) {
                    this.brandObjectForAllScenarios(brandObj, false, dataKey, this.baseScenarioId, null, sku);
                    this.compareScenarioIds.forEach(cId => {
                        this.brandObjectForAllScenarios(brandObj, true, dataKey, cId, this.baseScenarioId, sku);
                    });
                }
            });
        });
        const requiredOutputs = ['units', 'unitsPromoShare', 'unitsShare' , 'revenue', 'revenueShare', 'profit', 'profitShare', 'volume',
            'volumeShare', 'equivalizedVolume', 'equivalizedVolumeShare', 'psShare'];
        requiredOutputs.forEach(output => {
            this.createRequiredOutPutFields(brandObj, output, false, this.baseScenarioId, null);
        });
        this.compareScenarioIds.forEach(sId => {
            requiredOutputs.forEach(output => {
                this.createRequiredOutPutFields(brandObj, output, true, sId, this.baseScenarioId);
            });
        });
        return brandObj;
    }

    getIndexValue(compareValue, baseValue): any {
        const cValue = this.valueHasPercentageAtTheEnd(compareValue) ?
            compareValue.substr(0, compareValue.length - 1) : compareValue;
        const bValue = this.valueHasPercentageAtTheEnd(baseValue) ?
            baseValue.substr(0, compareValue.length - 1) : baseValue;
        if (isEmpty(compareValue) || isEmpty(baseValue) || isNaN(bValue) || isNaN(cValue) || compareValue <= 0 || baseValue <= 0) {
            return '--';
        }
        return this.cellRenderers.indexFormatForBrandRow(`${(parseFloat(compareValue) / parseFloat(baseValue))}`);
    }

    getRowsByAllBrands(rowCount, isToHide = false): Array<number> {
        const resultRowIds = [];
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            if (this.activeBrandsList.indexOf(rowData.brandName) !== -1 &&
                rowData.isBrand && rowData.groupId === this.activeGroup.skuGroupId) {
                if (isToHide && rowData.isSelected) {
                    this.hotTableInstance.setDataAtCell(i, 1, false);
                    const cellMeta = this.hotTableInstance.getCellMeta(i, 2);
                    cellMeta.className = 'ellipsis nota-cell htLeft padding-left-3 sif-13 sif sif-chevron-e';
                    this.hotTableInstance.setCellMeta(i, 2, cellMeta);
                }
                resultRowIds.push(i);
            }
        }
        return resultRowIds;
    }

    getRowsByBrand(groupId, brand, rowCount): Array<number> {
        const resultRowIds = [];
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            if (rowData.groupId === groupId && rowData.brandName === brand && !rowData.isBrand
                && resultRowIds.indexOf(i) === -1) {
                resultRowIds.push(i);
            }
        }
        return resultRowIds;
    }

    getRowsByFilter(columnIndex, args): Array<number> {
        const rowCount = this.hotTableInstance.countRows();
        if (this.filterState[(1)] && this.filterState[(1)].length > 0) {
            this.allFilteredRows = this.allFilteredRows.filter(x => this.filterState[(1)].indexOf(x) < 0);
        }
        this.filterState[(1)] = [];
        const resultRowIds = [];
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.hotTableInstance.getSourceDataAtRow(i);
            if (!(rowData.isGroupLabel || rowData.isBrand) &&
                args.indexOf(rowData['reportingName']) === -1) {
                resultRowIds.push(i);
            }
        }
        this.filterState[1].push(resultRowIds);
        resultRowIds.map(r => {
            if (this.allFilteredRows.indexOf(r) === -1) {
                this.allFilteredRows.push(r);
            }
        });
        return resultRowIds;
    }

    /**
     * on each click on group/brand from reportingName column it does show and hise of corresponding rows
     * @description rowNumber which is clicked
     * @param row contains row number
     * @description column number which is clicked on
     * @param col contains column number
     */
    expandCollapseRows(row, col): void {
        const rowData = this.hotTableInstance.getSourceDataAtRow(row);
        const plugin = this.hotTableInstance.getPlugin('hiddenRows');
        const rowCount = this.hotTableInstance.countRows();
        if ((rowData.isGroupLabel || rowData.isBrand) && this.activeGroup.skuGroupId !== rowData.groupId || !rowData.isSelected) {
            this.brandsSelected = [];
            this.activeGroup = this.skuGroups.find(sGroup => sGroup.skuGroupId === rowData.groupId);
            this.activeGroupClick.emit(this.activeGroup);
            this.constructGroupsHotData();
            this.hotTableInstance.loadData(this.hotTableData);
            this.insertPsShareNotaValueAtLastRow();
            this.activeBrandsList = this.groupsHierarchy[rowData.groupId];
            this.showOnlyActiveGroupBrands();
            this.hotTableInstance.render();
            return;
        }
        if (rowData.isGroupLabel) {
            this.activeBrandsList = this.groupsHierarchy[this.activeGroup.skuGroupId];
            this.hotTableInstance.setDataAtCell(row, col - 1, !rowData.isSelected);
            let finalRowsToHide = this.getRowsByAllBrands(rowCount, true);
            if (rowData.isSelected) {
                this.activeBrandsList.forEach((brand) => {
                    finalRowsToHide = finalRowsToHide.concat(this.getRowsByBrand(rowData.groupId, brand, rowCount));
                });
                plugin.hideRows(finalRowsToHide);
            } else {
                let finalRowsToShow = this.getRowsByAllBrands(rowCount, true);
                finalRowsToShow = finalRowsToShow.filter(rowNumber => this.allFilteredRows.indexOf(rowNumber) < 0);
                plugin.showRows(finalRowsToShow);
            }
            this.hotTableInstance.render();
        } else if (rowData.isBrand) {
            this.hotTableInstance.setDataAtCell(row, col - 1, !rowData.isSelected);
            let rowsByBrand = this.getRowsByBrand(rowData.groupId, rowData.brandName, rowCount);
            if (rowData.isSelected) {
                this.brandsSelected.splice(this.brandsSelected.indexOf(rowData.brandName), 1);
                plugin.hideRows(rowsByBrand);
            } else {
                if (this.brandsSelected.indexOf(rowData.brandName) < 0) {
                    this.brandsSelected.push(rowData.brandName);
                }
                rowsByBrand = rowsByBrand.filter(rowNumber => this.allFilteredRows.indexOf(rowNumber) < 0);
                plugin.showRows(rowsByBrand);
            }
            this.hotTableInstance.render();
        }
    }

    filterAllRowsToHide(rowsToShow: Array<number>): void {
        this.allFilteredRows = this.allFilteredRows.filter(rowNumber => rowsToShow.indexOf(rowNumber) < 0);
    }

    get handsontableLicenseKey(): string {
        const handsontableConfig = this.environmentService.environment.handsontable;
        const handsontableLicenseKey = handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
        return handsontableLicenseKey;
    }

    getSelectAllCheckBoxHtml(): string {
        let htmlText = `<input type='checkbox'`;
        htmlText += `class='checker ${this.isAllSkusSelectedState === 1 ? 'checked' : (this.isAllSkusSelectedState === 0 ? 'unchecked' : 'partial')}'>`;
        htmlText += `<span class='count-txt'>${this.selectedSkuCount}</span>`;
        return htmlText;
    }

    generateTable(tableData, hotTableInstance): void {
        const self = this;
        const handsontableLicenseKey = this.handsontableLicenseKey;
        hotTableInstance.updateSettings({
            viewportColumnRenderingOffset: 27,
            viewportRowRenderingOffset: 'auto',
            allowInsertColumn: false,
            allowInsertRow: false,
            allowRemoveColumn: false,
            allowRemoveRow: false,
            autoWrapRow: false,
            autoWrapCol: false,
            manualRowResize: true,
            manualRowMove: true,
            manualColumnMove: false,
            filters: self.compareTableSettingsWithColumnChooser.allowFiltersForHeaders,
            dropdownMenu: ['filter_by_value', 'filter_action_bar'],
            colHeaders: true,
            columns: self.compareTableSettingsWithColumnChooser.visibleColumns,
            height: document.documentElement.clientHeight - 300,
            rowHeights: 34,
            manualColumnResize: false,
            data: tableData,
            licenseKey: handsontableLicenseKey,
            nestedHeaders: this.nestedHeaders,
            hiddenRows: {
                rows: [],
                indicators: false
            },
            hiddenColumns: {
                columns: [1],
                indicators: false
            },
            collapsibleColumns: [{
                row: -3,
                col: this.compareTableSettingsWithColumnChooser.inputsStartAtIndex,
                collapsible: true
            }],
            renderAllRows: true,
            fixedRowsBottom: 0,
            afterOnCellMouseDown: (event, coords, TD): void => {
                if (coords.col === 2 && coords.row >= 0) {
                    self.expandCollapseRows(coords.row, coords.col);
                } else if (event && event.target && ((event.target.firstElementChild && event.target.firstElementChild.classList.contains('sif-column-chooser')) ||
                    event.target.classList.contains('sif-column-chooser'))) {
                    self.openColumnChooserDialog(event);
                    self.hotTableInstance.deselectCell();
                }
                return;
            },
            afterGetColHeader(index, th): void {
                if (index === 1) {
                    th.innerHTML = self.getSelectAllCheckBoxHtml();
                }
                const BUTTON_CLASS_NAME = 'changeType';
                const existingButton = th.querySelector('.' + BUTTON_CLASS_NAME);
                if (!this.enabled) {
                    if (existingButton) {
                        if (Object.prototype.toString.call(this.getSettings().filters) === '[object Array]'
                            && this.getSettings().filters.indexOf(index) === -1) {
                            existingButton.parentNode.removeChild(existingButton);
                        }
                    }
                    return;
                }

            },
            afterValidate: (isValid, value, row, prop, source): void => {
                // self.afterValidate(hotTableInstance, isValid, value, row, prop, source);
            },
            /*
              Need to update the original data source because handsontable is in a different component
              whose data is passed in as an input. Since it's one way data binding, the changes
              that have been made to the table are not reflected in original data source.
              An additional logic to update the original data source is written below
            */
            afterCreateRow: (...args): void => {
                // self.updateDataSource();
            },
            afterRemoveRow: (...args): void => {
                // self.updateDataSource();
            },
            beforeColumnCollapse: (): void => {
                // self.toggleCurrentCell();
            },
            beforeColumnExpand: (): void => {
                // self.toggleCurrentCell();
            },
            afterColumnCollapse: (currentCollapsedColumns: number[], destinationCollapsedColumns: number[],
                                  collapsePossible: boolean, successfullyCollapsed: boolean) => {
                // self.currentCollapsedColumns = destinationCollapsedColumns;
            },
            afterColumnExpand: (currentCollapsedColumns: number[], destinationCollapsedColumns: number[],
                                expandPossible: boolean, successfullyExpanded: boolean) => {
                // self.currentCollapsedColumns = destinationCollapsedColumns;
            },
            beforeFilter: (conditions): boolean => {
                const plugin = self.hotTableInstance.getPlugin('hiddenRows');
                /**
                 * show rows/selected brand rows before apply new filter
                 */
                if (isEmpty(self.activeGroup)) {
                    plugin.showRows(this.allFilteredRows);
                } else {
                    const rowsToShow = this.showOnlyActiveGroupItems();
                    const rowsToRemoveFromHiddenList = this.showOnlyActiveGroupItems(true);
                    this.filterAllRowsToHide(rowsToRemoveFromHiddenList);
                    plugin.showRows(rowsToShow);
                }
                /**
                 *  get rows to hide based on new conditions and hide them using plugin
                 */
                conditions.map((filter) => {
                    if (self.filterState[1] && self.filterState[1].length > 0 &&
                        self.allFilteredRows && self.allFilteredRows.length > 0) {
                        self.allFilteredRows = self.allFilteredRows.filter(rowNumber =>
                            self.filterState[(1)].indexOf(rowNumber) < 0);
                    }
                    self.filterState[1] = [];
                    const hRows = self.getRowsByFilter(filter.column, filter.conditions[0].args[0]);
                    plugin.hideRows(hRows);
                });
                Object.keys(self.filterState).forEach((column) => {
                    const col = conditions.find(condition => condition.column === 1);
                    if (!col) {
                        this.filterAllRowsToHide(self.filterState[(1)]);
                        self.filterState[1] = [];
                    }
                });

                /**
                 * remove hidden rows on no conditions
                 */
                if (!conditions.length) {
                    self.allFilteredRows = [];
                    self.filterState = [];
                }
                self.hotTableInstance.render();
                return false;
            },
            afterChange: (changes, source): void => {
                if (!changes) {
                    return;
                }
            },
        });
    }

    makeCopyOfTableSettings(): void {
        this.compareTableSettings.columnHeaders.forEach(c => {
            this.compareTableSettingsWithColumnChooser.visibleColumnHeaders.push(c);
        });
        this.compareTableSettings.columns.forEach(c => {
            this.compareTableSettingsWithColumnChooser.visibleColumns.push(c);
        });
        this.compareTableSettingsWithColumnChooser.groupHeaders = [];
        this.compareTableSettingsWithColumnChooser.subHeaders = [];
        this.compareTableSettings.groupHeaders.forEach(g => {
            this.compareTableSettingsWithColumnChooser.groupHeaders.push({
                label: g.label,
                colspan: g.colspan,
                type: g.type
            });
        });
        this.compareTableSettings.subHeaders.forEach(g => {
            this.compareTableSettingsWithColumnChooser.subHeaders.push({
                label: g.label,
                colspan: g.colspan,
                type: g.type
            });
        });
    }

    setGroupingsColumnChooser(): void {
        const configType = this.appConstantsService.skuConfigColumnChooserConfigType;
        this.skuConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
        this.skuConfigColumnChooser = this.skuConfigColumnChooser ? this.skuConfigColumnChooser : new UserConfigurations();
        this.skuConfigColumnChooser.configurations = this.skuConfigColumnChooser.configurations ? this.skuConfigColumnChooser.configurations : {
            hideGroups: [],
            hideInputs: [],
            hideOutputs: []
        };
    }

    dualComparisonColumnChooserSetup(): void {
        const configType = this.appConstantsService.compareSkuConfigColumnChooserConfigType;
        this.compareSkuConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
        this.compareSkuConfigColumnChooser = this.compareSkuConfigColumnChooser ? this.compareSkuConfigColumnChooser : new UserConfigurations();
        this.compareSkuConfigColumnChooser.configurations = this.compareSkuConfigColumnChooser.configurations ?
            this.compareSkuConfigColumnChooser.configurations : {
                hideGroups: [],
                hideInputs: [],
                hideOutputs: []
            };
        let hiddenColumnCount = 0;
        let outputHiddenCount = 0;
        this.compareSkuConfigColumnChooser.configType = this.appConstantsService.compareSkuConfigColumnChooserConfigType;
        if (this.compareTableSettingsWithColumnChooser.inputsStartAtIndex > 0) {
            this.setInputsColumnChooser();
            hiddenColumnCount = this.removeColumnsWhichAreNotSelected(this.compareSkuConfigColumnChooser.configurations['hideInputs']);

        }

        if (this.compareTableSettingsWithColumnChooser.outputsStartAtIndex > 0) {
            this.compareTableSettingsWithColumnChooser.outputsStartAtIndex = this.compareTableSettingsWithColumnChooser.outputsStartAtIndex - hiddenColumnCount;
            this.setOutputsColumnChooser();
            outputHiddenCount = this.removeColumnsWhichAreNotSelectedInOutputs(this.compareSkuConfigColumnChooser.configurations['hideOutputs']);
        }
        this.compareTableSettingsWithColumnChooser.groupHeaders.map(h => {
            if (h.type === 'INPUTS') {
                h.colspan = h.colspan - hiddenColumnCount;
            }
        });
        this.compareTableSettingsWithColumnChooser.subHeaders.map(h => {
            if (h.type === 'INPUTS') {
                h.colspan = h.colspan - hiddenColumnCount;
            }
            if (h.type === 'RESULTS') {
                h.colspan = h.colspan - outputHiddenCount;
            }
        });
    }

    setUpMultiCompareColumnChooser(): void {
        const configType = this.appConstantsService.compareMultiSkuConfigColumnChooserConfigType;
        this.compareSkuConfigColumnChooser = this.userConfigurationsService.getUserConfigurationsByType(configType);
        this.compareSkuConfigColumnChooser = this.compareSkuConfigColumnChooser ? this.compareSkuConfigColumnChooser : new UserConfigurations();
        this.compareSkuConfigColumnChooser.configurations = this.compareSkuConfigColumnChooser.configurations ?
            this.compareSkuConfigColumnChooser.configurations : {
                hideGroups: [],
                hideInputs: [],
                hideOutputs: []
            };
        if (this.compareScenarioIds.length > 1 && (this.compareSkuConfigColumnChooser.configurations['hideOutputs'].length === 0)) {
            let firstColumnName = this.compareTableSettings.columns[this.compareTableSettings.outPutColumnStartIndex + 1].name;
            const absolutes = ['units', 'revenue', 'volume', 'equivalizedVolume', 'profit'];
            const shareFields = ['unitsShare', 'unitsPromoShare', 'revenueShare', 'equivalizedVolumeShare', 'psShare'];

            if (this.hasUnSelectedNonSampleFilter) {
                const shareField = shareFields.find(abs => this.metaData.outputConfigurations[(abs)] && this.metaData.outputConfigurations[(abs)]['showInSimulator']);
                firstColumnName = shareField;
            } else {
                const absoluteField = absolutes.find(abs => this.metaData.outputConfigurations[(abs)] && this.metaData.outputConfigurations[(abs)]['showInSimulator']);
                firstColumnName = absoluteField ? absoluteField : firstColumnName;
            }
            let index = 0;
            this.compareTableSettings.columns.forEach((col) => {
                if (firstColumnName === col.name) {
                    const colObj = {
                        name: col.name,
                        displayName: col.displayName,
                        showInColumn: true,
                        showIndices: true
                    };
                    if (!this.compareSkuConfigColumnChooser.configurations['hideOutputs'].find(h => {
                        return h.name === col.name;
                    })) {
                        this.compareSkuConfigColumnChooser.configurations['hideOutputs'].push(colObj);
                    }
                } else if (firstColumnName && index > this.compareTableSettings.outPutColumnStartIndex) {
                    const colObj = {
                        name: col.name,
                        displayName: col.displayName,
                        showInColumn: false,
                        showIndices: false
                    };
                    if (!this.compareSkuConfigColumnChooser.configurations['hideOutputs'].find(h => {
                        return h.name === col.name;
                    })) {
                        this.compareSkuConfigColumnChooser.configurations['hideOutputs'].push(colObj);
                    }
                }
                index++;
            });
            this.compareSkuConfigColumnChooser.configType = this.appConstantsService.compareMultiSkuConfigColumnChooserConfigType;
        }
    }

    multiComparisonColumnChooserSetup(): void {
        let hiddenColumnCount = 0;
        let outputHiddenCount = 0;
        if (this.compareTableSettingsWithColumnChooser.inputsStartAtIndex > 0) {
            this.setInputsColumnChooser();
            hiddenColumnCount = this.removeColumnsWhichAreNotSelected(this.compareSkuConfigColumnChooser.configurations['hideInputs']);

        }
        if (this.compareTableSettingsWithColumnChooser.outputsStartAtIndex > 0) {
            this.compareTableSettingsWithColumnChooser.outputsStartAtIndex = this.compareTableSettingsWithColumnChooser.outputsStartAtIndex - hiddenColumnCount;
            this.setOutputsColumnChooser();
            outputHiddenCount = this.removeColumnsWhichAreNotSelectedInOutputs(this.compareSkuConfigColumnChooser.configurations['hideOutputs']);
        }
        this.compareTableSettingsWithColumnChooser.groupHeaders.map(h => {
            if (h.type === 'INPUTS') {
                h.colspan = h.colspan - hiddenColumnCount;
            }
            if (h.type === 'RESULTS') {
                h.colspan = h.colspan - outputHiddenCount;
            }
        });
        this.compareTableSettingsWithColumnChooser.subHeaders.map(h => {
            if (h.type === 'INPUTS') {
                h.colspan = h.colspan - hiddenColumnCount;
            }
            if (h.type === 'RESULTS') {
                h.colspan = h.colspan - outputHiddenCount;
            }
            const indexColumnExist = this.compareTableSettingsWithColumnChooser.visibleColumns.find(c => {
                return c.indexColumn;
            });
            if (h.type === 'ScenarioComp1' && !indexColumnExist) {
                h.colspan = h.colspan - 1;
            }
            if (h.type === 'ScenarioComp2' && !indexColumnExist) {
                h.colspan = h.colspan - 1;
            }
        });
    }

    applyUserConfigurationsForSkuConfigTable(): void {
        this.makeCopyOfTableSettings();
        this.setGroupingsColumnChooser();
        if (this.compareScenarioIds.length === 1) {
            this.dualComparisonColumnChooserSetup();
        } else if (this.compareScenarioIds.length > 1 && this.baseScenarioId && this.compareTableSettingsWithColumnChooser.outputsStartAtIndex > 0) {
            this.setUpMultiCompareColumnChooser();
            this.multiComparisonColumnChooserSetup();
        }
        this.nestedHeaders = [this.compareTableSettingsWithColumnChooser.groupHeaders];
        if (this.compareTableSettingsWithColumnChooser.outputsStartAtIndex > 0) {
            this.nestedHeaders.push(this.compareTableSettingsWithColumnChooser.subHeaders);
        }
        this.nestedHeaders.push(this.compareTableSettingsWithColumnChooser.visibleColumnHeaders);
    }


    /**
     *
     * @param  type which is columnChooser type either GROUPS/INPUTS/OUTPUTS
     * returns number of columns hidden due to user configurations
     */
    removeColumnsWhichAreNotSelected(configurations: any): number {
        let i = 0;
        this.compareTableSettings.columns.forEach((column) => {
            let columnChooser = {showInColumn: true, name: ''};
            columnChooser = configurations.find((c) => {
                return c.name === column.name;
            });
            if (columnChooser && !columnChooser.showInColumn) {
                const index = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex(a => a.name === columnChooser.name);
                this.compareTableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
                this.compareTableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
                i++;
            }
        });
        return i;
    }

    removeColumnsWhichAreNotSelectedInOutputs(configurations: any): number {
        const subGroupsSelection = this.metaDataService.outputsOnNonSampleFilterToggle(this.metaData);
        const shareFieldsMetaData = ['unitsShare', 'unitsPromoShare', 'revenueShare', 'equivalizedVolumeShare', 'psShare'];
        const shareConfig = shareFieldsMetaData.find(abs => this.metaData.outputConfigurations[(abs)] && this.metaData.outputConfigurations[(abs)]['showInSimulator']);
        if (this.hasUnSelectedNonSampleFilter) {
            if (subGroupsSelection === this.appConstantsService.SHARES_ONLY) {
               if(shareConfig){
                   configurations.forEach((c) => {
                       if (!this.metaData.outputConfigurations[(c.name)]['showOnNonSampleFilterToggle']) {
                           c.disable = true;
                           c.showInColumn = false;
                           c.showIndices = false;
                       }
                   });
               }
            } else if (subGroupsSelection === this.appConstantsService.PREFERENCE_SHARE_ONLY) {
                if(shareConfig){
                   const  matchedConfig = configurations.find((c) => {
                        return c.name ===  this.metaData.outputConfigurations[(shareConfig)].name && !c.showInColumn;
                    });
                    if(matchedConfig){
                        matchedConfig.showInColumn = true;
                        matchedConfig.showIndices = true;
                        matchedConfig.disable = false;
                    }
                    configurations.forEach((c) => {
                        if (!this.metaData.outputConfigurations[(c.name)]['showOnNonSampleFilterToggle']) {
                            c.disable = true;
                            c.showInColumn = false;
                            c.showIndices = false;
                        }
                    });
                }
            }else{
                configurations.forEach((c) => {
                    c.disable = false;
                    c.disableIndex = false;
                });
            }
        }else{
            configurations.forEach((c) => {
                c.disable = false;
            });
        }

        let i = 0;
        this.compareTableSettings.columns.forEach((column) => {
            const columnChooser = configurations.find((c) => {
                return c.name === column.name;
            });
            if (columnChooser && (!columnChooser.showInColumn || !columnChooser.showIndices)) {
                const columns = this.compareTableSettingsWithColumnChooser.visibleColumns.filter(a =>
                    a.name === columnChooser.name);
                if (!columnChooser.showIndices) {
                    columns.forEach(col => {
                        const index = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex(a =>
                            a.name === col.name && a.indexColumn);
                        if (index > 0) {
                            this.compareTableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
                            this.compareTableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
                            i++;
                        }
                    });
                }
                if (!columnChooser.showInColumn) {
                    columns.forEach(col => {
                        const index = this.compareTableSettingsWithColumnChooser.visibleColumns.findIndex(a =>
                            a.name === col.name && !a.indexColumn);
                        if (index > 0) {
                            this.compareTableSettingsWithColumnChooser.visibleColumns.splice(index, 1);
                            this.compareTableSettingsWithColumnChooser.visibleColumnHeaders.splice(index, 1);
                            i++;
                        }
                    });
                }
            }
        });
        return i;
    }

    /**
     *
     * @param data columns to be arranged
     */
    orderColumnsForColumnChooser(data: any): any {
        const columnData = [];
        this.compareTableSettingsWithColumnChooser.visibleColumns.forEach((column) => {
            const columnChooser = data.find((c) => {
                return c.name === column.name;
            });
            if (columnChooser) {
                if (columnData.indexOf(columnChooser) < 0) {
                    columnData.push(columnChooser);
                }
            }
        });
        return columnData;
    }

    setInputsColumnChooser(): void {
        this.compareTableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index > 2 && index <= this.compareTableSettingsWithColumnChooser.outputsStartAtIndex) {
                const inputColumnChooser = this.compareSkuConfigColumnChooser.configurations['hideInputs'].find((c) => {
                    return c.name === column.name;
                });
                if (!inputColumnChooser) {
                    this.compareSkuConfigColumnChooser.configurations['hideInputs'].push({
                        displayName: column.displayName,
                        name: column.name,
                        showInColumn: true
                    });
                }
            }
        });
        this.compareSkuConfigColumnChooser.configurations['hideInputs'] = this.orderColumnsForColumnChooser(this.compareSkuConfigColumnChooser.configurations['hideInputs']);
    }

    setOutputsColumnChooser(): void {
        this.compareTableSettingsWithColumnChooser.visibleColumns.forEach((column: any, index: number) => {
            if (index > this.compareTableSettingsWithColumnChooser.outputsStartAtIndex) {
                const outputColumnChooser = this.compareSkuConfigColumnChooser.configurations['hideOutputs'].find((c) => {
                    return c.name === column.name && !c.showInColumn;
                });
                if (!outputColumnChooser) {
                    this.compareSkuConfigColumnChooser.configurations['hideOutputs'].push({
                        displayName: column.displayName,
                        name: column.name,
                        showInColumn: true,
                        showIndices: true
                    });
                }
            }
        });
        this.compareSkuConfigColumnChooser.configurations['hideOutputs'] = this.orderColumnsForColumnChooser(this.compareSkuConfigColumnChooser.configurations['hideOutputs']);
    }

    applyColumnSelectionChange(): void {
        if (this.compareSkuConfigColumnChooser.id) {
            this.userConfigurationsService.updateUserConfiguration(this.compareSkuConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                //this.loadHotTable();
                this.columnChooserClick.emit(userConfigurations);
            });
        } else {
            this.compareSkuConfigColumnChooser.projectId = this.metaData.projectId;
            this.compareSkuConfigColumnChooser.modelRunId = this.metaData.modelRunId;
            this.userConfigurationsService.createNewUserConfiguration(this.compareSkuConfigColumnChooser).subscribe(userConfigurations => {
                this.userConfigurationsService.userConfigurations = userConfigurations;
                //this.loadHotTable();
                this.columnChooserClick.emit(userConfigurations);
            });
        }
    }

    openColumnChooserDialog(event): void {
        const targetAttr = event.target.getBoundingClientRect();
        const outputsOnNonSampleFilterToggle = this.metaDataService.outputsOnNonSampleFilterToggle(this.metaData);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.hasBackdrop = true;
        dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';
        if (event.target.classList.contains('inputs')) {
            dialogConfig.data = {
                columnData: this.compareSkuConfigColumnChooser.configurations['hideInputs'],
                customDialogOpen: true,
                groupHeaderLabel: 'inputs'
            };
        } else if (event.target.classList.contains('outputs')) {
            const absolutes = ['units', 'revenue', 'volume', 'equivalizedVolume', 'profit'];
            const absoluteField = absolutes.find(abs => this.metaData.outputConfigurations[(abs)] && this.metaData.outputConfigurations[(abs)]['showInSimulator']);
            this.compareSkuConfigColumnChooser.configurations['hideOutputs'].forEach((column) => {
                if (absolutes.indexOf(column.name) !== -1 && this.metaData.outputConfigurations[(absoluteField)] && this.metaData.outputConfigurations[(absoluteField)]['showAbsolutesIndices']) {
                    column.disable = true;
                    column.disableIndex = this.hasUnSelectedNonSampleFilter ? true : false;
                    column.showInColumn = false;
                }
            });

            dialogConfig.data = {
                columnData: this.compareSkuConfigColumnChooser.configurations['hideOutputs'],
                customDialogOpen: true,
                groupHeaderLabel: 'results',
                isMultiComparison: this.compareScenarioIds.length > 1,
                outputsOnNonSampleFilterToggle
            };
        }
        dialogConfig.position = {
            top: `${targetAttr.y + targetAttr.height + 5}px`,
            left: `${targetAttr.x - targetAttr.width - 200}px`
        };
        this.dialogRef = this.dialog.open(ColumnChooserComponent, dialogConfig);
        this.dialogRef.afterClosed().subscribe(() => {
            if (this.dialogRef.componentInstance.hasChanges) {
                this.applyColumnSelectionChange();
                this.currentCollapsedColumns = [];
                this.dialogRef = null;
            }
        });
    }
}
