import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserManagementService} from '../../services/user-management.service';
import {AuditTrail} from "@app/models/audit-trail.model";

@Component({
    selector: 'app-tau-audit-trail-edit-dialog',
    templateUrl: './tau-audit-trail-edit-dialog.component.html',
    styleUrls: ['./tau-audit-trail-edit-dialog.component.scss']
})
export class TauAuditTrailEditDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<TauAuditTrailEditDialogComponent>,
        public userManagementService: UserManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    skuData: any;
    onRevert = new EventEmitter();
    userName: string;
    activeAuditLog : AuditTrail;
    enableApply = false;
    tauAuditTrailLogs: any

    ngOnInit(): void {
        this.skuData = this.data;
        this.userManagementService.getUser(this.skuData.step2StartedBy).subscribe((user) => {
            this.userName = user.fullName;
        });
        this.tauAuditTrailLogs = this.skuData.auditTrailLogs.filter(it => it.property === 'adjustedTauValue');
        this.activeAuditLog = this.currentAuditLog;
    }

    get lastUpdated(): string {
        return `${moment(this.skuData.step2Ended).fromNow()}`;
    }

    onClickRevert(): void {
        this.onRevert.emit({name: "RESET", skuData: this.skuData});
    }

    onClickApply(): void {
        this.onRevert.emit({name: "APPLY", skuData: this.skuData,activeAuditLog:this.activeAuditLog});
    }

    onClickEdit(auditLog : AuditTrail): void{
        this.activeAuditLog = auditLog;
    }

    onEditValue(event: any): void {
        this.enableApply = this.activeAuditLog.reasonForAdjustment &&  this.activeAuditLog.reasonForAdjustment.trim().length > 0 ;
    }

    get hasAdjustment(){
        return this.skuData.hasTauAdjustmentDescription;
    }

    getRoundedTauValue(value : number){
        return this.roundTo(value,5);
    }

    get currentAuditLog(){
        return this.tauAuditTrailLogs[this.tauAuditTrailLogs.length-1];

    }

    roundTo(n, digits): any {
        const multiplicand = Math.pow(10, digits);
        n = parseFloat((n * multiplicand).toFixed(12));
        n = (Math.round(n) / multiplicand).toFixed(digits);
        return n;
    }

}
