import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {ProjectService} from '../services/project.service';
import {Project} from '../models/project.model';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppConstantsService} from '../services/app-constants.service';
import {UiBlockerService} from '../services/ui-blocker.service';

@Injectable({providedIn: 'root'})
export class ProjectResolver implements Resolve<Project> {

  constructor(private service: ProjectService,
              private appConstantsService: AppConstantsService,
              private uiBlockerService: UiBlockerService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Project> {
    this.uiBlockerService.block();
    return this.service.getProject(+route.paramMap.get('projectId')).pipe(map((project: Project) => {
      this.uiBlockerService.unblock();
      if (project.type !== this.appConstantsService.LPO_PRODUCT_TYPE_ID) {
        return null;
      }
      return project;
    }), catchError(error => {
      if (error.status === this.appConstantsService.httpStatus.UNAUTHORIZED ||
        error.status === this.appConstantsService.httpStatus.FORBIDDEN) {
        // if user has session but no permission to see project
        this.router.navigate(['/404']).then(() => {
          this.uiBlockerService.unblockAll();
        });
      }
      return of(null);
    }));
  }
}
