/**
 * `<app-empty-state>` component is a reusable component used across the
 * application to indicate no entries in that current page.
 *
 * @example
 * <app-empty-state
 *   [size]="'medium'"
 *   [icon]="'sif-dataset'"
 *   [iconColor]="'primary'"
 *   [title]="'Awaiting for Simulator run data'"
 *   [message]="'You will receive a notification once our team completes running
 *   the Analytics and the Simulator is ready.'"
 *   >
 * </app-empty-state>
 *
 * @param {string} size - small/medium/default(large) height variants
 * @param {string} icon - a sif-icon to indicate the current module
 * @param {string} iconColor - color of the icon. Accepts primary, accent, warn, error
 * @param {string} title - header of the <app-empty-state> component message
 * @param {string} message - first line of the <app-empty-state> content
 * @param {string} subMessage - content under `message`. Can be HTML
 *
 * @export
 * @class EmptyStateComponent
 */
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() size: string;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() title: string;
  @Input() message: string;
  @Input() subMessage: string;

}
