import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from './../../environments/environment';
import {AppConfig} from './../models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  /**
   * These are the config values that we get from assets/config/config.json file
   */
  private config: AppConfig;

  private externalConfigLoaded: boolean;

  constructor() {
    this.externalConfigLoaded = false;
  }

  setAppConfig(config: AppConfig): void {
    this.config = config;
    this.externalConfigLoaded = true;
  }

  get externalConfigHasLoaded(): boolean {
    return this.externalConfigLoaded;
  }

  /** Getter for environment variable */
  get environment(): AppConfig {
    return {...environment, ...this.config};
  }
}
