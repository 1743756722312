import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {UiBlockerService} from 'src/app/services/ui-blocker.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private _location: Location,
              private uiBlockerService: UiBlockerService) {
  }

  ngOnInit(): void {
    this.uiBlockerService.unblockAll();
  }

  goBack(): void {
    this._location.back();
  }

}
