import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {EnvironmentService} from '@app/services/environment.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;
    const environmentService = this.injector.get(EnvironmentService);
    const authProxyURL = environmentService.environment.authProxy.url;
    const headersObject = {
      targetUrl: window.location.href,
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Expose-Headers': ['targetUrl'],
    };

    const currentRequestHeaders = request.headers.keys().reduce((acc, cur) => {
      acc[cur] = request.headers.get(cur);
      return acc;
    }, {});

    const customizedRequest = request.clone({
      withCredentials: true,
      headers: new HttpHeaders({
        ...currentRequestHeaders,
        ...headersObject,
      })
    });

    return next.handle(customizedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('processing error', error);
        if (error.status === 401) {
          // redirect to cas url only if userInfo api fails
          // or else this causes the page to get in redirection loop
          if (request.url.indexOf('userInfo') !== -1) {
            const casUrl = error.headers.get('casLoginUrl') || `${authProxyURL}/logout`;
            window.location.href = casUrl;
            return of(error as any);
          }
        } else if (error.status === 403) {
          /**
           * NOTE: It is checking if our request to get project details is getting denied because of permission issue.
           * This is a bad solution IMO but I couldn't find any other way to bubble the error up to the resolver or the
           * component so they could handle this error!
           */
          const regex = /projects\/\d+$/;
          if (url.match(regex)) {
            this.router.navigate(['/404']);
          }
        }
        return throwError(error);
      })
    );
  }
}
