import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ApplicationBarModuleKey} from '@basesstudio/ngx-components';
import {Project} from '../../models/project.model';
import {ModelRunService} from '../../services/model-run.service';
import {EnvironmentService} from '@app/services/environment.service';
import {User} from '@app/models/user.model';
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {ProjectService} from '@app/services/project.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  projectId: number;

  project: Project;

  userInfo: User;

  public applicationBarModuleKey: string;

  public projectNavigationAPI: string;

  public studioURL: string;

  public userNavigationServiceURL: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modelRunService: ModelRunService,
              private authProxyService: AuthProxyService,
              private projectService: ProjectService,
              private environmentService: EnvironmentService
  ) {
    this.applicationBarModuleKey = ApplicationBarModuleKey.LPO_SIMULATOR;
  }

  ngOnInit(): void {
    this.project = this.route.snapshot.data.project;
    const env = this.environmentService.environment.authProxy;
    this.studioURL = `${env.url}/${env.studioContextPath}`;
    this.projectNavigationAPI = this.projectService.getProjectNavigationAPI(this.project.id);
    this.userInfo = this.authProxyService.user;
  }

}
