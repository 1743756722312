import {isEmpty} from '../utils/object-utils';

export function roundTo(n, digits): any {
  const multiplicand = Math.pow(10, digits);
  n = parseFloat((n * multiplicand).toFixed(12));
  n = (Math.round(n) / multiplicand).toFixed(digits);
  return n;
}

export function valueHasPercentageAtTheEnd(value): boolean {
  return value != undefined && value.length > 1 && value.toString().substr(value.length - 1, value.length) === '%';
}

export function priceCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? priceValidator(value, rowData.priceMin, rowData.priceMax) : true);
}

export function promoSlopeValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? numericRangeValidator(value, 0.05, 4) : true);
}

export function yellowTagValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? numericRangeValidator(value, -2, 2) : true);
}

export function promoPriceCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  const cellMeta = this.instance.getCellMeta(this.row, this.col);
  if (cellMeta.type !== 'dropdown') {
    return callback(priceValidator(value, rowData.promoPriceMin, rowData.promoPriceMax));
  } else {
    return callback(value === 'Special Price' || rowData.promotions.map((promo) => {
      return promo.text;
    }).indexOf(value) !== -1);
  }
}

export function featurePriceCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? priceValidator(value, rowData.featurePriceMin, rowData.featurePriceMax) : true);
}

export function sizeCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? numericRangeValidator(value, rowData.sizeMin, rowData.sizeMax) : true);
}

export function displayPriceCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? priceValidator(value, rowData.displayPriceMin, rowData.displayPriceMax) : true);
}

export function featureAndDisplayPriceCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? priceValidator(value, rowData.featureAndDisplayPriceMin, rowData.featureAndDisplayPriceMax) : true);
}

export function specialPromoPriceCellValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? priceValidator(value, rowData.promoPriceMin, rowData.promoPriceMax) : true);
}

export function percentageCellValidator(value, callback): void {
  callback(distributionValidator(value));
}

export function numericValidator(value): boolean {
  return !isNaN(value);
}

export function numericRangeValidator(value, min, max): boolean {
  return numericValidator(value) && value <= max && value >= min;
}

export function priceValidator(value, min, max): boolean {
  return numericRangeValidator(value, min, max);
}

export function distributionValidator(value): boolean {
  return isEmpty(value) || PercentageRangeValidator(value, 0, 1);
}

export function PercentageRangeValidator(value, min, max): boolean {
  if (valueHasPercentageAtTheEnd(value)) {
    const actualValue = value.substr(0, value.length - 1);
    return numericValidator(actualValue) && parseFloat(actualValue) <= 100 && parseFloat(actualValue) >= 0;
  }
  return numericValidator(value) && parseFloat(value) <= max && parseFloat(value) >= min;
}

export function scenarioNameValidator(value,callback): void {
    console.log(value);
    callback(false);
}

export function tauValidator(value,callback): void{
  const rowData = this.instance.getSourceDataAtRow(this.row);
  if(typeof value === 'number' && !isNaN(value)){
    callback(rowData.id && rowData.minTau ? priceValidator(value, rowData.minTau, rowData.maxTau) : true);
  }else{
    callback(false);
  }
}

export function rhoValidator(value,callback): void{
  const rowData = this.instance.getSourceDataAtRow(this.row);
  if(typeof value === 'number' && !isNaN(value)){
    callback(rowData.id && rowData.minRho ? priceValidator(value, rowData.minRho, rowData.maxRho) : true);
  }else{
    callback(false);
  }
}

export function costPerUnitValidator(value, callback): void {
  const rowData = this.instance.getSourceDataAtRow(this.row);
  callback(rowData.id ? priceValidator(value, 0, rowData.priceMax ? rowData.priceMax : rowData.priceInput) : true);
}
