<h2 mat-dialog-title>Share & View Collaborators</h2>
<div class="share-search-bar">
    <label>Search Names</label><br>
    <input id="searchCollaboratorId"matInput placeholder="Search" (keyup)="search($event.target.value)" style="width:400px;">
</div>

<span id="internalUserMessageId" class="nielsen-user" *ngIf="(isInternalUser || isScenarioGroupInternal);else shareToAllNielsenSection">All Nielsen Users are automatically shared</span>

<div>
    <ng-template #shareToAllNielsenSection>
        <mat-checkbox id="sharedToAllNielsenId" [checked]="sharedToAllNielsen" (change)="shareToAllNielsen($event)"></mat-checkbox>
        Share and Notify all Nielsen collaborators
    </ng-template>
</div>

<div class="share-notify">
    <label>Share and Notify:<span id="toggleCollaboratorsViewId" (click)="toggleCollaboratorsView()">{{collaboratorsMessage}}</span></label>
    <span class="sif sif-chevron-n" *ngIf="isShown"></span>
    <span class="sif sif-chevron-s" *ngIf="!isShown"></span>
</div>
<div *ngIf="isShown" class="collaborator-list-container">
    <div class="flex client-x-collaborator">
        <div class="width-30p">
            <mat-checkbox id="sharedToAllClientsId" [checked]="sharedToAllClients" (change)="shareToAllClients($event)">
            </mat-checkbox>
            All Collaborators
        </div>
    </div>
    <div class="collaborator-list">
        <div id="collaborator" class="width-30p" [ngClass]="collaboratorAssignment.isVisible ? 'show' : 'hide'"
             *ngFor="let collaboratorAssignment of collaboratorsAssignment">
            <mat-checkbox [id]="collaboratorAssignment.collaborator.userManagementId" [checked]="collaboratorAssignment.isChecked"
                          [disabled]="disableCollaborator(collaboratorAssignment)"
                          (change)="collaboratorAssignmentChanged(collaboratorAssignment, $event)">
                {{collaboratorAssignment.collaborator.fullName}}
            </mat-checkbox>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button id="share-scenario-group-modal-cancel"class="mat-secondary" mat-button mat-dialog-close>Cancel</button>
    <button id="share-scenario-group-modal-save" mat-raised-button [disabled]="!collaboratorsAssignmentSave" color="primary"
            [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveCollaboratorsAssigment()">save
    </button>
</mat-dialog-actions>