import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstantsService {

    readonly PREFERENCE_SHARE = 'Preference Share';
    readonly PREFERENCE_SHARE_ONLY = 'Preference Share only';
    readonly SHARES_ONLY = 'Shares only';
    readonly SHARES_AND_ABSOLUTES = 'Shares & Absolutes';
    readonly SCENARIO_NAME_MAX_LENGTH = 50;
    readonly SCENARIO_DESC_MAX_LENGTH = 50;
    readonly SCENARIO_GROUP_NAME_MAX_LENGTH = 75;
    readonly AUTO_UNLOCK_SCENARIO_PERIOD = (5 * 60 * 1000); // 5 minutes
    readonly USER_RUN_SEGMENTS = 'USER_RUN_SEGMENTS';
    readonly OUT_OF_SYNC = 'Out of Sync';
    readonly CALIBRATION_SCENARIO_NAME = 'Calibration Scenario';
    readonly REFERENCE_SCENARIO_NAME = 'Reference Scenario';
    readonly REPORT_ALREADY_EXISTS = 'A run with that name already exists.';
    readonly ITEM_ORDER_CONFIG_TYPE = 'RUN_SKU_CONFIGS_ITEM_ORDER';
    readonly ALL_OTHER_GROUPING = 'All Other'
    readonly NO_SKUS_SELECTED = 0;
    readonly ALL_SKUS_SELECTED = 1;
    readonly PARTIAL_SKUS_SELECTED = 2;
    readonly CALIBRATION_STEP1_STARTED = 1;
    readonly CALIBRATION_STEP1_FAILED = 2;
    readonly CALIBRATION_STEP1_COMPLETED = 3;
    readonly CALIBRATION_STEP2_STARTED = 4;
    readonly CALIBRATION_STEP2_FAILED = 5;
    readonly CALIBRATION_STEP2_COMPLETED = 6;
    readonly PROMO_SLOPE_DEFAULT_VALUE = 1;
    readonly YT_DEFAULT_VALUE = 0;
    readonly UNASSIGNED_SCENARIOS_GROUP_NAME = 'UNASSIGNED SCENARIOS';
    readonly CALIBRATION_SCENARIOS_FOLDER_NAME = 'CALIBRATION SCENARIOS';
    readonly VIEW_PERMISSION = 'VIEW';
    readonly EDIT_PERMISSION = 'EDIT';
    readonly SIMULATE_PERMISSION = 'SIMULATE';
    readonly DELETE_PERMISSION = 'DELETE';
    readonly LPO_PRODUCT_TYPE_ID = 15;
    readonly CALIBRATION_MSG_GROUP = 'calibration';
    readonly CALIBRATION_ADDITIONAL_COLUMNS_OPTIONS = 'CALIBRATION_ADDITIONAL_COLUMNS_OPTIONS';
    readonly RUN_NOT_AVAILABLE_LABEL = 'Model run data is not yet available';
    readonly FOR_ABSOLUTES_INDICES_ONLY = 'For Absolutes Indices only';


    get httpStatus(): Record<string, number> {
        return {
            UNAUTHORIZED: 401,
            FORBIDDEN: 403,
            NOT_FOUND: 404
        };
    }

    get shareScenarioGroup(): string {
        return 'SHARE_SCENARIO_GROUP';
    }

    get addScenarioInGroup(): string {
        return 'ADD_SCENARIO_IN_GROUP';
    }

    get internalGroup(): string {
        return 'internal';
    }

    get scenarioGroupOwnerPermission(): string {
        return 'SCENARIO_GROUP_OWNER';
    }

    get scenarioOwnerPermission(): string {
        return 'SCENARIO_OWNER';
    }

    get skuConfigColumnChooserConfigType(): string {
        return 'SKU_CONFIG_COLUMN_CHOOSER';
    }

    get compareSkuConfigColumnChooserConfigType(): string {
        return 'COMPARE_SKU_CONFIG_COLUMN_CHOOSER';
    }

    get compareMultiSkuConfigColumnChooserConfigType(): string {
        return 'COMPARE_MULTI_SKU_CONFIG_COLUMN_CHOOSER';
    }

    get scenarioGroupOrderConfigTye(): string {
        return 'SCENARIO_GROUP_ORDER';
    }

    get scenarioOrderConfigTye(): string {
        return 'SCENARIO_ORDER';
    }

    get setRegularPriceToDefault(): string {
        return 'SET_REGULAR_PRICE_TO_DEFAULT';
    }

    get setRegularPriceToValue(): string {
        return 'SET_REGULAR_PRICE_TO_VALUE';
    }

    get changeRegularPriceByPercentage(): string {
        return 'CHANGE_REGULAR_PRICE_BY_PERCENTAGE';
    }

    get setDistributionToDefault(): string {
        return 'SET_DISTRIBUTION_TO_DEFAULT';
    }

    get setDistributionToPercentage(): string {
        return 'SET_DISTRIBUTION_TO_PERCENTAGE';
    }

    get setPromotionPriceToDefault(): string {
        return 'SET_PROMOTION_PRICE_TO_DEFAULT';
    }

    get setSpecialPromotionPriceToDefault(): string {
        return 'SET_SPECIAL_PROMOTION_PRICE_TO_DEFAULT';
    }

    get changePromotionPriceByPercentage(): string {
        return 'CHANGE_PROMOTION_PRICE_BY_PERCENTAGE';
    }

    get changeSpecialPromotionPriceByPercentage(): string {
        return 'CHANGE_SPECIAL_PROMOTION_PRICE_BY_PERCENTAGE';
    }

    get setTimeOnPromoToDefault(): string {
        return 'SET_TIME_ON_PROMO_TO_DEFAULT';
    }

    get setTimeOnPromoToPercentage(): string {
        return 'SET_TIME_ON_PROMO_TO_PERCENTAGE';
    }

    get calibrationColumnChooserConfigType(): string {
        return 'CALIBRATION_COLUMN_CHOOSER';
    }
    
}
