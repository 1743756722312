import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {AppConstantsService} from '../services/app-constants.service';
import {UtilService} from './util.service';
import {SimulationRunResult} from '../models/simulation-run-result.model';

@Injectable({
  providedIn: 'root'
})
export class SimulationRunResultService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private appConstantsService: AppConstantsService,
    private utilService: UtilService) {
  }

  fetchAll(projectId: number, modelRunId: string, scenarioId: string): Observable<Array<SimulationRunResult>> {
    const url = this.utilService.getModelRunUriBasedPath(projectId, modelRunId, `scenarios/${scenarioId}/results`);
    return this.http.get<Array<SimulationRunResult>>(url).pipe(map((inputs: Array<SimulationRunResult>) => {
      return inputs;
    }));
  }
}
