import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {ModelRunService} from './model-run.service';
import {Permission} from '../models/permission.model';
import {AccessPolicy} from '../models/access-policy.model';
import {ScenarioGroup} from '../models/scenario-group.model';
import {ModelCacheUtil} from '../utils/model-cache-util';
import {UtilService} from '@app/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPolicyService {

  private cachedRunAccessPolicies: ModelCacheUtil<AccessPolicy>;
  private cachedRunUserPermissions: ModelCacheUtil<Permission>;


  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private modelRunService: ModelRunService,
              private utilService: UtilService) {
    this.cachedRunAccessPolicies = new ModelCacheUtil<AccessPolicy>();
    this.cachedRunUserPermissions = new ModelCacheUtil<Permission>();
  }

  get accessPolicies(): AccessPolicy[] {
    return this.cachedRunAccessPolicies.cachedModels;
  }

  getEntityPermissions(modelRunId: string): Observable<Permission[]> {
    const modelRun = this.modelRunService.getModelRun(modelRunId);
    if (modelRun) {
      const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, 'permissions')}`;
      return this.http.get<any>(url).pipe(map((permissions: Permission[]) => {
        this.cachedRunUserPermissions.clear();
        this.cachedRunUserPermissions.appendAll(permissions);
        return permissions;
      }));
    } else {
      return null;
    }
  }

  getAccessPolicies(modelRunId: string): Observable<AccessPolicy[]> {
    const modelRun = this.modelRunService.getModelRun(modelRunId);
    if (modelRun) {
      const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, 'accessPolicies')}`;
      return this.http.get<any>(url).pipe(map((accessPolicies: AccessPolicy[]) => {
        this.cachedRunAccessPolicies.clear();
        this.cachedRunAccessPolicies.appendAll(accessPolicies);
        return accessPolicies;
      }));
    } else {
      return null;
    }
  }

  getEntityAccessPolicies(entityId: string): AccessPolicy[] {
    return this.accessPolicies.filter(accessPolicy => {
      return accessPolicy.entityId === entityId;
    });
  }

  /**
   * updates AccessPolicies/Collaborators
   */
  updateCollaborators(modelRunId: string, updateCollaborators: any): Observable<ScenarioGroup> {
    const modelRun = this.modelRunService.getModelRun(modelRunId);
    if (modelRun) {
      const env = this.environmentService.environment.authProxy;
      const url = `${this.utilService.getModelRunUriBasedPath(modelRun.projectId, modelRun.id, 'accessPolicies')}`;
      return this.http.put<any>(url, {collaborators: updateCollaborators});
    } else {
      return null;
    }
  }

  hasPermission(entityId: string, permission: string): boolean {
    return this.cachedRunUserPermissions.cachedModels?.findIndex((entity: Permission) => {
      return entity.entityId === entityId && entity.permissions.findIndex(it => {
        return it === permission;
      }) !== -1;
    }) !== -1;
  }
}
