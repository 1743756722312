<div *ngIf="isDropDownData; else noData" class="filter-wrapper">
    <app-ns-default-dropdown [isDisable]="disable" [label]="newLabel ? newLabel : defaultLabel" [contentTitle]="dropDownData.contentTitle">
        <div class="dropdown-filter-menu">
            <ul class="dropdown-menu-list">
                <!-- List Items -->
                <div *ngIf="isListItems">
                    <div *ngFor="let item of dropDownData.data" class="menu-list-content">
                        <li class="list-item" (click)="onSelect(dropDownData,item)">{{item.label}}</li>
                    </div>
                </div>

                <!-- Checkbox Items -->
                <div *ngIf="isCheckboxes && !dropDownData.groupSelect" (click)="$event.stopPropagation()">
                    <div class="menu-list-content" *ngFor="let item of dropDownData.data">
                        <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="onSelect(dropDownData,item)"><li class="list-item">{{item.label}}</li></mat-checkbox>
                    </div>
                </div>

                <!-- Checkbox Group with Select All-->
                <div *ngIf="isCheckboxes && dropDownData.groupSelect" #checkboxgroup>
                    <li *ngIf="dropDownData.groupSelect" class="list-item"><mat-checkbox
                            [checked]="isAllCompleted"
                            (change)="selectAllCheckboxes($event.checked)"
                            (click)="$event.stopPropagation()">
                            {{dropDownData.groupSelect}}
                    </mat-checkbox></li>
                    <ul [ngClass]="'dropdown-menu-sublist'">
                        <div class="menu-list-content" *ngFor="let item of dropDownData.data" (click)="$event.stopPropagation()">
                            <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="updateAllSelectCheckboxes()" ><li class="list-item">{{item.label}}</li></mat-checkbox>
                        </div>
                    </ul>
                </div>

                <!-- Radio Items -->
                <div *ngIf="isRadioButtons">
                    <mat-radio-group>
                        <div class="menu-list-content" *ngFor="let item of dropDownData.data">
                            <mat-radio-button [checked]="item.selected" (change)="onSelect(dropDownData,item)" [value]="item.value"><li class="list-item">{{item.label}}</li></mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </ul>
        </div>
    </app-ns-default-dropdown>
</div>

<ng-template #noData>
    <p>No Dropdown Data</p>
</ng-template>
