import {isEmpty} from './object-utils';
import Handsontable from 'handsontable';

const INVALID_CELL_BORDER = '1px solid #dd0014';
const INVALID_CELL_BACK_GROUND_COLOR = '#FFF !important';

export class SkuConfigTableCellRenderers {

    private currencyFormat: Record<string, any>;
    private shareFormat: Record<string, any>;
    private absoluteFormat: Record<string, any>;
    private indicesFormat: Record<string, any>;
    private invalidScenarioNames: Array<string>=[];

    constructor(currencyFormat, shareFormat, absoluteFormat, indicesFormat) {
        this.currencyFormat = currencyFormat;
        this.shareFormat = shareFormat;
        this.absoluteFormat = absoluteFormat;
        this.indicesFormat = indicesFormat;
    }

    /**
     * Converts percent value to absolute value.
     */
    getShareValue(value: string): number {
        const stringValue = `${value}`;
        if (stringValue && stringValue.length) {
            if (stringValue[stringValue.length - 1] === '%') {
                return this.getShareValue(stringValue.replace('%', '')) / 100;
            } else if (!isNaN(Number(stringValue))) {
                return Number.parseFloat(stringValue);
            }
        }
        return null;
    }

    getAbsoluteValue(value: string): number {
        const stringValue = `${value}`;
        if (`${stringValue}`.length) {
            if (!isNaN(Number(stringValue))) {
                return Number.parseFloat(stringValue);
            }
        }
        return null;
    }

    validatePromoAdjustmentsValueRange(value: number, min: number, max: number, td): boolean {
        if (isEmpty(value) || (!isEmpty(min) && !isEmpty(max) && !(value >= min && value <= max))) {
            return this.validateValueRangeError(min, max, td);
        }
        return true;
    }

    validateValueRangeError(min: number, max: number, td): boolean {
        td.style.backgroundColor = INVALID_CELL_BACK_GROUND_COLOR;
        td.style.border = INVALID_CELL_BORDER;
        td.title = `Please enter a value between ${min} and ${max}`;
        return false;
    }

    isValueInRange(min: number, max: number, value: number): boolean{
        return (value >= min && value <= max);
    }

    validateDiscretePromoError(rowData: any, td, value , promoPriceCell): boolean {
        if (rowData.promoPriceText && rowData.promoPriceText !== 'Special Price' && promoPriceCell) {
            td.style.backgroundColor = value !== rowData.reportingBasePrice ? INVALID_CELL_BACK_GROUND_COLOR : '';
            td.style.border = value !== rowData.reportingBasePrice ? INVALID_CELL_BORDER : '';
            td.title =  value !== rowData.reportingBasePrice ? `Discrete promos must be simulated at regular base  ${rowData.reportingBasePrice}` : '';
            return false;
        }
        td.style.backgroundColor = '';
        td.style.border = '';
        td.title = promoPriceCell && value !== rowData.reportingBasePrice ? `Discrete promos must be simulated at regular base ${rowData.reportingBasePrice}` : '';
        return true;
    }

    validateValueRange(value: number, min: number, max: number, td): boolean {
        if (!isEmpty(value) && !isEmpty(min) && !isEmpty(max) && !(value >= min && value <= max)) {
            return this.validateValueRangeError(min, max, td);
        }
        return true;
    }

    checkBoxRenderer(instance, td, row, col, prop, value, cellProperties): any {
        const rowData = instance.getSourceDataAtRow(row);
        value = value ? 1 : 0;
        if (!rowData.id) {
            td.innerHtml = '';
            cellProperties.type = 'text';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            return td;
        }
        cellProperties.className = rowData.isSelected ? 'checked' : 'unchecked';
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
        return td;
    }

    reportNameRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        value = isEmpty(value) ? '--' : value;
        if (rowData.isGroupLabel) {
            td.innerHTML = `<span id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'><span class='margin-left-1 nota-cell-font'>${value}</span></span>`;
            if (rowData.isSelected) {
                cellProperties.className = !value ? 'hatch-cell' : 'ellipsis nota-cell htLeft padding-left-1 sif-13 sif sif-chevron-s';
            } else {
                cellProperties.className = !value ? 'hatch-cell' : 'ellipsis nota-cell htLeft padding-left-1 sif-13 sif sif-chevron-e';
            }
        } else if (rowData.isBrand) {
            td.innerHTML = `<span id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'><span class='margin-left-1 nota-cell-font'>${value}</span></span>`;
            if (rowData.isSelected) {
                cellProperties.className = !value ? 'hatch-cell' : 'ellipsis nota-cell htLeft padding-left-3-point-4 sif-13 sif sif-chevron-s';
            } else {
                cellProperties.className = !value ? 'hatch-cell' : 'ellipsis nota-cell htLeft padding-left-3-point-4 sif-13 sif sif-chevron-e';
            }
        } else if (!rowData.isBrand && !rowData.isGroupLabel && rowData.brandName && rowData.hasActiveGroup) {
            cellProperties.className = !value ? 'hatch-cell' : 'ellipsis htLeft padding-left-4-point-6';
            td.innerHTML = `<span class='margin-left-1' id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'>${value}</span>`;
        } else {
            cellProperties.className = !value ? 'hatch-cell' : rowData.hasCoRelatedSkus ? 'ellipsis htLeft nota-cell nota-cell-font' : 'ellipsis htLeft';
            td.innerHTML = `<span id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'>${value}</span>`;
        }
        td.title = value;
        return td;
    }

    toolTipRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id) {
            return td;
        }
        value = isEmpty(value) ? '--' : value;
        td.innerHTML = `<div class='ellipsis htLeft' rel='tooltip' tooltip-inverse tooltip-content='${value}'>${value}</div>`;
        td.title = value !== '--' ? value : '';
        cellProperties.className = 'ellipsis htLeft';
        cellProperties.disableVisualSelection = cellProperties.readOnly;
        return td;
    }

    priceInputRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
       
        if (this.validateValueRange(normalizedValue, rowData.priceMin, rowData.priceMax, td)){
            td.innerHTML = `${currency(normalizedValue, this.currencyFormat).format()}`;
            if (rowData.priceInput_difference) {
                const differenceClass = rowData.priceInput_difference === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue, this.currencyFormat).format()}</span>`;
            }
        }
        return td;
    }

    promoSlopeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        let className;
        let readOnly;
        if (rowData['promoType'] === 'continuous') {
            className = rowData['continuous_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
            readOnly = rowData['continuous_promo_hatch'];
        } else if (rowData['promoType'] === 'discrete') {
            className = rowData['discrete_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
            readOnly = rowData['discrete_promo_hatch'];
        } else if (rowData['promoType'] !== 'none') {
            className = rowData['continuous_promo_hatch'] && rowData['discrete_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
            readOnly = rowData['continuous_promo_hatch'] && rowData['discrete_promo_hatch'];
        }
        cellProperties.className = className;
        cellProperties.readOnly = readOnly;
        this.validatePromoAdjustmentsValueRange(value, 0.05, 4, td);
        return td;
    }

    yellowTagRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        if (rowData['promoType'] == 'continuous' || rowData['promoType'] == 'discrete' || (rowData['promoType'] == 'both' && rowData['continuous_promo_hatch'] && rowData['discrete_promo_hatch'])) {
            cellProperties.className = rowData[`${prop}_hatch`] || rowData['continuous_promo_hatch'] || rowData['discrete_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
            cellProperties.readOnly = !!(rowData[`${prop}_hatch`] || rowData['continuous_promo_hatch'] || rowData['discrete_promo_hatch']);
        } else if (rowData['promoType'] == 'both') {
            if (rowData['continuous_promo_hatch'] && prop === 'yt_1') {
                cellProperties.className = rowData[`${prop}_hatch`] || rowData['continuous_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
                cellProperties.readOnly = !!(rowData[`${prop}_hatch`] || rowData['continuous_promo_hatch']);
            } else if (rowData['discrete_promo_hatch'] && prop !== 'yt_1') {
                cellProperties.className = rowData[`${prop}_hatch`] || rowData['discrete_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
                cellProperties.readOnly = !!(rowData[`${prop}_hatch`] || rowData['discrete_promo_hatch']);
            } else {
                cellProperties.className = rowData[`${prop}_hatch`] ? 'hatch-cell' : cellProperties.className;
                cellProperties.readOnly = !!(rowData[`${prop}_hatch`]);
            }
        }


        this.validatePromoAdjustmentsValueRange(value, -2, 2, td);
        return td;
    }

    discretePromoRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }

        cellProperties.className = rowData[`${prop}_hatch`] || rowData['discrete_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
        td.title = rowData[`${prop}_hatch`] || rowData['discrete_promo_hatch'] ? '' : value !== '--' ? value : '';

        return td;
    }

    priceRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const priceMin = currency(rowData.priceMin, this.currencyFormat).format();
        const priceMax = currency(rowData.priceMax, this.currencyFormat).format();
        td.innerHTML = `${priceMin} - ${priceMax}`;
        cellProperties.className = rowData['continuous_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
        return td;
    }

    adjustPromotionsPromoPriceRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const priceMin = currency(rowData.promoPriceMin, this.currencyFormat).format();
        const priceMax = currency(rowData.promoPriceMax, this.currencyFormat).format();
        td.innerHTML = `${priceMin} - ${priceMax}`;
        cellProperties.className = rowData['continuous_promo_hatch'] ? 'hatch-cell' : cellProperties.className;
        return td;
    }

    distributionRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        let normalizedValue = this.getShareValue(value);
        normalizedValue = (normalizedValue === null || normalizedValue < 0) ? 0 : normalizedValue;
        if (this.validateValueRange(normalizedValue, 0, 1, td)) {
            td.innerHTML = currency(normalizedValue * 100, this.shareFormat).format();
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue * 100, this.shareFormat).format()}</span>`;
            }
            td.title = '';
        }
        return td;
    }

    continuousPromoPriceRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, rowData.promoPriceMin, rowData.promoPriceMax, td)) {
            td.innerHTML = `${currency(normalizedValue, this.currencyFormat).format()}`;
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue, this.currencyFormat).format()}</span>`;
            }
        }
        return td;
    }

    featurePriceRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, rowData.featurePriceMin, rowData.featurePriceMax, td)) {
            td.innerHTML = `${currency(normalizedValue, this.currencyFormat).format()}`;
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue, this.currencyFormat).format()}</span>`;
            }
        }
        return td;
    }

    baseSizeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        const requiredFormat = {
            symbol: '',decimal: '.',
            precision: 2};
        if (!rowData.id || value === '--') {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, rowData.sizeMin, rowData.sizeMax, td)) {
            td.innerHTML = `${currency(normalizedValue, requiredFormat).format()}`;
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue,requiredFormat).format()}</span>`;
            }
        }
        return td;
    }

    displayPriceRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, rowData.displayPriceMin, rowData.displayPriceMax, td)) {
            td.innerHTML = `${currency(normalizedValue, this.currencyFormat).format()}`;
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue, this.currencyFormat).format()}</span>`;
            }
        }
        return td;
    }

    featureAndDisplayPriceRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, rowData.featureAndDisplayPriceMin, rowData.featureAndDisplayPriceMax, td)) {
            td.innerHTML = `${currency(normalizedValue, this.currencyFormat).format()}`;
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue, this.currencyFormat).format()}</span>`;
            }
        }
        return td;
    }

    promoPriceDropdownRenderer(instance, td, row, col, prop, value, cellProperties): any {
        const specialPrice = 'Special Price';
        const isSpecialPriceValue = value === specialPrice;
        Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        const specialPromoPriceCell = instance.getCellMetaAtRow(row).find((meta) => meta.prop === 'specialPromoPrice');
        const promoPriceRangeCell = instance.getCellMetaAtRow(row).find((meta) => meta.prop === 'promoPriceRange');
        if (rowData && rowData.promotions) {
                const filteredPromosOnMap = rowData.promotions.filter(p => {
                    return p.map !== '-1.0';
                }).map(promo => promo.text);
                if (filteredPromosOnMap && filteredPromosOnMap.length) {
                    cellProperties.source = filteredPromosOnMap;
                    if (specialPromoPriceCell && (rowData.continuousMap !== -1 || rowData.continuousMap !== -1.0)) {
                        cellProperties.source.push(specialPrice);
                    }
                } else if (rowData.continuousMap === -1 || (rowData.promo === 'discrete')) {
                    cellProperties.type = 'text';
                    cellProperties.readOnly = true;
                    cellProperties.className = 'hatch-cell';
                }
        } else {
            if (specialPromoPriceCell && (rowData.continuousMap !== -1 || rowData.continuousMap !== -1.0)) {
                cellProperties.source=[specialPrice];
            }else{
                cellProperties.type = 'text';
            }
        }
        if (isSpecialPriceValue && specialPromoPriceCell && rowData.continuousMap !== -1) {
            specialPromoPriceCell.readOnly = cellProperties.readOnly;
            specialPromoPriceCell.className = 'htRight';
            instance.setCellMeta(row, specialPromoPriceCell.col, specialPromoPriceCell);
        } else {
            if (promoPriceRangeCell) {
                promoPriceRangeCell.className = isSpecialPriceValue && rowData.continuousMap !== -1 ? '' : 'hatch-cell';
                instance.setCellMeta(row, promoPriceRangeCell.col, promoPriceRangeCell);
            }
            if (specialPromoPriceCell) {
                specialPromoPriceCell.className = 'hatch-cell';
                specialPromoPriceCell.readOnly = true;
                instance.setCellMeta(row, specialPromoPriceCell.col, specialPromoPriceCell);
            }
        }
    }

    specialPromoPriceCellRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData.promoPriceText !== 'Special Price' || isEmpty(value)) {
            td.style.backgroundColor = '';
            td.style.border = '';
            td.title = '';
            td.innerHTML = '';
            td.value = '';
            cellProperties.className = 'hatch-cell';
            cellProperties.readOnly = true;
            cellProperties.disableVisualSelection = true;
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, rowData.promoPriceMin, rowData.promoPriceMax, td)) {
            td.innerHTML = currency(normalizedValue, this.currencyFormat).format();
            if (rowData[`${prop}_difference`]) {
                const differenceClass = rowData[`${prop}_difference`] === 'high' ? 'input-high-value' : 'input-low-value';
                td.innerHTML = `<span class="${differenceClass}">${currency(normalizedValue, this.currencyFormat).format()}</span>`;
            }
            td.title = '';
        }
        return td;
    }

    promoPriceRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        if ((rowData.promotions && rowData.promotions.length > 0 && rowData.promoPriceText !== 'Special Price') || (rowData.promo==='both' && rowData.continuousMap && rowData.continuousMap === -1)) {
            td.innerHTML = '';
            cellProperties.className = 'hatch-cell';
            cellProperties.readOnly = true;
            cellProperties.disableVisualSelection = true;
            return td;
        } else {
            cellProperties.className = 'htRight';
            const priceMin = currency(rowData.promoPriceMin, this.currencyFormat).format();
            const priceMax = currency(rowData.promoPriceMax, this.currencyFormat).format();
            td.innerHTML = `${priceMin} - ${priceMax}`;
        }
        return td;
    }

    featurePriceRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const priceMin = currency(rowData.featurePriceMin, this.currencyFormat).format();
        const priceMax = currency(rowData.featurePriceMax, this.currencyFormat).format();
        td.innerHTML = `${priceMin} - ${priceMax}`;
        return td;
    }

    sizeRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        const requiredFormat = {
            symbol: '',decimal: '.',
            precision: 2};
        if (!rowData.id || value === '--') {
            return td;
        }
        const priceMin = currency(rowData.sizeMin, requiredFormat).format();
        const priceMax = currency(rowData.sizeMax, requiredFormat).format();
        td.innerHTML = `${priceMin} - ${priceMax}`;
        return td;
    }

    displayPriceRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const priceMin = currency(rowData.displayPriceMin, this.currencyFormat).format();
        const priceMax = currency(rowData.displayPriceMax, this.currencyFormat).format();
        td.innerHTML = `${priceMin} - ${priceMax}`;
        return td;
    }

    featureAndDisplayPriceRangeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id || value === '--') {
            return td;
        }
        const priceMin = currency(rowData.featureAndDisplayPriceMin, this.currencyFormat).format();
        const priceMax = currency(rowData.featureAndDisplayPriceMax, this.currencyFormat).format();
        td.innerHTML = `${priceMin} - ${priceMax}`;
        return td;
    }

    promoDistributionRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.distributionRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    unitRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id && !rowData.isBrand) {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (isEmpty(normalizedValue) || normalizedValue === 0) {
            td.innerHTML = `--`;
        } else {
            td.innerHTML = currency(normalizedValue, this.absoluteFormat).format();
        }
        return td;
    }

    unitShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id && !rowData.isBrand) {
            return td;
        }
        const normalizedValue = this.getShareValue(value);
        if (isEmpty(normalizedValue) || normalizedValue === 0) {
            td.innerHTML = `--`;
        } else {
            td.innerHTML = currency(normalizedValue * 100, this.shareFormat).format();
        }
        return td;
    }

    unitsPromoShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    unitShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        td.title = '';
        if (rowData.hasCoRelatedSkus && rowData.viewType === 'current') {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        } else {
            const normalizedValue = this.getShareValue(value);
            if (isEmpty(normalizedValue) || normalizedValue === 0) {
                td.innerHTML = `--`;
            } else {
                td.innerHTML = currency(normalizedValue * 100, this.shareFormat).format();
            }
        }
        return td;
    }

    equivalizedVolumeRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitRendererForCalibration(instance, td, row, col, prop, value, cellProperties);
    }

    equivalizedVolumeShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties);
    }

    revenueRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        td.title = '';
        if (rowData.hasCoRelatedSkus && rowData.viewType === 'current') {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        } else {
            td.innerHTML = this.getFormattedOutputValueForRevenue(value);
        }
        return td;
    }

    revenueShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties);
    }

    preferenceShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRendererForCalibration(instance, td, row, col, prop, value, cellProperties);
    }

    unitRendererForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        td.title = '';
        if (rowData.hasCoRelatedSkus && rowData.viewType === 'current') {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        } else {
            const normalizedValue = this.getAbsoluteValue(value);
            if (isEmpty(normalizedValue) || normalizedValue === 0) {
                td.innerHTML = `--`;
            } else {
                td.innerHTML = currency(normalizedValue, this.absoluteFormat).format();
            }
        }
        return td;
    }

    coRelationWeightRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData.hasCoRelatedSkus || (!(rowData.isNewItem) && !rowData.parentSkuId)) {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        } else {
            const normalizedValue = this.getShareValue(value);
            if (isEmpty(normalizedValue) || normalizedValue === 0) {
                td.innerHTML = `--`;
            } else {
                td.innerHTML = currency(normalizedValue * 100, this.shareFormat).format();
            }
        }
        return td;
    }

    priceElasticityRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        td.title = '';
        if (rowData.hasCoRelatedSkus && (!rowData.viewType || rowData.viewType === 'current')) {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        }
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
            cellProperties.className = rowData.hasCoRelatedSkus && rowData.viewType === 'new' ? 'htRight' : cellProperties.className;
        } else {
            td.innerHTML = this.roundTo(value, 2);
            cellProperties.className = rowData.hasCoRelatedSkus && rowData.viewType === 'new' ? 'htRight' : cellProperties.className;
        }

        return td;
    }

    weightRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData.hasCoRelatedSkus || (!(rowData.isNewItem) && !rowData.parentSkuId)) {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        } else {
                const parentRowData = instance.getSourceData().find(it=>it.skuId===rowData.parentSkuId);
                const isAllSelected = rowData.viewType === 'new' && (!(parentRowData .correlatedSkus.find(it=>!it.isSelected)));
                const normalizedValue = rowData.isSelected ? (isAllSelected ? this.getShareValue(rowData.weight) : this.getShareValue(rowData.correlatedWeight)):0;
            if (isEmpty(normalizedValue) || normalizedValue === 0) {
                td.innerHTML = `--`;
            } else {
                td.innerHTML = currency(normalizedValue * 100, this.shareFormat).format();
            }
        }
        return td;
    }

    targetPriceElasticityRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        td.title = '';
        cellProperties.className = 'htRight';
        if (!rowData.viewType || rowData.viewType === 'current') {
            if (rowData.hasCoRelatedSkus) {
                cellProperties.className = 'hatch-cell';
                cellProperties.readOnly = true;
                cellProperties.disableVisualSelection = true;
            } else {
                cellProperties.readOnly = false;
            }

        } else {
            cellProperties.readOnly = !rowData.hasCoRelatedSkus;
        }
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
            cellProperties.className = rowData.hasCoRelatedSkus && rowData.viewType === 'new' ? 'htRight' : cellProperties.className;
        } else {
            td.innerHTML = this.roundTo(value, 2);
            cellProperties.className = rowData.hasCoRelatedSkus && rowData.viewType === 'new' ? 'htRight' : cellProperties.className;
        }

        return td;
    }

    tauRenderer(instance, td, row, col, prop, value, cellProperties): HTMLTableElement {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        cellProperties.readOnly = rowData.viewType === 'current' ? rowData.isNewItem : !rowData.isNewItem;
        cellProperties.className = (rowData.isNewItem && rowData.viewType === 'current') ? 'hatch-cell' : 'htRight';
        td.title = rowData.isNewItem && rowData.viewType === 'new' && rowData.targetUnitShare && rowData.targetUnitShare > 0 ? "Please clear target share to provide tau value" : "";
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
            td.title = isEmpty(value) ? `Please enter a value between ${rowData.minTau} and ${rowData.maxTau}`: '';
            if(rowData.viewType === 'new' && rowData.hasCoRelatedSkus){
                td.className = value === 0?'htRight':'';
            }
        } else {
            td.innerHTML = `<span>${this.roundTo(value, 2)}</span>`;
            if (this.isValueInRange(rowData.minTau, rowData.maxTau, value)) {
                if ((rowData.hasCoRelatedSkus && rowData.viewType === 'new') || (rowData.viewType === 'current' && !rowData.isNewItem)) {
                    if ((!rowData.targetUnitShare && this.roundTo(rowData.tau, 5) !== this.roundTo(rowData.tauValueFromStep2, 5)) || (rowData.targetUnitShare && rowData.targetUnitShare > 0 && this.roundTo(rowData.tau, 5) !== this.roundTo(rowData.tauValueFromStep2, 5))) {
                        if (rowData.hasTauAdjustment && rowData.hasTauAdjustmentDescription && rowData.tau === rowData.savedTauValue) {
                            cellProperties.className = 'has-description htRight';
                            td.innerHTML += `<span sku-id="${rowData.skuId}"  class="tau-audit-trail-btn sif tau-comment-gray"></span>`;
                        } else if (rowData.tau !== rowData.savedTauValue) {
                            cellProperties.className = 'has-modified htRight';
                            td.innerHTML += `<span sku-id="${rowData.skuId}" title="Click save to apply adjustment and add notes on reasoning." class="tau-comment"></span>`;
                        } else if(rowData.hasTauAdjustment){
                            cellProperties.className = 'has-override htRight';
                            td.innerHTML += `<span sku-id="${rowData.skuId}" class="tau-audit-trail-btn sif tau-comment"></span>`;
                        }
                    }
                }
            }else
            if((rowData.viewType === 'current' && !rowData.isNewItem) || (rowData.viewType === 'new' && rowData.hasCoRelatedSkus)){
                td.title = `Please enter a value between ${rowData.minTau} and ${rowData.maxTau}`;
                td.className+=' htInvalid';
            }
        }
        return td;
    }

    rhoRenderer(instance, td, row, col, prop, value, cellProperties): HTMLTableElement {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        cellProperties.readOnly = rowData.viewType === 'current' ? rowData.isNewItem : !rowData.isNewItem;
        cellProperties.className = (rowData.isNewItem && rowData.viewType === 'current') ? 'hatch-cell' : 'htRight';
        if (isEmpty(value)) {
            td.innerHTML = `--`;
            td.title = `Please enter a value between ${rowData.minRho} and ${rowData.maxRho}`;
        } else {
            td.innerHTML = `<span>${this.roundTo(value, 2)}</span>`;
            if (this.isValueInRange(rowData.minRho, rowData.maxRho, value)) {
                if ((rowData.hasCoRelatedSkus && rowData.viewType === 'new') || (rowData.viewType === 'current' && !rowData.isNewItem)) {
                    if ((this.roundTo(rowData.rho1, 5) !== this.roundTo(rowData.baseRho1ValueFromStep2, 5))) {
                        if (rowData.hasRhoAdjustment && rowData.hasRhoAdjustmentDescription && rowData.rho1 === rowData.rho1Saved) {
                            cellProperties.className = 'has-description htRight';
                            td.innerHTML += `<span sku-id="${rowData.skuId}"  class="rho-audit-trail-btn sif rho-comment-gray"></span>`;
                        } else if (rowData.rho1 !== rowData.rho1Saved) {
                            cellProperties.className = 'has-modified htRight';
                            td.innerHTML += `<span sku-id="${rowData.skuId}" title="Click save to apply adjustment and add notes on reasoning." class="rho-comment"></span>`;
                        } else if(rowData.hasRhoAdjustment){
                            cellProperties.className = 'has-override htRight';
                            td.innerHTML += `<span sku-id="${rowData.skuId}" class="rho-audit-trail-btn sif rho-comment"></span>`;
                        }
                    }
                }
            }else
            if((rowData.viewType === 'current' && !rowData.isNewItem) || (rowData.viewType === 'new' && rowData.hasCoRelatedSkus)){
                td.title = `Please enter a value between ${rowData.minRho} and ${rowData.maxRho}`;
                td.className+=' htInvalid';
            }
        }
        return td;
    }

    addTitleAfterRenderer(Td, visualRowIndex, visualColumnIndex, prop, value, cellProperties) {
        Td.title = value;
    }


    equivalizedVolumeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    equivalizedVolumeShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    revenueRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id && !rowData.isBrand) {
            return td;
        }
        td.innerHTML = this.getFormattedOutputValueForRevenue(value);
        return td;
    }

    revenueShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    preferenceShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    profitRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.revenueRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    volumeRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    volumeShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.unitShareRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    indexCellRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id && !rowData.isBrand) {
            return td;
        }
        if (isEmpty(value) || value === '0.0' || isNaN(value)) {
            td.innerHTML = `--`;
        } else {
            const valueInPercentageDecimal = currency(value * 100, this.indicesFormat).format();
            td.innerHTML = `${valueInPercentageDecimal}`;
        }
        return td;
    }

    indexFormatForBrandRow(value): string {
        if (isEmpty(value) || value === '0.0' || isNaN(value)) {
            return '--';
        } else {
            const valueInPercentageDecimal = currency(value * 100, this.indicesFormat).format();
            return `${valueInPercentageDecimal}`;
        }
    }

    roundTo(n, digits): any {
        const multiplicand = Math.pow(10, digits);
        n = parseFloat((n * multiplicand).toFixed(12));
        n = (Math.round(n) / multiplicand).toFixed(digits);
        return n;
    }

    inputsCheckboxRenderer(instance, td, row, col, prop, value, cellProperties): any {
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData['skuGroup'] && rowData['itemGrouping']) {
            const skuGroup = rowData['skuGroup'];
            if (rowData[(skuGroup)] === 'All Other') {
                cellProperties.readOnly = false;
            } else if (rowData[(skuGroup)] !== rowData['itemGrouping']) {
                cellProperties.readOnly = true;
            } else {
                cellProperties.readOnly = false;
            }
        } else {
            cellProperties.readOnly = true;
        }
        cellProperties.className = rowData.isSelected ? 'checked' : 'unchecked';
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
        return td;
    }

    salesTaxRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.distributionRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    regularMarginRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.distributionRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    retailerRegularMarginRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.distributionRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    promoMarginRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.distributionRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    retailerPromoMarginRenderer(instance, td, row, col, prop, value, cellProperties): any {
        return this.distributionRenderer(instance, td, row, col, prop, value, cellProperties);
    }

    costPerUnitRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (!rowData.id) {
            return td;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        if (this.validateValueRange(normalizedValue, 0, rowData.priceMax ? rowData.priceMax : rowData.priceInput, td)) {
            td.innerHTML = `${currency(normalizedValue, this.currencyFormat).format()}`;
        }
        return td;
    }

    getFormattedOutputValue(value: string): any {
        if (value === '--') {
            return value;
        }
        const normalizedValue = this.getAbsoluteValue(value);
        return currency(normalizedValue, this.absoluteFormat).format();
    }

    getFormattedOutputShareValue(value: string): any {
        if (value === '--') {
            return value;
        }
        const normalizedValue = this.getShareValue(value);
        return currency(normalizedValue * 100, this.shareFormat).format();
    }

    getFormattedOutputValueForRevenue(value: string): any {
        const normalizedValue = this.getAbsoluteValue(value);
        if (isEmpty(normalizedValue) || normalizedValue === 0) {
            return `--`;
        } else {
            return `${currency(normalizedValue, this.currencyFormat).format()}`;
        }
    }

    scenarioDropdownRenderer(instance, td, row, col, prop, value, cellProperties): any {
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData.isSelected || rowData.step !== 'Not Started') {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            cellProperties.readOnly = true;
            cellProperties.className = `${cellProperties.className} tooltip-class`;
            td.title = value;
        } else {
            Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);
            const scenarioCell = instance.getCellMetaAtRow(row).find((meta) => meta.prop === 'scenarioName');
            cellProperties.type = 'dropdown';
            cellProperties.readOnly = false;
            cellProperties.source = rowData.dropdownScenarios.map(scenario => scenario.name);
            cellProperties.className = value === 'Select Scenario' ? `${cellProperties.className} left-border tooltip-class` : `${cellProperties.className} tooltip-class`;
            instance.setCellMeta(row, col, scenarioCell);
        }
        if(this.isScenarioValid(value)){
            cellProperties.className = `${cellProperties.className} invalid-cell-border tooltip-class`;
        }
    }

    targetShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData.viewType === 'current' && rowData.scenarioName === 'Calibration Scenario') {
            cellProperties.readOnly = false;
        }
        if ((!rowData.viewType || rowData.viewType === 'current') && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.className = 'hatch-cell';
            cellProperties.readOnly = true;
        }
        if (rowData.viewType === 'new' && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.readOnly = false;
        }
        const normalizedValue = this.getShareValue(value);
        if (isEmpty(normalizedValue) || normalizedValue === 0) {
            td.innerHTML = `--`;
            return;
        } 
        if(this.isValueInRange(0,1,normalizedValue)){
            td.title = '';
        }else{
            td.className+=' htInvalid';
            td.title = 'Please enter a value between 0% and 100%';
        }
        td.innerHTML = currency(normalizedValue * 100, this.shareFormat) < 0 ? '--' : currency(normalizedValue * 100, this.shareFormat).format();
    }

    calibrationCheckBoxRenderer(instance, td, row, col, prop, value, cellProperties): any {
        const rowData = instance.getSourceDataAtRow(row);
        value = value ? 1 : 0;
        if (!rowData.parentSkuId) {
            td.innerHtml = '';
            cellProperties.type = 'text';
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            cellProperties.className = 'hatch-cell';
            cellProperties.readOnly = true;
            cellProperties.disableVisualSelection = true;
            return td;
        }
        cellProperties.className = rowData.isSelected ? 'checked' : 'unchecked';
        cellProperties.readOnly = rowData.userDefinedTau;
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
        return td;
    }


    calibrationReportNameRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        value = isEmpty(value) ? '--' : value;
        if (rowData.hasCoRelatedSkus) {
            td.innerHTML = `<span id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'><span class='margin-left-1 nota-cell-font'>${value}</span></span>`;
            if (rowData.isSelected) {
                cellProperties.className = !value ? 'hatch-cell' : 'ellipsis nota-cell htLeft padding-left-1 sif-13 sif sif-chevron-s';
            } else {
                cellProperties.className = !value ? 'hatch-cell' : 'ellipsis nota-cell htLeft padding-left-1 sif-13 sif sif-chevron-e';
            }
        } else if (rowData.parentSkuId) {
            cellProperties.className = !value ? 'hatch-cell' : 'ellipsis htLeft padding-left-4-point-2';
            td.innerHTML = `<span class='margin-left-1' id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'>${value}</span>`;
        } else {
            cellProperties.className = !value ? 'hatch-cell' : rowData.hasCoRelatedSkus ? 'ellipsis htLeft nota-cell nota-cell-font' : 'ellipsis htLeft';
            td.innerHTML = `<span id='' rel='tooltip'  tooltip-inverse tooltip-content='${value}'>${value}</span>`;
        }
        td.title = value;
        return td;
    }

    velocityRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if ((!rowData.viewType || rowData.viewType === 'current') && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.className = 'hatch-cell';
        }
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
        } else {
            td.innerHTML = this.roundTo(value, 2);
        }
    }

    precisionDifferenceRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        const isCurrentView = rowData.viewType === 'current' && !rowData.isNewItem;
        const isNewView = rowData.viewType === 'new' && rowData.isNewItem;

        if ((!rowData.viewType || rowData.viewType === 'current') && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.className = 'hatch-cell';
        }
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
            return;
        }
        value = value * 100;
        if (!this.isFitDifferenceRangeValid(value, td) && (isCurrentView || isNewView)) {
            td.style.backgroundColor = '#FCE1E1';
        }
        td.innerHTML = currency(value, this.shareFormat).format();
    }

    precisionIndexRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        const isCurrentView = rowData.viewType === 'current' && !rowData.isNewItem;
        const isNewView = rowData.viewType === 'new' && rowData.isNewItem;

        if ((!rowData.viewType || rowData.viewType === 'current') && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.className = 'hatch-cell';
        }
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
            return;
        }
        value = value * 100;
        if(!this.isFitIndexRangeValid(value,td) && (isCurrentView || isNewView)){
            td.style.backgroundColor = '#FCE1E1';
        }
        td.innerHTML = currency(value, this.indicesFormat).format();
    }

    isScenarioValid(scenarioName){
        return this.invalidScenarioNames.find(value => value === scenarioName);
    }

    setInvalidScenarioNames(invalidScenarioNames){
        this.invalidScenarioNames = invalidScenarioNames;
    }

    targetPercentageUnitsOnPromoShareRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        if (rowData.viewType === 'current' && rowData.scenarioName === 'Calibration Scenario') {
            cellProperties.readOnly = false;
        }
        if ((!rowData.viewType || rowData.viewType === 'current') && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.className = 'hatch-cell';
            cellProperties.readOnly = true;
        }
        if (rowData.viewType === 'new' && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.readOnly = false;
        }
        const normalizedValue = this.getShareValue(value);
        if (isEmpty(normalizedValue)) {
            td.innerHTML = `--`;
            return;
        } 
        if(this.isValueInRange(0,1,normalizedValue)){
            td.title = '';
        }else{
            if(typeof normalizedValue === 'number' && !isNaN(normalizedValue)){
                td.className+=' htInvalid';
            }
            td.title = 'Please enter a value between 0% and 100%';
        }
        td.innerHTML = currency(normalizedValue * 100, this.shareFormat) < 0 ? '--' : currency(normalizedValue * 100, this.shareFormat).format();
    }

    isFitIndexRangeValid(value, td){
        return (value < 90 || value > 110)?false:true;
    }
    
    isFitDifferenceRangeValid(value, td){
        return (value < -0.2 || value > 0.2)?false:true;

    }

    percentageUnitsOnPromoShareForCalibration(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        td.title = '';
        if (rowData.hasCoRelatedSkus && rowData.viewType === 'current') {
            cellProperties.className = 'hatch-cell';
            cellProperties.disableVisualSelection = true;
        } else {
            const normalizedValue = this.getShareValue(value);
            if (isEmpty(normalizedValue) || normalizedValue === 0) {
                td.innerHTML = `--`;
            } else {
                td.innerHTML = currency(normalizedValue * 100, this.shareFormat).format();
            }
        }
        return td;
    }

    promoSplitFitIndexRenderer(instance, td, row, col, prop, value, cellProperties): any {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        const rowData = instance.getSourceDataAtRow(row);
        const isCurrentView = rowData.viewType === 'current' && !rowData.isNewItem;
        const isNewView = rowData.viewType === 'new' && rowData.isNewItem;

        if ((!rowData.viewType || rowData.viewType === 'current') && rowData.scenarioName !== 'Calibration Scenario') {
            cellProperties.className = 'hatch-cell';
        }
        if (isEmpty(value) || value === 0) {
            td.innerHTML = `--`;
            return;
        }
        value = value * 100;
        if(!this.isFitIndexRangeValid(value,td) && (isCurrentView || isNewView)){
            td.style.backgroundColor = '#FCE1E1';
        }
        td.innerHTML = currency(value, this.indicesFormat).format();
    }
}

