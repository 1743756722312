import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UiBlockerService} from '../services/ui-blocker.service';
import {catchError, map} from 'rxjs/operators';
import {UtilService} from '../services/util.service';
import {ModelRunSkuService} from '../services/model-run-sku.service';
import {ModelRunSku} from '../models/model-run-sku.model';

@Injectable({providedIn: 'root'})
export class ModelRunSkuResolver implements Resolve<ModelRunSku> {

    constructor(private modelRunSkuService: ModelRunSkuService,
                private uiBlockerService: UiBlockerService,
                private utilService: UtilService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<ModelRunSku> {
        this.uiBlockerService.block();
        const projectId = +this.utilService.getRouteParameter(route, 'projectId');
        const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
        return this.modelRunSkuService.fetchAll(projectId, modelRunId).pipe(map((skus) => {
            this.uiBlockerService.unblock();
            return skus;
        }), catchError(() => {
            this.uiBlockerService.unblock();
            this.router.navigate(['/404']);
            return of(null);
        }));
    }
}
