import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../services/report.service';

@Component({
  selector: 'app-price-change-impact',
  templateUrl: './price-change-impact.component.html',
  styleUrls: ['./price-change-impact.component.scss']
})
export class PriceChangeImpactComponent implements OnInit {

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.reportService.activeReportSubject.next('price-change-impact');
  }


}
