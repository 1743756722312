import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentService} from '../services/environment.service';
import {ModelCacheUtil} from '../utils/model-cache-util';
import {Guardrail} from '../models/guardrail.model';
import {ModelRun} from "../models/model-run.model";
import {AsyncTask} from "../models/async-task";

@Injectable({
  providedIn: 'root'
})
export class AsyncTaskService {

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
  }

  getById(id: string, projectId: number): Observable<AsyncTask> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/asyncTasks/${id}`;
    return this.http.get<AsyncTask>(url).pipe(map((asyncTask: AsyncTask) => {
      return asyncTask;
    }));
  }

  download(asyncTask: AsyncTask): Observable<any> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${asyncTask.projectId}/asyncTasks/${asyncTask.id}/download`;
    return this.http.get(`${url}`, {
        responseType: 'arraybuffer'
      }
    );
  }

  downloadScenarioExport(asyncTask: AsyncTask) {
    this.download(asyncTask).subscribe((response) => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = `${asyncTask.name}.xlsx`;
      anchor.href = url;
      anchor.click();
    });
  }
}
