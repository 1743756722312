import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {SkuGroup} from '../models/sku-group.model';
import {ItemGrouping} from '../models/item-grouping.model';

@Injectable({
  providedIn: 'root'
})
export class SkuGroupService {

  /**
   * Cached list of groups
   */
  private cache: Array<SkuGroup>;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    this.cache = [];
  }

  get skuGroups(): Array<SkuGroup> {
    return this.cache;
  }

  fetchAll(projectId: number, modelRunId: string): Observable<Array<SkuGroup>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/skuGroups`;
    return this.http.get<Array<SkuGroup>>(url).pipe(map((skuGroups: SkuGroup[]) => {
      this.cache = skuGroups;
      return skuGroups;
    }));
  }

  /**
   * Lists all price elasticity reports associated with given project and run id.
   */
  getAll(projectId: number, modelRunId: string): Observable<Array<SkuGroup>> {
    const reports = this.cache.filter((it: SkuGroup) => {
      return it.projectId === projectId && it.modelRunId === modelRunId;
    });
    if (reports.length) {
      return of(reports);
    } else {
      return this.fetchAll(projectId, modelRunId);
    }
  }

  /**
   * Returns single price elasticity report associated with given project and run id.
   */
  getById(skuGroupId: number, params: { projectId: number; modelRunId: string }): Observable<SkuGroup> {
    const report = this.cache.find((it: SkuGroup) => {
      return it.skuGroupId === skuGroupId;
    });
    if (report) {
      return of(report);
    } else {
      const env = this.environmentService.environment.authProxy;
      const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${params.projectId}/runs/${params.modelRunId}/skuGroups/${skuGroupId}`;
      return this.http.get<SkuGroup>(url).pipe(map((skuGroup: SkuGroup) => {
        this.addToCache(skuGroup);
        return skuGroup;
      }));
    }
  }

  add(skuGroup: SkuGroup): Observable<SkuGroup> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${skuGroup.projectId}/runs/${skuGroup.modelRunId}/skuGroups`;
    return this.http.post<SkuGroup>(url, {skuGroup}).pipe(map((newSkuGroup: SkuGroup) => {
      if (skuGroup.skuGroupId) {
        const groupIndex = this.getGroupElementIndexInCache(skuGroup);
        this.cache[groupIndex] = newSkuGroup;
      } else {
        this.cache.push(newSkuGroup);
      }
      return newSkuGroup;
    }));
  }

  createNewItemGrouping(skuGroup: SkuGroup, itemGrouping: ItemGrouping): Observable<SkuGroup> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${skuGroup.projectId}/runs/${skuGroup.modelRunId}/skuGroups/${skuGroup.skuGroupId}/itemConfigurations`;
    return this.http.post<SkuGroup>(url, {
      skuGroup,
      itemGrouping
    }).pipe(map((updatedSkuGroup: SkuGroup) => {
      const groupIndex = this.getGroupElementIndexInCache(skuGroup);
      this.cache[groupIndex] =updatedSkuGroup;
      return updatedSkuGroup;
    }));
  }



  addToCache(skuGroup: SkuGroup): void {
    const match = this.cache.find((it) => {
      return it.projectId === skuGroup.projectId && it.modelRunId === skuGroup.modelRunId && it.skuGroupId === skuGroup.skuGroupId;
    });
    if (match) {
      this.cache.splice(this.cache.indexOf(match), 1, skuGroup);
    } else {
      this.cache.push(skuGroup);
    }
  }


  getGroupElementIndexInCache(skuGroup: SkuGroup): number {
    return this.cache.findIndex((it) => {
      return it.projectId === skuGroup.projectId && it.modelRunId === skuGroup.modelRunId && it.skuGroupId === skuGroup.skuGroupId;
    });
  }

  remove(skuGroup: SkuGroup): Observable<SkuGroup> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${skuGroup.projectId}/runs/${skuGroup.modelRunId}/skuGroups/${skuGroup.id}`;
    return this.http.delete<SkuGroup>(url).pipe(map(() => {
      this.cache.splice(this.getGroupElementIndexInCache(skuGroup), 1);
      return skuGroup;
    }));
  }

  removeItemGrouping(itemGrouping: ItemGrouping, skuGroup: SkuGroup): Observable<SkuGroup> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${skuGroup.projectId}/runs/${skuGroup.modelRunId}/skuGroups/${skuGroup.id}/itemGroupings/${itemGrouping.itemGroupingId}`;
    return this.http.delete<SkuGroup>(url).pipe(map((skuGroup: SkuGroup) => {
      const groupIndex = this.getGroupElementIndexInCache(skuGroup);
      this.cache[groupIndex] = skuGroup;
      return skuGroup;
    }));
  }

  clone(skuGroup: SkuGroup, displayName: string): Observable<SkuGroup> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${skuGroup.projectId}/runs/${skuGroup.modelRunId}/skuGroups/${skuGroup.skuGroupId}/clone`;
    return this.http.post<SkuGroup>(url, {displayName}).pipe(map((newSkuGroup: SkuGroup) => {
      this.cache.push(newSkuGroup);
      return newSkuGroup;
    }));
  }
}
