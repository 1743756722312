import {BrowserModule} from '@angular/platform-browser';
import {
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    APP_INITIALIZER,
    InjectionToken
} from '@angular/core';
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClient
} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import {
    ApplicationBarModule,
    BrandBarModule,
    HeartBeatService,
    LoggerType,
    NgxComponentsModule
} from '@basesstudio/ngx-components';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HotTableModule} from '@handsontable/angular';
import {ProjectComponent} from './routes/project/project.component';
import {RunsComponent} from './routes/project/runs/runs.component';
import {RunComponent} from './routes/project/runs/run/run.component';
import {HttpConfigInterceptor} from './core/interceptors/httpConfig.interceptor';
import {AppInitService} from './services/app-init.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NotFoundComponent} from './routes/errors/not-found/not-found.component';
import {EmptyStateComponent} from './components/empty-state/empty-state.component';
import {MatSelectModule} from '@angular/material/select';
import {CreateScenarioComponent} from './components/create-scenario/create-scenario.component';
import {DefaultDropdownModule} from '../app/components/default-dropdown/default-dropdown.module';
import {DropdownModule} from '../app/components/dropdown/dropdown.module';
import {DropdownFilterService} from './services/dropdown-filter.service';
import {PopulationFilterComponent} from './components/population-filter/population-filter.component';
import {SkuConfigTableComponent} from './components/sku-config-table/sku-config-table.component';
import {ScenarioComponent} from './routes/project/runs/run/scenarios/scenario/scenario.component';
import {ScenariosComponent} from './routes/project/runs/run/scenarios/scenarios.component';
import {ScenarioGroupFilterComponent} from './components/scenario-group-filter/scenario-group-filter.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ManageScenariosComponent} from './routes/project/runs/run/manage-scenarios/manage-scenarios.component';
import {CommonModule} from '@angular/common';
import {BulkEditComponent} from './routes/project/runs/run/bulk-edit/bulk-edit.component';
import {MatRadioModule} from '@angular/material/radio';
import {OverlayModule} from '@angular/cdk/overlay';
import {GroupItemsComponent} from './routes/project/runs/run/group-items/group-items.component';
import {GroupsetDialogComponent} from './routes/project/runs/run/groupset-dialog/groupset-dialog.component';
import {ConfirmationModalComponent} from '../app/components/confirmation-modal/confirmation-modal.component';
import {NgxFileHelpersModule} from 'ngx-file-helpers';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ProfitModalComponent} from './components/profit-modal/profit-modal.component';
import {HeartBeatServiceImpl} from './services/heart-beat.service';
import {EnvironmentService} from './services/environment.service';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {InputsConfigTableComponent} from './components/inputs-config-table/inputs-config-table.component';
import {MessagePromptComponent} from './components/message-prompt/message-prompt.component';
import {CompareScenariosComponent} from './routes/project/runs/run/compare-scenarios/compare-scenarios.component';

import {ReportsComponent} from './routes/reports/reports.component';

import {OptimizationComponent} from './routes/reports/optimization/optimization.component';
import {ListOptimizationReportComponent} from './routes/reports/optimization/list-optimization-report/list-optimization-report.component';
import {CreateOptimizationReportComponent} from './routes/reports/optimization/create-optimization-report/create-optimization-report.component';

import {PriceElasticityComponent} from './routes/reports/price-elasticity/price-elasticity.component';
import {ListPriceElasticityReportComponent} from './routes/reports/price-elasticity/list-price-elasticity-report/list-price-elasticity-report.component';
import {CreatePriceElasticityReportComponent} from './routes/reports/price-elasticity/create-price-elasticity-report/create-price-elasticity-report.component';

import {SizeElasticityComponent} from './routes/reports/size-elasticity/size-elasticity.component';
import {ListSizeElasticityReportComponent} from './routes/reports/size-elasticity/list-size-elasticity-report/list-size-elasticity-report.component';
import {CreateSizeElasticityReportComponent} from './routes/reports/size-elasticity/create-size-elasticity-report/create-size-elasticity-report.component';

import {PriceChangeImpactComponent} from './routes/reports/price-change-impact/price-change-impact.component';
import {ListPriceChangeImpactComponent} from './routes/reports/price-change-impact/list-price-change-impact/list-price-change-impact.component';
import {CreatePriceChangeImpactComponent} from './routes/reports/price-change-impact/create-price-change-impact/create-price-change-impact.component';

import {SourcingMatrixComponent} from './routes/reports/sourcing-matrix/sourcing-matrix.component';
import {ListSourcingMatrixReportComponent} from './routes/reports/sourcing-matrix/list-sourcing-matrix-report/list-sourcing-matrix-report.component';
import {CreateSourcingMatrixReportComponent} from './routes/reports/sourcing-matrix/create-sourcing-matrix-report/create-sourcing-matrix-report.component';

import {SharingModalComponent} from './components/sharing-modal/sharing-modal.component';
import {EditInputComponent} from './components/edit-input/edit-input.component';

import {GenericConfirmationModalComponent} from './components/generic-confirmation-modal/generic-confirmation-modal.component';
import {BulkEditConfigTableComponent} from './components/bulk-edit-config-table/bulk-edit-config-table.component';
import {ColumnChooserComponent} from './components/column-chooser/column-chooser.component';
import {ExportScenarioModalComponent} from './components/export-scenario-modal/export-scenario-modal.component';
import {CompareSkuConfigTableComponent} from './components/compare-sku-config-table/compare-sku-config-table.component';
import {CalibrationConfigTableComponent} from './components/calibration-config-table/calibration-config-table.component';

import {ItemReorderTableComponent} from './components/item-reorder-table/item-reorder-table.component';
import {CalibrationComponent} from './routes/project/runs/run/calibration/calibration.component';
import {MultiSelectDropdownComponent} from './components/multi-select-dropdown/multi-select-dropdown.component';
/************************************     DIRECTIVES     ***********************************************/
import {AutofocusDirective} from './directives/autofocus.directive';
import {ReportStatusDirective} from './directives/report-status.directive';
import {ReportSampleInfoDirective} from './directives/report-sample-info.directive';
/************************************    IMPORT PIPES     ***********************************************/
import {GetScenarioNamePipe} from './pipes/get-scenario-name.pipe';
import {GetGroupNamePipe} from './pipes/get-group-name.pipe';
import {GetScenarioOwnerNamePipe} from './pipes/get-scenario-owner-name.pipe';
import {GetMetricDisplayNamePipe} from './pipes/get-metric-display-name.pipe';

/************************************    IMPORT GUARDS    ***********************************************/
import {CreatePriceElasticityReportDeactivateGuard} from './routes/reports/price-elasticity/create-price-elasticity-report/create-price-elasticity-report.deactivate.guard';
import {CreateSizeElasticityReportDeactivateGuard} from './routes/reports/size-elasticity/create-size-elasticity-report/create-size-elasticity-report.deactivate.guard';
import {CreateOptimizationReportDeactivateGuard} from './routes/reports/optimization/create-optimization-report/create-optimization-report.deactivate.guard';
import {InternalUserActivateGuard} from './guards/internal-user-activate-guard.service';
import {CreatePriceChangeImpactReportDeactivateGuard} from './routes/reports/price-change-impact/create-price-change-impact/create-price-change-impact-report.deactivate.guard';
import {CreateSourcingMatrixDeactivateGuard} from './routes/reports/sourcing-matrix/create-sourcing-matrix-report/create-sourcing-matrix-report.deactivate.guard';
import {ErrorsActivateGuard} from './routes/errors/errors.activate.guard';
import {CalibrationDeactivateGuard} from "@app/routes/project/runs/run/calibration/calibration.deactivate.guard";
import {SyncedModelRunGuard} from "@app/guards/synced-model-run-guard.service";

/************************************    IMPORT COMPONENTS    ***********************************************/
import {CalibrationResultsComponent} from './components/calibration-results/calibration-results.component';
import {PromotionsModalComponent} from './components/promotions-modal/promotions-modal.component';
import {CalibrationResultsNewComponent} from "@app/components/calibration-results-new/calibration-results-new.component";
import {CalibrationScenarioResultsComponent} from "@app/components/calibration-scenario-results/calibration-scenario-results.component";
import {CalibrationBenchmarkComponent} from './components/calibration-benchmark/calibration-benchmark.component';
import {AdvancedSettingsComponent} from './components/advanced-settings/advanced-settings.component';
import {DisableUiComponent} from './components/disable-ui/disable-ui.component';
import {TauAuditTrailViewDialogComponent} from './components/tau-audit-trail-view-dialog/tau-audit-trail-view-dialog.component';
import {TauAuditTrailEditDialogComponent} from './components/tau-audit-trail-edit-dialog/tau-audit-trail-edit-dialog.component';
import {PromotionsConfigTableComponent} from "@app/components/promotions-config-table/promotions-config-table.component";
import {SingleSelectDropdownComponent} from './components/single-select-dropdown/single-select-dropdown.component';
import { CalibrationSettingsComponent } from './components/calibration-settings/calibration-settings.component';
import { PromptComponent } from './components/prompt/prompt.component';
import { RhoAuditTrailEditDialogComponent } from './components/rho-audit-trail-edit-dialog/rho-audit-trail-edit-dialog.component';
import { RhoAuditTrailViewDialogComponent } from './components/rho-audit-trail-view-dialog/rho-audit-trail-view-dialog.component';

/**
 * Injection token for heartbeart service.
 */
export const HEART_BEAT_TOKEN = new InjectionToken<HeartBeatService>(
    'HEART_BEAT_SERVICE'
);

export function translationLoaderFactory(
    httpClient: HttpClient
): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(httpClient, [
        {prefix: './assets/i18n/ngx-components/', suffix: '.json'},
        {prefix: './assets/i18n/', suffix: '.json'}
    ]);
}

@NgModule({
  declarations: [
    AppComponent,
    ProjectComponent,
    RunsComponent,
    RunComponent,
    NotFoundComponent,
    EmptyStateComponent,
    CreateScenarioComponent,
    PopulationFilterComponent,
    SkuConfigTableComponent,
    ScenariosComponent,
    ScenarioComponent,
    ScenarioGroupFilterComponent,
    ManageScenariosComponent,
    BulkEditComponent,
    GroupItemsComponent,
    GroupsetDialogComponent,
    ConfirmationModalComponent,
    InputsConfigTableComponent,
    MessagePromptComponent,
    ProfitModalComponent,
    CompareScenariosComponent,
    ReportsComponent,
    OptimizationComponent,
    ListOptimizationReportComponent,
    CreateOptimizationReportComponent,
    PriceElasticityComponent,
    ListPriceElasticityReportComponent,
    CreatePriceElasticityReportComponent,
    SizeElasticityComponent,
    ListSizeElasticityReportComponent,
    CreateSizeElasticityReportComponent,
    PriceChangeImpactComponent,
    ListPriceChangeImpactComponent,
    CreatePriceChangeImpactComponent,
    SourcingMatrixComponent,
    ListSourcingMatrixReportComponent,
    CreateSourcingMatrixReportComponent,
    SharingModalComponent,
    EditInputComponent,
    AutofocusDirective,
    ReportStatusDirective,
    ReportSampleInfoDirective,
    GenericConfirmationModalComponent,
    GetScenarioNamePipe,
    GetGroupNamePipe,
    GetScenarioOwnerNamePipe,
    GetMetricDisplayNamePipe,
    BulkEditConfigTableComponent,
    ColumnChooserComponent,
    ExportScenarioModalComponent,
    CompareSkuConfigTableComponent,
    ItemReorderTableComponent,
    CalibrationComponent,
    CalibrationConfigTableComponent,
    MultiSelectDropdownComponent,
    CalibrationResultsComponent,
    PromotionsModalComponent,
    CalibrationResultsNewComponent,
    CalibrationBenchmarkComponent,
    AdvancedSettingsComponent,
    DisableUiComponent,
    TauAuditTrailViewDialogComponent,
    TauAuditTrailEditDialogComponent,
    PromotionsConfigTableComponent,
    SingleSelectDropdownComponent,
    CalibrationScenarioResultsComponent,
    CalibrationSettingsComponent,
    PromptComponent,
    RhoAuditTrailEditDialogComponent,
    RhoAuditTrailViewDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxComponentsModule.forRoot({
      moduleName: 'LpoSimulator',
      heartbeat: {
        injectionToken: HEART_BEAT_TOKEN
      },
      logger: {
        type: LoggerType.CONSOLE
      }
    }),
    BrowserAnimationsModule,
    HotTableModule.forRoot(),
    NgxSpinnerModule,
    DefaultDropdownModule,
    DropdownModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTreeModule,
    MatBadgeModule,
    DragDropModule,
    MatRadioModule,
    MatTooltipModule,
    OverlayModule,
    NgxFileHelpersModule,
    BrandBarModule,
    ApplicationBarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: translationLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      // Application initializer to setup environment configuration dynamically
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () =>
        appInitService.loadConfiguration(),
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: HEART_BEAT_TOKEN,
      useClass: HeartBeatServiceImpl,
      deps: [EnvironmentService]
    },
    DropdownFilterService,
    CreateOptimizationReportDeactivateGuard,
    CreatePriceElasticityReportDeactivateGuard,
    CreateSizeElasticityReportDeactivateGuard,
    CreatePriceChangeImpactReportDeactivateGuard,
    CreateSourcingMatrixDeactivateGuard,
    InternalUserActivateGuard,
    SyncedModelRunGuard,
    ErrorsActivateGuard,
    CalibrationDeactivateGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
