<div *ngIf="customDialogOpen">
    <h2 class="column-chooser-title" mat-dialog-title>{{headerLabel}}</h2>
    <mat-dialog-content>
        <div class="column-chooser">
            <div *ngIf="groupHeaderLabel==='groups'" class="column-chooser-groups groups">
                <table>
                    <thead>
                    <tr class="tr-header-boarder">
                        <th></th>
                        <th>Column</th>
                        <th>Row</th>
                    </tr>
                    <tr>
                        <th class="table-header-width"></th>
                        <th><span class="padding-left-1"><mat-checkbox [(ngModel)]="isAllColumnsSelected"
                                                                       [checked]="isAllColumnsSelected"
                                                                       [indeterminate]="isIntermediateColumnsSelected"
                                                                       (change)="onAllColumnsSelectionChange(columnData)"></mat-checkbox></span>
                        </th>
                        <th><span class="padding-left-1"><mat-checkbox [(ngModel)]="isAllRowsSelected"
                                                                       [checked]="isAllRowsSelected"
                                                                       [indeterminate]="isIntermediateRowsSelected"
                                                                       (change)="onAllRowsSelectionChange(columnData)"></mat-checkbox></span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                        <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                        <td><span class="padding-left-1"><mat-checkbox
                                matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one item characteristic is required.': ''}}"
                                [disabled]="(isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                                [checked]="item.showInColumn"
                                (change)="onSelectionChange(item)"></mat-checkbox></span></td>
                        <td><span class="padding-left-1"><mat-checkbox [(ngModel)]="item.showInRow"
                                                                       (change)="onRowSelectionChange()"></mat-checkbox></span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="groupHeaderLabel==='inputs'" class="column-chooser-inputs inputs">
                <table>
                    <tbody>
                    <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                        <td><span class="padding-left-1"><mat-checkbox
                                matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one input is required.': ''}}"
                                [disabled]="(item.showAlways) ||( isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                                [checked]="item.showInColumn"
                                (change)="onSelectionChange(item)"></mat-checkbox></span></td>
                        <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="groupHeaderLabel==='outputs'" class="column-chooser-outputs groups">
                <table>
                    <tbody>
                    <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                        <td>
                            <span class="padding-left-1">
                            <mat-checkbox
                                    matTooltip="{{uncheckedAndDisabledColumns.has(item.name)?uncheckedAndDisabledOutputColumnTooltip:(isAllColumnsUnSelectedExceptOne && item.showInColumn)?'At least one metric is required.': ''}}"
                                    [disabled]="(item.showAlways) || uncheckedAndDisabledColumns.has(item.name) || (isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                                    [checked]="uncheckedAndDisabledColumns.has(item.name)?false:item.showInColumn"
                                    (change)="onSelectionChange(item)">
                                {{item.displayName}}
                            </mat-checkbox>
                        </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="groupHeaderLabel==='results' && !isMultiComparison" class="column-chooser-outputs groups">
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Show Index</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                        <td><span class="padding-left-1"><mat-checkbox
                                matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one metric is required.': ''}}"
                                [disabled]="(isAllColumnsUnSelectedExceptOne && item.showInColumn)||item.disable"
                                (change)="onIndexSelectionChange(item)"
                                [(ngModel)]="item.showInColumn"></mat-checkbox></span></td>
                        <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                        <td><span class="padding-left-1"><mat-checkbox
                                (change)="onIndexSelectionChange(item)"
                                matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showIndices?'At least one metric is required.': ''}}"
                                [disabled]="(isAllColumnsUnSelectedExceptOne && item.showIndices)||item.disableIndex"
                                [(ngModel)]="item.showIndices" (change)="onRowSelectionChange()"></mat-checkbox></span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="groupHeaderLabel==='results' && isMultiComparison" class="column-chooser-outputs groups">
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                        <td><span class="padding-left-1"><mat-radio-button name="column-chooser-radio"
                                                                           [checked]="item.showInColumn || item.showIndices"
                                                                           (change)="onRadioChange(item)"></mat-radio-button></span>
                        </td>
                        <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="groupHeaderLabel==='calibration-groups'" class="column-chooser-groups groups">
            <table>
                <thead>
                <tr class="tr-header-boarder">
                    <th></th>
                    <th>Column</th>
                </tr>
                <tr>
                    <th class="table-header-width"></th>
                    <th><span class="padding-left-1"><mat-checkbox [(ngModel)]="isAllColumnsSelected"
                                                                   [checked]="isAllColumnsSelected"
                                                                   [indeterminate]="isIntermediateColumnsSelected"
                                                                   (change)="onAllColumnsSelectionChange(columnData)"></mat-checkbox></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                    <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                    <td><span class="padding-left-1"><mat-checkbox
                            matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one item characteristic is required.': ''}}"
                            [disabled]="(isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                            [checked]="item.showInColumn"
                            (change)="onSelectionChange(item)"></mat-checkbox></span></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="groupHeaderLabel==='unCalibrated'" class="column-chooser-groups unCalibrated">
            <table>
                <thead>
                <tr class="tr-header-boarder">
                    <th></th>
                    <th>Column</th>
                </tr>
                <tr>
                    <th class="table-header-width"></th>
                    <th><span class="padding-left-1"><mat-checkbox [(ngModel)]="isAllColumnsSelected"
                                                                   [checked]="isAllColumnsSelected"
                                                                   [indeterminate]="isIntermediateColumnsSelected"
                                                                   (change)="onAllColumnsSelectionChange(columnData)"></mat-checkbox></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr class="menu-list-content" *ngFor="let item of columnData" (click)="$event.stopPropagation()">
                    <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                    <td><span class="padding-left-1"><mat-checkbox
                            matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one item characteristic is required.': ''}}"
                            [disabled]="(item.showAlways) ||  (isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                            [checked]="item.showInColumn"
                            (change)="onSelectionChange(item)"></mat-checkbox></span></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="groupHeaderLabel==='target'" class="column-chooser-groups target">
            <table>
                <thead>
                <tr class="tr-header-boarder">
                    <th></th>
                    <th>Column</th>
                </tr>
                <tr>
                    <th class="table-header-width"></th>
                    <th><span class="padding-left-1"><mat-checkbox [(ngModel)]="isAllColumnsSelected"
                                                                   [checked]="isAllColumnsSelected"
                                                                   [indeterminate]="isIntermediateColumnsSelected"
                                                                   (change)="onAllColumnsSelectionChange(columnData)"></mat-checkbox></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <div *ngFor="let item of columnData">
                    <tr *ngIf="!item.hide" class="menu-list-content" (click)="$event.stopPropagation()">
                        <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                        <td><span class="padding-left-1"><mat-checkbox
                                matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one item characteristic is required.': ''}}"
                                [disabled]="(item.showAlways) || ( isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                                [checked]="item.showInColumn"
                                (change)="onSelectionChange(item)"></mat-checkbox></span></td>
                    </tr>
                </div>

                </tbody>
            </table>
        </div>
        <div *ngIf="groupHeaderLabel==='calib-results'" class="column-chooser-groups target">
            <table>
                <thead>
                <tr class="tr-header-boarder">
                    <th></th>
                    <th>Column</th>
                </tr>
                <tr>
                    <th class="table-header-width"></th>
                    <th><span class="padding-left-1"><mat-checkbox [(ngModel)]="isAllColumnsSelected"
                                                                   [checked]="isAllColumnsSelected"
                                                                   [indeterminate]="isIntermediateColumnsSelected"
                                                                   (change)="onAllColumnsSelectionChange(columnData)"></mat-checkbox></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <div *ngFor="let item of columnData">
                    <tr *ngIf="!item.hide" class="menu-list-content" (click)="$event.stopPropagation()">
                        <td><span class="mat-checkbox-label">{{item.displayName}}</span></td>
                        <td><span class="padding-left-1"><mat-checkbox
                                matTooltip="{{isAllColumnsUnSelectedExceptOne && item.showInColumn?'At least one item characteristic is required.': ''}}"
                                [disabled]="(item.showAlways) || ( isAllColumnsUnSelectedExceptOne && item.showInColumn)"
                                [checked]="item.showInColumn"
                                (change)="onSelectionChange(item)"></mat-checkbox></span></td>
                    </tr>
                </div>

                </tbody>
            </table>
        </div>
    </mat-dialog-content>
    <div align="end">
        <button id="column-chooser-apply" class="mat-primary" [mat-dialog-close]="true" cdkFocusInitial mat-button
                [disabled]="!hasChanges" (click)="close();customDialogOpen = false">Apply
        </button>
    </div>
</div>
