import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {ModelRun} from '../models/model-run.model';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {Segment} from '../models/segment.model';
import {ModelCacheUtil} from '../utils/model-cache-util';
import {DataType, DropdownData, DropdownItem} from '../components/dropdown/dropdown.data.model';
import {MetaData} from '../models/meta-data.model';
import {AppConstantsService} from './app-constants.service';
import {UserConfigurationsService} from './user-configurations.service';

@Injectable({
  providedIn: 'root'
})
export class ModelRunService {

  activeModelRun: ModelRun = null;

  cache: ModelCacheUtil<ModelRun>;

  private segments$: Array<Segment> = [];

  readonly onRunIdChangedSubject: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private appConstantsService: AppConstantsService,
              private userConfigurationsService: UserConfigurationsService) {
    this.cache = new ModelCacheUtil<ModelRun>();
  }

  get modelRuns(): Array<ModelRun> {
    return this.cache.cachedModels;
  }

  /**
   * Returns single price elasticity report associated with given project and run id.
   */
  getById(id: string, params: { projectId: number; modelRunId: string }): Observable<ModelRun> {
    const modelRun = this.cache.find((it: ModelRun) => {
      return it.projectId === params.projectId && params.modelRunId === it.id;
    });
    if (modelRun) {
      this.activeModelRun = modelRun;
      return of(modelRun);
    } else {
      const env = this.environmentService.environment.authProxy;
      const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${params.projectId}/runs/${params.modelRunId}`;
      return this.http.get<ModelRun>(url).pipe(map((run: ModelRun) => {
        this.cache.append(run);
        this.activeModelRun = run;
        return run;
      }));
    }
  }

  /**
   * Returns an observable of ModelRun fetched using the API.
   * @param number: projectId The project id.
   * @returns Observable<Array<ModelRun>>: The project runs observable.
   */
  getModelRuns(projectId: number): Observable<Array<ModelRun>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs`;
    return this.http.get<Array<ModelRun>>(url).pipe(map((runs: ModelRun[]) => {
        this.cache.clear();
        this.cache.appendAll(runs);
        return runs;
      }
    ));
  }

  getModelRun(id: string): ModelRun {
    return this.cache.find((modelRun: ModelRun) => {
      return modelRun.id === id;
    });
  }

  fetchRunSegments(projectId: number, id: string): Observable<Array<Segment>> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${id}/segments`;
    return this.http.get<Array<Segment>>(url).pipe(map((segments: Segment[]) => {
        this.segments$ = segments;
        return segments;
      }
    ));
  }

  get segments(): Array<Segment> {
    return this.segments$;
  }

  getNSize(projectId: number, id: string, segments: Array<number>): Observable<any> {
    const env = this.environmentService.environment.authProxy;
    const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${id}/nSize?segments=${segments.join(',')}`;
    return this.http.get<any>(url);
  }

  /**
   * Prepares population filter drop down data for model run.
   * */
  getPopulationFilters(selectedSegmentsString: string, metaData: MetaData, segments: Segment[]): Array<DropdownData<string>> {
    const filters = [];
    const visibleSegments = segments.filter(segment => segment.showInSimulator);
    const selectedSegments = selectedSegmentsString.split(',');
    const isAls = metaData.projectType && metaData.projectType.toLowerCase().trim() === "als";

    visibleSegments.forEach((segment: Segment) => {
      let filter = filters.find(it => it.name === segment.groupName);
      if (!filter) {
        filter = {
          name: segment.groupName,
          dropdownLabel: segment.groupDisplayName,
          dataType: isAls ? DataType.RADIO : DataType.CHECKBOX,
          groupSelect: `All ${segment.groupDisplayName}`,
          data: []
        };
        filters.push(filter);
      }
      filter.data.push({
        name: segment.name,
        label: segment.displayName,
        value: segment.segment,
        selected: selectedSegments.indexOf(segment.segment + '') !== -1
      });
    });
    return filters;
  }

  getSelectedSegments(modelRun: ModelRun, segments: Segment[], filters: Array<DropdownData<string>>): Array<number> {
    const hiddenPreSelectedSegmentIds: Array<number> = segments
      .filter((it) => !it.showInSimulator)
      .map((it) => +it.segment);
    const selectedFilters = filters.reduce((accumulator: Array<number>, filterData: DropdownData<string>) => {
      filterData.data.forEach((it: DropdownItem<string>) => {
        if (it.selected) {
          accumulator.push(+it.value);
        }
      });
      return accumulator;
    }, hiddenPreSelectedSegmentIds).sort(function (a, b) {
      return a - b;
    });
    return [...(new Set(selectedFilters))];
  }

  unSelectedNonSameFilterExists(filters: Array<DropdownData<string>>): boolean {
    if (filters && filters.length) {
      const nonSampleFilters = filters.filter(it => it.name !== 'Sample');
      return nonSampleFilters.find(it => it.data.some(segment => !segment.selected)) !== undefined;
    }
    return false;
  }

  getUserRunLevelSelectedSegments(modelRun: ModelRun): string {
    const userConfig = this.userConfigurationsService.getUserSelectedSegmentsConfig();
    let selectedSegments = modelRun.selectedSegments;
    if (userConfig && userConfig.configurations && userConfig.configurations.userRunLevelSelectedSegments) {
      selectedSegments = userConfig.configurations.userRunLevelSelectedSegments;
    }
    return selectedSegments;
  }

  /**
   * Returns the n size population text based on filter selections
   */
  getNSizeText(filters: Array<DropdownData<string>>, nSize: number): string {
    let augmentDropDownItem: DropdownItem<string> = null;
    const nonAugmentDropDownItems: Array<DropdownItem<string>> = [];
    filters.forEach((filter: DropdownData<string>) => {
      filter.data.forEach((filterData: DropdownItem<string>) => {
        if (filterData.name.toLowerCase().trim() === 'augment') {
          augmentDropDownItem = filterData;
        } else {
          nonAugmentDropDownItems.push(filterData);
        }
      });
    });
    const isAllNonAugmentSegmentSelected = nonAugmentDropDownItems.every((item: DropdownItem<string>) => {
      return item.selected;
    });
    let nSizeText = isAllNonAugmentSegmentSelected ? `Sample (n = ${nSize})` : `Filtered Sample (n = ${nSize})`;

    if (augmentDropDownItem && augmentDropDownItem.selected) {
      nSizeText = `Sample with Over Quota (n = ${nSize})`;
    }
    return nSizeText;
  }

}
