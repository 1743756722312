<div class="export-report-container">
    <div class="export-report-header">
        <div class="optimization-report-name">
            <div class="report-name">
                <label>Run Name</label>
               <app-edit-input class="mat-edit-name" [data]='report.name' [charCounter]="'true'" [maxLength]="'25'" (changeEvent)="applyOptimizationReportName($event, false)" (focusOutEvent)='applyOptimizationReportName($event, true)'></app-edit-input>
               <mat-error class="validation-error">{{this.validationErrors.name}}</mat-error>
            </div>
            <div class="report-description">
                <label>Description</label>
                <app-edit-input class="mat-edit-name" [placeholder]="'Enter description'" [data]='report.description' [charCounter]="'true'" [maxLength]="'50'" (changeEvent)="applyOptimizationReportDescription($event)"></app-edit-input>
                <mat-error class="validation-error">{{this.validationErrors.description}}</mat-error>
            </div>
        </div>
        <div class="flex">
            <div class="optimization-method">
                <label>Optimization Method:</label>
                <app-ns-default-dropdown dropdwn [label]="this.report.method" class="dropdown-container">
                    <mat-radio-group>
                        <div class="menu-list-items" *ngFor="let optimizationMethod of optimizationMethods">
                            <mat-radio-button [checked]="this.report.method===optimizationMethod" (change)="onOptimizationMethodsChange(optimizationMethod)" [value]="optimizationMethod">
                                <span class="list-item">{{optimizationMethod}}</span>
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </app-ns-default-dropdown>
            </div>
            <span class="separator-vert"></span>
            <div>
                <button class="close-btn" mat-button (click)="closeCreateOptimizationReport()">Close</button>
                <button class="start-run-btn"  mat-flat-button [disabled]="disableStartRun? true : null" color="primary" (click)="startOptimization()">Start Run</button>
            </div>
        </div>
    </div>
    <div class="select-share-scenario">
        <div class="scenario-dropdown">
            <label>Scenario</label>
            <app-scenario-group-filter specificattr (dropDownChanged)="changeScenario($event)" (filter)="search($event)"
                                       [referenceScenarioData]="referenceScenarioData"
                                       [scenarioGroupsData]="scenarioGroupsData"
                                       [groupItemStatus]=false [enableActionMenu]=false
                                       [selectedScenarioIds]="selectedScenarioId"></app-scenario-group-filter>
        </div>
        <div class="metric-dropdown">
            <label>Metric</label>
            <mat-select placeholder="Select Metric" [(value)]="report.outputConfigMetric" (selectionChange)="onMetricChange($event.source.value)" class="mat-select-all">
                <mat-option *ngFor="let metric of metrics;" [value]="metric.id">{{metric.data.displayName}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div class="items-optimize">Select items for optimization (Hold Ctrl/Shift to select multiple checkboxes)</div>
    <div class="table-data-all mr-t-2">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Reporting Name</th>
                    <th>
                        <mat-checkbox class="complete-optimize-select-all checkbox-padding-right" [disabled]="(!optimizationSkuConfigs || !(optimizationSkuConfigs.length>0))" [checked]="completeAndOptimizeAllSelected"
                                      [indeterminate]="completeAndOptimizeIndeterminate"
                                      (change)="toggleCompleteAndOptimizeAllSelected($event)"></mat-checkbox>
                        Compete & Optimize
                    </th>
                    <th>
                        <mat-checkbox class="lock-in-optimize-select-all checkbox-padding-right" [disabled]="!(completeAndOptimizeAllSelected || completeAndOptimizeIndeterminate)"
                                      [checked]="lockInAndOptimizeAllSelected"
                                      [indeterminate]="lockInAndOptimizeIndeterminate"
                                      (change)="toggleLockInAndOptimizeAllSelected($event)"></mat-checkbox>
                        Lock-in & Optimize
                    </th>
                </tr>
            </thead>
            <tbody class="selectable-table">
                <tr *ngFor="let optimizationSkuConfig of optimizationSkuConfigs;" (click)="onSkuConfigRowClick($event, optimizationSkuConfig)" [className]="selectedOptimizationSkuConfigs.has(optimizationSkuConfig) ? 'selected' : ''" [id]="optimizationSkuConfig.skuId">
                    <td>{{optimizationSkuConfig.skuId}}</td>
                    <td>{{optimizationSkuConfig.reportingName}}</td>
                    <td><mat-checkbox class="compete-checkbox" value="{{optimizationSkuConfig.compete}}" [checked]="optimizationSkuConfig.compete" (change)="onSkuConfigCompeteToggle(optimizationSkuConfig.skuId)"></mat-checkbox></td>
                    <td><mat-checkbox class="lock-in-checkbox" value="{{optimizationSkuConfig.lockIn}}" [checked]="optimizationSkuConfig.lockIn" [disabled]="!optimizationSkuConfig.compete" (change)="onSkuConfigLockInToggle(optimizationSkuConfig.skuId)"></mat-checkbox></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="max-min-items mr-t-2">
        <div class="min-no-items">
            <label>Min # Items</label>
            <span><input matInput small placeholder="Enter Value" type="text" id="min-no-of-items" required="true" [class]="this.validationErrors.minNoOfItems?'error':''" [disabled]="!selectedScenarioId" [(ngModel)]="report.minNoOfItems" (focusout)="validateModelProperty('minNoOfItems')" (keyup)="validateModelProperty('minNoOfItems')"> of {{competingSkuConfigsLength}}
            </span>
            <span class="validation-error">{{this.validationErrors.minNoOfItems}}</span>
        </div>
        <div class="max-no-items">
            <label>Max # Items</label>
            <span><input matInput small placeholder="Enter Value" type="text" id="max-no-of-items" required="true" [class]="this.validationErrors.maxNoOfItems?'error':''" [disabled]="!selectedScenarioId" [(ngModel)]="report.maxNoOfItems" (focusout)="validateModelProperty('maxNoOfItems')" (keyup)="validateModelProperty('maxNoOfItems')"> of {{competingSkuConfigsLength}}
            </span>
            <span class="validation-error">{{this.validationErrors.maxNoOfItems}}</span>
        </div>
        <div class="no-of-combinations">
            <label># of Combinations</label>
            <span><input matInput small placeholder="Enter Value" type="text" id="no-of-combinations" required="true" [class]="this.validationErrors.noOfCombinations?'error':''" [(ngModel)]="report.noOfCombinations" (focusout)="validateModelProperty('noOfCombinations')" (keyup)="validateModelProperty('noOfCombinations')"></span>
            <span class="validation-error">{{this.validationErrors.noOfCombinations}}</span>
        </div>
    </div>
    <br>
    <div class="mr-t-2 rules-file mr-b-2">
        <label>Rules</label>
        <br>
        <div *ngIf="optimizationRule; then showOptimizationRulesBlock; else addOptimizationRulesBlock"></div>
        <ng-template #addOptimizationRulesBlock>
            <a ngxFilePicker accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (filePick)="importRules($event)"><span class="sif sif-add"></span>Rules File</a>
        </ng-template>
        <ng-template #showOptimizationRulesBlock>
            <span class="import-file"><span class="sif sif-document"></span>{{optimizationRule.fileName}}</span>
            <button class="node-btn-group custom-btn" mat-icon-button (click)="deleteOptimizationRule()"><span class="sif sif-delete"></span></button>
        </ng-template>
    </div>
</div>
