import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ModelRun} from '../../../models/model-run.model';
import {ModelRunService} from '../../../services/model-run.service';
import {isEmpty} from '../../../utils/object-utils';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.scss']
})
export class RunsComponent implements OnInit, OnDestroy, AfterViewChecked {

  runs: ModelRun[];
  selectedRunIndex: number;
  runsAvailable: boolean;
  subscriptions: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modelRunService: ModelRunService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.onRunIdChangeSubscription();

    this.subscriptions.add(this.router.events.subscribe((navigationEvent) => {
      if (navigationEvent instanceof NavigationEnd) {
        if (navigationEvent.url.match(/^\/projects\/[a-zA-Z0-9]+\/runs$/) || navigationEvent.url.match(/^\/projects\/[0-9]+$/)) {
          this.redirectToDefaultRun();
        }
      }
    }));

    this.subscriptions.add(this.route.parent.params.subscribe({
      next: () => {
        this.redirectToDefaultRun();
      }
    }));
  }

  redirectToDefaultRun(): void {
    const runs = this.modelRunService.modelRuns.filter(run => run.showInSimulator);
    this.runsAvailable = runs.length > 0;
    if (this.runsAvailable) {
      this.runs = runs;
      const activeRunTabIndex = localStorage.getItem('activeRunTabIndex_' + this.route.parent.snapshot.params.projectId);
      this.selectedRunIndex = !isEmpty(activeRunTabIndex) && +activeRunTabIndex <= (runs.length - 1) ? +activeRunTabIndex : 0;
      this.navigateToRun(this.selectedRunIndex);
    }
  }

  changeRun(selectedRunIndex: number): void {
    if (this.selectedRunIndex !== selectedRunIndex) {
      this.selectedRunIndex = selectedRunIndex;
      this.navigateToRun(selectedRunIndex);
      localStorage.setItem('activeRunTabIndex_' + this.route.parent.snapshot.params.projectId, `${selectedRunIndex}`);
    }
  }

  navigateToRun(runIndex: number): void {
    const run = this.runs[runIndex];
    if (run) {
      this.router.navigate([run.id], {relativeTo: this.route});
    } else {
      this.router.navigate(['/404']);
    }
  }

  onRunIdChangeSubscription(): void {
    this.subscriptions.add(this.modelRunService.onRunIdChangedSubject.subscribe((modelRunId: string) => {
      const currentRunIndex = this.runs.findIndex((modelRun) => {
        return modelRun.id === modelRunId;
      });
      if (this.selectedRunIndex !== currentRunIndex) {
        this.selectedRunIndex = currentRunIndex;
        localStorage.setItem('activeRunTabIndex_' + this.route.parent.snapshot.params.projectId, currentRunIndex.toString());
      }
    }));
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
