import {Pipe, PipeTransform} from '@angular/core';
import {ScenarioService} from '../services/scenario.service';
import {Scenario} from '../models/scenario.model';

@Pipe({
  name: 'getScenarioName',
  pure: true
})
export class GetScenarioNamePipe implements PipeTransform {

  private scenarioName = '';

  constructor(private scenarioService: ScenarioService) {
  }

  transform(scenarioId: string, projectId?: number, modelRunId?: string): string {
    this.scenarioService.getById(scenarioId, {projectId, modelRunId}).subscribe((scenario: Scenario) => {
      this.scenarioName = scenario.name;
    });
    return this.scenarioName;
  }
}
