<h2 mat-dialog-title>{{header}}</h2>
<mat-dialog-content>
    <p class="dialog-content">{{body}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="mat-secondary cancel-button" mat-button mat-dialog-close>{{cancelButtonLabel}}</button>
    <button *ngIf="showSaveButton" class="mat-secondary cancel-button" mat-button [mat-dialog-close]="true" (click)="onConfirm()">{{confirmButtonLabel}}</button>
    <button *ngIf="!showSaveButton" class="mat-flat-button mat-primary confirm-button" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="onConfirm()">{{confirmButtonLabel}}</button>
    <button *ngIf="showSaveButton" class="mat-flat-button mat-primary save-button" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="onSaveChanges()">{{saveChangesButtonLabel}}</button>

</mat-dialog-actions>
