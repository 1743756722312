<div (mouseleave)="hideDropDown()">
    <button class="drop-toggle btn flat" (click)="toggleDropDown()">
        <span class="selection-option-label" [class.disabled]="disable">{{selectedOptionLabel}}</span>
    </button>
    <div class="drop-show" *ngIf="showDropDown">
        <label *ngFor="let option of options">
            <input type="radio" [value]="option.value" [checked]="option.selected" (change)="selectOption(option)" />
            <span>{{option.label}}</span>
        </label>
    </div>
</div>
