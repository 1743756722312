import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AuthProxyService} from './services/auth-proxy.service';
import {User} from './models/user.model';
import {UiBlockerService} from './services/ui-blocker.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {EnvironmentService} from './services/environment.service';
import {Subscription} from 'rxjs';
import {GuardrailService} from './services/guardrail.service';
import {WebAnalyticsService} from '@app/services/web-analytics.service';
import {VERSION} from './../environments/version';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    title = 'LPO Simulator';
    userInfo: User;
    subscriptions: Subscription;
    userNavigationURL = 'userNavigation';

    public constructor(private router: Router,
                       private titleService: Title,
                       private authProxyService: AuthProxyService,
                       private uiBlockerService: UiBlockerService,
                       private dialog: MatDialog,
                       private translate: TranslateService,
                       private guardrailService: GuardrailService,
                       private environmentService: EnvironmentService,
                       private webAnalyticsService: WebAnalyticsService) {
        console.log(`Application version is: ${VERSION.hash}`);
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.setupAppLocale();
        this.titleService.setTitle(this.title);
        this.subscriptions.add(this.authProxyService.getUserInfo().subscribe((user: User) => {
            this.userInfo = user;
            this.webAnalyticsService.start(user);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get logoutURL(): string {
        return `${this.environmentService.environment.authProxy.url}/logout`;
    }

    setupAppLocale(): void {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en-US');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('en-US');
    }

    /**
     * Returns user navigation URL.
     */
    get navigationServiceURL(): string {
        const env = this.environmentService.environment.authProxy;
        const studioURL = `${env.url}/${env.studioContextPath}`;
        return `${studioURL}/${this.userNavigationURL}`;
    }
}
