import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {AppConstantsService} from '@app/services/app-constants.service';
import {ModelRunService} from '@app/services/model-run.service';

@Directive({
  selector: '[appReportSampleInfo]',
})
export class ReportSampleInfoDirective implements OnInit {

  @Input() appReportSampleInfo = null;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,
              private appConstantsService: AppConstantsService,
              private modelRunService: ModelRunService) {
  }

  ngOnInit(): void {
    const samples = [];
    const reportSelectedSegments = this.appReportSampleInfo.selectedSegments ? this.appReportSampleInfo.selectedSegments.split(',').map(it => +it) : [];
    const segments = this.modelRunService.segments;
    const segmentGroups = new Set(segments.map(it => it.groupDisplayName));
    let totalSample = reportSelectedSegments.length ? true : false;

    segmentGroups.forEach((segmentGroup) => {
      const segmentsInGroup = segments.filter(it => it.groupDisplayName === segmentGroup);
      if (!segmentsInGroup.every(it => reportSelectedSegments.indexOf(it.segment) !== -1)) {
        totalSample = false;
        const selectedSegmentsInGroup = segmentsInGroup.filter(it => reportSelectedSegments.indexOf(it.segment) !== -1);
        if (selectedSegmentsInGroup.length === 1) {
          samples.push(selectedSegmentsInGroup[0].displayName);
        } else if (selectedSegmentsInGroup.length > 0) {
          samples.push(`${selectedSegmentsInGroup.length} ${segmentGroup}`);
        }
      }
    });
    const sampleText = totalSample ? 'Total' : samples.join(', ');
    this.elementRef.nativeElement.setAttribute('title', sampleText);
    this.elementRef.nativeElement.innerText = sampleText.length > 20 ? `${sampleText.substr(0, 20)}...` : sampleText;
  }
}
