import {Component, OnInit} from '@angular/core';
import {ReportService} from '../../../services/report.service';

@Component({
  selector: 'app-sourcing-matrix',
  templateUrl: './sourcing-matrix.component.html',
  styleUrls: ['./sourcing-matrix.component.scss']
})
export class SourcingMatrixComponent implements OnInit {

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.reportService.activeReportSubject.next('sourcing-matrix');
  }

}
