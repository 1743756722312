<div class="mat-card">
    <h3 class="bulk-edit-header">
        Bulk Edit
        <button mat-icon-button aria-label="Close" (click)="onBulkEditClose()"><span class="sif sif-close"></span>
        </button>
    </h3>
    <div class="bulk-edit-body">
        <div *ngIf="metaData.hasPrice">
            <h4 class="sub-heading">Regular Price</h4>
            <ul>
                <li>
                    <mat-radio-button value="SET_REGULAR_PRICE_TO_DEFAULT" name="bulkEditPrice"
                                      [checked]="bulkEditInput.regularPrice.selectedBulkEdit==='SET_REGULAR_PRICE_TO_DEFAULT'"
                                      (change)="radioChangeHandler($event,'regularPrice')">Set Regular Price to default
                    </mat-radio-button>
                </li>
                <li>
                    <mat-radio-button value="SET_REGULAR_PRICE_TO_VALUE" name="bulkEditPrice"
                                      [checked]="bulkEditInput.regularPrice.selectedBulkEdit==='SET_REGULAR_PRICE_TO_VALUE'"
                                      (change)="radioChangeHandler($event,'regularPrice')">Set Regular Price to
                    </mat-radio-button>
                    <input id="set-regular-price-to" matInput [(ngModel)]="bulkEditInput.regularPrice.value"
                           [class]="validations.regularPrice.error && bulkEditInput.regularPrice.selectedBulkEdit==='SET_REGULAR_PRICE_TO_VALUE'?'error':''"
                           [disabled]="bulkEditInput.regularPrice.selectedBulkEdit!=='SET_REGULAR_PRICE_TO_VALUE'"
                           type="text"
                           (focusout)="validateSkuConfigs('SET_REGULAR_PRICE_TO_VALUE')">
                </li>
                <li>
                    <mat-radio-button value="CHANGE_REGULAR_PRICE_BY_PERCENTAGE" name="bulkEditPrice"
                                      [checked]="bulkEditInput.regularPrice.selectedBulkEdit==='CHANGE_REGULAR_PRICE_BY_PERCENTAGE'"
                                      (change)="radioChangeHandler($event,'regularPrice')">Change Regular Price by
                    </mat-radio-button>
                    <input id="change-regular-price-by-percentage" matInput
                           [(ngModel)]="bulkEditInput.regularPrice.percentage"
                           [class]="validations.regularPrice.error && bulkEditInput.regularPrice.selectedBulkEdit==='CHANGE_REGULAR_PRICE_BY_PERCENTAGE'?'error':''"
                           [disabled]="bulkEditInput.regularPrice.selectedBulkEdit!=='CHANGE_REGULAR_PRICE_BY_PERCENTAGE'"
                           type="text"
                           (focusout)="validateSkuConfigs('CHANGE_REGULAR_PRICE_BY_PERCENTAGE')">
                    <span class="sif-percentage">&#37;</span>
                </li>
            </ul>
            <div class="flex mat-error-div">
                <mat-error *ngIf="validations.regularPrice.error">
                    {{validations.regularPrice.error}}
                </mat-error>
            </div>
        </div>
        <div *ngIf="metaData.hasDistribution">
            <h4 class="sub-heading">Distribution</h4>
            <ul>
                <li>
                    <mat-radio-button value="SET_DISTRIBUTION_TO_DEFAULT" name="bulkEditDistribution"
                                      [checked]="bulkEditInput.distribution.selectedBulkEdit==='SET_DISTRIBUTION_TO_DEFAULT'"
                                      (change)="radioChangeHandler($event,'distribution')">Set Distribution to default
                    </mat-radio-button>
                </li>
                <li>
                    <mat-radio-button value="SET_DISTRIBUTION_TO_PERCENTAGE" name="bulkEditDistribution"
                                      [checked]="bulkEditInput.distribution.selectedBulkEdit==='SET_DISTRIBUTION_TO_PERCENTAGE'"
                                      (change)="radioChangeHandler($event,'distribution')">Set Distribution to
                    </mat-radio-button>
                    <input id="set-distribution-to" matInput [(ngModel)]="bulkEditInput.distribution.percentage"
                           [class]="validations.distribution.error && bulkEditInput.distribution.selectedBulkEdit==='SET_DISTRIBUTION_TO_PERCENTAGE'?'error':''"
                           [disabled]="bulkEditInput.distribution.selectedBulkEdit!=='SET_DISTRIBUTION_TO_PERCENTAGE'"
                           type="text"
                           (focusout)="validateSkuConfigs('SET_DISTRIBUTION_TO_PERCENTAGE')">
                    <span class="sif-percentage">&#37;</span>
                </li>
            </ul>
            <div class="flex mat-error-div">
                <mat-error *ngIf="validations.distribution.error">
                    {{validations.distribution.error}}
                </mat-error>
            </div>
        </div>
        <div *ngIf="metaData.promo!=='none'">
            <h4 class="sub-heading">Promotions</h4>
            <ul>
                <li>
                    <mat-radio-button value="SET_PROMOTION_PRICE_TO_DEFAULT" name="bulkEditPromotions"
                                      [checked]="bulkEditInput.promotion.selectedBulkEdit==='SET_PROMOTION_PRICE_TO_DEFAULT'"
                                      (change)="radioChangeHandler($event,'promotion')">Set Promotion Price to
                        Default
                    </mat-radio-button>
                </li>
                <li *ngIf="metaData.promo==='continuous'">
                    <mat-radio-button value="CHANGE_PROMOTION_PRICE_BY_PERCENTAGE" name="bulkEditPromotions"
                                      [checked]="bulkEditInput.promotion.selectedBulkEdit==='CHANGE_PROMOTION_PRICE_BY_PERCENTAGE'"
                                      (change)="radioChangeHandler($event,'promotion')">Change Promotion Price By
                    </mat-radio-button>
                    <input id="set-promo-price-to" matInput [(ngModel)]="bulkEditInput.promotion.promotionPercentage"
                           [class]="validations.promotions.error && bulkEditInput.promotion.selectedBulkEdit==='CHANGE_PROMOTION_PRICE_BY_PERCENTAGE'?'error':''"
                           [disabled]="bulkEditInput.promotion.selectedBulkEdit!=='CHANGE_PROMOTION_PRICE_BY_PERCENTAGE'"
                           type="text"
                           (focusout)="validateSkuConfigs('CHANGE_PROMOTION_PRICE_BY_PERCENTAGE')">
                    <span class="sif-percentage">&#37;</span>
                </li>
                <li *ngIf="metaData.promo==='both'">
                    <mat-radio-button value="SET_SPECIAL_PROMOTION_PRICE_TO_DEFAULT" name="bulkEditPromotions"
                                      [checked]="bulkEditInput.promotion.selectedBulkEdit==='SET_SPECIAL_PROMOTION_PRICE_TO_DEFAULT'"
                                      (change)="radioChangeHandler($event,'promotion')">Set Special Promotion Price to
                        Default
                    </mat-radio-button>
                </li>
                <li *ngIf="metaData.promo==='both'">
                    <mat-radio-button value="CHANGE_SPECIAL_PROMOTION_PRICE_BY_PERCENTAGE"
                                      name="bulkEditPromotions"
                                      [checked]="bulkEditInput.promotion.selectedBulkEdit==='CHANGE_SPECIAL_PROMOTION_PRICE_BY_PERCENTAGE'"
                                      (change)="radioChangeHandler($event,'promotion')">Change Special Promotion Price
                        By
                    </mat-radio-button>
                    <input id="set-special-promo-price-to" matInput
                           [(ngModel)]="bulkEditInput.promotion.specialPromotionPercentage"
                           [class]="validations.promotions.error && bulkEditInput.promotion.selectedBulkEdit==='CHANGE_SPECIAL_PROMOTION_PRICE_BY_PERCENTAGE'?'error':''"
                           [disabled]="bulkEditInput.promotion.selectedBulkEdit!=='CHANGE_SPECIAL_PROMOTION_PRICE_BY_PERCENTAGE'"
                           type="text"
                           (focusout)="validateSkuConfigs('CHANGE_SPECIAL_PROMOTION_PRICE_BY_PERCENTAGE')">
                    <span class="sif-percentage">&#37;</span>
                </li>
            </ul>
            <div class="flex mat-error-div">
                <mat-error *ngIf="validations.promotions.error">
                    {{validations.promotions.error}}
                </mat-error>
            </div>
        </div>
        <div *ngIf="metaData.promo!=='none'">
            <h4 class="sub-heading">Time on Promotion</h4>
            <ul>
                <li>
                    <mat-radio-button value="SET_TIME_ON_PROMO_TO_DEFAULT" name="bulkEditTimeOmPromo"
                                      [checked]="bulkEditInput.timeOnPromo.selectedBulkEdit==='SET_TIME_ON_PROMO_TO_DEFAULT'"
                                      (change)="radioChangeHandler($event,'timeOnPromo')">Set Time on Promotion to
                        Default
                    </mat-radio-button>
                </li>

                <li>
                    <mat-radio-button value="SET_TIME_ON_PROMO_TO_PERCENTAGE" name="bulkEditTimeOmPromo"
                                      [checked]="bulkEditInput.timeOnPromo.selectedBulkEdit==='SET_TIME_ON_PROMO_TO_PERCENTAGE'"
                                      (change)="radioChangeHandler($event,'timeOnPromo')">Set Time on promotion to
                    </mat-radio-button>
                    <input id="set-time-on-promo-to" matInput [(ngModel)]="bulkEditInput.timeOnPromo.percentage"
                           [class]="validations.timeOnPromo.error && bulkEditInput.timeOnPromo.selectedBulkEdit==='SET_TIME_ON_PROMO_TO_PERCENTAGE'?'error':''"
                           [disabled]="bulkEditInput.timeOnPromo.selectedBulkEdit!=='SET_TIME_ON_PROMO_TO_PERCENTAGE'"
                           type="text"
                           (focusout)="validateSkuConfigs('SET_TIME_ON_PROMO_TO_PERCENTAGE')">
                    <span class="sif-percentage">&#37;</span>
                </li>
            </ul>
            <div class="flex mat-error-div">
                <mat-error *ngIf="validations.timeOnPromo.error">
                    {{validations.timeOnPromo.error}}
                </mat-error>
            </div>
        </div>
    </div>
    <div class="bulk-edit-footer">
        <button id="apply-bulk-edit-options" mat-button [disabled]="disableBulkEditApplyChanges || hasError"
                (click)="applyChanges(false)">Apply Changes
        </button>
        <button id="save-bulk-edit-options" mat-flat-button color="primary"
                [disabled]="disableBulkEditApplyChanges || hasError"
                (click)="applyChanges(true)">Save
            & Close
        </button>
    </div>
</div>
