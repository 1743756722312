import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {MetaData} from '../models/meta-data.model';
import {catchError, map} from 'rxjs/operators';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {UtilService} from '@app/services/util.service';
import {MetaDataService} from '@app/services/meta-data.service';

@Injectable({providedIn: 'root'})
export class MetaDataResolver implements Resolve<MetaData> {

    constructor(private metaDataService: MetaDataService,
                private uiBlockerService: UiBlockerService,
                private utilService: UtilService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<MetaData> {
        this.uiBlockerService.block();
        const projectId = +this.utilService.getRouteParameter(route, 'projectId');
        const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
        return this.metaDataService.get(projectId, modelRunId).pipe(map((metaData) => {
            this.uiBlockerService.unblock();
            return metaData;
        }), catchError(() => {
            this.uiBlockerService.unblock();
            this.router.navigate(['/404']);
            return of(null);
        }));
    }
}
