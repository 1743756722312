import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UiBlockerService} from '../services/ui-blocker.service';
import {catchError, map} from 'rxjs/operators';
import {UtilService} from '../services/util.service';
import {SimulationRunInput} from '../models/simulation-run-input.model';
import {SimulationRunInputService} from '../services/simulation-run-input.service';

@Injectable({providedIn: 'root'})
export class SimulationRunInputResolver implements Resolve<SimulationRunInput> {

  constructor(private simulationRunInputService: SimulationRunInputService,
              private uiBlockerService: UiBlockerService,
              private utilService: UtilService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<SimulationRunInput> {
    this.uiBlockerService.block();
    const projectId = +this.utilService.getRouteParameter(route, 'projectId');
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    const scenarioId = this.utilService.getRouteParameter(route, 'scenarioId');
    return this.simulationRunInputService.fetchAll(projectId, modelRunId, scenarioId).pipe(map((inputs) => {
      this.uiBlockerService.unblock();
      return inputs;
    }), catchError(() => {
      this.uiBlockerService.unblock();
      this.router.navigate(['/404']);
      return of(null);
    }));
  }
}
