<h2 mat-dialog-title>Add New Group Set</h2>
<mat-dialog-content>
    <mat-form-field  appearance="standard">
        <label>Name of Group Set</label>
        <input matInput placeholder="Enter Name of Group Set">
     </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [disabled]=false [mat-dialog-close]="true" cdkFocusInitial color="primary">Save</button>
</mat-dialog-actions>

