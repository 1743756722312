import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AccessPolicyService} from '../../../../../services/access-policy.service';
import {Permission} from '@app/models/permission.model';

@Injectable({providedIn: 'root'})
export class PermissionResolver implements Resolve<any> {

  constructor(private accessPolicyService: AccessPolicyService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Permission[]> {
    return this.accessPolicyService.getEntityPermissions(route.params.modelRunId);
  }
}
