import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ProjectService} from '../services/project.service';
import {Collaborator} from '../models/collaborator.model';
import {catchError, map} from 'rxjs/operators';
import {UtilService} from '../services/util.service';
import {UiBlockerService} from '../services/ui-blocker.service';

@Injectable({providedIn: 'root'})
export class ProjectCollaboratorResolver implements Resolve<Collaborator[]> {

    constructor(private service: ProjectService,
                private utilService: UtilService,
                private uiBlockerService: UiBlockerService,
                private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Collaborator[]> {
        this.uiBlockerService.block();
        return this.service.getProjectCollaborators(+route.paramMap.get('projectId')).pipe(map((collaborators: Collaborator[]) => {
            this.uiBlockerService.unblock();
            return collaborators;
        }), catchError(() => {
            this.router.navigate(['/404']).then(() => {
                this.uiBlockerService.unblockAll();
            });
            return of(null);
        }));
    }
}
