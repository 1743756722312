<ng-template [ngIf]="showSkuConfigTableWithFlyout">
    <app-inputs-config-table
            [metaData]="metaData"
            [modelRunSkus]="modelRunSkus"
            [cellRenderers]="cellRenderers"
            [skuOrderConfig]="skuOrderConfig"
            [skuGroups]="skuGroups"
            [activeSkuGroup]="activeSkuGroup"
            [activeItemGrouping]="activeItemGrouping"
            (updateSkusItemGrouping)="updateSkusItemGrouping($event)"></app-inputs-config-table>
</ng-template>
<ng-template [ngIf]="showSkuConfigTableWithBulkEditFlyout">
    <app-bulk-edit-config-table
            [modelRunSkus]="modelRunSkus"
            [metaData]="metaData"
            [cellRenderers]="cellRenderers"
            [scenarioId]="scenarioId"></app-bulk-edit-config-table>
</ng-template>
<ng-template  [ngIf]="(!showSkuConfigTableWithFlyout && !showSkuConfigTableWithBulkEditFlyout)">
    <app-sku-config-table
            [hotTableData]="hotData"
            [cellRenderers]="cellRenderers"
            [tableSettings]="tableSettings"
            [scenarioId]="scenarioId"
            [skuGroups]="skuGroups"
            [outPutFieldsTotal]="outPutFieldsTotal"
            [metaData]="metaData"
            [hasUnSelectedNonSampleFilter]="hasUnSelectedNonSampleFilter"
            [clearOutputs]="clearOutputs"
            [reloadTableSubject]="reloadTable"
            [dropdownNotifier]="dropdownChange"
            [editMode]="editMode"
            (errorMessageEvent)="tableErrorEventHandler($event)"
            (tableChangesEvent)="tableDataChangeEventHandler($event)"
            (profitInputClickEvent)="profitInputClickEvent()"></app-sku-config-table>
</ng-template>
