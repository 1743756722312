<div class="export-report-container">
    <div class="export-report-header">
        <div class="price-report-name">
            <div class="report-name">
                <label>Run Name</label>
                <app-edit-input editInput class="mat-edit-name" [data]='report.name' [additionalClass]="validationErrors.name?'error':''" [charCounter]="'true'"
                    [maxLength]="reportNameMaxLength" (changeEvent)="applyReportName($event, false)"
                    (focusOutEvent)='applyReportName($event, true)'></app-edit-input>
                <mat-error class="validation-error">{{validationErrors.name}}</mat-error>
            </div>
            <div class="report-description">
                <label>Description</label>
                <app-edit-input editInput class="mat-edit-description" [placeholder]="'Enter description'" [data]='report.description' [charCounter]="'true'" [maxLength]="reportDescriptionMaxLength" (changeEvent)="applyReportDescription($event)"></app-edit-input>
                <mat-error class="validation-error">{{this.validationErrors.description}}</mat-error>
            </div>
        </div>
        <div>
            <button class="close-btn" mat-button (click)="closeReport()">Close</button>
            <button class="start-run-btn" mat-flat-button color="primary" [disabled]="disableStartRun? true : null" (click)="generateReport()">Start Run</button>
        </div>
    </div>
    <div class="report-dropdown-filters">
        <div class="scenario-dropdown">
            <label>Scenario</label>
            <app-scenario-group-filter specificattr (dropDownChanged)="changeScenario($event)" (filter)="search($event)"
                                       [referenceScenarioData]="referenceScenarioData"
                                       [scenarioGroupsData]="scenarioGroupsData"
                                       [groupItemStatus]=false
                                       [enableActionMenu]=false
                                       [selectedScenarioIds]="selectedScenarioId"></app-scenario-group-filter>
        </div>
        <div class="item-characteristics-dropdown">
            <label>Item Characteristic</label>
            <mat-select placeholder="Select Item Characteristic" [(value)]="report.skuGroupId" (selectionChange)="changeGroup($event.source.value)" class="mat-select-all">
                <mat-option [value]="-1">Item level</mat-option>
                <mat-option *ngFor="let skuGroup of skuGroups;" [value]="skuGroup.skuGroupId">{{skuGroup.displayName}}</mat-option>
            </mat-select>
        </div>
        <div class="metric-dropdown">
            <label>Metric</label>
            <mat-select placeholder="Select Metric" [(value)]="report.metric" (selectionChange)="changeMetric($event.source.value)" class="mat-select-all">
                <mat-option [value]="'share'">Share</mat-option>
                <mat-option [value]="'absolute'">Absolute</mat-option>
            </mat-select>
        </div>
    </div>
</div>
