import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AccessPolicyService} from '../../../../../services/access-policy.service';
import {AccessPolicy} from './../../../../../models/access-policy.model';

@Injectable({providedIn: 'root'})
export class AccessPolicyResolver implements Resolve<any> {

  constructor(private service: AccessPolicyService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AccessPolicy[]> {
    return this.service.getAccessPolicies(route.parent.params.modelRunId);
  }
}
