import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {UiBlockerService} from 'src/app/services/ui-blocker.service';
import {map} from 'rxjs/operators';
import {UserConfigurations} from '../models/user-configurations.model';
import {UserConfigurationsService} from '../services/user-configurations.service';
import {UtilService} from '../services/util.service';

@Injectable({providedIn: 'root'})
export class UserConfigurationsResolver implements Resolve<UserConfigurations[]> {

  constructor(private service: UserConfigurationsService,
              private utilService: UtilService,
              private uiBlockerService: UiBlockerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<UserConfigurations[]> {
    this.uiBlockerService.block();
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    const projectId = this.utilService.getRouteParameter(route, 'projectId');
    return this.service.getUserConfigurations(+projectId, modelRunId).pipe(map((configurations: Array<UserConfigurations>) => {
      this.uiBlockerService.unblock();
      return configurations;
    }));
  }
}
