import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Scenario} from '../models/scenario.model';
import {ScenarioService} from '../services/scenario.service';
import {UtilService} from '../services/util.service';
import {UiBlockerService} from '@app/services/ui-blocker.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ScenarioResolver implements Resolve<Scenario[]> {

  constructor(private service: ScenarioService,
              private utilService: UtilService,
              private uiBlockerService: UiBlockerService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Scenario[]> {
    this.uiBlockerService.block();
    const projectId = this.utilService.getRouteParameter(route, 'projectId');
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    return this.service.fetchAll(+projectId, modelRunId).pipe(map((scenarios: Scenario[]) => {
      this.uiBlockerService.unblock();
      return scenarios;
    }), catchError(() => {
      this.uiBlockerService.unblock();
      return of(null);
    }));
  }
}
