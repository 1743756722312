<div class="flex margin-bottom-2 mat-card-box">
    <app-scenario-group-filter id="multiSelectDropDown" [showCheckbox]=true [enableActionMenu]=false
                               [selectedScenarioIds]="multiSelectedScenarioIds"
                               (dropDownChanged)="multiSelectChanged($event)" (filter)="multiSelectFilter($event)"
                               [referenceScenarioData]="multiSelectReferenceScenarioData"
                               [scenarioGroupsData]="multiSelectScenarioGroupsData"></app-scenario-group-filter>
    <button mat-icon-button class="create-scenario-btn ml-3 compare-btn-set" (click)=toggleCompareModel($event)>
        <span class="sif sif-comparison"></span>
    </button>
    <app-scenario-group-filter id="selectDropDown" [enableActionMenu]=false (dropDownChanged)="selectChanged($event)"
                               (filter)="selectFilter($event)"
                               [placeholder]="'Select Base Scenario'"
                               [referenceScenarioData]="selectReferenceScenarioData"
                               [scenarioGroupsData]="selectScenarioGroupsData"></app-scenario-group-filter>
    <button class="hide-save" mat-icon-button disabled><span class="sif sif-save"></span></button>
    <div class="btn-group-right">
        <button id="compareScenariosButtonId" mat-flat-button color="primary" (click)="compareScenarios()" [disabled]="!enableCompare">compare</button>
    </div>
</div>
<mat-card class="compare-table-container">
    <div class="compare-filters-container">
        <div class="selected-options">
            <div class="filter-menu-list">
                <app-population-filter
                        [disable]="disablePopulationFilters"
                        [filters]="filters"
                        [nSize]="nSize"
                        [nSizeClass]="lowPopulationMsgType"
                        (selectionChange)="onFilterSelectionChange($event)">
                </app-population-filter>
            </div>
            <span class="separator-vert seprator"></span>
            <div class="show-inputs">
                <span>Show Inputs</span>
                <app-ns-default-dropdown dropdwn [label]="inputsLabel" class="dropdown-container"
                                         [isDisable]="inputScenariosList.length < 1">
                    <ul class="input-list">
                        <li id="inputScenarioNone" (click)="changeComparisonInputs('0')">None</li>
                        <li *ngFor="let inputScenario of inputScenariosList" [id]="inputScenario"
                            (click)="changeComparisonInputs(inputScenario)">{{getScenario(inputScenario).name}}</li>
                    </ul>
                </app-ns-default-dropdown>
                <!--<span class="separator-vert seprator"></span>
                <mat-radio-group class="scenario-radio-group">
                    <mat-radio-button class="scenario-radio-button">Compare to Base</mat-radio-button>
                    <mat-radio-button class="scenario-radio-button">Compare in Sequence</mat-radio-button>
                </mat-radio-group>-->
            </div>
        </div>
        <div class="adjust-icons">
            <button id="export-compare" [disabled]="isExportButtonDisable || enableCompare" mat-icon-button
                    title="Export" (click)="exportScenarioComparison()"><span class="sif sif-export"></span></button>
            <span class="separator-vert"></span>
        </div>
    </div>
    <app-message-prompt *ngIf="lowPopulation" [message]="lowPopulationMsg"
                        [type]="lowPopulationMsgType"></app-message-prompt>
    <app-message-prompt *ngIf="outputsFilteredOnNonSampleFilterToggleTooltip" [message]="outputsFilteredOnNonSampleFilterToggleTooltip" [type]="'warn'"  class="non-sample-filter-engage-warning"></app-message-prompt>
    <div>
        <app-compare-sku-config-table
                [hotTableData]="hotData"
                [compareTableSettings]="compareTableSettings"
                [metaData]="metaData"
                [dropdownNotifier]="dropdownChange"
                [skuGroups]="skuGroups"
                [outPutFieldsTotal]="outPutFieldsTotal"
                [compareScenarioIds]="multiSelectedScenarioIds"
                [baseScenarioId]="selectScenarioId"
                [cellRenderers]="cellRenderers"
                [generalSetting]="generalSetting"
                [hasUnSelectedNonSampleFilter]="hasUnSelectedNonSampleFilter"
                (columnChooserClick)="columnChooserClick(userConfigurations)"
                (activeGroupClick)="activeGroupClick($event)"
        ></app-compare-sku-config-table>

    </div>
</mat-card>
