import {Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {of as ofObservable, Observable} from 'rxjs';
import {ScenarioGroup} from '@app/models/scenario-group.model';
import {ExportGroup, Scenario} from '@app/models/scenario.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AuthProxyService} from '@app/services/auth-proxy.service';
import {GuardrailService} from '@app/services/guardrail.service';
import {Guardrail} from '@app/models/guardrail.model';
import {ScenarioGroupService} from '@app/services/scenario-group.service';
import {AppConstantsService} from '@app/services/app-constants.service';
import {UserConfigurationsService} from '@app/services/user-configurations.service';
import {ModelRunService} from '@app/services/model-run.service';
import {ScenarioService} from '@app/services/scenario.service';
import { FormControl } from '@angular/forms';

export class ScenarioNode {
  children: ScenarioNode[];
  scenarioId: string;
  name: string;
  description: string;
  createdBy: string;
  simulatedOn: string;
  hasParent: boolean; // this property is needed to set proper class to align checkboxes for ref/calibration scenarios
  

  constructor(scenarioId: string,
              name: string,
              description: string,
              createdBy: string,
              children: ScenarioNode[],
              simulatedOn: string,
              hasParent = false) {
    children.forEach(child => child.hasParent = true);
    this.scenarioId = scenarioId;
    this.name = name;
    this.description = description;
    this.createdBy = createdBy;
    this.children = children;
    this.hasParent = hasParent;
    this.simulatedOn = simulatedOn;
  }
}

export class ScenarioFlatNode {
  scenarioId: string;
  level: number;
  expandable: boolean;
  type: string;
  name: string;
  description: string;
  createdBy: string;
  isChecked: boolean;
  hasParent: boolean;
  simulatedOn: string;
}

@Component({
  selector: 'app-export-scenario-modal',
  templateUrl: './export-scenario-modal.component.html',
  styleUrls: ['./export-scenario-modal.component.scss']
})
export class ExportScenarioModalComponent implements OnInit, AfterViewInit {

  exportWarnMessage: string;

  disableExportButton: boolean;

  treeControl: FlatTreeControl<ScenarioFlatNode>;

  treeFlattener: MatTreeFlattener<ScenarioNode, ScenarioFlatNode>;

  dataSource: MatTreeFlatDataSource<ScenarioNode, ScenarioFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ScenarioFlatNode>(true /* multiple */);

  exportingForChartingTool = false;

  exportingForSharePoint = false;
  
  selectedExportOption: any;

  exportGroup: ExportGroup[] = [
    {name: 'TO EXCEL', disabled: true, exportOptions : [{ id: 'client', name: 'for Client'},{ id: 'chartingTool', name: 'for Charting Tool'}]},
    {name: 'TO SHAREPOINT', disabled: true, exportOptions : [{ id: 'sharePoint', name: 'for Power BI'}]}
  ];

  constructor(public dialogRef: MatDialogRef<ExportScenarioModalComponent>,
              private guardrailService: GuardrailService,
              private authProxyService: AuthProxyService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private scenarioGroupService: ScenarioGroupService,
              private appConstantsService: AppConstantsService,
              private userConfigurationsService: UserConfigurationsService,
              private modelRunService: ModelRunService,
              private scenarioService: ScenarioService) {

  }

  get isInternalUser(): boolean {
    return this.authProxyService.isInternalUser();
  }

  getLevel(node: ScenarioFlatNode): number {
    return node.level;
  }

  isExpandable(node: ScenarioFlatNode): boolean {
    return node.expandable;
  }

  getChildren = (node: ScenarioNode): Observable<ScenarioNode[]> => {
    return ofObservable(node.children);
  }

  hasChild(index: number, node: ScenarioFlatNode): boolean {
    return node.expandable;
  }

  get selectedScenarioNodes(): ScenarioFlatNode[] {
    return this.treeControl.dataNodes.filter(node => !node.expandable && this.checklistSelection.isSelected(node));
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ScenarioNode, level: number) => {
    return {
      scenarioId: node.scenarioId,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      description: node.description,
      createdBy: node.createdBy,
      hasParent: node.hasParent,
      simulatedOn: node.simulatedOn,
      level
    } as ScenarioFlatNode;
  }

  allScenariosSelected(): boolean {
    const nodes = this.treeControl.dataNodes.filter(node => !node.expandable);
    return nodes.every(node => this.checklistSelection.isSelected(node));
  }

  someScenariosSelected(): boolean {
    const nodes = this.treeControl.dataNodes.filter(node => !node.expandable);
    return nodes.some(node => this.checklistSelection.isSelected(node)) && !this.allScenariosSelected();
  }

  toggleAllScenarios(event: MatCheckboxChange): void {
    const nodes = this.treeControl.dataNodes.filter(node => !node.expandable);
    if (event.checked) {
      this.checklistSelection.select(...nodes);
    } else {
      this.checklistSelection.deselect(...nodes);
    }
    this.toggleExportButton();
  }

  toggleScenario(node: ScenarioFlatNode): void {
    this.checklistSelection.toggle(node);
    this.toggleExportButton();
  }

  toggleExportButton(): void {
    const uniqueSelectedScenario = new Set(this.selectedScenarioNodes.map(node => node.scenarioId));
    this.guardrailService.getByName('MAX_SCENARIO_EXPORT_COUNT').subscribe((guardrail: Guardrail) => {
      if (guardrail) {
        const guardrailFail = uniqueSelectedScenario.size > guardrail.value;
        this.disableExportButton = uniqueSelectedScenario.size === 0 || guardrailFail;
        this.exportWarnMessage = guardrailFail ? `Please limit your selection to ${guardrail.value} scenarios at a time.` : null;
      } else {
        this.disableExportButton = uniqueSelectedScenario.size === 0;
      }
    });
  }

  /** Whether all the descendants of the node are selected */
  allScenariosInGroupSelected(node: ScenarioFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const allScenariosInGroupSelected = descendants.every(child => this.checklistSelection.isSelected(child));
    const noneScenariosInGroupSelected = descendants.every(child => !this.checklistSelection.isSelected(child));
    if (allScenariosInGroupSelected) {
      this.checklistSelection.select(node);
    } else if (noneScenariosInGroupSelected) {
      this.checklistSelection.deselect(node);
    }
    return allScenariosInGroupSelected;
  }

  /** Whether part of the descendants are selected */
  someScenariosInGroupSelected(node: ScenarioFlatNode): boolean {
    const scenariosInGroupNodes = this.treeControl.getDescendants(node);
    const result = scenariosInGroupNodes.some(child => this.checklistSelection.isSelected(child));
    return result && !this.allScenariosInGroupSelected(node);
  }

  toggleScenarioInGroup(node: ScenarioFlatNode): void {
    this.checklistSelection.toggle(node);
    const scenariosInGroupNodes = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...scenariosInGroupNodes)
      : this.checklistSelection.deselect(...scenariosInGroupNodes);
    this.disableExportButton = this.selectedScenarioNodes.length === 0;
    this.toggleExportButton();
  }

  ngAfterViewInit(): void {
    const nodes: Array<ScenarioNode> = this.dataSource.data;
    if (nodes.some(node => node.children.length > 0)) {
      this.treeControl.expandAll();
    }
  }

  scenarioDataTree(scenarioGroups: Array<ScenarioGroup>, scenarios: Array<Scenario>): Array<ScenarioNode> {
    const nodes = [];
    const referenceScenario = scenarios.find(it => it.name === 'Reference Scenario');

    if (referenceScenario) {
      nodes.push(new ScenarioNode(referenceScenario.id,
          referenceScenario.name,
          referenceScenario.description,
          null,
          [],
          this.scenarioService.getSimulatedOn(referenceScenario.id)));
    }

    scenarioGroups.forEach((scenarioGroup: ScenarioGroup) => {
      const children = [];
      this.scenarioGroupService.scenariosOrderForGroup(scenarioGroup).map((assignedScenarioId) => {
        const scenario = scenarios.find(it => it.id === assignedScenarioId);
        if (scenario) {
          children.push(new ScenarioNode(scenario.id, scenario.name, scenario.description, scenario.createdBy, [], this.scenarioService.getSimulatedOn(scenario.id)));
        } else {
          console.log(`No scenario by id ${assignedScenarioId} found referenced in scenario group "${scenarioGroup.name}"(${scenarioGroup.id})`);
        }
      });
      if (children.length) {
        nodes.push(new ScenarioNode(null, scenarioGroup.name, null, null, children, null));
      }
    });
    return nodes;
  }


  ngOnInit(): void {
    this.disableExportButton = true;
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ScenarioFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = this.scenarioDataTree(this.data.scenarioGroups, this.data.scenarios);
    this.selectedExportOption =  this.exportGroup[0].exportOptions[0].id;
  }

  exportSelectedScenarios(): void {
    this.dialogRef.close({
      exportScenarios: this.selectedScenarioNodes.map(it => it.scenarioId),
      exportingForChartingTool: this.selectedExportOption == "chartingTool" ? true : false,
      exportingForSharePoint: this.selectedExportOption == "sharePoint" ? true : false
    });
  }

  updateExportOption(event){
    if(event.source.selected) {
      this.selectedExportOption = event.source.value;
    }
  }

}
