import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {CreatePriceElasticityReportComponent} from './create-price-elasticity-report.component';

@Injectable()
export class CreatePriceElasticityReportDeactivateGuard implements CanDeactivate<CreatePriceElasticityReportComponent> {

  canDeactivate(component): Promise<boolean> {
    return component.canDeactivate();
  }
}
