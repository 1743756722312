import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppConstantsService} from '@app/services/app-constants.service';
import {MetaDataService} from '@app/services/meta-data.service';

@Component({
  selector: 'app-column-chooser',
  templateUrl: './column-chooser.component.html',
  styleUrls: ['./column-chooser.component.scss']
})
export class ColumnChooserComponent implements OnInit {
  columnData: any;
  customDialogOpen: boolean;
  isAllColumnsUnSelectedExceptOne: boolean;
  hasChanges: boolean;
  groupHeaderLabel: string;
  headerLabel: string;
  isMultiComparison: boolean;
  isAllColumnsSelected: boolean;
  isIntermediateColumnsSelected: boolean;
  isAllRowsSelected: boolean;
  isIntermediateRowsSelected: boolean;
  calibrationStatus: number;
  /**
   * Columns that should be unchecked and disabled. Applicable only for output/results columns.
   * This is usually set when a non sample filter is toggled, and based on meta data settings
   * we either need to how just shares, or just preference share.
   * */
  uncheckedAndDisabledColumns: Set<string>;

  /**
   * Similar to uncheckedAndDisabledColumns, but in indicates which output column types needs to be disabled and unchecked.
   * Applicable only for output/results columns.
   * Values could be : Shares only|Preference Share only|Shares & Absolutes
   * */
  outputsOnNonSampleFilterToggle: string;

  constructor(
    public dialogRef: MatDialogRef<ColumnChooserComponent>,
    private appConstantService: AppConstantsService,
    private metaDataService: MetaDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.columnData = this.data.columnData;
    this.customDialogOpen = this.data.customDialogOpen;
    this.hasChanges = false;
    this.uncheckedAndDisabledColumns = this.data.uncheckedAndDisabledColumns ? this.data.uncheckedAndDisabledColumns : new Set([]);
    this.outputsOnNonSampleFilterToggle = this.data.outputsOnNonSampleFilterToggle ? this.data.outputsOnNonSampleFilterToggle : null;
    this.groupHeaderLabel = this.data.groupHeaderLabel;
    this.headerLabel = this.groupHeaderLabel !== 'groups' ? 'Show/Hide Columns' : 'Show/Hide Columns and Rows';
    this.isMultiComparison = this.data.isMultiComparison ? this.data.isMultiComparison : false;
    this.isAllColumnsUnSelectedExceptOne = this.checkIsAllColumnsUnSelectedExceptOne();
    this.calibrationStatus = this.data.calibrationStatus;
    this.allSelectedStatusCheck();
  }

  checkIsAllColumnsUnSelectedExceptOne(): boolean {
    let selectedColumns = this.columnData.filter(columnConfig => columnConfig.showInColumn);
    const selectedIndices = this.columnData.filter(column => column.showIndices);
    if (this.groupHeaderLabel === 'results') {
      return selectedColumns.length + selectedIndices.length === 1;
    } else if (this.groupHeaderLabel === 'outputs') {
      selectedColumns = selectedColumns.filter(column => !this.uncheckedAndDisabledColumns.has(column.name));
      return selectedColumns.length === 1;
    } else {
      return selectedColumns.length === 1;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  onSelectionChange(item): void {
    item.showInColumn = !item.showInColumn;
    const selectedColumns = this.columnData.filter(column => column.showInColumn && !this.uncheckedAndDisabledColumns.has(column.name));
    this.isAllColumnsUnSelectedExceptOne = selectedColumns.length === 1;
    this.hasChanges = true;
    this.allSelectedStatusCheck();
  }

  onRowSelectionChange(): void {
    this.hasChanges = true;
    this.allSelectedStatusCheck();
  }

  onAllColumnsSelectionChange(columnData: any): void {
    columnData.forEach(item => {
      item.showInColumn = item.showAlways ? true : this.isAllColumnsSelected;
    });
    if (!this.isAllColumnsSelected && columnData.length > 0) {
      columnData[0].showInColumn = true;
      this.isAllColumnsUnSelectedExceptOne = true;
    } else {
      const selectedColumns = this.columnData.filter(column => column.showInColumn && !this.uncheckedAndDisabledColumns.has(column.name));
      this.isAllColumnsUnSelectedExceptOne = selectedColumns.length === 1;
    }
    this.hasChanges = true;
    this.allSelectedStatusCheck();
  }

  onAllRowsSelectionChange(columnData: any): void {
    columnData.forEach(item => {
      item.showInRow = this.isAllRowsSelected;
    });
    this.allSelectedStatusCheck();
    this.hasChanges = true;
  }

  allSelectedStatusCheck(): void {
    const totalCount = this.columnData.filter(column => !this.uncheckedAndDisabledColumns.has(column.name)).length;
    const selectedColumns = this.columnData.filter(column => column.showInColumn && !this.uncheckedAndDisabledColumns.has(column.name)).length;
    const selectedRows = this.columnData.filter(column => column.showInRow && !this.uncheckedAndDisabledColumns.has(column.name)).length;
    this.isAllColumnsSelected = totalCount == selectedColumns;
    this.isAllRowsSelected = totalCount == selectedRows;
    this.isIntermediateColumnsSelected = !this.isAllColumnsSelected && selectedColumns > 0;
    this.isIntermediateRowsSelected = !this.isAllRowsSelected && selectedRows > 0;
  }

  onIndexSelectionChange(item) {
    const selectedColumns = this.columnData.filter((column) => {
      return column.showInColumn;
    });
    const selectedIndices = this.columnData.filter((column) => {
      return column.showIndices;
    });
    this.isAllColumnsUnSelectedExceptOne = selectedColumns.length + selectedIndices.length === 1;
    this.hasChanges = true;
  }

  onRadioChange(item) {
    this.columnData.map(col => {
      if (item.name !== col.name) {
        col.showInColumn = false;
        col.showIndices = false;
      }
    });
    item.showInColumn = true;
    item.showIndices = true;
    this.hasChanges = true;
  }

  get uncheckedAndDisabledOutputColumnTooltip(): string {
    return this.metaDataService.tooltipOnNonSampleFilterEngage(this.outputsOnNonSampleFilterToggle);
  }

}
