<div class="mat-card">
    <h3 class="settings-header">
        Advanced Settings
        <button mat-icon-button aria-label="Close" (click)="onClose()"><span class="sif sif-close"></span>
        </button>
    </h3>
    <div class="settings-body">
        <div class="view-adjustments">
            <div>
                <span class="title-settings">ADJUSTMENTS</span>
                <mat-checkbox id="packRateAdjuster" [(ngModel)]="usePackRateAdjuster"
                              (click)="togglePackRateAdjuster()">
                    <span>Use Pack Rate Adjuster</span>
                </mat-checkbox>
                <div *ngIf="adminView">
                <mat-checkbox id="calibrationAdjust" [(ngModel)]="calibratePrice"
                              (click)="toggleOptimizeCalibrationLock()">
                    <span> Calibrate price </span>
                </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox id="calibrationTau" [(ngModel)]="isTauThreshold" (change)="toggleTauThreshold()">
                        <span> Adjust Tau Threshold </span>
                    </mat-checkbox>
                    <div [hidden]="!isTauThreshold" class="collapsible" @tauExpansion *ngIf="isTauThreshold">
                        <section class="section-details">
                            <div class="tau-threshold-min">
                                <label>Min</label>
                                <input matInput [(ngModel)]="minTau" id="minTau" (keyup)="validateMinTau()"
                                       [ngClass]="{'error':tauValueMinError}"/>
                                <span class="validation-error">{{tauValueMinError}}</span>
                            </div>
                            <div class="tau-threshold-max">
                                <label>Max</label>
                                <input matInput [(ngModel)]="maxTau" id="maxTau" (keyup)="validateMaxTau()"
                                       [ngClass]="{'error':tauValueMaxError}"/>
                                <span class="validation-error">{{tauValueMaxError}}</span>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div>
                <span class="title-settings">UNITS SCALING</span>
                <div class="category-details">
                    <label>Category Total</label>
                    <input matInput
                           id="totalCategory"
                           [ngClass]="{'error':totalCategoryError}"
                           [(ngModel)]="totalCategory"
                           (keyup)="validateTotalCategory()">
                    <span class="category-validation-error">{{totalCategoryError}}</span>
                </div>
            </div>
            <div *ngIf="adminView">
                <span class="title-settings">DISTRIBUTION SCALING</span>
                <section class="section-details">
                    <div class="linear-scale">
                        <label>Linear</label>
                        <input matInput
                               id="linearScale"
                               [ngClass]="{'error':linearScaleError}"
                               [(ngModel)]="linearScale"
                               (keyup)="validateLinearScale()">
                        <span class="validation-error">{{linearScaleError}}</span>
                    </div>
                    <div class="exponential-scale">
                        <label>Exponential</label>
                        <input matInput
                               id="exponentialScale"
                               [ngClass]="{'error':exponentialScaleError}"
                               [(ngModel)]="exponentialScale"
                               (keyup)="validateExponentialScale()">
                        <span class="validation-error">{{exponentialScaleError}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <div class="settings-footer">
        <button mat-button [disabled]="disableSaveButton? true : null" (click)="onSave(false)">Apply Changes</button>
        <button mat-flat-button color="primary" [disabled]="disableSaveButton? true : null" (click)="onSave(true)">Save & Close</button>
    </div>
</div>
