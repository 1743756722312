import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss']
})
export class EditInputComponent implements OnInit {

  @Input() charCounter: boolean;
  @Input() maxLength: number;
  @Input() data: string;
  @Input() additionalClass = '';
  @Input() placeholder = '';
  @Output() focusOutEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeEvent: EventEmitter<string> = new EventEmitter<string>();

  editMode = false;
  lengthValidationClass: string;

  ngOnInit(): void {
    this.lengthValidationClass = this.getLengthValidationClass(this.data);
  }

  onFocusOut(): void {
    this.focusOutEvent.emit(this.data);
  }

  enableEdit(): void {
    this.editMode = true;
  }

  disableEdit(): void {
    this.editMode = false;
  }

  onKeyUp(): void {
    this.checkInputDataLength(this.data);
    this.changeEvent.emit(this.data);
  }

  checkInputDataLength(val: string): void {
    this.lengthValidationClass = this.getLengthValidationClass(val);
  }

  getLengthValidationClass(val: string): string {
    if (val && val.length > this.maxLength) {
      return 'error';
    } else if (val && val.length > (this.maxLength / 2)) {
      return 'warn';
    } else {
      return '';
    }
  }
}
