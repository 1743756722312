import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UtilService} from '../../../services/util.service';
import {PriceChangeImpactReport} from '../../../models/price-change-impact-report.model';
import {PriceChangeImpactService} from '../../../services/price-change-impact.service';
import {UiBlockerService} from '../../../services/ui-blocker.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PriceChangeImpactResolver implements Resolve<Observable<Array<PriceChangeImpactReport>>> {

  constructor(private service: PriceChangeImpactService, private utilService: UtilService, private uiBlockerService: UiBlockerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<PriceChangeImpactReport>> {
    this.uiBlockerService.block();
    const projectId = this.utilService.getRouteParameter(route, 'projectId');
    const modelRunId = this.utilService.getRouteParameter(route, 'modelRunId');
    return this.service.fetchAll(+projectId, modelRunId).pipe(map((reports: PriceChangeImpactReport[]) => {
      this.uiBlockerService.unblock();
      return reports;
    }));
  }
}
