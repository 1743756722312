import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {of as observableOf, BehaviorSubject, Subscription, forkJoin} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {CdkDragDrop, CdkDragMove, CdkDragRelease} from '@angular/cdk/drag-drop';
import {Scenario} from '../../../../../models/scenario.model';
import {ScenarioGroup} from '../../../../../models/scenario-group.model';
import {ScenarioService} from '../../../../../services/scenario.service';
import {ScenarioGroupService} from '../../../../../services/scenario-group.service';
import {SnackBarService} from '../../../../../components/snack-bar/snack-bar.service';
import {UiBlockerService} from '../../../../../services/ui-blocker.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationModalComponent} from '../../../../../components/confirmation-modal/confirmation-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {cloneJson} from '../../../../../utils/object-utils';
import {AccessPolicyService} from '../../../../../services/access-policy.service';
import {AppConstantsService} from '../../../../../services/app-constants.service';
import {Collaborator} from '../../../../../models/collaborator.model';
import {SharingModalComponent} from '../../../../../components/sharing-modal/sharing-modal.component';
import {Permission} from '../../../../../models/permission.model';
import {ProjectService} from '../../../../../services/project.service';
import {User} from '../../../../../models/user.model';
import {AuthProxyService} from '../../../../../services/auth-proxy.service';
import {AccessPolicy} from '../../../../../models/access-policy.model';
import * as moment from 'moment';
import {ModelRunService} from '../../../../../services/model-run.service';
import {UserConfigurations} from '../../../../../models/user-configurations.model';
import {UserConfigurationsService} from '../../../../../services/user-configurations.service';
import {Calibration} from '@app/models/calibration/calibration.model';
import {CalibrationService} from '@app/services/calibration.service';
import {WebMessageService} from '@app/services/web-message.service';

/* File node data with nested structure. */
export class PARENTNODE {
    id: string;
    parentId: string;
    name: string;
    children: PARENTNODE[];
    description: string;
    type: string;
    owner: Collaborator;
    isChecked: boolean;
    isNameDuplicate: boolean;
    changed: boolean;
    hasError: boolean;
    conditions: any;
    permissions: any;
}

/* Flat node with expandable and level information */
export class CHILDNODE {
    constructor(
        public id: string,
        public parentId: string,
        public name: string,
        public children: PARENTNODE[],
        public level: number,
        public expandable: boolean,
        public description: string,
        public type: string,
        public owner: Collaborator,
        public isChecked: boolean,
        public isNameDuplicate: boolean,
        public changed: boolean,
        public hasError: boolean,
        public conditions: any,
        public permissions: any,
    ) {
    }
}

@Component({
    selector: 'app-manage-scenarios',
    templateUrl: './manage-scenarios.component.html',
    styleUrls: ['./manage-scenarios.component.scss'
    ]
})

export class ManageScenariosComponent implements OnInit, AfterViewInit, OnDestroy {

    // Map from flat node to nested node. This helps us finding the nested node to be modified
    childNodeMap = new Map<CHILDNODE, PARENTNODE>();
    // Map from nested node to flattened node. This helps us to keep the same object for selection
    parentNodeMap = new Map<PARENTNODE, CHILDNODE>();
    // The TreeControl controls the expand/collapse state of tree nodes.
    treeControl: FlatTreeControl<CHILDNODE>;
    // The TreeFlattener is used to generate the flat list of items from hierarchical data.
    treeFlattener: MatTreeFlattener<PARENTNODE, CHILDNODE>;
    // The MatTreeFlatDataSource connects the control and flattener to provide data.
    dataSource: MatTreeFlatDataSource<PARENTNODE, CHILDNODE>;

    isGroupNode = false;
    expandedNodeSet = new Set<string>();
    isDragging = false;
    expandTimeout: any;
    dragStartPosition: number;
    dragEndPosition: number;
    expandDelay = 1000;
    pointHoverNode;
    createScenarioGroupOpen = false;
    expandAll = true;
    runDataNotAvailable = false;
    runDataNotAvailableTitle: string;

    public allSelected = false;
    public indeterminate = false;
    public isScenariosChecked = false;
    public isScenariosAvailable = false;
    public createScenarioGroupSave = false;
    public scenarioGroupsAssignmentSave = false;
    public scenarioOwnerToggle = true;

    scenarios: Scenario[];
    projectCollaborators: Collaborator[];
    scenarioGroupsList: ScenarioGroup[];
    selectedScenarioId: string;
    modelRunId: string;
    projectId: number;
    scenarioGroup: ScenarioGroup;
    dataChange = new BehaviorSubject<PARENTNODE[]>([]);
    subscriptions: Subscription;
    scenarioGroupValidations: any;
    scenariosSelectedCount: number;
    scenarioGroupsAssignment: Array<any>;
    currentUser: User;
    accessPolicies: AccessPolicy[];
    scenarioOwnerLabel = 'Owner';
    scenarioGroupOrder: UserConfigurations;
    scenarioOrder: UserConfigurations;
    readonly scenarioNameLength: number;
    readonly scenarioDescLength: number;
    readonly scenarioGroupNameLength: number;
    calibration: Calibration;
    hasCalibrationStarted = false;
    scenarioIds: any[]

    constructor(private scenarioService: ScenarioService,
                private scenarioGroupService: ScenarioGroupService,
                private snackBarService: SnackBarService,
                private uiBlockerService: UiBlockerService,
                private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private accessPolicyService: AccessPolicyService,
                private appConstantsService: AppConstantsService,
                private authProxyService: AuthProxyService,
                private projectService: ProjectService,
                private modelRunService: ModelRunService,
                private userConfigurationsService: UserConfigurationsService,
                private calibrationService: CalibrationService,
                private webMessageService: WebMessageService) {
        this.runDataNotAvailableTitle = this.appConstantsService.RUN_NOT_AVAILABLE_LABEL;
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<CHILDNODE>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.scenarioGroup = new ScenarioGroup();
        this.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
        this.scenarioGroupValidations = {name: {error: '', warning: false}};
        this.scenarioGroupsAssignment = [];
        this.currentUser = this.authProxyService.user;
        this.accessPolicies = this.accessPolicyService.accessPolicies;
        this.scenarioNameLength = this.appConstantsService.SCENARIO_NAME_MAX_LENGTH;
        this.scenarioDescLength = this.appConstantsService.SCENARIO_DESC_MAX_LENGTH;
        this.scenarioGroupNameLength = this.appConstantsService.SCENARIO_GROUP_NAME_MAX_LENGTH;
    }

    get data(): PARENTNODE[] {
        return this.dataChange.value;
    }

    initialize(): any {
        const manageData = this.prepareManageScenariosData();

        // Notify the change.
        this.dataChange.next(manageData);
    }

    updateItem(): any {
        this.dataChange.next(this.data);
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.route.params.subscribe({
            next: (params: Params) => {
                this.scenarios = this.scenarioService.scenarios;
                this.projectCollaborators = this.projectService.collaborators;
                this.scenarioGroupsList = this.scenarioGroupService.scenarioGroups;
                this.projectId = this.scenarios[0].projectId;
                this.modelRunId = this.scenarios[0].modelRunId;
                this.scenarioGroupOrder = this.scenarioGroupService.scenarioGroupsOrder;
                this.scenarioOrder = this.scenarioGroupService.scenariosOrder;
                this.calibration = this.route.snapshot.data.calibration;
                this.hasCalibrationStarted = this.calibrationService.started(this.calibration);
                this.initialize();
                this.subscriptions.add(this.scenarioGroupService.onCreateScenarioGroup().subscribe((data) => {
                    this.scenarioService.getScenarios(this.modelRunId, false).subscribe((scenarios: Scenario[]) => {
                        this.scenarios = scenarios;
                        if (!this.scenarios.length) {
                            this.modelRunService.getModelRuns(this.projectId).subscribe((data) => {
                                this.router.navigate(['.'], {relativeTo: this.route.parent.parent.parent});
                            });
                        } else {
                            this.userConfigurationsService.getUserConfigurations(this.projectId, this.modelRunId).subscribe((userConfigurations) => {
                                this.scenarioGroupOrder = this.scenarioGroupService.scenarioGroupsOrder;
                                this.scenarioOrder = this.scenarioGroupService.scenariosOrder;
                                this.scenarioGroupService.getScenarioGroups(this.modelRunId).subscribe((scenarioGroups: ScenarioGroup[]) => {
                                    this.scenarioGroupsList = this.scenarioGroupService.scenarioGroups;
                                    this.accessPolicyService.getEntityPermissions(this.modelRunId).subscribe((permissions: Permission[]) => {
                                        this.accessPolicyService.getAccessPolicies(this.modelRunId).subscribe((policies: AccessPolicy[]) => {
                                            this.accessPolicies = this.accessPolicyService.accessPolicies;
                                            this.rebuildTreeForData(this.prepareManageScenariosData());
                                        });
                                    });
                                });
                            });
                        }
                    });
                }));

                this.webMessageService.consumeMessage(`${this.appConstantsService.CALIBRATION_MSG_GROUP}-${this.modelRunId}`).subscribe({
                    next: (eventData) => {
                        const responseJson = JSON.parse(eventData).data;
                        if (responseJson.modelRunId === this.modelRunId) {
                            this.calibrationService.fetch(this.projectId, this.modelRunId).subscribe((calibration: Calibration) => {
                                this.calibration = calibration;
                                this.hasCalibrationStarted = this.calibrationService.started(this.calibration);
                            });
                        }
                    }
                });

                this.subscriptions.add(this.scenarioGroupService.scenarioGroupsReOrderSubject.subscribe(() => {
                    this.scenarioGroupOrder = this.scenarioGroupService.scenarioGroupsOrder;
                    this.scenarioOrder = this.scenarioGroupService.scenariosOrder;
                    this.scenarioGroupsList = this.scenarioGroupService.scenarioGroups;
                    this.prepareManageScenariosData();
                }));

                this.modelRunService.getById(this.modelRunId, {
                    projectId: this.projectId,
                    modelRunId: this.modelRunId
                }).subscribe((modelRun) => {
                    this.runDataNotAvailable = !modelRun.runId;
                });
            }
        });

        this.subscriptions.add(this.scenarioService.deleteScenarioSubject.subscribe((data) => {
            this.deleteScenario(data);
        }));
        this.subscriptions.add(this.scenarioGroupService.deleteScenarioGroupSubject.subscribe((data) => {
            this.deleteScenarioGroup(data);
        }));
    }

    restrictCalibrationScenarios(node): boolean {
        return this.hasCalibrationStarted && this.scenarioGroupService.isCalibrationScenarioGroup(node);
    }

    toolTip(node: CHILDNODE): string {
        return this.restrictCalibrationScenarios(this.getParentNode(node)) ? 'Scenarios used for calibration are locked once calibration has started' :
            this.scenarioService.isCalibrationScenario(node) ? 'Calibration Scenario can not be deleted and moved to any other folder' : '';
    }

    prepareManageScenariosData(): any {
        const manageScenariosData = [];
        this.scenarioIds = [];
        this.scenarioGroupsList.forEach(scenarioGroup => {
            const scenarios = [];
            this.scenarioGroupService.scenariosOrderForGroup(scenarioGroup).forEach(scenarioId => {
                const scenario = this.scenarios.find((scenarioItem: Scenario) => {
                    return scenarioItem.id === scenarioId;
                });
                if (scenario) {
                    const conditions = {name: {error: '', warning: false}, description: {error: '', warning: false}};
                    const permissions = {
                        view: this.accessPolicyService.hasPermission(scenario.id, this.appConstantsService.VIEW_PERMISSION),
                        edit: this.accessPolicyService.hasPermission(scenario.id, this.appConstantsService.EDIT_PERMISSION),
                        simulate: this.accessPolicyService.hasPermission(scenario.id, this.appConstantsService.SIMULATE_PERMISSION),
                        delete: this.accessPolicyService.hasPermission(scenario.id, this.appConstantsService.DELETE_PERMISSION)
                    };
                    const owner = this.projectService.getCollaboratorById(scenario.createdBy);

                    if(scenario.createdBy){
                        this.scenarioIds.push(scenario.id);
                    }

                    scenarios.push({
                        id: scenario.id,
                        parentId: scenarioGroup.id,
                        name: scenario.name,
                        description: scenario.description,
                        type: 'Child',
                        owner,
                        isChecked: false,
                        children: [],
                        isNameDuplicate: false,
                        conditions,
                        permissions
                    });
                }
            });
            const groupPermissions = {
                view: this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.VIEW_PERMISSION),
                edit: this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.EDIT_PERMISSION),
                shareScenarioGroup: this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.shareScenarioGroup),
                addRemoveScenarioInGroup: this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.addScenarioInGroup),
                delete: this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.DELETE_PERMISSION)
            };
            const scenarioGroupOwner = this.projectService.getCollaboratorById(scenarioGroup.createdBy);

            const scenarioGroupData = {
                id: scenarioGroup.id, name: scenarioGroup.name, type: 'Group',
                owner: scenarioGroupOwner, isChecked: false, children: scenarios, isNameDuplicate: false,
                conditions: {name: {error: '', warning: false}}, permissions: groupPermissions
            };
            this.isScenariosAvailable = this.isScenariosAvailable ? this.isScenariosAvailable : scenarios.length > 0;
            manageScenariosData.push(scenarioGroupData);
        });
        this.allSelected = false;
        this.indeterminate = false;
        return manageScenariosData;
    }

    createScenarioGroup(): void {
        this.uiBlockerService.block();
        this.scenarioGroup.modelRunId = this.modelRunId;
        this.scenarioGroup.projectId = this.projectId;
        this.scenarioGroup.name = this.scenarioGroup.name?.trim();

        const selectedScenarios = this.treeControl.dataNodes.filter(i => i.isChecked).map(scenario => {
            return scenario.id;
        });
        this.scenarioGroup.assignedScenarios = selectedScenarios && selectedScenarios.length > 0 ? selectedScenarios : [];
        this.scenarioGroupService.createScenarioGroup(this.scenarioGroup).subscribe(res => {
                const scenarioGroupId = res.id;
                const unAssociatedScenarios = this.scenarioGroupService.findUnAssignedScenariosGroup(this.scenarioGroupsList);
                if (unAssociatedScenarios) {
                    const removeScenarios = [];
                    this.treeControl.dataNodes.filter(i => i.isChecked).forEach(scenario => {
                        const scenarioGroup = this.getParentNode(scenario);
                        if (scenarioGroup && this.scenarioGroupService.isUnAssignedScenariosGroupName(scenarioGroup.name)) {
                            removeScenarios.push(scenario.id);
                        }
                    });
                    if (removeScenarios.length > 0) {
                        const scenarioChanges = {add: [], remove: removeScenarios};
                        this.scenarioGroupService.manageScenarios(this.modelRunId, unAssociatedScenarios.id, scenarioChanges).subscribe(updateGroupRes => {
                                this.createScenarioGroupSuccess(scenarioGroupId);
                            },
                            error => {
                                this.onFailure(error.error.message);
                            });
                    } else {
                        this.createScenarioGroupSuccess(scenarioGroupId);
                    }
                } else {
                    this.createScenarioGroupSuccess(scenarioGroupId);
                }
            },
            err => {
                this.onFailure(err.error.message);
            });
    }

    createScenarioGroupSuccess(scenarioGroupId: any): any {
        this.onSuccess('Scenario folder created successfully!');
        this.isScenariosChecked = false;
        this.scenarioGroup = new ScenarioGroup();
        this.scenarioGroupService.createScenarioGroupSuccess(scenarioGroupId);
        this.createScenarioGroupOpen = !this.createScenarioGroupOpen;
    }

    validateScenarioGroup(scenarioGroup: ScenarioGroup): void {
        if (this.isScenarioGroupNameDuplicate(scenarioGroup.name)) {
            this.scenarioGroupValidations.name.error = 'A scenario folder with that name already exists. Please try another name.';
        } else if (scenarioGroup && (scenarioGroup.name.length < 1 ||
            (scenarioGroup.name.length > 0 && scenarioGroup.name.trim().length === 0))) {
            this.scenarioGroupValidations.name.error = 'This is required. Please enter a scenario folder name';
        } else if (scenarioGroup.name.length > this.scenarioGroupNameLength) {
            this.scenarioGroupValidations.name.error = 'The maximum number of characters allowed is 75.';
        } else {
            this.scenarioGroupValidations.name.error = '';
            this.scenarioGroupValidations.name.warning = scenarioGroup.name.length >=
                (this.scenarioGroupNameLength / 2) && scenarioGroup.name.length <= this.scenarioGroupNameLength;
        }
        this.createScenarioGroupSave = this.scenarioGroupValidations.name.error.length === 0;
    }

    isScenarioGroupNameDuplicate(scenarioGroupName: string): boolean {
        return this.scenarioGroupsList.findIndex(s => {
            if (this.currentUser.isInternalUser) {
                return scenarioGroupName && this.isEntityOwnerInternal(s.createdBy) &&
                    s.name.trim().toLowerCase() === scenarioGroupName.trim().toLowerCase();
            } else {
                return scenarioGroupName && s.name.trim().toLowerCase() === scenarioGroupName.trim().toLowerCase() &&
                    s.createdBy === this.currentUser.userId;
            }
        }) !== -1;
    }

    // Transform the data to something the tree can read.
    transformer(node: PARENTNODE, level: number): any {
        return {
            id: node.id,
            parentId: node.parentId,
            name: node.name,
            description: node.description,
            type: node.type,
            owner: node.owner,
            level,
            isChecked: node.isChecked,
            expandable: !!node.children,
            children: node.children,
            conditions: node.conditions,
            permissions: node.permissions,
        };
    }

    // Get the level of the node
    getLevel(node: CHILDNODE): any {
        return node.level;
    }

    // Return whether the node is expanded or not.
    isExpandable(node: CHILDNODE): any {
        return node.expandable;
    }

    // Get the children for the node.
    getChildren(node: PARENTNODE): any {
        return observableOf(node.children);
    }

    // Get whether the node has children or not.
    hasChild(index: number, node: CHILDNODE): boolean {
        return node.expandable;
    }

    // check if group node or not

    grpNode(node: PARENTNODE, nodes: CHILDNODE): boolean {
        if (nodes.type === 'Group' || node.type === 'Group') {
            this.isGroupNode = true;
            return this.isGroupNode;
        }
    }

    // collapse all child node if parent node is not opened
    collapseAllChildNodes(node: CHILDNODE): any {
        if (!this.treeControl.isExpanded(node)) {
            this.treeControl.collapseDescendants(node);
        }
    }

    // open parent node when creating a child item
    expandCurrentNode(nodeId): any {
        for (const dataNode of this.treeControl.dataNodes) {
            if (dataNode.id === nodeId) {
                this.treeControl.expand(dataNode);
            }
        }
    }

    // get all node from DOM and get all expanded nodes

    visibleNodes(): PARENTNODE[] {
        this.rememberExpandedTreeNodes(this.expandedNodeSet);
        const result = [];

        function addExpandedChildren(node: PARENTNODE, expanded: Set<string>): any {
            result.push(node);
            if (expanded.has(node.id)) {
                node.children.map(child => addExpandedChildren(child, expanded));
            }
        }

        this.dataSource.data.forEach(node => {
            addExpandedChildren(node, this.expandedNodeSet);
        });
        return result;
    }

    recNode(arr: any[], data: any[], index: number, maxIndex: number): any[] {
        if (arr === undefined) {
            arr = [];
        }

        for (let i = 0; i < data.length; i++) {
            index++;
            if (index === maxIndex) {
                return ([true, index, arr]);
            }

            if (data[i].children.length) {
                const res = this.recNode(arr, data[i].children, index, maxIndex);
                index = res[1];
                if (res[0] === true) {
                    arr.splice(0, 0, (i !== (data.length - 1)));
                    return ([true, index, arr]);
                }
            }
        }
        return ([false, index, arr]);
    }

    findNodeSiblings(arr: Array<any>, node): Array<any> {
        let result;
        let subResult;
        arr.forEach(item => {
            if (item.id === node.id && item.parentId === node.parentId) {
                result = arr;
            } else if (item.children) {
                subResult = this.findNodeSiblings(item.children, node);
                if (subResult) {
                    result = subResult;
                }
            }
        });
        return result;
    }

    dropScenario(event: CdkDragDrop<string[]>): any {
        const visibleNodes = this.visibleNodes();
        const changedData = JSON.parse(JSON.stringify(this.dataSource.data));
        const node = event.item.data;
        const siblings = this.findNodeSiblings(changedData, node);
        const siblingIndex = siblings.findIndex(n => n.id === node.id);
        const nodeToInsert: PARENTNODE | CHILDNODE = siblings.splice(siblingIndex, 1)[0];
        const nodeAtDest = visibleNodes[event.currentIndex];
        if (nodeAtDest.id === nodeToInsert.id) {
            return;
        }
        let relativeIndex = event.currentIndex; // default if no parent
        const nodeAtDestFlatNode = this.treeControl.dataNodes.find(n => nodeAtDest.id === n.id && nodeAtDest.parentId === n.parentId);
        const parent = this.getParentNode(nodeAtDestFlatNode);
        if (!parent || nodeAtDestFlatNode.type === 'Group' || this.getParentNode(node)?.id !== parent.id) {
            this.snackBarService.openWarningSnackBar('Scenarios can only be reordered within the same folder.' +
                ' To move a scenario to another folder use the assign icon to the right.');
            return;
        }

        if (parent) {
            const parentIndex = visibleNodes.findIndex(n => n.id === parent.id) + 1;
            relativeIndex = event.currentIndex - parentIndex;
        }
        const newSiblings = this.findNodeSiblings(changedData, nodeAtDest);
        if (!newSiblings) {
            return;
        }
        this.uiBlockerService.block();
        newSiblings.splice(relativeIndex, 0, nodeToInsert);
        const scenarioGroup = this.scenarioGroupsList.find(sg => sg.id === parent.id);
        const assignedScenarios = newSiblings.map(s => s.id);
        this.rebuildTreeForData(changedData);
        let scenarioOrder = null;
        if (this.scenarioOrder?.id) {
            scenarioOrder = this.scenarioOrder;
            scenarioOrder.configurations[scenarioGroup.id] = assignedScenarios;
            this.userConfigurationsService.updateUserConfiguration(scenarioOrder).subscribe((response) => {
                    this.onSuccess('Scenarios reordered successfully.');
                    this.scenarioGroupService.scenarioGroupsReOrderSubject.next({});
                },
                err => {
                    this.onFailure(err.error.message);
                });
        } else {
            scenarioOrder = new UserConfigurations();
            scenarioOrder.projectId = this.projectId;
            scenarioOrder.modelRunId = this.modelRunId;
            scenarioOrder.configType = this.appConstantsService.scenarioOrderConfigTye;
            scenarioOrder.configurations = {};
            scenarioOrder.configurations[scenarioGroup.id] = scenarioGroup.assignedScenarios;
            this.userConfigurationsService.createNewUserConfiguration(scenarioOrder).subscribe((response) => {
                    this.onSuccess('Scenarios reordered successfully.');
                    this.scenarioGroupService.scenarioGroupsReOrderSubject.next({});
                },
                err => {
                    this.onFailure(err.error.message);
                });
        }
    }

    dropScenarioGroup(event: CdkDragDrop<string[]>): any {
        const visibleNodes = this.visibleNodes();
        const changedData = JSON.parse(JSON.stringify(this.dataSource.data));
        const node = event.item.data;
        const siblings = this.findNodeSiblings(changedData, node);
        const siblingIndex = siblings.findIndex(n => n.id === node.id);
        const nodeToInsert: PARENTNODE | CHILDNODE = siblings.splice(siblingIndex, 1)[0];
        const nodeAtDest = visibleNodes[event.currentIndex];
        if (nodeAtDest.id === nodeToInsert.id) {
            return;
        }
        if (this.scenarioGroupService.isUnAssignedScenariosGroupName(nodeAtDest.name) || this.scenarioGroupService.isCalibrationScenarioGroup(nodeAtDest)) {
            this.snackBarService.openWarningSnackBar('Scenario folders can\'t be located below the Calibration Scenarios and Unassigned Scenarios folder');
            return;
        }
        const relativeIndex = event.currentIndex; // default if no parent
        const nodeAtDestFlatNode = this.treeControl.dataNodes.find(n => nodeAtDest.id === n.id);

        if (nodeAtDestFlatNode.level !== node.level) {
            return;
        }

        const newSiblings = this.findNodeSiblings(changedData, nodeAtDest);
        if (!newSiblings) {
            return;
        }
        this.uiBlockerService.block();
        newSiblings.splice(relativeIndex, 0, nodeToInsert);
        this.rebuildTreeForData(changedData);
        const scenarioGroupIds = newSiblings.map(scenarioGroup => {
            return scenarioGroup.id;
        });
        let scenarioGroupOrder = null;
        if (this.scenarioGroupOrder?.id) {
            scenarioGroupOrder = this.scenarioGroupOrder;
            scenarioGroupOrder.configurations = {scenarioGroupIds: scenarioGroupIds};
            this.userConfigurationsService.updateUserConfiguration(scenarioGroupOrder).subscribe((response) => {
                    this.onSuccess('Scenarios folders reordered successfully.');
                    this.scenarioGroupService.scenarioGroupsReOrderSubject.next({});
                },
                err => {
                    this.onFailure(err.error.message);
                });
        } else {
            scenarioGroupOrder = new UserConfigurations();
            scenarioGroupOrder.projectId = this.projectId;
            scenarioGroupOrder.modelRunId = this.modelRunId;
            scenarioGroupOrder.configType = this.appConstantsService.scenarioGroupOrderConfigTye;
            scenarioGroupOrder.configurations = {scenarioGroupIds: scenarioGroupIds};
            this.userConfigurationsService.createNewUserConfiguration(scenarioGroupOrder).subscribe((response) => {
                    this.onSuccess('Scenarios folders reordered successfully.');
                    this.scenarioGroupService.scenarioGroupsReOrderSubject.next({});
                },
                err => {
                    this.onFailure(err.error.message);
                });
        }
    }

    drop(event: CdkDragDrop<string[]>): any {
        if (event.item.data.type === 'Child' && !event.isPointerOverContainer) {
            this.snackBarService.openWarningSnackBar('Scenarios can only be reordered within the same folder.' +
                ' To move a scenario to another folder use the assign icon to the right.');
            return;
        }
        if (event.item.data.type === 'Group') {
            this.dropScenarioGroup(event);
            this.treeControl.expandAll();
        } else {
            this.dropScenario(event);
        }
    }

    dragStart($event, node: CHILDNODE): any {
        this.isDragging = true;
        if (node.type === 'Group') {
            this.treeControl.collapseAll();
        }
    }

    dragEnd(event: CdkDragRelease): any {
        if (event.source.element.nativeElement.parentElement.querySelector('.drag-recieve')) {
            event.source.element.nativeElement.parentElement.querySelector('.drag-recieve').classList.remove('drag-recieve');
        }
        this.isDragging = false;
    }

    dragMoved(event: CdkDragMove): any {
        this.dragEndPosition = event.pointerPosition.y;
        return this.dragEndPosition;
    }

    dragHover($event, node: CHILDNODE): any {
        if (this.isDragging) {
            this.pointHoverNode = node;
            if ($event.target.classList.contains('parent-node')) {
                $event.target.classList.add('drag-recieve');
            }
            clearTimeout(this.expandTimeout);
            this.expandTimeout = setTimeout(() => {
                this.treeControl.expand(node);
            }, this.expandDelay);
        }
    }

    dragHoverEnd($event): any {
        if (this.isDragging) {
            $event.target.classList.remove('drag-recieve');
            clearTimeout(this.expandTimeout);
        }
    }

    rebuildTreeForData(data: any): any {
        this.rememberExpandedTreeNodes(this.expandedNodeSet);
        this.dataSource.data = data;
        this.expandNodesById(this.treeControl.dataNodes, Array.from(this.expandedNodeSet));
    }

    ngAfterViewInit(): void {
        this.treeControl.expandAll();
    }

    expandCollapseAll(): void {
        this.expandAll = !this.expandAll;
        if (this.expandAll) {
            this.treeControl.expandAll();
        } else {
            this.treeControl.collapseAll();
        }
    }

    private rememberExpandedTreeNodes(
        expandedNodeSet: Set<string>
    ): any {
        for (const dataNode of this.treeControl.dataNodes) {
            const item = dataNode;
            if (this.treeControl.isExpanded(item)) {
                expandedNodeSet.add(item.id);
            } else {
                expandedNodeSet.delete(item.id);
            }
        }
    }

    private expandNodesById(flatNodes: CHILDNODE[], ids: string[]): any {
        if (!flatNodes || flatNodes.length === 0) {
            return;
        }

        const idSet = new Set(ids);
        return flatNodes.forEach((node) => {
            if (idSet.has(node.id)) {
                this.treeControl.expand(node);
                let parent = this.getParentNode(node);
                while (parent) {
                    this.treeControl.expand(parent);
                    parent = this.getParentNode(parent);
                }
            }
        });
    }

    private getParentNode(node: CHILDNODE): CHILDNODE | null {
        if (!node.parentId) {
            return null;
        }
        return this.treeControl.dataNodes.find(dn => node.parentId === dn.id);
    }

    itemChanged(node, event): any {
        node.isChecked = event.checked;
        this.scenariosStatusCheck();
    }

    toggleSelectAll(event): any {
        this.allSelected = event.checked;
        this.treeControl.dataNodes.forEach(item => {
            if (item.type === 'Child') {
                item.isChecked = event.checked;
            }
        });
        this.scenariosStatusCheck();
    }

    scenariosStatusCheck(): void {
        const totalSelected = this.treeControl.dataNodes.filter(i => i.type === 'Child' && i.isChecked).length;
        const totalCount = this.treeControl.dataNodes.filter(i => i.type === 'Child').length;
        this.scenariosSelectedCount = totalSelected;
        if (totalSelected === 0) {
            this.allSelected = false;
            this.indeterminate = false;
        } else if (totalSelected > 0 && totalSelected < totalCount) {
            this.allSelected = false;
            this.indeterminate = true;
        } else if (totalSelected === totalCount) {
            this.allSelected = true;
            this.indeterminate = false;
        }
        this.isScenariosChecked = this.indeterminate || this.allSelected;
    }

    toggleNode(node): any {
        const expanded = this.treeControl.isExpanded(node);
        if (expanded) {
            this.treeControl.expand(node);
        } else {
            this.treeControl.collapse(node);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    returnToScenarios(): void {
        this.router.navigate(['scenarios'], {relativeTo: this.route.parent});
    }

    validateName(node: CHILDNODE): any {
        node.changed = true;

        if (node.type === 'Child' && this.isNameDuplicate(node)) {
            node.conditions.name.error = 'Scenario name already exists.';
        } else if (node.type === 'Group' && this.isNameDuplicate(node)) {
            node.conditions.name.error = 'A scenario folder with that name already exists.';
        } else if (node.type === 'Group' && (node.name.length < 1 || (node.name.length > 0 && node.name.trim().length === 0))) {
            node.conditions.name.error = 'Please enter a scenario folder name.';
        } else if (node.type === 'Group' && node.name.length > this.scenarioGroupNameLength) {
            node.conditions.name.error = `The maximum number of characters allowed is ${this.scenarioGroupNameLength}.`;
        } else if (node.type === 'Child' && (node.name.length < 1 || (node.name.length > 0 && node.name.trim().length === 0))) {
            node.conditions.name.error = 'Please enter a scenario name.';
        } else if (node.type === 'Child' && node.name.length > this.scenarioNameLength) {
            node.conditions.name.error = 'You have reached the allowed character limit.';
        } else {
            node.conditions.name.error = '';
            if (node.type === 'Child') {
                node.conditions.name.warning = node.name.length >= (this.scenarioNameLength / 2) && node.name.length <= this.scenarioNameLength;
            } else if (node.type === 'Group') {
                node.conditions.name.warning = node.name.length >= (this.scenarioGroupNameLength / 2) && node.name.length <= this.scenarioGroupNameLength;
            }
        }

        if (node.type === 'Child') {
            node.hasError = node.conditions.name.error ? true : !!node.conditions.description.error;
        } else {
            node.hasError = !!node.conditions.name.error;
        }
    }

    isNameDuplicate(node: CHILDNODE): any {
        let isNameDuplicate = false;

        for (const dataNode of this.treeControl.dataNodes) {
            const item = dataNode;
            if (node.type === item.type && node.id !== item.id) {
                if (this.currentUser.isInternalUser) {
                    if ((!item.owner || this.isEntityOwnerInternal(item.owner.userManagementId)) &&
                        item.name.trim().toLowerCase() === node.name.trim().toLowerCase()) {
                        isNameDuplicate = true;
                    }
                } else if (item.owner.userManagementId === this.currentUser.userId &&
                    item.name.trim().toLowerCase() === node.name.trim().toLowerCase()) {
                    isNameDuplicate = true;
                }
            }
        }
        return isNameDuplicate;
    }

    isEntityOwnerInternal(createdBy: string): boolean {
        const owner = this.projectService.getCollaboratorById(createdBy);
        return owner ? owner.isInternalUser : false;
    }

    validateDescription(node: CHILDNODE): any {
        node.changed = true;
        if (node.description && node.description.length > this.scenarioDescLength) {
            node.conditions.description.error = 'You have reached the allowed character limit.';
        } else if (node.description?.length > 0 && node.description.trim().length === 0) {
            node.conditions.description.error = 'Enter valid Scenario Description.';
        } else {
            node.conditions.description.error = '';
            node.conditions.description.warning = node.description.length >= this.scenarioDescLength && node.description.length <= this.scenarioDescLength;
        }
        node.hasError = node.conditions.description.error ? true : !!node.conditions.name.error;
    }

    nodeChanged(node): any {
        if (node.changed && !node.hasError) {
            this.uiBlockerService.block();
            let observable;
            if (node.type === 'Child') {
                observable = this.scenarioService.updateScenario(this.projectId, this.modelRunId, node.id, {
                    name: node.name,
                    description: node.description
                });
            } else if (node.type === 'Group') {
                const scenarioGroup = this.scenarioGroupsList.find(sg => {
                    return sg.id === node.id;
                });
                scenarioGroup.name = node.name;
                observable = this.scenarioGroupService.updateScenarioGroup(this.modelRunId, scenarioGroup);
            }
            observable.subscribe(res => {
                    this.onSuccess('Changes Updated Successfully.');
                    this.scenarioGroupService.createScenarioGroupSuccess('');
                },
                err => {
                    this.onFailure(err.error.message);
                }
            );
        }
    }

    deleteScenarioModel(node): any {
        const dialogRef = this.dialog.open(ConfirmationModalComponent,
            {
                data: {
                    header: 'Delete Scenario?',
                    body: 'The scenario will be removed from all assigned folders. Are you sure you want to delete this scenario permanently?',
                    footer: 'DELETE',
                    action: 'deleteScenario',
                    scenario: {id: node.id, projectId: this.projectId, modelRunId: this.modelRunId}
                }
            });
    }

    deleteScenario(data): any {
        this.uiBlockerService.block();
        this.scenarioService.delete(data.scenario).subscribe(res => {
                this.onSuccess('Scenario Deleted Successfully.');
                this.scenarioGroupService.createScenarioGroupSuccess('');
            },
            err => {
                this.onFailure(err.error.message);
            });
    }

    deleteScenarioGroupModel(node): any {
        const dialogRef = this.dialog.open(ConfirmationModalComponent,
            {
                data: {
                    header: 'Delete scenario folder?',
                    body: 'Are you sure you want to delete this scenario folder permanently?',
                    footer: 'DELETE',
                    action: 'deleteScenarioGroup',
                    scenarioGroup: {id: node.id, projectId: this.projectId, modelRunId: this.modelRunId}
                }
            });
    }

    deleteScenarioGroup(data): any {
        this.uiBlockerService.block();
        this.scenarioGroupService.delete(data.scenarioGroup).subscribe(res => {
                this.onSuccess('Scenario folder deleted successfully.');
                this.scenarioGroupService.createScenarioGroupSuccess('');
            },
            err => {
                this.onFailure(err.error.message);
            });
    }

    /**
     * this will load initial scenarioGroup selection details, on click on add/remove ScenarioGroup icon.
     */
    loadScenarioGroupAssignment(node): void {
        this.scenarioGroupsList.forEach(scenarioGroup => {
            const scenarioExistsInGroup = scenarioGroup.assignedScenarios.findIndex(scenarioId => node.id === scenarioId) !== -1;
            const canAddScenarioToGroup = !(this.accessPolicyService.hasPermission(scenarioGroup.id, this.appConstantsService.addScenarioInGroup));
            this.scenarioGroupsAssignment.push({
                scenarioGroup: cloneJson(scenarioGroup),
                isChecked: scenarioExistsInGroup,
                changed: false,
                disable: this.scenarioGroupService.isUnAssignedScenariosGroup(scenarioGroup) ? !this.isScenarioOwner(node.id) :
                    this.restrictCalibrationScenarios(scenarioGroup) ? true :
                        this.scenarioGroupService.isCalibrationScenarioGroup(scenarioGroup) ? !this.isScenarioOwner(node.id) : canAddScenarioToGroup
            });
        });
    }

    /**
     * Checks if the scenario is owned by the session user or not.
     * */
    isScenarioOwner(scenarioId: string): boolean {
        const scenario: Scenario = this.scenarioService.getScenario(scenarioId);
        const sessionUser: User = this.authProxyService.user;
        let isOwner = sessionUser.userId === scenario.createdBy;
        if (!isOwner) {
            const scenarioOwnedByInternalGroup = this.accessPolicyService.accessPolicies.find((policy: AccessPolicy) => {
                return policy.entityId === scenarioId &&
                    policy.role === this.appConstantsService.scenarioOwnerPermission &&
                    policy.group === this.appConstantsService.internalGroup;
            });
            isOwner = (sessionUser.isInternalUser && scenarioOwnedByInternalGroup !== undefined);
        }
        return isOwner;
    }

    /**
     * on change of checked this method will validate the rules and take actions
     * 1)if no groups are selected select unAssociatedScenarioGroup
     * 2)if any group selected unCheck unAssociatedScenarioGroup.
     */
    scenarioGroupAssignmentChanged(node: any, scenarioGroupAssignment: any, event: any): any {
        scenarioGroupAssignment.isChecked = event.checked;
        scenarioGroupAssignment.changed = !scenarioGroupAssignment.changed;
        if (event.checked) {
            this.addScenarioToScenarioGroup(node, scenarioGroupAssignment.scenarioGroup);
        } else {
            this.removeScenarioFromScenarioGroup(node, scenarioGroupAssignment.scenarioGroup);
        }
        const checkedGroupsLength = this.scenarioGroupsAssignment.filter(sga => {
            return sga.isChecked && !sga.disable;
        }).length;
        const unAssociatedScenariosAssignment = this.scenarioGroupsAssignment.find((sga) => {
            return this.scenarioGroupService.isUnAssignedScenariosGroup(sga.scenarioGroup);
        });
        const calibrationScenariosAssignment = this.scenarioGroupsAssignment.find((sga) => {
            return this.scenarioGroupService.isCalibrationScenarioGroup(sga.scenarioGroup);
        });
        if (checkedGroupsLength === 0 && !unAssociatedScenariosAssignment.disable) {
            unAssociatedScenariosAssignment.isChecked = true;
            unAssociatedScenariosAssignment.changed = !unAssociatedScenariosAssignment.changed;
            this.addScenarioToScenarioGroup(node, unAssociatedScenariosAssignment.scenarioGroup);
        } else if (checkedGroupsLength > 1 && unAssociatedScenariosAssignment.isChecked) {
            unAssociatedScenariosAssignment.isChecked = false;
            unAssociatedScenariosAssignment.changed = !unAssociatedScenariosAssignment.changed;
            this.removeScenarioFromScenarioGroup(node, unAssociatedScenariosAssignment.scenarioGroup);
        } else if (checkedGroupsLength > 1 && calibrationScenariosAssignment.isChecked) {
            calibrationScenariosAssignment.isChecked = false;
            calibrationScenariosAssignment.changed = !calibrationScenariosAssignment.changed;
            this.removeScenarioFromScenarioGroup(node, calibrationScenariosAssignment.scenarioGroup);
        }
        this.scenarioGroupsAssignmentSaveCheck();
    }

    /**
     * clicking unAssociatedScenario radio button will clear all other(checkbox) selection and select only unAssociatedScenarioGroup
     */
    unAssociatedScenarioGroupAssignment(node: any, event: any): any {
        this.scenarioGroupsAssignment.forEach((sga) => {
            if (this.scenarioGroupService.isUnAssignedScenariosGroup(sga.scenarioGroup)) {
                sga.isChecked = true;
                sga.changed = !sga.changed;
                this.addScenarioToScenarioGroup(node, sga.scenarioGroup);
            } else {
                if (sga.isChecked) {
                    sga.isChecked = false;
                    sga.changed = !sga.changed;
                    this.removeScenarioFromScenarioGroup(node, sga.scenarioGroup);
                }
            }
        });
        this.scenarioGroupsAssignmentSaveCheck();
    }

    calibrationScenarioGroupAssignment(node: any, event: any): any {
        this.scenarioGroupsAssignment.forEach((sga) => {
            if (this.scenarioGroupService.isCalibrationScenarioGroup(sga.scenarioGroup)) {
                sga.isChecked = true;
                sga.changed = !sga.changed;
                this.addScenarioToScenarioGroup(node, sga.scenarioGroup);
            } else {
                if (sga.isChecked) {
                    sga.isChecked = false;
                    sga.changed = !sga.changed;
                    this.removeScenarioFromScenarioGroup(node, sga.scenarioGroup);
                }
            }
        });
        this.scenarioGroupsAssignmentSaveCheck();
    }

    scenarioGroupsAssignmentSaveCheck(): void {
        this.scenarioGroupsAssignmentSave = this.scenarioGroupsAssignment.findIndex(scenarioGroupAssignment => {
            return scenarioGroupAssignment.changed;
        }) !== -1;
    }

    removeScenarioFromScenarioGroup(node: any, scenarioGroup: any): any {
        const removeScenarioIndex = scenarioGroup.assignedScenarios.findIndex(scenarioId => node.id === scenarioId);
        scenarioGroup.assignedScenarios.splice(removeScenarioIndex, 1);
    }

    addScenarioToScenarioGroup(node: any, scenarioGroup: any): any {
        scenarioGroup.assignedScenarios.push(node.id);
    }

    /**
     * this will create scenarioGroupsAssignment after we close the add/remove scenario view.
     */
    scenarioGroupsAssignmentClear(): void {
        this.scenarioGroupsAssignment = [];
    }

    /**
     * this method will collect the checked ScenarioGroups and update to backend.
     */
    onSaveScenarioAssigment(node): void {
        this.uiBlockerService.block();
        const updateGroups = {addToGroups: [], removeFromGroups: []};
        this.scenarioGroupsAssignment.filter((scenarioGroupAssignment) => {
            return scenarioGroupAssignment.changed;
        }).forEach((scenarioGroupAssignment) => {
            const scenarioGroup = scenarioGroupAssignment.scenarioGroup;
            const scenarioIndex = scenarioGroup.assignedScenarios.findIndex(scenarioId => node.id === scenarioId);
            if (scenarioIndex !== -1) {
                updateGroups.addToGroups.push(scenarioGroup.id);
            } else {
                updateGroups.removeFromGroups.push(scenarioGroup.id);
            }
        });

        this.scenarioGroupService.manageScenarioToGroupAssignments(this.projectId, this.modelRunId, node.id, updateGroups).subscribe(res => {
                this.onSuccess('Scenario folder assignment updated successfully.');
                this.scenarioGroupService.createScenarioGroupSuccess('');
            },
            err => {
                this.onFailure(err.error.message);
            });
    }

    onSuccess(message: string): void {
        this.snackBarService.openSnackBar(message, 'success', 4000);
        this.uiBlockerService.unblock();
    }

    onFailure(message: string): void {
        this.uiBlockerService.unblock();
        this.snackBarService.openSnackBar(message, 'error', 4000);
    }

    shareScenarioGroupModal(node): void {
        const shareScenarioGroup = this.scenarioGroupsList.find(scenarioGroup => {
            return scenarioGroup.id === node.id;
        });
        const accessPolicies = this.accessPolicyService.getEntityAccessPolicies(shareScenarioGroup.id);
        this.dialog.open(SharingModalComponent,
            {
                data: {
                    modelRunId: this.modelRunId,
                    scenarioGroup: shareScenarioGroup,
                    accessPolicies
                }
            });
    }

    mouseOverText(node): void {
        const input = document.getElementById(node.id + 'desc');
        input.setAttribute('placeholder', 'Enter description');
    }

    mouseLeaveText(node): void {
        const input = document.getElementById(node.id + 'desc');
        input.setAttribute('placeholder', '--');
    }

    lastModified(node): string {
        const scenario = this.scenarioService.getScenario(node.id);
        const dateTimeAgo = scenario ? moment(scenario.inputsModifiedOn).fromNow() : '';
        return `${dateTimeAgo}`;
    }

    modifiedBy(node): string {
        const scenario = this.scenarioService.getScenario(node.id);
        const modifiedUser = scenario ? this.projectService.getCollaboratorById(scenario.updatedBy) : null;
        return modifiedUser ? (this.currentUser.isInternalUser || !modifiedUser.isInternalUser) ? modifiedUser.fullName : 'BASES' : '';
    }

    owner(node): string {
        const fullName = node.owner ? node.owner.fullName : null;
        const ownerIsInternal = node.owner ? node.owner.isInternalUser : false;
        return (this.currentUser.isInternalUser || !ownerIsInternal) ? fullName : 'BASES';
    }

    toggleOwnerAndModifiedBy(status): void {
        this.scenarioOwnerToggle = status === 0;
        this.scenarioOwnerLabel = this.scenarioOwnerToggle ? 'Owner' : 'Modified By';
    }
}

