import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from './environment.service';
import {map} from 'rxjs/operators';
import {AuditTrail} from "../models/audit-trail.model";
import {Calibration} from "@app/models/calibration/calibration.model";

@Injectable({
    providedIn: 'root'
})
export class AuditTrialService {

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService
    ) {
    }

    getAuditTrails(projectId: number, modelRunId: string): Observable<AuditTrail[]> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/auditTrails`;
        return this.http.get<Array<AuditTrail>>(url).pipe(map((auditTrail: AuditTrail[]) => {
            return auditTrail;
        }));
    }

    update(projectId: number, modelRunId: string, auditTrail: AuditTrail): Observable<AuditTrail> {
        const env = this.environmentService.environment.authProxy;
        const url = `${env.url}/${env.lpoSimulatorContextPath}/projects/${projectId}/runs/${modelRunId}/auditTrails`;
        return this.http.put<AuditTrail>(url, {
            auditTrail: auditTrail
        }).pipe(map((auditTrail: AuditTrail) => {
            return auditTrail;
        }));
    }
}
